import React from "react"

import ReactGA from "react-ga4"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"

import * as Yup from "yup"
import { Autocomplete, Box, CircularProgress, Grid, TextField } from "@mui/material"
import {
  Button,
  InputText,
  NotificationUtils,
  SingleDayPicker,
  Snackbar,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"
import moment from "moment"

import { KonanLogo } from "../../../../components/icons/KonanLogo"
import { SidAPI } from "../../../../services/SidAPI"
import { CustomTokenRefresh } from "../../../../types/generated/authentication/CustomTokenRefresh"
import { Organization } from "../../../../types/generated/authentication/Organization"
import { OrganizationInvitationBulkRequest } from "../../../../types/generated/authentication/OrganizationInvitationBulkRequest"
import { OrganizationInvitationBulkResponse } from "../../../../types/generated/authentication/OrganizationInvitationBulkResponse"
import { Auth } from "../../../../utils/auth"

import styles from "../../Entry.module.scss"

// Yup validation schema object
const validationSchema = Yup.object({
  name: Yup.string().required("Organization Name is required"),
  email: Yup.array().of(Yup.string().email((el) => el.value + " is an invalid email. ")),
})

/**
 * create organization component
 * @return  {<UserOrganizationPage />}
 */
export function UserOrganizationPage(): React.ReactElement {
  const navigate = useNavigate()

  const [selectedDate, setSelectedDate] = React.useState<moment.Moment>(moment().add(7, "days"))
  const [lastInput, setLastInput] = React.useState<string>("")

  // request mutations
  const createOrganizationMutation = useMutation<AxiosResponse<Organization>, AxiosError, string>(
    SidAPI.createOrganization,
  )
  const refreshTokenMutation = useMutation<AxiosResponse<CustomTokenRefresh>, AxiosError, string>(SidAPI.refreshToken)

  const inviteUserMutation = useMutation<
    AxiosResponse<OrganizationInvitationBulkResponse>,
    AxiosError,
    {
      uid: string
    } & OrganizationInvitationBulkRequest
  >(SidAPI.inviteUsers)

  // form control
  const formik = useFormik({
    initialValues: {
      name: "",
      email: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const org = await createOrganizationMutation.mutateAsync(values.name)
        // Refresh the access token to update the organization_id and organization_name fields in the payload
        // TODO: Handle errors when refreshing token fails
        const refreshResponse = await refreshTokenMutation.mutateAsync(Auth.getRefreshToken() as string)
        await Auth.login(refreshResponse.data.access, Auth.getRefreshToken())

        // sends request to invite members if emails are added to the input component
        if (formik.values.email.length > 0) {
          try {
            let res
            if (Yup.string().email().isValidSync(lastInput)) {
              //if the user forgot to press enter and the last email entered is valid
              res = await inviteUserMutation.mutateAsync({
                uid: org.data?.uid ? org.data?.uid : "",
                emails: lastInput !== "" ? [lastInput].concat(values.email) : values.email,
                expiry_date: selectedDate?.toISOString() as string,
              })
            } else {
              res = await inviteUserMutation.mutateAsync({
                uid: org.data?.uid ? org.data?.uid : "",
                emails: values.email,
                expiry_date: selectedDate?.toISOString() as string,
              })
            }

            ReactGA.event({
              category: "Users",
              action: "Invited users to organization",
            })

            if (res.data.fail.length === 0) {
              NotificationUtils.toast("Invitation successfully sent!", {
                snackBarVariant: "positive",
              })
            }
          } catch (e) {
            NotificationUtils.toast("Couldn't send invitations, please try again later!", {
              snackBarVariant: "negative",
            })
          }
        }

        navigate("/")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.response?.data) {
          formik.setErrors(e.response.data)
        }
      }
    },
  })

  // Disable accessing past dates in date picker
  const disablePast = (date: moment.Moment): boolean => {
    return moment() < date
  }

  const handleDateChange = (startDate: moment.Moment | null): void => {
    setSelectedDate(startDate as moment.Moment)
  }

  return (
    <Grid container item xs={12} direction="column" alignItems="center" wrap="nowrap">
      <Grid item className={styles.logo}>
        <KonanLogo logoWidth={window.innerWidth >= 400 ? 360 : 300} />
      </Grid>

      {/* Network error */}
      <Grid item>
        {createOrganizationMutation.isError && createOrganizationMutation.error?.message === "Network Error" && (
          <Grid item xs={12}>
            <Snackbar variant="negative" fullWidth description="Network Error Could not connect to server." />
            <Box mt={2} />
          </Grid>
        )}
      </Grid>

      <Grid container className={styles.verificationContainer}>
        <Grid item xs={12}>
          <Typography variant="h2-bold">Create an Organization</Typography>
          <Typography variant="p">Or wait until you’re invited to one</Typography>
        </Grid>

        <Grid item mt={2}>
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore */}
          {createOrganizationMutation.isError && createOrganizationMutation.error?.response?.data?.name[0] && (
            <Grid item xs={12}>
              <Snackbar
                variant="negative"
                fullWidth
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                description={createOrganizationMutation.error?.response?.data?.name[0]}
              />
            </Grid>
          )}
        </Grid>

        <Grid container item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item xs={12} paddingTop={3}>
              <InputText
                fullWidth
                size={"100%"}
                id="name"
                label="Organization Name"
                placeholder="My Organization"
                error={Boolean(formik.errors.name) && formik.touched.name && formik.errors.name}
                value={formik.values.name}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                required
                disabled={formik.isSubmitting}
                hideDescription
              />
            </Grid>

            <Grid item xs={12} paddingTop={3}>
              <Typography variant="h3-bold">Invite Members</Typography>
              <Box mb={2} />

              <Typography variant="span" gutterBottom>
                Emails
              </Typography>

              <Autocomplete
                multiple
                options={[]}
                onBlur={formik.handleBlur}
                onChange={(_, value) => formik.setFieldValue("email", value)}
                onInputChange={(_, value) => setLastInput(value)}
                freeSolo
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="email"
                    variant="standard"
                    size="small"
                    placeholder="John@doe.com"
                    type="email"
                    value={formik.values.email}
                    error={Boolean(formik.errors.email) || !Yup.string().email().isValidSync(lastInput)}
                    helperText={formik.errors.email || "Press enter after entering the email to add it to the list"}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} paddingTop={1}>
              <Typography variant="span" gutterBottom>
                Expiry date
              </Typography>
              <SingleDayPicker date={selectedDate} onDateChange={handleDateChange} isOutsideRange={disablePast} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} paddingTop={3}>
          <Button
            onClick={() => formik.submitForm()}
            variant="primary"
            disabled={formik.isSubmitting}
            type="submit"
            fullWidth
          >
            {formik.isSubmitting ? <CircularProgress color="inherit" size={20} /> : "Create Organization"}
          </Button>
          <hr style={{ margin: "8px 0px", borderColor: "rgba(128, 128, 128, 0.8)" }} />
          <Button
            onClick={async () => {
              await SidAPI.logOut({
                all_devices: false,
                refresh: localStorage.getItem("refresh_token") as string,
              }).catch(console.error)
              Auth.logOut(true, navigate)
            }}
            variant="secondary"
            disabled={formik.isSubmitting}
            fullWidth
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
