import React, { useState } from "react"

import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import TerminalOutlinedIcon from "@mui/icons-material/TerminalOutlined"
import { Card, Grid, IconButton, SvgIcon } from "@mui/material"
import { Button, Menu, MenuItem, Skeleton, Tag, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format } from "date-fns"
import moment from "moment"

import { KonanAvatar } from "../../components/Avatar"
import { ScriptCardProps } from "./Interfaces"

import styles from "./Scripts.module.scss"

/**
 * Renders a script card component with details about a specific script. It includes interactive elements
 * such as edit, delete, and logs. This component is integrated with several dialogs for different
 * actions (view logs, edit, delete) and uses mutations for script deletion.
 *
 * @param {Script} script script data.
 * @returns The `ReactElement` that represents the script card.
 */
export function ScriptCard({
  script,
  MenuOptions,
  onLogsDialogClick,
  onNameClick,
}: Readonly<ScriptCardProps>): React.ReactElement {
  const { created_at, created_by, name, template, is_draft } = script

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <Card className="card-box-shadow">
      <Grid container direction="column" className={styles.card}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="flex-start">
          <Grid item container gap={0.5}>
            {/* Script type (custom/builtin) */}
            <Grid item>
              <Tag size="small" variant={template ? "purple" : "pink"}>
                {template ? "Built-in" : "Custom"}
              </Tag>
            </Grid>

            <Grid item>
              {is_draft && (
                <Tag variant="default" size="small">
                  Draft
                </Tag>
              )}
            </Grid>
          </Grid>

          {/* Menu  */}
          <Grid item xs={2} container justifyContent="flex-end" alignItems="flex-start">
            <IconButton
              style={{ marginTop: "-5px" }}
              onClick={(e) => setAnchorEl(e?.currentTarget)}
              aria-label="settings"
              size="small"
            >
              <MoreHorizIcon sx={{ color: "var(--grayscale-text-2)" }} />
            </IconButton>
            <Menu
              id="simple-menu"
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
              menuMaxContent
              open={Boolean(anchorEl)}
            >
              {MenuOptions?.map((option) => (
                <MenuItem
                  onClick={() => {
                    option.onClick()
                    setAnchorEl(null)
                  }}
                  key={option.title}
                >
                  <Typography variant="label" color={option.variant ?? "neutral"} variantColor={2}>
                    {option.title}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>

        {/* Name and draft status */}
        <Grid item container flexWrap="nowrap" xs={12} gap={1} alignItems="center" justifyContent="flex-start">
          <Typography
            variant="h2-bold"
            noWrap
            onClick={onNameClick}
            className={styles.scriptName}
            color="important"
            variantColor={2}
          >
            {name}
          </Typography>
        </Grid>

        {/* Created by and logs row */}
        <Grid container item xs={12} justifyContent="space-between" wrap="nowrap" mt={0.5}>
          <Grid
            container
            item
            spacing={1}
            alignItems="center"
            display="flex"
            flexWrap="nowrap"
            // 42px = button width
            maxWidth={"calc(100% - 42px)"}
          >
            <Grid item sx={{ marginTop: "2px" }} display="flex" alignItems="center">
              {/* SvgIcon is used to fix square avatars on safari */}
              <SvgIcon className={styles.avatar}>
                <KonanAvatar size={24} name={created_by ?? ""} />
              </SvgIcon>
            </Grid>
            <Grid item xs={10}>
              <Grid item>
                <Typography variant="label" noWrap>
                  {created_by}
                </Typography>
              </Grid>

              <Typography variant="label" noWrap>
                <Tooltip title={format(new Date(created_at), "dd/MM/yyyy, p")} placement="right">
                  <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                    {moment(new Date(created_at)).fromNow()}
                  </Typography>
                </Tooltip>
              </Typography>
            </Grid>
          </Grid>

          <Grid item display="flex" alignSelf="flex-end" justifySelf="flex-end">
            <Button onClick={onLogsDialogClick} size={"small"} variant="secondary">
              <TerminalOutlinedIcon sx={{ fontSize: "16px" }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

/**
 * Loading card for script card
 * @returns {React.ReactElement}
 */
export function ScriptLoadingCard(): React.ReactElement {
  return (
    <Card className={"card-box-shadow"}>
      <Grid container direction="column" className={styles.card}>
        <Grid item xs={3} mb={0.5}>
          <Skeleton variant="rectangular" height={15} width={"20%"} />
        </Grid>

        <Grid item mt={1} xs={12}>
          <Skeleton variant="rectangular" height={15} width={"60%"} />
        </Grid>

        <Grid item xs={12} container flexWrap="nowrap" spacing={1} alignItems="center">
          <Grid item mt={1}>
            <Skeleton variant="circular" width={32} height={32} />
          </Grid>

          <Grid direction="column" container item mt={1}>
            <Grid item xs={6} my={1}>
              <Skeleton variant="rectangular" height={10} width={"80%"} />
            </Grid>
            <Grid item xs={3} mb={0.5}>
              <Skeleton variant="rectangular" height={10} width={"30%"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
