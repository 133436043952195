import React, { Fragment, useState } from "react"

import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { queryClient } from "../.."
import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { KonanAPI } from "../../services/KonanAPI"
import { ExportJobRetrieve } from "../../types/generated/api/ExportJobRetrieve"
import { MenuOption } from "../Members"
import { DataFileCard } from "./DataFileCard"
import { DataFileCardCardContainerProps } from "./interfaces"

/**
 * Represents a container function that wraps the DataFile component.
 *
 * @param {ExportJobList} exportJob The export job associated with the data file.
 * @returns {React.ReactElement}
 */
export function Container(props: Readonly<DataFileCardCardContainerProps>): React.ReactElement {
  const { exportJob, openDataFileHistory } = props
  const { id: projectId } = useParams()

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false)

  // retrieve export job
  const { refetch } = useQuery<AxiosResponse<ExportJobRetrieve>, AxiosError>(
    ["export-job-retrieve"],
    () => KonanAPI.retrieveExportJob({ project_uuid: projectId as string, export_job_uuid: exportJob.uuid }),
    {
      enabled: false,
      // Downloading file from pre-signed url
      onSuccess: ({ data }) => {
        const link = document.createElement("a")
        link.href = data?.predictions_file as string
        link.setAttribute("download", data.name + ".csv")

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode?.removeChild(link)
      },
    },
  )

  const deleteExportJobMutation = useMutation<
    AxiosResponse,
    AxiosError,
    {
      project_uuid: string
      export_job_uuid: string
    }
  >(KonanAPI.deleteExportJob, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["data-files", projectId])

      NotificationUtils.toast("Export Job deleted successfully", { snackBarVariant: "positive" })
    },
    onError: async () => {
      NotificationUtils.toast("Export Job deletion failed", { snackBarVariant: "negative" })
    },
  })

  const cancelExportJobMutation = useMutation<
    AxiosResponse,
    AxiosError,
    {
      project_uuid: string
      export_job_uuid: string
    }
  >(KonanAPI.cancelExportJob, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["data-files", projectId])

      NotificationUtils.toast("Export Job deleted successfully", { snackBarVariant: "positive" })
    },
    onError: async () => {
      NotificationUtils.toast("Export Job deletion failed", { snackBarVariant: "negative" })
    },
  })

  const isJobInProgress = [ExportJobRetrieve.status.RUNNING, ExportJobRetrieve.status.PENDING].includes(
    ExportJobRetrieve.status[exportJob.status as keyof typeof ExportJobRetrieve.status],
  )

  const menuOptions = (): MenuOption[] => {
    const options: MenuOption[] = []

    if (isJobInProgress) {
      options.push({
        title: "Cancel Data File",
        variant: "negative",
        onClick: () => {
          setIsDeletionDialogOpen(true)
        },
      })
    } else {
      options.push({
        title: "Download History",
        variant: "neutral",
        onClick: () => {
          openDataFileHistory?.(exportJob.name)
        },
      })

      options.push({
        title: "Delete Data File",
        variant: "negative",
        onClick: () => {
          setIsDeletionDialogOpen(true)
        },
      })
    }

    return options
  }

  const onDialogAccept = async (): Promise<void> => {
    if (!isJobInProgress) {
      await deleteExportJobMutation.mutateAsync({
        project_uuid: projectId as string,
        export_job_uuid: exportJob.uuid,
      })
    } else {
      await cancelExportJobMutation.mutateAsync({
        project_uuid: projectId as string,
        export_job_uuid: exportJob.uuid,
      })
    }
  }

  return (
    <Fragment>
      {isDeletionDialogOpen && (
        <BaseSimpleDialog
          isLoading={deleteExportJobMutation.isLoading}
          onAccept={onDialogAccept}
          mode={!isJobInProgress ? "data-file-deletion" : "data-file-cancelation"}
          name={exportJob.name}
          open
          onClose={() => setIsDeletionDialogOpen(false)}
        />
      )}

      <DataFileCard
        exportJob={exportJob}
        onDownloadInitiated={() => {
          refetch()
          NotificationUtils.toast("Downloading your data, please wait...", { snackBarVariant: "important" })
        }}
        menuOptions={menuOptions()}
      />
    </Fragment>
  )
}
