import React, { Fragment, useMemo, useState } from "react"

import { useQuery } from "react-query"

import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CloseIcon from "@mui/icons-material/Close"
import FaceIcon from "@mui/icons-material/Face"
import ShieldIcon from "@mui/icons-material/Shield"
import TextSnippetIcon from "@mui/icons-material/TextSnippet"
import { CircularProgress, Grid, IconButton, useMediaQuery, useTheme } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import { Button, Snackbar, Tab, Tabs, Tag, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosResponse } from "axios"

import { KonanEmptyState } from "../../../../../components/KonanEmptyState"
import { KonanJsonView } from "../../../../../components/KonanJsonView"
import { getTheme } from "../../../../../hooks/UseTheme"
import { DoxterAPI } from "../../../../../services/DoxterAPI"

type Props = {
  open: boolean
  uuid: string
  onClose: () => void
}

/**
 * Doxter's session details dialog
 * @param {boolean} open
 * @param {string} uuid
 * @param {Function} onClose
 * @return {React.ReactElement}
 */
export function SessionDetailsDialog(props: Readonly<Props>): React.ReactElement {
  const { uuid, open, onClose } = props

  const MUITheme = useTheme()
  const theme = getTheme()

  const [tabValue, setTabValue] = useState<string>("Document Extraction")

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: sessionDetails, isLoading: isSessionLoading } = useQuery<AxiosResponse<any>>(
    ["ekyc-session-details", uuid],
    () => DoxterAPI.EkycSessionDetails(uuid),
    { enabled: uuid !== "" },
  )

  // zips checks that return as separate arrays
  const liveness_checks = useMemo(() => {
    if (sessionDetails?.data.liveness_detection.length === 0) {
      return []
    }

    const challenges =
      sessionDetails?.data.liveness_detection[sessionDetails?.data.liveness_detection.length - 1].liveness_challenges
    const results =
      sessionDetails?.data.liveness_detection[sessionDetails?.data.liveness_detection.length - 1]
        .liveness_challenges_results

    // Zipping challenge string and their values in an array to ease the mapping process
    const checks_array = []
    for (const i in challenges) {
      checks_array.push([challenges[i], results[i]])
    }
    return checks_array
  }, [sessionDetails?.data.liveness_detection])

  // memoizing details to cleanup the code below
  const details = useMemo(() => {
    return {
      ...sessionDetails,
      document_classifier: sessionDetails?.data.document_classifier[sessionDetails?.data.document_classifier.length - 1]
        ? {
            ...sessionDetails?.data.document_classifier[sessionDetails?.data.document_classifier.length - 1],
          }
        : null,
      backside_data: sessionDetails?.data.backside_data[sessionDetails?.data.backside_data.length - 1]
        ? { ...sessionDetails?.data.backside_data[sessionDetails?.data.backside_data.length - 1] }
        : null,
      backside_fraud: sessionDetails?.data.backside_fraud[sessionDetails?.data.backside_fraud.length - 1]
        ? { ...sessionDetails?.data.backside_fraud[sessionDetails?.data.backside_fraud.length - 1] }
        : null,
      frontside_data: sessionDetails?.data.frontside_data[sessionDetails?.data.frontside_data.length - 1]
        ? { ...sessionDetails?.data.frontside_data[sessionDetails?.data.frontside_data.length - 1] }
        : null,
      frontside_fraud: sessionDetails?.data.frontside_fraud[sessionDetails?.data.frontside_fraud.length - 1]
        ? { ...sessionDetails?.data.frontside_fraud[sessionDetails?.data.frontside_fraud.length - 1] }
        : null,
      liveness_detection: sessionDetails?.data.liveness_detection[sessionDetails?.data.liveness_detection.length - 1]
        ? {
            ...sessionDetails?.data.liveness_detection[sessionDetails?.data.liveness_detection.length - 1],
          }
        : null,
    }
  }, [sessionDetails])

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        onClose()
        // To prevent flickering
        setTimeout(() => setTabValue("Document Extraction"), 300)
      }}
      maxWidth="md"
      fullScreen={useMediaQuery(MUITheme.breakpoints.down("md"))}
    >
      <DialogContent className="dialog-content-base">
        {isSessionLoading ? (
          <Grid container justifyContent={"center"} my={1}>
            <CircularProgress />
          </Grid>
        ) : (
          <Fragment>
            <Grid container justifyContent={"space-between"} mb={1}>
              <Grid item>
                <Tag
                  variant={
                    (sessionDetails?.data.status as unknown as string) === "verified"
                      ? "positive"
                      : (sessionDetails?.data.status as unknown as string) === "unverified"
                        ? "negative"
                        : "warning"
                  }
                >
                  {sessionDetails?.data.status}
                </Tag>
              </Grid>
              <Grid item>
                {sessionDetails?.data.status === "unverified" && (
                  <Button size="small" variant="dangerous" disabled>
                    Force Verify
                  </Button>
                )}
                <IconButton
                  className="close-icon-button"
                  onClick={() => {
                    onClose()
                    // To prevent flickering
                    setTimeout(() => setTabValue("Document Extraction"), 300)
                  }}
                  size={"small"}
                >
                  <CloseIcon style={{ color: theme.palette.gray.background[1] }} />
                </IconButton>
              </Grid>
            </Grid>

            {/* Summary Section */}
            {(sessionDetails?.data.status as unknown as string) === "unverified" && (
              <Fragment>
                <Typography variant="h3-bold" gutterBottom>
                  Summary
                </Typography>

                {/* Reason */}
                <Typography variant="p" gutterBottom>
                  Face can't be verified
                </Typography>
              </Fragment>
            )}

            <Tabs value={tabValue}>
              <Tab
                icon={() => <TextSnippetIcon fontSize="small" />}
                label={
                  <Grid container>
                    <Grid item mr={0.5}>
                      Data Extraction
                    </Grid>
                  </Grid>
                }
                value={"Document Extraction"}
                selected={tabValue === "Document Extraction"}
                onClick={() => setTabValue("Document Extraction")}
              />
              <Tab
                icon={() => <ShieldIcon fontSize="small" />}
                label={
                  <Grid container>
                    <Grid item mr={0.5}>
                      Fraud Detection
                    </Grid>
                    <Grid item>
                      <Tag
                        variant={
                          !sessionDetails?.data.nid_fs_fraud_completed || !sessionDetails?.data.nid_bs_fraud_completed
                            ? "warning"
                            : sessionDetails?.data.nid_bs_fraud_verified && sessionDetails?.data.nid_fs_fraud_verified
                              ? "positive"
                              : "negative"
                        }
                        size="small"
                      >
                        {!sessionDetails?.data.nid_fs_fraud_completed || !sessionDetails?.data.nid_bs_fraud_completed
                          ? "Incomplete"
                          : sessionDetails?.data.nid_bs_fraud_verified && sessionDetails?.data.nid_fs_fraud_verified
                            ? "Verified"
                            : "Unverified"}
                      </Tag>
                    </Grid>
                  </Grid>
                }
                value="Fraud Detection"
                selected={tabValue === "Fraud Detection"}
                onClick={() => setTabValue("Fraud Detection")}
              />
              <Tab
                icon={() => <FaceIcon fontSize="small" />}
                label={
                  <Grid container>
                    <Grid item mr={0.5}>
                      Face Validation
                    </Grid>
                    <Grid item>
                      <Tag
                        variant={
                          !sessionDetails?.data.nid_fs_classifier_completed ||
                          !sessionDetails?.data.nid_bs_classifier_completed ||
                          !sessionDetails?.data.liveness_completed
                            ? "warning"
                            : details.liveness_detection?.face_match_score &&
                                Math.round(details.liveness_detection.face_match_score) > 40
                              ? "positive"
                              : "negative"
                        }
                        size="small"
                      >
                        {!sessionDetails?.data.nid_fs_classifier_completed ||
                        !sessionDetails?.data.nid_bs_classifier_completed ||
                        !sessionDetails?.data.liveness_completed
                          ? "Incomplete"
                          : details.liveness_detection?.face_match_score &&
                              Math.round(details.liveness_detection.face_match_score) > 40
                            ? "Verified"
                            : "Unverified"}
                      </Tag>
                    </Grid>
                  </Grid>
                }
                value={"Face Validation"}
                selected={tabValue === "Face Validation"}
                onClick={() => setTabValue("Face Validation")}
              />
              <Tab
                icon={() => <FaceIcon fontSize="small" />}
                label={
                  <Grid container>
                    <Grid item mr={0.5}>
                      Liveness
                    </Grid>
                    <Grid item>
                      <Tag
                        variant={
                          !sessionDetails?.data.liveness_completed
                            ? "warning"
                            : sessionDetails?.data.liveness_verified
                              ? "positive"
                              : "negative"
                        }
                        size="small"
                      >
                        {!sessionDetails?.data.liveness_completed
                          ? "Incomplete"
                          : sessionDetails?.data.liveness_verified
                            ? "Verified"
                            : "Unverified"}
                      </Tag>
                    </Grid>
                  </Grid>
                }
                value={"Liveness"}
                selected={tabValue === "Liveness"}
                onClick={() => setTabValue("Liveness")}
              />
            </Tabs>

            {/* Document Extraction */}
            {(details.backside_data || details.frontside_data) && tabValue === "Document Extraction" ? (
              <Grid container>
                <Grid item mt={2}>
                  <Typography variant="h2-bold" gutterBottom>
                    Document Extraction
                  </Typography>
                </Grid>

                {/* NID Front side */}
                {details.frontside_data ? (
                  <Grid container item>
                    <Grid item>
                      <Typography variant="label" gutterBottom>
                        National ID front
                      </Typography>
                    </Grid>
                    <Grid container item spacing={2}>
                      <Grid item xs={6}>
                        <img src={details.frontside_data.image} width={"100%"} alt="nid" />
                      </Grid>

                      {/* Extracted Data */}
                      <Grid item xs={6}>
                        <KonanJsonView src={details.frontside_data.data} />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <KonanEmptyState title="National ID frontside unavailable" subTitle="please try again" />
                )}

                {/* NID Back side */}
                {details.backside_data ? (
                  <Grid container item mt={2}>
                    <Grid item>
                      <Typography variant="label" gutterBottom>
                        National ID back
                      </Typography>
                    </Grid>
                    <Grid container item spacing={2}>
                      <Grid item xs={6}>
                        <img src={details.backside_data.image} width={"100%"} alt="nid" />
                      </Grid>

                      {/* Extracted Data */}
                      <Grid item xs={6}>
                        <Grid item xs={12}>
                          <KonanJsonView src={details.backside_data.data} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <KonanEmptyState title="National ID backside unavailable" subTitle="please try again" />
                )}
              </Grid>
            ) : (
              tabValue === "Document Extraction" && (
                <KonanEmptyState title="Document Extraction" subTitle="please try again" />
              )
            )}

            {/* Fraud Detection */}
            {tabValue === "Fraud Detection" &&
              (details.backside_fraud || details.frontside_fraud ? (
                <Grid container spacing={1}>
                  <Grid item xs={12} mt={2}>
                    <Typography variant="h2-bold" gutterBottom>
                      Fraud Detection
                    </Typography>
                  </Grid>

                  <Grid container item spacing={2}>
                    <Grid item xs={6}>
                      {details.frontside_fraud ? (
                        <Fragment>
                          <Grid item xs={12}>
                            <Tag variant={details.frontside_fraud.label === "fraud" ? "negative" : "positive"}>
                              Score: {(100 - details.frontside_fraud.score).toFixed(2)}% (
                              {details.frontside_fraud.label})
                            </Tag>
                          </Grid>

                          <Grid item xs={12} mr={1} mt={1}>
                            <Typography variant="label" gutterBottom>
                              National ID front
                            </Typography>
                          </Grid>

                          <img src={details.frontside_fraud.document_image} width={"100%"} alt="nid" />
                        </Fragment>
                      ) : (
                        <KonanEmptyState title="Frontside fraud unavailable" subTitle="please try again" />
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      {details.backside_fraud ? (
                        <Fragment>
                          <Grid item xs={12}>
                            <Tag variant={details.backside_fraud.label === "fraud" ? "negative" : "positive"}>
                              Score: {(100 - details.backside_fraud.score).toFixed(2)}% ({details.backside_fraud.label})
                            </Tag>
                          </Grid>
                          <Grid item mr={1} mt={1} xs={12}>
                            <Typography variant="label" gutterBottom>
                              National ID BACK
                            </Typography>
                          </Grid>
                          <img src={details.backside_fraud.document_image} width={"100%"} alt="nid" />
                        </Fragment>
                      ) : (
                        <KonanEmptyState title="Backside fraud unavailable" subTitle="please try again" />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <KonanEmptyState title="Fraud detection unavailable" subTitle="please try again later" />
              ))}

            {/* Face Validation */}
            {tabValue === "Face Validation" && details.liveness_detection ? (
              <Grid container spacing={1}>
                <Grid item xs={12} mt={2}>
                  <Typography variant="h2-bold" gutterBottom>
                    Face Validation
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Snackbar
                    variant={
                      details.liveness_detection?.face_match_score &&
                      Math.round(details.liveness_detection.face_match_score) > 40
                        ? "positive"
                        : "negative"
                    }
                    fullWidth
                    description={`Face matching score: ${
                      details.liveness_detection?.face_match_score &&
                      Math.round(details.liveness_detection.face_match_score)
                    }`}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="label" gutterBottom>
                    Selfie photo
                  </Typography>

                  <img src={details.liveness_detection.face_image} width={"100%"} alt="nid" />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="label" gutterBottom>
                    National ID
                  </Typography>

                  <img src={details.liveness_detection.id_image} width={"100%"} alt="nid" />
                </Grid>
              </Grid>
            ) : (
              tabValue === "Face Validation" && (
                <KonanEmptyState title="Face Validation unavailable" subTitle="please try again" />
              )
            )}

            {/* Liveness */}
            {tabValue === "Liveness" && details.liveness_detection ? (
              <Grid container spacing={1}>
                <Grid item xs={12} mt={2}>
                  <Typography variant="h2-bold" gutterBottom>
                    Liveness
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="label" gutterBottom>
                    Selfie photo
                  </Typography>

                  <img src={details.liveness_detection.face_image} width={"100%"} alt="nid" />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="label">Liveness Instructions</Typography>

                  <Grid container mt={1}>
                    {liveness_checks?.map((item, index) => (
                      <Grid container key={item[0]}>
                        <Grid item mr={1}>
                          {item[1] === true ? (
                            <CheckCircleOutlineIcon style={{ color: theme.palette.green.text[2] }} />
                          ) : (
                            <CancelIcon style={{ color: theme.palette.red.text[2] }} />
                          )}
                        </Grid>
                        <Grid item>
                          <Typography variant="p" color={item[1] === true ? "positive" : "negative"} variantColor={2}>
                            {index + 1}. {item[0].replaceAll("_", " ").toLowerCase()}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              tabValue === "Liveness" && <KonanEmptyState title="Liveness unavailable" subTitle="please try again" />
            )}
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  )
}
