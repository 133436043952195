import { useEffect, useMemo, useRef } from "react"

import { DebouncedFunc, debounce } from "lodash"

export const useDebounce = (callback: () => void, timer?: number): DebouncedFunc<() => void> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = (): void => {
      ref.current?.()
    }

    return debounce(func, timer ?? 1000)
  }, [timer])

  return debouncedCallback
}
