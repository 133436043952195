import React, { Fragment, useContext, useEffect, useMemo, useState } from "react"

import { useMutation, useQueries, useQuery, useQueryClient } from "react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import { AddOutlined } from "@mui/icons-material"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Button as MuiButton,
  Skeleton,
  SvgIcon,
} from "@mui/material"
import {
  Button,
  InputChangeEvent,
  InputText,
  Menu,
  MenuItem,
  NotificationUtils,
  RadioButton,
  Tooltip,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import { useFormik } from "formik"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

import { KonanAvatar } from "../../../../../../../components/Avatar"
import { VersionTag } from "../../../../../../../components/VersionTag"
import { DecisionLoadingCard } from "../../../../../../../components/cards/DecisionLoadingCard"
import { BaseSimpleDialog } from "../../../../../../../components/dialogs/BaseSimpleDialog"
import { VersioningDialog } from "../../../../../../../components/dialogs/VersioningDialog"
import { ScorecardEquationDialog } from "../../../../../../../features/Scorecards/ScorecardEquationDialog"
import { getTheme } from "../../../../../../../hooks/UseTheme"
import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../../../store/CurrentProjectAndModelContext"
import { RootState } from "../../../../../../../store/ReduxStore"
import {
  CreateScoreCardRequest,
  CreateScorecardSetRequest,
  CreateWorkflowRequest,
  Operators,
  UpdateScorecardsetRequest,
} from "../../../../../../../types/custom/projects"
import {
  CustomRuleRequest,
  MapValueTypeToFeatureType,
  Rule,
  ScorecardRule,
  SingleRuleCard,
  VersionChangeRequest,
} from "../../../../../../../types/custom/rules"
import { MapWorkflowValueTypeToFeatureType, SchemaFeature } from "../../../../../../../types/custom/workflows"
import { ScoreCardList } from "../../../../../../../types/generated/api/ScoreCardList"
import { ScoreCardRetrieve } from "../../../../../../../types/generated/api/ScoreCardRetrieve"
import { ScoreCardSetCreate } from "../../../../../../../types/generated/api/ScoreCardSetCreate"
import { ScoreCardSetGroupRetrieve } from "../../../../../../../types/generated/api/ScoreCardSetGroupRetrieve"
import { ScoreCardSetList } from "../../../../../../../types/generated/api/ScoreCardSetList"
import { ScoreCardSetRetrieve } from "../../../../../../../types/generated/api/ScoreCardSetRetrieve"
import { WorkflowCreateRequest } from "../../../../../../../types/generated/api/WorkflowCreateRequest"
import { WorkflowSchemaFeature } from "../../../../../../../types/generated/api/WorkflowSchemaFeature"
import { Auth } from "../../../../../../../utils/auth"
import { detectRuleType, getNewRuleInfo, levelSchema } from "../../../../../../../utils/conditionHelpers"
import {
  extractScorecardErrorsMessages,
  getValidMaxMinorVersion,
} from "../../../../../../../utils/deploymentDetailsHelpers"
import {
  convertComplexConditionsToNestedForm,
  convertComplexFormToCondition,
} from "../../../../../../../utils/rulesetHelpers"
import { searchListByName } from "../../../../../../../utils/searchSortFilterHelpers"
import { DataBlock } from "../../Ruleset/components/components/RuleCard"
import { EMPTY_SCORECARD_CONDITION } from "../CONSTANTS"
import { Scorecard } from "./Scorecard"

import styles from "../ScorecardSet.module.scss"

type Props = {
  createScorecardsetMode: boolean
  isLoading: boolean
  allScorecards: ScoreCardList[]
  goToFirstPage: () => void
  handleCancel: () => void
  scorecardset?: ScoreCardSetList
}

// Yup validation schema
const validationSchemaScorecard = Yup.object({
  scorecardSetName: Yup.string().required("Scorecardset name is required"),
  scorecards: Yup.array().of(
    Yup.object({
      name: Yup.string().required("Scorecard name is required"),
      weight: Yup.number().typeError("Numerical value is required"),
      ruleCards: Yup.array().of(
        Yup.object().shape({
          levelOneConditions: Yup.array().of(levelSchema),
          levelTwoConditions: Yup.array().of(levelSchema),
          return_value: Yup.number().typeError("Numeric value is required").required("Rule weight is required"),
        }),
      ),
    }),
  ),
})

export function ScorecardLoadingComponent(): React.ReactElement {
  return (
    <Grid item md={12} container style={{ width: "100%" }}>
      <Card className={styles.loaderScorecard} style={{ width: "100%" }}>
        <CardHeader className={styles.loaderHeader} title={<Skeleton width={200} />} />
        <CardContent className={styles.loaderContent}>
          <Skeleton height={30} />
          <Skeleton height={30} />
        </CardContent>
      </Card>
    </Grid>
  )
}

/**
 * single scorecardset component
 * @param {boolean} createScorecardsetMode current mode for scorecardset
 * @param {boolean} isLoading indicator for scorecardset data loading or not
 * @param {ScoreCardSetList} scorecardset retrieved scorecardset from backend
 * @param {ScoreCardList} allScorecards list contains all scorecardset from backend
 * @param {function} handleCancel handler for opting out of scorecardset creation
 * @param {function} goToFirstPage set current page to 0 after creating scorecardset
 * @returns {React.ReactElement}
 */
export function SingleScorecardset({
  createScorecardsetMode,
  scorecardset,
  handleCancel,
  isLoading,
  goToFirstPage,
  allScorecards,
}: Readonly<Props>): React.ReactElement {
  const { id: projectId } = useParams<{ id: string }>()

  const workflowsList = useSelector((state: RootState) => state?.workflow?.workflows)

  const [isCreateMode, setIsCreateMode] = useState<boolean>(createScorecardsetMode)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openNewCardMenu = Boolean(anchorEl)

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false)

  const [isExplanationDialogOpen, setIsExplanationDialogOpen] = useState<boolean>(false)

  const [anchor2, setAnchor2] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchor2)

  // state to handle scorecards list after using search param
  const [adjustedAllScorecards, setAdjustedAllScorecards] = useState<ScoreCardList[]>(allScorecards ?? [])

  const [isVersionDialogOpen, setIsVersionDialogOpen] = useState<boolean>(false)
  const [selectedScorecards, setSelectedScorecards] = useState<string[]>([])
  const [fetchedScorecards, setFetchedScorecards] = useState<(ScoreCardRetrieve | undefined)[]>([])
  const [shouldQueriesFetch, setShouldQueriesFetch] = useState<boolean>(false)
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined)

  const [calculationMethod, setCalculationMethod] = useState<
    | ScoreCardSetCreate.calculation_method.VALUE_BY_WEIGHT
    | ScoreCardSetCreate.calculation_method.NORMALIZED_VALUE_BY_WEIGHT
  >(scorecardset?.calculation_method ?? ScoreCardSetCreate.calculation_method.NORMALIZED_VALUE_BY_WEIGHT)

  // getting the current user
  const userEmail = Auth.getEmail()
  const theme = getTheme()

  // indicator for updateMode
  const isUpdateMode = scorecardset && isCreateMode
  const { currentProject } = useContext(CurrentProjectAndModelContext)
  const queryClient = useQueryClient()

  const { isLoading: isScorecardsetLoading, data: scorecardsetData } = useQuery<
    AxiosResponse<ScoreCardSetGroupRetrieve>,
    AxiosError
  >(
    ["scorecardset", scorecardset?.uuid],
    () => KonanAPI.fetchScorecardset(currentProject?.uuid, scorecardset?.uuid as string),
    {
      // Only enable the query if the scorecardset?.uuid is not undefined
      enabled: !!scorecardset?.uuid,
    },
  )

  // create workflow mutation
  const createWorkflowMutation = useMutation<AxiosResponse, AxiosError, CreateWorkflowRequest>(
    KonanAPI.createWorkflow,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["workflows", currentProject?.uuid])
        await queryClient.invalidateQueries(["project", currentProject?.uuid])
      },
    },
  )

  const createScoreCardMutation = useMutation<
    AxiosResponse,
    AxiosError<CreateScoreCardRequest>,
    CreateScoreCardRequest
  >(KonanAPI.createScorecard, {
    mutationKey: "createScorecard",
    onError: async (response: AxiosError<CreateScoreCardRequest>) => {
      const errorMessage = extractScorecardErrorsMessages(response?.response?.data, "create")
      NotificationUtils.toast(errorMessage, {
        snackBarVariant: "negative",
      })
    },
  })

  const createScorecardSetsMutation = useMutation<
    AxiosResponse,
    AxiosError<CreateScorecardSetRequest>,
    CreateScorecardSetRequest
  >(KonanAPI.createScorecardSet, {
    mutationKey: "createScorecardset",
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(["scorecardsets", currentProject?.uuid])
      await queryClient.invalidateQueries(["scorecardset", response?.data?.uuid])
      await queryClient.invalidateQueries(["scorecards", currentProject?.uuid])
      // closing the scorecardset view upon creation
      handleCancel()
      NotificationUtils.toast("Scorecardset successfully created!", {
        snackBarVariant: "positive",
      })
      setIsCreateMode(false)
      // Redirect to first page after new scorecardset gets created!
      goToFirstPage?.()
    },
    onError: async (response: AxiosError<CreateScorecardSetRequest>) => {
      const errorMessage = extractScorecardErrorsMessages(response?.response?.data, "create")
      NotificationUtils.toast(errorMessage, {
        snackBarVariant: "negative",
      })
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateScorecardsetMutation = useMutation<AxiosResponse, AxiosError<any>, UpdateScorecardsetRequest>(
    KonanAPI.updateScorecardset,
    {
      mutationKey: "updateScorecardset",
      onSuccess: async (response) => {
        await queryClient.invalidateQueries(["scorecardsets", currentProject?.uuid])
        await queryClient.invalidateQueries(["scorecardset", response?.data?.uuid])
        await queryClient.invalidateQueries(["scorecards", currentProject?.uuid])
        await queryClient.invalidateQueries(["scorecard", response?.data?.uuid])

        NotificationUtils.toast("Scorecardset successfully updated!", {
          snackBarVariant: "positive",
        })
        setIsCreateMode(false)
      },
      onError: async (response: AxiosError<UpdateScorecardsetRequest>) => {
        const errorMessage = extractScorecardErrorsMessages(response?.response?.data, "update")
        NotificationUtils.toast(errorMessage, {
          snackBarVariant: "negative",
        })
      },
    },
  )

  // change scorecardset version
  const scorecardsetChangeMutation = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
    KonanAPI.changeResourceVersion,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["scorecardsets", currentProject?.uuid])
        await queryClient.invalidateQueries(["scorecardset", scorecardset?.uuid])
        await queryClient.invalidateQueries(["scorecards", currentProject?.uuid])
      },
    },
  )

  // scorecardset deletion Mutation
  const deleteScoreCardSetMutation = useMutation<
    AxiosResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    { project_uuid: string; resource_uuid: string }
  >((props) => KonanAPI.deleteWorkflowResource({ ...props, resource_type: "scorecardsets" }), {
    onSuccess: async () => {
      NotificationUtils.toast("Scorecard deleted successfully", { snackBarVariant: "positive" })

      await queryClient.invalidateQueries(["scorecardsets", currentProject?.uuid])
      await queryClient.invalidateQueries(["scorecardset", scorecardset?.uuid])
      await queryClient.invalidateQueries(["scorecards", currentProject?.uuid])
    },
    onError: ({ response }) => {
      NotificationUtils.toast(response?.data?.details ?? "Scorecard deletion failed", { snackBarVariant: "negative" })
    },
  })

  // fetch all selected scorecards and then update the fetchedScorecards state
  const scorecardQueries = useQueries(
    selectedScorecards.map((uuid) => ({
      queryKey: ["selected-scorecard", uuid],
      queryFn: () => KonanAPI.fetchScorecard(currentProject?.uuid, uuid),
      enabled: shouldQueriesFetch && !!currentProject?.uuid && !!uuid,
      onSuccess: (data: AxiosResponse) => {
        // Update the fetchedScorecards state with the data of the completed query
        setFetchedScorecards([data?.data])
      },
    })),
  )

  // Memoize active version to render it in the card
  const activeScorecardsetVersion = useMemo(() => {
    if (scorecardsetData?.data && scorecardsetData?.data?.versions) {
      for (const version of scorecardsetData?.data?.versions ?? []) {
        if (version.is_active_version) {
          return version
        }
      }
    }
  }, [scorecardsetData])

  // memoized scorecards for each scorecardset
  const scorecards = useMemo(() => {
    const activeVersion = scorecardsetData?.data?.versions?.find(
      (version) => version?.version === (scorecardset?.version ?? 0),
    )
    return activeVersion?.scorecards?.sort((a, b) => Number(a?.created_at) - Number(b?.created_at))
  }, [scorecardset, scorecardsetData])

  // form control
  const formik = useFormik({
    validationSchema: validationSchemaScorecard,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues:
      isCreateMode && !isUpdateMode
        ? {
            scorecardSetName: "",
            scorecards: [
              {
                name: "",
                weight: undefined,
                ruleCards: [
                  {
                    id: `new-${uuidv4()}`,
                    type: "simple",
                    ruleName: "",
                    levelOneConditions: [EMPTY_SCORECARD_CONDITION],
                    levelTwoConditions: [],
                    return_value: "",
                    condition_list_file: { name: "", uuid: "" },

                    schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
                  },
                ],
                uuid: "",
              },
            ],
          }
        : {
            scorecardSetName: "",
            scorecards: [],
          },
    onSubmit: async ({ scorecards, scorecardSetName }) => {
      const trimmedScorecardsetName = scorecardSetName?.trim()

      // Check if every Scorecard has a weight
      const allScorecardsHaveWeight = scorecards.every(
        (scorecard) => scorecard.weight !== undefined && scorecard.weight !== "",
      )

      // check if some scorecards have weights
      const someScorecardsHaveWeight = scorecards.some(
        (scorecard) => scorecard.weight !== undefined && scorecard.weight !== "",
      )

      // check if some scorecards have invalid weights
      const someScorecardsHaveInvalidWeight = scorecards.some(
        (scorecard) => scorecard.weight !== undefined && scorecard?.weight > 999,
      )

      // Calculate the sum of all weights
      const totalWeight = scorecards.reduce((sum, scorecard) => sum + (parseFloat(scorecard?.weight ?? "0") || 0), 0)

      if (someScorecardsHaveWeight && !allScorecardsHaveWeight) {
        NotificationUtils.toast(
          <Fragment>
            Weight: Providing partial weights is not allowed. <br /> Either provide a weight value for all scorecards or
            none of them!
          </Fragment>,
          {
            snackBarVariant: "negative",
          },
        )
      } else if (someScorecardsHaveInvalidWeight) {
        NotificationUtils.toast(
          "Weight: Please ensure that there are no more than 3 digits before the decimal point!",
          {
            snackBarVariant: "negative",
          },
        )
      }

      // Check if the sum of all weights is less than 100
      else if (allScorecardsHaveWeight && Math.round(totalWeight) < 100) {
        // Display a message
        NotificationUtils.toast("Sum of all weights less than 100!", {
          snackBarVariant: "negative",
        })
      } else {
        // Filter the scorecards to only include scorecards without a uuid field
        const newScorecards = scorecards.filter((scorecard) => !scorecard.uuid)

        const featuresArray: Array<SchemaFeature> = []

        // Map over the newScorecards and create a mutation for each one
        const scorecardsPromises = newScorecards.map((scorecard) => {
          const finalRulesFormat: CustomRuleRequest[] = []

          scorecard.ruleCards.forEach((ruleCard: ScorecardRule): void => {
            const multiValuesOperators = [
              Operators["in"],
              Operators["not in"],
              Operators["contains"],
              Operators["not contains"],
            ]

            if (ruleCard.levelOneConditions && ruleCard.levelOneConditions.length > 0) {
              ruleCard.levelOneConditions.forEach((item: Rule) => {
                if (!featuresArray.find((elm) => elm?.name === item?.feature)) {
                  featuresArray.push({
                    name: item?.feature,
                    is_required: true,
                    source: "workflow",
                    type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
                  })
                }

                item.andOr = ruleCard.levelOneConditions[0]?.andOr ?? "and"
                if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
                  item.type = item.operator
                }
              })
            }

            if (ruleCard.levelTwoConditions && ruleCard.levelTwoConditions.length > 0) {
              ruleCard.levelTwoConditions.forEach((item: Rule) => {
                if (!featuresArray.find((elm) => elm?.name === item?.feature)) {
                  featuresArray.push({
                    name: item?.feature,
                    is_required: true,
                    source: "workflow",
                    type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
                  })
                }

                item.andOr = ruleCard.levelTwoConditions[0]?.andOr ?? "and"
                if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
                  item.type = item.operator
                }
              })
            }

            const newRule = ruleCard?.condition_list_file?.uuid
              ? {
                  name: `rule-${uuidv4()}`,
                  condition: convertComplexFormToCondition(
                    ruleCard.levelOneConditions,
                    ruleCard.levelTwoConditions,
                    [],
                  ),
                  return_value: ruleCard?.return_value,
                  condition_list_file: ruleCard?.condition_list_file?.uuid,
                }
              : {
                  name: `rule-${uuidv4()}`,
                  condition: convertComplexFormToCondition(
                    ruleCard.levelOneConditions,
                    ruleCard.levelTwoConditions,
                    [],
                  ),
                  return_value: ruleCard?.return_value,
                }

            finalRulesFormat.push(newRule)
          })

          return createScoreCardMutation.mutateAsync({
            // Pass the data of the current Scorecard to the mutation
            name: scorecard.name?.trim(),
            projectUUID: currentProject?.uuid,
            rules: [...finalRulesFormat],
          })
        })

        // Wait for all the mutations to complete
        const results = await Promise.all(scorecardsPromises)

        // Create a new array of objects representing the Scorecards with their UUIDs and weights
        const scorecardsWithUuidsAndWeights = scorecards.map((scorecard) => {
          let uuid
          if (scorecard.uuid) {
            uuid = scorecard.uuid
          } else {
            // IMPORTANT: scorecard name here must be trimmed to compare it with the retrieved name
            uuid = results.find((result) => result?.data?.name === scorecard.name?.trim())?.data?.uuid
          }
          const scorecardSetObject: { uuid: string; weight?: string } = { uuid }

          // Return an object representing the current Scorecard with its UUID and weight
          if (scorecard.weight) {
            scorecardSetObject["weight"] = scorecard.weight
            return scorecardSetObject
          }
          return scorecardSetObject
        })
        if (isUpdateMode) {
          try {
            await updateScorecardsetMutation.mutateAsync({
              projectUUID: currentProject?.uuid,
              scorecardsetUUID: scorecardset?.uuid ?? "",
              name: trimmedScorecardsetName === scorecardset?.name ? undefined : trimmedScorecardsetName,
              calculation_method: calculationMethod,
              scorecards: [...scorecardsWithUuidsAndWeights],
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (e: any) {
            if (e.response?.data) {
              formik.setErrors(e.response.data)
            }
          }
        } else {
          if (workflowsList?.length === 0) {
            const allWorkflowRules = getNewRuleInfo(scorecards, "global")?.nonEmptyFeatures
            const featuresArray: Array<SchemaFeature> = []

            allWorkflowRules?.forEach((rule) => {
              featuresArray.push({
                name: rule?.feature,
                is_required: true,
                source: "workflow",
                type: MapWorkflowValueTypeToFeatureType[rule?.type as keyof typeof MapWorkflowValueTypeToFeatureType],
              })
            })

            if (workflowsList?.length === 0 && featuresArray?.length > 0) {
              await createWorkflowMutation.mutateAsync({
                name: "Untitled-1",
                state: WorkflowCreateRequest.state.DISABLED,
                projectUUID: currentProject?.uuid as string,
                schema: [...featuresArray],
              })
            }
          }

          try {
            await createScorecardSetsMutation.mutateAsync({
              projectUUID: currentProject?.uuid,
              calculation_method: calculationMethod,
              name: trimmedScorecardsetName,
              scorecards: [...scorecardsWithUuidsAndWeights],
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (e: any) {
            if (e.response?.data) {
              formik.setErrors(e.response.data)
            }
          }
        }
      }
    },
  })

  const handleAddScorecard = (): void => {
    formik.setFieldError("scorecardSetName", undefined)

    formik.setFieldTouched("scorecardSetName", false)

    formik.setFieldValue("scorecards", [
      ...formik.values.scorecards,
      {
        name: "",
        type: "string",
        ruleCards: [
          {
            id: `new-${uuidv4()}`,
            type: "simple",
            ruleName: "",
            levelOneConditions: [EMPTY_SCORECARD_CONDITION],
            levelTwoConditions: [],
            return_value: "",
            condition_list_file: { name: "", uuid: "" },

            schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
          },
        ],
      },
    ])

    setAnchorEl(null)
  }

  const handleRemoveScorecard = (scorecardIndex: number, uuid?: string): void => {
    if (uuid && selectedScorecards.includes(uuid)) {
      setSelectedScorecards(selectedScorecards.filter((id) => id !== uuid))
      // Remove the corresponding scorecard from the fetchedScorecards array
      setFetchedScorecards(fetchedScorecards.filter((scorecard) => scorecard?.uuid !== uuid))
    }
    // Update the scorecards array by removing the object representing the Scorecard at the given index
    formik.setFieldValue(
      "scorecards",
      formik.values.scorecards.filter((_, index) => index !== scorecardIndex),
    )
  }

  const handleSelectingScorecards = (uuid: string): void => {
    setSelectedScorecards([...selectedScorecards, uuid])
    // Set shouldFetch to true to trigger the queries
    setShouldQueriesFetch(true)

    setAnchorEl(null)
  }

  // onChange handler in textInput for searching inside menu options for scorecard names
  const onSearchValueChange = (value: string): ScoreCardList[] | void => {
    if (allScorecards && value) {
      const list = searchListByName(allScorecards, value)
      setAdjustedAllScorecards(list)
    } else if (!value && allScorecards) {
      setAdjustedAllScorecards(allScorecards)
    }
  }

  const handleCancelButton = (): void => {
    if (isUpdateMode) {
      setIsCreateMode(false)
      //formik?.resetForm()

      setSelectedScorecards([])

      if (scorecardset?.calculation_method) {
        setCalculationMethod(scorecardset?.calculation_method)
      }
    } else {
      handleCancel()
    }
  }

  useEffect(() => {
    if (allScorecards) {
      setAdjustedAllScorecards(allScorecards)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allScorecards])

  /**
   * This effect responsible for adding the fetched scorecard to our formik state controller
   * just to be tracked when user submits the whole scorecardsets, so it's only single state of truth
   * (formik) = controls both empty new scorecards and already existing and fetched scorecards.
   */
  useEffect(() => {
    //first make sure to filter out the already existing scorecards in both fetchedScorecards array
    // and in formik state scorecards array to not add a duplicated cards.
    const newScorecards = fetchedScorecards
      .filter(
        (scorecard) =>
          !formik.values.scorecards.some(
            (formScorecard) => formScorecard.name === scorecard?.name || formScorecard.uuid === scorecard?.uuid,
          ),
      )
      // map over elements and add it to formik with their already existing values
      .map((scorecard, index) => {
        // get the active version of the scorecard
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const activeScorecardVersion = scorecard?.versions?.find((version: any) => version?.is_active_version === true)
        const formikRuleCards: SingleRuleCard[] = []

        const parsedScorecard = {
          name: scorecard?.name,
          weight: undefined,
          uuid: scorecard?.uuid,
          id: `fetched-${uuidv4()}`,
          ruleCards:
            activeScorecardVersion?.rules &&
            activeScorecardVersion?.rules
              ?.sort((a, b) => a?.order - b?.order)
              ?.map((rule) => {
                const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)
                const newRule = {
                  type: detectRuleType(rule?.condition),
                  id: uuidv4(),
                  ruleName: rule?.name,
                  levelOneConditions:
                    convertedRule && convertedRule.length > 0 ? convertedRule[0] : [EMPTY_SCORECARD_CONDITION],
                  levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
                  levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
                  return_value: rule?.return_value,
                  condition_list_file: { name: rule?.condition_list_file?.name, uuid: rule?.condition_list_file?.uuid },
                }
                formikRuleCards?.push(newRule)

                return newRule
              }),
        }

        formik.setFieldValue(`scorecards[${index}].ruleCards`, [...formikRuleCards])

        return parsedScorecard
      })

    // Concatenate the new scorecards with the existing scorecards
    const updatedScorecards = [...formik.values.scorecards, ...newScorecards]

    // Update the scorecards field of the Formik form with the new data
    formik.setFieldValue("scorecards", updatedScorecards)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedScorecards])

  useEffect(() => {
    if (scorecardset) {
      formik.setFieldValue("scorecardSetName", scorecardset?.name)
      const newScorecards = scorecards?.map((scorecard, index) => {
        const formikRuleCards: SingleRuleCard[] = []

        const newScorecard = {
          name: scorecard?.name,
          weight: scorecard?.weight,
          uuid: scorecard?.uuid,
          ruleCards:
            scorecard?.rules &&
            scorecard?.rules
              ?.sort((a, b) => a?.order - b?.order)
              ?.map((rule) => {
                const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)
                const newRule = {
                  type: detectRuleType(rule?.condition),
                  id: uuidv4(),
                  ruleName: rule?.name,
                  levelOneConditions:
                    convertedRule && convertedRule.length > 0 ? convertedRule[0] : [EMPTY_SCORECARD_CONDITION],
                  levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
                  levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
                  return_value: rule?.return_value,
                  condition_list_file: { name: rule?.condition_list_file?.name, uuid: rule?.condition_list_file?.uuid },
                }
                formikRuleCards?.push(newRule)
                return newRule
              }),
        }

        formik.setFieldValue(`scorecards[${index}].ruleCards`, [...formikRuleCards])

        return newScorecard
      })

      if (newScorecards) {
        const updatedScorecards = [...newScorecards]
        // Update the scorecards field of the Formik form with the new data
        formik.setFieldValue("scorecards", updatedScorecards)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateMode, scorecardset, scorecards])

  const changeActiveScorecardsetVersion = async (version: string): Promise<void> => {
    try {
      await scorecardsetChangeMutation.mutateAsync({
        projectUUID: currentProject.uuid,
        resourceUUID: scorecardset?.uuid as string,
        version: version.toString(),
        resource: "scorecardsets",
      })

      NotificationUtils.toast(`Version (${version}) restored.`, {
        snackBarVariant: "positive",
      })
      // Invalidate react-query queries
      await queryClient.invalidateQueries(["scorecards", currentProject?.uuid])
      await queryClient.invalidateQueries(["scorecardsets", currentProject?.uuid])
    } catch (error) {
      NotificationUtils.toast(`Couldn't restore version (${version}). Please try again later`, {
        snackBarVariant: "negative",
      })
    }
  }

  const GetScorecardsetContainer = (): React.ReactElement => {
    const [cachedResults, setCachedResults] = useState<Record<string, string>>({})

    const Version: ScoreCardSetRetrieve | undefined = useMemo((): ScoreCardSetRetrieve | undefined => {
      if (scorecardsetData && selectedVersion) {
        if (!cachedResults[selectedVersion?.split(".")?.[0]]) {
          const result = getValidMaxMinorVersion(
            scorecardsetData.data.versions as ScoreCardSetRetrieve[],
            selectedVersion,
          )
          setCachedResults((prevResults) => ({
            ...prevResults,
            ...result,
          }))
        }

        for (const version of scorecardsetData.data.versions?.sort(
          (a, b) => Number(a?.created_at) - Number(b?.created_at),
        )) {
          if (version.version === selectedVersion) {
            return version
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVersion, scorecardsetData])

    // extracting the current major version
    const majorSelectedVersion = (selectedVersion ?? activeScorecardsetVersion?.version)?.split(".")?.[0]

    // show restore button when the current major version from the selected version/active version existed in our valid versions hashMap
    // and it's not the current active version
    const shouldShowRestoreButton =
      cachedResults[majorSelectedVersion as string] === (selectedVersion ?? activeScorecardsetVersion?.version) &&
      cachedResults[majorSelectedVersion as string] !== activeScorecardsetVersion?.version

    return (
      <Grid container>
        {/* Header */}
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          className={"versioning-dialog-header"}
        >
          <Grid item gap={1} xs={9} display="flex">
            <Typography variant="h2-bold" style={{ textTransform: "inherit", marginRight: "5px" }}>
              {scorecardsetData?.data?.name}
            </Typography>

            <VersionTag
              version={isNaN(Number(Version?.version)) ? undefined : Number(Version?.version) ?? selectedVersion}
              variant={
                Version ? (activeScorecardsetVersion?.version === selectedVersion ? "positive" : "default") : "positive"
              }
            />
          </Grid>

          {shouldShowRestoreButton && (
            <Button
              variant="secondary"
              size="small"
              onClick={() => changeActiveScorecardsetVersion(selectedVersion as string)}
              disabled={scorecardsetChangeMutation.isLoading}
            >
              {scorecardsetChangeMutation.isLoading ? <CircularProgress size={12} /> : "Restore"}
            </Button>
          )}
        </Grid>

        {/* Body */}
        <Grid item xs={12} p={1.5} pt={0}>
          {Version?.scorecards &&
            Version?.scorecards?.length &&
            Version?.scorecards
              ?.sort((a, b) => Number(a?.created_at) - Number(b?.created_at))
              ?.map((scorecard, index) => (
                <Grid container item key={index} justifyContent="flex-start" alignItems="center" spacing={2} pt={1.5}>
                  <Grid item xs={12} container>
                    <Scorecard
                      SCSViewMode="read"
                      scorecardIndex={index}
                      scorecardFormik={formik}
                      scorecard={scorecard}
                      dialogView={true}
                    />
                  </Grid>
                </Grid>
              ))}
        </Grid>
      </Grid>
    )
  }

  const shouldDisableEditButton = Boolean(
    createScorecardSetsMutation.isLoading ||
      isScorecardsetLoading ||
      createScoreCardMutation.isLoading ||
      formik.isSubmitting ||
      updateScorecardsetMutation.isLoading,
  )

  return (
    <Fragment>
      {/* Versioning Dialog*/}
      {isVersionDialogOpen && (
        <VersioningDialog
          isOpen={isVersionDialogOpen}
          onClose={() => setIsVersionDialogOpen(false)}
          versions={scorecardsetData?.data.versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          activeVersion={activeScorecardsetVersion as ScoreCardSetRetrieve}
        >
          <GetScorecardsetContainer />
        </VersioningDialog>
      )}

      {isExplanationDialogOpen && (
        <ScorecardEquationDialog
          setCalculationMethod={setCalculationMethod}
          calculationMethod={calculationMethod}
          open
          onClose={() => setIsExplanationDialogOpen(false)}
          shouldEnableSelectingMethods={Boolean(isCreateMode || isUpdateMode)}
        />
      )}

      {isDeletionDialogOpen && (
        <BaseSimpleDialog
          open={isDeletionDialogOpen}
          name={scorecardset?.name ?? ""}
          onClose={() => setIsDeletionDialogOpen(false)}
          onAccept={() => {
            deleteScoreCardSetMutation.mutateAsync({
              project_uuid: projectId as string,
              resource_uuid: scorecardset?.uuid as string,
            })
          }}
          mode={"scorecardset-deletion"}
          isLoading={deleteScoreCardSetMutation.isLoading}
        />
      )}

      {/* Scorecardset Loader */}
      {isLoading ? (
        <DecisionLoadingCard />
      ) : (
        <Card className="card-box-shadow">
          <CardHeader
            className={styles.scorecardSetHeader}
            title={
              <Grid container direction="column" item xs={12} spacing={1}>
                <Grid item container xs={12} justifyContent="space-between">
                  <Grid item container xs={5}>
                    {scorecardset?.uuid && !isCreateMode ? (
                      <Grid item mr={1} xs={"auto"} sm={"auto"}>
                        <Typography noWrap variant="h3-bold" className={styles.cardTitle}>
                          {scorecardset?.name}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        <InputText
                          hideDescription
                          id="scorecardSetName"
                          placeholder="Name"
                          value={formik.values.scorecardSetName}
                          handleChange={formik.handleChange}
                          error={
                            formik.touched.scorecardSetName &&
                            Boolean(formik.errors.scorecardSetName) &&
                            formik.errors.scorecardSetName
                          }
                          handleBlur={formik.handleBlur}
                          disabled={
                            formik.isSubmitting || Boolean(updateScorecardsetMutation.isLoading && isUpdateMode)
                          }
                          fullWidth
                        />
                      </Grid>
                    )}

                    {!isCreateMode && !isUpdateMode && (activeScorecardsetVersion?.version || selectedVersion) && (
                      <Grid item mt={isCreateMode || isUpdateMode ? 0 : -1}>
                        <VersionTag
                          version={selectedVersion ?? activeScorecardsetVersion?.version}
                          variant={
                            selectedVersion
                              ? selectedVersion === activeScorecardsetVersion?.version
                                ? "positive"
                                : "default"
                              : "positive"
                          }
                        />
                      </Grid>
                    )}
                  </Grid>

                  {!isCreateMode && !isUpdateMode && (
                    <Grid container item xs justifyContent={"flex-end"}>
                      <IconButton
                        aria-label="settings"
                        size="small"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor2(event.currentTarget)}
                      >
                        <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
                      </IconButton>

                      <Menu
                        key="basic-menu"
                        anchorEl={anchor2}
                        open={isMenuOpen}
                        onClose={() => setAnchor2(null)}
                        menuMaxContent
                      >
                        <MenuItem
                          onClick={() => {
                            setIsDeletionDialogOpen(true)
                            setAnchor2(null)
                          }}
                        >
                          <Typography variant="a" color="negative" variantColor={2}>
                            Remove Scorecardset
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </Grid>
                  )}
                </Grid>

                {/* Avatar section */}
                <Grid
                  item
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                  container
                  xs={12}
                  justifyContent="space-between"
                >
                  <Grid
                    sx={{ flexDirection: { xs: "column", md: "row" } }}
                    item
                    container
                    xs={12}
                    justifyContent="space-between"
                  >
                    <Grid container item spacing={1} md={4} alignItems="flex-start" flexWrap={"nowrap"}>
                      <Grid item sx={{ marginTop: "2px" }}>
                        {/* SvgIcon is used to fix square avatars on safari */}
                        <SvgIcon className={styles.modelAvatar}>
                          <KonanAvatar
                            size={24}
                            name={scorecardset?.created_by ? scorecardset?.created_by : userEmail ?? "user"}
                          />
                        </SvgIcon>
                      </Grid>
                      <Grid container item xs={10}>
                        <Grid item mt={scorecardset?.created_at ? 0 : 0.8}>
                          <Typography variant="label" noWrap style={{ width: "fit-content" }}>
                            {scorecardset?.created_by ?? userEmail}
                          </Typography>

                          {scorecardset?.created_at && (
                            <Typography variant="label" noWrap style={{ width: "fit-content" }}>
                              <Tooltip
                                title={format(new Date(scorecardset?.created_at), "dd/MM/yyyy, p")}
                                placement="right"
                              >
                                <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                                  {moment(new Date(scorecardset?.created_at)).fromNow()}
                                </Typography>
                              </Tooltip>
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Action Buttons*/}
                    <Grid item container xs={12} sm={6} sx={{ justifyContent: { xs: "flex-start", md: "flex-end" } }}>
                      {isCreateMode ? (
                        <Grid item display="flex" gap={1}>
                          <Button
                            size="small"
                            disabled={Boolean(
                              isScorecardsetLoading ||
                                createScoreCardMutation.isLoading ||
                                updateScorecardsetMutation.isLoading,
                            )}
                            onClick={handleCancelButton}
                            variant={"secondary"}
                          >
                            Cancel
                          </Button>

                          <Button
                            variant="primary"
                            disabled={Boolean(
                              isScorecardsetLoading ||
                                createScoreCardMutation.isLoading ||
                                updateScorecardsetMutation.isLoading,
                            )}
                            size="small"
                            onClick={formik.submitForm}
                          >
                            {formik.isSubmitting ||
                            createScorecardSetsMutation.isLoading ||
                            updateScorecardsetMutation.isLoading ? (
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                display="flex"
                                className={styles.loadingContainer}
                              >
                                <Grid item margin={"auto"}>
                                  <CircularProgress style={{ margin: "auto", verticalAlign: "top" }} size={14} />
                                </Grid>
                              </Grid>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </Grid>
                      ) : (
                        <Fragment>
                          <Box mr={1} alignSelf="flex-start" mt={-0.3} mb={0.5}>
                            <Button
                              size="small"
                              variant="secondary"
                              disabled={Boolean(
                                createScorecardSetsMutation.isLoading ||
                                  isScorecardsetLoading ||
                                  createScoreCardMutation.isLoading ||
                                  formik.isSubmitting ||
                                  updateScorecardsetMutation.isLoading,
                              )}
                              onClick={() => {
                                setSelectedVersion(activeScorecardsetVersion?.version)
                                setIsVersionDialogOpen(true)
                              }}
                            >
                              Version History
                            </Button>
                          </Box>
                          <Box alignSelf="flex-start" mt={-0.3} mb={0.5}>
                            <Button
                              variant="secondary"
                              disabled={shouldDisableEditButton}
                              size="small"
                              onClick={() => setIsCreateMode(true)}
                            >
                              Edit
                            </Button>
                          </Box>
                        </Fragment>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container xs={12} display="flex" flexDirection={{ xs: "column", lg: "row" }}>
                  <Grid item xs>
                    <Typography variant="p" display="block" style={{ textTransform: "inherit" }}>
                      Assign weighted numerical scores to applicants based on their features. To be effective, you
                      integrate it into your workflow.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
          {/* Body */}
          <CardContent className={styles.cardContent}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item container display="flex" flexDirection="column">
                <Typography variant="label" tooltip="Calculation method applied for all scorecards">
                  Scorecard calculation
                </Typography>
                <Grid item container display="flex" justifyContent="flex-start">
                  {isCreateMode || isUpdateMode ? (
                    <Grid container item width={{ xs: "fit-content" }} spacing={1} flexWrap="nowrap">
                      <Grid item mr={0.5}>
                        <RadioButton
                          value="Value"
                          onChange={() =>
                            setCalculationMethod(ScoreCardSetCreate.calculation_method.NORMALIZED_VALUE_BY_WEIGHT)
                          }
                          checked={
                            calculationMethod === ScoreCardSetCreate.calculation_method.NORMALIZED_VALUE_BY_WEIGHT
                          }
                          label="Divide by max value"
                          id={`normalized-value-by-weight-${uuidv4()}`}
                          disabled={formik?.isSubmitting}
                        />
                      </Grid>

                      <Grid item mr={0.5}>
                        <RadioButton
                          value="Feature"
                          onChange={() => setCalculationMethod(ScoreCardSetCreate.calculation_method.VALUE_BY_WEIGHT)}
                          checked={calculationMethod === ScoreCardSetCreate.calculation_method.VALUE_BY_WEIGHT}
                          label="Multiply by card weight"
                          id={`Value-by-weight-${uuidv4()}`}
                          disabled={formik?.isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item width="min-content" mt={0.5} mr={1.5}>
                      <DataBlock
                        textVariant="p"
                        value={
                          calculationMethod === ScoreCardSetCreate.calculation_method.VALUE_BY_WEIGHT
                            ? "Multiply by card weight"
                            : "Divide By Max Value"
                        }
                      />
                    </Grid>
                  )}
                  {/* TODO:: replace with SUI anchor component in the revamp
             leaving the styles inline to remember to remove them when revamping */}
                  <Grid
                    display="flex"
                    alignSelf={isCreateMode || isUpdateMode ? "flex-end" : "center"}
                    item
                    ml={1}
                    mt={isCreateMode || isUpdateMode ? "0px" : "4px"}
                    onClick={() => setIsExplanationDialogOpen(true)}
                  >
                    <Link className={styles.explainAnchor} underline="none">
                      Explain
                    </Link>
                  </Grid>
                </Grid>
              </Grid>

              {formik.values.scorecards && formik.values.scorecards?.length > 0 ? (
                formik?.values?.scorecards.map((scorecard, index: number) => (
                  <Grid key={index} container item justifyContent="flex-start" alignItems="center">
                    <Grid item xs={12} container>
                      <Scorecard
                        handleRemoveScorecard={() => handleRemoveScorecard(index, scorecard?.uuid)}
                        SCSViewMode={
                          isCreateMode || isUpdateMode
                            ? formik.values.scorecards[index].uuid
                              ? "weight-edit"
                              : "edit"
                            : "read"
                        }
                        scorecardIndex={index}
                        scorecard={scorecard}
                        scorecardFormik={formik}
                        isScorecardsetInEditMode={isUpdateMode}
                        isScorecardsetLoading={
                          updateScorecardsetMutation.isLoading || createScorecardSetsMutation.isLoading
                        }
                      />
                    </Grid>
                  </Grid>
                ))
              ) : (
                <ScorecardLoadingComponent />
              )}
              {/* Scorecards Loader*/}
              {scorecardQueries.some((query) => query.isLoading) && <ScorecardLoadingComponent />}
            </Grid>

            <Box mt={1} />

            {(isCreateMode || isUpdateMode) && (
              <Grid container justifyContent="flex-start" alignItems="center" mt={2} mb={0.5}>
                <Grid item justifyContent="flex-start">
                  <MuiButton
                    size="small"
                    disabled={Boolean(
                      createScorecardSetsMutation.isLoading ||
                        updateScorecardsetMutation.isLoading ||
                        formik.isSubmitting,
                    )}
                    className={styles.MuiButton}
                    onClick={
                      allScorecards && allScorecards?.length === 0
                        ? handleAddScorecard
                        : (event) => setAnchorEl(event.currentTarget)
                    }
                  >
                    + Add Card
                  </MuiButton>

                  <Menu
                    key="basic-menu1"
                    anchorEl={anchorEl}
                    open={openNewCardMenu}
                    onClose={() => {
                      setAnchorEl(null)
                      setAdjustedAllScorecards(allScorecards ?? [])
                    }}
                    menuMaxContent
                  >
                    <Grid item container justifyContent="center" paddingLeft={1} paddingRight={1}>
                      <Button
                        variant="secondary"
                        startIcon={<AddOutlined fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />}
                        onClick={handleAddScorecard}
                        fullWidth
                      >
                        <Typography variant="a">New Scorecard</Typography>
                      </Button>
                    </Grid>

                    {allScorecards && allScorecards?.length > 0 && (
                      <Grid>
                        <Grid mt={1} paddingLeft={1} paddingRight={1}>
                          <InputText
                            id="scorecards"
                            width={100}
                            fullWidth
                            placeholder={"Search existing scorecards"}
                            key={allScorecards[0]?.name}
                            hideDescription
                            handleChange={(e: InputChangeEvent) => onSearchValueChange(e.target.value as string)}
                          />
                        </Grid>

                        <Grid container direction="column" className={styles.scorecardsOptionsContainer}>
                          {adjustedAllScorecards?.map((scorecard) => {
                            return (
                              <MenuItem
                                key={scorecard?.name}
                                onClick={() => {
                                  if (!selectedScorecards?.includes(scorecard?.uuid)) {
                                    handleSelectingScorecards(scorecard?.uuid)
                                  }
                                }}
                              >
                                <Grid item justifyContent="space-between" container px={0.5}>
                                  <Typography
                                    style={{
                                      marginLeft: "-8px",
                                    }}
                                    disabled={
                                      Boolean(selectedScorecards?.includes(scorecard?.uuid)) ||
                                      Boolean(formik?.values?.scorecards?.find((sc) => sc?.uuid === scorecard?.uuid))
                                    }
                                    variant="p"
                                  >
                                    {scorecard?.name}
                                  </Typography>
                                  {(selectedScorecards?.includes(scorecard?.uuid) ||
                                    formik?.values?.scorecards?.find((sc) => sc?.uuid === scorecard?.uuid)) && (
                                    <CheckOutlinedIcon fontSize="small" style={{ color: "var(--green-text-2)" }} />
                                  )}
                                </Grid>
                              </MenuItem>
                            )
                          })}
                        </Grid>
                      </Grid>
                    )}
                  </Menu>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      )}
    </Fragment>
  )
}
