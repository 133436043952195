import { useContext, useState } from "react"

import { useQuery } from "react-query"
import { useSelector } from "react-redux"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { Grid, Pagination } from "@mui/material"
import { Button } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { KonanEmptyState } from "../components/KonanEmptyState"
import { KonanPageHeader } from "../components/KonanPageHeader"
import { DecisionLoadingCard } from "../components/cards/DecisionLoadingCard"
import { TaglistCard } from "../features/Taglist/TaglistCard"
import { KonanAPI } from "../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../store/CurrentProjectAndModelContext"
import { RootState } from "../store/ReduxStore"
import { PaginatedTagListListList } from "../types/generated/api/PaginatedTagListListList"
import { isPermitted } from "../utils/PermissionsHelpers"
import { Auth } from "../utils/auth"

/**
 * Taglist page
 * @param {*} param0
 * @returns
 */
export function Taglist(): React.ReactElement {
  const permissions = Auth.getPermissions()

  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  // Pagination
  const pageSize = 3
  const [page, setPage] = useState<number>(0)

  const [showTaglistCard, setShowTaglistCard] = useState<boolean>(false)

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const currentUserEmail = Auth.getEmail()

  // fetch taglists
  const { isLoading: isTaglistsLoading, data: taglists } = useQuery<
    AxiosResponse<PaginatedTagListListList>,
    AxiosError
  >(
    ["taglists", currentProject, page, pageSize],
    () => KonanAPI.fetchTagLists(currentProject?.uuid as string, page + 1, pageSize),
    {
      enabled: !!currentProject,
    },
  )

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12} textAlign={"right"}>
        <KonanPageHeader
          title="Taglists"
          actions={
            isPermitted("Add taglist", permissions.konan, flattenedKonanPermissions)
              ? [
                  <Button
                    key="Create_taglist"
                    variant="primary"
                    startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
                    onClick={() => setShowTaglistCard(true)}
                    disabled={showTaglistCard || isTaglistsLoading}
                    size="regular"
                  >
                    Create Taglist
                  </Button>,
                ]
              : undefined
          }
        />
      </Grid>

      {/* Taglists empty state */}
      {!!currentProject && !isTaglistsLoading && !taglists?.data.count && !showTaglistCard && (
        <Grid item xs={12} className={"empty-container"}>
          <KonanEmptyState
            title="No Taglists"
            subTitle="Define conditions with its tags. To be effective, you integrate it into your workflow"
            buttonText={
              isPermitted("Add taglist", permissions.konan, flattenedKonanPermissions) ? "Create Taglist" : undefined
            }
            setAction={setShowTaglistCard}
          />
        </Grid>
      )}

      {!isTaglistsLoading && showTaglistCard && (
        <Grid item xs={12} key={"new"}>
          <TaglistCard
            taglistUUID={"new"}
            createdBy={currentUserEmail ?? ""}
            closeCreateCard={() => setShowTaglistCard(false)}
          />
        </Grid>
      )}

      {/* Loading state */}
      {isTaglistsLoading
        ? [1, 2].map((item: number) => <DecisionLoadingCard key={item} />)
        : taglists?.data.results &&
          taglists?.data.results.map((taglist) => (
            <Grid item xs={12} key={taglist.uuid}>
              <TaglistCard taglistUUID={taglist.uuid} createdBy={taglist.created_by} createdAt={taglist.created_at} />
            </Grid>
          ))}

      {/* Pagination Section */}
      {!isTaglistsLoading && taglists?.data.results && taglists?.data.results.length > 0 && (
        <Grid container item justifyContent="flex-start">
          <Pagination
            count={Math.ceil((taglists.data.count as number) / pageSize)}
            page={page + 1}
            onChange={(_, value: number) => {
              setPage(value - 1)
              window.scrollTo(0, 0)
            }}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  )
}
