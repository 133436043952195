import React from "react"

import { useMutation, useQueryClient } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Button, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { Model } from "../../../../../../../types/generated/api/Model"

type Props = {
  open: boolean
  onClose: () => void
  projectUUID: string
  trainingUUID: string
  trainingOrRetraining: string
  liveModel: Model | undefined
}

export function AbortTrainingJobDialog(props: Props): React.ReactElement {
  const { open, onClose, projectUUID, trainingUUID, trainingOrRetraining, liveModel } = props
  const theme = useTheme()

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()

  const abortTrainingJobMutation = useMutation<AxiosResponse, AxiosError>(() =>
    KonanAPI.deleteTrainingModel(projectUUID, trainingUUID),
  )

  const abortRetrainingJobMutation = useMutation<AxiosResponse, AxiosError>(() =>
    KonanAPI.deleteRetrainingModel(projectUUID),
  )

  const handleAbort = async (): Promise<void> => {
    try {
      if (trainingOrRetraining === "training") {
        await abortTrainingJobMutation.mutateAsync()
        await queryClient.invalidateQueries(["training-jobs", projectUUID])
        onClose()
        // Invalidate react-query queries
      } else if (trainingOrRetraining === "retraining") {
        await abortRetrainingJobMutation.mutateAsync()
        await queryClient.invalidateQueries(["retraining-jobs", liveModel?.uuid])
        onClose()
      }

      navigate({
        pathname: location.pathname,
        search: `tab=failed`,
      })
      NotificationUtils.toast(`Successfully aborted training job`, { snackBarVariant: "positive" })
    } catch (err) {
      NotificationUtils.toast(`an error occurred while aborting training job`, {
        snackBarVariant: "negative",
      })
      onClose()
    }
  }
  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          width: fullScreen ? "100%" : "400px",
        },
      }}
      onClose={(_, reason) => {
        if ((reason === "backdropClick" || reason === "escapeKeyDown") && abortRetrainingJobMutation?.isLoading) {
          return undefined
        } else {
          onClose()
        }
      }}
    >
      <DialogTitle className="dialog-header-base">
        <Typography variant="h2-bold">Abort Training</Typography>
      </DialogTitle>

      <DialogContent className="dialog-content-base">
        <Typography variant="h3-regular" gutterBottom>
          {"Training job is still in progress"}
        </Typography>

        <Typography variant="p">Are you sure you want to abort it?</Typography>
      </DialogContent>

      <DialogActions className="dialog-actions-base">
        <Button onClick={() => onClose()} variant={"secondary"}>
          Cancel
        </Button>
        <Button
          onClick={() => handleAbort()}
          disabled={abortRetrainingJobMutation.isLoading || abortTrainingJobMutation.isLoading}
          variant="dangerous"
        >
          {abortRetrainingJobMutation?.isLoading || abortTrainingJobMutation.isLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            "Abort"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
