import React from "react"

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import BlockIcon from "@mui/icons-material/Block"
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined"
import CodeIcon from "@mui/icons-material/Code"
import FilterListIcon from "@mui/icons-material/FilterList"
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined"
import SubjectIcon from "@mui/icons-material/Subject"
import TagIcon from "@mui/icons-material/Tag"
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined"
import { Card, Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../../hooks/UseTheme"
import { NodeDataBasedOnType } from "../../../screens/ProjectDetails/components/DecisonEngines/components/Workflows/components/SelectionNode"
import { ValueBlock } from "../../ValueBlock"

import styles from "../../../screens/ProjectDetails/components/DecisonEngines/components/Workflows/components/CustomCards.module.scss"

interface Props {
  nodeType:
    | "scorecardset"
    | "ruleset"
    | "taglist"
    | "program"
    | "label"
    | "filter"
    | "calculator"
    | "project"
    | "script"
  data: string
}

export function LabelAuditCard(props: Readonly<Props>): React.ReactElement {
  const { nodeType, data } = props

  const theme = getTheme()

  const getNodesDataBasedOnNodeType = (
    nodeType:
      | "scorecardset"
      | "ruleset"
      | "taglist"
      | "program"
      | "label"
      | "filter"
      | "calculator"
      | "project"
      | "script",
  ): NodeDataBasedOnType => {
    switch (nodeType) {
      case "ruleset":
        return {
          nodeLogo: <RuleOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "RULESET",
          logoClassName: "RulesetCardType",
        }
      case "scorecardset":
        return {
          nodeLogo: <AssignmentOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "SCORECARDSET",
          logoClassName: "ScorecardsetCardType",
        }
      case "program":
        return {
          nodeLogo: <SubjectIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "PROGRAM",
          logoClassName: "ProgramCardType",
        }
      case "filter":
        return {
          nodeLogo: <FilterListIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "FILTER",
          logoClassName: "filterCardType",
        }
      case "calculator":
        return {
          nodeLogo: <CalculateOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "CALCULATOR",
          logoClassName: "calculatorCardType",
        }
      case "project":
        return {
          nodeLogo: <TokenOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "PROJECT",
          logoClassName: "ProjectCardType",
        }
      case "taglist":
        return {
          nodeLogo: <TagIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "TAGLIST",
          logoClassName: "TaglistCardType",
        }
      case "script":
        return {
          nodeLogo: <CodeIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "SCRIPT",
          logoClassName: "ScriptCardType",
        }
      default:
        return {
          nodeLogo: <BlockIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "LABEL",
          logoClassName: "endCardType",
        }
    }
  }

  return (
    <Card className={styles.card} style={{ width: "240px", marginRight: "0px" }}>
      <Grid container>
        {/* Node Logo */}
        <Grid item xs={"auto"}>
          <Grid item className={styles[`${getNodesDataBasedOnNodeType(nodeType)?.logoClassName}`]}>
            {getNodesDataBasedOnNodeType(nodeType)?.nodeLogo}
          </Grid>
        </Grid>

        <Grid
          item
          xs
          flexDirection={"column"}
          paddingLeft={1}
          justifyContent="space-between"
          alignItems="flex-start"
          position={"relative"}
        >
          <Grid item container justifyContent="space-between">
            <Typography variant="label" variantColor={2}>
              {getNodesDataBasedOnNodeType(nodeType)?.nodeTitle}
            </Typography>
          </Grid>

          {/* Node Body*/}
          <Grid item xs={12} container alignItems="flex-start" justifyContent={"space-between"} mt={1}>
            <ValueBlock value={data} size={12} />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
