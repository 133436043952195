import React, { useContext, useState } from "react"

import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { Grid, Pagination } from "@mui/material"
import { Button } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { KonanEmptyState } from "../../../../../../components/KonanEmptyState"
import { KonanPageHeader } from "../../../../../../components/KonanPageHeader"
import { DecisionLoadingCard } from "../../../../../../components/cards/DecisionLoadingCard"
import { KonanAPI } from "../../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../../store/CurrentProjectAndModelContext"
import { Deployment } from "../../../../../../types/generated/api/Deployment"
import { PaginatedProgramList } from "../../../../../../types/generated/api/PaginatedProgramList"
import { PaginatedScoreCardSetListList } from "../../../../../../types/generated/api/PaginatedScoreCardSetListList"
import { ProgramCard } from "./components/ProgramCard"

/**
 * Programs screen
 * @returns {React.ReactElement}
 */
export function Programs(): React.ReactElement {
  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const navigate = useNavigate()

  const [openNewProgram, setOpenNewProgram] = useState<boolean>(false)

  // Pagination
  const pageSize = 3
  const [page, setPage] = useState<number>(0)

  // Fetching projects
  const { isLoading: isProjectsLoading, data: projects } = useQuery<AxiosResponse<Array<Deployment>>, AxiosError>(
    ["projects"],
    () => KonanAPI.fetchProjects(),
  )

  // fetch scorecardsets
  const { isLoading: isScorecardSetsLoading, data: scorecardSetsData } = useQuery<
    AxiosResponse<PaginatedScoreCardSetListList>,
    AxiosError
  >(["scorecardsets", currentProject], () => KonanAPI.fetchScorecardsets(currentProject?.uuid as string), {
    enabled: !!currentProject,
  })

  // check if either models or scorecardsets not empty
  const areModelsOrScorecardsetsExisting = Boolean(
    (projects && projects?.data.length > 0) || scorecardSetsData?.data?.count,
  )

  // fetch programs
  const { isLoading: isProgramsLoading, data: programs } = useQuery<AxiosResponse<PaginatedProgramList>, AxiosError>(
    ["programs", currentProject, page, pageSize],
    () => KonanAPI.fetchPrograms(currentProject.uuid as string, page + 1, pageSize),
    {
      enabled: !!currentProject && areModelsOrScorecardsetsExisting,
    },
  )

  const isBlocksLoading = isProjectsLoading || isProgramsLoading || isScorecardSetsLoading

  // enabling creating programs if these conditions are met
  const enableProgramCreation = !!currentProject && !openNewProgram

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} textAlign={"right"} mb={2}>
        <KonanPageHeader
          title="Programs"
          actions={[
            <Button
              variant="primary"
              startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
              onClick={() => setOpenNewProgram(true)}
              disabled={!enableProgramCreation || isBlocksLoading || !areModelsOrScorecardsetsExisting}
              size="regular"
            >
              Create Program
            </Button>,
          ]}
        />
      </Grid>

      {/* New Program Card */}
      {openNewProgram && (
        <Grid item xs={12} mb={1}>
          <ProgramCard
            projectsData={projects?.data}
            scorecardSetData={scorecardSetsData?.data}
            isScorecardSetsLoading={isScorecardSetsLoading}
            handleCancel={() => setOpenNewProgram(false)}
            isNewProgram={true}
            isProjectsLoading={isProjectsLoading}
          />
        </Grid>
      )}

      {/* Loading state and empty states */}
      <Grid container spacing={2}>
        {isBlocksLoading ? (
          [1, 2].map((item: number) => <DecisionLoadingCard key={item} />)
        ) : programs?.data.results && programs?.data.results?.length > 0 ? (
          programs?.data?.results.map((item) => {
            return (
              <Grid item xs={12} key={item.uuid}>
                <ProgramCard
                  isProjectsLoading={isProjectsLoading}
                  projectsData={projects?.data}
                  scorecardSetData={scorecardSetsData?.data}
                  isScorecardSetsLoading={isScorecardSetsLoading}
                  isLoading={isBlocksLoading}
                  program={item}
                  key={item.uuid}
                />
              </Grid>
            )
          })
        ) : !areModelsOrScorecardsetsExisting ? (
          <Grid item xs={12} className={"empty-container"}>
            <KonanEmptyState
              title="No models or scorecardsets created, yet."
              buttonText="Create a Model"
              setAction={() => navigate(`/projects/${currentProject.uuid}/decision-engines?page=Models`)}
            />
          </Grid>
        ) : (
          enableProgramCreation && (
            <Grid item xs={12} className={"empty-container"}>
              <KonanEmptyState
                title="No programs, yet."
                subTitle="Combine multiple scorecards and AI models with weights. To be effective, you integrate it into your workflow"
                buttonText="Create Program"
                setAction={() => setOpenNewProgram(true)}
              />
            </Grid>
          )
        )}
      </Grid>

      {/* Pagination Section */}
      {!isProjectsLoading && !isProgramsLoading && programs?.data.results && programs?.data.results.length > 0 && (
        <Grid container item justifyContent="flex-start" mt={1}>
          <Pagination
            count={Math.ceil((programs.data.count as number) / pageSize)}
            page={page + 1}
            onChange={(_, value: number) => {
              setPage(value - 1)
              window.scrollTo(0, 0)
            }}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  )
}
