import React, { Fragment, useEffect, useMemo, useState } from "react"

import { Handle, Position, useReactFlow, useStoreApi } from "reactflow"

import * as Yup from "yup"
import CloseIcon from "@mui/icons-material/Close"
import FilterListIcon from "@mui/icons-material/FilterList"
import { Card, Grid, IconButton } from "@mui/material"
import { Button, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { FieldArray, FormikProps, FormikProvider, useFormik } from "formik"
import { v4 as uuidv4 } from "uuid"

import { CustomTooltip } from "../../../../../../../components/UI/CustomTooltip"
import { BaseSimpleDialog } from "../../../../../../../components/dialogs/BaseSimpleDialog"
import { getTheme } from "../../../../../../../hooks/UseTheme"
import { FilterCondition } from "../../../../../../../types/custom/rules"
import { SchemaFeature } from "../../../../../../../types/custom/workflows"
import { ConditionListFile } from "../../../../../../../types/generated/api/ConditionListFile"
import { levelSchema } from "../../../../../../../utils/conditionHelpers"
import { getLayoutedElements, handleRemoveNodeAndEdges } from "../../../../../../../utils/workflowHelpers"
import { EMPTY_FILTER_CONDITION } from "../workflow-fixtures"
import { Condition } from "./Condition"

import styles from "./CustomCards.module.scss"

// Yup validation schema
const validationSchema = Yup.object({
  rules: Yup.array().of(levelSchema),
})

// props for filter nodes
type FilterNodeType = {
  id?: string
  menuView?: boolean
  data?: {
    condition: string
    condition_list_file: ConditionListFile | null
    fileData: ConditionListFile
    readMode: boolean
    editMode: boolean
    feature: string
    operator: string
    type: string
    value: string
    filesCount: number
    secondValue: string
    secondFeature: string
    valueOrFeature: "Value" | "Feature"
    rules: Array<FilterCondition>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>
  }
}

export interface FilterFormikValues {
  rules: Array<FilterCondition>
  filesCount: number
  fileData: ConditionListFile
  condition_list_file: ConditionListFile | null
}

/**
 * Filter node component
 * @param {string} id filter node id
 * @param {boolean} menuView indicator prop wether the view is menu or full canvas
 * @param data all important data for filter nodes (condition and form values)
 * @return {React.ReactElement}
 */
export function FilterNode(props: Readonly<FilterNodeType>): React.ReactElement {
  const { menuView } = props

  const readMode = props?.data?.readMode ?? null
  const editMode = props?.data?.editMode ?? null
  const nodeId = props?.id ?? null

  const theme = getTheme()

  // this is reactflow helper function to delete passed nodes as well as all the connected edges from the passed nodes
  const { deleteElements } = useReactFlow()

  // instance of our state management that share our nodes and edges across all component
  const store = useStoreApi()

  const { setNodes, getNodes, edges } = store.getState()

  const [isConfirmRemoveNodeDialogOpen, setIsConfirmRemoveNodeDialogOpen] = useState<boolean>(false)

  const [shouldReCalculateLayout, setShouldReCalculateLayout] = useState<boolean>(false)

  // formik initial values
  const formik = useFormik({
    validationSchema,
    validateOnMount: true,
    initialValues: {
      rules: props?.data?.rules || [EMPTY_FILTER_CONDITION],
      filesCount: props?.data?.filesCount ?? 0,
      fileData: props?.data?.fileData || { name: "", uuid: "" },
      condition_list_file: props?.data?.condition_list_file || null,
    },

    onSubmit: () => {
      formik?.resetForm()
    },
  })

  // when removing a filter node we call the deleteDescendants function first to get
  // a final list of updated nodes then updated our nodes state with the returned array
  const handleRemoveNode = (): void => {
    handleRemoveNodeAndEdges(nodeId, getNodes(), deleteElements, setNodes)

    setIsConfirmRemoveNodeDialogOpen(false)

    NotificationUtils.toast("Filter node removed!", {
      snackBarVariant: "positive",
    })
  }

  // Trigger layout update only when shouldReCalculateLayout is true
  useEffect(() => {
    if (shouldReCalculateLayout) {
      const { condition_list_file, fileData, filesCount, rules } = formik?.values
      setTimeout(() => {
        getLayoutedElements(
          getNodes().map((node) =>
            node.id === nodeId
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    rules,
                    fileData,
                    filesCount,
                    condition_list_file,
                    formik: props?.data?.formik,
                    rulesValidations: formik?.errors,
                  },
                }
              : node,
          ),
          edges,
        )
      }, 100)
    }
  }, [edges, getNodes, nodeId, props?.data?.formik, shouldReCalculateLayout, formik?.values, formik?.errors])

  const currentSchema = useMemo(() => {
    return !readMode || editMode ? props?.data?.formik?.values?.features : props?.data?.schema?.features
  }, [editMode, props?.data?.formik?.values?.features, props?.data?.schema?.features, readMode])

  const computedFeatureNames = useMemo(() => {
    return props?.data?.formik?.values?.computedFeatures
      ?.filter((item: { uuid: string; value: string }) => item?.value?.length > 0)
      ?.map((feat: { uuid: string; value: string }) => feat?.value)
  }, [props?.data?.formik?.values?.computedFeatures])

  // state contains all features (computed, schema)
  const [allFeatures, setAllFeatures] = useState([
    ...(currentSchema ?? []),
    ...(computedFeatureNames?.length > 0
      ? computedFeatureNames?.map((name: string) => {
          return {
            name,
            type: "NUMBER",
            is_required: false,
            new: false,
            id: uuidv4(),
            source: "workflow",
          }
        })
      : []),
  ])

  // Update allFeatures whenever currentSchema or computedFeatureNames change
  useEffect(() => {
    const computedFeatures =
      computedFeatureNames?.map((name: string) => ({
        name,
        type: "NUMBER",
        is_required: false,
        new: false,
        id: uuidv4(),
        source: "workflow",
      })) || []

    const updatedAllFeatures = [
      ...(currentSchema?.filter((feat: SchemaFeature) => !!feat?.name) ?? []),
      ...(computedFeatures ?? []),
    ]
    setAllFeatures(updatedAllFeatures)
  }, [currentSchema, computedFeatureNames])

  // handler for ensuring layout updates after adding/removing rules from filter
  const handleRulesUpdate = (newRules: Array<FilterCondition>): void => {
    // triggering formik update to ensure re-rendering
    props?.data?.formik?.setFieldValue("renderControl", props?.data?.formik?.values?.renderControl + 1)

    setNodes(
      getNodes().map((node) => {
        const newNode = { ...node }
        if (node.id === nodeId) {
          newNode.data = {
            ...newNode.data,
            rules: newRules,
            // rulesValidations: formik?.errors,
            formik: {
              ...props?.data?.formik,
              values: {
                ...props?.data?.formik?.values,
                renderControl: props?.data?.formik?.values?.renderControl + 1,
              },
            },
          }
        }
        return newNode
      }),
    )

    setShouldReCalculateLayout(true)
  }

  return (
    <Fragment>
      {isConfirmRemoveNodeDialogOpen && (
        <BaseSimpleDialog
          isLoading={false}
          onAccept={handleRemoveNode}
          open
          onClose={() => setIsConfirmRemoveNodeDialogOpen(false)}
          mode="remove-filter-node"
          name=""
        />
      )}
      <Card className={menuView ? styles.card : `${styles.filterCard} disableKeyboardA11y nodrag`}>
        {!menuView && <Handle type="target" position={Position.Top} id="b" isConnectable={true} />}
        <Grid
          display="flex"
          xs={12}
          container
          flexWrap="nowrap"
          justifyContent={menuView ? "space-between" : "flex-start"}
          gap={menuView ? 0.5 : 0}
        >
          <Grid item xs={menuView ? 1.7 : 0.8}>
            <Grid item className={styles.filterCardType}>
              <FilterListIcon sx={{ color: theme.palette.grayscale.text[1] }} />
            </Grid>
          </Grid>
          <Grid
            xs={menuView ? true : 11.2}
            item
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={0.5}
          >
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item xs={11}>
                <Typography variant="label" variantColor={2}>
                  FILTER
                </Typography>
              </Grid>

              {/* Close icon */}
              {((!menuView && editMode) || (!readMode && !editMode && !menuView)) && (
                <Grid item marginRight={"-5px"} justifySelf={"flex-end"}>
                  <IconButton
                    style={{ marginTop: "-15px" }}
                    size="small"
                    onClick={() => setIsConfirmRemoveNodeDialogOpen(true)}
                  >
                    <CloseIcon fontSize="small" className={styles.remove} />
                  </IconButton>
                </Grid>
              )}
            </Grid>

            <FormikProvider value={formik}>
              <FieldArray name="rules">
                {({ remove: removeRule, push: addRule }) => (
                  <Fragment>
                    {formik?.values?.rules?.map((item, index) => (
                      <Condition
                        key={item?.id}
                        isReadMode={Boolean(props?.data?.readMode)}
                        isMenuView={Boolean(menuView)}
                        id={`rules[${index}]`}
                        nodeId={nodeId as string}
                        condition_list_file={props?.data?.condition_list_file as ConditionListFile}
                        schema={currentSchema}
                        allFeatures={allFeatures}
                        isEditMode={Boolean(props?.data?.editMode)}
                        formik={formik}
                        index={index}
                        handleRemoveRule={() => {
                          removeRule(index)

                          setTimeout(() => {
                            handleRulesUpdate(formik?.values?.rules?.filter((_, idx) => idx !== index))
                          }, 100)
                        }}
                        workflowFormik={props?.data?.formik}
                      />
                    ))}

                    {/* Add Rule Button */}
                    {!readMode && !menuView && (
                      <CustomTooltip
                        title={
                          formik?.values?.rules?.length >= Number(window.__RUNTIME_CONFIG__.KONAN_MAX_RULE_NUMBER)
                            ? "You have reached the maximum number of rules"
                            : ""
                        }
                        placement="top"
                      >
                        <Button
                          type="button"
                          variant="ghost"
                          size="large"
                          style={{ marginTop: "8px" }}
                          disabled={Boolean(
                            formik?.values?.rules?.length >= Number(window.__RUNTIME_CONFIG__.KONAN_MAX_RULE_NUMBER),
                          )}
                          onClick={() => {
                            addRule({ ...EMPTY_FILTER_CONDITION })

                            handleRulesUpdate([...formik?.values?.rules, { ...EMPTY_FILTER_CONDITION }])
                          }}
                        >
                          + Add Rule
                        </Button>
                      </CustomTooltip>
                    )}
                  </Fragment>
                )}
              </FieldArray>
            </FormikProvider>
          </Grid>
        </Grid>

        {!menuView && (
          <Fragment>
            <Handle type="source" position={Position.Bottom} id="a" isConnectable={true} />{" "}
            <Handle
              type="source"
              position={Position.Bottom}
              id="b"
              isConnectable={true}
              style={{ marginLeft: "50px" }}
            />
          </Fragment>
        )}
      </Card>
    </Fragment>
  )
}
