import React, { useState } from "react"

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Card, Grid, IconButton, SvgIcon } from "@mui/material"
import { Button, Menu, MenuItem, Skeleton, Tag, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format } from "date-fns"
import moment from "moment"

import { KonanAvatar } from "../../components/Avatar"
import { InfoBlock } from "../../components/containers/InfoBlock"
import { ExportJobList } from "../../types/generated/api/ExportJobList"
import { DataFileProps } from "./interfaces"

import styles from "./DataFiles.module.scss"

enum FileTypeEnum {
  MODEL_PREDICTIONS = "Model",
  DEPLOYMENT_PREDICTIONS = "Live",
  SIMULATION_PREDICTIONS = "Simulation",
}

enum FileTypeColorMap {
  MODEL_PREDICTIONS = "cyan",
  DEPLOYMENT_PREDICTIONS = "pink",
  SIMULATION_PREDICTIONS = "orange",
}

enum StatusColorMap {
  PENDING = "default",
  RUNNING = "warning",
  SUCCEEDED = "positive",
  FAILED = "negative",
  CANCELLED = "crimson",
}

/**
 * DataFile component that wraps the UI for the export jobs
 *
 * @param {ExportJobList} exportJob The export job associated with the data file.
 * @param {function} onDownloadInitiated Callback function called when the download is initiated.
 * @param {Array<object>} [menuOptions] An optional array of menu options.
 * @returns
 */
export function DataFileCard(props: Readonly<DataFileProps>): React.ReactElement {
  const { exportJob, onDownloadInitiated, menuOptions } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <Card className="card-box-shadow">
      <Grid container direction="column" className={styles.card} gap={2}>
        <Grid container item xs={12} justifyContent={"space-between"}>
          <Grid container item xs={10} gap={1}>
            <Grid item>
              <Tag size="small" variant={exportJob?.status && StatusColorMap[exportJob?.status]}>
                {exportJob?.status &&
                  exportJob?.status?.charAt(0)?.toUpperCase() + exportJob?.status?.slice(1)?.toLowerCase()}
              </Tag>
            </Grid>
            <Grid item>
              <Tag size="small" variant={FileTypeColorMap[exportJob.type]}>
                {FileTypeEnum[exportJob.type]}
              </Tag>
            </Grid>
          </Grid>

          {/* Menu  */}
          {menuOptions && menuOptions.length > 0 && (
            <Grid container item xs={2} justifyContent="flex-end" alignItems="flex-start">
              <IconButton
                style={{ marginTop: "-5px" }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                aria-label="settings"
                size="small"
              >
                <MoreHorizIcon sx={{ color: "var(--grayscale-text-2)" }} />
              </IconButton>

              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} menuMaxContent>
                {menuOptions.map((option) => (
                  <MenuItem
                    onClick={() => {
                      option.onClick()
                      setAnchorEl(null)
                    }}
                    key={option.title}
                  >
                    <Typography variant="label" color={option.variant ?? "neutral"} variantColor={2}>
                      {option.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          )}
        </Grid>

        <Grid item container flexWrap="nowrap" xs={12} gap={1} alignItems="center" justifyContent="flex-start">
          <Typography variant="h2-bold" noWrap>
            {exportJob.name}
          </Typography>
        </Grid>

        {/* Avatar  */}
        <Grid container item xs={12} justifyContent="space-between" wrap="nowrap">
          <Grid
            container
            spacing={1}
            item
            alignItems="center"
            display="flex"
            flexWrap="nowrap"
            maxWidth={"calc(100% - 42px)"}
          >
            <Grid item sx={{ marginTop: "2px" }} display="flex" alignItems="center">
              {/* SvgIcon is used to fix square avatars on safari */}
              <SvgIcon className={styles.avatar}>
                <KonanAvatar size={24} name={exportJob.created_by ?? ""} />
              </SvgIcon>
            </Grid>

            <Grid item xs={10}>
              <Grid item xs={12}>
                <Typography variant="label" noWrap>
                  {exportJob.created_by}
                </Typography>
              </Grid>

              <Typography variant="label" noWrap>
                <Tooltip title={format(new Date(exportJob.created_at), "dd/MM/yyyy, p")} placement="right">
                  <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                    Created {moment(new Date(exportJob.created_at)).fromNow()}
                  </Typography>
                </Tooltip>
              </Typography>
            </Grid>
          </Grid>

          {exportJob.status === "SUCCEEDED" && (
            <Grid item display="flex" alignSelf="flex-end" justifySelf="flex-end">
              <Button onClick={() => onDownloadInitiated()} size={"small"} variant="secondary">
                <FileDownloadOutlinedIcon sx={{ color: "var(--grayscale-text-2)", fontSize: "16px" }} />
              </Button>
            </Grid>
          )}
        </Grid>

        {exportJob.status === ExportJobList.status.SUCCEEDED && (
          <Grid item xs={12}>
            <InfoBlock
              text={`${exportJob.requested_predictions_count} / ${exportJob.predictions_count}`}
              title="Predictions (Requested / Actual)"
            />
          </Grid>
        )}
      </Grid>
    </Card>
  )
}

/**
 * Loading card for script card
 * @returns {React.ReactElement}
 */
export function DataFileLoadingCard(): React.ReactElement {
  return (
    <Card className={"card-box-shadow"}>
      <Grid container direction="column" className={styles.card} gap={2}>
        <Grid item xs={3} mb={0.5}>
          <Skeleton variant="rectangular" height={15} width={"20%"} />
        </Grid>

        <Grid item mt={1} xs={12}>
          <Skeleton variant="rectangular" height={15} width={"60%"} />
        </Grid>

        <Grid item xs={12} container flexWrap="nowrap" spacing={1} alignItems="center">
          <Grid item mt={1}>
            <Skeleton variant="circular" width={32} height={32} />
          </Grid>

          <Grid direction="column" container item mt={1}>
            <Grid item xs={6} my={1}>
              <Skeleton variant="rectangular" height={10} width={"80%"} />
            </Grid>
            <Grid item xs={3} mb={0.5}>
              <Skeleton variant="rectangular" height={10} width={"30%"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
