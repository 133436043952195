import { v4 as uuidv4 } from "uuid"

import { CustomRuleRequest, CustomTagRequest, Rule, RuleGroupConditions } from "../types/custom/rules"
import { RulesetRuleList } from "../types/generated/api/RulesetRuleList"
import { WorkflowSchemaFeature } from "../types/generated/api/WorkflowSchemaFeature"
import { ConditionParser, parseConditionIntoString } from "./conditionHelpers"

/**
 * all related helpers for ruleset component.
 */

export const reorderList = (list: RuleGroupConditions[], name: string, direction: string): RuleGroupConditions[] => {
  // Create a new list
  const newList = [...list]
  // Find the index of the object with the specified id
  const index = newList.findIndex((obj) => obj.ruleName === name)
  // Check if the direction is "up"
  if (direction === "up" && index > 0) {
    // Remove the object at the specified index and insert it at the previous index
    newList.splice(index - 1, 0, newList.splice(index, 1)[0])
  }

  // Check if the direction is "down"
  else if (direction === "down" && index < newList.length - 1) {
    // Remove the object at the specified index and insert it at the next index
    newList.splice(index + 1, 0, newList.splice(index, 1)[0])
  }

  return newList
}

// This function converts the 3-level complex form into a string
// for example, it takes three arrays as an input
// arr1 = [{ id: 1, feature: "age", andOr: "and", operator: ">", type: "number", value: "18" },
// { id: 2, feature: "age", andOr: "and", operator: "<", type: "number", value: "65" }]
//
// arr2 = [{ id: 1, feature: "name", andOr: "and", operator: "!=", type: "string", value: "someone" }
//
// arr3 = [
// { id: 1, feature: "name", andOr: "or", operator: "=", type: "null", value: "" },
// { id: 1, feature: "age", andOr: "or", operator: "=", type: "null", value: "" },]
//
// then -> convertRulesToString(arr1, arr2, arr3) -> it should return
// "((age > 18) and (age < 65) and ((name != 'someone') and ((name = null) or (age = null))))"
export const convertComplexFormToCondition = (arr1: Rule[], arr2: Rule[], arr3: Rule[]): string => {
  let result1 = ""
  let result2 = ""
  let result3 = ""
  let finalResult = ""

  arr1.forEach((item: Rule, index: number) => {
    result1 += parseConditionIntoString(item)
    if (arr1.length - 1 > index) {
      result1 += " " + arr1[index + 1].andOr + " "
    } else if (arr2 && arr2.length > 0) {
      result1 += " " + item.andOr + " "
    }
  })

  arr2.forEach((item: Rule, index: number) => {
    result2 += parseConditionIntoString(item)
    if (arr2.length - 1 > index) {
      result2 += " " + arr2[index + 1].andOr + " "
    } else if (arr3 && arr3.length > 0) {
      result2 += " " + item.andOr + " "
    }
  })

  arr3.forEach((item: Rule, index: number) => {
    result3 += parseConditionIntoString(item)
    if (arr3.length - 1 > index) {
      result3 += " " + arr3[index + 1].andOr + " "
    }
  })

  if (result3 !== "") {
    finalResult = "(" + result3 + ")"
  }
  if (result2 !== "") {
    finalResult = "(" + result2 + finalResult + ")"
  }
  if (result1 !== "") {
    finalResult = "(" + result1 + finalResult + ")"
  }

  return finalResult
}

//Get all indices for a specific string
const indexAll = (str: string, search: string): number[] => {
  const indices: number[] = []
  const searchLength = search.length

  for (let i = 0; i <= str.length - searchLength; i++) {
    if (str.slice(i, i + searchLength) === search) {
      indices.push(i)
    }
  }

  return indices
}

// This function uses a loop to iterate over each character in the input string
// str. It uses a variable inside to keep track of whether it is currently inside
// a pair of parentheses or not. If it encounters an opening parenthesis,
// it sets inside to true, if it encounters a closing parenthesis,
// it sets inside to false and pushes the current string to the result array.
//for example, '(age > 18) and (age < 65) and ' -> ["age > 18", "age < 65"]
const extractConditionsFromParentheses = (str: string): string[] => {
  const result = []
  let current = ""
  let inside = false
  for (const element of str) {
    const char = element
    if (char === "(") {
      inside = true
      continue
    }
    if (char === ")") {
      inside = false
      result.push(current)
      current = ""
      continue
    }
    if (!inside) continue
    current += char
  }
  return result
}

//This function is the reversed version of the upper function "convertComplexFormToCondition"
export const convertComplexConditionsToNestedForm = (condition: string): Rule[][] => {
  const parsedLevels: string[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parsedConditions: any = []
  const result: Rule[][] = []
  const startIndices = indexAll(condition, "((")
  const endIndices = indexAll(condition, "))")
  for (let i = 0; i < startIndices.length; i++) {
    const start = i === 0 ? startIndices[i] - 1 : startIndices[i] + 1
    const end = i === startIndices.length - 1 ? endIndices[0] + 1 : startIndices[i + 1]
    const subCondition = condition.substring(i === 0 ? start + 2 : start, end)
    parsedLevels.push(subCondition)
  }

  parsedLevels.forEach((item) => {
    parsedConditions.push([item.includes(") or") ? "or" : "and", ...extractConditionsFromParentheses(item)])
  })

  //convert parsed conditions to complex form
  for (const element of parsedConditions) {
    const tempArr: Rule[] = []
    for (let j = 1; j < element.length; j++) {
      const ParseCondition = new ConditionParser(element[j])
      tempArr.push({
        id: uuidv4(),
        feature: ParseCondition.getFeature(),
        andOr: element[0],
        operator: ParseCondition.getOpeartor(),
        type: ParseCondition.getType(),
        valueOrFeature: ParseCondition.getRHSIndicator(),
        value: ParseCondition.getValue(),
        secondValue: ParseCondition.getSecondValue(),
        secondFeature: ParseCondition.getSecondFeature(),
        schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
      })
    }
    result.push(tempArr)
  }

  return result
}

// given 2 rulesList, this function will return true or false based on if these 2 versions are the exact same
// rules/values with the exact same ordering -> (true) or not -> (false)
export function compareTwoRulesList(
  arr1: CustomRuleRequest[] | CustomTagRequest[],
  arr2: RulesetRuleList[] | CustomTagRequest[],
): boolean {
  if (arr1.length !== arr2.length) return false
  for (let i = 0; i < arr1.length; i++) {
    if (
      arr1[i].return_label !== arr2[i].return_label ||
      arr1[i].name !== arr2[i].name ||
      arr1[i].condition !== arr2[i].condition
    ) {
      return false
    }
  }
  return true
}

// checks if the condition type in true, false, null
export function isTypeIncluded(type: string): boolean {
  return ["true", "false", "null"].includes(type)
}
