import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useDropzone } from "react-dropzone"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { useStoreApi } from "reactflow"

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import SearchIcon from "@mui/icons-material/Search"
import { Box, Grid } from "@mui/material"
import {
  Button,
  InputChangeEvent,
  InputText,
  Menu,
  MenuItem,
  NotificationUtils,
  Select,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FormikProps } from "formik"
import { v4 as uuidv4 } from "uuid"

import { RadioButtonsCondition } from "../../../../../../../components/RadioButtonsCondition"
import { UploadFileBlock } from "../../../../../../../components/UploadFileBlock"
import { ValueBlock } from "../../../../../../../components/ValueBlock"
import { useDebounce } from "../../../../../../../hooks/useDebounce"
import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { Operators } from "../../../../../../../types/custom/projects"
import { MapValueTypeToFeatureType } from "../../../../../../../types/custom/rules"
import { MapWorkflowValueTypeToFeatureType, SchemaFeature } from "../../../../../../../types/custom/workflows"
import { ConditionListFile } from "../../../../../../../types/generated/api/ConditionListFile"
import { DataFile } from "../../../../../../../types/generated/api/DataFile"
import {
  handleFeatureChange,
  isMultiValuesOperatorsIncluded,
  multiValuesOperators,
  operatorsBasedOnFeatureType,
} from "../../../../../../../utils/conditionHelpers"
import { dropZoneCSVExtensionValidator } from "../../../../../../../utils/genericHelpers"
import { isTypeIncluded } from "../../../../../../../utils/rulesetHelpers"
import { ArrowAdornment } from "../../Scorecardset/components/Condition"
import { FilterFormikValues } from "./FilterNode"

type Props = {
  isReadMode: boolean
  isEditMode: boolean
  condition?: string
  condition_list_file: ConditionListFile
  formik: FormikProps<FilterFormikValues>
  id: string
  nodeId: string
  isMenuView: boolean
  index: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: any
  handleRemoveRule: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allFeatures: Array<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workflowFormik: any
}

export function Condition(props: Readonly<Props>): React.ReactElement {
  const {
    isEditMode,
    isReadMode,
    condition_list_file,
    formik,
    nodeId,
    isMenuView,
    schema,
    index,
    id,
    workflowFormik,
    handleRemoveRule,
    allFeatures,
  } = props

  // ref for LHS feature
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>()
  // ref for RHS feature
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef2 = useRef<any>()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const andOrRef = useRef<any>()

  // instance of our state management that share our nodes and edges across all component
  const store = useStoreApi()

  const { setNodes, getNodes, edges } = store.getState()

  const { id: projectID } = useParams<{ id: string }>()

  const [operators, setOperators] = useState<Operators[] | string[]>(
    !isReadMode && !isEditMode ? operatorsBasedOnFeatureType.TEXT : operatorsBasedOnFeatureType.UNDEFINED,
  )

  const [types, setTypes] = useState<Array<string>>(["string", "number", "true", "false", "null"])

  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])

  // anchor for LHS feature
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // anchor for RHS feature
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null)

  const [andOrAnchor, setAndOrAnchor] = useState<null | HTMLElement>(null)

  const [selectedOption, setSelectedOption] = useState<string>("")

  // filtered features for LHS feature input
  const [filteredFeatures, setFilteredFeatures] = useState<Array<SchemaFeature>>([])
  // filtered features for RHS feature input
  const [secondFilteredFeatures, setSecondFilteredFeatures] = useState<Array<SchemaFeature>>([])

  const [isFilteredFeaturesSet, setIsFilteredFeaturesSet] = useState(false)

  const [secondSelectedOption, setSecondSelectedOption] = useState<string>("")

  const abortControllerRef = useRef<AbortController | null>(null)

  const queryClient = useQueryClient()

  const {
    isLoading: uploadingCSV,
    mutateAsync: uploadCSVAsyncMutation,
    reset,
  } = useMutation<DataFile, AxiosError, File>(
    (file: File) => {
      abortControllerRef.current = new AbortController()
      return KonanAPI.uploadDataFile({
        project_uuid: projectID as string,
        file: file,
        signal: abortControllerRef.current.signal,
        type: DataFile.type.CONDITION_LIST,
      })
    },
    {
      onSuccess: async (response) => {
        formik.setFieldValue("fileData", { name: response.name, uuid: response.uuid })

        formik.setFieldValue(`condition_list_file`, {
          name: response?.name,
          uuid: response?.uuid,
        })

        formik.setFieldValue("filesCount", 1)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (response: any) => {
        // if upload file fails, we make sure we empty the value block
        formik?.setFieldValue(`rules[${index}].value`, "")

        if (response?.response?.data?.data_file?.length) {
          NotificationUtils.toast(response?.response?.data?.data_file[0], {
            snackBarVariant: "negative",
          })
        } else {
          NotificationUtils.toast("An error occurred while uploading file", {
            snackBarVariant: "negative",
          })
        }
      },
    },
  )

  // handle close dropzone
  const handleCloseAndAbort = useCallback(() => {
    abortControllerRef.current?.abort()
    reset()
    setAcceptedFiles([])
  }, [reset])

  const deleteDataFileMutation = useMutation<AxiosResponse, AxiosError>(
    () => KonanAPI.deleteDataFile(projectID as string, condition_list_file?.uuid ?? formik?.values?.fileData?.uuid),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Data File deleted successfully!", {
          snackBarVariant: "positive",
        })

        formik.setFieldValue(`condition_list_file`, null)
        formik.setFieldValue("fileData", { name: "", uuid: "" })
        formik.setFieldValue("filesCount", 0)

        queryClient.invalidateQueries(["data-files", projectID])
        reset()
        setAcceptedFiles([])
      },
      onError: () => {
        handleCloseAndAbort()
      },
    },
  )

  const { open, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted: (files) => {
      setAcceptedFiles([...acceptedFiles, ...files])
      if (
        !isReadMode &&
        multiValuesOperators.includes(Operators[formik?.values?.rules[index]?.operator as keyof typeof Operators])
      ) {
        formik.setFieldValue(`rules[${index}].value`, "$file")
      }
    },
    accept: {
      "text/*": [".csv"],
    },
    validator: dropZoneCSVExtensionValidator,
  })

  /**
   * inidicator for displaying the file block that contains the file name used in condition
   */
  const shouldDisplayFileBlock = Boolean(
    formik?.values?.rules[index]?.value === "$file" &&
      multiValuesOperators.includes(formik?.values?.rules[index]?.operator) &&
      (isEditMode || !isReadMode),
  )

  // special handler for changing type field and all side effects in specific cases
  const handleTypeChange = (e: InputChangeEvent<Element>): void => {
    formik.setFieldValue(id + `.type`, e.target.value)

    //When selecting true, false, or null from the menu -> set the value field to the selection
    if (isTypeIncluded(e?.target?.value as string)) {
      formik.setFieldValue(id + `.value`, e.target.value)
      setOperators([Operators["!="], Operators["="]])
    }

    // whenever number type is selected, change the operators to include all numerical operators
    if (e.target.value === "number") {
      setOperators(operatorsBasedOnFeatureType.NUMBER)
    }

    if (e.target.value === "string") {
      setOperators(operatorsBasedOnFeatureType.TEXT)
    }

    //When selecting number or string from the menu -> reset the value field
    else if (
      ["string", "number"].includes(e.target.value as string) &&
      !["string", "number"].includes(formik?.values?.rules[index]?.type as string)
    ) {
      if (formik?.values?.rules[index]?.value === "$file") {
        handleDeleteFile(true)
      }

      formik.setFieldValue(id + ".value", "")
    }

    const newFeature = workflowFormik?.values?.features?.find(
      (feat: SchemaFeature) => feat?.name === formik.values?.rules[index]?.feature,
    )

    if (newFeature) {
      // Step 2: Update the type property
      newFeature.type =
        MapWorkflowValueTypeToFeatureType[`${e.target.value}` as keyof typeof MapWorkflowValueTypeToFeatureType]

      // Step 3: Update the features array
      const updatedFeatures = workflowFormik?.values?.features.map((feature: SchemaFeature) =>
        feature.name === formik.values?.rules[index]?.feature ? newFeature : feature,
      )

      // Step 4: Update the Formik object
      workflowFormik.setFieldValue("features", updatedFeatures)
    }
  }

  // special handler for changing operator field and all side effects in specific cases
  const handleOperatorChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    formik.setFieldValue(id + `.operator`, e.target.value)

    // reset the value field when selecting in/not in operators from the menu
    if (
      multiValuesOperators.includes(Operators[e.target.value as keyof typeof Operators]) &&
      !multiValuesOperators.includes(Operators[formik.values?.rules[index]?.operator as keyof typeof Operators])
    ) {
      if (formik.values?.rules[index]?.value === "$file" && formik.values?.fileData?.uuid) {
        handleDeleteFile(true)
      }
      formik.setFieldValue(id + ".value", "")
    }

    if ([Operators.between, ...multiValuesOperators].includes(Operators[e.target.value as keyof typeof Operators])) {
      formik.setFieldValue(id + ".valueOrFeature", "Value")
    }

    if (
      formik.values?.rules[index]?.value === "$file" &&
      formik.values?.fileData?.uuid &&
      !multiValuesOperators.includes(Operators[e.target.value as keyof typeof Operators]) &&
      multiValuesOperators.includes(Operators[formik.values?.rules[index]?.operator as keyof typeof Operators])
    ) {
      handleDeleteFile(true)
    }

    // automatically switch value's type to number when these operators are active
    // -> "greater than", "greater than/equal", "lower than", "lower than/equal", "between"
    if (["greater than", "greater than/equal", "lower than", "lower than/equal", "between"].includes(e.target.value)) {
      formik?.setFieldValue(id + ".type", "number")
    }

    // automatically switch value's type to string when these operators are active
    // -> "in", "not in", "contains", "not contains"
    if (multiValuesOperators.includes(e.target.value)) {
      formik?.setFieldValue(id + ".type", "string")
    }
  }

  // this effect listens to any change happens to any of 5 formik values in filter node
  // and update the node state with the changed values
  // if isReadMode or isEditMode is true (creating or updating workflow)
  useEffect(() => {
    if (!isReadMode || isEditMode) {
      const { rules, condition_list_file, fileData, filesCount } = formik.values
      setTimeout(() => {
        setNodes(
          getNodes().map((node) => {
            const newNode = { ...node }
            if (node.id === nodeId) {
              newNode.data = {
                ...newNode.data,
                rules,
                condition_list_file,
                rulesValidations: formik?.errors,
                fileData,
                filesCount,
              }
            }
            return newNode
          }),
        )
      }, 50)
    }
  }, [isReadMode, formik.values, nodeId, isEditMode, getNodes, edges, setNodes, formik?.errors])

  // handle upload once files are accepted
  const handleUpload = async (): Promise<void> => {
    try {
      await uploadCSVAsyncMutation(acceptedFiles[0])

      // Invalidate training data to trigger a refresh
      await queryClient.invalidateQueries(["data-files", projectID])
    } catch (err) {
      // reset dropzone on error
      handleCloseAndAbort()
    }
  }

  // handle delete data file
  const handleDeleteFile = async (isRemovingFile: boolean): Promise<void> => {
    if (isEditMode) {
      formik.setFieldValue(`rules[${index}]value`, "")

      if (formik?.values?.filesCount === 1) {
        formik.setFieldValue(`fileData`, null)
        formik.setFieldValue(`condition_list_file`, null)
      }

      setAcceptedFiles([])
      formik.setFieldValue(`filesCount`, formik?.values?.filesCount - 1 < 0 ? 0 : formik?.values?.filesCount - 1)
    } else if (formik?.values?.filesCount > 1) {
      formik.setFieldValue("filesCount", formik?.values?.filesCount - 1 < 0 ? 0 : formik?.values?.filesCount - 1)
      formik.setFieldValue(`rules[${index}].value`, "")
    } else {
      try {
        await deleteDataFileMutation.mutateAsync()

        if (isRemovingFile) {
          formik.setFieldValue(`rules[${index}].value`, "")
        }
      } catch {
        handleCloseAndAbort()
      }
    }
  }

  // starts uploading once the file is read
  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      handleUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  // return type of value input field based on conditions
  const getValueFieldType = (): string => {
    const type =
      (formik.values?.rules[index]?.operator === "not equal" && formik.values?.rules[index]?.type !== "number") ||
      (formik.values?.rules[index]?.operator === "equal" && formik.values?.rules[index]?.type !== "number") ||
      isMultiValuesOperatorsIncluded(formik.values?.rules[index]?.operator)
        ? "text"
        : "number"

    return type
  }

  // return value field styles based on conditions
  const getValueFieldStyles = (): undefined | { display: string } => {
    const styles =
      formik.values?.rules[index]?.type === "string" ||
      formik.values?.rules[index]?.type === "number" ||
      isMenuView ||
      (isTypeIncluded(formik.values?.rules[index]?.type as string) &&
        formik.values?.rules[index]?.valueOrFeature === "Feature") ||
      isMultiValuesOperatorsIncluded(formik.values?.rules[index]?.operator)
        ? undefined
        : { display: "none" }

    return styles
  }

  // return the size of the type field based on conditions
  const getTypeFieldSize = (): number | boolean => {
    const SMALL_SIZE = 3.5
    const BIG_SIZE = true

    const isValuesMatching = (): boolean => {
      const typeIncluded = isTypeIncluded(formik?.values?.rules[index]?.type as string)
      return typeIncluded && formik?.values?.rules[index]?.type === formik.values?.rules[index]?.type
    }

    const typeSize =
      isValuesMatching() ||
      (formik.values?.rules[index]?.type !== "string" && formik.values?.rules[index]?.type !== "number")
        ? BIG_SIZE
        : SMALL_SIZE

    return typeSize
  }

  const shouldEnableUpdateFilteredFeatures =
    (!isFilteredFeaturesSet || formik.values?.rules[index]?.feature === "") && (isEditMode || !isReadMode)

  // update filteredFeatures whenever input is empty or hasn't changed (input = returned value)
  // or workflow mode = edit/create
  useEffect(() => {
    if (shouldEnableUpdateFilteredFeatures) {
      setFilteredFeatures([...(allFeatures ?? [])])
      setSecondFilteredFeatures([...(allFeatures ?? [])])
      if ([...allFeatures]?.length > 0) {
        setIsFilteredFeaturesSet(true)
      }
    }
  }, [allFeatures, shouldEnableUpdateFilteredFeatures])

  const currentFeature = useMemo(() => {
    const feature = formik.values?.rules[index]?.feature
    if (filteredFeatures?.length > 0 && feature) {
      const current = filteredFeatures?.find((feat) => feat?.name === feature)
      return current
    }
  }, [formik.values?.rules, index, filteredFeatures])

  const debouncedOnChangeLHS = useDebounce((): void => {
    const { condition_list_file, rules } = formik.values

    const { feature, operator, value, type, secondValue, valueOrFeature, secondFeature } = rules[index]

    const conditionObject = {
      feature,
      operator,
      value,
      type,
      secondValue,
      valueOrFeature,
      secondFeature,
      condition_list_file,
    }

    const updatedConditionParts = handleFeatureChange(allFeatures, feature, conditionObject)
    setFilteredFeatures(updatedConditionParts?.filteredFeatures)
    setSecondFilteredFeatures(updatedConditionParts?.otherFilteredFeatures)
    setOperators(updatedConditionParts?.operators)
    formik.setFieldValue(id + ".operator", updatedConditionParts?.operator)

    setTypes(updatedConditionParts?.types)

    formik.setFieldValue(id + ".type", updatedConditionParts?.type)

    formik.setFieldValue(id + ".value", updatedConditionParts?.value)

    setAnchorEl(inputRef?.current)
    setTimeout(() => {
      inputRef?.current?.focus()
    }, 100)
  }, 400)

  const debouncedOnChangeRHS = useDebounce((): void => {
    const { condition_list_file, rules } = formik.values

    const { feature, operator, value, type, secondValue, valueOrFeature, secondFeature } = rules[index]

    const conditionObject = {
      feature,
      operator,
      value,
      type,
      secondValue,
      valueOrFeature,
      secondFeature,
      condition_list_file,
    }

    const updatedConditionParts = handleFeatureChange(allFeatures, secondFeature as string, conditionObject)
    setFilteredFeatures(updatedConditionParts?.otherFilteredFeatures)
    setSecondFilteredFeatures(updatedConditionParts?.filteredFeatures)
    setOperators(updatedConditionParts?.operators)
    formik.setFieldValue(id + ".operator", updatedConditionParts?.operator)

    setTypes(updatedConditionParts?.types)

    formik.setFieldValue(id + ".type", updatedConditionParts?.type)

    formik.setFieldValue(id + ".value", updatedConditionParts?.value)

    setAnchorEl2(inputRef2?.current)
    setTimeout(() => {
      inputRef2?.current?.focus()
    }, 100)
  }, 400)

  const handleFeatureSelectionFromMenu = (item: SchemaFeature, featureIndicator: "LHS" | "RHS"): void => {
    const { condition_list_file, rules } = formik.values

    const { feature, operator, value, type, secondValue, valueOrFeature, secondFeature } = rules[index]

    const conditionObject = {
      feature,
      operator,
      value,
      type,
      secondValue,
      valueOrFeature,
      secondFeature,
      condition_list_file,
    }

    const updatedConditionParts = handleFeatureChange(allFeatures, item?.name, conditionObject)
    setFilteredFeatures(updatedConditionParts?.filteredFeatures)
    setSecondFilteredFeatures(updatedConditionParts?.otherFilteredFeatures)
    setOperators(updatedConditionParts?.operators)
    formik.setFieldValue(id + ".operator", updatedConditionParts?.operator)

    setTypes(updatedConditionParts?.types)

    formik.setFieldValue(id + ".type", updatedConditionParts?.type)

    formik.setFieldValue(id + ".value", updatedConditionParts?.value)

    if (featureIndicator === "LHS") {
      setSelectedOption(item?.name)
      setFilteredFeatures(updatedConditionParts?.filteredFeatures)
      setSecondFilteredFeatures(updatedConditionParts?.otherFilteredFeatures)
    } else {
      setSecondSelectedOption(item?.name)
      setFilteredFeatures(updatedConditionParts?.otherFilteredFeatures)
      setSecondFilteredFeatures(updatedConditionParts?.filteredFeatures)
    }
  }

  const shouldDisplayTypeBlock =
    // 1. not menu view
    !isMenuView &&
    // 2. RHS = value not a feature
    formik.values?.rules[index]?.valueOrFeature === "Value" &&
    // 3. no current feature or current feature type is undefined or type is a boolen/null or it's a new feature
    (!currentFeature ||
      schema?.features?.find((feat: SchemaFeature) => feat?.name === currentFeature?.name)?.type === "UNDEFINED" ||
      currentFeature?.type === "BOOLEAN" ||
      isTypeIncluded(formik?.values?.rules[index]?.type as string) ||
      currentFeature?.new) &&
    // 4. edit mode or not read mode or read mode but the current type isn't string or number
    (!isReadMode ||
      isEditMode ||
      (isReadMode && !["string", "number"].includes(formik?.values?.rules[index]?.type as string)))

  // return value block in the value field based on conditions
  const getValueFieldBlockValue = (): string => {
    return formik?.values?.rules[index]?.value === "$file"
      ? formik?.values?.condition_list_file?.name ?? condition_list_file?.name
      : formik.values?.rules[index]?.value
  }

  // handling if this file is first one so we upload or just reference the existing file
  const handleClickOnUploadIcon = (): void => {
    if ((!formik?.values?.fileData?.name && !formik?.values?.fileData?.uuid) || formik?.values?.filesCount < 1) {
      open()
    } else {
      formik.setFieldValue(`rules[${index}].value`, "$file")

      // TODO:: change this later to increment upon accepting the selected file not just on icon click
      formik.setFieldValue(`filesCount`, formik?.values?.filesCount + 1)
    }
  }

  return (
    <Fragment>
      {!isMenuView && index > 0 && (
        <Grid
          container
          item
          display="flex"
          gap={1}
          xs
          alignItems="center"
          margin={!isReadMode ? "8px 0px" : "4px 0px 0px 0px"}
        >
          <Grid
            item
            onClick={
              !isReadMode && index === 1 ? () => setAndOrAnchor(andOrAnchor ? null : andOrRef?.current) : undefined
            }
            style={{ cursor: "pointer" }}
          >
            <Grid container item xs="auto" display="flex" gap="6px" justifyContent="space-between">
              <Typography
                ref={andOrRef}
                color={index === 1 && !isReadMode ? "indigo" : "neutral"}
                variant="h3-bold"
                variantColor={2}
              >
                {formik?.values?.rules[0]?.andOr}
              </Typography>

              {index === 1 && !isReadMode && (
                <ArrowAdornment
                  onClick={() => setAndOrAnchor(andOrAnchor ? null : andOrRef?.current)}
                  anchorEl={andOrAnchor}
                  fill="var(--neutral-text-active)"
                  style={{ marginRight: "2px" }}
                />
              )}
            </Grid>
          </Grid>

          <Grid item xs>
            <hr style={{ border: "1px solid var(--neutral-border-default)", width: "100%" }} />
          </Grid>

          <Menu anchorEl={andOrAnchor} open={Boolean(andOrAnchor)} onClose={() => setAndOrAnchor(null)} menuMaxContent>
            {["and", "or"]?.map((item) => (
              <MenuItem
                isSelected={item === formik?.values?.rules[0]?.andOr}
                onClick={() => {
                  formik?.setFieldValue(`rules[${0}].andOr`, item)
                  setAndOrAnchor(null)
                }}
                key={item}
              >
                {item}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}

      <Grid container display="flex" gap={0.7} justifyContent="space-between">
        <Grid
          flexWrap="wrap"
          item
          display="flex"
          xs={index === 0 || isMenuView || isReadMode ? 12 : 11.2}
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          paddingTop={isMenuView ? "0px" : isReadMode ? "5px" : "-4px"}
          gap={1}
        >
          <Grid item xs={12} container display="flex" gap={1}>
            {/* Feature */}
            <Grid
              item
              xs={5.9}
              display="flex"
              className="nowheel"
              alignSelf={isReadMode && !isEditMode ? "flex-start" : "flex-end"}
            >
              {!isReadMode || isEditMode ? (
                <Fragment>
                  <InputText
                    hideDescription
                    anchoringRef={inputRef}
                    key={`${selectedOption}-${allFeatures?.length}`}
                    startAdornment={
                      allFeatures?.length > 0 ? (
                        <SearchIcon fontSize="small" sx={{ color: "var(--grayscale-text-2)" }} />
                      ) : undefined
                    }
                    id={id + ".feature"}
                    placeholder="Feature"
                    value={formik.values?.rules[index]?.feature}
                    handleChange={(e) => {
                      formik?.handleChange(e)
                      debouncedOnChangeLHS()
                    }}
                    handleBlur={formik.handleBlur}
                    disabled={formik.isSubmitting || isMenuView}
                    type="text"
                    endAdornment={
                      allFeatures?.length > 0 ? (
                        <ArrowAdornment
                          anchorEl={anchorEl}
                          onClick={() => setAnchorEl(anchorEl ? null : inputRef?.current)}
                        />
                      ) : undefined
                    }
                    fullWidth
                  />

                  {allFeatures?.length > 0 && (
                    <Menu
                      menuMaxContent
                      anchorEl={anchorEl}
                      key={allFeatures?.length}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      {filteredFeatures?.length > 0
                        ? filteredFeatures?.map((item: SchemaFeature) => (
                            <MenuItem
                              key={item.id}
                              value={item.name}
                              isSelected={item.name === formik?.values?.rules[index]?.feature}
                              onClick={() => {
                                formik.setFieldValue(id + ".feature", item.name)
                                handleFeatureSelectionFromMenu(item, "LHS")
                                setAnchorEl(null)
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          ))
                        : filteredFeatures?.length === 0 && (
                            <MenuItem value={"new feature"}>
                              <Grid container item xs={12} justifyContent="space-between">
                                <Grid item>
                                  <Typography style={{ marginRight: "4px" }} variant="a">
                                    Add this feature to schema
                                  </Typography>
                                </Grid>

                                <Grid item display={"flex"} alignItems={"self-end"}>
                                  <Button
                                    onClick={() => {
                                      const newFeat = {
                                        name: formik.values?.rules[index]?.feature?.replace(/\s/g, ""),
                                        type: MapWorkflowValueTypeToFeatureType[
                                          `${formik.values?.rules[index]?.type}` as keyof typeof MapWorkflowValueTypeToFeatureType
                                        ],
                                        is_required: true,
                                        id: uuidv4(),
                                        source: "workflow",
                                        new: true,
                                      }

                                      workflowFormik?.setFieldValue("features", [
                                        ...(workflowFormik.values?.features ?? []),
                                        newFeat,
                                      ])

                                      setFilteredFeatures([...allFeatures, newFeat])
                                      setAnchorEl(null)
                                    }}
                                    size="small"
                                    variant="primary"
                                  >
                                    Add
                                  </Button>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          )}
                    </Menu>
                  )}
                </Fragment>
              ) : (
                <ValueBlock size={12} value={formik?.values?.rules[index]?.feature} />
              )}
            </Grid>

            {/* Operator */}
            <Grid item xs className="nodrag" display={"flex"} flexGrow={1}>
              {!isReadMode || isEditMode ? (
                <Select
                  id={id + ".operator"}
                  value={formik.values?.rules[index]?.operator}
                  handleChange={(e) => handleOperatorChange(e)}
                  fullWidth
                  disabled={isMenuView}
                  options={operators}
                  hideDescription
                  autosize
                  menuProps={{
                    menuMaxContent: true,
                  }}
                />
              ) : (
                <ValueBlock size={12} value={formik.values?.rules[index]?.operator} />
              )}
            </Grid>
          </Grid>

          {/* Type */}
          {shouldDisplayTypeBlock && (
            <Grid
              item
              xs={getTypeFieldSize()}
              style={
                formik.values?.rules[index]?.operator !== Operators["!="] &&
                formik.values?.rules[index]?.operator !== Operators["="]
                  ? { display: "none" }
                  : undefined
              }
              className="nodrag"
              display={"flex"}
              alignSelf={"center"}
            >
              {!isReadMode || (isReadMode && isEditMode) ? (
                <Select
                  id={id + ".type"}
                  value={formik.values?.rules[index]?.type}
                  handleChange={(e) => handleTypeChange(e)}
                  options={types}
                  fullWidth
                  hideDescription
                  menuProps={{
                    menuMaxContent: true,
                  }}
                />
              ) : (
                <ValueBlock size={12} value={formik.values?.rules[index]?.type as string} />
              )}
            </Grid>
          )}

          {/* Feature/value radio buttons*/}
          {(!isReadMode || isEditMode) &&
            (!isTypeIncluded(formik.values?.rules[index]?.type as string) ||
              formik.values?.rules[index]?.valueOrFeature === "Feature") &&
            !isMenuView &&
            "between" !== formik.values?.rules[index]?.operator && (
              <Grid item>
                <RadioButtonsCondition
                  handleOnChange={(e) => {
                    setOperators(
                      operatorsBasedOnFeatureType[
                        MapValueTypeToFeatureType[
                          formik.values?.rules[index]?.type as keyof typeof MapValueTypeToFeatureType
                        ]
                      ],
                    )

                    formik.setFieldValue(id + `.valueOrFeature`, e.target.value)
                  }}
                  checkedFeat={formik.values?.rules[index]?.valueOrFeature === "Feature"}
                  checkedValue={formik.values?.rules[index]?.valueOrFeature === "Value"}
                  shouldDisableFeature={formik?.values?.rules[index]?.value === "$file"}
                />
              </Grid>
            )}

          {/* Value */}
          {formik.values?.rules[index]?.operator?.toLowerCase() === Operators.between.toLowerCase() ? (
            <Grid xs item container>
              <Grid item xs>
                {!isReadMode || isEditMode ? (
                  <InputText
                    hideDescription
                    id={id + ".value"}
                    placeholder="Value"
                    value={formik.values?.rules[index]?.value}
                    handleChange={formik.handleChange}
                    key={formik.values?.rules[index]?.operator}
                    handleBlur={formik.handleBlur}
                    disabled={formik.isSubmitting || isMenuView}
                    fullWidth
                    type={"number"}
                  />
                ) : (
                  <ValueBlock size={12} value={formik.values?.rules[index]?.value} isLoading={false} />
                )}
              </Grid>

              {/* And */}
              <Grid
                item
                xs={0.2}
                mx={1}
                display={"flex"}
                alignSelf={!isEditMode ? "start" : "center"}
                className={!isReadMode || isEditMode ? "and-block-filter" : "and-block-readMode"}
              >
                <Typography variant="label" variantColor={1} color="neutral">
                  And
                </Typography>
              </Grid>

              <Grid item xs>
                {!isReadMode || isEditMode ? (
                  <InputText
                    hideDescription
                    id={id + ".secondValue"}
                    placeholder="Value"
                    value={formik.values?.rules[index]?.secondValue}
                    handleChange={formik.handleChange}
                    key={formik.values?.rules[index]?.operator}
                    handleBlur={formik.handleBlur}
                    disabled={formik.isSubmitting || isMenuView}
                    fullWidth
                    type={"number"}
                  />
                ) : (
                  <ValueBlock size={12} value={formik.values?.rules[index]?.secondValue as string} />
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs
              justifySelf="flex-end"
              // Important: hide this field using display: "none" in order NOT to lose access to this element by id,
              // as the id of this field is used by the formik object
              style={getValueFieldStyles()}
              alignSelf={"center"}
            >
              {shouldDisplayFileBlock ? (
                <UploadFileBlock
                  onDelete={() => handleDeleteFile(true)}
                  fileName={condition_list_file?.name ?? formik?.values?.fileData?.name}
                  isUploading={uploadingCSV}
                  handleCancel={handleCloseAndAbort}
                  isDeleting={deleteDataFileMutation.isLoading}
                  type="FilterNode"
                />
              ) : (!isReadMode || isEditMode) && formik.values?.rules[index]?.valueOrFeature === "Feature" ? (
                <Fragment>
                  <div>
                    <InputText
                      hideDescription
                      anchoringRef={inputRef2}
                      key={secondSelectedOption}
                      id={id + ".secondFeature"}
                      placeholder="Feature"
                      value={formik.values?.rules[index]?.secondFeature}
                      handleChange={(e) => {
                        formik?.handleChange(e)
                        debouncedOnChangeRHS()
                      }}
                      handleBlur={formik.handleBlur}
                      disabled={formik.isSubmitting || isMenuView}
                      type="text"
                      endAdornment={
                        allFeatures?.length > 0 ? (
                          <ArrowAdornment
                            anchorEl={anchorEl2}
                            onClick={() => setAnchorEl2(anchorEl2 ? null : inputRef2?.current)}
                          />
                        ) : undefined
                      }
                      fullWidth
                    />
                  </div>

                  {allFeatures?.length > 0 && (
                    <Menu
                      menuMaxContent
                      anchorEl={anchorEl2}
                      open={Boolean(anchorEl2)}
                      onClose={() => setAnchorEl2(null)}
                    >
                      {secondFilteredFeatures?.length > 0
                        ? secondFilteredFeatures?.map((item: SchemaFeature) => (
                            <MenuItem
                              key={item.id}
                              value={item.name}
                              isSelected={item.name === formik?.values?.rules[index]?.secondFeature}
                              onClick={() => {
                                formik.setFieldValue(id + ".secondFeature", item.name)
                                handleFeatureSelectionFromMenu(item, "RHS")
                                setAnchorEl2(null)
                              }}
                            >
                              <Typography variant="a" className="menu-item-padding">
                                {item.name}
                              </Typography>
                            </MenuItem>
                          ))
                        : secondFilteredFeatures?.length === 0 && (
                            <MenuItem value={"new feature"}>
                              <Grid container item xs={12} justifyContent="space-between">
                                <Grid item>
                                  <Typography style={{ marginRight: "4px" }} variant="a" className="menu-item-padding">
                                    Add this feature to schema
                                  </Typography>
                                </Grid>

                                <Grid item display={"flex"} alignItems={"self-end"}>
                                  <Button
                                    onClick={() => {
                                      const newFeat = {
                                        name: formik.values?.rules[index]?.secondFeature?.replace(/\s/g, ""),
                                        type: MapWorkflowValueTypeToFeatureType[
                                          `${formik.values?.rules[index]?.type}` as keyof typeof MapWorkflowValueTypeToFeatureType
                                        ],
                                        is_required: true,
                                        id: uuidv4(),
                                        source: "workflow",
                                        new: true,
                                      }

                                      workflowFormik?.setFieldValue("features", [
                                        ...(workflowFormik.values?.features ?? []),
                                        newFeat,
                                      ])

                                      setSecondFilteredFeatures([...allFeatures, newFeat])
                                      setAnchorEl2(null)
                                    }}
                                    size="small"
                                    variant="primary"
                                  >
                                    Add
                                  </Button>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          )}
                    </Menu>
                  )}
                </Fragment>
              ) : !isReadMode || isEditMode ? (
                <InputText
                  hideDescription
                  id={id + ".value"}
                  placeholder="Value"
                  value={formik.values?.rules[index]?.value}
                  handleChange={formik.handleChange}
                  key={`${formik.values?.rules[index]?.operator}`}
                  handleBlur={formik.handleBlur}
                  disabled={formik.isSubmitting || isMenuView}
                  fullWidth
                  endAdornment={
                    isMultiValuesOperatorsIncluded(formik.values?.rules[index]?.operator) ? (
                      <Fragment>
                        {/* must add this input to make it work in safari*/}
                        <input {...getInputProps()} />
                        <FileUploadOutlinedIcon
                          onClick={handleClickOnUploadIcon}
                          fontSize="small"
                          style={{ color: "var(--grayscale-text-2)" }}
                        />
                      </Fragment>
                    ) : undefined
                  }
                  type={getValueFieldType()}
                />
              ) : (
                <ValueBlock size={12} value={getValueFieldBlockValue()} isLoading={false} />
              )}
            </Grid>
          )}
        </Grid>

        {/* Remove button */}
        {!isReadMode && index > 0 && (
          <Grid item justifyContent="center" alignItems="center" alignSelf="center" xs={0.5}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="dangerous"
                size="small"
                onClick={async () => {
                  if (
                    multiValuesOperators.includes(
                      Operators[formik?.values?.rules[index]?.operator as keyof typeof Operators],
                    ) &&
                    formik?.values?.rules[index]?.value === "$file"
                  ) {
                    await handleDeleteFile(false)
                  }

                  handleRemoveRule()
                }}
              >
                <RemoveCircleOutlineIcon fontSize="inherit" />
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}
