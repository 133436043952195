import React, { Fragment, ReactElement, RefObject, useEffect, useMemo, useState } from "react"

import CloseIcon from "@mui/icons-material/Close"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff"
import { Grid, IconButton } from "@mui/material"
import { Button, InputText, Typography } from "@synapse-analytics/synapse-ui"
import {
  MRT_GlobalFilterTextField as GlobalFilterTextField,
  type MRT_ColumnDef,
  MRT_ColumnOrderState,
  MRT_PaginationState,
  MRT_Row,
  MaterialReactTable,
  MRT_ShowHideColumnsButton as ShowHideColumnsButton,
  MRT_ToggleFiltersButton as ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as ToggleGlobalFilterButton,
} from "material-react-table"

import { getTheme } from "../../hooks/UseTheme"
import { TableRefState, VisibilityMap } from "../../types/custom/tables"
import { TableConfigurationRequest } from "../../types/generated/api/TableConfigurationRequest"
import { KonanPagination } from "./KonanPagination"
import { tableIcons } from "./TableIcons"

const MemoizedCustomPagination = React.memo(KonanPagination)

interface Props {
  id?: string
  title: string | ReactElement
  tooltip?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  isLoading: boolean
  columns: MRT_ColumnDef[]
  enablePagination?: boolean
  manualPagination?: boolean
  enableGlobalFilter?: boolean
  enableHiding?: boolean
  enableFilters?: boolean
  enableTableHead?: boolean
  detailPanel?: (row: MRT_Row) => ReactElement
  pagination?: MRT_PaginationState
  setPagination?: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
  rowCount?: number
  stripped?: boolean
  pageSize?: number
  hiddenColumns?: string[]
  disableToolbar?: boolean
  disableToolbarActions?: boolean
  enableOrdering?: boolean
  layoutMode?: "grid" | "semantic"
  clearAllFiltersFn?: () => void
  source?: TableConfigurationRequest.table
  columnsOrder?: string[]
  onColumnOrderChange?: (newOrder: MRT_ColumnOrderState) => void
  onColumnVisibilityChange?: (updaterFn: (visibility: VisibilityMap) => VisibilityMap) => void
  shouldShowProgressBar?: boolean
  columnsVisibility?: VisibilityMap
  tableRef: RefObject<TableRefState>
  onExportClick?: () => void
}

export function BaseTable(props: Readonly<Props>): React.ReactElement {
  const {
    id,
    title,
    data,
    isLoading,
    columns,
    enablePagination = true,
    manualPagination = false,
    enableGlobalFilter = true,
    enableHiding = true,
    enableFilters = true,
    enableTableHead = true,
    enableOrdering = true,
    detailPanel,
    pagination = undefined,
    setPagination,
    disableToolbar = false,
    pageSize = 10,
    rowCount,
    stripped = false,
    layoutMode = "semantic",
    clearAllFiltersFn,
    source,
    columnsOrder,
    onColumnOrderChange,
    onColumnVisibilityChange,
    shouldShowProgressBar,
    columnsVisibility,
    tableRef,
    onExportClick,
  } = props

  const theme = getTheme()

  // Using Key to re-render the component in case we need to remove all filter
  // Since material-table-react don't expose an out of the box way to do so
  const [key, setKey] = useState<string>("key")

  // To handle internal pagination in case no pagination state and setter were passed
  const [internalPagination, setInternalPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  })

  const adjustedColumns: MRT_ColumnDef[] = useMemo(() => {
    return columns.map((column) => {
      return {
        Filter: (filterProps) => {
          const value = filterProps.column.getFilterValue() as string

          const id = filterProps.column.columnDef.header?.split(" ").join("_")

          return (
            <InputText
              hideDescription
              id={`filter-${id}`}
              key={`filter-${id}`}
              placeholder={"Filter"}
              fullWidth
              value={value}
              handleChange={(e) => {
                filterProps.column.setFilterValue(e.target.value)
              }}
              endAdornment={
                !["", null, undefined].includes(value) ? (
                  <CloseIcon
                    fontSize="small"
                    onClick={() => {
                      filterProps.column.setFilterValue("")
                      document.getElementById(`filter-${id}`).value = ""
                    }}
                  />
                ) : undefined
              }
            />
          )
        },
        size: 100,
        ...column,
      }
    })
  }, [columns])

  /**
   * currently, there's no direct way to control the internal toolbar action buttons, like show/hide all
   * so this mutationObserver removes the 2 buttons, by selecting the classes of their elements
   * using mutationObserver will make sure these buttons will be removed across renders and
   * whenever menu open/close.
   * TODO: remove all this logic and implement a custom action for the toolbar.
   */
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList" && mutation.addedNodes.length) {
          const buttons = document.querySelectorAll(".MuiModal-root > .MuiPaper-root > .MuiMenu-list > div > button")
          buttons.forEach((button) => {
            // Check the inner text to identify "Show All" and "Hide All"
            if (button.innerHTML.includes("Hide all") || button.innerHTML.includes("Show all")) {
              button.className = "removeMuiTableButton" // Hide "Hide/Show All" button
            }
          })
        }
      })
    })

    // Start observing
    const config = { childList: true, subtree: true }
    const targetNode = document.body // Adjust if needed to a more specific part of your DOM
    observer.observe(targetNode, config)

    // Disconnect the observer when the component unmounts to prevent memory leaks
    return () => observer.disconnect()
  }, [])

  const allColumnsHidden = columnsVisibility && Object.values(columnsVisibility).every((isVisible) => !isVisible)

  return (
    <div className={`table ${stripped && "table-no-box-shadow"}`} id={id} key={key}>
      <MaterialReactTable
        tableInstanceRef={tableRef}
        columns={adjustedColumns}
        data={!allColumnsHidden ? data ?? [] : []}
        icons={{ ...tableIcons, DragHandleIcon: (props) => <DragIndicatorIcon {...props} fontSize="small" /> }}
        autoResetPageIndex={false}
        autoResetAll={false}
        enableSorting={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enablePagination={enablePagination}
        enableBottomToolbar
        enableTopToolbar={!disableToolbar && !stripped}
        enableColumnActions={false}
        enableGlobalFilter={enableGlobalFilter}
        enableHiding={enableHiding}
        enableFilters={enableFilters}
        manualPagination={manualPagination}
        onColumnOrderChange={onColumnOrderChange}
        onColumnVisibilityChange={onColumnVisibilityChange}
        enableColumnResizing={false}
        enableColumnOrdering={enableOrdering}
        enableColumnDragging={enableOrdering}
        enableTableHead={enableTableHead}
        enableToolbarInternalActions={false}
        layoutMode={layoutMode}
        muiSearchTextFieldProps={{
          placeholder: "Search all columns",
          sx: { minWidth: "8rem" },
          variant: "standard",
        }}
        positionGlobalFilter="none"
        state={{
          showProgressBars: isLoading || shouldShowProgressBar,
          density: "compact",
          pagination: pagination ?? internalPagination,
          columnVisibility: columnsVisibility,
          columnOrder:
            source && Object.values(TableConfigurationRequest.table).includes(source)
              ? (["mrt-row-expand", ...(columnsOrder ?? [])] as string[])
              : ([...(columnsOrder ?? [])] as string[]),
        }}
        onPaginationChange={setPagination ?? setInternalPagination}
        rowCount={rowCount}
        // enableColumnResizing
        renderEmptyRowsFallback={() => (
          <Grid
            container
            display="flex"
            flexDirection="column"
            height={"200px"}
            justifyContent={"center"}
            alignContent={"center"}
          >
            {allColumnsHidden && !isLoading ? (
              <Fragment>
                <Grid item>
                  <Typography variant="h3-bold" align="center">
                    All Columns are hidden
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="p" align="center">
                    Reveal a column to see your data
                  </Typography>
                </Grid>
              </Fragment>
            ) : (
              <Grid item>
                <Typography variant="h3-regular" align="center">
                  {!isLoading ? "No data to display." : "Fetching your data..."}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        renderDetailPanel={detailPanel ? ({ row }) => detailPanel(row) : undefined}
        renderTopToolbarCustomActions={({ table }) => {
          const tableState = table.getState()
          return (
            // hardcoding height to align text with icons
            <Grid container item xs direction="row" justifyContent="space-between" alignItems="center" height={"40px"}>
              <Grid item xs>
                <Typography variant="h3-bold">{title}</Typography>
              </Grid>

              <Grid container item xs justifyContent={"flex-end"} alignItems="center" textAlign={"right"}>
                {(tableState.columnFilters.length > 0 || clearAllFiltersFn) && (
                  <IconButton
                    size="medium"
                    onClick={() => {
                      clearAllFiltersFn?.()
                      setKey(key + 1)
                    }}
                  >
                    <FilterAltOffIcon fontSize="medium" style={{ color: theme.palette.grayscale.text[2] }} />
                  </IconButton>
                )}

                <GlobalFilterTextField table={table} />
                <ToggleGlobalFilterButton table={table} />
                <ToggleFiltersButton table={table} />

                {enableHiding && <ShowHideColumnsButton table={table} />}

                {onExportClick && (
                  <Button
                    startIcon={<FileDownloadOutlinedIcon sx={{ fontSize: "16px" }} />}
                    variant="secondary"
                    size="small"
                    onClick={() => onExportClick?.()}
                  >
                    Export
                  </Button>
                )}
              </Grid>
            </Grid>
          )
        }}
        // overwriting overall table styles
        muiTableProps={{
          // TODO:: fix chipping at the edges
          sx: {
            border: stripped || disableToolbar ? "0px" : `1px solid ${theme.palette.grayscale.border}`,
            borderTop: "0px",
            borderBottom: "0px",
            borderRadius: "0px",
            padding: stripped ? "0px" : "0px 12px",
          },
        }}
        // overwriting Top toolbar styles
        muiTopToolbarProps={{
          variant: "dense",
          sx: {
            backgroundColor: theme.palette.grayscale.background[3],
            borderRadius: "8px 8px 0px 0px",
            border: `1px solid ${theme.palette.grayscale.border}`,
            borderBottom: "0px",
            zIndex: 0,
            padding: "0.25rem !important",
          },
        }}
        // overwriting Bottom toolbar styles
        muiBottomToolbarProps={{
          variant: "dense",
          sx: {
            backgroundColor: stripped ? theme.palette.grayscale.background[2] : theme.palette.grayscale.background[3],
            borderRadius: "0px 0px 8px 8px",
            border: stripped ? "0px" : `1px solid ${theme.palette.grayscale.border}`,
            borderTop: "0px",

            // Splitting pagination section
            "> div.MuiBox-root > div": {
              justifyContent: "space-between",
              width: "100%",
            },
          },
        }}
        // overwriting expand all btn styles
        muiExpandAllButtonProps={{
          sx: {
            display: "none",
            width: "20px",
          },
        }}
        // overwriting HEADER cell styles
        muiTableHeadCellProps={{
          sx: {
            borderBottom: `1px solid ${theme.palette.grayscale.border}`,
            backgroundColor: theme.palette.grayscale.background[2],
            color: theme.palette.grayscale.text[1],
            padding: "8px 8px 8px 4px !important",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "20px",
            textAlign: "center",
            borderTop: disableToolbar ? `1px solid ${theme.palette.grayscale.border}` : "none",
            "> div.Mui-TableHeadCell-Content": { flexDirection: "row-reverse", justifyContent: "flex-end" },
          },
        }}
        // overwriting HEADER row styles
        muiTableHeadRowProps={{
          sx: {
            boxShadow: "unset",
          },
        }}
        muiTableBodyProps={{ sx: { position: "static" } }}
        // overwriting BODY row styles
        muiTableBodyRowProps={({ staticRowIndex }) => {
          return {
            style: {
              background:
                staticRowIndex % 2 ? theme.palette.grayscale.background[2] : theme.palette.grayscale.background[3],
            },
            // TODO:: fix hover state colors
            hover: false,
          }
        }}
        // overwriting BODY cell styles
        muiTableBodyCellProps={{
          sx: {
            padding: "8px",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            color: theme.palette.grayscale.text[1],
            borderBottom: `1px solid ${theme.palette.grayscale.border}`,
          },
        }}
        // Custom pagination component
        muiTablePaginationProps={(props) => {
          return {
            component: () => {
              return (
                <MemoizedCustomPagination
                  stripped={stripped}
                  count={rowCount ?? data.length ?? 0}
                  page={props.table.getState().pagination.pageIndex}
                  onPageChange={(index: number) => props.table.setPageIndex(index)}
                  rowsPerPage={props.table.getState().pagination.pageSize}
                  setRowsPerPage={(pageSize: number) => props.table.setPageSize(pageSize)}
                />
              )
            },
          }
        }}
      />
    </div>
  )
}
