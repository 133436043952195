import React, { useEffect, useState } from "react"

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { Grid } from "@mui/material"
import { InputChangeEvent, InputText, Typography } from "@synapse-analytics/synapse-ui"
import { FieldArray, FormikProps, FormikProvider } from "formik"

import { DataRecordForm, InitialFormState } from "../../../features/Scripts/BuiltinScriptDialog"
import { FeatureMapping, InputFeature, SchemaFeature } from "../../../types/custom/workflows"
import { ScriptSchemaFeature } from "../../../types/generated/api/ScriptSchemaFeature"
import { WorkflowSchemaFeature } from "../../../types/generated/api/WorkflowSchemaFeature"
import { getIconForFeatureType } from "../../../utils/conditionHelpers"
import { filteredFeaturesBasedOnType } from "../../../utils/workflowHelpers"

import styles from "../WorkflowBlocksDialog.module.scss"

interface KeyValueBlockProps {
  isCreds?: boolean
  valueListOptions?: Array<SchemaFeature>
  formik: FormikProps<InitialFormState | DataRecordForm>
  isViewMode?: boolean
  inputFeatures?: Array<ScriptSchemaFeature>
  usedCreds?: string[] | null
  type: "Script" | "Project"
  enableSelfMappingUnmappedValues?: boolean
}

interface SingleDataRecordProps {
  index?: number
  isCreds: boolean
  valueListOptions?: Array<SchemaFeature>
  dataRecordKey: string
  dataRecordValue: string | number
  formik?: FormikProps<DataRecordForm | InitialFormState>
  isViewMode?: boolean
  isCredUsed?: boolean
  isHeader?: boolean
}

export function SingleDataRecord(props: Readonly<SingleDataRecordProps>): React.ReactElement {
  const {
    index = 0,
    isCreds,
    valueListOptions,
    dataRecordKey,
    formik,
    isViewMode,
    isCredUsed,
    isHeader,
    dataRecordValue,
  } = props

  const [inputValue, setInputValue] = useState<string>("")
  const [isCredShown, setIsCredShown] = useState<boolean>(false)

  const handleFocus = (): void => {
    // If input contains placeholder dots, clear them on focus
    if (inputValue === "•••••••••" && isCredUsed && formik?.values.creds[index].value === "") {
      setInputValue("")
    }
  }

  const handleChange = (e: InputChangeEvent<Element>): void => {
    setInputValue(e.target.value as string)
    if (!inputValue.includes("•")) {
      formik?.setFieldValue(`creds[${index}].value`, e.target.value)
    }
  }

  const handleBlur = (): void => {
    if (inputValue === "" && isCredUsed && formik?.values.creds[index].value === "") {
      setInputValue("•••••••••")
    }
  }

  // Set initial input value based on whether the credential is used and if there's no actual value
  // else, just update the input value with the creds value
  useEffect(() => {
    if (isCreds && index !== undefined && index !== null) {
      if (formik?.values?.creds[index]?.value === "" && isCredUsed) {
        setInputValue("•••••••••")
      } else {
        setInputValue(formik?.values?.creds[index]?.value as string)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCredUsed, isCreds])

  return (
    <Grid item className={styles.keyValue} container key={`${index}-${valueListOptions?.length}`}>
      <Grid gap={0.5} className={styles.key} flexBasis="50%" item display="flex">
        {!isHeader &&
          getIconForFeatureType(
            !isCreds && formik?.values?.feature_mappings
              ? (formik?.values?.feature_mappings[index]?.type as WorkflowSchemaFeature.type)
              : ((formik as FormikProps<DataRecordForm>).values?.creds[index]?.type as WorkflowSchemaFeature.type),
          )}

        <Typography
          variant={isHeader ? "h3-bold" : "p"}
          noWrap
          style={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {dataRecordKey}

          {isCreds && (formik as FormikProps<DataRecordForm>).values?.creds[index]?.isRequired && "*"}
        </Typography>
      </Grid>

      <Grid item flexBasis="50%" display="flex" className={styles.value} style={{ overflow: "hidden" }}>
        <Grid item alignItems="center" display="flex" container style={{ overflow: "hidden" }}>
          {isViewMode ? (
            <Typography
              variant={isHeader ? "h3-bold" : "p"}
              noWrap
              style={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {dataRecordValue}
            </Typography>
          ) : isCreds ? (
            <InputText
              placeholder={"value"}
              required={(formik as FormikProps<DataRecordForm>).values?.creds[index]?.isRequired}
              handleChange={handleChange}
              value={inputValue}
              id={`creds[${index}].value`}
              type={formik?.values.creds[index].value !== "" && !isCredShown ? "masked" : "text"}
              fullWidth
              hideDescription
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              endAdornment={
                formik?.values.creds[index].value !== "" ? (
                  isCredShown ? (
                    <VisibilityOutlinedIcon
                      style={{ color: "var(--grayscale-text-2)" }}
                      fontSize="small"
                      onClick={() => setIsCredShown(!isCredShown)}
                      sx={{ fontSize: "14px" }}
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ color: "var(--grayscale-text-2)" }}
                      fontSize="small"
                      onClick={() => setIsCredShown(!isCredShown)}
                      sx={{ fontSize: "14px" }}
                    />
                  )
                ) : undefined
              }
            />
          ) : (
            <InputText
              handleChange={formik?.handleChange}
              value={formik?.values?.feature_mappings && formik?.values?.feature_mappings[index]?.workflow_feature}
              id={`feature_mappings[${index}].workflow_feature`}
              placeholder="Search Feature"
              hideDescription
              optionsWithValues={
                valueListOptions?.length === 0
                  ? [{ label: "No entries found!", value: "default", disabled: true }]
                  : valueListOptions?.map((value) => ({ label: value?.name, value: value?.name }))
              }
              fullWidth
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export function KeyValueBlock(props: Readonly<KeyValueBlockProps>): React.ReactElement {
  const {
    valueListOptions,
    isCreds = false,
    formik,
    isViewMode,
    inputFeatures,
    usedCreds,
    type,
    enableSelfMappingUnmappedValues = false,
  } = props

  const formikListName = isCreds ? "creds" : "feature_mappings"

  return (
    <Grid xs={12} className={styles.keyValueContainer} container item justifyContent="space-between">
      {formikListName !== "creds" && (
        <Grid container item>
          <SingleDataRecord
            key={`${formikListName}-${0}`}
            dataRecordKey={`${type} features`}
            dataRecordValue="Workflow features"
            isViewMode
            isCreds={false}
            isHeader
          />
        </Grid>
      )}

      <FormikProvider value={formik}>
        <FieldArray name={formikListName}>
          {() => (
            <Grid container item>
              {formik?.values[`${formikListName}`]?.map((item: InputFeature | FeatureMapping, index: number) => (
                <SingleDataRecord
                  key={`${formikListName}-${index}`}
                  valueListOptions={filteredFeaturesBasedOnType(
                    (item as FeatureMapping)?.node_feature ?? (item as FeatureMapping)?.node_feature,
                    valueListOptions as SchemaFeature[],
                    inputFeatures as ScriptSchemaFeature[],
                  )}
                  formik={formik}
                  index={index}
                  isViewMode={isViewMode}
                  isCreds={isCreds}
                  isCredUsed={usedCreds?.includes((item as InputFeature)?.feat)}
                  dataRecordValue={
                    isCreds
                      ? usedCreds?.includes((item as InputFeature)?.feat)
                        ? "•••••••••"
                        : ""
                      : formik.values?.feature_mappings
                        ? formik.values?.feature_mappings[index]?.workflow_feature !== ""
                          ? formik.values?.feature_mappings[index]?.workflow_feature
                          : enableSelfMappingUnmappedValues
                            ? formik.values?.feature_mappings[index]?.node_feature
                            : ""
                        : ""
                  }
                  dataRecordKey={
                    formikListName === "creds"
                      ? (item as InputFeature)?.feat
                      : (item as FeatureMapping)?.node_feature ?? (item as FeatureMapping)?.node_feature
                  }
                />
              ))}
            </Grid>
          )}
        </FieldArray>
      </FormikProvider>
    </Grid>
  )
}
