import moment from "moment"

import {
  DataDriftBarChartData,
  ModelAverageCpuUsage,
  ModelAvgResponseTime,
  PredictionsSummary,
  TrainingAndRetraining,
} from "../types/custom/projects"
import { AutoMLTrainingJob } from "../types/generated/api/AutoMLTrainingJob"
import { Deployment } from "../types/generated/api/Deployment"
import { ExportJobList } from "../types/generated/api/ExportJobList"
import { ModelListCreate } from "../types/generated/api/ModelListCreate"
import { ModelSettingsSet } from "../types/generated/api/ModelSettingsSet"
import { RetrainingJob } from "../types/generated/api/RetrainingJob"
import { ScoreCardList } from "../types/generated/api/ScoreCardList"
import { ScriptGroupList } from "../types/generated/api/ScriptGroupList"
import { TrainingDataUpload } from "../types/generated/api/TrainingDataUpload"
import { TrainingJob } from "../types/generated/api/TrainingJob"

/**
 * Function that sorts models by status
 * live is set first then challenger then disabled
 * and every type is sorted by creation date
 * ascending order is needed for models in overview + models drop-down menu
 * descending order is needed for graphs
 */
export function orderModelsByStatus(
  models: (
    | ModelListCreate
    | ModelAvgResponseTime
    | DataDriftBarChartData
    | PredictionsSummary
    | ModelAverageCpuUsage
  )[],
  type: "asc" | "desc",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const tempArray = Array.from(models)
  //sort by creation date
  tempArray.sort(
    (a, b) =>
      new Date(b.created_at ? b.created_at : "").getTime() - new Date(a.created_at ? a.created_at : "").getTime(),
  )
  //then sort by state
  //move the live model to the start
  tempArray.sort((a, b) => (a.state === "live" ? -1 : b.state === "live" ? 1 : 0))
  if (type === "desc") {
    tempArray.reverse()
  }
  // TODO:: should be done dynamically
  //filter disabled models + live and challenger models
  const disabledModels = tempArray.filter((item) => item.state === "disabled")
  const liveAndChallengerModels = tempArray.filter((item) => item.state === "live" || item.state === "challenger")
  const pendingActionModels = tempArray.filter((item) => item.state === "pending-action")
  //move disabled models -> to the end
  //move live + challengers -> to the beginning of the array
  if (type === "asc") {
    return liveAndChallengerModels.concat(disabledModels).concat(pendingActionModels)
  } else {
    return pendingActionModels.concat(disabledModels).concat(liveAndChallengerModels)
  }
}

/**
 * Function that searches models/projects/training data list by name
 */
export function searchListByName(
  list: (ModelListCreate | Deployment | TrainingDataUpload | ModelSettingsSet | ScoreCardList)[],
  searchValue: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] {
  const tempArray = list ? Array.from(list) : []
  const searchedList = tempArray.filter((item) => item.name?.toLowerCase().includes(searchValue.toLowerCase()))
  return searchedList
}

// function that returns list of jobs that matches the search value of the training/retraining job
export function searchRetrainingsByName(
  Retraining: (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[],
  searchValue: string,
): (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] {
  const tempArray = Retraining ? (Array.from(Retraining) as Array<TrainingAndRetraining>) : []
  const searchedRetraining = tempArray.filter((item: TrainingAndRetraining) =>
    item?.training_job_name?.toLowerCase().includes(searchValue.toLowerCase()),
  )
  return searchedRetraining
}

/**
 * Function that sorts models/projects/training data list based on sortValue selected
 */
export function sortList(
  list: (ModelListCreate | Deployment | TrainingDataUpload | ScriptGroupList)[],
  sortValue: string,
  listType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tempArray: any[] = list ? Array.from(list) : []
  if (sortValue === "Most Recent")
    tempArray.sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
  if (listType === "models") {
    if (sortValue === "Status") orderModelsByStatus(tempArray, "asc")
  }
  if (sortValue === "Name") tempArray.sort((a, b) => a.name.localeCompare(b.name))
  return tempArray
}

/**
 * Function that sorts models/projects/training data list based on sortValue selected
 */
export function sortConfigList(list: ModelSettingsSet[], sortValue: string): ModelSettingsSet[] {
  const tempArray: ModelSettingsSet[] = list ? Array.from(list) : []

  if (sortValue === "Most Recent")
    tempArray.sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  else if (sortValue === "Status") tempArray.sort((a, b) => b.is_active - a.is_active)

  return tempArray
}

// Function to sort Retraining/Training jobs based on job name and the most recent created job
export function sortRetrainings(
  retrainings: (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[],
  sortValue: string,
): (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] {
  const tempArray = retrainings ? (Array.from(retrainings) as Array<TrainingAndRetraining>) : []
  if (sortValue === "Most Recent")
    tempArray.sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
  if (sortValue === "Job Name") tempArray.sort((a, b) => a?.training_job_name.localeCompare(b?.training_job_name))
  return tempArray
}

/**
 * Function that filters models/projects by state
 */
export function filterListByState(
  list: (ModelListCreate | Deployment | ModelSettingsSet | ScriptGroupList)[],
  filterValue: string[],
  listType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tempArray: any[] = list ? Array.from(list) : []
  let filteredList = []
  if (listType === "models") {
    filteredList = tempArray.filter((item) => filterValue.includes(item.state))
  } else if (listType === "projects") {
    filteredList = tempArray.filter((item) => filterValue.includes(item.type.replace(/_+/g, " ")))
  } else if (listType === "configurations") {
    filteredList = tempArray.filter((item) => filterValue.includes(item.is_active_str))
  } else if (listType === "scripts") {
    tempArray.forEach((item) => {
      if (filterValue.includes("Custom") && item?.template === null) {
        filteredList.push(item)
      } else if (filterValue.includes("Built-in") && item?.template) {
        filteredList.push(item)
      }
    })
  }
  return filteredList
}

// Function to sort Data files based on name and the most recent created job
export function sortDataFiles(exportJobs: ExportJobList[], sortValue: string): ExportJobList[] {
  const tempArray = Array.from(exportJobs)

  if (sortValue === "Most Recent")
    tempArray.sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())

  if (sortValue === "Name") tempArray.sort((a, b) => a?.name.localeCompare(b?.name))

  return tempArray
}
