import React from "react"

import WarningIcon from "@mui/icons-material/Warning"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { Chip, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../../../../../../hooks/UseTheme"
import { NumericalHistogram } from "./NumericalHistogram"
import { ParentsBreadcrumbs } from "./components/ParentsBreadcrumbs"
import { StatisticsRow } from "./components/StatisticsRow"

import styles from "../DataMonitor.module.scss"

type HistogramType = {
  low_value: number
  high_value: number
  sample_count: number
}

type Props = {
  name: string
  type: string
  num_of_examples: number
  min?: number
  max?: number
  mean?: number
  median?: number
  num_zeros: number
  num_missing: number
  std_dev?: number
  histogram?: HistogramType[]
  parents: string
}

export function ContinuousOrDiscreteFeatureCard(props: Props): React.ReactElement {
  const { name, type, num_of_examples, min, max, mean, median, num_zeros, num_missing, std_dev, histogram, parents } =
    props

  const theme = getTheme()

  const zeroTolerance = 50
  const missingTolerance = 30

  const zeroPercentage = React.useMemo(() => {
    if (num_zeros) return (num_zeros / num_of_examples) * 100
    else return undefined
  }, [num_of_examples, num_zeros])

  const missingPercentage = React.useMemo(() => {
    if (num_missing) return (num_missing / num_of_examples) * 100
    else return undefined
  }, [num_of_examples, num_missing])

  return (
    <Paper className={styles.valueContainer}>
      <Grid container>
        {/* Name and Type */}
        <Grid item xs={2}>
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" wrap="wrap">
            <Grid container item spacing={1} xs={12}>
              {/* Parents */}
              {parents && <ParentsBreadcrumbs parents={parents} />}
            </Grid>

            <Grid container item xs={11} spacing={1}>
              <Grid item xs={11} className={styles.featName}>
                <Typography variant="h2-bold" noWrap>
                  {name}
                </Typography>
              </Grid>

              <Grid item xs={12} className={styles.chipAlignment}>
                <Chip clickable={false}> {type.charAt(0).toUpperCase() + type.slice(1)}</Chip>
              </Grid>

              {missingPercentage && missingPercentage > missingTolerance && (
                <Grid item xs={12} className={styles.chipAlignment}>
                  <Chip clickable={false}>
                    <WarningIcon style={{ color: theme.palette.yellow.text[2], marginRight: "8px" }} fontSize="small" />
                    High % of Missing
                  </Chip>
                </Grid>
              )}

              {zeroPercentage && zeroPercentage > zeroTolerance && (
                <Grid item xs={12} className={styles.chipAlignment}>
                  <Chip clickable={false}>
                    <WarningIcon style={{ color: theme.palette.yellow.text[2], marginRight: "8px" }} fontSize="small" />
                    High % of Zeros
                  </Chip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Stats  */}
        <Grid container item xs={6} className={styles.dataSection} justifyContent="space-between">
          <Grid container item xs={6} spacing={2} zeroMinWidth direction="column">
            <StatisticsRow name="Min" value={min} />
            <StatisticsRow name="Max" value={max} />
            <StatisticsRow name="Median" value={median} precision={3} />
            <StatisticsRow name="Mean" value={mean} precision={3} tooltip={"Average value"} />
            <StatisticsRow name="STD" value={std_dev} precision={3} tooltip={"Standard Deviation"} />
          </Grid>
          <Grid container item xs={6} spacing={2} zeroMinWidth direction="column">
            <StatisticsRow name="Zeros" value={num_zeros} precision={0} />
            <StatisticsRow
              name="Zeros (%)"
              value={zeroPercentage !== undefined ? zeroPercentage.toFixed(1) + "%" : "0.0%"}
              precision={0}
            />
            <StatisticsRow name="Num Missing" value={num_missing} precision={0} />
            <StatisticsRow
              name="Num Missing (%)"
              value={missingPercentage !== undefined ? missingPercentage.toFixed(1) + "%" : "0.0%"}
              precision={1}
            />
          </Grid>
        </Grid>

        {/* Histogram  */}
        <Grid item xs={4}>
          <NumericalHistogram data={histogram ? histogram : []} graphHeight={200} />
        </Grid>
      </Grid>
    </Paper>
  )
}
