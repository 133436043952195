import React, { PropsWithChildren } from "react"

import { Pagination } from "@mui/material"
import Grid from "@mui/material/Grid"
import { Select, Typography } from "@synapse-analytics/synapse-ui"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const KonanPagination = (props: PropsWithChildren<any>): React.ReactElement | null => {
  const { count, page, onPageChange, rowsPerPage, setRowsPerPage, stripped = false, hideRowCount = false } = props

  return (
    <Grid
      container
      item
      justifyContent="space-between"
      className="pagination"
      p={1.5}
      pl={stripped ? 0 : 1.5}
      pr={stripped ? 0 : 1.5}
      xs={12}
      wrap="nowrap"
    >
      <Grid item xs>
        <Pagination
          shape="rounded"
          count={Math.ceil(count / rowsPerPage)}
          page={page + 1}
          onChange={(_, value) => {
            onPageChange(value - 1)
          }}
          siblingCount={1}
        />
      </Grid>

      <Grid container item xs display={"flex"} alignSelf={"center"} justifyContent={"end"} wrap="nowrap" gap={1}>
        {!hideRowCount && (
          <Grid item>
            <Select
              hideDescription
              width={100}
              value={rowsPerPage}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              options={[5, 10, 20, 50]}
              handleChange={(e) => setRowsPerPage(e.target.value)}
            />
          </Grid>
        )}

        <Grid item alignSelf={"center"}>
          <Typography variant="p" variantColor={2}>
            Result{" "}
            {page * rowsPerPage + 1 < count
              ? Math.sign(page * rowsPerPage + 1) === 1
                ? page * rowsPerPage + 1
                : 0
              : count}
            -{(page + 1) * rowsPerPage > count ? count : (page + 1) * rowsPerPage} of {count}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
