import React, { Dispatch, SetStateAction } from "react"

import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, Grid, IconButton, useMediaQuery, useTheme as useMuiTheme } from "@mui/material"
import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"

import { KonanCopyToClipboard } from "../../../../../../../components/KonanCopyToClipboard"
import { ModelType } from "../../../../../../../components/ModelType"
import { getTheme } from "../../../../../../../hooks/UseTheme"

type Props = {
  open: boolean
  modelStatus: string
  modelName: string
  imageUrl: string
  port: number | string | undefined
  UUID: string
  setClose: Dispatch<SetStateAction<boolean>>
}

/**
 * Model's meta data dialog
 * displays meta data about the model
 * @param {string} open
 * @param {string} modelStatus
 * @param {string} modelName
 * @param {string} imageUrl
 * @param {number | string | undefined} port
 * @param {string} UUID
 * @param {Dispatch} setClose
 * @return {React.ReactElement}
 */
export function ModelMetaDataDialog(props: Props): React.ReactElement {
  const { open, modelStatus, modelName, imageUrl, port, UUID, setClose } = props

  const muiTheme = useMuiTheme()
  const theme = getTheme()

  return (
    <Dialog
      open={open}
      onClose={() => setClose(false)}
      maxWidth="xs"
      fullScreen={useMediaQuery(muiTheme.breakpoints.down("md"))}
    >
      <DialogContent className="dialog-content-base">
        <Grid item container justifyContent="space-between" xs={12}>
          <Grid item>
            <ModelType modelState={modelStatus} size="regular" />
          </Grid>

          <Grid item>
            <IconButton id="close" onClick={() => setClose(false)} size="small" className={"close-icon-button"}>
              <CloseIcon style={{ color: theme.palette.gray.background[1] }} />
            </IconButton>
          </Grid>
        </Grid>

        <Typography variant="h2-bold">{modelName}</Typography>
        <Grid marginTop={2}>
          <Typography variant="h3-bold">Metadata</Typography>
        </Grid>

        <Grid item xs={12} marginTop={2}>
          <Typography variant="p">UUID</Typography>
          <Typography variant="label" className="clipboard-box-model-info" noWrap>
            <Tooltip title={UUID} placement="top">
              <KonanCopyToClipboard text={UUID} />
              {UUID}
            </Tooltip>
          </Typography>
        </Grid>

        <Grid item xs={12} marginTop={2}>
          <Typography
            variant="p"
            tooltip="URL of the container image used"
            tooltipIconSize={16}
            tooltipVerticalAlign="middle"
          >
            Image
          </Typography>
          <Typography variant="label" noWrap className="clipboard-box-model-info typography-and-tooltip-container">
            <Tooltip title={imageUrl} width="100%" placement="top">
              <KonanCopyToClipboard text={imageUrl} />
              <Typography variant="label" noWrap className="typography-and-tooltip-container">
                {imageUrl}
              </Typography>
            </Tooltip>
          </Typography>
        </Grid>

        <Grid item xs={12} marginTop={2} marginBottom={2}>
          <Typography
            variant="p"
            tooltip="Port exposed by the container image"
            tooltipIconSize={16}
            tooltipVerticalAlign="middle"
          >
            Port
          </Typography>
          <Typography variant="label" className={"clipboard-box-model-info"} noWrap>
            <KonanCopyToClipboard text={port as string} />
            {port}
          </Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
