import React from "react"

import { Link } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import styles from "../../Entry.module.scss"

type Props = {
  type: "signin" | "signup"
}

/**
 * entry disclaimer text to show policy and terms and conditions
 * @param  {string} type to condition the wording of the text
 * @return  {<EntryDisclaimer />}
 */
export function EntryDisclaimer(props: Props): React.ReactElement {
  const { type } = props

  return (
    <Typography align="center" variant="p" color="neutral" className={styles.disclaimerContainer}>
      By signing {type === "signin" ? "in" : "up"} you agree to our{" "}
      <Link href="https://www.synapse-analytics.io/konan-terms-and-conditions" target="_blank" underline="hover">
        Terms and Conditions
      </Link>{" "}
      and{" "}
      <Link href="https://www.synapse-analytics.io/konan-privacy-policy" target="_blank" underline="hover">
        Privacy Policy
      </Link>
    </Typography>
  )
}
