import React, { useContext, useEffect, useState } from "react"

import { useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"

import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import { CircularProgress } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import Checkbox from "@mui/material/Checkbox"
import Grid from "@mui/material/Grid"
import Pagination from "@mui/material/Pagination"
import TextField from "@mui/material/TextField"
import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { InfoContainer } from "../../../../../../components/InfoContainer"
import { KonanEmptyState } from "../../../../../../components/KonanEmptyState"
import { getTheme } from "../../../../../../hooks/UseTheme"
import { KonanAPI } from "../../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../../store/CurrentProjectAndModelContext"
import {
  CategoricalType,
  DatetimeType,
  DiscreteOrContinuousType,
  StatisticsResponse,
  TaskId,
  UnknownType,
} from "../../../../../../types/custom/projects"
import { sortFeatures } from "../../../../../../utils/deploymentDetailsHelpers"
import { CategoricalFeatureCard } from "./components/CategoricalFeatureCard"
import { ContinuousOrDiscreteFeatureCard } from "./components/ContinuousOrDiscreteFeatureCard"
import { DatetimeFeatureCard } from "./components/DatetimeFeatureCard"
import { UnknownFeatureCard } from "./components/UnknownFeatureCard"

import styles from "./DataMonitor.module.scss"

export function DataMonitor(): React.ReactElement {
  const theme = getTheme()

  const [searchParams] = useSearchParams()

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const startDate = searchParams.get("startDate")
  const endDate = searchParams.get("endDate")

  const pageSize = 10
  const [page, setPage] = useState<number>(0)

  const [selectedColumns, setSelectedColumns] = useState([])

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  // Run Statistics Task
  const {
    isLoading: isLoadingCreatingStatisticsRun,
    data: taskID,
    isError: isCreatingStatisticsRunError,
  } = useQuery<TaskId, AxiosError>(
    ["taskId", currentProject?.uuid, startDate, endDate],
    () =>
      KonanAPI.createStatisticsRun({
        start_date: startDate as string,
        end_date: endDate as string,
        project_uuid: currentProject?.uuid as string,
      }),
    {
      enabled: startDate != null && endDate != null,
      refetchOnMount: true,

      retry: false,
    },
  )

  // Fetch Statistics Results
  const [refetchInterval, setRefetchInterval] = useState<number | false | undefined>(false)
  const {
    isLoading: isLoadingStatistics,
    data: stats,
    isError: isFetchingStatisticsError,
    remove: removeResults,
  } = useQuery<AxiosResponse<StatisticsResponse>, AxiosError>(
    ["stats", taskID],
    () => KonanAPI.fetchStatistics(taskID?.task_id as string),
    {
      enabled: taskID !== undefined,
      refetchInterval: refetchInterval,
      refetchIntervalInBackground: true,
      refetchOnMount: false,

      // Turn off refetching if an error occurs
      onError: () => {
        setRefetchInterval(false)
      },
      onSuccess: (data) => {
        if (data.data.task_status === "PENDING") {
          setRefetchInterval(3000)
        } else setRefetchInterval(false)
      },
    },
  )

  // Clear statistics cache when date range changes
  useEffect(() => {
    if (removeResults) {
      removeResults()
    }
  }, [startDate, endDate, removeResults])

  const statistics = React.useMemo(() => {
    if (stats?.data.task_status === "SUCCESS") {
      return sortFeatures(stats.data.task_result)
    }
    return []
  }, [stats])

  const isLoading = React.useMemo(() => {
    return isLoadingCreatingStatisticsRun || isLoadingStatistics || stats?.data.task_status === "PENDING"
  }, [isLoadingCreatingStatisticsRun, isLoadingStatistics, stats])

  const isError = React.useMemo(() => {
    return isFetchingStatisticsError || isCreatingStatisticsRunError
  }, [isFetchingStatisticsError, isCreatingStatisticsRunError])

  const filterColumnsByName = (
    arrayOfColumns: (DiscreteOrContinuousType | CategoricalType | DatetimeType | UnknownType)[],
    selectedColumns: string[],
  ): (DiscreteOrContinuousType | CategoricalType | DatetimeType | UnknownType)[] => {
    if (!selectedColumns || !selectedColumns.length) {
      return statistics as (DiscreteOrContinuousType | CategoricalType | DatetimeType | UnknownType)[]
    }
    const tmp = []
    for (let i = 0; i < arrayOfColumns.length; i++) {
      for (let j = 0; j < selectedColumns.length; j++) {
        if (arrayOfColumns[i].name === selectedColumns[j]) {
          tmp.push(arrayOfColumns[i])
        }
      }
    }
    //Reset page to 1 when filtering
    if (parseInt(tmp.length / 10 + "") < page / 10) {
      setPage(0)
    }

    return tmp
  }
  return (
    <Grid container spacing={2}>
      {/* Search + DateRange Section*/}
      <Grid container item xs={12} justifyContent={"space-between"}>
        <Grid item xs={12} sm={6} md={3}>
          <Grid container item direction={"column"}>
            <Grid item>
              <Autocomplete
                multiple
                disabled={isLoading || statistics.length === 0}
                options={statistics as (DiscreteOrContinuousType | CategoricalType | DatetimeType | UnknownType)[]}
                size="small"
                disableCloseOnSelect
                getOptionLabel={(option: DiscreteOrContinuousType | CategoricalType | DatetimeType | UnknownType) =>
                  option?.name
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(_event: any, value: any) => setSelectedColumns(value.map((item: any) => item?.name))}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                renderOption={(props: any, option: any, { selected }: any) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8, color: theme.palette.gray.background[1] }}
                      checked={selected}
                    />
                    <Typography variant="a">{option?.name}</Typography>
                  </li>
                )}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                renderInput={(params: any) => (
                  <TextField {...params} variant="outlined" label="Select Features" fullWidth />
                )}
              />
            </Grid>

            <Grid container item xs={12} sm={6} md={3} alignItems="flex-end">
              {stats?.data.task_result?.num_of_examples && (
                <Typography variant="p" gutterBottom>
                  Statistics calculated on <strong>{stats.data.task_result?.num_of_examples.toLocaleString()}</strong>{" "}
                  samples.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Info Section */}
      {isLoading && !isError ? (
        <Grid item xs={12} className={"empty-container"}>
          <InfoContainer
            icon={<CircularProgress className={styles.circularProgress} />}
            title="Calculating data statistics..."
          />
        </Grid>
      ) : isError ? (
        <Grid item xs={12} className={"empty-container"}>
          <KonanEmptyState
            title={"An error occurred when calculating statistics."}
            subTitle={"Please try again later."}
          />
        </Grid>
      ) : !isLoading && !isError && statistics?.length === 0 ? (
        <Grid item xs={12} className={"empty-container"}>
          <KonanEmptyState
            title={"No features to display!"}
            subTitle={"Try selecting another date range that contains data."}
          />
        </Grid>
      ) : (
        ""
      )}

      {/* Card Rendering Section */}
      {filterColumnsByName(statistics, selectedColumns)
        .slice(page * pageSize, (page + 1) * pageSize)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((feature: any) =>
          feature.type === "discrete" || feature.type === "continuous" ? (
            <Grid item xs={12} key={feature.name}>
              <ContinuousOrDiscreteFeatureCard
                name={feature.name}
                type={feature.type}
                num_of_examples={feature.num_of_examples}
                min={feature.min}
                max={feature.max}
                mean={feature.mean}
                median={feature.median}
                num_zeros={feature.num_zeros}
                num_missing={feature.num_missing}
                std_dev={feature.std_dev}
                histogram={feature.histogram}
                parents={feature.parent}
              />
            </Grid>
          ) : feature.type === "categorical" ? (
            <Grid item xs={12} key={feature.name}>
              <CategoricalFeatureCard
                name={feature.name}
                num_of_examples={feature.num_of_examples}
                num_missing={feature.num_missing}
                num_non_missing={feature.num_non_missing}
                unique={feature.unique}
                avg_length={feature.avg_length}
                rank_histogram={feature.rank_histogram}
                parents={feature.parent}
              />
            </Grid>
          ) : feature.type === "datetime" ? (
            <Grid item xs={12} key={feature.name}>
              <DatetimeFeatureCard
                name={feature.name}
                min={feature.min}
                max={feature.max}
                span={feature.span}
                num_of_examples={feature.num_of_examples}
                num_missing={feature.num_missing}
                unique={feature.unique}
                frequency={feature.aggregate_by}
                aggregates={feature.aggregates}
                parents={feature.parent}
              />
            </Grid>
          ) : (
            <Grid item xs={12} key={feature.name}>
              <UnknownFeatureCard name={feature.name} parents={feature.parent} />
            </Grid>
          ),
        )}

      {/* Pagination Section */}
      {statistics?.length > 0 && !isLoading && statistics && (
        <Grid container item justifyContent="flex-end">
          <Pagination
            count={
              !selectedColumns || !selectedColumns.length
                ? Math.ceil((statistics?.length as number) / pageSize)
                : Math.ceil((selectedColumns.length as number) / pageSize)
            }
            page={page + 1}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any, value: any) => {
              setPage(value - 1)
              window.scrollTo(0, 0)
            }}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  )
}
