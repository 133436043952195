import { FormikProps } from "formik"
import { Moment } from "moment"

import { WorkflowSimulationFormik } from "../../types/custom/workflows"
import { WorkflowSimulationJob } from "../../types/generated/api/WorkflowSimulationJob"
import { WorkflowVersionsRetrieve } from "../../types/generated/api/WorkflowVersionsRetrieve"

export interface TopDataFeaturesProps {
  features: []
  label: string
}

export interface SimulationReportProps {
  report?: WorkflowSimulationJob
  isLoading?: boolean
}

export interface PieChartContainerProps {
  isLoading: boolean
  index: number
  data: {
    reasons: { name: string; value: number; percent: number }[]
    label: { name: string; percent: number; value: number }
  }[]
}

export interface SimulationCreationDialogProps {
  open: boolean
  onClose: () => void
  workflowID: string
  workflowName: string
}

export interface DataSourceProps {
  // TODO:: remove formik as props
  formik: FormikProps<WorkflowSimulationFormik>
}

export interface ExistingDatasetViewProps {
  workflow: WorkflowVersionsRetrieve
  setDataFile: (uuid: string, columns: string[]) => void
  dataFile?: string
}

export interface LiveDataViewProps {
  setFormikStartDate: (date?: Moment) => void
  setFormikEndDate: (date?: Moment) => void
  setFormikRequestsNumber: (data: number) => void
}

export interface NewDatasetViewProps {
  workflow: WorkflowVersionsRetrieve
  setDataFile: (uuid: string, columns: string[]) => void
  formik: FormikProps<WorkflowSimulationFormik>
}

export enum SimulationTagMap {
  "Running" = "warning",
  "Failed" = "negative",
  "Success" = "positive",
  "Pending" = "default",
}
