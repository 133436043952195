import { Dispatch, SetStateAction } from "react"

import { UseMutationResult } from "react-query"
import { Edge, Node, Position, getConnectedEdges, getIncomers, getOutgoers } from "reactflow"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import dagre from "dagrejs"
import { FormikProps } from "formik"
import { v4 as uuidv4 } from "uuid"

import {
  EMPTY_FILTER_CONDITION,
  edgeStyles,
  markerEnd,
} from "../screens/ProjectDetails/components/DecisonEngines/components/Workflows/workflow-fixtures"
import { CreateWorkflowRequest, UpdateWorkflowRequest } from "../types/custom/projects"
import { FilterCondition } from "../types/custom/rules"
import {
  ComputedFeatures,
  FeatureData,
  FeatureMapping,
  SchemaFeature,
  UpdateGraphParams,
  WorkflowNode,
  mapNodeTypeToFlowFormatEnum,
} from "../types/custom/workflows"
import { Schema } from "../types/generated/api/Schema"
import { ScriptSchemaFeature } from "../types/generated/api/ScriptSchemaFeature"
import { WorkflowCreateRequest } from "../types/generated/api/WorkflowCreateRequest"
import { WorkflowDeploymentSchemaFeature } from "../types/generated/api/WorkflowDeploymentSchemaFeature"
import { WorkflowList } from "../types/generated/api/WorkflowList"
import { WorkflowSchemaFeature } from "../types/generated/api/WorkflowSchemaFeature"
import { WorkflowVersionsRetrieve } from "../types/generated/api/WorkflowVersionsRetrieve"
import { convertSingleRuleToCondition } from "./conditionHelpers"
import { convertComplexConditionsToNestedForm } from "./rulesetHelpers"

// Validate that feature mapped values are in correct format to be sent to backend
// each value must have node_features and workflow_feature keys
// if feature is mapped to itself remove feature from array
export function validateFeatureMapping(feature_mappings: FeatureMapping[]): FeatureMapping[] {
  let mappings: (FeatureMapping | null)[] = []

  if (feature_mappings?.length > 0) {
    mappings = feature_mappings?.map((feature: FeatureMapping | null) => {
      if ([null, undefined, ""].includes(feature?.workflow_feature?.toString()?.trim())) {
        return null
      }

      return feature
    })
  }

  return mappings.filter((mapping) => mapping !== null) as FeatureMapping[]
}

// this function returns an array of nodes based on the node type selected, that should be added
// to the workflow, this is because some nodes are terminating (no children) and some have pre-defined
// children for them
// ruleset-node --> 1 children (uncovered route)
// filter-node --> 2 children (true route, false route)
// live model-node --> terminator
// end result-node --> terminator
export const preDefinedRoutesForNodes = (
  nodeType: string,
  addBlockId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateGraph: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodeData: any,
  parentId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (nodeType === "RulesetNode" || nodeType === "DecisionNode") {
    const id = uuidv4()
    const newRulsetNode = {
      id: addBlockId,
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        updateGraph,
        ruleset: "",
        uncovered_route: null,
        node_type: "ruleset",
        nodeType,
        rulesets: nodeData,
        parentId,
        editMode: false,
        readMode: false,
        createMode: true,
        schema,
        formik,
      },
    }
    const addBlockNode = {
      id: `${id}`,
      height: 60,
      width: 125,
      position: {
        x: 0,
        y: 0,
      },
      type: "AddBlockNode",
      data: {
        updateGraph,
        parentId: addBlockId,
        schema,
        formik,
      },
    }
    return [newRulsetNode, addBlockNode]
  } else if (nodeType === "ProgramNode") {
    const id = uuidv4()

    const newProgramNode = {
      id: addBlockId,
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        updateGraph,
        program: "",
        node_type: "program",
        nodeType,
        programs: nodeData,
        resolution_route: null,
        parentId,
        editMode: false,
        readMode: false,
        createMode: true,
        schema,
        formik,
      },
    }

    const addBlockNode = {
      id: `${id}`,
      height: 60,
      width: 125,
      position: {
        x: 0,
        y: 0,
      },
      type: "AddBlockNode",
      data: {
        updateGraph,
        parentId: addBlockId,
        schema,
        formik,
      },
    }
    return [newProgramNode, addBlockNode]
  } else if (nodeType === "TaglistNode") {
    const id = uuidv4()

    const newTaglistNode = {
      id: addBlockId,
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        updateGraph,
        taglist: "",
        node_type: "taglist",
        nodeType,
        taglists: nodeData,
        resolution_route: null,
        parentId,
        editMode: false,
        readMode: false,
        createMode: true,
        schema,
        formik,
      },
    }

    const addBlockNode = {
      id: `${id}`,
      height: 60,
      width: 125,
      position: {
        x: 0,
        y: 0,
      },
      type: "AddBlockNode",
      data: {
        updateGraph,
        parentId: addBlockId,
        schema,
        formik,
      },
    }
    return [newTaglistNode, addBlockNode]
  } else if (nodeType === "ScriptNode") {
    const id = uuidv4()

    const newScriptNode = {
      id: addBlockId,
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        formik,
        feature_mappings: [],
        updateGraph,
        script: "",
        node_type: "script",
        computed_feature_name: "",
        nodeType,
        scripts: nodeData,
        resolution_route: null,
        file: "",
        is_draft: true,
        parentId,
        editMode: false,
        readMode: false,
        createMode: true,
      },
    }

    const addBlockNode = {
      id: `${id}`,
      height: 60,
      width: 125,
      position: {
        x: 0,
        y: 0,
      },
      type: "AddBlockNode",
      data: {
        updateGraph,
        parentId: addBlockId,
      },
    }
    return [newScriptNode, addBlockNode]
  } else if (nodeType === "ProjectNode") {
    const id = uuidv4()

    const newProjectNode = {
      id: addBlockId,
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        updateGraph,
        project: "",
        computed_feature_name: "",
        node_type: "project",
        nodeType,
        projects: nodeData,
        resolution_route: null,
        parentId,
        editMode: false,
        readMode: false,
        createMode: true,
        schema,
        formik,
      },
    }

    const addBlockNode = {
      id: `${id}`,
      height: 60,
      width: 125,
      position: {
        x: 0,
        y: 0,
      },
      type: "AddBlockNode",
      data: {
        updateGraph,
        parentId: addBlockId,
        schema,
        formik,
      },
    }
    return [newProjectNode, addBlockNode]
  } else if (nodeType === "EndResultNode") {
    const newEndResultNode = {
      id: addBlockId,
      position: {
        x: 0,
        y: 0,
      },
      type: "LabeledNode",
      data: {
        node_type: "label",
        return_label: {
          name: "",
          uuid: "",
        },
        nodeType,
        updateGraph,
        parentId,
        formik,
      },
    }
    return [newEndResultNode]
  } else if (nodeType === "FilterNode") {
    const id = uuidv4()
    const newFilterNode = {
      id: addBlockId,
      position: {
        x: 0,
        y: 0,
      },
      type: "FilterNode",
      data: {
        node_type: "filter",
        nodeType,
        feature_nesting_operator: ".",
        rules: [EMPTY_FILTER_CONDITION],
        filesCount: 0,
        condition_list_file: null,
        true_route: null,
        false_route: null,
        editMode: false,
        readMode: false,
        updateGraph,
        parentId,
        schema,
        formik,
      },
    }
    const addBlockNodeTrue = {
      id: `${id}`,
      position: {
        x: 0,
        y: 0,
      },
      height: 60,
      width: 125,
      type: "AddBlockNode",
      data: {
        updateGraph,
        parentId: addBlockId,
        is_true_route: true,
        nodeType: "AddBlockNode",
        schema,
        formik,
      },
    }
    const addBlockNodeFalse = {
      id: `${uuidv4()}`,
      position: {
        x: 0,
        y: 0,
      },
      type: "AddBlockNode",
      height: 60,
      width: 125,
      data: {
        updateGraph,
        parentId: addBlockId,
        is_false_route: true,
        schema,
        nodeType: "AddBlockNode",
        formik,
      },
    }
    return [newFilterNode, addBlockNodeTrue, addBlockNodeFalse]
  } else if (nodeType === "CalculatorNode") {
    const id = uuidv4()
    const newCalculatorNode = {
      id: addBlockId,
      position: {
        x: 0,
        y: 0,
      },
      type: "CalculatorNode",
      data: {
        node_type: "calculator",
        computed_feature_name: "",
        equation: "",
        feature_nesting_operator: ".",
        updateGraph,
        nodeType,
        parentId,
        schema,
        formik,
      },
    }

    const addBlockNode = {
      id: `${id}`,
      height: 60,
      width: 125,
      position: {
        x: 0,
        y: 0,
      },
      type: "AddBlockNode",
      data: {
        updateGraph,
        uncovered_route: null,
        parentId: addBlockId,
        schema,
        formik,
      },
    }
    return [newCalculatorNode, addBlockNode]
  } else if (nodeType === "ScorecardsetNode") {
    const id = uuidv4()
    const newScorecardsetNode = {
      id: addBlockId,
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        updateGraph,
        scorecard_set: "",
        resolution_route: null,
        node_type: "scorecardset",
        nodeType,
        scorecardsets: nodeData,
        parentId,
        editMode: false,
        readMode: false,
        createMode: true,
        schema,
        formik,
      },
    }
    const addBlockNode = {
      id: `${id}`,
      height: 60,
      width: 125,
      position: {
        x: 0,
        y: 0,
      },
      type: "AddBlockNode",
      data: {
        updateGraph,
        parentId: addBlockId,
        schema,
        formik,
      },
    }
    return [newScorecardsetNode, addBlockNode]
  }
}

// validate adding opening and closing parentheses or not on the equation
const validateEquationParentheses = (equation: string): string => {
  // Check if the expression already has surrounding parentheses
  const hasOpeningParenthesis = equation.startsWith("(")
  const hasClosingParenthesis = equation.endsWith(")")

  if (hasOpeningParenthesis && hasClosingParenthesis) {
    // Expression already has surrounding parentheses, no need to add more
    return equation
  }

  // Add surrounding parentheses
  return `(${equation})`
}

// this function acts as a converter from the node object structure that we store before creating
// a request and the final format we need to send to the backend to create a workflow request
// it extracts the node.data object which is holds all the necessary keys we want to send as well as
// adding the index that points to the child of the parent node
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseWorkflow = (nodes: Node[], edges?: Edge[]): any => {
  const filteredNodes = nodes?.slice(1)?.filter((node) => node?.type !== "AddBlockNode")

  const alteredNodes = filteredNodes?.map((node) => {
    if (node?.data?.nodeType === "RulesetNode" || node?.data?.nodeType === "DecisionNode") {
      const uncoveredNodeIndex = filteredNodes.findIndex((innerNode) => innerNode?.data?.parentId === node?.id)
      node.data = {
        ...node.data,
        ruleset: typeof node?.data?.ruleset === "string" ? node?.data?.ruleset : node?.data?.ruleset?.uuid,
        uncovered_route: uncoveredNodeIndex,
      }
    } else if (node?.data?.nodeType === "CalculatorNode") {
      const resolutionNodeIndex = filteredNodes.findIndex((innerNode) => innerNode?.data?.parentId === node?.id)
      node.data = {
        ...node.data,
        computed_feature_name: node?.data?.computed_feature_name,
        equation: validateEquationParentheses(node?.data?.equation),
        resolution_route: resolutionNodeIndex,
      }
    } else if (node?.data?.nodeType === "ProgramNode") {
      const resolutionNodeIndex = filteredNodes.findIndex((innerNode) => innerNode?.data?.parentId === node?.id)
      node.data = {
        ...node.data,
        program: typeof node?.data?.program === "string" ? node?.data?.program : node?.data?.program?.uuid,
        resolution_route: resolutionNodeIndex,
      }
    } else if (node?.data?.nodeType === "TaglistNode") {
      const resolutionNodeIndex = filteredNodes.findIndex((innerNode) => innerNode?.data?.parentId === node?.id)
      node.data = {
        ...node.data,
        taglist: typeof node?.data?.taglist === "string" ? node?.data?.taglist : node?.data?.taglist?.uuid,
        resolution_route: resolutionNodeIndex,
      }
    } else if (node?.data?.nodeType === "ScriptNode") {
      const { feature_mappings, ...rest } = node.data
      const resolutionNodeIndex = filteredNodes.findIndex((innerNode) => innerNode?.data?.parentId === node?.id)
      if (rest?.isBuiltin || !!rest?.script?.template) {
        node.data = {
          ...rest,
          script: typeof node?.data?.script === "string" ? node?.data?.script : node?.data?.script?.uuid,
          computed_feature_name: node?.data?.computed_feature_name,
          is_draft: false,
          resolution_route: resolutionNodeIndex,
          /**
           * first if feature mappings array is empty (no input features found),
           * then we send an empty array
           * else, if it's not empty .. we check on each value (mapped workflow feature)
           * if any of the values is empty we assign it to the same value as the key (script feature)
           * because by default each script feature is mapped to itself from the workflow schema
           */
          feature_mappings: validateFeatureMapping(feature_mappings),
        }
      } else {
        return {
          ...rest,
          script: typeof rest.script === "string" ? rest.script : rest.script?.uuid,
          computed_feature_name: rest.computed_feature_name,
          is_draft: rest.is_draft,
          resolution_route: resolutionNodeIndex,
        }
      }
    } else if (node?.data?.nodeType === "ProjectNode") {
      const { feature_mappings } = node.data
      const resolutionNodeIndex = filteredNodes.findIndex((innerNode) => innerNode?.data?.parentId === node?.id)

      if (resolutionNodeIndex !== -1) {
        node.data = {
          ...node.data,
          project: typeof node?.data?.project === "string" ? node?.data?.project : node?.data?.project?.uuid,
          computed_feature_name: node?.data?.computed_feature_name,
          resolution_route: resolutionNodeIndex,
          feature_mappings: validateFeatureMapping(feature_mappings),
        }
      } else {
        delete node?.data["resolution_route"]
        node.data = {
          ...node.data,
          project: typeof node?.data?.project === "string" ? node?.data?.project : node?.data?.project?.uuid,
          feature_mappings: validateFeatureMapping(feature_mappings),
        }
      }
    } else if (node?.data?.nodeType === "ScorecardsetNode") {
      const resolutionNodeIndex = filteredNodes.findIndex((innerNode) => innerNode?.data?.parentId === node?.id)
      node.data = {
        ...node.data,
        scorecard_set:
          typeof node?.data?.scorecard_set === "string" ? node?.data?.scorecard_set : node?.data?.scorecard_set?.uuid,
        resolution_route: resolutionNodeIndex,
      }
    } else if (node?.type === "FilterNode") {
      const trueFalseNodesIndices = filteredNodes.filter((innerNode) => innerNode?.data?.parentId === node?.id)

      let trueRoute: null | number = null
      let falseRoute: null | number = null
      if (edges && edges?.length > 0) {
        for (let i = 0; i < 2; i++) {
          for (let j = 0; j < edges?.length; j++) {
            if (edges[j]?.target === trueFalseNodesIndices[i]?.id) {
              if (edges[j]?.data?.text === "true") {
                trueRoute = filteredNodes?.findIndex((node) => node?.id === trueFalseNodesIndices[i]?.id)
              } else if (edges[j]?.data?.text === "false") {
                falseRoute = filteredNodes?.findIndex((node) => node?.id === trueFalseNodesIndices[i]?.id)
              }
            }
          }
        }
      }

      const newData = {
        condition: `${convertSingleRuleToCondition<FilterCondition>(node?.data?.rules)}`,
        condition_list_file:
          node?.data?.condition_list_file !== null
            ? node?.data?.condition_list_file?.uuid
            : node?.data?.fileData?.uuid
              ? node?.data?.fileData?.uuid
              : null,
        true_route: trueRoute,
        false_route: falseRoute,
      }

      if (newData?.condition_list_file !== null || node?.data?.fileData?.uuid) {
        node.data = {
          ...node.data,
          ...newData,
        }
      } else {
        const temp = { ...node?.data, ...newData }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { condition_list_file, ...newTempObj } = temp
        node.data = { ...newTempObj }
      }
    }
    return node?.data
  })

  return {
    nodes: [...alteredNodes],
  }
}

// this function return the necessary key for each node depending on its type
// so when each node gets parsed after retrieving the request, it should has all the needed props
// stored inside of it
const nodeDataBasedOnType = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  node: any,
  type: "retrieve" | "edit",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes: any[],
  updateGraph: (updatedData: UpdateGraphParams) => void,
  schema: Schema,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>,
  startNode?: string | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const readMode = type === "retrieve"
  let data: {
    readMode: boolean
    updateGraph: (updatedData: UpdateGraphParams) => void
    parentId?: string
    schema: Schema
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>
  } = {
    readMode: readMode,
    updateGraph,
    schema,
    formik,
  }
  if (node?.uuid === startNode) {
    data = { ...data, parentId: "1" }
  }
  if (node?.node_type === "ruleset") {
    const uncoveredNode = nodes?.find((innerNode) => innerNode?.uuid === node?.uncovered_route?.uuid)
    uncoveredNode["data"] = { readMode, parentId: node?.uuid }
    data = {
      ...data,
      ...node?.data,
      nodeType: node?.ruleset?.type === "DECISION" ? "DecisionNode" : "RulesetNode",
      ruleset: node?.ruleset,
      rulesetName: node?.ruleset?.name,
      rules: node?.ruleset?.rules,
      node_type: "ruleset",
      readMode,
    }
  }

  if (node?.node_type === "calculator") {
    const uncoveredNode = nodes?.find((innerNode) => innerNode?.uuid === node?.resolution_route?.uuid)
    uncoveredNode["data"] = { readMode, parentId: node?.uuid }
    data = {
      ...data,
      ...node?.data,
      nodeType: "CalculatorNode",
      ruleset: node?.ruleset,
      node_type: "calculator",
      computed_feature_name: node?.computed_feature_name,
      equation: node?.equation,
      readMode,
    }
  }
  if (node?.node_type === "scorecardset") {
    const uncoveredNode = nodes?.find((innerNode) => innerNode?.uuid === node?.resolution_route?.uuid)
    uncoveredNode["data"] = { readMode, parentId: node?.uuid }
    data = {
      ...data,
      ...node?.data,
      nodeType: "ScorecardsetNode",
      scorecard_set: node?.scorecard_set,
      node_type: "scorecardset",
      readMode,
    }
  }
  if (node?.node_type === "program") {
    const uncoveredNode = nodes?.find((innerNode) => innerNode?.uuid === node?.resolution_route?.uuid)
    uncoveredNode["data"] = { readMode, parentId: node?.uuid }
    data = { ...data, ...node?.data, nodeType: "ProgramNode", program: node?.program, node_type: "program", readMode }
  }
  if (node?.node_type === "taglist") {
    const uncoveredNode = nodes?.find((innerNode) => innerNode?.uuid === node?.resolution_route?.uuid)

    uncoveredNode["data"] = { readMode, parentId: node?.uuid }
    data = { ...data, ...node?.data, nodeType: "TaglistNode", taglist: node?.taglist, node_type: "taglist", readMode }
  }
  if (node?.node_type === "script") {
    const uncoveredNode = nodes?.find((innerNode) => innerNode?.uuid === node?.resolution_route?.uuid)
    uncoveredNode["data"] = { readMode, parentId: node?.uuid }
    data = {
      ...data,
      ...node?.data,
      nodeType: "ScriptNode",
      feature_mappings: node?.feature_mappings,
      script: node?.script,
      node_type: "script",
      scriptName: node?.script?.name,
      file: node?.script?.file,
      readMode,
      computed_feature_name: node?.computed_feature_name,
    }
  }
  if (node?.node_type === "project") {
    const resolutionNode = nodes?.find((innerNode) => innerNode?.uuid === node?.resolution_route?.uuid)
    if (resolutionNode) {
      resolutionNode["data"] = { readMode, parentId: node?.uuid }
    }
    data = {
      ...data,
      ...node?.data,
      nodeType: "ProjectNode",
      feature_mappings: node?.feature_mappings,
      computed_feature_name: node?.computed_feature_name,
      project: node?.project,
      node_type: "project",
      readMode,
      hasChildren: !!resolutionNode,
    }
  }
  if (node?.node_type === "decision") {
    const uncoveredNode = nodes?.find((innerNode) => innerNode?.uuid === node?.uncovered_route?.uuid)
    uncoveredNode["data"] = { readMode, parentId: node?.uuid }
    data = {
      ...data,
      ...node?.data,
      rulesetName: node?.ruleset?.name,
      rules: node?.ruleset?.rules,
      ruleset: node?.ruleset,
      node_type: "decision",
      nodeType: "DecisionNode",
      readMode,
    }
  }
  if (node?.node_type === "label") {
    data = {
      ...data,
      ...node?.data,
      nodeType: "EndResultNode",
      return_label: node?.return_label,
      node_type: "label",
      readMode,
    }
  }
  if (node?.node_type === "filter") {
    const trueNode = nodes.find((innerNode) => innerNode?.uuid === node?.true_route?.uuid)
    const falseNode = nodes.find((innerNode) => innerNode?.uuid === node?.false_route?.uuid)
    trueNode["data"] = { ...data, ...node?.data, parentId: node?.uuid, is_true_route: true }
    falseNode["data"] = { ...data, ...node?.data, parentId: node?.uuid, is_false_route: true }

    const convertedRule = convertComplexConditionsToNestedForm(node?.condition)

    let filesCount = 0
    // counting files references
    for (const element of convertedRule[0]) {
      if (element?.value === "$file") {
        filesCount++
      }
    }

    return {
      ...data,
      ...node?.data,
      readMode: type === "retrieve",
      editMode: type !== "retrieve",
      filesCount,
      rules: convertedRule[0],
      fileData: { name: node?.condition_list_file?.name, uuid: node?.condition_list_file?.uuid },
      condition: node?.condition,
      condition_list_file: { name: node?.condition_list_file?.name, uuid: node?.condition_list_file?.uuid },
      node_type: "filter",
      nodeType: "FilterNode",
    }
  }
  return data
}

// this function retrieves the returned nodes from the backend and parse the whole nodes and edges array
export const convertNodesToFlow = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes: any,
  startNodeUUID: string | null,
  type: "retrieve" | "edit",
  updateGraph: (updatedData: UpdateGraphParams) => void,
  schema: Schema,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { nodes: any[]; edges: any[] } => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startNode = nodes?.find((node: any) => node?.uuid === startNodeUUID)

  const flowNodes = [
    {
      id: "1",
      data: { nodeType: "StartNode" },
      position: { x: 100, y: 100 },
      type: "SelectionNode",
      draggable: true,
      height: 64,
      width: 320,
    },
    {
      id: startNodeUUID,
      data: nodeDataBasedOnType(startNode, type, nodes, updateGraph, schema, formik, startNodeUUID),
      position: { x: 0, y: 0 },
      height: 64,
      width: 320,
      type:
        mapNodeTypeToFlowFormatEnum[startNode?.node_type as keyof typeof mapNodeTypeToFlowFormatEnum] ?? "LabeledNode",
    },
  ]
  const flowEdges = [
    {
      id: "e1-2",
      source: "1",
      target: startNodeUUID,
      animated: true,
      type: "custom",
      data: { updateGraph, readMode: type === "retrieve" },
      markerEnd,
      style: edgeStyles,
    },
  ]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes?.forEach((node: any) => {
    const CONDITIONAL_NODE_ID = uuidv4()

    node?.uuid !== startNodeUUID &&
      flowNodes.push({
        id: node?.uuid,
        height: 64,
        width: 320,
        data: nodeDataBasedOnType(node, type, nodes, updateGraph, schema, formik),
        position: { x: 0, y: 0 },
        type: mapNodeTypeToFlowFormatEnum[node?.node_type as keyof typeof mapNodeTypeToFlowFormatEnum] ?? "LabeledNode",
      })
    if (type === "edit" && node?.node_type === "project" && node?.resolution_route === null) {
      flowNodes.push({
        id: CONDITIONAL_NODE_ID,
        height: 64,
        width: 320,
        data: { updateGraph, readMode: false, parentId: node?.uuid, parentNodeType: "project", schema, formik },
        position: { x: 0, y: 0 },
        type: mapNodeTypeToFlowFormatEnum["addBlock"],
      })
    }
    if (node?.node_type === "ruleset" || node?.node_type === "decision") {
      const uncoverdRouteEdge = {
        id: `${uuidv4()}`,
        source: node?.uuid,
        animated: false,
        target: node?.uncovered_route?.uuid,
        data: { text: "Uncovered", updateGraph, readMode: type === "retrieve" },
        type: "custom",
        markerEnd,
        style: edgeStyles,
      }
      flowEdges.push(uncoverdRouteEdge)
    } else if (["program", "calculator", "scorecardset", "script", "taglist"].includes(node?.node_type)) {
      const resolvesToRouteEdge = {
        id: `${uuidv4()}`,
        source: node?.uuid,
        animated: false,
        target: node?.resolution_route?.uuid,
        data: { updateGraph, readMode: type === "retrieve" },
        type: "custom",
        markerEnd: markerEnd,
        style: edgeStyles,
        labelShowBg: false,
      }
      flowEdges.push(resolvesToRouteEdge)
    } else if (node?.node_type === "project") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const resolutionNode = nodes?.find((innerNode: any) => innerNode?.uuid === node?.resolution_route?.uuid)
      if (resolutionNode || (type === "edit" && !resolutionNode)) {
        const resolvesToRouteEdge = {
          id: `${uuidv4()}`,
          source: node?.uuid,
          animated: false,
          sourceHandle: "a",
          target: type === "edit" && !resolutionNode ? CONDITIONAL_NODE_ID : node?.resolution_route?.uuid,
          type: "custom",
          data: { updateGraph, readMode: type === "retrieve" },
          markerEnd: markerEnd,
          style: edgeStyles,
        }
        flowEdges.push(resolvesToRouteEdge)
      }
    } else if (node?.node_type === "filter") {
      const trueRouteEdge = {
        id: `${uuidv4()}`,
        source: node?.uuid,
        animated: false,
        target: node?.true_route?.uuid,
        sourceHandle: "a",
        type: "custom",
        data: { text: "true", updateGraph, readMode: type === "retrieve" },
        markerEnd,
        style: edgeStyles,
      }
      const falseRouteEdge = {
        id: `${uuidv4()}`,
        source: node?.uuid,
        target: node?.false_route?.uuid,
        sourceHandle: "b",
        animated: false,
        type: "custom",
        data: { text: "false", updateGraph, readMode: type === "retrieve" },
        markerEnd,
        style: edgeStyles,
      }
      flowEdges.push(trueRouteEdge, falseRouteEdge)
    }
  })
  return { nodes: flowNodes, edges: flowEdges }
}

export const nodeBuilder = (
  parentId: string,
  nodeType: string,
  updateGraph: (data: UpdateGraphParams) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (nodeType === "DecisionNode" || nodeType === "RulesetNode") {
    return {
      id: uuidv4(),
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        updateGraph,
        ruleset: "",
        uncovered_route: null,
        node_type: "ruleset",
        nodeType,
        rulesets: [],
        parentId,
      },
    }
  } else if (nodeType === "ScriptNode") {
    return {
      id: uuidv4(),
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        updateGraph,
        script: "",
        feature_mappings: [],
        node_type: "script",
        computed_feature_name: "",
        nodeType,
        scripts: [],
        resolution_route: null,
        file: "",
        is_draft: true,
        parentId,
      },
    }
  } else if (nodeType === "CalculatorNode") {
    return {
      id: uuidv4(),
      position: {
        x: 0,
        y: 0,
      },
      type: "CalculatorNode",
      data: {
        node_type: "calculator",
        computed_feature_name: "",
        equation: "",
        feature_nesting_operator: ".",
        updateGraph,
        nodeType,
        parentId,
      },
    }
  } else if (nodeType === "EndResultNode") {
    return {
      id: uuidv4(),
      position: {
        x: 0,
        y: 0,
      },
      type: "LabeledNode",
      data: {
        node_type: "label",
        return_label: {
          name: "",
          uuid: "",
        },
        nodeType,
        updateGraph,
        parentId,
      },
    }
  } else if (nodeType === "ScorecardsetNode") {
    return {
      id: uuidv4(),
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        updateGraph,
        scorecard_set: "",
        resolution_route: null,
        node_type: "scorecardset",
        nodeType,
        parentId,
      },
    }
  } else if (nodeType === "ProgramNode") {
    return {
      id: uuidv4(),
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        updateGraph,
        program: "",
        node_type: "program",
        nodeType,
        resolution_route: null,
        parentId,
      },
    }
  } else if (nodeType === "TaglistNode") {
    return {
      id: uuidv4(),
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        updateGraph,
        taglist: "",
        node_type: "taglist",
        nodeType,
        resolution_route: null,
        parentId,
      },
    }
  } else if (nodeType === "ProjectNode") {
    return {
      id: uuidv4(),
      position: {
        x: 0,
        y: 0,
      },
      type: "SelectionNode",
      data: {
        updateGraph,
        project: "",
        computed_feature_name: "",
        node_type: "project",
        nodeType,
        resolution_route: null,
        parentId,
        feature_mappings: [],
      },
    }
  }
}

// when removing a node with one or many descendants, we check on each node's parentID property that is equal
// to the node's ID we want to remove, then go to the recursive calls that remove each descendant of the descendants
// of our parent node, finally we update our instance of the nodes array and return it.
const deleteDescendants = (nodeId: string | null, nodes: Node[], updatedNodes: Node[]): Node[] => {
  const descendants = nodes.filter((node) => node?.data?.parentId === nodeId)

  if (descendants?.length > 0) {
    descendants.forEach((descendant) => {
      updatedNodes.splice(updatedNodes.indexOf(descendant), 1)
      deleteDescendants(descendant?.id, nodes, updatedNodes)
    })
  }

  return updatedNodes
}

const propertyNameBasedOnNodeType = (type: string): string => {
  switch (type) {
    case "ruleset":
      return "ruleset"
    case "calculator":
      return "calculator"
    case "filter":
      return "condition"
    case "label":
      return "return_label"
    case "decision":
      return "decision"
    case "program":
      return "program"
    case "taglist":
      return "taglist"
    case "project":
      return "project"
    case "scorecardset":
      return "scorecardset"
    case "script":
      return "script"
    default:
      return "ruleset"
  }
}

/**
 * Checks if the values of two script nodes are equivalent. The function specifically compares the script UUID
 * and feature mappings between two nodes.
 *
 * @param updatedNode The updated node object which includes script and feature mappings details.
 * @param retrievedNode The node object retrieved from a database or API, to compare against the updated node.
 * @returns {boolean} Returns `true` if the nodes are considered equivalent, otherwise `false`.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const areScriptNodeValuesEqual = (updatedNode: any, retrievedNode: any): boolean => {
  // Compare the script UUIDs of both nodes; if they don't match, return false.
  if (updatedNode?.script !== retrievedNode?.script?.uuid) {
    return false
  }

  // Check if either node has feature mappings; proceed to detailed comparison if so.
  else if (updatedNode?.feature_mappings?.length > 0 || retrievedNode?.feature_mappings?.length > 0) {
    // Compare the lengths of the feature mappings arrays; if they don't match, return false.
    if (updatedNode?.feature_mappings?.length !== retrievedNode?.feature_mappings?.length) {
      return false
    } else {
      // Iterate over each object in the feature mappings arrays and compare specific properties.
      return updatedNode?.feature_mappings.every((item1: FeatureMapping, index: number) => {
        const item2 = retrievedNode?.feature_mappings[index]
        // Compare both 'node_feature' and 'workflow_feature' properties of the mapping objects.
        return item1?.node_feature === item2?.node_feature && item1?.workflow_feature === item2?.workflow_feature
      })
    }
  }
  // If there are no feature mappings to compare, return true as the script UUIDs are already confirmed to match.
  return true
}

const areNodesEqual = (retrievedNodes: Node[] | undefined, currentNodes: Node[]): boolean => {
  const nodesToSubmit = parseWorkflow(currentNodes)
  let equal = true
  if (currentNodes && retrievedNodes && retrievedNodes?.length === nodesToSubmit?.nodes?.length) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retrievedNodes?.forEach((node: any) => {
      // eslint-disable-next-line array-callback-return, @typescript-eslint/no-explicit-any
      const existingNode = nodesToSubmit?.nodes?.find((innerNode: any) => {
        if (innerNode?.node_type === node?.node_type) {
          const property = propertyNameBasedOnNodeType(innerNode?.node_type)

          if (
            property === "ruleset" || property === "decision"
              ? innerNode?.ruleset === node?.ruleset?.uuid
              : property === "scorecardset"
                ? innerNode?.scorecard_set === node?.scorecard_set?.uuid
                : property === "program"
                  ? innerNode?.program === node?.program?.uuid
                  : property === "taglist"
                    ? innerNode?.taglist === node?.taglist?.uuid
                    : property === "project"
                      ? innerNode?.project === node?.project?.uuid
                      : property === "script"
                        ? areScriptNodeValuesEqual(innerNode, node)
                        : property === "calculator"
                          ? innerNode?.equation === node?.equation &&
                            innerNode?.computed_feature_name === node?.computed_feature_name
                          : property === "condition" &&
                              innerNode?.condition === node?.condition &&
                              innerNode?.condition_list_file &&
                              node?.condition_list_file
                            ? innerNode?.condition_list_file?.uuid === node?.condition_list_file?.uuid
                            : innerNode[property] === node[property]
          ) {
            return innerNode
          }
        }
      })
      if (existingNode) {
        nodesToSubmit?.nodes?.splice(nodesToSubmit?.nodes?.indexOf(existingNode), 1)
      } else {
        equal = false
        return false
      }
    })
  } else {
    equal = false
    return equal
  }
  return equal
}

const validParenthesis = (equation: string): boolean => {
  let openCount = 0
  let closeCount = 0

  for (const element of equation) {
    if (element === "(") {
      openCount++
    } else if (element === ")") {
      closeCount++
    }
  }

  return openCount === closeCount
}

// helper function to indicate workflow have any incomplete nodes or not
const isWorkflowInComplete = (nodes: Node[]): boolean => {
  const addBlockNode = nodes.find((node: Node) => node.type === "AddBlockNode")
  if (addBlockNode) {
    const parentNode = nodes.find((node: Node) => node?.id === addBlockNode?.data?.parentId)
    if (parentNode && parentNode?.data?.node_type !== "project") {
      return true
    }
  }

  return false
}

/**
 * this helper function extracts only the relevant key fields in each node's data to prepare it
 * to be sent in the create/update workflow payload
 * @param  {WorkflowNode} node
 * @returns {WorkflowNode}
 */
const filterNodesKeys = (node: WorkflowNode): WorkflowNode => {
  const rulesetNodeKeys = ["node_type", "uncovered_route", "ruleset"]
  const filterNodeKeys = [
    "node_type",
    "feature_nesting_operator",
    "true_route",
    "false_route",
    "condition",
    "condition_list_file",
  ]
  const taglistNodeKeys = ["taglist", "node_type", "resolution_route"]
  const scorecardsetNodeKeys = ["scorecard_set", "node_type", "resolution_route"]
  const programNodeKeys = ["program", "node_type", "resolution_route"]
  const calculatorNodeKeys = [
    "node_type",
    "computed_feature_name",
    "equation",
    "feature_nesting_operator",
    "resolution_route",
  ]
  const scriptNodeKeys = ["node_type", "computed_feature_name", "script", "feature_mappings", "resolution_route"]
  const projectNodeKeys = ["node_type", "computed_feature_name", "project", "feature_mappings", "resolution_route"]
  const labelNodeKeys = ["node_type", "return_label"]

  let validKeys: string[]

  switch (node.node_type) {
    case "ruleset":
      validKeys = rulesetNodeKeys
      break
    case "filter":
      validKeys = filterNodeKeys
      break
    case "taglist":
      validKeys = taglistNodeKeys
      break
    case "scorecardset":
      validKeys = scorecardsetNodeKeys
      break
    case "program":
      validKeys = programNodeKeys
      break
    case "calculator":
      validKeys = calculatorNodeKeys
      break
    case "script":
      validKeys = scriptNodeKeys
      break
    case "project":
      validKeys = projectNodeKeys
      break
    case "label":
      validKeys = labelNodeKeys
      break
    default:
      validKeys = []
  }

  const filteredNode: WorkflowNode = {} as WorkflowNode

  validKeys?.forEach((key) => {
    if (node.hasOwnProperty(key)) {
      filteredNode[key as keyof WorkflowNode] = node[key as keyof WorkflowNode]
    }
  })

  return filteredNode
}

const validateWorkflow = (
  nodes: Node[],
  workflowsData?: WorkflowList[],
  workflowName?: string,
  type?: "update" | "create",
  edges?: Edge[],
  retrievedWorkflowName?: string | undefined,
): void | { nodes: Array<WorkflowNode>; startNode: number } => {
  const areNodesInComplete = isWorkflowInComplete(nodes)
  const duplicateNames = workflowsData?.filter((wf: WorkflowList) => {
    if (type === "update") {
      return wf?.name === workflowName && workflowName !== retrievedWorkflowName
    } else {
      return wf?.name === workflowName
    }
  })
  const correctFormat = parseWorkflow([...nodes], edges)
  let incompleteNodes = null
  const ISOpattern = /^\(\d{4}-\d{2}-\d{2}\)$/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  correctFormat?.nodes?.forEach((node: any) => {
    if (node?.node_type === "ruleset" && !node?.ruleset) {
      incompleteNodes = "Please choose a ruleset!"
    } else if (node?.node_type === "scorecardset" && !node?.scorecard_set) {
      incompleteNodes = "Please choose a scorecardset!"
    } else if (node?.node_type === "program" && !node?.program) {
      incompleteNodes = "Please choose a program!"
    } else if (node?.node_type === "taglist" && !node?.taglist) {
      incompleteNodes = "Please choose a taglist!"
    } else if (node?.node_type === "project" && !node?.project) {
      incompleteNodes = "Please choose a project!"
    } else if (node?.node_type === "decision" && !node?.ruleset) {
      incompleteNodes = "Please choose a decision ruleset!"
    } else if (node?.node_type === "label" && !node?.return_label) {
      incompleteNodes = "Please add a return label!"
    } else if (node?.node_type === "filter" && node?.rulesValidations?.rules?.length > 0) {
      const filteredErrors = node?.rulesValidations?.rules?.filter((rule) => !!rule)
      incompleteNodes = `${Object.values(filteredErrors[0])[0]} in filter`
    } else if (
      node?.node_type === "calculator" &&
      (!node?.computed_feature_name || !node?.equation || node?.equation === "()")
    ) {
      incompleteNodes = "Please add feature name and equation in calculator node!"
    } else if (node?.equation === "(TODAY)" || ISOpattern.test(node?.equation)) {
      incompleteNodes = "Equation can't be a single date"
    } else if (node?.node_type === "calculator" && !validParenthesis(node?.equation)) {
      incompleteNodes = "Unable to parse, Expression has invalid parenthesis combination"
    } else if (node?.node_type === "label" && !node?.return_label.uuid) {
      incompleteNodes = `Label ${
        "(" + node?.return_label.name + ")"
      }: Choose an item from the dropdown to select a label`
    } else if (node?.node_type === "script" && node?.is_draft) {
      incompleteNodes = "Script node is draft, please save it first then add it to the workflow or remove it!"
    } else if (node?.node_type === "calculator" && node?.computedNameError) {
      incompleteNodes = `${node?.computedNameError}`
    }
  })

  if (areNodesInComplete) {
    NotificationUtils.toast("Workflow incomplete, please connect all workflow nodes!", { snackBarVariant: "negative" })
  } else if (incompleteNodes) {
    NotificationUtils.toast(incompleteNodes, { snackBarVariant: "negative" })
  } else if (duplicateNames && duplicateNames?.length > 0) {
    NotificationUtils.toast("Can't submit a duplicated workflow name, please change the workflow name!", {
      snackBarVariant: "negative",
    })
  } else {
    // getting the valid and filtered nodes
    const filteredNodes = correctFormat?.nodes?.map((node: WorkflowNode) => filterNodesKeys(node))

    // getting the start node
    const startNode = correctFormat?.nodes?.findIndex(
      (node: WorkflowNode & { parentId: string }) => node?.parentId === "1",
    )

    return { nodes: filteredNodes, startNode }
  }
}

export const handleCreateWorkflow = async (
  workflowName: string,
  nodes: Node[],
  workflowsData: WorkflowList[],
  createWorkflowMutation: UseMutationResult<AxiosResponse, AxiosError, CreateWorkflowRequest, unknown>,
  currentProjectId: string,
  setIsCreateMode: Dispatch<SetStateAction<boolean>>,
  edges: Edge[],
  schema: Array<SchemaFeature>,
): Promise<Node[] | undefined> => {
  try {
    const validWorkflow = validateWorkflow([...nodes], workflowsData, workflowName, "create", edges)

    if (validWorkflow && validWorkflow?.nodes?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const clonedNodes = (JSON.parse(JSON.stringify(validWorkflow?.nodes)) as any[]) ?? []
      clonedNodes?.forEach((node) => {
        if (node.node_type === "label") node.return_label = node.return_label.uuid
      })

      if (validWorkflow?.nodes) {
        if (schema?.length > 0) {
          await createWorkflowMutation.mutateAsync({
            name: workflowName,
            state:
              workflowsData?.length > 0 ? WorkflowCreateRequest.state.DISABLED : WorkflowCreateRequest.state.ACTIVE,
            start_node: validWorkflow?.startNode && validWorkflow?.startNode !== -1 ? validWorkflow?.startNode : 0,
            nodes: clonedNodes?.length ? [...clonedNodes] : [...(validWorkflow?.nodes ?? [])],
            projectUUID: currentProjectId,
            schema,
          })
        } else {
          await createWorkflowMutation.mutateAsync({
            name: workflowName,
            state:
              workflowsData?.length > 0 ? WorkflowCreateRequest.state.DISABLED : WorkflowCreateRequest.state.ACTIVE,
            start_node: validWorkflow?.startNode && validWorkflow?.startNode !== -1 ? validWorkflow?.startNode : 0,
            nodes: clonedNodes?.length ? [...clonedNodes] : [...(validWorkflow?.nodes ?? [])],
            projectUUID: currentProjectId,
          })
        }

        setIsCreateMode(false)
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e?.response?.data?.details) {
      NotificationUtils.toast(`${e?.response?.data?.details}`, {
        snackBarVariant: "negative",
      })
    } else if (e?.response?.data?.nodes?.length > 0) {
      NotificationUtils.toast(`${e?.response?.data?.nodes[0]}`, {
        snackBarVariant: "negative",
      })
    } else if (e?.response?.data?.feature_mappings?.length > 0) {
      NotificationUtils.toast(`${e?.response?.data?.feature_mappings[0]}`, {
        snackBarVariant: "negative",
      })
    } else if (e?.response?.data?.non_field_errors?.length > 0) {
      NotificationUtils.toast(`${e?.response?.data?.non_field_errors[0]}`, {
        snackBarVariant: "negative",
      })
    } else {
      NotificationUtils.toast("An error occurred while creating this workflow, please try again!", {
        snackBarVariant: "negative",
      })
    }

    setIsCreateMode(true)
    return nodes
  }
}

// Function to compare two objects
function areSchemaObjectsDifferent(obj1: SchemaFeature, obj2: SchemaFeature): boolean {
  return (
    obj1.is_required !== obj2.is_required ||
    obj1.name !== obj2.name ||
    obj1.source !== obj2.source ||
    obj1.type !== obj2.type
  )
}

/**
 * Indicator for schema changes, used when we want to detect schema changes to decide whether
 * it should be included in the payload or not
 * @param currentSchema the current form for schema
 * @param retrievedSchema the original form (retrieved) for schema
 * @returns {boolean}
 */
export function hasSchemaChanged(currentSchema: SchemaFeature[], retrievedSchema: SchemaFeature[]): boolean {
  // Check if arrays have same length
  if (currentSchema?.length !== retrievedSchema?.length || !currentSchema || !retrievedSchema) {
    return true
  }

  // Sort both arrays based on the 'name' property
  currentSchema.sort((a, b) => a.name.localeCompare(b.name))
  retrievedSchema.sort((a, b) => a.name.localeCompare(b.name))

  // Iterate through arrays and compare each object's properties
  for (let i = 0; i < currentSchema.length; i++) {
    const obj1 = currentSchema[i]
    const obj2 = retrievedSchema[i]

    if (areSchemaObjectsDifferent(obj1, obj2)) {
      return true // If a difference is found, return true immediately
    }
  }

  return false // If no differences are found, return false
}

export const handleUpdateWorkflow = async (
  projectUUID: string,
  workflowUUID: string,
  updateWorkflowMutation: UseMutationResult<AxiosResponse, AxiosError, UpdateWorkflowRequest, unknown>,
  setIsEditMode: Dispatch<SetStateAction<boolean>>,
  workflowName: string,
  edges: Edge[],
  workflowsData: WorkflowList[],
  nodes: Node[],
  retrievedWorkflowName: string | undefined,
  schema: Array<SchemaFeature>,
  setUpdatePayload: Dispatch<SetStateAction<UpdateWorkflowRequest | undefined>>,
  activeWorkflow?: WorkflowVersionsRetrieve,
): Promise<Node[] | undefined> => {
  const validWorkflow = validateWorkflow(nodes, workflowsData, workflowName, "update", edges, retrievedWorkflowName)

  if (validWorkflow && validWorkflow?.nodes?.length > 0) {
    // TODO: change this any type later when backend have proper type for nodes
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clonedNodes = (JSON.parse(JSON.stringify(validWorkflow?.nodes)) as any[]) ?? []

    clonedNodes.forEach((node) => {
      if (node.node_type === "label") node.return_label = node.return_label.uuid
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const areAllNodesEqual = areNodesEqual(activeWorkflow?.nodes as any, nodes)

    try {
      const features: Array<WorkflowSchemaFeature> = []
      schema?.forEach((elm) => {
        const objForm = {
          name: elm?.name?.trim(),
          type: elm?.type,
          is_required: elm?.is_required,
          source: elm?.source as WorkflowSchemaFeature.source,
        }
        features?.push(objForm)
      })

      if (areAllNodesEqual && validWorkflow?.nodes?.length > 0) {
        const baseMutationParams = {
          name: workflowName,
          projectUUID,
          workflowUUID,
        }

        const mutationParams = hasSchemaChanged(features, activeWorkflow?.schema?.features as SchemaFeature[])
          ? { ...baseMutationParams, schema: [...features] }
          : baseMutationParams

        setUpdatePayload(mutationParams)
        await updateWorkflowMutation.mutateAsync(mutationParams)
      } else if (nodes && validWorkflow?.nodes?.length) {
        const baseMutationParams = {
          name: workflowName,
          start_node: validWorkflow?.startNode && validWorkflow?.startNode !== -1 ? validWorkflow?.startNode : 0,
          nodes: clonedNodes,
          projectUUID,
          workflowUUID,
        }

        const mutationParams = hasSchemaChanged(features, activeWorkflow?.schema?.features as SchemaFeature[])
          ? { ...baseMutationParams, schema: [...features] }
          : baseMutationParams

        setUpdatePayload(mutationParams)
        await updateWorkflowMutation.mutateAsync(mutationParams)
      }
    } catch (e) {
      console.warn(e)
      setIsEditMode(true)
      return nodes
    }
  }
}

export const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = "TB"): void => {
  const dagreGraph = new dagre.graphlib.Graph()
    .setGraph({ rankdir: direction, ranksep: 100, nodesep: 150 })
    .setDefaultEdgeLabel(() => ({}))

  nodes.forEach((node: Node) => {
    let nodeWidth = 350
    let nodeHeight = 100 // Use dynamic height if available

    if (node?.type === "FilterNode") {
      nodeHeight = node?.data?.readMode ? 100 + node?.data?.rules?.length * 90 : 100 + node?.data?.rules?.length * 130
      nodeWidth = 750 // Ensuring width is set for FilterNode
    } else if (node?.type === "AddBlockNode") {
      nodeWidth = 125
      nodeHeight = 60
    } else if (node?.type === "CalculatorNode") {
      nodeWidth = 453
    }

    // Add padding to nodes
    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height: nodeHeight,
    })
  })

  edges.forEach((edge: Edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node: Node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    let nodeWidth = 350
    let nodeHeight = 100 // Use dynamic height if available

    if (node?.type === "FilterNode") {
      nodeHeight = node?.data?.readMode ? 100 + node?.data?.rules?.length * 90 : 100 + node?.data?.rules?.length * 130
      nodeWidth = 750 // Ensuring width is set for FilterNode
    } else if (node?.type === "AddBlockNode") {
      nodeWidth = 125
      nodeHeight = 60
    } else if (node?.type === "CalculatorNode") {
      nodeWidth = 453
    }

    if (node && node.targetPosition && node.sourcePosition) {
      node.targetPosition = Position?.Top
      node.sourcePosition = Position.Bottom
    }

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }

    node.data = {
      ...node.data,
      height: nodeHeight, // Store dynamic height
    }

    return node
  })
}

export const findBiggerVersion = (num: number, arr: (number | string)[]): number | string => {
  let maxVersion: number | string = num
  let foundFloatVersion = false

  for (const element of arr) {
    const arrNum = typeof element === "number" ? element : parseFloat(element)

    if (arrNum === num) {
      continue // skip checking the original number
    }

    if (arrNum > maxVersion && arrNum < num + 1) {
      // check if arrNum is between the original number and the next integer
      foundFloatVersion = true
      maxVersion = arrNum
    } else if (arrNum === Math.floor(num)) {
      maxVersion = num // the array contains the original number as an integer, return it
      break
    }
  }

  return foundFloatVersion ? maxVersion : num
}

export const handleRemoveNodeAndEdges = (
  nodeId: string | null,
  nodes: Node[],
  deleteElements: ({ nodes, edges }: { nodes?: Node[]; edges?: Edge[] }) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setNodes: (nodes: Node<any, string | undefined>[]) => void,
): void => {
  // getting all the updated nodes that we want to keep in the next state update
  const updatedNodes = deleteDescendants(nodeId, nodes, [...nodes])

  // getting all the nodes that are about to be deleted
  const deletedNodes = nodes.filter((node) => !updatedNodes.some((updatedNode) => updatedNode.id === node.id))

  // passing the deletedNodes to deleteElements function and it will auto-delete all connected edges
  deleteElements({
    nodes: deletedNodes,
  })

  // setting the state with the updated nodes and changing the type of removed node with addBlock node
  setNodes(
    updatedNodes.map((node) => {
      const newNode = { ...node }

      if (node.id === nodeId) {
        newNode.type = "AddBlockNode"

        // Destructure the data object from newNode
        const { data } = newNode

        // Extract the desired properties from data
        const { updateGraph, parentId, formik, schema } = data

        // Assign the filtered data back to newNode
        newNode.data = { updateGraph, parentId, formik, schema }
      }

      return newNode
    }),
  )
}

export function getWorkflowByUUID(
  uuid: string | undefined,
  workflows: Array<WorkflowList> | undefined,
): WorkflowList | null {
  if (!workflows || !uuid) return null
  return workflows?.find((workflow) => workflow.uuid === uuid) || null
}

export const getEdgeLabel = (nodeType: string, index: number): string => {
  const edgeLabels = {
    ruleset: "Uncovered",
    filter: ["true", "false"],
    none: "",
  }
  if (["RulesetNode", "DecisionNode"].includes(nodeType)) {
    return edgeLabels["ruleset"]
  } else if (nodeType === "FilterNode") {
    return edgeLabels["filter"][index]
  } else {
    return edgeLabels["none"]
  }
}

export const isStaticAnalysisValid = (text: string): { valid: boolean; reason?: string } => {
  if (!text?.includes("main()")) {
    return { valid: false, reason: "Can't remove main function from script" }
  }
  return { valid: true }
}

/**
 * Loops through list to find item with given key that matches query key
 * then returned trimmed name w/underscores instead of spaces of this item name
 * @param list list of items we iterating on, can be list of strings or objects
 * @param word
 * @param key
 * @returns {string}
 */
export function getTrimmedComputedFeatureName<T>(list: Array<T> | undefined, word: string, key: keyof T): string {
  const item = list?.find((item) => item[key] === word)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nonTrimmedName = (item as any)?.name
  return nonTrimmedName?.replace(/\s+/g, "_")
}

/**
 * Transforms a list of schema features to a structured format. Each schema feature is
 * mapped to a new object that includes the feature's name, type, and the names of the
 * workflows sourced from the feature. The transformation capitalizes on utilizing
 * the feature's inherent properties to organize and prepare them for further processing
 * or display.
 *
 * @param {Array<WorkflowDeploymentSchemaFeature>} schemaFeatures - An array of `WorkflowDeploymentSchemaFeature` objects representing
 * the schema features to be mapped.
 * @returns {Array<FeatureData>} An array of `FeatureData` objects. Each object includes the `name` of the
 * feature, its `type`, and an array of `workflows` names derived from the feature's source workflows.
 */
export const mappedSchemaFeatures = (schemaFeatures: Array<WorkflowDeploymentSchemaFeature>): Array<FeatureData> => {
  return schemaFeatures?.map((feature) => ({
    name: feature.name,
    type: feature.type,
    workflows: [...feature?.source_workflows?.map((feat) => feat?.name)],
  }))
}

/**
 * Processes an array of computed features to group them by their names and associate
 * each group with its corresponding workflows. If a feature name already exists in the
 * accumulator, the workflow name is added to the existing group. Otherwise, a new group
 * is created for that feature name. This function is particularly useful for aggregating
 * computed feature names and their respective workflows, aiding in data organization and
 * retrieval. It also ensures each feature name is formatted with the first letter capitalized
 * and the remaining letters in lowercase for consistency.
 *
 * @param {Array<ComputedFeatures> | null} computedFeatures - An optional array of `ComputedFeatures` objects representing
 * the computed features to be processed. If null or empty, the function returns `void`.
 * @returns {Array<FeatureData>} An array of `FeatureData` objects, or `void` if `computedFeatures` is null or empty.
 * Each `FeatureData` object contains the capitalized `name` of the feature, an array of `workflows`
 * associated with that feature, and a `type` defaulted to `WorkflowSchemaFeature.type.UNDEFINED`.
 */
export const mappedComputedFeaturesNames = (computedFeatures: Array<ComputedFeatures> | null): Array<FeatureData> => {
  if (computedFeatures && computedFeatures?.length > 0) {
    return computedFeatures.reduce(
      (formattedComputedFeatures: FeatureData[], currentComputedFeature: ComputedFeatures) => {
        currentComputedFeature.computed_feature_names.forEach((featureName: string) => {
          const featureIndex = formattedComputedFeatures.findIndex(
            (feature: FeatureData) => feature.name.toLowerCase() === featureName.toLowerCase(),
          )
          if (featureIndex !== -1) {
            formattedComputedFeatures[featureIndex]?.workflows.push(currentComputedFeature.workflow_name)
          } else {
            formattedComputedFeatures.push({
              name: featureName,
              workflows: [currentComputedFeature.workflow_name],
              type: WorkflowSchemaFeature.type.UNDEFINED,
            })
          }
        })
        return formattedComputedFeatures
      },
      [],
    )
  }
  return []
}

/**
 * Handles the deletion of selected nodes and their associated edges in a graph.
 * This function updates the edges to remove any connections to the deleted nodes,
 * and then updates the nodes to reflect these deletions. It automatically manages
 * the reconnection of nodes that were indirectly connected through the deleted nodes
 * by creating new edges between them. This function is designed to be reused in any
 * part of the application where node and edge deletion with reconnection logic is required.
 *
 * @param {Node[]} deleted - An array of Node objects that are to be deleted.
 * @param {Edge[]} edges - The current array of Edge objects in the graph.
 * @param {Node[]} getNodes - A function that returns the current array of Node objects.
 * @param {string} nodeId - The ID of the node being directly interacted with or deleted.
 * @return {object} {nodes:Node[], edges:Edge[]}
 */
export function handleNodesDelete(
  deleted: Node[],
  edges: Edge[],
  nodes: Node[],
  nodeId: string,
): { nodes: Node[]; edges: Edge[] } {
  // Process edges and capture the resulting edges array
  const newEdges = deleted.reduce((acc, node) => {
    /**
     * We create another array of edges by flatMapping over the array of incomers.
     * These are nodes that were connected to the deleted node as a source.
     * For each of these nodes, we create a new edge that connects to each node in the array of outgoers.
     * These are nodes that were connected to the deleted node as a target.
     */
    const incomers = getIncomers(node, nodes, edges)
    const outgoers = getOutgoers(node, nodes, edges)

    // getConnectedEdges gives us all the edges connected to a node, either as source or target.
    const connectedEdges = getConnectedEdges([node], edges)

    // We create a new array of edges - remainingEdges -
    // that contains all the edges in the flow that have nothing to do with the node(s) we just deleted.
    const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge))

    // Instead of creating entirely new edges, we find the original edge from the incomer to the deleted node
    // and modify its target to point to each outgoer. Preserve all original edge data.
    const createdEdges = incomers.flatMap((incomer) =>
      outgoers.map((outgoer) => {
        // Find the original edge that connected the incomer to the deleted node.
        const originalEdge = edges.find((edge) => edge.source === incomer.id && edge.target === node.id)
        // If there's no original edge (which should theoretically never happen in this setup), create a new edge.
        // Otherwise, create a modified copy of the original edge with a new target.
        return originalEdge
          ? { ...originalEdge, target: outgoer.id, id: `${incomer.id}->${outgoer.id}` }
          : { id: `${incomer.id}->${outgoer.id}`, source: incomer.id, target: outgoer.id }
      }),
    )
    // this is to make sure always the true branch (sourceHandle = a), always comes first before
    // false branch (sourceHandle = b), otherwise edges in filter will visually swap
    if (createdEdges[0]?.data?.text === "true" && createdEdges[0]?.sourceHandle === "a") {
      return [...createdEdges, ...remainingEdges]
    } else {
      return [...remainingEdges, ...createdEdges]
    }
  }, edges)

  // Process nodes and capture the resulting nodes array
  const newNodes = nodes
    .map((node) => {
      const newNode = { ...node }
      if (node?.data?.parentId === nodeId) {
        newNode.data = {
          ...newNode.data,
          parentId: deleted[0]?.data?.parentId,
        }
      }
      return newNode
    })
    .filter((node) => node.id !== nodeId)

  // Return an object with the new nodes and edges arrays
  return {
    nodes: newNodes,
    edges: newEdges,
  }
}

export function filteredFeaturesBasedOnType(
  feature: string,
  features: Array<SchemaFeature>,
  inputFeatures: ScriptSchemaFeature[],
): Array<SchemaFeature> {
  const existingFeatureType = inputFeatures?.find((feat) => feat?.name === feature)?.type

  if (existingFeatureType === WorkflowSchemaFeature.type.UNDEFINED) {
    return features
  } else {
    return features?.filter(
      (feat) => feat?.type === existingFeatureType || feat?.type === WorkflowSchemaFeature.type.UNDEFINED,
    )
  }
}
