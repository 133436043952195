import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import AutoSizer from "react-virtualized-auto-sizer"
import { VariableSizeList as List } from "react-window"

import SearchIcon from "@mui/icons-material/Search"
import { Grid } from "@mui/material"
import { DateRangePicker, InputText, ThemeProvider, Typography, darkTheme } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../hooks/UseTheme"
import { useWindowSize } from "../hooks/useWindowResize"
import { KonanTimeHelper } from "../utils/genericHelpers"
import { KonanRowRender } from "./KonanRowRender"
import { LoadingContainer } from "./LoadingContainer"

import styles from "./KonanLogViewer.module.scss"

type Props = {
  data: string[]
  isLoading?: boolean
  type?: string
  enableSearch?: boolean
  customBorderRadius?: string
  scriptNodeView?: boolean
  minHeight?: string
  enableDatePicker?: boolean
  startDate?: moment.Moment | null
  endDate?: moment.Moment | null
  onStartDateChange?: React.Dispatch<React.SetStateAction<moment.Moment | null>>
  onEndDateChange?: React.Dispatch<React.SetStateAction<moment.Moment | null>>
  isOutSideRange?: (date: moment.Moment) => boolean
}

export function KonanLogViewer(props: Readonly<Props>): React.ReactElement {
  const {
    data,
    enableDatePicker,
    isLoading,
    type,
    enableSearch = true,
    customBorderRadius,
    scriptNodeView,
    minHeight,
    endDate,
    onEndDateChange,
    onStartDateChange,
    isOutSideRange,
    startDate,
  } = props

  const konanTime = new KonanTimeHelper()

  const [searchValue, setSearchValue] = useState<string>("")

  const [isAtBottom, setIsAtBottom] = useState<boolean>(true)
  const [jumpedOnce, setJumpedOnce] = useState<boolean>(false)

  const modifiedData = useMemo(() => {
    return data.filter((s) => s.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) || ""
  }, [data, searchValue])

  const logRef = useRef<List>(null)
  const [windowWidth] = useWindowSize()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sizeMap = useRef<any>({})
  const setSize = useCallback(
    (index: number, size: number) => {
      if (sizeMap?.current) {
        sizeMap.current = { ...sizeMap.current, [index]: size }
        if (logRef.current) {
          logRef.current.resetAfterIndex(index)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/explicit-function-return-type
  const getSize = (index: number) => {
    if (sizeMap.current[index]) {
      return sizeMap.current[index]
    } else {
      return 20
    }
  }

  // Jump to bottom once component mounts
  useEffect(() => {
    if (logRef.current && modifiedData.length > 0 && !isAtBottom && !jumpedOnce) {
      logRef.current.scrollToItem(modifiedData?.length)
      setJumpedOnce(true)
      setIsAtBottom(true)
    }
  }, [modifiedData, isAtBottom, jumpedOnce])

  // Update scroll position to follow any new logs if user is already at bottom
  useEffect(() => {
    if (logRef.current && isAtBottom) {
      logRef.current.scrollToItem(modifiedData?.length)
    }
  }, [modifiedData.length, isAtBottom])

  return (
    <React.Fragment>
      {enableSearch && (
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: scriptNodeView ? "black" : "transparent",
            padding: scriptNodeView ? "16px" : "0px 0px 12px 0px",
          }}
          display="flex"
          justifyContent="space-between"
        >
          <ThemeProvider theme={scriptNodeView ? darkTheme : getTheme()}>
            <InputText
              value={searchValue}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              handleChange={(e: any) => setSearchValue(e.target.value)}
              placeholder="Search"
              disabled={isLoading}
              variant="filled"
              width={300}
              hideDescription
              startAdornment={
                <SearchIcon
                  fontSize="small"
                  style={{
                    paddingTop: "2px",
                    fill: "var(--grayscale-text-2)",
                  }}
                />
              }
            />

            {enableDatePicker && onStartDateChange && onEndDateChange && (
              <Grid item alignSelf="center">
                <DateRangePicker
                  key="Model logs"
                  startDate={startDate as moment.Moment | null}
                  endDate={endDate as moment.Moment | null}
                  onStartDateChange={onStartDateChange}
                  onEndDateChange={(endDate: moment.Moment | null) =>
                    onEndDateChange(konanTime.adjustDate(endDate, "end"))
                  }
                  // TODO:: fix in SUI to enable both options at the same time
                  // disableFuture
                  isOutsideRange={isOutSideRange}
                />
              </Grid>
            )}
          </ThemeProvider>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        className={`card-box-shadow ${styles.paper} ${type === "LogsPage" ? styles.page : !minHeight && styles.dialog}`}
        style={{
          borderRadius: customBorderRadius ? `${customBorderRadius}` : "4px",
          minHeight: minHeight ?? "200px !important",
        }}
      >
        {isLoading ? (
          <LoadingContainer />
        ) : !modifiedData?.length ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Typography variant="label" variantColor={2}>
              No logs to display.
            </Typography>
          </Grid>
        ) : (
          // manages width and height properties to fill available space
          <AutoSizer>
            {({ height, width }) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <List
                height={height}
                width={width}
                itemCount={modifiedData.length}
                itemSize={getSize}
                ref={logRef}
                onItemsRendered={({ visibleStopIndex }) => {
                  if (visibleStopIndex === modifiedData?.length - 1) {
                    setIsAtBottom(true)
                  } else {
                    setIsAtBottom(false)
                  }
                }}
              >
                {({ index, style }) => (
                  <div style={style}>
                    <KonanRowRender
                      modifiedData={modifiedData}
                      searchValue={searchValue}
                      index={index}
                      style={style}
                      setSize={setSize}
                      windowWidth={windowWidth}
                    />
                  </div>
                )}
              </List>
            )}
          </AutoSizer>
        )}
      </Grid>
    </React.Fragment>
  )
}
