import React, { Fragment, useContext, useState } from "react"

import { useMutation } from "react-query"
import { useSelector } from "react-redux"

import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from "@mui/icons-material/Clear"
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import { CircularProgress, Grid, Button as MuiButton } from "@mui/material"
import { Button, InputText, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { queryClient } from "../.."
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { RootState } from "../../store/ReduxStore"
import { Label } from "../../types/generated/api/Label"
import { PaginatedLabelList } from "../../types/generated/api/PaginatedLabelList"
import { isPermitted } from "../../utils/PermissionsHelpers"
import { Auth } from "../../utils/auth"
import { KonanPagination } from "./KonanPagination"

import styles from "./LabelsTabels.module.scss"

const MemoizedCustomPagination = React.memo(KonanPagination)

interface ItemProps {
  label: Label
  isNew?: boolean
  onNewItemSubmit?: () => void
}

function TableItem(props: Readonly<ItemProps>): React.ReactElement {
  const { label, isNew = false, onNewItemSubmit } = props
  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const permissions = Auth.getPermissions()

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [name, setName] = useState(isNew ? "" : label.name)

  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  const CreateLabelMutation = useMutation<
    AxiosResponse,
    AxiosError,
    {
      project_uuid: string
      name: string
    }
  >(KonanAPI.CreateLabel, {
    onSuccess: async () => {
      NotificationUtils.toast("Label created successfully", { snackBarVariant: "positive" })

      await queryClient.invalidateQueries(["label-list", currentProject?.uuid])
    },
    onError: async () => {
      NotificationUtils.toast("Label creation failed", { snackBarVariant: "negative" })
    },
  })

  const UpdateLabelMutation = useMutation<
    AxiosResponse,
    AxiosError,
    {
      project_uuid: string
      label_uuid: string
      name: string
    }
  >(KonanAPI.UpdateLabel, {
    onSuccess: async () => {
      NotificationUtils.toast("Label updated successfully", { snackBarVariant: "positive" })

      await queryClient.invalidateQueries(["label-list", currentProject?.uuid])
    },
    onError: async () => {
      NotificationUtils.toast("Label updating failed", { snackBarVariant: "negative" })
    },
  })

  const DeleteLabelMutation = useMutation<
    AxiosResponse,
    AxiosError,
    {
      project_uuid: string
      label_uuid: string
    }
  >(KonanAPI.DeleteLabel, {
    onSuccess: async () => {
      NotificationUtils.toast("Label deleted successfully", { snackBarVariant: "positive" })

      await queryClient.invalidateQueries(["label-list", currentProject?.uuid])
    },
    onError: async () => {
      NotificationUtils.toast("Label deletion failed", { snackBarVariant: "negative" })
    },
  })

  const handleEditLabel = async (): Promise<void> => {
    await UpdateLabelMutation.mutateAsync({
      project_uuid: currentProject?.uuid as string,
      label_uuid: label?.uuid,
      name: name,
    })

    setIsEditMode(false)
  }

  const handleCreateLabel = async (): Promise<void> => {
    await CreateLabelMutation.mutateAsync({
      project_uuid: currentProject?.uuid as string,
      name: name?.trim(),
    })

    onNewItemSubmit?.()
    setIsEditMode(false)
  }

  return (
    <Grid
      container
      key={label?.uuid}
      item
      xs={12}
      px={1.5}
      py={0.75}
      style={{ borderBottom: "1px solid var(--grayscale-border)" }}
    >
      <Grid item xs={8}>
        {isEditMode || isNew ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <InputText hideDescription placeholder={"label"} value={name} handleChange={(e) => setName(e.target.value)} />
        ) : (
          <Typography variant="p">{label?.name}</Typography>
        )}
      </Grid>

      <Grid container item xs={4} gap={1} alignContent={"center"}>
        {isPermitted("Update label", permissions.konan, flattenedKonanPermissions) &&
          (!isEditMode && !isNew ? (
            <Grid item>
              <Button onClick={() => setIsEditMode(true)}>
                <ModeEditOutlinedIcon fontSize="inherit" />
              </Button>
            </Grid>
          ) : (
            <Fragment>
              <Grid item>
                <Button
                  disabled={(isNew && name === "") || (isEditMode && label.name === name)}
                  onClick={() => (isEditMode ? handleEditLabel() : handleCreateLabel())}
                >
                  {CreateLabelMutation.isLoading || UpdateLabelMutation.isLoading ? (
                    <CircularProgress size={14} />
                  ) : (
                    <CheckIcon fontSize="inherit" style={{ fill: "var(--green-background-1)" }} />
                  )}
                </Button>
              </Grid>

              <Grid item>
                <Button onClick={() => (isNew ? onNewItemSubmit?.() : setIsEditMode(false))}>
                  <ClearIcon fontSize="inherit" style={{ fill: "var(--red-background-1)" }} />
                </Button>
              </Grid>
            </Fragment>
          ))}

        {!isNew && isPermitted("Delete label", permissions.konan, flattenedKonanPermissions) && (
          <Grid item>
            <Button
              onClick={async () =>
                await DeleteLabelMutation.mutateAsync({
                  project_uuid: currentProject?.uuid as string,
                  label_uuid: label?.uuid,
                })
              }
            >
              {DeleteLabelMutation.isLoading ? (
                <CircularProgress size={14} />
              ) : (
                <RemoveCircleOutlineIcon fontSize="inherit" style={{ fill: "var(--red-background-1)" }} />
              )}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

interface TableProps {
  labels?: PaginatedLabelList
  page: number
  setPage: (page: number) => void
}

export function LabelsTable(props: Readonly<TableProps>): React.ReactElement {
  const { labels, page, setPage } = props

  const permissions = Auth.getPermissions()

  const [hasNewLabel, setHasNewLabel] = useState<boolean>(false)

  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  return (
    <Grid container>
      {/* Table Header */}
      <Grid container item xs={12} px={1.5} py={0.75} style={{ borderBottom: "1px solid var(--grayscale-border)" }}>
        <Grid item xs={8}>
          <Typography variant={"a"}>Labels</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"a"}>Actions</Typography>
        </Grid>
      </Grid>

      {isPermitted("Add label", permissions.konan, flattenedKonanPermissions) && (
        <Grid item xs={12} px={1.5} py={0.75} style={{ borderBottom: "1px solid var(--grayscale-border)" }}>
          <MuiButton
            disabled={hasNewLabel}
            size="small"
            onClick={() => setHasNewLabel(true)}
            className={styles.MuiButton}
          >
            + Add Label
          </MuiButton>
        </Grid>
      )}

      {hasNewLabel && (
        <TableItem
          onNewItemSubmit={() => {
            setHasNewLabel(false)
          }}
          label={{ uuid: "", name: "", created_at: "", created_by: "" }}
          isNew
        />
      )}

      {labels?.results?.map((item) => {
        return <TableItem key={item.uuid} label={item} />
      })}

      <Grid item xs={12}>
        <MemoizedCustomPagination
          stripped={true}
          count={labels?.count ?? 0}
          page={page}
          onPageChange={(index: number) => {
            setPage(index)
          }}
          rowsPerPage={10}
        />
      </Grid>
    </Grid>
  )
}
