import React, { useCallback, useEffect, useMemo } from "react"

import { useQueries, useQuery, useQueryClient } from "react-query"
import { useSelector } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"

import { useMediaQuery, useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"
import { AxiosError, AxiosResponse } from "axios"
import moment from "moment"

import { KonanActivityCard } from "../../../../components/KonanActivityCard"
import { KonanLastFeedbackCard } from "../../../../components/KonanLastFeedbackCard"
import { DataDriftBarChart } from "../../../../components/graphs/DataDriftBarChart"
import { RequestsBarChart } from "../../../../components/graphs/RequestsBarChart"
import { ResponseTimeBarChart } from "../../../../components/graphs/ResponseTimeBarChart"
import { UsageBarChart } from "../../../../components/graphs/UsageBarChart"
import { KonanAPI } from "../../../../services/KonanAPI"
import { RootState } from "../../../../store/ReduxStore"
import { ModelAverageCpuUsage, baseErrorType } from "../../../../types/custom/projects"
import { DeploymentLatestFeedbackDateTime } from "../../../../types/generated/api/DeploymentLatestFeedbackDateTime"
import { DeploymentModelsAvgResponseTime } from "../../../../types/generated/api/DeploymentModelsAvgResponseTime"
import { DeploymentPredictionsSummary } from "../../../../types/generated/api/DeploymentPredictionsSummary"
import { DeploymentUsage } from "../../../../types/generated/api/DeploymentUsage"
import { ListModelStateSwitch } from "../../../../types/generated/api/ListModelStateSwitch"
import { Model } from "../../../../types/generated/api/Model"
import { PaginatedListModelStateSwitchList } from "../../../../types/generated/api/PaginatedListModelStateSwitchList"
import { isPermitted } from "../../../../utils/PermissionsHelpers"
import { Auth } from "../../../../utils/auth"
import { getAverageCpuUsageForModel, getAverageRamUsageForModel } from "../../../../utils/deploymentDetailsHelpers"
import { convertDataDriftListToChart, getModelByUUID } from "../../../../utils/modelDetailsHelpers"
import { orderModelsByStatus } from "../../../../utils/searchSortFilterHelpers"

type ParamsType = {
  id: string
}

/**
 * Model overview screen component
 * @return {React.ReactElement}
 */
export function Overview(): React.ReactElement {
  const { id: projectId } = useParams<ParamsType>()

  const permissions = Auth.getPermissions()
  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  const queryClient = useQueryClient()

  const theme = useTheme()

  const [searchParams] = useSearchParams()

  const startDate = searchParams.get("startDate")
  const endDate = searchParams.get("endDate")

  const graphHeight = 200
  const chartsBaseHeight = 50

  const {
    isLoading: isModelsLoading,
    data: models,
    isFetchedAfterMount: isModelFetchedAfterMount,
  } = useQuery<AxiosResponse<Array<Model>>, AxiosError<baseErrorType>>(["overviewModels", projectId], () =>
    KonanAPI.fetchModels(projectId as string),
  )

  //function that returns adjusted models based on search, sort, and filter values
  const adjustedModels = useMemo((): Model[] => {
    return models?.data ? orderModelsByStatus(models?.data, "asc") : []
  }, [models])

  const queryModelList: Model[] = models?.data ?? []

  const dataDriftResponseList = useQueries(
    queryModelList.map((model) => ({
      queryKey: ["overviewDriftJobs", model.uuid],
      queryFn: () => KonanAPI.fetchDataDriftJobs(model.uuid),
    })),
  )

  const CPUAndRAMResponseList = useQueries(
    queryModelList.map(
      (model) => ({
        queryKey: ["cpuAndRamUsage", model.uuid, startDate, endDate],
        queryFn: () => KonanAPI.fetchModelCpuAndRamUsage(model.uuid, startDate as string, endDate as string),
      }),
      {
        enabled: startDate != null && endDate != null,
      },
    ),
  )

  const { isLoading: isApiRequestsLoading, data: apiRequests } = useQuery<
    AxiosResponse<DeploymentPredictionsSummary>,
    AxiosError<baseErrorType>
  >(
    ["apiRequests", projectId, startDate, endDate],
    () =>
      KonanAPI.fetchApiRequestsSummary({
        project_uuid: projectId as string,
        start_date: startDate as string,
        end_date: endDate as string,
      }),
    {
      enabled: projectId !== "" && startDate != null && endDate != null,
    },
  )

  const { isLoading: isAverageResponseTimeLoading, data: averageResponseTime } = useQuery<
    AxiosResponse<DeploymentModelsAvgResponseTime>,
    AxiosError<baseErrorType>
  >(
    ["averageResponseTime", projectId, startDate, endDate],
    () =>
      KonanAPI.fetchProjectAverageResponseTime({
        start_date: startDate as string,
        end_date: endDate as string,
        project_uuid: projectId as string,
      }),
    {
      enabled: projectId !== "" && startDate != null && endDate != null,
    },
  )

  const { isLoading: isLastFeedbackLoading, data: lastFeedbackResponse } = useQuery<
    AxiosResponse<DeploymentLatestFeedbackDateTime>,
    AxiosError<baseErrorType>
  >(["lastFeedback", projectId], () => KonanAPI.fetchLastFeedback(projectId as string), {
    enabled: projectId !== "",
  })

  const { isLoading: isActivityLoading, data: activityResponse } = useQuery<
    PaginatedListModelStateSwitchList,
    AxiosError<baseErrorType>
  >(["modelHistory", projectId], () => KonanAPI.getProjectHistory(projectId as string), {
    enabled: projectId !== "",
  })

  const dataDriftChartData = useMemo(() => {
    if (models?.data && models?.data.length > 0) {
      return convertDataDriftListToChart(dataDriftResponseList, models.data)
    }
    return []
  }, [dataDriftResponseList, models])

  const isDataDriftLoading = useMemo(() => {
    if (dataDriftResponseList && dataDriftResponseList.length > 0 && !(startDate && !endDate ? true : false)) {
      if (dataDriftResponseList.every((item) => item.isLoading === false)) {
        return false
      }
    }
    if (adjustedModels.length === 0 && isModelFetchedAfterMount) return false

    return true
  }, [adjustedModels.length, dataDriftResponseList, endDate, isModelFetchedAfterMount, startDate])

  const CPUChartData = useMemo(() => {
    const result: ModelAverageCpuUsage[] = []
    if (models?.data && models?.data.length > 0) {
      CPUAndRAMResponseList.forEach((item, index) => {
        result.push(getAverageCpuUsageForModel(models.data[index], item?.data?.data as DeploymentUsage))
      })
    }
    return result.every((item) => item.realMax === 0) ? [] : result
  }, [CPUAndRAMResponseList, models])

  const RAMChartData = useMemo(() => {
    const result: ModelAverageCpuUsage[] = []
    if (models?.data && models?.data.length > 0) {
      CPUAndRAMResponseList.forEach((item, index) => {
        result.push(getAverageRamUsageForModel(models.data[index], item?.data?.data as DeploymentUsage))
      })
    }
    return result.every((item) => item.realMax === 0) ? [] : result
  }, [CPUAndRAMResponseList, models])

  const isCPUAndRAMLoading = useMemo(() => {
    if (CPUAndRAMResponseList && CPUAndRAMResponseList.length > 0 && !(startDate && !endDate ? true : false)) {
      if (CPUAndRAMResponseList.every((item) => item.isLoading === false)) {
        return false
      }
    }
    if (adjustedModels.length === 0 && isModelFetchedAfterMount) return false
    return true
  }, [CPUAndRAMResponseList, adjustedModels.length, endDate, isModelFetchedAfterMount, startDate])

  const requestsGraphHeight = useMemo(() => {
    return apiRequests?.data.results.length ? chartsBaseHeight + apiRequests?.data.results.length * 40 : graphHeight
  }, [apiRequests])

  const averageResponseTimeGraphHeight = useMemo(() => {
    return averageResponseTime?.data.results.length
      ? chartsBaseHeight + averageResponseTime?.data.results.length * 40
      : graphHeight
  }, [averageResponseTime])

  const requestsAndResponseTimeHeight = useMemo(() => {
    return Math.max(requestsGraphHeight, averageResponseTimeGraphHeight, graphHeight)
  }, [averageResponseTimeGraphHeight, requestsGraphHeight])

  const cpuGraphHeight = useMemo(() => {
    return CPUChartData?.length && CPUChartData?.some((item) => item.realMax !== 0) && !isCPUAndRAMLoading
      ? chartsBaseHeight + CPUChartData.filter((item) => item.realMax !== 0).length * 40
      : graphHeight
  }, [CPUChartData, isCPUAndRAMLoading])

  const ramGraphHeight = useMemo(() => {
    return RAMChartData?.length && RAMChartData?.some((item) => item.realMax !== 0) && !isCPUAndRAMLoading
      ? chartsBaseHeight + RAMChartData.filter((item) => item.realMax !== 0).length * 40
      : graphHeight
  }, [RAMChartData, isCPUAndRAMLoading])

  const dataDriftGraphHeight = useMemo(() => {
    return dataDriftChartData?.length && !isDataDriftLoading
      ? chartsBaseHeight + dataDriftChartData.length * 40
      : graphHeight
  }, [dataDriftChartData, isDataDriftLoading])

  const driftAndUsageHeight = useMemo(() => {
    return Math.max(dataDriftGraphHeight, cpuGraphHeight, ramGraphHeight, graphHeight)
  }, [cpuGraphHeight, dataDriftGraphHeight, ramGraphHeight])

  const activityGraphHeight = useMemo(() => {
    return requestsAndResponseTimeHeight + driftAndUsageHeight + 78
  }, [driftAndUsageHeight, requestsAndResponseTimeHeight])

  // Add model name to activity response
  const memoizedGetModelByUUID = useCallback((uuid: string) => getModelByUUID(uuid, models?.data), [models])
  const activityData = useMemo(() => {
    if (activityResponse?.results) {
      return activityResponse?.results?.map((item) => ({
        ...item,
        name: memoizedGetModelByUUID(item.model)?.name,
      }))
    }
    return []
  }, [activityResponse?.results, memoizedGetModelByUUID])

  //reset CPU, RAM and Data Drift states whenever the user deletes a model
  useEffect(() => {
    queryClient.invalidateQueries("apiRequests")
    queryClient.invalidateQueries("averageResponseTime")
    queryClient.invalidateQueries("overviewDriftJobs")
    queryClient.invalidateQueries("cpuAndRamUsage")
  }, [models, queryClient])

  return (
    <Grid container spacing={2}>
      {/* Overview section */}
      <Grid
        item
        xs={12}
        lg={isPermitted("View model state switch history", permissions.konan, flattenedKonanPermissions) ? 9 : 12}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <RequestsBarChart
              title="API REQUESTS"
              data={apiRequests?.data.results ?? []}
              models={models?.data ? models?.data : []}
              graphHeight={requestsAndResponseTimeHeight}
              isLoading={isApiRequestsLoading || (startDate && !endDate ? true : false)}
              range={
                moment(endDate)?.diff(moment(startDate), "days")
                  ? moment(endDate)?.diff(moment(startDate), "days") + 1
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <ResponseTimeBarChart
              title="RESPONSE TIME"
              data={
                averageResponseTime?.data.results.every((item) => item.avg_response_time === 0)
                  ? []
                  : averageResponseTime?.data.results ?? []
              }
              models={models && models.data ? models?.data : []}
              graphHeight={requestsAndResponseTimeHeight}
              isLoading={isAverageResponseTimeLoading || (startDate && !endDate ? true : false)}
              range={
                !useMediaQuery(theme.breakpoints.between(1200, 1260)) &&
                moment(endDate)?.diff(moment(startDate), "days")
                  ? moment(endDate)?.diff(moment(startDate), "days") + 1
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <KonanLastFeedbackCard
              date={
                lastFeedbackResponse?.data?.latest_feedback_date_time
                  ? new Date(lastFeedbackResponse?.data?.latest_feedback_date_time)
                  : undefined
              }
              cardHeight={requestsAndResponseTimeHeight}
              isLoading={isLastFeedbackLoading || (startDate && !endDate ? true : false)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <UsageBarChart
              title="CPU USAGE"
              data={CPUChartData}
              graphHeight={driftAndUsageHeight}
              isLoading={isCPUAndRAMLoading}
              range={
                moment(endDate)?.diff(moment(startDate), "days")
                  ? moment(endDate)?.diff(moment(startDate), "days") + 1
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <UsageBarChart
              title="RAM USAGE"
              data={RAMChartData}
              graphHeight={driftAndUsageHeight}
              isLoading={isCPUAndRAMLoading}
              isRamUsage
              range={
                moment(endDate)?.diff(moment(startDate), "days")
                  ? moment(endDate)?.diff(moment(startDate), "days") + 1
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <DataDriftBarChart
              title="DATA DRIFT"
              data={dataDriftChartData}
              graphHeight={driftAndUsageHeight}
              isLoading={isDataDriftLoading}
            />
          </Grid>
        </Grid>
      </Grid>

      {isPermitted("View model state switch history", permissions.konan, flattenedKonanPermissions) && (
        <Grid item xs={12} lg={3}>
          <KonanActivityCard
            data={activityData as ({ name: string } & ListModelStateSwitch)[]}
            cardHeight={activityGraphHeight}
            isLoading={isActivityLoading || (startDate && !endDate ? true : false) || isModelsLoading}
          />
        </Grid>
      )}
    </Grid>
  )
}
