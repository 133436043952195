import { getHostnames } from "../../../utils/genericHelpers"

export function swaggerTemplate(title: string, apiSpec: string): string {
  return `<!DOCTYPE html>
    <html xmlns="http://www.w3.org/1999/xhtml" lang="english">
    <head>
        <meta charset="UTF-8">
        <title>${title || "Konan REST API Documentation"}</title>

        <link rel="stylesheet" type="text/css" href="https://unpkg.com/swagger-ui-dist@4.14.1/swagger-ui.css">

        <!-- Favicon -->
        <link rel="icon" type="image/svg+xml" href="https://${getHostnames().frontend}/favicon.svg">
        <link rel="icon" type="image/png" href="https://${getHostnames().frontend}/favicon.png">

    </head>
    <body>

    <div id="swagger-ui"></div>

    <script src="https://unpkg.com/swagger-ui-dist@4.14.1/swagger-ui-standalone-preset.js" charset="UTF-8"></script>
    <script src="https://unpkg.com/swagger-ui-dist@4.14.1/swagger-ui-bundle.js" charset="UTF-8"></script>

    <script>

        window.onload = function() {
            // Build a system
            const ui = SwaggerUIBundle({
                spec: ${apiSpec},
                dom_id: '#swagger-ui',
                deepLinking: true,
                docExpansion: "list",
                presets: [
                    SwaggerUIBundle.presets.apis,
                ],
                plugins: [
                    SwaggerUIBundle.plugins.DownloadUrl
                ],
            })
            window.ui = ui
        }
    </script>
    </body>
    </html>`
}
