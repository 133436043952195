import React, { Fragment, useContext, useState } from "react"

import { useQuery } from "react-query"

import { CircularProgress, Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { DebouncedInput } from "../../../../../../../components/form/DebouncedInput"
import { LabelsTable } from "../../../../../../../components/tables/LabelsTable"
import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../../../store/CurrentProjectAndModelContext"
import { PaginatedLabelList } from "../../../../../../../types/generated/api/PaginatedLabelList"

export function Labels(): React.ReactElement {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [searchKey, setSearchKey] = useState<string | null>(null)
  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const pageSize = 10

  // fetch labels list
  const { data: labelList, isLoading: isLabelListLoading } = useQuery<AxiosResponse<PaginatedLabelList>, AxiosError>(
    ["label-list", currentProject?.uuid, currentPage, pageSize, searchKey],
    () =>
      KonanAPI.RetrieveLabels({
        project_uuid: currentProject?.uuid as string,
        page: currentPage + 1,
        pageSize: pageSize,
        search: searchKey,
      }),
    {
      keepPreviousData: true,
    },
  )

  return (
    <Grid container>
      {isLabelListLoading ? (
        <Grid container style={{ minHeight: "250px" }}>
          <Grid item xs={12} display={"flex"} justifyContent="center" alignItems={"flex-end"}>
            <CircularProgress />
          </Grid>

          <Grid item xs={12} display={"flex"} justifyContent="center">
            <Typography variant={"a"}>Loading your labels, please wait for a moment...</Typography>
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          <Grid item gap={1} my={1}>
            <DebouncedInput
              callback={(value: string | null) => {
                setSearchKey(value)
              }}
              variant="outlined"
            />
          </Grid>

          <LabelsTable
            labels={labelList?.data}
            page={currentPage}
            setPage={(page) => {
              setCurrentPage(page)
            }}
          />
        </Fragment>
      )}
    </Grid>
  )
}
