import React, { Fragment, useEffect, useMemo, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Button as MuiButton,
  useMediaQuery,
} from "@mui/material"
import {
  Button,
  InputText,
  Menu,
  MenuItem,
  NotificationUtils,
  Tooltip,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FormikProps, FormikValues, useFormik } from "formik"
import { v4 as uuidv4 } from "uuid"

import { VersionTag } from "../../../../../../../components/VersionTag"
import { InfoBlock } from "../../../../../../../components/containers/InfoBlock"
import { VersioningDialog } from "../../../../../../../components/dialogs/VersioningDialog"
import { getTheme } from "../../../../../../../hooks/UseTheme"
import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { Operators, ScoreRule, UpdateScorecardRequest } from "../../../../../../../types/custom/projects"
import {
  CustomRuleRequest,
  MapValueTypeToFeatureType,
  Rule,
  ScorecardRule,
  VersionChangeRequest,
} from "../../../../../../../types/custom/rules"
import { SchemaFeature } from "../../../../../../../types/custom/workflows"
import { ScoreCardGroupRetrieve } from "../../../../../../../types/generated/api/ScoreCardGroupRetrieve"
import { ScoreCardRetrieve } from "../../../../../../../types/generated/api/ScoreCardRetrieve"
import { ScoreCardRetrieveWithWeight } from "../../../../../../../types/generated/api/ScoreCardRetrieveWithWeight"
import { WorkflowSchemaFeature } from "../../../../../../../types/generated/api/WorkflowSchemaFeature"
import {
  detectRuleType,
  getFieldErrorIfTouched,
  getNewRuleInfo,
  levelSchema,
} from "../../../../../../../utils/conditionHelpers"
import {
  extractScorecardErrorsMessages,
  getValidMaxMinorVersion,
} from "../../../../../../../utils/deploymentDetailsHelpers"
import {
  convertComplexConditionsToNestedForm,
  convertComplexFormToCondition,
  isTypeIncluded,
} from "../../../../../../../utils/rulesetHelpers"
import { EMPTY_SCORECARD_CONDITION } from "../CONSTANTS"
import { RuleCard } from "./RuleCard"
import { ScorecardLoadingComponent } from "./SingleScorecardset"

import styles from "./Scorecard.module.scss"

export interface ScorecardFormikValues {
  scorecardSetName: string
  scorecards: {
    name: string
    weight: string
    ruleCards: Array<ScorecardRule>
    uuid: string
    scorecardID: string
  }[]
}

type Props = {
  SCSViewMode: "read" | "edit" | "weight-edit"
  scorecardFormik: FormikProps<ScorecardFormikValues> | FormikValues
  scorecardIndex: number
  isScorecardsetInEditMode?: boolean
  dialogView?: boolean
  scorecard?: ScoreCardRetrieveWithWeight
  handleRemoveScorecard?: () => void
  isScorecardsetLoading?: boolean
}

// Yup validation schema
const validationSchema = Yup.object({
  scorecards: Yup.array().of(
    Yup.object({
      name: Yup.string().required("Scorecard name is required"),
      weight: Yup.number().typeError("Numerical value is required"),
      ruleCards: Yup.array().of(
        Yup.object().shape({
          levelOneConditions: Yup.array().of(levelSchema),
          levelTwoConditions: Yup.array().of(levelSchema),
          return_value: Yup.number().typeError("Numeric value is required").required("Rule weight is required"),
        }),
      ),
    }),
  ),
})

/**
 * single scorecard component
 * @param {boolean} SCSViewMode indicator for current mode for scorecardset
 * @param {function} scorecardFormik form control state handle
 * @param {function} handleRemoveScorecard handler function for removing scorecard
 * @param {ScoreCardRetrieveWithWeight} scorecard retrieved scorecard with assigned weight from backend
 * @param {number} scorecardIndex index for scorecard
 * @param {number} selectedScorecardsCount number of checked checkboxes of selected scorecards
 * @param {boolean} dialogView indicator if this scorecard being rendered inside dialog view or not
 * @param {boolean} isScorecardsetInEditMode indicator if scorecardset is in update mode or not
 * @param {boolean} isScorecardsetLoading indicator if scorecardset loading/currently updating or not
 * @returns {React.ReactElement}
 */
export function Scorecard({
  SCSViewMode,
  scorecardIndex,
  scorecardFormik,
  scorecard,
  handleRemoveScorecard,
  dialogView,
  isScorecardsetInEditMode,
  isScorecardsetLoading,
}: Readonly<Props>): React.ReactElement {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)

  // specific media query breakpoint
  const smallActionButtons = useMediaQuery("(min-width:925px)")
  const [isVersionDialogOpen, setIsVersionDialogOpen] = useState<boolean>(false)
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined)

  // saving a snap shot of the ruleCards on edit click so if we cancel after some edit we get this snap shot back
  const [scorecardsSnapShot, setScorecardsSnapShot] = useState<Array<ScorecardRule>>([])

  // internal indicator for edit mode for scorecard
  const [isScorecardInEditMode, setIsScorecardInEditMode] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { id: projectId } = useParams<{ id: string }>()

  const theme = getTheme()

  const openScoreCardMenu = Boolean(anchor)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateScorecardMutation = useMutation<AxiosResponse, AxiosError<any>, UpdateScorecardRequest>(
    KonanAPI.updateScorecard,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["scorecard", scorecard?.uuid])
        await queryClient.invalidateQueries(["scorecards", projectId])
        await queryClient.invalidateQueries("scorecardset")

        setIsScorecardInEditMode(false)

        NotificationUtils.toast("Scorecard updated successfully!", {
          snackBarVariant: "positive",
        })

        queryClient.invalidateQueries(["scorecardsets", projectId])
      },
      onError: async (response: AxiosError<UpdateScorecardRequest>) => {
        const errorMessage = extractScorecardErrorsMessages(response?.response?.data, "update")

        NotificationUtils.toast(errorMessage, {
          snackBarVariant: "negative",
        })
      },
    },
  )

  // change scorecard version
  const scorecardChangeMutation = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
    KonanAPI.changeResourceVersion,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["scorecard", scorecard?.uuid])
        await queryClient.invalidateQueries(["scorecards", projectId])
        // TODO: remove this line when backend order scorecards based on weight
        /**
         * currently, the backend returns the order of scorecards, according to the created_at field
         * which changes after each new version, so let's say this is the order of scorecards (a - b - c)
         * after updating b for example, it will be -> (b - a - c) .. so this shift in order will refelct
         * in the content of the version history because now the same index is pointing to a different scorecard
         * so as of right now, after restoring the newly version we automatically close the dialog
         */
        setIsVersionDialogOpen(false)

        await queryClient.invalidateQueries(["scorecardsets", projectId])

        await queryClient.invalidateQueries("scorecardset")
      },
    },
  )

  // retrieve the current scorecard
  const { data: scorecardData, isLoading: isScorecardDataLoading } = useQuery<
    AxiosResponse<ScoreCardGroupRetrieve>,
    AxiosError
  >(["scorecard", scorecard?.uuid], () => KonanAPI.fetchScorecard(projectId as string, scorecard?.uuid as string), {
    // Only enable the query if the scorecard?.uuid is not undefined
    enabled: !!scorecard?.uuid,
  })

  // Memoize active version to render it in the card
  const activeScorecardVersion = useMemo(() => {
    if (scorecardData?.data?.versions && scorecardData?.data?.versions?.length > 0) {
      for (const version of scorecardData?.data?.versions) {
        if (version.is_active_version) {
          return version
        }
      }
    }
  }, [scorecardData])

  // simpler formik specific for editing the current scorecard
  const singleScorecardFormik = useFormik({
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      scorecards: [
        {
          name: "",
          weight: undefined,
          type: "string",
          ruleCards: [
            {
              id: `new-${uuidv4()}`,
              ruleName: "",
              levelOneConditions: [EMPTY_SCORECARD_CONDITION],
              levelTwoConditions: [],
              return_value: "",
              condition_list_file: { name: "", uuid: "" },
              schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
            },
          ],
          uuid: "",
        },
      ],
    },
    onSubmit: async ({ scorecards }) => {
      const featuresArray: Array<SchemaFeature> = []
      const finalRulesFormat: CustomRuleRequest[] = []

      scorecards[0].ruleCards.forEach((ruleCard: ScorecardRule) => {
        const multiValuesOperators = [
          Operators["in"],
          Operators["not in"],
          Operators["contains"],
          Operators["not contains"],
        ]

        if (ruleCard.levelOneConditions && ruleCard.levelOneConditions.length > 0) {
          ruleCard.levelOneConditions.forEach((item: Rule) => {
            if (!featuresArray.find((elm) => elm?.name === item?.feature)) {
              featuresArray.push({
                name: item?.feature,
                is_required: true,
                source: "workflow",
                type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
              })
            }

            item.andOr = ruleCard.levelOneConditions[0]?.andOr ?? "and"
            if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
              item.type = item.operator
            }
          })
        }

        if (ruleCard.levelTwoConditions && ruleCard.levelTwoConditions.length > 0) {
          ruleCard.levelTwoConditions.forEach((item: Rule) => {
            if (!featuresArray.find((elm) => elm?.name === item?.feature)) {
              featuresArray.push({
                name: item?.feature,
                is_required: true,
                source: "workflow",
                type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
              })
            }
            item.andOr = ruleCard.levelTwoConditions[0]?.andOr ?? "and"
            if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
              item.type = item.operator
            }
          })
        }

        const newRule = ruleCard?.condition_list_file?.uuid
          ? {
              name: `rule-${uuidv4()}`,
              condition: convertComplexFormToCondition(ruleCard.levelOneConditions, ruleCard.levelTwoConditions, []),
              return_value: ruleCard?.return_value,
              condition_list_file: ruleCard?.condition_list_file?.uuid,
            }
          : {
              name: `rule-${uuidv4()}`,
              condition: convertComplexFormToCondition(ruleCard.levelOneConditions, ruleCard.levelTwoConditions, []),
              return_value: ruleCard?.return_value,
            }

        finalRulesFormat.push(newRule)
      })

      return updateScorecardMutation.mutateAsync({
        // Pass the data of the current Scorecard to the mutation
        name: scorecards[0]?.name?.trim() === scorecard?.name ? undefined : scorecards[0]?.name?.trim(),
        scorecardUUID: scorecard?.uuid as string,
        projectUUID: projectId as string,
        rules: [...finalRulesFormat],
      })
    },
  })

  // grouping repeating data into 3 variables..
  const currentFormik = isScorecardInEditMode ? singleScorecardFormik : scorecardFormik
  const currentScorecardIndex = isScorecardInEditMode ? 0 : scorecardIndex

  // if there's retrieved scorecard pass down to formik state
  useEffect(() => {
    if (scorecard) {
      const updatedScorecards = [scorecard]
      // Update the scorecards field of the Formik form with the new data
      singleScorecardFormik.setFieldValue("scorecards", updatedScorecards)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScorecardInEditMode, scorecard])

  // handler for activating scorecard version
  const changeActiveScorecardVersion = async (version: string): Promise<void> => {
    try {
      await scorecardChangeMutation.mutateAsync({
        projectUUID: projectId as string,
        resourceUUID: scorecard?.uuid as string,
        version,
        resource: "scorecards",
      })

      NotificationUtils.toast(`Version (${version}) restored.`, {
        snackBarVariant: "positive",
      })
    } catch (error) {
      NotificationUtils.toast(`Couldn't restore version (${version}). Please try again later`, {
        snackBarVariant: "negative",
      })
    }
  }

  // children component for the versioning dialog for scorecard
  const ScorecardContainerInVersionHistory = (): React.ReactElement => {
    const [cachedResults, setCachedResults] = useState<Record<string, string>>({})

    const Version = useMemo(() => {
      if (scorecardData && selectedVersion) {
        if (!cachedResults[selectedVersion?.split(".")?.[0]]) {
          const result = getValidMaxMinorVersion(scorecardData.data?.versions as ScoreCardRetrieve[], selectedVersion)
          setCachedResults((prevResults) => ({
            ...prevResults,
            ...result,
          }))
        }

        for (const version of scorecardData.data?.versions) {
          if (version.version === selectedVersion) {
            return version
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVersion, scorecardData])

    // extracting the current major version
    const majorSelectedVersion = (selectedVersion ?? activeScorecardVersion?.version)?.split(".")?.[0]

    // show restore button when the current major version from the selected version/active version existed in our valid versions hashMap
    // and it's not the current active version
    const shouldShowRestoreButton =
      cachedResults[majorSelectedVersion as string] === (selectedVersion ?? activeScorecardVersion?.version) &&
      cachedResults[majorSelectedVersion as string] !== activeScorecardVersion?.version

    const tagVariant = activeScorecardVersion?.version === selectedVersion ? "positive" : "default"

    return (
      <Grid container>
        {/* Header */}
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          className={"versioning-dialog-header"}
        >
          <Grid item gap={1} xs={9} display="flex">
            <Typography variant="h2-bold" style={{ textTransform: "inherit", marginRight: "5px" }}>
              {scorecardData?.data?.name}
            </Typography>

            <VersionTag
              version={isNaN(Number(Version?.version)) ? undefined : Number(Version?.version) ?? selectedVersion}
              variant={Version ? tagVariant : "positive"}
            />
          </Grid>

          {shouldShowRestoreButton && (
            <Button
              variant="secondary"
              size="small"
              onClick={() => changeActiveScorecardVersion(selectedVersion as string)}
              disabled={scorecardChangeMutation.isLoading}
            >
              {scorecardChangeMutation.isLoading ? <CircularProgress size={12} /> : "Restore"}
            </Button>
          )}
        </Grid>

        {/* Body */}
        <Grid item xs={12} p={1.5} pt={0} mt={1}>
          {Version?.rules?.map((rule, index) => {
            const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)

            return (
              <RuleCard
                versionHistoryRuleInfo={{
                  id: uuidv4(),
                  levelOneConditions: convertedRule[0],
                  levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
                  levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
                }}
                ruleType={detectRuleType(rule?.condition)}
                key={`${Version?.rules?.length}`}
                isScorecardLoading={Boolean(updateScorecardMutation.isLoading || isScorecardsetLoading)}
                id={`${index}`}
                scorecardIndex={index}
                index={index}
                formik={currentFormik}
                updateMode={isScorecardInEditMode}
                createMode={false}
                ruleName={rule?.name}
                ruleWeight={rule?.return_value}
                conditionListFile={rule?.condition_list_file}
                ruleIndex={index}
              />
            )
          })}
        </Grid>
      </Grid>
    )
  }

  const getWeightValue = (): number | undefined => {
    return scorecardFormik.values.scorecards[currentScorecardIndex]?.weight
      ? `${scorecardFormik.values.scorecards[currentScorecardIndex]?.weight}`?.endsWith(".00000")
        ? parseInt(scorecardFormik.values.scorecards[currentScorecardIndex]?.weight)
        : parseFloat(scorecardFormik.values.scorecards[currentScorecardIndex]?.weight)
      : undefined
  }

  const shouldDisplayVersionHistoryButton =
    !isScorecardsetInEditMode && !isScorecardInEditMode && SCSViewMode === "read" && !dialogView

  const handleAddingNewRule = (ruleType: "simple" | "complex"): void => {
    const condition = getNewRuleInfo(
      currentFormik?.values?.scorecards,
      "scorecard",
      currentScorecardIndex,
    )?.commonFeature

    currentFormik?.setFieldValue(`scorecards[${currentScorecardIndex}].ruleCards`, [
      ...currentFormik?.values?.scorecards[currentScorecardIndex]?.ruleCards,
      {
        type: ruleType,
        id: `new-${uuidv4()}`,
        ruleName: `new-${uuidv4()}`,
        levelOneConditions:
          ruleType === "complex"
            ? [
                {
                  ...EMPTY_SCORECARD_CONDITION,
                  feature: condition?.name,
                  type: condition?.type,
                  value: isTypeIncluded(condition?.type as string) ? condition?.type : "",
                },
                {
                  ...EMPTY_SCORECARD_CONDITION,
                  feature: condition?.name,
                  type: condition?.type,
                  value: isTypeIncluded(condition?.type as string) ? condition?.type : "",
                },
              ]
            : [
                {
                  ...EMPTY_SCORECARD_CONDITION,
                  feature: condition?.name,
                  type: condition?.type,
                  value: isTypeIncluded(condition?.type as string) ? condition?.type : "",
                },
              ],
        levelTwoConditions: [],
        return_value: "",
        condition_list_file: { name: "", uuid: "" },

        schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
      },
    ])
  }

  const handleCancelScorecard = (): void => {
    scorecardFormik.setFieldValue("scorecards", scorecardsSnapShot)

    setIsScorecardInEditMode(false)

    singleScorecardFormik.resetForm()
  }

  return (
    <Fragment>
      {/* Versioning Dialog */}
      {isVersionDialogOpen && (
        <VersioningDialog
          isOpen={isVersionDialogOpen}
          onClose={() => setIsVersionDialogOpen(false)}
          versions={scorecardData?.data?.versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          maxWidth="lg"
          activeVersion={activeScorecardVersion as ScoreCardRetrieve}
        >
          <ScorecardContainerInVersionHistory />
        </VersioningDialog>
      )}

      {/* || (!scorecard?.rules && SCSViewMode === "read" && !isScorecardInEditMode)*/}
      {isScorecardDataLoading ? (
        <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
          <ScorecardLoadingComponent />
        </Grid>
      ) : (
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item xs={12}>
            <Card className={styles.card}>
              <CardHeader
                className={styles.cardHeader}
                title={
                  <Grid
                    direction={smallActionButtons ? "row" : "column"}
                    container
                    justifyContent={smallActionButtons ? "space-between" : "flex-start"}
                    alignItems={smallActionButtons ? "center" : "flex-start"}
                    maxWidth="100%"
                  >
                    {(SCSViewMode === "edit" && !scorecard?.rules?.length) || isScorecardInEditMode ? (
                      <Grid item container width="fit-content" xs={6} md={3}>
                        <InputText
                          id={`scorecards[${currentScorecardIndex}].name`}
                          hideDescription
                          placeholder="Name"
                          value={currentFormik.values.scorecards[currentScorecardIndex]?.name}
                          handleChange={currentFormik.handleChange}
                          handleBlur={currentFormik.handleBlur}
                          error={getFieldErrorIfTouched(
                            currentFormik?.errors,
                            currentFormik?.touched,
                            `scorecards.${currentScorecardIndex}.name`,
                          )}
                          disabled={currentFormik.isSubmitting || Boolean(updateScorecardMutation.isLoading)}
                          fullWidth
                        />
                      </Grid>
                    ) : (
                      <Grid item container width="fit-content" xs={6}>
                        <Typography style={{ width: "100%" }} className={styles.cardTitle} noWrap variant="h3-bold">
                          {scorecard?.name ?? currentFormik.values.scorecards[currentScorecardIndex]?.name}
                        </Typography>
                      </Grid>
                    )}

                    <Grid
                      item
                      container
                      xs={12}
                      sm={6}
                      justifyContent="flex-end"
                      style={{ justifyContent: smallActionButtons ? "flex-end" : "flex-start" }}
                    >
                      <Grid container item xs={3} mr={1}>
                        {(SCSViewMode === "edit" && !scorecard?.rules?.length) || SCSViewMode === "weight-edit" ? (
                          <InputText
                            hideDescription
                            key={scorecardFormik.values.scorecards[currentScorecardIndex]?.uuid}
                            id={`scorecards[${currentScorecardIndex}].weight`}
                            placeholder="Weight"
                            value={getWeightValue()}
                            handleChange={scorecardFormik.handleChange}
                            disabled={scorecardFormik.isSubmitting || isScorecardsetLoading}
                            handleBlur={scorecardFormik.handleBlur}
                            fullWidth
                            variant="filled"
                            error={getFieldErrorIfTouched(
                              currentFormik?.errors,
                              currentFormik?.touched,
                              `scorecards.${currentScorecardIndex}.weight`,
                            )}
                          />
                        ) : (
                          <InfoBlock
                            text={
                              scorecard?.weight
                                ? `${parseInt(scorecard?.weight)}%`
                                : `${parseInt(scorecardFormik.values.scorecards[currentScorecardIndex]?.weight)}%`
                            }
                          />
                        )}
                      </Grid>

                      {shouldDisplayVersionHistoryButton && (
                        <Fragment>
                          <Box mr={1} alignSelf="flex-start" mt={-0.3}>
                            <Button
                              size="regular"
                              variant="secondary"
                              onClick={() => {
                                setSelectedVersion(activeScorecardVersion?.version)
                                setIsVersionDialogOpen(true)
                              }}
                            >
                              Version History
                            </Button>
                          </Box>
                          <Box alignSelf="flex-start" mt={-0.3}>
                            <Button
                              variant="secondary"
                              disabled={Boolean(updateScorecardMutation.isLoading)}
                              size="regular"
                              onClick={() => {
                                setIsScorecardInEditMode(!isScorecardInEditMode)
                                // to deep copy array of nested objects
                                setScorecardsSnapShot(JSON.parse(JSON.stringify(currentFormik.values.scorecards)))
                              }}
                            >
                              Edit
                            </Button>
                          </Box>
                        </Fragment>
                      )}

                      {isScorecardInEditMode && SCSViewMode === "read" && !dialogView && (
                        <Fragment>
                          <Box mr={1} alignSelf="flex-start">
                            <Button
                              disabled={Boolean(updateScorecardMutation.isLoading)}
                              onClick={handleCancelScorecard}
                              size="regular"
                              variant={"secondary"}
                            >
                              cancel
                            </Button>
                          </Box>
                          <Box alignSelf="flex-start">
                            <Button
                              variant="primary"
                              size="regular"
                              onClick={() => singleScorecardFormik.submitForm()}
                              disabled={Boolean(updateScorecardMutation.isLoading)}
                            >
                              {updateScorecardMutation.isLoading ? (
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  display="flex"
                                  className={styles.loadingContainer}
                                >
                                  <Grid item margin={"auto"}>
                                    <CircularProgress style={{ margin: "auto", verticalAlign: "top" }} size={17} />
                                  </Grid>
                                </Grid>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          </Box>{" "}
                        </Fragment>
                      )}
                    </Grid>
                  </Grid>
                }
                action={
                  SCSViewMode !== "read" && (
                    <Grid item xs={3} mt={0.5}>
                      <IconButton
                        aria-label="settings"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget)}
                      >
                        <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
                      </IconButton>
                      <Menu
                        key="basic-menu"
                        anchorEl={anchor}
                        open={openScoreCardMenu}
                        onClose={() => setAnchor(null)}
                        menuMaxContent
                      >
                        <Tooltip
                          width={"100%"}
                          title={
                            1 === scorecardFormik.values?.scorecards?.length &&
                            "Scorecardset should have at least one scorecard"
                          }
                        >
                          <MenuItem
                            className={styles.x}
                            style={{ overflowY: "hidden" }}
                            onClick={() => {
                              setAnchor(null)
                              handleRemoveScorecard?.()
                            }}
                            disabled={scorecardFormik.values?.scorecards?.length === 1 || isScorecardsetLoading}
                          >
                            <Typography variant="a" className={styles.removeCardMenuItem}>
                              Remove Card
                            </Typography>
                          </MenuItem>
                        </Tooltip>
                      </Menu>
                    </Grid>
                  )
                }
              />

              {/* Card content */}
              <CardContent className={styles.cardContent}>
                {
                  <Grid
                    py={0.5}
                    key={currentScorecardIndex}
                    item
                    container
                    xs={12}
                    direction="column"
                    justifyContent="center"
                  >
                    {dialogView ? (
                      scorecard?.rules
                        ?.sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0))
                        ?.map((rule, index) => {
                          const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)
                          return (
                            <RuleCard
                              isScorecardLoading={Boolean(updateScorecardMutation.isLoading || isScorecardsetLoading)}
                              versionHistoryRuleInfo={{
                                id: uuidv4(),
                                levelOneConditions: convertedRule[0],
                                levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
                                levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
                              }}
                              ruleType={detectRuleType(rule?.condition)}
                              ruleIndex={index}
                              scorecardIndex={currentScorecardIndex}
                              formik={currentFormik}
                              editMode={false}
                              createMode={false}
                              ruleWeight={rule?.return_value}
                              conditionListFile={rule?.condition_list_file}
                            />
                          )
                        })
                    ) : (
                      <Fragment>
                        {currentFormik?.values?.scorecards[currentScorecardIndex]?.ruleCards?.map(
                          (item: ScoreRule, index: number) => (
                            <RuleCard
                              isScorecardLoading={Boolean(updateScorecardMutation.isLoading || isScorecardsetLoading)}
                              ruleType={item?.type as "simple" | "complex"}
                              ruleIndex={index}
                              scorecardIndex={currentScorecardIndex}
                              formik={currentFormik}
                              editMode={isScorecardInEditMode}
                              createMode={!currentFormik?.values?.scorecards[currentScorecardIndex]?.uuid}
                              ruleWeight={
                                currentFormik?.values?.scorecards[currentScorecardIndex]?.ruleCards[index]?.return_value
                              }
                              conditionListFile={{
                                name: item?.condition_list_file?.name as string,
                                uuid: item?.condition_list_file?.uuid as string,
                              }}
                            />
                          ),
                        )}

                        {(SCSViewMode === "edit" || isScorecardInEditMode) && (
                          <Grid item marginBottom={1}>
                            <Tooltip
                              title={
                                currentFormik?.values?.scorecards[currentScorecardIndex]?.conditionsArray?.length > 7
                                  ? "You have reached the maximum number of rules"
                                  : ""
                              }
                              placement="top"
                            >
                              <MuiButton
                                size="small"
                                className={styles.MuiButton}
                                disabled={
                                  currentFormik?.isSubmitting ||
                                  Boolean(updateScorecardMutation.isLoading || isScorecardsetLoading) ||
                                  currentFormik?.values?.scorecards[currentScorecardIndex]?.conditionsArray?.length > 7
                                }
                                onClick={(event) => setAnchorEl(event.currentTarget)}
                              >
                                + Add Rule
                              </MuiButton>
                              <Menu
                                key="basic-menu1"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                                menuMaxContent
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleAddingNewRule("simple")
                                    setAnchorEl(null)
                                  }}
                                >
                                  <Grid container display={"flex"} gap={1} height={"20px"}>
                                    <Grid item>
                                      <AddOutlinedIcon fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="a">Add Rule</Typography>
                                    </Grid>
                                  </Grid>
                                </MenuItem>

                                <MenuItem
                                  onClick={() => {
                                    handleAddingNewRule("complex")
                                    setAnchorEl(null)
                                  }}
                                >
                                  <Grid container display={"flex"} gap={1} height={"20px"}>
                                    <Grid item>
                                      <AddBoxOutlinedIcon
                                        fontSize="small"
                                        htmlColor={theme.palette.grayscale.text[1]}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="a">Add Group</Typography>
                                    </Grid>
                                  </Grid>
                                </MenuItem>
                              </Menu>
                            </Tooltip>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                  </Grid>
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}
