import React, { useState } from "react"

import { Grid } from "@mui/material"
import { Chip, InputChangeEvent, InputText, Select, Typography } from "@synapse-analytics/synapse-ui"

import { useDebounce } from "../hooks/useDebounce"

import styles from "./SearchSortFilter.module.scss"

type Props = {
  searchValue: string | undefined
  onSearchValueChange: React.Dispatch<React.SetStateAction<string>>
  sortValue: string | undefined
  onSortValueChange: React.Dispatch<React.SetStateAction<string>>
  filterValue?: string[] | null
  onFilterValueChange?: React.Dispatch<React.SetStateAction<string[]>>
  containerType: string
  filterOptions?: string[]
  sortOptions?: string[]
  disableSearch?: boolean
  isFilterLoading?: boolean
  isDebouncedInput?: boolean
}

/**
 * search sort filter component
 * @param  {string} searchValue
 * @param  {React.Dispatch} setSearchValue callback filter setting function
 * @param  {string} onSortValueChange value in the sort component
 * @param  {React.Dispatch} setSortValue callback filter setting function
 * @param  {string[]} filterValue
 * @param  {React.Dispatch} setFilterValue callback filter setting function
 * @param  {string} containerType to control placeholder and style accordingly
 * @param  {string[]} filterOptions
 * @param  {string[]} sortOptions
 * @param  {boolean} disableSearch option to disable search
 * @param  {boolean} isFilterLoading filter loading mode
 * @return {React.ReactElement}
 */
export function SearchSortFilter(props: Readonly<Props>): React.ReactElement {
  const {
    searchValue,
    sortValue,
    onSearchValueChange,
    onSortValueChange,
    filterValue,
    onFilterValueChange,
    containerType,
    filterOptions,
    sortOptions,
    disableSearch = false,
    isFilterLoading = false,
    isDebouncedInput,
  } = props

  // setting the search input placeholder based on the container
  const placeHolder = containerType + " Name"

  const [innerSearchValue, setInnerSearchValue] = useState<string>("")

  const onChange = (): void => {
    if (isDebouncedInput) {
      onSearchValueChange(innerSearchValue ?? "")
    }
  }

  const debouncedOnChange = useDebounce(onChange, 400)

  return (
    <Grid container direction="row" className={containerType === "Model" ? styles.modMain : styles.depMain}>
      {/* Sort */}
      {sortOptions && 0 < sortOptions.length && (
        <div className={styles.compSpacing}>
          <Grid item xs={12}>
            <Select
              id="sort-by"
              label="Sort By"
              options={sortOptions}
              value={sortValue}
              handleChange={(e: InputChangeEvent) => onSortValueChange(String(e.target.value))}
            />
          </Grid>
        </div>
      )}

      {/* Search */}
      {!disableSearch && (
        <div className={styles.compSpacing}>
          <InputText
            label="Search"
            id="search"
            value={isDebouncedInput ? innerSearchValue : searchValue}
            variant="filled"
            handleChange={(e: InputChangeEvent) => {
              const target = e.target

              if (isDebouncedInput) {
                debouncedOnChange()
                setInnerSearchValue(target.value as string)
              } else {
                onSearchValueChange(target.value as string)
              }
            }}
            placeholder={placeHolder}
            hideDescription
          />
        </div>
      )}

      {/* Filter */}
      {filterOptions && 0 < filterOptions.length && (
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              <Typography variant="span" gutterBottom={false}>
                Filter
              </Typography>
            </Grid>
            <Grid item xs={12} padding={0}>
              <Grid container direction="row" padding={0}>
                {filterOptions.map((item: string, index) => (
                  <Grid item padding={0} key={index} style={{ marginRight: 4 }}>
                    <Chip
                      isSelected={filterValue?.includes(item)}
                      onClick={() =>
                        onFilterValueChange?.(
                          filterValue?.includes(item)
                            ? filterValue.filter((elem) => elem !== item)
                            : filterValue
                              ? [...filterValue, item]
                              : [item],
                        )
                      }
                      clickable={true}
                      disabled={isFilterLoading}
                      id={`${item.replace(" ", "_")}_chip`}
                      size="large"
                    >
                      {item.toLowerCase().charAt(0).toUpperCase() + item.toLowerCase().slice(1)}
                    </Chip>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
