import React, { Fragment, useContext, useMemo, useState } from "react"

import { useMutation, useQuery } from "react-query"
import { useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router"

import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined"
import { Card, Grid, IconButton, SvgIcon } from "@mui/material"
import { Button, Menu, MenuItem, NotificationUtils, Skeleton, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import moment from "moment"

import { queryClient } from "../../../../../../.."
import { KonanAvatar } from "../../../../../../../components/Avatar"
import { ModelType } from "../../../../../../../components/ModelType"
import { ModelSwitchDialog } from "../../../../../../../components/dialogs/BaseSimpleDialog"
import { KonanLogo } from "../../../../../../../components/icons/KonanLogo"
import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../../../store/CurrentProjectAndModelContext"
import { RootState } from "../../../../../../../store/ReduxStore"
import { CreateRetrainingModelRequest, baseErrorType } from "../../../../../../../types/custom/projects"
import { AutoMLTrainingJob } from "../../../../../../../types/generated/api/AutoMLTrainingJob"
import { EvaluateResult } from "../../../../../../../types/generated/api/EvaluateResult"
import { Model } from "../../../../../../../types/generated/api/Model"
import { PaginatedAutoMLTrainingJobList } from "../../../../../../../types/generated/api/PaginatedAutoMLTrainingJobList"
import { PaginatedRetrainingJobList } from "../../../../../../../types/generated/api/PaginatedRetrainingJobList"
import { PaginatedTrainingJobList } from "../../../../../../../types/generated/api/PaginatedTrainingJobList"
import { PredefinedMetric } from "../../../../../../../types/generated/api/PredefinedMetric"
import { RetrainingJob } from "../../../../../../../types/generated/api/RetrainingJob"
import { TrainingDataUpload } from "../../../../../../../types/generated/api/TrainingDataUpload"
import { TrainingJob } from "../../../../../../../types/generated/api/TrainingJob"
import { isPermitted } from "../../../../../../../utils/PermissionsHelpers"
import { Auth } from "../../../../../../../utils/auth"
import { modelTrainedByKonan } from "../../../../../../../utils/deploymentDetailsHelpers"
import { customRound } from "../../../../../../../utils/genericHelpers"
import { RetrainingCreationDialog } from "../../../../ModelTraining/components/Training/components/RetrainingCreationCard"
import { ModelMetaDataDialog } from "./ModelMetaDataDialog"

import styles from "../Models.module.scss"

type ParamsType = {
  id: string
}

type EvalProps = {
  title: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

/**
 * Mini evaluation metrics card
 * @param {string} title
 * @param {any} value
 * @return {React.ReactElement}
 */
function EvaluationDataCard(props: Readonly<EvalProps>): React.ReactElement {
  const { title, value } = props

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      className={styles.dataCard}
    >
      <Typography noWrap variant="label" style={{ width: "100%" }}>
        <Tooltip title={title} placement="top" width="100%">
          <Typography variant="p" noWrap className={styles.trainingText}>
            {title}
          </Typography>
        </Tooltip>
      </Typography>

      <Typography variant="p" variantColor={2} noWrap className={styles.trainingDate}>
        {typeof value === "number" ? value?.toFixed(2) : value}
      </Typography>
    </Grid>
  )
}

/**
 * Mini evaluation metrics card loader
 */
function EvaluationDataCardLoader(): React.ReactElement {
  return (
    <Grid container direction="row" spacing={1} marginBottom={1.5}>
      {[1, 2, 3].map((_, index: number) => (
        <Grid item xs={4} key={index}>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="flex-start"
            className={styles.dataCard}
          >
            <Skeleton height={10} width={"100%"} />
            <Skeleton height={10} width={"100%"} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

// Evaluation metrics to be used in the evaluation metrics card
const evaluationMetricsMap: Record<string, string> = {
  mae: "Mean Absolute Error",
  rmse: "Root Mean Squared Error",
  f1_score: "F1 Score",
  precision: "Precision",
  recall: "Recall",
}

type Props = {
  modelState: string
  uuid: string
  modelName: string
  port: number | undefined
  imageURL: string
  createdAt: string
  createdBy: string
  onModelDeletionClicked(name: string, id: string, isLive: boolean): void
  trainingDataUUID: string | undefined
  canSwitchToChallenger: boolean
}

/**
 * Konan model card
 * @return  {React.ReactElement}
 */
export function KonanModelCard(props: Readonly<Props>): React.ReactElement {
  const {
    modelState,
    imageURL,
    port,
    createdAt,
    createdBy,
    uuid,
    modelName,
    onModelDeletionClicked,
    trainingDataUUID,
  } = props

  const { id: projectId } = useParams<ParamsType>()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const permissions = Auth.getPermissions()

  const { setCurrentModel } = useContext(CurrentProjectAndModelContext)
  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  // dialog open states
  const [modelInfoDialogOpen, setModelInfoDialogOpen] = useState<boolean>(false)
  const [openRetrainingDialog, setOpenRetrainingDialog] = useState<boolean>(false)
  const [isSwitchDialogOpen, setIsSwitchDialogOpen] = useState<boolean>(false)
  const [isActionResolveDialogOpen, setIsActionResolveDialogOpen] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { data: model, isLoading: isModelLoading } = useQuery<AxiosResponse<Model>, AxiosError>(
    ["model", uuid],
    () => KonanAPI.fetchModel(uuid),
    {
      enabled: modelState === "pending-action",
    },
  )

  const {
    data: evaluationData,
    isLoading: isEvaluationLoading,
    isError,
  } = useQuery<EvaluateResult, AxiosError<baseErrorType>>(
    ["model-eval-metrics", uuid],
    () =>
      KonanAPI.evaluateModel({
        start_date: moment().subtract(30, "days").toISOString(),
        end_date: moment().toISOString(),
        model_uuid: uuid,
      }),
    { enabled: !!uuid },
  )

  const { data: trainingData, isLoading } = useQuery<TrainingDataUpload, AxiosError<baseErrorType>>(
    ["training-data", projectId, trainingDataUUID],
    () => KonanAPI.fetchSingleTrainingData(projectId as string, trainingDataUUID),
    { enabled: !!projectId && !!trainingDataUUID },
  )

  const createRetrainingModelMutation = useMutation<
    AxiosResponse<RetrainingJob>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    CreateRetrainingModelRequest
  >(KonanAPI.createRetrainingModel, {
    onSuccess: () => {
      navigate({
        pathname: `/projects/${projectId}/model-training`,
        search: "page=Training-Jobs",
      })
    },
    onError: ({ response }) => {
      setErrorMessage(response?.data.detail)

      response?.data?.resulting_model_name &&
        NotificationUtils.toast(response?.data?.resulting_model_name[0], {
          snackBarVariant: "negative",
        })
    },
  })

  const promoteModelMutation = useMutation<
    AxiosResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    {
      projectUUID: string | undefined
      modelUUID: string
      newState: string
    }
  >(KonanAPI.switchModelState, {
    onSuccess: async (res) => {
      NotificationUtils.toast(res?.data?.detail ?? "Model Switched Successfully", { snackBarVariant: "positive" })

      setIsActionResolveDialogOpen(false)
      setIsSwitchDialogOpen(false)

      // Invalidate react-query queries
      await queryClient.invalidateQueries("models")
      await queryClient.invalidateQueries(["model", uuid as string])
      await queryClient.invalidateQueries(["overviewModels", projectId])
      await queryClient.invalidateQueries(["modelHistory", projectId])
    },
  })

  const { data: retrainingModels } = useQuery<PaginatedRetrainingJobList, AxiosError>(
    ["models", uuid],
    () => KonanAPI.fetchRetrainingModels(projectId as string, 100, 1),
    {
      enabled: !!uuid,
    },
  )

  const { data: trainingModels } = useQuery<PaginatedTrainingJobList, AxiosError>(
    ["projects", projectId],
    () => KonanAPI.fetchTrainingModels(projectId as string, 100, 1),
    {
      enabled: !!projectId,
    },
  )

  // fetch automl training jobs
  const { data: trainingAutoMlModels } = useQuery<PaginatedAutoMLTrainingJobList, AxiosError<baseErrorType>>(
    ["training-jobs-automl", projectId],
    () => KonanAPI.fetchAutoMlTrainingJobs(projectId as string, 100, 1),
    {
      enabled: !!projectId,
    },
  )

  const modelTrainedOrRetrained = useMemo((): string => {
    if (trainingModels?.results?.length || retrainingModels?.results?.length || trainingAutoMlModels?.results?.length) {
      return modelTrainedByKonan(
        modelName,
        trainingModels?.results as TrainingJob[],
        trainingAutoMlModels?.results as AutoMLTrainingJob[],
        retrainingModels?.results as RetrainingJob[],
      )
    } else {
      return ""
    }
  }, [trainingModels?.results, retrainingModels?.results, trainingAutoMlModels?.results, modelName])

  const handleRetrainingModelCreation = (
    predictions_start_time: string,
    predictions_end_time: string,
    append_training_data: boolean,
    resulting_model_name: string,
    resulting_model_state: string,
  ): void => {
    createRetrainingModelMutation
      .mutateAsync({
        predictions_start_time,
        predictions_end_time,
        append_training_data,
        resulting_model_name,
        resulting_model_state,
        project_uuid: projectId as string,
      })
      .then(() => {
        setOpenRetrainingDialog(false)

        queryClient.invalidateQueries("retrainingModels")
      })
  }

  const handleCreationDialogClose = (): void => {
    setOpenRetrainingDialog(false)
    setErrorMessage(undefined)
  }

  async function promoteModel(newState: string): Promise<void> {
    try {
      await promoteModelMutation.mutateAsync({
        projectUUID: projectId,
        modelUUID: uuid as string,
        newState: newState,
      })
    } catch (e) {
      NotificationUtils.toast("Failed to switch Model " + modelName + " to " + newState, {
        snackBarVariant: "negative",
      })
    }
  }

  return (
    <Fragment>
      {modelInfoDialogOpen && (
        <ModelMetaDataDialog
          UUID={uuid}
          imageUrl={imageURL}
          port={port}
          open={modelInfoDialogOpen}
          modelStatus={modelState}
          modelName={modelName}
          setClose={setModelInfoDialogOpen}
        />
      )}
      {openRetrainingDialog && (
        <RetrainingCreationDialog
          open={openRetrainingDialog}
          onClose={handleCreationDialogClose}
          onModelCreation={handleRetrainingModelCreation}
          retrainingError={errorMessage}
          isLoading={createRetrainingModelMutation.isLoading}
        />
      )}

      {/* Model switch dialog */}
      {isSwitchDialogOpen && (
        <ModelSwitchDialog
          open
          modelState={modelState}
          name="Switch Model Type"
          mode={"model-switch"}
          onAccept={(newModelState) => promoteModel(newModelState)}
          isLoading={promoteModelMutation.isLoading}
          onClose={() => {
            setIsSwitchDialogOpen(false)
            //  To prevent flickering of alerts when closing the dialog
            setTimeout(() => promoteModelMutation.reset(), 300)
          }}
        />
      )}

      {isActionResolveDialogOpen && (
        <ModelSwitchDialog
          open
          name={model?.data?.name as string}
          mode="pending-model-switch"
          onAccept={(newModelState) => promoteModel(newModelState)}
          isLoading={promoteModelMutation.isLoading}
          onClose={() => {
            setIsActionResolveDialogOpen(false)
            //  To prevent flickering of alerts when closing the dialog

            setTimeout(() => {
              promoteModelMutation.reset()
            }, 300)
          }}
        />
      )}

      <Card className="card-box-shadow">
        <Grid container direction="column" className={styles.headerContainer}>
          <Grid item xs={12} container justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={9} container>
              <Grid item>
                <ModelType modelState={modelState} size="small" />
              </Grid>
              {/* showing Konan logo if model was trained by konan */}
              {modelTrainedOrRetrained?.length > 0 && (
                <Grid item>
                  <Typography className={styles.trainedBy} variant="span">
                    {modelTrainedOrRetrained} <KonanLogo logoWidth={40} />
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid
              item
              xs={3}
              className={styles.iconContainer}
              container
              alignItems="flex-start"
              justifyContent="flex-end"
            >
              <Grid item>
                <IconButton
                  size="small"
                  className={styles.modelInfoButton}
                  onClick={() => setModelInfoDialogOpen(true)}
                  id="meta_dialog"
                >
                  <StickyNote2OutlinedIcon className={styles.modelInfoIcon} />
                </IconButton>
              </Grid>

              <Grid item className={styles.headerButtonContainer}>
                <IconButton
                  size="small"
                  aria-label="settings"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                  className={styles.headerButton}
                  id="model_options"
                >
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  menuMaxContent
                >
                  <MenuItem
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                      e.stopPropagation()
                      navigator.clipboard.writeText(uuid)
                      NotificationUtils.toast("Copied to Clipboard!", { snackBarVariant: "positive" })
                    }}
                  >
                    <Typography variant="label">Copy UUID</Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      navigate(`/projects/${projectId}/model-training?page=Training-Data`)
                    }}
                  >
                    <Typography variant="label">Assign to Dataset</Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setCurrentModel(uuid)
                      navigate(`/projects/${projectId}/models/requests`)
                    }}
                  >
                    <Typography variant="label">View API Requests</Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setCurrentModel(uuid)
                      navigate(`/projects/${projectId}/models/drift`)
                    }}
                  >
                    <Typography variant="label">View Data Drift</Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setCurrentModel(uuid)
                      navigate(`/projects/${projectId}/models/logs`)
                    }}
                  >
                    <Typography variant="label">View Logs</Typography>
                  </MenuItem>

                  {/* TODO:: fix it using portal in SUI */}
                  {/* <Tooltip title={modelState === "live" && "Live models cant be deleted"}> */}
                  {isPermitted("Delete model", permissions.konan, flattenedKonanPermissions) && (
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null)
                        onModelDeletionClicked(modelName, uuid, modelState === "live")
                      }}
                      disabled={modelState === "live"}
                    >
                      <Typography variant="label" color="negative" variantColor={modelState === "live" ? 1 : 2}>
                        Delete Model
                      </Typography>
                    </MenuItem>
                  )}
                  {/* </Tooltip> */}
                </Menu>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h2-bold"
              noWrap
              onClick={() => {
                setCurrentModel(uuid)
                navigate(`${pathname.substring(0, pathname.lastIndexOf("/"))}/models/requests`)
              }}
              className={styles.modelName}
              color="important"
              variantColor={2}
            >
              {modelName}
            </Typography>
          </Grid>

          {/* Avatar section */}
          <Grid item container xs={12} justifyContent="space-between">
            <Grid
              container
              item
              spacing={1}
              xs={modelState === "pending-action" && model?.data.pending_action === "fix-model-schema" ? 12 : 8}
              alignItems="flex-start"
              className={styles.avatarContainer}
            >
              <Grid item sx={{ marginTop: "2px" }}>
                {/* SvgIcon is used to fix square avatars on safari */}
                <SvgIcon className={styles.modelAvatar}>
                  <KonanAvatar size={24} name={createdBy} />
                </SvgIcon>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="label" noWrap>
                  {createdBy}
                </Typography>

                <Typography variant="label" noWrap>
                  <Tooltip title={format(new Date(createdAt), "dd/MM/yyyy, p")} placement="right">
                    <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                      {moment(new Date(createdAt)).fromNow()}
                    </Typography>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>

            {/**
             * displays retrain or switch buttons based on the model state
             */}
            {modelState === "live" ? (
              <Grid item className={styles.buttonsContainer}>
                <Button size="small" variant="secondary" onClick={() => setOpenRetrainingDialog(true)}>
                  Retrain
                </Button>
              </Grid>
            ) : (
              isPermitted("Switch model state", permissions.konan, flattenedKonanPermissions) &&
              (modelState !== "pending-action" ? (
                <Grid item className={styles.buttonsContainer}>
                  <Button
                    tooltip={modelState === "live" ? "Can't switch a live model" : undefined}
                    onClick={() => setIsSwitchDialogOpen(true)}
                    disabled={modelState === "live"}
                    variant="secondary"
                    startIcon={<CachedOutlinedIcon sx={{ fontSize: "16px" }} />}
                    size="small"
                  >
                    Switch
                  </Button>
                </Grid>
              ) : isModelLoading ||
                (modelState === "pending-action" && model?.data.pending_action === "fix-model-schema") ? (
                <></>
              ) : (
                <Grid item className={styles.buttonsContainer}>
                  <Button variant="secondary" size="small" onClick={() => setIsActionResolveDialogOpen(true)}>
                    Resolve
                  </Button>
                </Grid>
              ))
            )}
          </Grid>
        </Grid>

        {/* Evaluation metrics section */}
        <Grid container className={styles.bodyContainer}>
          {modelState === "pending-action" && model?.data.pending_action === "fix-model-schema" ? (
            <Grid p={3} item display={"flex"} flexDirection={"column"} justifyContent={"center"}>
              <Grid xs={12}>
                <Typography variant="h2-bold" align="center">
                  This Model collides with project schema.
                </Typography>
              </Grid>

              <Grid container direction="row" justifyContent="center" xs={12}>
                <Typography variant="p" align="center">
                  Delete model and try again with a different schema.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Fragment>
              <Grid container item direction="row">
                <Grid item container direction="row" justifyContent={"space-between"}>
                  <Grid item>
                    <Typography
                      variant="label"
                      tooltip="metrics defined in your retraining logic"
                      className={styles.labelColor}
                      tooltipIconSize={16}
                      tooltipVerticalAlign="middle"
                    >
                      Evaluation Metrics
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="span" className={styles.labelColor}>
                      Last 30 days
                    </Typography>
                  </Grid>
                </Grid>

                {/**
                 * This next Ternary piece of code checks on all the possible results when calling
                 * the `evaluate/` endpoint as follows:
                 *
                 * 1- first, if there's an error returned, then the provided time range is falsy and no
                 * predications exist in this range for this model.
                 *
                 * 2- second, if there are predications in this range for this model but no metrics found
                 * so we need to display a different empty state for it.
                 *
                 * 3- then, if there are metrics then we render it.
                 *
                 * 4- finally, if none of the above is valid, we show the loader for the section.
                 */}
                {!isEvaluationLoading && isError ? (
                  <Grid
                    className={styles.addTrainingSection}
                    container
                    direction="row"
                    justifyContent="space-between"
                    item
                    xs={12}
                    marginBottom={1.5}
                  >
                    <Typography variant="span" align="center" style={{ padding: "4px" }}>
                      No predictions in the last 30 days
                    </Typography>
                  </Grid>
                ) : !isEvaluationLoading &&
                  evaluationData?.metrics?.custom?.length === 0 &&
                  Object.values(evaluationData?.metrics?.predefined as PredefinedMetric).every(
                    (item) => item === null,
                  ) ? (
                  <Grid
                    className={styles.addTrainingSection}
                    container
                    direction="row"
                    justifyContent="space-between"
                    marginBottom={1.5}
                    item
                    xs={12}
                  >
                    <Typography variant="span" align="center">
                      No Metrics to display
                    </Typography>
                  </Grid>
                ) : !isEvaluationLoading &&
                  (evaluationData?.metrics?.custom?.length !== 0 ||
                    Object.values(evaluationData?.metrics?.predefined as PredefinedMetric).some(
                      (item) => item !== null,
                    )) ? (
                  <Grid container direction="row" spacing={1} className={styles.horizontalScroller}>
                    {!isEvaluationLoading &&
                      evaluationData?.metrics &&
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      Object.entries(evaluationData!.metrics!.predefined as PredefinedMetric).map(
                        ([key, value]) =>
                          value !== null && (
                            <Grid item xs={4} className={styles.evaluationCard} key={key}>
                              <EvaluationDataCard
                                title={key in evaluationMetricsMap ? evaluationMetricsMap[key] : key}
                                value={typeof value == "number" ? customRound(value, 2) : value}
                              />
                            </Grid>
                          ),
                      )}

                    {!isEvaluationLoading &&
                      evaluationData?.metrics &&
                      evaluationData?.metrics?.custom?.length > 0 &&
                      evaluationData?.metrics?.custom?.map((elem) => (
                        <Grid item xs={4} className={styles.evaluationCard} key={elem.metric_name}>
                          <EvaluationDataCard
                            title={elem?.metric_name}
                            value={
                              parseFloat(elem.metric_value as unknown as string)
                                ? customRound(parseFloat(elem.metric_value as unknown as string), 2)
                                : elem.metric_value
                            }
                          />
                        </Grid>
                      ))}
                  </Grid>
                ) : (
                  <EvaluationDataCardLoader />
                )}
              </Grid>

              {/* Training data card section */}
              <Grid item container direction="row" alignItems="flex-start">
                <Typography
                  variant="label"
                  tooltip="training data uploaded and attached to this model"
                  tooltipIconSize={16}
                  tooltipVerticalAlign="middle"
                  className={styles.labelColor}
                >
                  Training Data
                </Typography>
              </Grid>

              <Grid marginBottom={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  item
                  xs={12}
                  className={styles.trainingBox}
                >
                  {isLoading ? (
                    <Grid container item>
                      {/* loading state */}
                      <Grid item xs={2}>
                        <Skeleton height={15} width={"100%"} />
                      </Grid>
                    </Grid>
                  ) : trainingData?.name ? (
                    <Grid item sx={{ width: "100%" }}>
                      {/* training data has name */}
                      <Typography variant="p" noWrap>
                        {trainingData?.name}
                      </Typography>
                    </Grid>
                  ) : trainingData?.uuid ? (
                    <Grid item>
                      {/* Rare case, where data has no name */}
                      <Typography variant="p" noWrap>
                        data.csv
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                      {/* No data case */}
                      <Typography variant="span" noWrap align="center" style={{ width: "100%" }}>
                        No training data assigned to this model
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Card>
    </Fragment>
  )
}
