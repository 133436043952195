import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { getTheme } from "../hooks/UseTheme"

// nivo custom color scheme
export const customColorScheme = (): string[] => {
  const theme = getTheme()
  return [
    theme.palette.blue.background[1],
    theme.palette.green.background[1],
    theme.palette.yellow.background[1],
    theme.palette.pink.background[1],
    theme.palette.purple.background[1],
    theme.palette.teal.background[1],
    theme.palette.red.background[1],
  ]
}

export const customRechartsColorScheme = (): {
  background: string
  text: string
  border: string
}[] => {
  const theme = getTheme()
  return [
    {
      background: theme.palette.blue.background[1],
      text: "default",
      border: theme.palette.blue.border,
    },
    {
      background: theme.palette.green.background[1],
      text: "positive",
      border: theme.palette.green.border,
    },
    {
      background: theme.palette.yellow.background[1],
      text: "warning",
      border: theme.palette.yellow.border,
    },
    {
      background: theme.palette.pink.background[1],
      text: "pink",
      border: theme.palette.pink.border,
    },
    {
      background: theme.palette.purple.background[1],
      text: "purple",
      border: theme.palette.purple.border,
    },
    {
      background: theme.palette.teal.background[1],
      text: "teal",
      border: theme.palette.teal.border,
    },
    {
      background: theme.palette.red.background[1],
      text: "negative",
      border: theme.palette.red.border,
    },
  ]
}

// returns auth and backend urls based on the hostname
export function getHostnames(): {
  frontend: string
  auth: string
  backend: string
  registry: string
  docs: string
  doxter: string
} {
  const hostname = window.location.hostname
  if (hostname.includes("app")) {
    const konan_hosts = {
      frontend: hostname,
      auth: hostname.replace("app", "auth"),
      backend: hostname.replace("app", "api"),
      registry: hostname.replace("app", "registry"),
      docs: hostname.replace("app", "docs"),
      doxter: "api.doxter.ai",
    }
    // commenting since doxter doesn't have dev or staging envs
    // if (hostname === "app.konan.ai") {
    //   konan_hosts.doxter = "api.doxter.ai"
    // } else if (hostname === "app.staging.konan.ai") {
    //   konan_hosts.doxter = "api.dev.doxter.ai"
    // } else {
    //   konan_hosts.doxter = "api.dev.doxter.ai"
    // }
    return konan_hosts
  } else {
    return {
      frontend: hostname,
      auth: "auth.dev.konan.ai",
      // example qa branch url
      // backend: "review-kb-757-exp-4qxbbh.api.qa.konan.ai",
      backend: "api.dev.konan.ai",
      registry: "registry.dev.konan.ai",
      docs: "docs.dev.konan.ai",
      doxter: "api.doxter.ai",
    }
  }
}

// Setting google client id based on the hostname
export function getGoogleCreds(): { clientID: string } {
  // production googleID
  if (getHostnames().frontend === "app.konan.ai") {
    return {
      clientID: "59774869638-egai6uaa1f8dcv3meporhket08dbvl8d.apps.googleusercontent.com",
    }
  }
  // (getHostnames().frontend === "localhost" || getHostnames().frontend === "127.0.0.1" || getHostnames().frontend === "app.dev.konan.ai")
  else {
    return {
      clientID: "920860085678-qb4sejiphfr7tss351i10a3phu3ei2sg.apps.googleusercontent.com",
    }
  }
}

export class KonanTimeHelper {
  adjustDate(date: moment.Moment | null, time: "start" | "end"): moment.Moment | null {
    if (date === null) return null
    /**
     * utcOffset(0) to standardize on UTC, and local() on the date before passing it to the date picker.
     * This will convert it back to the user's local time zone for display.
     */
    const adjustedDate = date.utcOffset(0).local()
    if (time === "start") {
      adjustedDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    } else {
      adjustedDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    }

    return adjustedDate
  }

  timeParser(duration: string): string | undefined {
    if (duration.includes(" ")) {
      const tempDuration = duration.split(" ")
      return `${parseInt(tempDuration[0])}d`
    } else {
      const hours = duration.split(":")[0]
      if (parseInt(hours) > 0) {
        return `${parseInt(hours)}h`
      }

      const minutes = duration.split(":")[1]
      if (parseInt(minutes) > 0) {
        return `${parseInt(minutes)}m`
      }

      const seconds = duration.split(":")[2]
      if (parseInt(seconds) > 0) {
        return `${parseInt(seconds)}s`
      }
    }
  }

  formatTimeMinsAndSec = (time: number): string => {
    // Remainder of division by 60
    const seconds = time % 60
    // Divide by 60 and floor the result (get the nearest lower integer)
    const minutes = Math.abs(Math.floor(time / 60))

    // Put it all in one string
    return ("" + minutes).padStart(2, "0") + ":" + ("" + seconds).padStart(2, "0")
  }

  // check if passed date within last week or not
  lastWeekOnly = (date: moment.Moment): boolean => {
    return moment().subtract(7, "days") < date && date < moment()
  }

  // check if two dates are on the same day or not -->
  datesAreOnSameDay = (first: Date, second: Date): boolean =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()

  isToday(date: Date): boolean {
    const today = new Date()

    if (today.toDateString() === date.toDateString()) {
      return true
    }

    return false
  }
}

// Custom helper function to round numbers
export const customRound = (value: number, precision: number): string => {
  return (Math.round(value * 100) / 100).toFixed(value % 1 === 0 ? 0 : precision)
}

export const commify = (num: number | string): string => {
  const parts = num?.toString()?.split(".")
  const numberPart = parts[0]
  const thousands = /\B(?=(\d{3})+(?!\d))/g
  return numberPart?.replace(thousands, ",")
}

export const truncateString = (value: string, maxLineLength: number): string => {
  if (value && value?.length > maxLineLength) {
    return value.slice(0, maxLineLength) + "..."
  } else {
    return value
  }
}

// return tick values interval based on the date range selected
export function getTickInterval(dataLength: number): string {
  if (dataLength <= 10) {
    return "every day"
  } else if (dataLength <= 30) {
    return "every 2 days"
  } else if (dataLength <= 40) {
    return "every 4 days"
  } else if (dataLength <= 100) {
    return "every week"
  } else if (dataLength <= 150) {
    return "every 2 weeks"
  } else {
    return "every month"
  }
}

export function createFileFromString(content: string, filename: string, contentType: string): File {
  const blob = new Blob([content], { type: contentType })
  return new File([blob], filename, { type: contentType })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findDuplicateElementByProperty(arr: Array<any>, property: string): any | undefined {
  const seen = new Set()

  for (const item of arr) {
    const value = item[property]

    if (seen.has(value)) {
      return item // Return the object with the duplicate property value
    }

    seen.add(value)
  }

  return undefined // Return undefined if no duplicates are found
}

// validates that the file selected for upload is a csv file
export function dropZoneCSVExtensionValidator(file: File): null | {
  code: string
  message: string
} {
  if (!file.name.endsWith(".csv")) {
    NotificationUtils.toast("Incorrect file format: Must have .csv extension", { snackBarVariant: "negative" })

    return {
      code: "Wrong file format",
      message: "Must have .csv extension",
    }
  }

  return null
}

/**
 * T and K are generic function args types, inferred in function runtime
 * T is a generic type, inferred in the context of function execution
 * @param arg in this case arg is generic, T -> list item whether it's string or object
 * @param key K -> the key in the list item if item is object
 * @returns {boolean} Returns true if the object has the specified property of type string, otherwise false.
 */
function isObjectWithProperty<T, K extends keyof T>(arg: T, key?: K): arg is T & { [P in K]: string } {
  if (key === undefined) {
    return false // If key is undefined, arg cannot be an object with a string property
  }
  return typeof arg === "object" && arg !== null && key in arg && typeof arg[key] === "string"
}

/**
 * checks if string type item has an exact match from list or not
 * generic functions with generic list type, in case we want to check on list of strings
 * or list of objects that has given key in it
 * @param list list of items we iterating on, can be list of strings or objects
 * @param word the word to exact match it
 * @param key the key to compare in list, only makes sense if list items are objects not strings
 * @returns {boolean}
 */
export function hasExactMatch<T>(list: Array<T>, word: string, key?: keyof T): boolean {
  return list.some((item) => {
    if (key && isObjectWithProperty(item, key)) {
      return item[key]?.toLowerCase() === word?.toLowerCase()
    } else if (typeof item === "string") {
      return item?.toLowerCase() === word?.toLowerCase()
    } else {
      return false
    }
  })
}

/**
 * Function to extract the page from pagination backend link
 * @param {string} link
 * @returns {number}
 */
export const extractPageFromBackEndPaginationLink = (link: string): number => {
  return parseInt(link.split("page=")[1]?.split("&")[0])
}

/**
 * Gets the starting position of the specified occurrence of a substring within a string.
 *
 * @param {string} string - The string to search within.
 * @param {string} subString - The substring to search for.
 * @param {number} index - The 1-based index of the occurrence to find.
 * @returns {number} The zero-based index position of the nth occurrence of the substring within the string.
 *          If the specified occurrence is not found, the length of the string is returned,
 *          which effectively points to the end of the string.
 *
 * @example
 * // returns 9
 * getPosition("/projects/e264951b-dcfa-4eab-867a-c9f4d66dcad6/models/requests", "/", 3);
 *
 * @example
 * // returns 48
 * getPosition("/projects/e264951b-dcfa-4eab-867a-c9f4d66dcad6/models/requests", "/", 5);
 */
export function getPosition(string: string, subString: string, index: number): number {
  //get the starting position of the Nth occurrence of a substring
  //for example -> getPosition(/projects/e264951b-dcfa-4eab-867a-c9f4d66dcad6/models/requests, "/", 3)
  //for example -> getPosition(/projects/e264951b-dcfa-4eab-867a-c9f4d66dcad6/models/requests, "/", 3)
  // -> get the starting position for the 3rd occurrence of "/" from the url
  return string.split(subString, index).join(subString).length
}

/**
 * Extracts the initial letters from each word in a provided string.
 *
 * @param {string} name - The full string from which to extract initial letters.
 * @returns {string} A string composed of the first letter of each word in the `name` string.
 *          If `name` is empty or if it's a single word, the first letter of the string or word is returned.
 *
 * @example
 * // returns "JD"
 * getInitialLetters("John Doe");
 *
 * @example
 * // returns "A"
 * getInitialLetters("Alice");
 */
export function getInitialLetters(name: string): string {
  return name
    .split(" ")
    .map((item) => item[0])
    .join("")
}
