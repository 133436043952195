import { Edge, Node } from "reactflow"

import { PatchedScriptUpdateRequest } from "../generated/api/PatchedScriptUpdateRequest"
import { ScriptCreateFromTemplateRequest } from "../generated/api/ScriptCreateFromTemplateRequest"
import { ScriptCreateRequest } from "../generated/api/ScriptCreateRequest"
import { ScriptTemplate } from "../generated/api/ScriptTemplate"
import { WorkflowSchemaFeature } from "../generated/api/WorkflowSchemaFeature"

export interface WorkflowSimulationFormik {
  workflowID: string
  source: string
  totalRequestsNumber: number
  shouldProceed: boolean
  predictions_datafile: string | undefined
  predictions_start_time: string | undefined
  predictions_end_time: string | undefined
  target_column: string | undefined | null
}

export type SelectionDataType =
  | "ScorecardsetNode"
  | "RulesetNode"
  | "ProgramNode"
  | "DecisionNode"
  | "StartNode"
  | "ProjectNode"
  | "ScriptNode"
  | "TaglistNode"

type Features = {
  [key: string]: string
}

export type CreateScriptRequest = ScriptCreateRequest & { project_uuid: string }

export type CreateBuiltinScriptRequest = ScriptCreateFromTemplateRequest & {
  project_uuid: string
  template_uuid: string
}

export type UpdateScriptRequest = PatchedScriptUpdateRequest & {
  project_uuid: string
  script_uuid: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  creds?: Record<string, any>
}
export type TestScriptRequest = { project_uuid: string; script_uuid: string; features?: Features; version?: string }

export enum mapNodeTypeToFlowFormatEnum {
  "filter" = "FilterNode",
  "label" = "LabeledNode",
  "ruleset" = "SelectionNode",
  "program" = ruleset,
  "decision" = ruleset,
  "project" = ruleset,
  "scorecardset" = ruleset,
  "script" = ruleset,
  "taglist" = ruleset,
  "calculator" = "CalculatorNode",
  "addBlock" = "AddBlockNode",
}

export type SchemaFeature = {
  name: string
  source?: string
  type?: WorkflowSchemaFeature.type
  is_required?: boolean
  file?: boolean
  id?: string
  new?: boolean
  remove?: boolean
}

export type WorkflowSchema = {
  features: Array<SchemaFeature>
  workflowName: string
}

export type FeatureData = {
  workflows: Array<string>
  type: WorkflowSchemaFeature.type
  name: string
}

export enum MapWorkflowValueTypeToFeatureType {
  "string" = WorkflowSchemaFeature.type.TEXT,
  "date" = WorkflowSchemaFeature.type.DATE,
  "number" = WorkflowSchemaFeature.type.NUMBER,
  true = WorkflowSchemaFeature.type.BOOLEAN,
  false = WorkflowSchemaFeature.type.BOOLEAN,
  "null" = WorkflowSchemaFeature.type.UNDEFINED,
}

// Type for operators including the fallback ones
export enum MappedOperators {
  "<" = "less than",
  ">" = "more than",
  "<=" = "less than/equal",
  ">=" = "more than/equal",
  "=" = "equal",
  "between" = "between",
}

export type InputFeature = {
  feat: string
  value: string | number
}

export type ConfirmCloseDialogState = {
  isOpen: boolean
  action?: { name: string; workflowName?: string; workflowId?: string }
}

export type ComputedFeatures = {
  computed_feature_names: Array<string>
  workflow_name: string
  workflow_uuid: string
}

export type UpdateGraphParams = {
  dataAfterDeletion?: { nodes: Node[]; edges: Edge[] }
  arrayOfNewNodes?: Node[]
  isNodeBetween?: boolean
  targetNode?: string
  sourceNode?: string
  edgeLabel?: string
}

export type FeatureMapping = {
  node_feature: string
  workflow_feature: string | number
  type?: WorkflowSchemaFeature.type
}

export interface CustomScriptTemplate extends ScriptTemplate {
  isInUse?: boolean
}

export interface RulesetNode {
  node_type: string
  uncovered_route: number | string
  ruleset: string
}

export interface FilterNode {
  node_type: string
  feature_nesting_operator: string
  true_route: number | string
  false_route: number | string
  condition: string
}

export interface TaglistNode {
  taglist: string
  node_type: string
  resolution_route: number | string
}

export interface ScorecardsetNode {
  scorecard_set: string
  node_type: string
  resolution_route: number | string
}

export interface ProgramNode {
  prorgram: string
  node_type: string
  resolution_route: number | string
}

export interface CalculatorNode {
  node_type: string
  computed_feature_name: string
  equation: string
  feature_nesting_operator: string
  resolution_route: number | string
}

export interface ScriptNode {
  node_type: string
  computed_feature_name: string
  script: string
  feature_mappings?: Array<FeatureMapping>
  resolution_route: number | string
}

export interface ProjectNode {
  node_type: string
  computed_feature_name: string
  project: string
  resolution_route: number | string
}

export interface LabelNode {
  node_type: string
  return_label: string
}

export type WorkflowNode =
  | LabelNode
  | FilterNode
  | RulesetNode
  | ScorecardsetNode
  | ProgramNode
  | ScriptNode
  | TaglistNode
  | CalculatorNode
  | ProjectNode
