import React, { useState } from "react"

import { useSelector } from "react-redux"

import CloseIcon from "@mui/icons-material/Close"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import { Tab, Tabs, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { Labels } from "../../screens/ProjectDetails/components/DecisonEngines/components/Workflows/components/Labels"
import { RootState } from "../../store/ReduxStore"
import { isPermitted } from "../../utils/PermissionsHelpers"
import { Auth } from "../../utils/auth"
import { ProjectSchemaTable } from "../tables/ProjectSchemaTable"

type Props = {
  isOpen: boolean
  onClose: () => void
}

export function WorkflowConfigurationDialog(props: Readonly<Props>): React.ReactElement {
  const { isOpen, onClose } = props

  const permissions = Auth.getPermissions()

  const theme = getTheme()
  const MuiTheme = useMuiTheme()

  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  const [activeTab, setActiveTab] = useState<string>("Schema")

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      fullWidth
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("md"))}
      onClose={onClose}
    >
      <DialogTitle className="dialog-header-base" style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2-bold">Project Configuration</Typography>

        <IconButton onClick={onClose} size="small" className="close-icon-button">
          <CloseIcon style={{ color: theme.palette.grayscale.text[2] }} />
        </IconButton>
      </DialogTitle>

      <DialogContent className="dialog-content-base">
        <Grid item xs={12} container mb={1}>
          {/* // TODO:: should handle this variant from SUI */}
          <Tabs value={activeTab} className="base-tabs">
            {/*Grouping Tabs */}
            {[
              "Schema",
              ...(isPermitted("List labels", permissions.konan, flattenedKonanPermissions) ? ["Labels"] : []),
            ].map((tab) => (
              <Tab
                key={tab}
                label={tab}
                onClick={() => setActiveTab(tab)}
                value={tab}
                selected={activeTab === tab}
                id={tab}
              />
            ))}
          </Tabs>
        </Grid>

        <Grid item marginBottom={2}>
          {/* Content */}
          {activeTab === "Labels" ? <Labels /> : activeTab === "Schema" && <ProjectSchemaTable />}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
