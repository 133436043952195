import { UseQueryResult } from "react-query"

import { AxiosResponse } from "axios"

import {
  AdjustedDriftJob,
  AdjustedDriftSkewInfo,
  AnomalyInfo,
  ConfusionMatrixInput,
  CoverageGraphInput,
  DataDriftBarChartData,
  DriftJobs,
  DriftResponse,
  MetricsState,
  ModelComparison,
} from "../types/custom/projects"
import { ClassificationConfusionMatrixCell } from "../types/generated/api/ClassificationConfusionMatrixCell"
import { ClassificationLabelEvaluation } from "../types/generated/api/ClassificationLabelEvaluation"
import { ListModelOutputs } from "../types/generated/api/ListModelOutputs"
import { Model } from "../types/generated/api/Model"
import { customRound } from "./genericHelpers"

export function mapSeverityToFeatures(driftResults: DriftJobs[]): AdjustedDriftJob[] {
  const adjustedDriftJobs: AdjustedDriftJob[] = []
  if (driftResults && driftResults.length > 0) {
    driftResults?.forEach((driftObject) => {
      const skewInfo: AdjustedDriftSkewInfo[] = []
      driftObject?.result?.detected_drift?.anomalyInfo?.forEach((anomalyFeature: AnomalyInfo) => {
        skewInfo.push({
          error: true,
          errorReason: anomalyFeature.reason[0],
          featureName: anomalyFeature.feature,
          driftValue: null,
          driftThreshold: null,
        })
      })
      driftObject?.result?.detected_drift?.driftSkewInfo?.forEach((feature) => {
        const matchingIndex = skewInfo.findIndex((e) => e.featureName === feature.feature)
        if (matchingIndex > -1) {
          skewInfo[matchingIndex] = {
            ...skewInfo[matchingIndex],
            driftValue: feature.skewMeasurements[0].value,
            driftThreshold: feature.skewMeasurements[0].threshold,
          }
        } else {
          skewInfo.push({
            error: false,
            errorReason: null,
            featureName: feature.feature,
            driftValue: feature.skewMeasurements[0].value,
            driftThreshold: feature.skewMeasurements[0].threshold,
          })
        }
      })
      if (driftObject?.status === "success" || driftObject?.status === "error") {
        adjustedDriftJobs.push({
          ...driftObject,
          result: skewInfo,
        })
      }
    })
  }
  return adjustedDriftJobs
}

export function convertDataDriftListToChart(
  list: UseQueryResult<AxiosResponse<DriftResponse>>[],
  modelList: Model[],
): DataDriftBarChartData[] {
  const result: DataDriftBarChartData[] = []
  if (list?.length > 0) {
    list.forEach((driftJob: UseQueryResult<AxiosResponse<DriftResponse>>, index: number) => {
      const adjustedDriftResponse = mapSeverityToFeatures(driftJob?.data?.data?.results as DriftJobs[])
      let drifted = 0
      let passed = 0
      let total = 0

      const model = modelList[index]
      if (adjustedDriftResponse[0]?.result && adjustedDriftResponse[0]?.result?.length > 0) {
        drifted = adjustedDriftResponse[0]?.result?.filter((feature: AdjustedDriftSkewInfo) => feature.error).length
        passed = adjustedDriftResponse[0]?.result?.length - drifted
        total = adjustedDriftResponse[0]?.result?.length
      }

      result.push({
        name: model?.name ?? "",
        created_at: model?.created_at ?? "",
        state: model?.state ?? "",
        drifted,
        passed,
        total,
      })
    })
  }
  return result
}

export function compareModels(modelA: ListModelOutputs[], modelB: ListModelOutputs[]): ModelComparison[] {
  const result: ModelComparison[] = []
  if (modelA && modelA.length > 0 && modelB && modelB.length > 0) {
    modelA.forEach((requestA: ListModelOutputs) => {
      const requestB = modelB.find((item) => item.uuid === requestA.uuid)
      result.push({
        model_a_uuid: requestA.model,
        model_b_uuid: requestB?.model,
        model_a_response_time: requestA.mls_response_time,
        model_b_response_time: requestB?.mls_response_time,
        model_a_status_code: requestA.mls_status_code,
        model_b_status_code: requestB?.mls_status_code,
        created_at: requestA.created_at,
        feedback: requestA.feedback,
        features: requestA.features,
        model_a_mls_output: requestA?.mls_output_json ?? requestA?.mls_output,
        model_b_mls_output: requestB?.mls_output_json ?? requestB?.mls_output,
      })
    })
  } else if (modelA && modelA.length > 0 && modelB && modelB.length === 0) {
    modelA.forEach((requestA: ListModelOutputs) => {
      result.push({
        model_a_uuid: requestA.model,
        model_b_uuid: "",
        model_a_response_time: requestA.mls_response_time,
        model_b_response_time: null,
        model_a_status_code: requestA.mls_status_code,
        model_b_status_code: null,
        created_at: requestA.created_at,
        feedback: requestA.feedback,
        features: requestA.features,
        model_a_mls_output: requestA?.mls_output_json ?? requestA?.mls_output,
        model_b_mls_output: undefined,
      })
    })
  } else if (modelA && modelA.length === 0 && modelB && modelB.length > 0) {
    modelB.forEach((requestB: ListModelOutputs) => {
      result.push({
        model_a_uuid: "",
        model_b_uuid: requestB?.model,
        model_a_response_time: null,
        model_b_response_time: requestB?.mls_response_time,
        model_a_status_code: null,
        model_b_status_code: requestB?.mls_status_code,
        created_at: requestB.created_at,
        feedback: requestB.feedback,
        features: requestB.features,
        model_a_mls_output: undefined,
        model_b_mls_output: requestB?.mls_output_json ?? requestB?.mls_output,
      })
    })
  }

  return result
}

// A function that converts classification labels into coverage pie chart (nivo format)
export function convertCoverageToPieChart(
  data: ClassificationLabelEvaluation[],
  totalObservations: number,
): CoverageGraphInput[] {
  const result: CoverageGraphInput[] = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      if (item.predicted_occurances > 0) {
        result.push({
          id: item.name,
          label: item.name,
          count: item.predicted_occurances,
          value: Number(customRound((item.predicted_occurances / totalObservations) * 100, 2)),
        })
      }
    })
  }

  return result
}

// A function that converts confusion matrix and labels into nivo heatmap format
export function convertDataToConfusionMatrix(
  data: ClassificationConfusionMatrixCell[],
  labels: ClassificationLabelEvaluation[],
): ConfusionMatrixInput[] {
  const result: ConfusionMatrixInput[] = []
  if (data && data.length > 0 && labels && labels.length > 0) {
    const temp: { x: string; y: number }[] = []
    labels.forEach((label) => {
      temp.push({
        x: label.name,
        y: 0,
      })
    })

    labels.forEach((label) => {
      result.push({
        id: label.name,
        data: Array.from(temp),
      })
    })

    data.forEach((item) => {
      const indexX = result.findIndex((x) => x.id === item.actual_label)
      const indexY = result[indexX].data.findIndex((y) => y.x === item.predicted_label)

      result[indexX].data[indexY] = { ...result[indexX].data[indexY], y: item.count }
    })
  }

  return result
}

// Function that returns the empty state of the metrics graphs
export function getModelPredictionsGraphsState(
  projectLevelPredictionsCount: number,
  ModelLevelPredictionsCountForSelectedTimeRange: number,
  observationsCount: number,
  feedbacksCount: number,
  projectHasFeedback: boolean,
): MetricsState {
  const projectHasPredictions = projectLevelPredictionsCount > 0
  const modelHasPredictionsInTheSelectedTimeRange = ModelLevelPredictionsCountForSelectedTimeRange > 0
  const hasObservationsInTheSelectedTimeRange = observationsCount > 0
  const hasFeedbackInTheSelectedTimeRange = feedbacksCount > 0

  const result: MetricsState = { showGraphs: false }

  // If the project does not have predictions since the date of creation
  if (!projectHasPredictions) {
    result.title = "No predictions sent in this project ever"
    result.description = "Send your first prediction"
    result.action = "Send predictions"
  }
  // There are predictions in the project, but not in the selected time range
  else if (!modelHasPredictionsInTheSelectedTimeRange && !hasObservationsInTheSelectedTimeRange) {
    result.title = "No predictions sent in this date range"
    result.description = "Choose another date range"
  }
  // If the project does not have feedback since the date of creation
  else if (!projectHasFeedback) {
    result.title = "Feedback is required to calculate this field"
    result.description = "Send your first feedback"
    result.action = "Send feedback"
  } else if (
    // If there are predictions in the project in the selected time range + There are no observations count + There are no feedbacks count
    (modelHasPredictionsInTheSelectedTimeRange &&
      !hasObservationsInTheSelectedTimeRange &&
      !hasFeedbackInTheSelectedTimeRange) ||
    // OR
    // If there are predictions in the project in the selected time range + There are observations count + There are no feedbacks count
    (modelHasPredictionsInTheSelectedTimeRange &&
      hasObservationsInTheSelectedTimeRange &&
      !hasFeedbackInTheSelectedTimeRange)
  ) {
    result.title = "Feedback is required to calculate this field"
    result.description = "Make sure to send feedback"
    result.action = "Send feedback"
  }
  // If there are predictions in the project in the selected time range + There are no observations count + There are feedbacks count
  else if (
    modelHasPredictionsInTheSelectedTimeRange &&
    !hasObservationsInTheSelectedTimeRange &&
    hasFeedbackInTheSelectedTimeRange
  ) {
    result.title = "No valid observations made in this date range"
    result.description = "Choose another date range"
  }
  // If there are predictions in the project in the selected time range + There are observations count + There are feedbacks count
  // -> Show graphs
  else if (
    modelHasPredictionsInTheSelectedTimeRange &&
    hasObservationsInTheSelectedTimeRange &&
    hasFeedbackInTheSelectedTimeRange
  ) {
    result.showGraphs = true
  }
  return result
}

// Function that returns the empty state of the coverage graph
export function getModelPredictionsCoverageState(
  projectLevelPredictionsCount: number,
  ModelLevelPredictionsCountForSelectedTimeRange: number,
  observationsCount: number,
): MetricsState {
  const projectHasPredictions = projectLevelPredictionsCount > 0
  const modelHasPredictionsInTheSelectedTimeRange = ModelLevelPredictionsCountForSelectedTimeRange > 0
  const hasObservationsInTheSelectedTimeRange = observationsCount > 0

  const result: MetricsState = { showGraphs: false }

  // If the project does not have predictions since the date of creation
  if (!projectHasPredictions) {
    result.title = "No predictions sent in this project ever"
    result.description = "Send your first prediction"
    result.action = "Send predictions"
  }
  // There are predictions in the project, but not in the selected time range
  else if (!modelHasPredictionsInTheSelectedTimeRange && !hasObservationsInTheSelectedTimeRange) {
    result.title = "No predictions sent in this date range"
    result.description = "Choose another date range"
  }
  // If there are predictions in the project in the selected time range AND there are no observations count
  else if (modelHasPredictionsInTheSelectedTimeRange && !hasObservationsInTheSelectedTimeRange) {
    result.title = "No valid observations made in this date range"
    result.description = "Choose another date range"
  }
  // If there are predictions in the project in the selected time range AND there are observations count -> Show graphs
  else if (modelHasPredictionsInTheSelectedTimeRange && hasObservationsInTheSelectedTimeRange) {
    result.showGraphs = true
  }
  return result
}

/**
 * Function that fetches the values of a json object recursively
 * the function gets all the values on all levels instead of the shallow Jsonify() function in JS
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function deepJsonify(obj: any): string[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any[] = []
  Object.values(obj).forEach((value) => {
    if (typeof value !== "object" || value === null) {
      // simple value detected -> push it to the result array
      result.push(value)
    } else {
      // nested object detected -> loop through it recursively
      const tempResult = deepJsonify(value)
      result.push(...tempResult)
    }
  })

  return result
}

/**
 * Function that un-nests a nested json and return a single level json
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function keyValueDeepJsonify(obj: any, exclusionList?: string[]): string[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function keyValueDeepJsonifyHelper(obj: any, parentKey?: string): void {
    if (![null, undefined].includes(obj))
      Object.entries(obj).forEach(([key, value]) => {
        // Workaround till backend returns a list of table headers to filter on
        if (exclusionList?.includes(key)) return

        // if value is an array only return the parent key to render it in a popper instead of spreading columns
        if (Array.isArray(value)) {
          result[key] = value
          return
        }

        if (typeof value !== "object" || value === null) {
          // simple value detected -> push it to the result array
          const deepKey = parentKey ? `${parentKey}.${key}` : key

          // if value is null dont add it to the return to avoid screen blackout
          if (value !== null) {
            result[deepKey] = value
          }
        } else {
          // nested object detected -> loop through it recursively
          const deepKey = parentKey ? `${parentKey}.${key}` : key
          keyValueDeepJsonifyHelper(value, deepKey)
        }
      })
  }

  keyValueDeepJsonifyHelper(obj)

  return result
}

/**
 * Function that changes the json object (values) passed through to an array of arrays format
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function convertResponseToTargets(response: any): string[][] {
  const result: string[][] = []
  if (response !== undefined && response !== null) {
    Object.keys(response).forEach((key) => {
      if (typeof response[key] === "object") {
        // nested object detected -> loop through it recursively
        convertResponseToTargetsHelper(response[key], result, [key])
      } else {
        // simple key detected -> push it to the result array
        result.push([key])
      }
    })
  }

  return result
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertResponseToTargetsHelper = (nestedObject: any, result: any, parent: any[]): void => {
  Object.keys(nestedObject).forEach((key) => {
    if (typeof nestedObject[key] === "object" && nestedObject[key] !== null) {
      //nested object detected -> loop through it recursively
      const temp = Array.from(parent)
      convertResponseToTargetsHelper(nestedObject[key], result, [...temp, key])
    } else {
      //simple key detected -> push it to the result array
      const temp = Array.from(parent)
      result.push([...temp, key])
    }
  })
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types  */
export const extractPredictionKeysFromOpenapi = (openapiDoc: any): Record<string, unknown> => {
  const valueBasedOnType = (
    type: "boolean" | "number" | "string" | "integer",
  ): boolean | number | string | Record<string, unknown> => {
    // default value switcher function to help decide the default value based on type
    switch (type) {
      case "integer":
      case "number": {
        return 0
      }
      case "string":
        return "string"
      case "boolean":
        return false
      default:
        return {}
    }
  }

  // Follows the api schema recursively to get the prediction request body path and extract it's properties
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getPredictionRequestProperties = (apidocSchema: any): any => {
    if (!apidocSchema.$ref) {
      return apidocSchema
    }

    return getPredictionRequestProperties(openapiDoc?.components?.schemas?.[apidocSchema.$ref.split("/").reverse()[0]])
  }

  // checking if there's a predictions request from response or not
  if (openapiDoc) {
    const predictionsObject =
      getPredictionRequestProperties(openapiDoc.components?.schemas?.PredictionDocsRequest).properties ?? {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const extractedObject: any = {}
    //check if property has a direct type or a $ref value
    for (const key in predictionsObject) {
      if (predictionsObject[key]?.hasOwnProperty("$ref")) {
        //extracting the actual property name to access it
        const extractValue = predictionsObject[key]?.$ref.substring(predictionsObject[key]?.$ref?.lastIndexOf("/") + 1)
        const correctValue = openapiDoc?.components?.schemas?.[extractValue]
        /**
         * accessing the correct property name and extracting the first value from the array
         * to assign it to our returned object
         */
        extractedObject[key] = correctValue?.enum[0]
      } else {
        // Reading the value type and using our helper function to assign a default value for it
        extractedObject[key] = predictionsObject[key]?.minimum
          ? predictionsObject[key]?.minimum
          : valueBasedOnType(predictionsObject[key]?.type)
      }
    }
    return extractedObject
  }
  // returning empty object as there're no predictions for this deployment
  else {
    return {}
  }
}

export function getModelByUUID(uuid: string | undefined, models: Model[] | undefined): Model | null {
  if (!models || !uuid) return null
  return models?.find((model) => model.uuid === uuid) || null
}
