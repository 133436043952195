import React, { useMemo } from "react"

import { DialogTitle } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Typography } from "@synapse-analytics/synapse-ui"

import { ModelCreationDialog } from "../../../../../../../features/projects/components/ModelCreationDialog"
import { Model } from "../../../../../../../types/generated/api/Model"
import { ModelListCreate } from "../../../../../../../types/generated/api/ModelListCreate"
import { filterListByState } from "../../../../../../../utils/searchSortFilterHelpers"

import styles from "../Models.module.scss"

type Props = {
  open: boolean
  onClose: () => void
  projectId: string
  models: Model[] | undefined
  isProjectCard?: boolean
}

/**
 * New Model Dialog
 * @param {boolean} open
 * @param {function} onClose
 * @param {string} projectId
 * @param {Model[] | undefined} models used to check if this is the first model in the project or not
 * @param {boolean} isProjectCard check if the dialog is opening from the overview screen or the project card
 * @return {React.ReactElement}
 */
export function NewModelDialog(props: Props): React.ReactElement {
  const { open, onClose, projectId, models, isProjectCard } = props

  const theme = useTheme()

  //function that returns adjusted models based on search, sort, and filter values
  const adjustedModels = useMemo((): ModelListCreate[] => {
    return models ? filterListByState(models as ModelListCreate[], "live", "models") : []
  }, [models])

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={useMediaQuery(theme.breakpoints.down("md"))}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="dialog-header-base">
          <Typography variant="h3-bold">New Model</Typography>
        </DialogTitle>

        <DialogContent className={`dialog-content-base ${styles.dialog}`}>
          <ModelCreationDialog
            projectUUID={projectId}
            onClose={onClose}
            isFirstModel={adjustedModels.length === 0}
            isProjectCard={isProjectCard}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
