import React from "react"

import "react-datepicker/dist/react-datepicker.css"
import { createRoot } from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { Provider } from "react-redux"

import * as Sentry from "@sentry/react"
import { StyledEngineProvider } from "@mui/material"
import { Integrations } from "@sentry/tracing"

import * as serviceWorker from "./serviceWorker"
import packageJson from "../package.json"
import { App } from "./screens/App"
import { store } from "./store/ReduxStore"
import { getHostnames } from "./utils/genericHelpers"
import "./utils/i18n"

import "./index.scss"

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnReconnect: false, refetchOnWindowFocus: false } },
})

if (getHostnames().backend === "api.konan.ai") {
  Sentry.init({
    dsn: "https://ead656439a944fc6b07dcb8bd12684a1@o1005988.ingest.sentry.io/5966451",
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    environment: "production",
    release: packageJson.version,
    // Was 1.0 but lowered to 0.75 in production
    tracesSampleRate: 0.75,
    //capture replay for 100% of sessions with errors
    replaysOnErrorSampleRate: 1.0,
  })
}

if (getHostnames().backend === "api.staging.konan.ai") {
  Sentry.init({
    dsn: "https://ead656439a944fc6b07dcb8bd12684a1@o1005988.ingest.sentry.io/5966451",
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    environment: "staging",
    release: packageJson.version,
    // Was 1.0 but lowered to 0.75 in production
    tracesSampleRate: 0.5,
    //capture replay for 100% of sessions with errors
    replaysOnErrorSampleRate: 1.0,
  })
}

// TODO:: fix later
// Start the mocking service only when in development mode and the VITE_ENABLE_MOCK_SERVICE env var is set to true
// if (import.meta.env.NODE_ENV === "development" && import.meta.env.VITE_ENABLE_MOCK_SERVICE === "true") {
//   const { worker } = await import("./mocks/browser")
//   worker.start()
// }

const root = createRoot(document.getElementById("root") as HTMLElement)
// Strict mode is the reason why components re-render twice in development
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <App />
        </Provider>
      </StyledEngineProvider>
      {import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />}
    </QueryClientProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
