import React, { useContext, useState } from "react"

import { useQuery } from "react-query"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { Card, CardContent, CardHeader, Grid, Pagination, Skeleton } from "@mui/material"
import { Button } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { KonanEmptyState } from "../../../../../../components/KonanEmptyState"
import { KonanPageHeader } from "../../../../../../components/KonanPageHeader"
import { KonanAPI } from "../../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../../store/CurrentProjectAndModelContext"
import { PaginatedScoreCardSetListList } from "../../../../../../types/generated/api/PaginatedScoreCardSetListList"
import { SingleScorecardset } from "./components/SingleScorecardset"

import styles from "./ScorecardSet.module.scss"

/**
 * Main view for scorecardSet screen
 * @returns {React.ReactElement}
 */
export function ScorecardSet(): React.ReactElement {
  const [showScorecardSet, setShowScorecardSet] = useState<boolean>(false)

  // Pagination
  const pageSize = 3
  const [page, setPage] = useState<number>(0)

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  // fetch scorecardsets
  const { isLoading: isScorecardsetsLoading, data: scorecardsets } = useQuery<
    AxiosResponse<PaginatedScoreCardSetListList>,
    AxiosError
  >(
    ["scorecardsets", currentProject?.uuid, page, pageSize],
    () => KonanAPI.fetchScorecardsets(currentProject?.uuid as string, page + 1, pageSize),
    {
      enabled: !!currentProject?.uuid,
    },
  )

  // fetch scorecards
  const { data: scorecardsData, isLoading: isScorecardsLoading } = useQuery<AxiosResponse, AxiosError>(
    ["scorecards", currentProject?.uuid],
    () => KonanAPI.fetchScorecards(currentProject?.uuid as string),
    {
      enabled: !!currentProject?.uuid,
    },
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} textAlign={"right"}>
        <KonanPageHeader
          title="Scorecards"
          actions={[
            <Button
              variant="primary"
              disabled={showScorecardSet || isScorecardsetsLoading || isScorecardsLoading}
              startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
              onClick={() => setShowScorecardSet(true)}
              size="regular"
            >
              Create Scorecard
            </Button>,
          ]}
        />
      </Grid>

      {showScorecardSet && (
        <Grid item xs={12}>
          <SingleScorecardset
            isLoading={isScorecardsetsLoading}
            createScorecardsetMode={true}
            handleCancel={() => setShowScorecardSet(false)}
            allScorecards={scorecardsData?.data?.results}
            goToFirstPage={() => setPage(0)}
          />
        </Grid>
      )}

      {/* Scorecardset loading state */}
      {isScorecardsetsLoading || !scorecardsets
        ? [1, 2].map((item: number) => (
            <Grid item xs={12} key={item}>
              <Card className="card-box-shadow">
                <CardHeader className={styles.scorecardSetHeader} title={<Skeleton width={200} />} />
                <CardContent className={styles.cardContent}>
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                </CardContent>
              </Card>
            </Grid>
          ))
        : scorecardsets?.data?.results?.length && scorecardsets?.data?.results?.length > 0
          ? scorecardsets?.data.results.map((scorecardset) => (
              <Grid item xs={12} key={scorecardset.uuid}>
                <SingleScorecardset
                  handleCancel={() => setShowScorecardSet(false)}
                  createScorecardsetMode={false}
                  scorecardset={scorecardset}
                  isLoading={isScorecardsetsLoading}
                  allScorecards={scorecardsData?.data?.results}
                  goToFirstPage={() => setPage(0)}
                />
              </Grid>
            ))
          : !showScorecardSet &&
            !isScorecardsetsLoading && (
              <Grid item xs={12} className={"empty-container"}>
                <KonanEmptyState
                  title="No Scorecards"
                  subTitle="Assign weighted numerical scores to applicants based on their features. To be effective, you integrate it into your workflow"
                  buttonText="Create Scorecard"
                  setAction={setShowScorecardSet}
                />
              </Grid>
            )}

      {/* Pagination Section */}
      {!isScorecardsetsLoading && scorecardsets?.data.results && scorecardsets?.data.results.length > 0 && (
        <Grid container item justifyContent="flex-start">
          <Pagination
            count={Math.ceil((scorecardsets.data.count as number) / pageSize)}
            page={page + 1}
            onChange={(_, value: number) => {
              setPage(value - 1)
              window.scrollTo(0, 0)
            }}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  )
}
