import React, { Fragment, useState } from "react"

import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { KonanAPI } from "../../services/KonanAPI"
import { ScriptGroupList } from "../../types/generated/api/ScriptGroupList"
import { BuiltinScriptDialog } from "./BuiltinScriptDialog"
import { ScriptCard } from "./ScriptCard"
import { ScriptDialog } from "./ScriptDialog"

/**
 * @param {Script} ScriptGroupList
 * @return {ReactElement}
 */
export function Container({ script }: Readonly<{ script: ScriptGroupList }>): React.ReactElement {
  const { name, uuid, template } = script

  const queryClient = useQueryClient()
  const { id: projectId } = useParams()

  const [isBuiltinScriptDialogOpen, setIsBuiltinScriptDialogOpen] = useState<{
    isOpen: boolean
    isEditMode: boolean
    tab: "Logs" | undefined
  }>({
    isOpen: false,
    isEditMode: false,
    tab: undefined,
  })

  const [isDeleteScriptDialogOpen, setIsDeleteScriptDialogOpen] = useState<boolean>(false)
  const [isScriptDialogOpen, setIsScriptDialogOpen] = useState<{
    isOpen: boolean
    isEditMode: boolean
    tab: "Logs" | undefined
  }>({
    isOpen: false,
    isEditMode: false,
    tab: undefined,
  })

  const DeleteScriptMutation = useMutation<
    AxiosResponse,
    AxiosError,
    {
      project_uuid: string
      script_uuid: string
    }
  >(KonanAPI.DeleteScript, {
    onSuccess: async () => {
      NotificationUtils.toast("Script deleted successfully", { snackBarVariant: "positive" })

      await queryClient.invalidateQueries(["scripts", projectId])
    },
    onError: async () => {
      NotificationUtils.toast("script deletion failed", { snackBarVariant: "negative" })
    },
  })

  return (
    <Fragment>
      {isScriptDialogOpen.isOpen && (
        <ScriptDialog
          isOpen
          onClose={() => setIsScriptDialogOpen({ isOpen: false, isEditMode: false, tab: undefined })}
          scriptId={uuid}
          isNewScriptTriggered={false}
          renderView="scripts"
          openInEditMode={isScriptDialogOpen.isEditMode}
          tab={isScriptDialogOpen.tab}
        />
      )}

      {isBuiltinScriptDialogOpen.isOpen && (
        <BuiltinScriptDialog
          isOpen
          onClose={() => setIsBuiltinScriptDialogOpen({ isOpen: false, isEditMode: false, tab: undefined })}
          scriptInfo={{ name: name, uuid: uuid }}
          isNewScriptTriggered={false}
          renderView="scripts"
          openInEditMode={isBuiltinScriptDialogOpen.isEditMode}
          tab={isBuiltinScriptDialogOpen.tab}
        />
      )}

      {isDeleteScriptDialogOpen && (
        <BaseSimpleDialog
          isLoading={DeleteScriptMutation.isLoading}
          onAccept={async () =>
            await DeleteScriptMutation.mutateAsync({
              project_uuid: projectId as string,
              script_uuid: uuid,
            })
          }
          mode="script-deletion"
          name={name}
          open
          onClose={() => setIsDeleteScriptDialogOpen(false)}
        />
      )}

      <ScriptCard
        MenuOptions={[
          {
            title: "Edit",
            variant: "neutral",
            onClick: () => {
              template
                ? setIsBuiltinScriptDialogOpen({ isOpen: true, isEditMode: true, tab: undefined })
                : setIsScriptDialogOpen({ isOpen: true, isEditMode: true, tab: undefined })
            },
          },
          {
            title: "Delete",
            variant: "negative",
            onClick: () => setIsDeleteScriptDialogOpen(true),
          },
        ]}
        onNameClick={() => {
          template
            ? setIsBuiltinScriptDialogOpen({ isOpen: true, isEditMode: false, tab: undefined })
            : setIsScriptDialogOpen({ isOpen: true, isEditMode: false, tab: undefined })
        }}
        onLogsDialogClick={() => {
          template
            ? setIsBuiltinScriptDialogOpen({ isOpen: true, isEditMode: false, tab: "Logs" })
            : setIsScriptDialogOpen({ isOpen: true, isEditMode: false, tab: "Logs" })
        }}
        script={script}
      />
    </Fragment>
  )
}
