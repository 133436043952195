import React, { Dispatch, SetStateAction } from "react"

import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { VersioningComponents } from "../../types/custom/projects"
import { VersionHistory } from "../UI/VersionHistory"
import { SelectionLoaderCard } from "../cards/SelectionCard"

type Props = {
  children: React.ReactElement
  isOpen: boolean
  versions: Array<VersioningComponents> | undefined
  selectedVersion: string | undefined
  activeVersion: VersioningComponents
  onClose: () => void
  setSelectedVersion: Dispatch<SetStateAction<string | undefined>>
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
  height?: string
  isSideBarDataLoading?: boolean
}

/**
 * Versioning dialog component
 * @param {React.ReactElement} children right-side display of the dialog
 * @param {boolean} isOpen is dialog open
 * @param {RuleSetVersionRetrieve[] | undefined} versions versions list
 * @param {number | undefined} selectedVersion
 * @param {Function} onClose
 * @param {Function} setSelectedVersion
 * @return {React.ReactElement}
 */
export function VersioningDialog(props: Readonly<Props>): React.ReactElement {
  const {
    children,
    setSelectedVersion,
    selectedVersion,
    onClose,
    isOpen,
    versions,
    activeVersion,
    maxWidth = "xl",
    height,
    isSideBarDataLoading,
  } = props

  const theme = getTheme()
  const MuiTheme = useMuiTheme()

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={maxWidth}
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("md"))}
      onClose={() => {
        setTimeout(() => {
          setSelectedVersion(undefined)
          onClose()
        }, 100)
      }}
    >
      <DialogTitle className="dialog-header-base" style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2-bold">Version History</Typography>
        <IconButton
          onClick={() => {
            setSelectedVersion(undefined)
            onClose()
          }}
          size="small"
          className={"close-icon-button"}
        >
          <CloseIcon style={{ color: theme.palette.grayscale.text[2] }} />
        </IconButton>
      </DialogTitle>

      <DialogContent className="dialog-content-versioning">
        <Box className="versioning-dialog" style={{ height: height }}>
          <Box width={1} height={1} className={"ruleset-version-container"}>
            {children}
          </Box>

          <div
            style={{ height: isSideBarDataLoading ? "fit-content" : "100%" }}
            className={"versioning-dialog-sidebar"}
          >
            {isSideBarDataLoading ? (
              <Grid container item xs={12} gap={1.5} px={1} pb={2}>
                {[1, 2, 3].map((item) => {
                  return (
                    <Grid item xs={12} key={item} mt={2}>
                      <SelectionLoaderCard />
                    </Grid>
                  )
                })}
              </Grid>
            ) : (
              <VersionHistory
                versions={versions ?? []}
                // TODO: remove the string quotes when all endpoints return version as strings
                selectedVersion={`${selectedVersion}` ?? `${activeVersion?.version}`}
                setSelectedVersion={setSelectedVersion}
                isLoading={Boolean(isSideBarDataLoading)}
                activeVersion={activeVersion}
              />
            )}
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
