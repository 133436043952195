import React, { ChangeEvent, useState } from "react"

import { useQuery } from "react-query"
import { useSelector } from "react-redux"

import * as Yup from "yup"
import { CircularProgress, Dialog, DialogActions, DialogContent, Grid, useMediaQuery, useTheme } from "@mui/material"
import { Button, Checkbox, InputText, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"
import { MRT_ColumnDef } from "material-react-table"

import { InfoBlock } from "../../components/containers/InfoBlock"
import { BaseTable } from "../../components/tables/BaseTable"
import { KonanAPI } from "../../services/KonanAPI"
import { RootState } from "../../store/ReduxStore"
import { Deployment } from "../../types/generated/api/Deployment"
import { KonanResource } from "../../types/generated/authentication/KonanResource"
import { TypeEnum } from "../../types/generated/authentication/TypeEnum"
import { isPermitted } from "../../utils/PermissionsHelpers"
import { Auth } from "../../utils/auth"
import { GroupDialogProps } from "./Interfaces"

// Yup validation schema object
const groupValidationSchema = Yup.object({
  groupName: Yup.string().required("Role name is required"),
  selectedProjects: Yup.array()
    .required()
    .test({
      test: (arr) => (arr as KonanResource[]).length > 0,
    }),
})

export function GroupCreationDialog(props: Readonly<GroupDialogProps>): React.ReactElement {
  const { isOpen, onClose, onAccept, group, isViewMode } = props

  const MuiTheme = useTheme()

  const permissions = Auth.getPermissions()

  const flattenedSidPermissions = useSelector((state: RootState) => state.permissions.flattenedSidPermissions)

  const [isReadMode, setIsReadMode] = useState<boolean>(isViewMode ?? !!group ?? false)

  // Fetch projects
  const { isLoading: isProjectsLoading, data: projects } = useQuery<AxiosResponse<Array<Deployment>>, AxiosError>(
    ["projects"],
    () => KonanAPI.fetchProjects(),
    { enabled: !!isOpen },
  )

  const formik = useFormik({
    initialValues: {
      groupName: group?.name ?? "",
      selectedProjects: (group?.konan_resources ?? []) as KonanResource[],
    },
    validationSchema: groupValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      await onAccept?.(values.groupName, values.selectedProjects)

      resetForm({})
      onClose()
    },
  })

  const columns: MRT_ColumnDef<Deployment>[] = [
    {
      header: "Project",
      Cell: ({ row }) => (
        <Checkbox
          key={row.original.name}
          id={row.original.name}
          label={row.original.name}
          name={row.original.name}
          value={row.original.uuid}
          checked={!!formik.values.selectedProjects.find((project: KonanResource) => project.uid === row.original.uuid)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (!isReadMode)
              if (e.target?.checked === true) {
                const selectedProject = projects?.data.find((project: Deployment) => project.uuid === row.original.uuid)

                formik.setFieldValue("selectedProjects", [
                  ...formik.values.selectedProjects,
                  { uid: selectedProject?.uuid, type: TypeEnum.PROJECT },
                ])
              } else {
                formik.setFieldValue(
                  "selectedProjects",
                  formik.values.selectedProjects.filter((item: KonanResource) => item.uid !== row.original.uuid),
                )
              }
          }}
        />
      ),
    },
  ]

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        formik.resetForm()
        onClose()
      }}
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("sm"))}
      maxWidth="sm"
    >
      <DialogContent className="dialog-content-base">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={"h1-bold"}>{group ? (isReadMode ? "View" : "Edit") : "New"} Group</Typography>
          </Grid>

          <Grid item xs={12}>
            {isReadMode ? (
              <InfoBlock text={formik.values.groupName} title={`Group name`} />
            ) : (
              <InputText
                label={`Group name`}
                id="groupName"
                value={formik.values.groupName}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                placeholder={`Konan Group`}
                description="Must be unique"
                fullWidth
                required
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography variant={"h3-bold"}>Choose Access</Typography>
            </Grid>

            <BaseTable
              title={"Choose Projects"}
              data={projects?.data ?? []}
              isLoading={isProjectsLoading}
              columns={columns}
              enableToolbarInternalActions={false}
              enableTableHead={false}
              enableOrdering={false}
              stripped
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className="dialog-actions-base">
        <Button
          variant="secondary"
          onClick={() => {
            formik.resetForm()
            onClose()
          }}
        >
          Cancel
        </Button>
        {isReadMode && isPermitted("Update groups", permissions.synapse_id, flattenedSidPermissions) ? (
          <Button onClick={() => setIsReadMode(false)} variant="primary">
            Edit
          </Button>
        ) : (
          !isReadMode &&
          (isPermitted("Update groups", permissions.synapse_id, flattenedSidPermissions) ||
            isPermitted("Create groups", permissions.synapse_id, flattenedSidPermissions)) && (
            <Button
              onClick={formik.submitForm}
              disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
              variant="primary"
            >
              {!formik.isSubmitting ? "Save" : <CircularProgress size={20} color="inherit" />}
            </Button>
          )
        )}
      </DialogActions>
    </Dialog>
  )
}
