import React, { useEffect, useRef, useState } from "react"

import { Grid } from "@mui/material"
import { Tag, Typography } from "@synapse-analytics/synapse-ui"
import { Cell, Label, Legend, Pie, PieChart, PieProps, ResponsiveContainer, Tooltip } from "recharts"

import { customRechartsColorScheme } from "../../utils/genericHelpers"
import { ChartsContainer } from "./ChartsContainer"
import { GraphLabelText } from "./GraphLabelText"

import styles from "./PieChart.module.scss"

/**
 * Recharts custom legend component
 * @return {React.ReactElement}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CustomLegend(props: any): React.ReactElement {
  return (
    <ul className={styles.legend}>
      {props.payload.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any, index: number) => (
          <li key={item.value}>
            <Tag variant={customRechartsColorScheme()[index].text} size="small">
              {item.value} {item.payload.percent}%
            </Tag>
          </li>
        ),
      )}
    </ul>
  )
}

interface PieChartProps extends Omit<PieProps, "dataKey"> {
  title: string
  tooltipText?: string
  dataKey?: string
  width?: number
  graphHeight?: number
  range?: number
  isLoading?: boolean
  data: { name: string; value: number; percent: number }[]
  label: { name: string; percent: number; value: number }
  selectProps?: {
    options: string[]
    value: string
    setValue: (val: string) => void
  }
  headerMode?: "full" | "select" | "none"
}

/**
 * Recharts semi-circle doughnut chart
 * @return {React.ReactElement}
 */
export function BasePieChart(props: Readonly<PieChartProps>): React.ReactElement {
  const {
    dataKey = "value",
    graphHeight = 400,
    title,
    range,
    tooltipText,
    isLoading = false,
    data,
    label,
    selectProps,
    headerMode,
  } = props

  const containerRef = useRef()

  const [containerWidth, setContainerWidth] = useState<number>(
    containerRef.current?.current.clientWidth * (3 / 4) || 200,
  )

  // dynamic width calculation chart to take a fourth of the container
  // TODO:: add an edge case for when a fourth isn't enough, maybe take the total width minus the width of the chart?
  useEffect(() => {
    setContainerWidth(containerRef.current?.current.clientWidth * (3 / 4))
  }, [containerRef.current?.current.clientWidth])

  return (
    <ChartsContainer
      title={title}
      tooltipText={tooltipText}
      range={range}
      isLoading={isLoading}
      graphHeight={graphHeight}
      selectProps={selectProps}
      headerMode={headerMode}
    >
      <ResponsiveContainer ref={containerRef}>
        <PieChart>
          <Pie
            data={data}
            dataKey={dataKey}
            cx={containerWidth}
            cy={80}
            endAngle={0}
            startAngle={180}
            innerRadius={45}
            outerRadius={60}
            paddingAngle={props.paddingAngle}
            stroke="none"
          >
            {data.map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={customRechartsColorScheme()[index].background} />
            ))}
            <Label
              x={300}
              y={25}
              position="outside"
              content={({ y }) => (
                <foreignObject
                  // TODO:: numbers should be refactored or assigned to a const if they get reused
                  x={20}
                  y={y + 10}
                  style={{
                    overflow: "visible",
                  }}
                >
                  <GraphLabelText label={label} />
                </foreignObject>
              )}
            />
          </Pie>
          <Legend content={CustomLegend} />

          <Tooltip
            content={({ payload }) =>
              payload?.length && (
                <div className={"chart-tooltip"} style={{ width: "fit-content" }}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Grid item>
                      <span
                        key="chip"
                        style={{
                          display: "block",
                          width: "12px",
                          height: "12px",
                          background: payload?.[0].payload.fill,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h3-bold" display="block" variantColor={2}>
                        {payload?.[0].payload.name}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography variant="p" variantColor={2}>
                    Count: {payload?.[0].payload.value}
                  </Typography>
                  <Typography variant="p" variantColor={2}>
                    Percentage: {payload?.[0].payload.payload.percent + "%"}
                  </Typography>
                </div>
              )
            }
          />
        </PieChart>
      </ResponsiveContainer>
    </ChartsContainer>
  )
}
