import React, { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from "react"

import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Button as MuiButton } from "@mui/material"
import {
  InputChangeEvent,
  InputText,
  Menu,
  MenuItem,
  NotificationUtils,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FieldArray, FormikProps, FormikProvider } from "formik"
import { v4 as uuidv4 } from "uuid"

import { queryClient } from "../../../../../../../.."
import { BaseSimpleDialog } from "../../../../../../../../components/dialogs/BaseSimpleDialog"
import { getTheme } from "../../../../../../../../hooks/UseTheme"
import { useDebounce } from "../../../../../../../../hooks/useDebounce"
import { KonanAPI } from "../../../../../../../../services/KonanAPI"
import { Operators } from "../../../../../../../../types/custom/projects"
import { Rule, RuleCardFormikValues, RuleGroupConditions } from "../../../../../../../../types/custom/rules"
import { ConditionListFile } from "../../../../../../../../types/generated/api/ConditionListFile"
import { Label } from "../../../../../../../../types/generated/api/Label"
import { PaginatedLabelList } from "../../../../../../../../types/generated/api/PaginatedLabelList"
import { WorkflowSchemaFeature } from "../../../../../../../../types/generated/api/WorkflowSchemaFeature"
import { Condition } from "./Condition"

import styles from "./RuleCard.module.scss"

interface DataBlockProps {
  value: string | React.ReactElement
  isLoading?: boolean
  textVariant?: "h3-bold" | "a" | "p"
  textColor?: string
  variantColor?: 1 | 2
  bgVariant?: "neutral" | "gray"
}

/**
 * Helper UI component to render data items in ruleset card
 * @param {string} value
 * @return {React.ReactElement}
 */
export function DataBlock(props: DataBlockProps): React.ReactElement {
  const {
    value,
    isLoading,
    textVariant = "h3-bold",
    bgVariant = "gray",
    variantColor = 1,
    textColor = "neutral",
  } = props

  return (
    <div className={bgVariant === "gray" ? styles.cardFieldLight : styles.cardFieldDark}>
      {typeof value === "string" ? (
        <Typography variant={textVariant} color={textColor} variantColor={variantColor}>
          {isLoading ? <Skeleton variant="rectangular" width={"100%"} height={20} /> : value}
        </Typography>
      ) : (
        value
      )}
    </div>
  )
}

type Props = {
  editMode?: boolean
  createMode?: boolean
  name: string
  condition?: Rule[][]
  returnLabel: string
  conditionListFile: ConditionListFile | null
  rulesetFormik: FormikProps<RuleCardFormikValues>
  isLoading?: boolean
  index: number
  group1Switch: string
  group2Switch: string
  setGroup1Switch: Dispatch<SetStateAction<string>>
  setGroup2Switch: Dispatch<SetStateAction<string>>
  andOr: ["and", "or"]
  dialogView?: boolean
  handleDelete?: (ruleName: string, stateFn: Dispatch<SetStateAction<boolean>>) => void
  handleRemoveRule?: (index: number) => boolean
}

/**
 * Rule card component
 * @return {React.ReactElement}
 */
export function RuleCard(props: Props): React.ReactElement {
  const {
    editMode = false,
    createMode = false,
    name,
    condition,
    returnLabel,
    handleDelete,
    handleRemoveRule,
    conditionListFile,
    group1Switch,
    group2Switch,
    setGroup1Switch,
    setGroup2Switch,
    isLoading = false,
    index,
    andOr,
    rulesetFormik: formik,
    dialogView,
  } = props
  const { id: projectId } = useParams<{ id: string }>()

  const theme = getTheme()

  const [openRuleDeletionDialog, setOpenRuleDeletionDialog] = useState<boolean>(false)

  const uniqueId = uuidv4()

  const emptyCondition: Rule = {
    id: uniqueId,
    feature: "",
    andOr: "and",
    operator: Operators["="],
    valueOrFeature: "Value",
    type: "string",
    value: "",
    secondValue: "",
    schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
  }

  // specific object to view rule card in version history (read-only mode)
  const singleRuleCard = {
    ruleName: createMode ? "" : name,
    levelOneConditions: condition && condition.length > 0 ? condition[0] : [emptyCondition],
    levelTwoConditions: condition && condition.length > 1 ? condition[1] : [],
    levelThreeConditions: condition && condition.length > 2 ? condition[2] : [],
    label: returnLabel,
    label_id: "",
    condition_list_file: { name: "", uuid: "" },
  }

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null)
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null)
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null)
  const openMenu1 = Boolean(anchorEl1)
  const openMenu2 = Boolean(anchorEl2)
  const openMenu3 = Boolean(anchorEl3)

  // this state tracks the number of uploaded/referenced files in a rule
  const [filesCountInRule, setFilesCountInRule] = useState<number>(0)

  const [fileData, setFileData] = useState<{ name: string; uuid: string }>({ name: "", uuid: "" })

  const openRuleCardMenu = Boolean(anchor)

  const pageSize = 20
  const [page, setPage] = useState<number>(0)
  const [searchKey, setSearchKey] = useState<string | null>(null)

  const [labels, setLabels] = useState<Label[]>([])

  const CreateLabelMutation = useMutation<
    AxiosResponse<Label>,
    AxiosError,
    {
      project_uuid: string
      name: string
    }
  >(KonanAPI.CreateLabel, {
    onSuccess: async ({ data }) => {
      NotificationUtils.toast("Label created successfully", { snackBarVariant: "positive" })

      formik.setFieldValue(`ruleCards[${index}].label`, data.name)
      formik.setFieldValue(`ruleCards[${index}].label_id`, data.uuid)

      await queryClient.invalidateQueries("label-list")
    },
    onError: async () => {
      NotificationUtils.toast("Label creation failed", { snackBarVariant: "negative" })
    },
  })

  // fetch uploaded training data headers (columns)
  const { data: labelList, isLoading: isLabelListLoading } = useQuery<AxiosResponse<PaginatedLabelList>, AxiosError>(
    ["label-list", projectId, page, pageSize, searchKey],
    () =>
      KonanAPI.RetrieveLabels({
        project_uuid: projectId as string,
        page: page + 1,
        pageSize: pageSize,
        search: searchKey,
      }),
    {
      onSuccess: (response) => {
        // filtering out already existing labels in the list
        const tempLabels =
          response.data.results?.filter((item: Label) => !labels.find((label: Label) => label.uuid === item.uuid)) ?? []

        setLabels([...labels, ...tempLabels])

        response.data.results?.length === 1 &&
          !["", null, undefined].includes(searchKey) &&
          // setting name (automatically) ONLY if the user writes the full name correctly
          response.data.results[0].name === searchKey &&
          formik.setFieldValue(`ruleCards[${index}].label_id`, response.data.results[0].uuid)
      },
      enabled: editMode || createMode,
      refetchOnMount: true,
    },
  )

  const optionsWithValues = useMemo(() => {
    return labels.map((label) => {
      return { label: label.name, value: label.uuid }
    })
  }, [labels])

  const updateFileCount = (shoudlRemoveFile: boolean): void => {
    /**
     * this removeCount variable handles the edge case where if you removed the only condition in
     * levelTwoConditions and levelThreeConditions also have conditions, the delete count of removed files should equal
     * the length of -> (levelThreeConditions + 1), otherwise the count will be set to 1
     * */
    const removeCount =
      formik?.values?.ruleCards[index]?.levelTwoConditions?.length === 1 &&
      formik?.values?.ruleCards[index]?.levelThreeConditions?.length > 0
        ? formik?.values?.ruleCards[index]?.levelThreeConditions?.length + 1
        : 1
    if (shoudlRemoveFile) {
      // only remove the condition_list_file from rule, if we removed the file in this conditions
      // and filesCountInRule was equal to only one
      if (filesCountInRule === 1) {
        formik.setFieldValue(`ruleCards[${index}].condition_list_file`, { name: "", uuid: "" })
      }

      setFilesCountInRule((prev) => (prev - removeCount < 0 ? 0 : prev - removeCount))
    }
  }

  const handleRemoveCondition = (
    levelRemove: (idx: number) => void,
    conditionIdx: number,
    conditionLevel: "levelThreeConditions" | "levelTwoConditions",
  ): void => {
    // current level the condition being removed from
    const currentLevelLength = formik?.values?.ruleCards[index]?.[conditionLevel]?.length

    // the migrated level/group -> ex: if removing condition from levelTwo then the migrated level is levelOneConditions
    const levelToBeMigratedTo = conditionLevel === "levelThreeConditions" ? "levelTwoConditions" : "levelOneConditions"

    // the condition index that will be migarted to the prev level
    const conditionIndexToBeMigrated = conditionIdx === 0 ? 1 : 0

    if (currentLevelLength <= 2) {
      // cache the migrated condition in a temp variable
      const tempCondition = formik?.values?.ruleCards[index]?.[conditionLevel][conditionIndexToBeMigrated]

      // remove the whole level
      formik?.setFieldValue(`ruleCards[${index}].${conditionLevel}`, [])

      // push the migrated condition to the prev/migrated level
      formik?.setFieldValue(`ruleCards[${index}].${levelToBeMigratedTo}`, [
        ...formik?.values?.ruleCards[index]?.[levelToBeMigratedTo],
        tempCondition,
      ])

      // edge case, removing levelTwoConditions while levelThreeConditions still has data
      if (
        conditionLevel === "levelTwoConditions" &&
        formik?.values?.ruleCards[index]?.levelThreeConditions?.length > 0
      ) {
        // assign levelTwoConditions data to be equal to levelThreeConditions
        formik?.setFieldValue(`ruleCards[${index}].levelTwoConditions`, [
          ...formik?.values?.ruleCards[index]?.levelThreeConditions,
        ])

        // and remove levelThreeConditions data
        formik?.setFieldValue(`ruleCards[${index}].levelThreeConditions`, [])
      }
    } else {
      levelRemove(conditionIdx)
    }
  }

  // setting the "filesCountInRule" state to the number of "$file" tokens found in the 3 levels of conditions
  useEffect(() => {
    if (editMode) {
      formik.values.ruleCards.forEach((ruleCard) => {
        if (ruleCard.levelOneConditions && ruleCard.levelOneConditions.length > 0) {
          ruleCard.levelOneConditions.forEach((item: Rule) => {
            if (item?.value === "$file") {
              setFilesCountInRule((prev) => prev + 1)
            }
          })
        }

        if (ruleCard.levelTwoConditions && ruleCard.levelTwoConditions.length > 0) {
          ruleCard.levelTwoConditions.forEach((item: Rule) => {
            if (item?.value === "$file") {
              setFilesCountInRule((prev) => prev + 1)
            }
          })
        }

        if (ruleCard.levelThreeConditions && ruleCard.levelThreeConditions.length > 1) {
          ruleCard.levelThreeConditions.forEach((item: Rule) => {
            if (item?.value === "$file") {
              setFilesCountInRule((prev) => prev + 1)
            }
          })
        }
      })
    } else {
      setFilesCountInRule(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])

  const debouncedOnChange = useDebounce((): void => {
    const label = formik.values.ruleCards[index].label

    setPage(0)
    setSearchKey(label === "" ? null : label)
  }, 400)

  return (
    <Card className={styles.card}>
      {openRuleDeletionDialog && (
        <BaseSimpleDialog
          open={openRuleDeletionDialog}
          name={name}
          onClose={() => setOpenRuleDeletionDialog(false)}
          onAccept={() => {
            if (handleDelete) {
              handleDelete(formik?.values?.ruleCards[index]?.ruleName, setOpenRuleDeletionDialog)
            }
          }}
          mode={"rule-deletion"}
          isLoading={isLoading}
        />
      )}

      <CardHeader
        className={styles.cardHeader}
        title={
          <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item xs={6}>
              {editMode || createMode ? (
                <InputText
                  id={`ruleCards[${index}].ruleName`}
                  hideDescription
                  placeholder="Name"
                  value={formik.values.ruleCards[index]?.ruleName}
                  handleChange={formik.handleChange}
                  error={
                    formik?.touched?.ruleCards &&
                    formik?.errors?.ruleCards &&
                    formik?.touched?.ruleCards[index]?.ruleName &&
                    Boolean((formik?.errors?.ruleCards[index] as RuleGroupConditions)?.ruleName) &&
                    (formik?.errors?.ruleCards[index] as RuleGroupConditions)?.ruleName
                  }
                  handleBlur={formik.handleBlur}
                  disabled={formik?.isSubmitting || isLoading}
                  description="Manually configured condition that returns a decision when matched."
                  fullWidth
                />
              ) : (
                <Typography variant="h3-bold" style={{ textTransform: "inherit" }}>
                  {name}
                </Typography>
              )}
            </Grid>
          </Grid>
        }
        action={
          handleDelete &&
          handleRemoveRule && (
            <Fragment>
              <IconButton
                aria-label="settings"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget)}
              >
                <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
              </IconButton>
              <Menu
                key="basic-menu"
                anchorEl={anchor}
                open={openRuleCardMenu}
                onClose={() => setAnchor(null)}
                menuMaxContent
              >
                <Tooltip
                  width={"100%"}
                  title={1 === formik?.values?.ruleCards?.length && "Ruleset should have at least one rule"}
                >
                  <MenuItem
                    onClick={() => {
                      const removeIndicator = handleRemoveRule?.(index)
                      if (removeIndicator) {
                        setOpenRuleDeletionDialog(true)
                      }
                      setAnchor(null)
                    }}
                    disabled={formik?.values?.ruleCards?.length === 1 || isLoading}
                  >
                    <Typography variant="a" color="negative" variantColor={2}>
                      Remove Rule
                    </Typography>
                  </MenuItem>
                </Tooltip>
              </Menu>
            </Fragment>
          )
        }
      />

      <CardContent className={styles.cardContent}>
        {dialogView && condition ? (
          <Grid container justifyContent="flex-end" alignItems="center" className={styles.groupBackground}>
            <Grid container item spacing={1}>
              {singleRuleCard?.levelOneConditions?.map((item: Rule, idx: number) => (
                <Grid item xs={12} key={item?.id}>
                  <Condition
                    key={item?.id}
                    formik={formik}
                    conditionLevel={"levelOneConditions"}
                    id={`levelOneConditions[${idx}]`}
                    index={idx}
                    editMode={false}
                    createMode={false}
                    versionDialogCondition={singleRuleCard?.levelOneConditions[idx]}
                    ruleIndex={index}
                    conditionListFile={conditionListFile as ConditionListFile}
                    groupSwitch={group1Switch}
                    lastSwitchSetter={setGroup1Switch}
                    setFileData={setFileData}
                    fileData={fileData}
                    filesCount={filesCountInRule}
                    setFilesCount={setFilesCountInRule}
                  />
                </Grid>
              ))}
              {/* and/or */}
              {singleRuleCard.levelTwoConditions && singleRuleCard.levelTwoConditions.length > 0 && (
                <Grid item xs={3} md={2} lg={1}>
                  <DataBlock
                    value={
                      singleRuleCard.levelOneConditions.length > 0 ? singleRuleCard.levelOneConditions[0].andOr : ""
                    }
                  />
                </Grid>
              )}

              {singleRuleCard.levelTwoConditions.length > 0 && (
                <Grid item xs>
                  <Grid
                    container
                    item
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    className={styles.groupBackground}
                  >
                    <Grid container item spacing={1}>
                      {singleRuleCard.levelTwoConditions?.map((item: Rule, idx: number) => (
                        <Grid item xs={12}>
                          <Condition
                            key={item?.id}
                            versionDialogCondition={singleRuleCard?.levelTwoConditions[idx]}
                            ruleIndex={index}
                            conditionLevel={"levelTwoConditions"}
                            id={`levelTwoConditions[${idx}]`}
                            conditionListFile={conditionListFile as ConditionListFile}
                            index={idx}
                            editMode={false}
                            createMode={false}
                            formik={formik}
                            groupSwitch={group2Switch}
                            lastSwitchSetter={setGroup2Switch}
                            setFileData={setFileData}
                            fileData={fileData}
                            filesCount={filesCountInRule}
                            setFilesCount={setFilesCountInRule}
                          />
                        </Grid>
                      ))}

                      {/* and/or */}
                      {singleRuleCard.levelThreeConditions && singleRuleCard.levelThreeConditions.length > 0 && (
                        <Grid item xs={3} md={2} lg={1}>
                          <DataBlock
                            value={
                              singleRuleCard.levelTwoConditions && singleRuleCard.levelTwoConditions.length > 0
                                ? singleRuleCard.levelTwoConditions[0].andOr
                                : ""
                            }
                          />
                        </Grid>
                      )}

                      {/* Level 3 group */}
                      {singleRuleCard.levelThreeConditions.length > 0 && (
                        <Grid item xs>
                          <Grid container item className={styles.groupBackground}>
                            <Grid container item justifyContent="flex-end" alignItems="center" spacing={1}>
                              {singleRuleCard?.levelThreeConditions?.map((item: Rule, idx: number) => (
                                <Grid item xs={12}>
                                  <Condition
                                    key={item?.id}
                                    versionDialogCondition={singleRuleCard?.levelThreeConditions[idx]}
                                    ruleIndex={index}
                                    conditionLevel={"levelThreeConditions"}
                                    id={`levelThreeConditions[${idx}]`}
                                    conditionListFile={conditionListFile as ConditionListFile}
                                    index={idx}
                                    editMode={false}
                                    createMode={false}
                                    formik={formik}
                                    setFileData={setFileData}
                                    fileData={fileData}
                                    filesCount={filesCountInRule}
                                    setFilesCount={setFilesCountInRule}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        ) : (
          <FormikProvider value={formik}>
            {/* Level 1 group */}
            <FieldArray name={`ruleCards[${index}].levelOneConditions`}>
              {({ push: level1push, remove: level1remove }) => (
                <Grid container justifyContent="flex-end" alignItems="center" className={styles.groupBackground}>
                  <Grid container item spacing={1}>
                    {formik?.values?.ruleCards[index]?.levelOneConditions?.map((item: Rule, idx: number) => (
                      <Grid item xs={12}>
                        <Condition
                          key={item?.id}
                          conditionLevel={"levelOneConditions"}
                          id={`ruleCards[${index}].levelOneConditions[${idx}]`}
                          index={idx}
                          editMode={editMode}
                          createMode={createMode}
                          ruleIndex={index}
                          conditionListFile={conditionListFile as ConditionListFile}
                          formik={formik}
                          removeCondition={(shoudlRemoveFile: boolean) => {
                            updateFileCount(shoudlRemoveFile)
                            level1remove(idx)
                          }}
                          groupSwitch={group1Switch}
                          lastSwitchSetter={setGroup1Switch}
                          setFileData={setFileData}
                          fileData={fileData}
                          filesCount={filesCountInRule}
                          setFilesCount={setFilesCountInRule}
                        />
                      </Grid>
                    ))}

                    {/* and/or */}
                    {formik.values.ruleCards[index]?.levelTwoConditions &&
                      formik.values.ruleCards[index]?.levelTwoConditions.length > 0 && (
                        <Grid item xs={3} md={2} lg={1}>
                          {editMode && formik.values.ruleCards[index]?.levelOneConditions.length === 1 ? (
                            <Select
                              type="text"
                              value={group1Switch}
                              handleChange={(e) => setGroup1Switch(e.target.value as string)}
                              fullWidth
                              disabled={formik.values.ruleCards[index]?.levelOneConditions.length > 1}
                              options={andOr}
                              hideDescription
                            />
                          ) : (
                            <DataBlock
                              value={
                                editMode
                                  ? group1Switch
                                  : formik.values.ruleCards[index]?.levelOneConditions &&
                                      formik.values.ruleCards[index]?.levelOneConditions.length > 0
                                    ? formik.values.ruleCards[index]?.levelOneConditions[0].andOr
                                    : ""
                              }
                            />
                          )}
                        </Grid>
                      )}

                    {/* Level 2 group */}
                    {formik.values.ruleCards[index]?.levelTwoConditions &&
                      formik.values.ruleCards[index]?.levelTwoConditions.length > 0 && (
                        <Grid item xs>
                          <FieldArray name={`ruleCards[${index}].levelTwoConditions`}>
                            {({ push: level2push, remove: level2remove }) => (
                              <Grid
                                container
                                item
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                className={styles.groupBackground}
                              >
                                <Grid container item spacing={1}>
                                  {formik.values.ruleCards[index]?.levelTwoConditions?.map(
                                    (item: Rule, idx: number) => (
                                      <Grid item xs={12} key={item?.id}>
                                        <Condition
                                          key={item?.id}
                                          ruleIndex={index}
                                          conditionLevel={"levelTwoConditions"}
                                          id={`ruleCards[${index}].levelTwoConditions[${idx}]`}
                                          conditionListFile={conditionListFile as ConditionListFile}
                                          index={idx}
                                          editMode={editMode}
                                          createMode={createMode}
                                          formik={formik}
                                          removeCondition={(shouldRemoveFile: boolean) => {
                                            updateFileCount(shouldRemoveFile)
                                            handleRemoveCondition(level2remove, idx, "levelTwoConditions")
                                          }}
                                          groupSwitch={group2Switch}
                                          lastSwitchSetter={setGroup2Switch}
                                          setFileData={setFileData}
                                          fileData={fileData}
                                          filesCount={filesCountInRule}
                                          setFilesCount={setFilesCountInRule}
                                        />
                                      </Grid>
                                    ),
                                  )}

                                  {/* and/or */}
                                  {formik.values.ruleCards[index]?.levelThreeConditions &&
                                    formik.values.ruleCards[index]?.levelThreeConditions.length > 0 && (
                                      <Grid item xs={3} md={2} lg={1}>
                                        {editMode && formik.values.ruleCards[index]?.levelTwoConditions.length === 1 ? (
                                          <Select
                                            type="text"
                                            value={group2Switch}
                                            handleChange={(e) => setGroup2Switch(e.target.value as string)}
                                            fullWidth
                                            disabled={formik.values.ruleCards[index]?.levelTwoConditions.length > 1}
                                            options={andOr}
                                            hideDescription
                                          />
                                        ) : (
                                          <DataBlock
                                            value={
                                              editMode
                                                ? group2Switch
                                                : formik.values.ruleCards[index]?.levelTwoConditions &&
                                                    formik.values.ruleCards[index]?.levelTwoConditions.length > 0
                                                  ? formik.values.ruleCards[index]?.levelTwoConditions[0].andOr
                                                  : ""
                                            }
                                          />
                                        )}
                                      </Grid>
                                    )}

                                  {/* Level 3 group */}
                                  {formik.values.ruleCards[index]?.levelThreeConditions &&
                                    formik.values.ruleCards[index]?.levelThreeConditions.length > 0 && (
                                      <Grid item xs>
                                        <FieldArray name={`ruleCards[${index}].levelThreeConditions`}>
                                          {({ push: level3push, remove: level3remove }) => (
                                            <Grid container item className={styles.groupBackground}>
                                              <Grid
                                                container
                                                item
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                spacing={1}
                                              >
                                                {formik.values.ruleCards[index]?.levelThreeConditions?.map(
                                                  (item: Rule, idx: number) => (
                                                    <Grid item xs={12} key={item?.id}>
                                                      <Condition
                                                        key={item?.id}
                                                        ruleIndex={index}
                                                        conditionLevel={"levelThreeConditions"}
                                                        id={`ruleCards[${index}].levelThreeConditions[${idx}]`}
                                                        conditionListFile={conditionListFile as ConditionListFile}
                                                        index={idx}
                                                        editMode={editMode}
                                                        createMode={createMode}
                                                        formik={formik}
                                                        removeCondition={(shouldRemoveFile: boolean) => {
                                                          updateFileCount(shouldRemoveFile)
                                                          handleRemoveCondition(
                                                            level3remove,
                                                            idx,
                                                            "levelThreeConditions",
                                                          )
                                                        }}
                                                        setFileData={setFileData}
                                                        fileData={fileData}
                                                        filesCount={filesCountInRule}
                                                        setFilesCount={setFilesCountInRule}
                                                      />
                                                    </Grid>
                                                  ),
                                                )}
                                              </Grid>

                                              {/* level 3 menu button  */}
                                              {(editMode || createMode) &&
                                                formik.values.ruleCards[index]?.levelThreeConditions &&
                                                formik.values.ruleCards[index]?.levelThreeConditions.length > 0 && (
                                                  <Grid container>
                                                    <Grid item lg={12} mt={1}>
                                                      {/* Todo: Should be replaced later on once we have text buttons in Sui */}
                                                      <MuiButton
                                                        size="small"
                                                        className={styles.MuiButton}
                                                        onClick={(event) => setAnchorEl3(event.currentTarget)}
                                                        disabled={isLoading || formik.isSubmitting}
                                                      >
                                                        + Add Rule
                                                      </MuiButton>
                                                      <Menu
                                                        key="basic-menu3"
                                                        anchorEl={anchorEl3}
                                                        open={openMenu3}
                                                        onClose={() => setAnchorEl3(null)}
                                                        menuMaxContent
                                                      >
                                                        <MenuItem
                                                          onClick={() => {
                                                            level3push(emptyCondition)
                                                            setAnchorEl3(null)
                                                          }}
                                                        >
                                                          <Grid container display={"flex"} gap={1} height={"20px"}>
                                                            <Grid item>
                                                              <AddOutlinedIcon
                                                                fontSize="small"
                                                                htmlColor={theme.palette.grayscale.text[1]}
                                                              />
                                                            </Grid>
                                                            <Grid item>
                                                              <Typography variant="a">Add Rule</Typography>
                                                            </Grid>
                                                          </Grid>
                                                        </MenuItem>
                                                      </Menu>
                                                    </Grid>
                                                  </Grid>
                                                )}
                                            </Grid>
                                          )}
                                        </FieldArray>
                                      </Grid>
                                    )}

                                  {/* level 2 menu button  */}
                                  {(editMode || createMode) &&
                                    formik.values.ruleCards[index]?.levelTwoConditions &&
                                    formik.values.ruleCards[index]?.levelTwoConditions.length > 0 && (
                                      <Grid container>
                                        <Grid item lg={12} mt={1}>
                                          {/* Todo: Should be replaced later on once we have text buttons in Sui  */}
                                          <MuiButton
                                            size="small"
                                            className={styles.MuiButton}
                                            onClick={(event) => setAnchorEl2(event.currentTarget)}
                                            disabled={isLoading || formik.isSubmitting}
                                          >
                                            + Add Rule
                                          </MuiButton>
                                          <Menu
                                            key="basic-menu2"
                                            anchorEl={anchorEl2}
                                            open={openMenu2}
                                            onClose={() => setAnchorEl2(null)}
                                            menuMaxContent
                                          >
                                            <MenuItem
                                              onClick={() => {
                                                level2push({ ...emptyCondition, andOr: group2Switch })
                                                setAnchorEl2(null)
                                              }}
                                            >
                                              <Grid container display={"flex"} gap={1} height={"20px"}>
                                                <Grid item>
                                                  <AddOutlinedIcon
                                                    fontSize="small"
                                                    htmlColor={theme.palette.grayscale.text[1]}
                                                  />
                                                </Grid>
                                                <Grid item>
                                                  <Typography variant="a">Add Rule</Typography>
                                                </Grid>
                                              </Grid>
                                            </MenuItem>
                                            {formik.values.ruleCards[index]?.levelThreeConditions &&
                                              formik.values.ruleCards[index]?.levelThreeConditions.length === 0 && (
                                                <MenuItem
                                                  onClick={() => {
                                                    formik.values.ruleCards[index]?.levelThreeConditions.push(
                                                      emptyCondition as never,
                                                      emptyCondition as never,
                                                    )
                                                    setAnchorEl2(null)
                                                  }}
                                                >
                                                  <Grid container display={"flex"} gap={1} height={"20px"}>
                                                    <Grid item>
                                                      <AddBoxOutlinedIcon
                                                        fontSize="small"
                                                        htmlColor={theme.palette.grayscale.text[1]}
                                                      />
                                                    </Grid>
                                                    <Grid item>
                                                      <Typography variant="a">Add Group</Typography>
                                                    </Grid>
                                                  </Grid>
                                                </MenuItem>
                                              )}
                                          </Menu>
                                        </Grid>
                                      </Grid>
                                    )}
                                </Grid>
                              </Grid>
                            )}
                          </FieldArray>
                        </Grid>
                      )}

                    {/* level 1 menu button  */}
                    {(editMode || createMode) && (
                      <Grid container justifyContent="flex-start" alignItems="center">
                        <Grid item lg={12} mt={1}>
                          {/* Todo: Should be replaced later on once we have text buttons in Sui  */}
                          <MuiButton
                            size="small"
                            className={styles.MuiButton}
                            onClick={(event) => setAnchorEl1(event.currentTarget)}
                            disabled={isLoading || formik.isSubmitting}
                          >
                            + Add Rule
                          </MuiButton>
                          <Menu
                            key="basic-menu1"
                            anchorEl={anchorEl1}
                            open={openMenu1}
                            onClose={() => setAnchorEl1(null)}
                            menuMaxContent
                          >
                            <MenuItem
                              onClick={() => {
                                level1push({ ...emptyCondition, andOr: group1Switch })
                                setAnchorEl1(null)
                              }}
                            >
                              <Grid container display={"flex"} gap={1} height={"20px"}>
                                <Grid item>
                                  <AddOutlinedIcon fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />
                                </Grid>
                                <Grid item>
                                  <Typography variant="a">Add Rule</Typography>
                                </Grid>
                              </Grid>
                            </MenuItem>
                            {formik.values.ruleCards[index]?.levelTwoConditions &&
                              formik.values.ruleCards[index]?.levelTwoConditions.length === 0 && (
                                <MenuItem
                                  onClick={() => {
                                    formik.values.ruleCards[index]?.levelTwoConditions.push(
                                      emptyCondition as never,
                                      emptyCondition as never,
                                    )
                                    setAnchorEl1(null)
                                  }}
                                >
                                  <Grid container display={"flex"} gap={1} height={"20px"}>
                                    <Grid item>
                                      <AddBoxOutlinedIcon
                                        fontSize="small"
                                        htmlColor={theme.palette.grayscale.text[1]}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="a">Add Group</Typography>
                                    </Grid>
                                  </Grid>
                                </MenuItem>
                              )}
                          </Menu>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </FieldArray>
          </FormikProvider>
        )}

        <Grid container justifyContent="flex-start" alignItems="center" spacing={2} mt={2}>
          {/* Return */}
          <Grid item xs={3} lg={1} display="flex" justifyContent="flex-end">
            <Typography variant="p">return</Typography>
          </Grid>

          {/* Label */}
          <Grid item xs={9} lg={11}>
            {editMode || createMode ? (
              <InputText
                hideDescription
                id={`ruleCards[${index}].label`}
                placeholder="Label"
                value={formik.values.ruleCards[index]?.label}
                handleChange={(e: InputChangeEvent) => {
                  if (e._reactName === "onClick") {
                    formik.setFieldValue(`ruleCards[${index}].label_id`, e.target.value)
                    formik.setFieldValue(
                      `ruleCards[${index}].label`,
                      optionsWithValues.filter((option) => e.target.value === option.value)[0].label ?? "",
                    )
                  } else {
                    formik.handleChange(e)
                    formik.setFieldValue(`ruleCards[${index}].label_id`, "")
                  }
                  debouncedOnChange()
                }}
                error={
                  formik?.touched?.ruleCards &&
                  formik?.errors?.ruleCards &&
                  formik?.touched?.ruleCards[index]?.label &&
                  Boolean((formik?.errors?.ruleCards[index] as RuleGroupConditions)?.label_id) &&
                  (formik?.errors?.ruleCards[index] as RuleGroupConditions)?.label_id
                }
                optionsWithValues={
                  optionsWithValues.length === 0
                    ? [{ label: "No entries found!", value: "default", disabled: true }]
                    : optionsWithValues
                }
                handleBlur={formik.handleBlur}
                disabled={formik.isSubmitting || isLoading}
                noEntriesProps={{
                  handleAddNewValue: async () => {
                    await CreateLabelMutation.mutateAsync({
                      project_uuid: projectId as string,
                      name: formik.values.ruleCards[index].label,
                    }).then((res) => {
                      formik.setFieldValue(`ruleCards[${index}].label_id`, res.data.uuid)
                    })
                  },
                }}
                loaderComponent={
                  <Grid container item xs={12} px={2} py={0.5}>
                    <CircularProgress size={14} />
                  </Grid>
                }
                isLoadingOptions={isLabelListLoading}
                menuProps={{
                  handleScrollToEnd: () => {
                    labelList?.data.next && setPage(page + 1)
                  },
                  scrollThreshold: 10,
                }}
                fullWidth
                description="Labels are case sensitive and must be exactly the same as the model's output."
              />
            ) : (
              <DataBlock value={returnLabel ?? formik.values.ruleCards[index]?.label} />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
