import React from "react"

import { Box } from "@mui/material"
import { ResponsiveBar } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../../../../../../hooks/UseTheme"
import { NivoTheme } from "../../../../../../../themes/nivoTheme"
import { truncateString } from "../../../../../../../utils/genericHelpers"

type CategoricalType = {
  low_rank: number
  high_rank: number
  sample_count: number
  label: string
}

type Props = {
  title?: string
  data: CategoricalType[]
  graphHeight: number
  isLoading?: boolean
}

export function CategoricalHistogram(props: Props): React.ReactElement {
  const { data, graphHeight } = props

  const theme = getTheme()

  const sortedData = React.useMemo(() => {
    const newArr = Array.from(data)
    if (newArr.length > 9) {
      // get the sum of other values
      const sortedArray = newArr.sort((a, b) => b.sample_count - a.sample_count)
      const otherCount = sortedArray.slice(9).reduce((acc, obj) => {
        return acc + obj.sample_count
      }, 0)
      return [
        { high_rank: 0, label: "Other (" + (newArr.length - 9) + ")", low_rank: 0, sample_count: otherCount },
        ...sortedArray.slice(0, 9).sort((a, b) => a.sample_count - b.sample_count),
      ]
    } else {
      return newArr.sort((a, b) => a.sample_count - b.sample_count).slice(0, 9)
    }
  }, [data])

  return (
    <Box style={{ height: graphHeight }}>
      <ResponsiveBar
        data={sortedData}
        theme={NivoTheme(theme)}
        indexBy="label"
        keys={["sample_count"]}
        colors={["#deebf7", "#c6dbef", "#9ecae1", "#6baed6", "#4292c6", "#2171b5", "#08519c", "#08306b"]}
        colorBy="indexValue"
        borderWidth={1}
        margin={{ top: 20, right: 10, bottom: 25, left: 100 }}
        padding={sortedData.length < 3 ? 0.9 : 0}
        layout="horizontal"
        valueScale={{ type: "linear" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          format: (v) => truncateString(v, 14),
        }}
        enableLabel={true}
        enableGridY={false}
        enableGridX={true}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        tooltip={(slice) => {
          return (
            <div className={"chart-tooltip"}>
              <Typography variant="h3-bold" variantColor={2}>
                {slice.data.label}
              </Typography>
              <Typography variant="p" variantColor={2}>
                {`Count: ${slice.data.sample_count.toFixed(0)}`}
              </Typography>
            </div>
          )
        }}
      />
    </Box>
  )
}
