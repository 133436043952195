import React, { Fragment } from "react"

import InfiniteScroll from "react-infinite-scroll-component"

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import { CircularProgress, Grid, useMediaQuery, useTheme } from "@mui/material"
import { Button, MenuItem, Tag, Typography } from "@synapse-analytics/synapse-ui"
import { isToday } from "date-fns"
import moment from "moment"

import { VersioningComponents } from "../../types/custom/projects"
import { ScriptVersion } from "../../types/generated/api/ScriptVersion"
import { WorkflowVersionsRetrieve } from "../../types/generated/api/WorkflowVersionsRetrieve"
import { KonanTimeHelper } from "../../utils/genericHelpers"
import { KonanAvatarComponent } from "../KonanAvatar"

import styles from "./VersionHistory.module.scss"

interface RenderVersionsProps {
  versions: Array<VersioningComponents>
  selectedVersion: string
  setSelectedVersion: React.Dispatch<React.SetStateAction<string | undefined>>
}

const RenderVersions = (props: RenderVersionsProps): React.ReactElement => {
  const { versions, selectedVersion, setSelectedVersion } = props

  const konanTime = new KonanTimeHelper()

  return (
    <Fragment>
      {versions.map((version, index: number) => {
        const sameDateAsBefore = konanTime.datesAreOnSameDay(
          new Date(version?.created_at),
          new Date(versions[index - 1]?.created_at),
        )

        return (
          <VersionCard
            key={version?.version}
            version={version}
            selectedVersion={selectedVersion}
            setSelectedVersion={setSelectedVersion}
            sameDateAsBefore={sameDateAsBefore}
            index={index}
          />
        )
      })}
    </Fragment>
  )
}

interface VersionCardProps {
  title?: string
  version: ScriptVersion
  selectedVersion?: string
  setSelectedVersion?: React.Dispatch<React.SetStateAction<string | undefined>>
  sameDateAsBefore?: boolean
  index?: number
}

const VersionCard = (props: VersionCardProps): React.ReactElement => {
  const { title, version, selectedVersion, setSelectedVersion, sameDateAsBefore, index } = props

  const theme = useTheme()

  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Fragment>
      {!sameDateAsBefore && (
        <Typography
          variant="label"
          gutterBottom
          variantColor={2}
          style={{ marginTop: (index as number) > 0 ? "16px" : "0px" }}
        >
          {title
            ? title
            : isToday(new Date(version?.created_at))
              ? "Today"
              : moment(new Date(version?.created_at)).format("ddd, Do MMMM, yyyy")}
        </Typography>
      )}

      <MenuItem
        key={version?.version}
        isSelected={version?.version === selectedVersion}
        // this to avoid fetching the same version we already standing on
        onClick={() => version?.version !== selectedVersion && setSelectedVersion?.(version?.version)}
        style={{ marginLeft: "0px" }}
      >
        <Grid item container display="flex" justifyContent="space-between">
          <Grid item xs={2.25} display={"flex"} alignItems={"center"} mr={0.5}>
            <Typography noWrap variant={"a"} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              v{version?.version}
            </Typography>
          </Grid>

          <Grid item xs>
            <KonanAvatarComponent
              createdBy={version.created_by ?? "Synapse-bot"}
              createdAt={version.created_at}
              title={version.created_by?.split("@")[0] ?? "Synapse-bot"}
              avatarSize={24}
            />
          </Grid>

          {(version as WorkflowVersionsRetrieve)?.is_deprecated ? (
            <Grid item xs display={"flex"} alignItems={"center"} justifySelf={"flex-end"} justifyContent={"flex-end"}>
              <Tag size="small" variant="negative">
                Deprecated
              </Tag>
            </Grid>
          ) : (
            version.is_active_version && (
              <Grid item xs justifySelf={"flex-end"} justifyContent={"flex-end"} display={"flex"} alignItems={"center"}>
                <Tag size={isMediumScreen ? "small" : "regular"}>Active</Tag>
              </Grid>
            )
          )}
        </Grid>
      </MenuItem>
    </Fragment>
  )
}

interface VersionHistoryProps {
  versions: VersioningComponents[]
  selectedVersion: string
  setSelectedVersion: React.Dispatch<React.SetStateAction<string | undefined>>
  onClose?: () => void
  isLoading: boolean
  activeVersion: VersioningComponents

  // This is just a flag to indicate whether this version history will follow 100% the new revamp layout or not
  // TODO: remove it after revamp
  isScriptView?: boolean

  fetchingStrategy?: "infinite" | "default"

  infiniteScrollProps?: {
    hasNextPage?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchNextPage?: any
    isFetchingNextPage: boolean
  }
}

export function VersionHistory(props: Readonly<VersionHistoryProps>): React.ReactElement {
  const {
    onClose,
    setSelectedVersion,
    selectedVersion,
    versions,
    isLoading,
    activeVersion,
    isScriptView,
    infiniteScrollProps,
    fetchingStrategy = "default",
  } = props

  return (
    <Grid
      item
      p={2}
      className={styles.root}
      height={document.getElementById("single-script")?.clientHeight + "px"}
      id="version-history"
    >
      {/* Header */}
      <Grid item xs={12} display={"flex"} gap={1.5}>
        {onClose && (
          <Grid item>
            <Button onClick={() => onClose?.()} size="small">
              <KeyboardBackspaceIcon fontSize="small" />
            </Button>
          </Grid>
        )}

        {isScriptView && (
          <Grid item>
            <Typography variant="h2-bold" textTransform="capitalize">
              Version History
            </Typography>
          </Grid>
        )}
      </Grid>

      {/* Body */}
      <Grid item xs={12} py={3}>
        <VersionCard
          setSelectedVersion={setSelectedVersion}
          title="Active Version"
          version={activeVersion}
          key={"active-version"}
        />

        {isLoading ? (
          <Grid item mt={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress size={20} color="primary" />
          </Grid>
        ) : fetchingStrategy === "infinite" && infiniteScrollProps ? (
          <InfiniteScroll
            dataLength={versions.length ?? 0}
            next={() => !isLoading && !infiniteScrollProps.isFetchingNextPage && infiniteScrollProps.fetchNextPage?.()}
            hasMore={infiniteScrollProps.hasNextPage ?? false}
            loader={
              <Grid item mt={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <CircularProgress size={20} color="primary" />
              </Grid>
            }
            scrollableTarget={"version-history"}
          >
            <RenderVersions
              selectedVersion={selectedVersion}
              setSelectedVersion={setSelectedVersion}
              versions={versions}
            />
          </InfiniteScroll>
        ) : (
          <Fragment>
            <RenderVersions
              selectedVersion={selectedVersion}
              setSelectedVersion={setSelectedVersion}
              versions={versions}
            />
          </Fragment>
        )}
      </Grid>
    </Grid>
  )
}
