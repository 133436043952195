import { HeaderMetaData } from "../types/custom/projects"
import { useRoutesHeaderData } from "./useRoutesHeaderData"

/**
 * Custom hook, used for getting the current header data needed according to the current route
 * @returns {HeaderMetaData}
 */
export const useHeaderData = (): HeaderMetaData => {
  const { pathname, search } = window.location
  const queryParams = new URLSearchParams(search)
  const routes = useRoutesHeaderData()

  switch (true) {
    // workflow overview
    case pathname === "/projects" && queryParams.get("page") === "workflow":
      return routes?.workflows

    // models overview
    case pathname === "/projects" && queryParams.get("page") === "model":
      return routes?.models

    // KCR
    case /\/kcr/i.test(pathname):
      return routes?.kcr

    // team / settings
    case /\/settings/i.test(pathname):
    case /\/team/i.test(pathname):
      return routes?.settings

    // Live-decisions
    case /\/decisions/i.test(pathname):
      return routes?.decisions

    // Data-files
    case /\/data-files/i.test(pathname):
      return routes?.dataFiles

    // Workflow and its sub-routes (workflow components)
    case /\/workflows/i.test(pathname):
    case /\/rulesets/i.test(pathname):
    case /\/taglists/i.test(pathname):
    case /\/scorecards/i.test(pathname):
    case /\/programs/i.test(pathname):
    case /\/scripts/i.test(pathname):
    case /\/SimulationReports/i.test(pathname):
      return routes?.workflow

    // EKYC
    case /\/ekyc/i.test(pathname):
      return routes?.ekyc

    // Models Overview
    case /\/overview/i.test(pathname):
      return routes?.overview

    // Live monitoring
    case /\/requests/i.test(pathname) && !pathname.includes("models"):
      return routes?.requests

    // Models and configuration
    case /\/decision-engines/i.test(pathname):
      return routes?.modelsList

    // Model training
    case /\/model-training/i.test(pathname):
      return routes?.modelTraining

    // Integration
    case /\/integration/i.test(pathname):
      return routes?.integration

    // current model sub-routes (api requests, classification, data drift, model logs)
    case /\/requests/i.test(pathname):
    case /\/classification/i.test(pathname):
    case /\/drift/i.test(pathname):
    case /\/logs/i.test(pathname):
      return routes?.classification

    default:
      return { title: "" }
  }
}
