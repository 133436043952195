import React from "react"

import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { CircularProgress, Grid, IconButton } from "@mui/material"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"

import styles from "./AccordionHeader.module.scss"

type Props = {
  shouldShowActionButton?: boolean
  isAccordionExpanding?: boolean
  isActionButtonDisabled?: boolean
  view?: "code" | "features"
  actionButtonOnClick?: () => void
  headerTitle: string
}

/**
 * Header UI for the Accordions used in script dialog
 * @param {boolean} shouldShowActionButton toggle for rendering action button
 * @param {boolean} isAccordionExpanding indicator wether accordion is expanding or not
 * @param {boolean} isActionButtonDisabled indicator for the disable state for the action button
 * @param {string} view current view for the accordion ("code" | "features")
 * @param {function} actionButtonOnClick handler for clicking on the action button
 * @param {string} headerTitle
 * @returns <ReactElement />
 */
export function AccordionHeader(props: Readonly<Props>): React.ReactElement {
  const {
    shouldShowActionButton,
    isActionButtonDisabled,
    actionButtonOnClick,
    view,
    headerTitle,
    isAccordionExpanding,
  } = props
  const theme = getTheme()

  return (
    <Grid className={view === "code" ? styles.codeHeader : styles.consoleHeader}>
      <Typography variantColor={2} variant="p">
        {headerTitle}
      </Typography>

      {shouldShowActionButton && view === "code" && (
        <Grid>
          <Button
            variant="secondary"
            onClick={() => actionButtonOnClick?.()}
            size="small"
            startIcon={<PlayArrowIcon style={{ fontSize: 16 }} />}
            disabled={isActionButtonDisabled}
          >
            {isActionButtonDisabled ? <CircularProgress size={20} color="inherit" /> : "Run Test"}
          </Button>
        </Grid>
      )}

      {view !== "code" && (
        <IconButton size="small" onClick={() => actionButtonOnClick?.()}>
          {isAccordionExpanding ? (
            <ExpandLessIcon style={{ color: theme.palette.grayscale.text[2] }} />
          ) : (
            <ExpandMoreIcon style={{ color: theme.palette.grayscale.text[2] }} />
          )}
        </IconButton>
      )}
    </Grid>
  )
}
