import React, { Dispatch, Fragment, SetStateAction } from "react"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { Grid } from "@mui/material"
import { Button, Tooltip } from "@synapse-analytics/synapse-ui"

import { SearchSortFilter } from "./SearchSortFilter"

import styles from "./KonanSubHeader.module.scss"

type Props = {
  setAction?: Dispatch<SetStateAction<boolean>>
  title?: string
  searchValue: string | undefined
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  sortValue: string | undefined
  setSortValue: React.Dispatch<React.SetStateAction<string>>
  filterValue?: string[] | null
  setFilterValue?: React.Dispatch<React.SetStateAction<string[]>>
  containerType: "Project" | "Model" | "Retraining" | "Configuration" | "Script"
  filterOptions?: string[]
  sortOptions?: string[]
  isBtnDisabled?: boolean
  disabledBtnTooltip?: string
  actions?: React.ReactElement[]
  showButton?: boolean
  isDebouncedInput?: boolean
}

/**
 * section header component
 * @param  {string} title section title
 * @param  {Dispatch} setAction section Action
 * Values passed to Search sort filter component
 * @param  {string} searchValue
 * @param  {React.Dispatch} setSearchValue
 * @param  {string} onSortValueChange
 * @param  {React.Dispatch} setSortValue
 * @param  {string} filterValue
 * @param  {React.Dispatch} setFilterValue
 * @param  {"Project" | "Model" | "Retraining"} containerType
 * @param  {string[]} filterOptions
 * @param  {string[]} sortOptions
 * @param  {boolean} isBtnDisabled
 * @param  {string} disabledBtnTooltip
 * @return {React.ReactElement}
 */
export function KonanSubHeader(props: Readonly<Props>): React.ReactElement {
  const {
    setAction,
    title,
    sortValue,
    containerType,
    sortOptions,
    setSearchValue,
    filterValue,
    setFilterValue,
    setSortValue,
    searchValue,
    filterOptions,
    isBtnDisabled = false,
    showButton = true,
    disabledBtnTooltip,
    actions,
    isDebouncedInput,
  } = props

  return (
    <Grid
      direction="row"
      container
      display="flex"
      sx={{
        flexDirection: {
          xs: "column", // column direction for xs to lg
          md: "row", // row direction for md and above
        },
        justifyContent: {
          xs: "flex-start", // flex-start for xs to md
          md: "space-between", // space-between for md and above
        },
        alignItems: {
          xs: "flex-start", // flex-start for xs to md
          md: "center", // center for md and above
        },
      }}
    >
      <Grid item className={styles.ssfContainer}>
        <SearchSortFilter
          searchValue={searchValue}
          onSearchValueChange={setSearchValue}
          sortValue={sortValue}
          onSortValueChange={setSortValue}
          filterValue={filterValue}
          onFilterValueChange={setFilterValue}
          containerType={containerType}
          sortOptions={sortOptions}
          filterOptions={filterOptions}
          isDebouncedInput={isDebouncedInput}
        />
      </Grid>

      {((actions && actions.length > 0) || setAction) && (
        <Grid item display="flex" gap={1}>
          {actions && actions?.length > 0 ? (
            actions?.map((action, index) => {
              return (
                <Grid
                  item
                  key={index}
                  sx={{
                    mb: {
                      lg: 0,
                      xs: 1,
                    },
                  }}
                >
                  {action}
                </Grid>
              )
            })
          ) : (
            <Fragment>
              {showButton && (
                <Tooltip title={disabledBtnTooltip}>
                  <Button
                    onClick={() => setAction?.(true)}
                    variant="primary"
                    disabled={isBtnDisabled}
                    className={styles.subHeaderBtn}
                    startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
                    id="sub_header_btn"
                    size="regular"
                  >
                    {title}
                  </Button>
                </Tooltip>
              )}
            </Fragment>
          )}
        </Grid>
      )}
    </Grid>
  )
}
