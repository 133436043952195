import React, { Fragment } from "react"

import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"

import { Box, CircularProgress, Grid, Link } from "@mui/material"
import { Button, NotificationUtils, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { SidAPI } from "../../../../services/SidAPI"

import styles from "../../Entry.module.scss"

type Props = {
  email: string
  isPostSignup?: boolean
  createAccount?: () => void
}

/**
 * email verification component
 * @param  {string} email email which the verification email will be sent to
 * @param  {boolean} isPostSignup check if validation screen is entered from login or SignUp screen
 * @param  {function} createAccount navigates user to signup screen if wrong email
 * @return  {<Verification />}
 */
export function Verification(props: Props): React.ReactElement {
  const { email, isPostSignup = false, createAccount } = props

  const navigate = useNavigate()

  const [isVerificationSent, setIsVerificationSent] = React.useState<boolean>(isPostSignup)

  // Request mutations
  const resendVerificationMutation = useMutation<AxiosResponse<void>, AxiosError, string>(
    SidAPI.resendVerificationLink,
    {
      onSuccess: () => setIsVerificationSent(true),
      onError: () =>
        NotificationUtils.toast("Resending verification link failed, please try again.", {
          snackBarVariant: "negative",
        }),
    },
  )

  /**
   *  Resend verification email to user
   * */
  async function resendVerification(): Promise<void> {
    await resendVerificationMutation.mutateAsync(email)
  }

  return (
    <Grid>
      <Grid container justifyContent="center" className={styles.containerWidth}>
        {/**
         * Verification screens snackbars
         * 1. email sent to account successfully
         * 2. Network error
         */}
        {resendVerificationMutation.isSuccess && (
          <Grid item xs={12}>
            <Snackbar variant="positive" fullWidth description="Verification link has been sent to your email" />
            <Box mt={2} />
          </Grid>
        )}

        {resendVerificationMutation.isError && resendVerificationMutation.error?.message === "Network Error" && (
          <Grid item xs={12}>
            <Snackbar variant="negative" fullWidth description="Network Error Could not connect to server." />
            <Box mt={2} />
          </Grid>
        )}
      </Grid>

      <Grid container className={styles.verificationContainer}>
        <Grid item>
          <Typography variant="h2-bold">
            {!isVerificationSent ? "Your account is not verified yet" : "Verify your account"}
          </Typography>
        </Grid>

        <Grid item xs={12} paddingBottom={3}>
          <Typography variant="p">
            {!isVerificationSent ? (
              <Fragment>
                A verification email will be sent to <strong>{email}</strong>
              </Fragment>
            ) : (
              <Fragment>
                We've sent an email to <strong>{email}</strong> to verify your email address and and activate your
                account.
              </Fragment>
            )}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {isVerificationSent && (
            <Fragment>
              <Button onClick={() => navigate("/")} variant="primary" type="submit" fullWidth>
                Back to login
              </Button>
              <hr style={{ margin: "8px 0px", borderColor: "rgba(128, 128, 128, 0.8)" }} />
            </Fragment>
          )}

          <Button
            onClick={resendVerification}
            variant="secondary"
            disabled={resendVerificationMutation.isLoading}
            type="submit"
            fullWidth
          >
            {resendVerificationMutation.isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : !isVerificationSent ? (
              "Send verification link"
            ) : (
              "Resend Email"
            )}
          </Button>
        </Grid>

        <Grid container item xs={12} justifyContent={"center"} paddingTop={1.5}>
          <Typography variant="span">
            Wrong email?{" "}
            <Link
              disabled={resendVerificationMutation.isLoading}
              className={styles.link}
              component="button"
              variant="body2"
              onClick={() => (createAccount ? createAccount() : navigate("/register"))}
              underline="hover"
            >
              <Typography variant="span">Create account</Typography>
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
