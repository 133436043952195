import React, { Fragment, useState } from "react"

import CloseIcon from "@mui/icons-material/Close"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useTheme as MuiTheme,
  useMediaQuery,
} from "@mui/material"
import { Button, RadioButton, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { ScoreCardSetCreate } from "../../types/generated/api/ScoreCardSetCreate"
import { ScorecardEquationDialogProps } from "./Interfaces"
import { ScorecardExample } from "./ScorecardExample"

import styles from "./ScorecardExample.module.scss"

/**
 * Dialog to compare between the 2 methods of scorecard calculation, and choose between them.
 * @param {boolean} open
 * @param {function} onClose
 * @returns {React.ReactElement}
 */
export function ScorecardEquationDialog(props: ScorecardEquationDialogProps): React.ReactElement {
  const { open, onClose, setCalculationMethod, calculationMethod, shouldEnableSelectingMethods } = props
  const theme = MuiTheme()

  const colorTheme = getTheme()
  const [innerCalculationMethod, setInnerCalculationMethod] = useState(calculationMethod)

  return (
    <Dialog
      open={open}
      onClose={() => {
        setCalculationMethod(innerCalculationMethod)
        setTimeout(() => {
          onClose()
        }, 10)
      }}
      fullWidth
      maxWidth="md"
      fullScreen={useMediaQuery(theme.breakpoints.down("md"))}
    >
      <DialogTitle
        id="form-dialog-title"
        className="dialog-header-base"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h2-bold">Scorecard Block Explanation</Typography>
        {!shouldEnableSelectingMethods && (
          <IconButton onClick={onClose} size="small" className={"close-icon-button"}>
            <CloseIcon style={{ color: colorTheme.palette.grayscale.text[2] }} />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent className="dialog-content-base">
        <Grid
          className={styles.examplesContainer}
          container
          style={{ marginBottom: shouldEnableSelectingMethods ? 0 : "12px" }}
        >
          <Grid
            mb={useMediaQuery(theme.breakpoints.down("lg")) ? 2 : 0}
            item
            xs={12}
            lg={6}
            mr={3}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Grid item mb={1.5}>
              {shouldEnableSelectingMethods ? (
                <RadioButton
                  value="normalized-value-by-weight-inner"
                  onChange={() =>
                    setInnerCalculationMethod(ScoreCardSetCreate.calculation_method.NORMALIZED_VALUE_BY_WEIGHT)
                  }
                  checked={innerCalculationMethod === ScoreCardSetCreate.calculation_method.NORMALIZED_VALUE_BY_WEIGHT}
                  description="Divide value by max value then multiply by card weight"
                  label="Divide by max value"
                  id="normalized-value-by-weight-inner"
                />
              ) : (
                <Fragment>
                  <Typography variant="p">Divide by max value</Typography>
                  <Typography variant="span" variantColor={2}>
                    Divide value by max value then multiply by card weight
                  </Typography>
                </Fragment>
              )}
            </Grid>

            <Grid item xs={12} width="100%">
              <ScorecardExample
                isEquationNormalized
                isSelected={innerCalculationMethod === ScoreCardSetCreate.calculation_method.NORMALIZED_VALUE_BY_WEIGHT}
              />
            </Grid>
          </Grid>

          <Grid item display="flex" flexDirection="column" xs={12} lg={6}>
            <Grid item mb={1.5}>
              {shouldEnableSelectingMethods ? (
                <RadioButton
                  value="value-by-weight-inner"
                  onChange={() => setInnerCalculationMethod(ScoreCardSetCreate.calculation_method.VALUE_BY_WEIGHT)}
                  checked={innerCalculationMethod === ScoreCardSetCreate.calculation_method.VALUE_BY_WEIGHT}
                  description="Multiply value by card weight"
                  label="Multiply by card weight"
                  id="Value-by-weight-inner"
                />
              ) : (
                <Fragment>
                  <Typography variant="p">Multiply by card weight</Typography>
                  <Typography variant="span" variantColor={2}>
                    Multiply value by card weight
                  </Typography>
                </Fragment>
              )}
            </Grid>

            <Grid item xs={12}>
              <ScorecardExample
                isSelected={innerCalculationMethod === ScoreCardSetCreate.calculation_method.VALUE_BY_WEIGHT}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      {shouldEnableSelectingMethods && (
        <DialogActions className="dialog-actions-base">
          <Button onClick={onClose} variant="ghost">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setCalculationMethod(innerCalculationMethod)
              setTimeout(() => {
                onClose()
              }, 10)
            }}
            variant="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
