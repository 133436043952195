import React, { useEffect, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import CloseIcon from "@mui/icons-material/Close"
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined"
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button as MuiButton,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import { Button, InputText, NotificationUtils, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FieldArray, FormikProps, FormikProvider, useFormik } from "formik"
import { v4 as uuidv4 } from "uuid"

import { getTheme } from "../../hooks/UseTheme"
import { KonanAPI } from "../../services/KonanAPI"
import { Operators } from "../../types/custom/projects"
import { CreateRulesetRequest, UpdateRulesetRequest } from "../../types/custom/rules"
import { RuleList } from "../../types/generated/api/RuleList"
import { RuleSetCreateRequest } from "../../types/generated/api/RuleSetCreateRequest"
import { RuleSetGroupRetrieve } from "../../types/generated/api/RuleSetGroupRetrieve"
import { RuleSetUpdate } from "../../types/generated/api/RuleSetUpdate"
import { RuleSetVersionRetrieve } from "../../types/generated/api/RuleSetVersionRetrieve"
import { ConditionParser, parseConditionIntoString } from "../../utils/conditionHelpers"
import { Condition } from "./components/Condition"

import styles from "./WorkflowBlocksDialog.module.scss"

interface Ruleset {
  name: string
  rules: Rule[]
}

export interface Rule {
  feature: string
  operator: string
  value: string
  type?: string
  secondValue?: string
  returnValue: string
  label_id: string
  uuid?: string
  created_at?: string
  id?: string
}

type DecisionDialogType = {
  isOpen: boolean
  onClose: () => void
  rulesetName: string | undefined
  rulesetUUID: string
  readMode: boolean | null
  rules: RuleList[] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workflowFormik: FormikProps<any>
}

// Yup validation schema
const validationSchemaDecisionNode = Yup.object({
  ruleset: Yup.object({
    name: Yup.string()
      .required("Feature is required")
      .matches(/^[a-zA-Z0-9._-]+$/, {
        message: "This field cannot contain white spaces or special character except: (.-_)",
      }),
    rules: Yup.array().of(
      Yup.object().shape({
        feature: Yup.string().required("Feature is required"),
        returnValue: Yup.string().required("Return value is required"),
        label_id: Yup.string().required("Choose an item from the dropdown to select a label"),
        operator: Yup.string().required("Operator is required"),
        value: Yup.number().typeError("Value must be number").required("Value is required"),
        secondValue: Yup.string().when(["operator"], (operator) => {
          return operator === "between"
            ? Yup.number().typeError("Value must be number").required("Value is required")
            : Yup.number()
        }),
      }),
    ),
  }),
})

/**
 * Dialog for creating, reading and updating ruleset with type decision
 * @param {boolean} isOpen indicator to open dialog or not
 * @param {function} onClose handler for closing dialog
 * @param {string} rulesetName
 * @param {string}  rulesetUUID
 * @param {boolean} readMode
 * @param {RuleList[]} rules rules array
 * @returns {React.reactELement}
 */
export function DecisionNodeDialog(props: Readonly<DecisionDialogType>): React.ReactElement {
  const { isOpen, onClose, readMode, rules, rulesetName, rulesetUUID, workflowFormik } = props
  const { id: projectId } = useParams<{ id: string }>()

  const MuiTheme = useMuiTheme()
  const queryClient = useQueryClient()
  const theme = getTheme()

  const [currentRuleset, setCurrentRuleset] = useState<RuleSetUpdate>()

  // state to handle the current mode
  const [mode, setMode] = useState<{ create: boolean; edit: boolean }>({
    create: !rulesetUUID,
    edit: false,
  })

  // fetch single ruleset
  const { data: rulesetData } = useQuery<AxiosResponse<RuleSetGroupRetrieve>, AxiosError>(
    ["ruleset", projectId, rulesetUUID],
    () => KonanAPI.fetchRuleset(projectId as string, rulesetUUID),
    {
      enabled: !!projectId && !!rulesetUUID,
      onSuccess: (res) => {
        // to avoid screen blackouts
        try {
          formik.setFieldValue("ruleset.name", res.data.name)

          const convertedRules = res?.data?.versions
            ?.find((version: RuleSetVersionRetrieve) => version?.is_active_version === true)
            ?.rules?.map((condition) => {
              const parsedCondition = new ConditionParser(condition?.condition)
              return {
                feature: parsedCondition.getFeature(),
                operator: parsedCondition.getOpeartor(),
                value: parsedCondition.getValue(),
                secondValue: parsedCondition.getSecondValue(),
                returnValue: condition?.return_label.name ?? "",
                label_id: condition?.return_label.uuid,
                created_at: condition?.created_at,
                id: uuidv4(),
              }
            })

          formik.setFieldValue("ruleset.rules", convertedRules)
        } catch (e) {
          console.warn(e)
        }
      },
    },
  )

  // creating a ruleset with type decision
  const createRulesetMutation = useMutation<AxiosResponse, AxiosError, CreateRulesetRequest>(KonanAPI.createRuleset, {
    mutationKey: "ruleset-create",
    onSuccess: async (response) => {
      NotificationUtils.toast("Decision Ruleset Created Successfully", { snackBarVariant: "positive" })
      onClose()
      setCurrentRuleset(response?.data)
      setMode({ create: false, edit: false })
      queryClient.invalidateQueries(["rulesets", projectId])
    },
    onError: async () => {
      NotificationUtils.toast("An error occurred while creating decision ruleset", { snackBarVariant: "negative" })
    },
  })

  // updating a ruleset with type decision
  const updateRulesetMutation = useMutation<AxiosResponse, AxiosError, UpdateRulesetRequest>(KonanAPI.updateRuleset, {
    mutationKey: "ruleset-updated",
    onSuccess: async (response) => {
      NotificationUtils.toast("Decision Ruleset Updated Successfully", { snackBarVariant: "positive" })
      onClose()
      setCurrentRuleset(response?.data)
      queryClient.invalidateQueries(["rulesets", projectId])
      queryClient.invalidateQueries(["ruleset", rulesetUUID])
      queryClient.invalidateQueries("ruleset")
      queryClient.invalidateQueries(["workflows", projectId])
      setMode({ create: false, edit: false })
    },
    onError: async () => {
      NotificationUtils.toast("An error occurred while updating decision ruleset", { snackBarVariant: "negative" })
    },
  })

  // formik state that contains all fields states and values
  const formik = useFormik<{ ruleset: Ruleset }>({
    validationSchema: validationSchemaDecisionNode,
    initialValues: {
      ruleset: {
        name: currentRuleset?.name ?? rulesetName ?? "",
        rules: [
          {
            feature: "",
            operator: Operators["<="],
            value: "",
            secondValue: "",
            returnValue: "",
            label_id: "",
            created_at: "",
          },
          {
            id: uuidv4(),
            feature: "",
            operator: Operators.between,
            value: "",
            secondValue: "",
            returnValue: "",
            label_id: "",
            created_at: "",
          },
          {
            feature: "",
            operator: Operators[">="],
            value: "",
            secondValue: "",
            returnValue: "",
            label_id: "",
            created_at: "",
          },
        ],
      },
    },
    initialErrors: {
      ruleset: {
        name: "",
        rules: [{}, {}, {}],
      },
    },
    initialTouched: {
      ruleset: {
        name: false,
        rules: [{}, {}, {}],
      },
    },
    onSubmit: async ({ ruleset }) => {
      const rulesArray = ruleset?.rules?.map((condition, index) => {
        // assign condition type to number on submit
        condition.type = "number"
        return {
          return_label: condition.label_id,
          name: `${ruleset.name}-${index + 1}`,
          uuid: condition?.uuid ?? "",
          created_at: condition?.created_at ?? "",
          condition: `(${parseConditionIntoString(condition)})`,
        }
      })
      if (mode.edit) {
        await updateRulesetMutation.mutateAsync({
          projectUUID: projectId as string,
          rulesetName: rulesetData?.data.name !== ruleset.name ? ruleset.name : undefined,
          rulesetUUID: rulesetUUID ?? currentRuleset?.uuid,
          rules: rulesArray,
          type: RuleSetCreateRequest.type.DECISION,
        })
      } else {
        await createRulesetMutation.mutateAsync({
          projectUUID: projectId as string,
          rulesetName: ruleset.name,
          rules: rulesArray,
          type: RuleSetCreateRequest.type.DECISION,
        })
      }
    },
  })

  const handleAddRule = (): void => {
    const rules = formik.values.ruleset.rules
    const newRule = {
      feature: "",
      operator: Operators.between,
      value: "",
      secondValue: "",
      returnValue: "",
      label_id: "",
      id: uuidv4(),
    }

    // Insert the new rule before the last item
    rules.splice(rules.length - 1, 0, newRule)

    // Update the formik values
    formik.setFieldValue("ruleset.rules", rules)
  }

  // switch edit mode to false if we have exiting rules
  useEffect(() => {
    if (rules?.length) {
      setMode({ create: false, edit: false })
    }
  }, [rules])

  // final indicator for readMode in condition
  const ruleInReadMode = !mode.edit && !mode.create

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"md"}
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("md"))}
      onClose={() => {
        onClose()
        setMode({ edit: false, create: mode.create })
      }}
    >
      <DialogTitle className="dialog-header-base" style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2-bold">Decision Ruleset</Typography>
        <IconButton
          onClick={() => {
            onClose()
            setMode({ edit: false, create: mode.create })
          }}
          size="small"
          className={"close-icon-button"}
        >
          <CloseIcon style={{ color: theme.palette.grayscale.text[2] }} />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.dialogContent}>
        <Grid container display={"flex"} flexDirection={"row"}>
          <Grid className={styles.decisionNodeDialog} display={"flex"} flexGrow={1}>
            <Grid
              className={styles.DecisionDialogSideBar}
              item
              container
              mr={2}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              flexGrow={1}
            >
              <RuleOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />
            </Grid>
            <Grid container item direction={"column"}>
              <Grid item container style={{ height: "fit-content" }} justifyContent={"space-between"}>
                <Grid item xs={4} container direction={"column"}>
                  <Grid item>
                    <Typography variant="label" variantColor={2}>
                      DECISION
                    </Typography>
                  </Grid>
                  <Grid item mt={0} container>
                    {ruleInReadMode ? (
                      <Typography variant="h3-bold">{formik.values.ruleset.name ?? rulesetName}</Typography>
                    ) : (
                      <InputText
                        type="text"
                        handleChange={formik.handleChange}
                        value={formik.values.ruleset.name}
                        disabled={formik.isSubmitting}
                        id={`ruleset.name`}
                        widthCalculator
                        placeholder={"Decision Name"}
                        hideDescription
                        error={
                          formik.touched.ruleset?.name &&
                          Boolean(formik.errors.ruleset?.name) &&
                          formik.errors.ruleset?.name
                        }
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid item mt={1} xs={4} justifyContent="flex-end" container alignSelf={"flex-start"}>
                  {mode.edit && (
                    <Button
                      style={{ marginRight: "8px" }}
                      onClick={() => {
                        setMode({ create: false, edit: false })
                        formik.setErrors({})
                      }}
                      disabled={formik.isSubmitting}
                      variant="secondary"
                    >
                      cancel
                    </Button>
                  )}
                  <Button
                    onClick={
                      mode.create || mode.edit ? formik.submitForm : () => setMode({ create: false, edit: true })
                    }
                    variant={mode.create || mode.edit ? "primary" : "secondary"}
                  >
                    {formik.isSubmitting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : mode.create && !currentRuleset ? (
                      "Confirm"
                    ) : mode?.edit ? (
                      "Save"
                    ) : (
                      "Edit"
                    )}
                  </Button>
                </Grid>
              </Grid>

              {/* Conditions*/}
              <FormikProvider value={formik}>
                <FieldArray name={"ruleset.rules"}>
                  {({ remove: levelRemove }) => (
                    <Grid container item mt={0.5} spacing={1}>
                      {formik?.values?.ruleset?.rules?.map((item, index: number) => (
                        <Condition
                          key={item?.id}
                          readMode={Boolean(readMode || currentRuleset)}
                          ruleInReadMode={ruleInReadMode}
                          formik={formik}
                          index={index}
                          item={item}
                          disableRule={formik.isSubmitting}
                          handleRemoveRule={() => levelRemove(index)}
                          mode={mode}
                          schema={workflowFormik?.values?.features ?? []}
                          workflowFormik={workflowFormik}
                        />
                      ))}

                      {/* Add Rule Button */}
                      {(mode.create || mode.edit) && (
                        <Tooltip
                          title={
                            formik?.values?.ruleset?.rules?.length > 7
                              ? "You have reached the maximum number of rules"
                              : ""
                          }
                          placement="top"
                          width={"fit-content"}
                        >
                          <MuiButton
                            disabled={formik?.values.ruleset?.rules?.length === 8 || formik.isSubmitting}
                            size="small"
                            onClick={() => handleAddRule()}
                            className={styles.MuiButton}
                          >
                            + Add Rule
                          </MuiButton>
                        </Tooltip>
                      )}
                    </Grid>
                  )}
                </FieldArray>
              </FormikProvider>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
