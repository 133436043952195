import React, { useMemo } from "react"

import CloseIcon from "@mui/icons-material/Close"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../../../../../../hooks/UseTheme"
import { ClassificationSetting } from "../../../../../../../types/generated/api/ClassificationSetting"
import { ModelSettingsSet } from "../../../../../../../types/generated/api/ModelSettingsSet"
import { ConfigurationSlider } from "./ModelConfiguration/components/ConfigurationSlider"

import styles from "../ModelConfigurations.module.scss"

type Props = {
  open: boolean
  onClose: () => void
  Configuration: ModelSettingsSet
}

/**
 * Configuration labels dialog
 * displays labels, min, max values
 * @param {string} open
 * @param {Dispatch} setClose
 * @param {ModelSettingsSet} Configuration
 * @return {React.ReactElement}
 */
export function ConfigurationLabelsDialog(props: Props): React.ReactElement {
  const { open, onClose, Configuration } = props

  const muiTheme = useMuiTheme()
  const theme = getTheme()

  // ordered labels and filtering boundary label from the list
  const orderedLabels = useMemo(() => {
    const labels = Configuration.settings?.sort((a, b) => a.lower_bound - b.lower_bound) ?? []

    const temp = labels.filter((item) => item.type !== "boundary")

    return temp
  }, [Configuration.settings])

  // extracted boundary label
  const boundary = useMemo((): ClassificationSetting | undefined => {
    if (orderedLabels.length === 0) {
      return undefined
    }

    const temp = Configuration.settings?.filter((item) => item.type === "boundary") ?? []

    return temp[0]
  }, [Configuration.settings, orderedLabels.length])

  // uncovered labels
  const uncovered = useMemo(() => {
    // Setting uncovered sliders
    const result: number[][] = []
    let type: "int" | "float" = "int"

    const tempSliders = [...orderedLabels]

    for (let i = 0; i < tempSliders.length; i++) {
      if (typeof tempSliders[i].lower_bound === "number" && !Number.isInteger(tempSliders[i].lower_bound)) {
        type = "float"
        // optimization: break after the type changes to float
        break
      }
    }

    // setting difference value given values type to better deduct the uncovered ranges
    const deductionValue = type === "int" ? 1 : 0.1

    if (boundary?.lower_bound !== undefined && boundary?.upper_bound !== undefined) {
      for (let i = 0; i < tempSliders.length; i++) {
        // if first index and difference between lower limit and lower boundary -> push the difference to uncovered array
        if (i === 0 && tempSliders[i].lower_bound - (boundary?.lower_bound as number) > deductionValue) {
          result.push([boundary?.lower_bound as number, tempSliders[i].lower_bound - deductionValue])
          // if non first or last -> calculate the difference if any and add it to uncovered array
        } else if (i > 0 && tempSliders[i].lower_bound - tempSliders[i - 1].upper_bound > deductionValue) {
          result.push([tempSliders[i - 1].upper_bound + deductionValue, tempSliders[i].lower_bound - deductionValue])
        }

        // if last index and difference between upper limit and upper boundary -> push the difference to uncovered array
        if (
          i === tempSliders.length - 1 &&
          (boundary?.upper_bound as number) - tempSliders[i].upper_bound > deductionValue
        ) {
          result.push([tempSliders[i].upper_bound + deductionValue, boundary?.upper_bound as number])
        }
      }
    }

    return result
  }, [boundary, orderedLabels])

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth="sm"
      fullScreen={useMediaQuery(muiTheme.breakpoints.down("md"))}
    >
      <DialogTitle className="dialog-header-base" style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2-bold">Configuration labels</Typography>
        <IconButton
          onClick={() => {
            onClose()
          }}
          size="small"
          className={"close-icon-button"}
        >
          <CloseIcon style={{ color: theme.palette.grayscale.text[2] }} />
        </IconButton>
      </DialogTitle>

      <DialogContent className="dialog-content-base">
        <Grid container>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="label"
                className={styles.labelColor}
                tooltipIconSize={16}
                tooltipVerticalAlign="middle"
              >
                Minimum
              </Typography>

              <Grid item className={styles.idbox} sx={{ width: "100px" }}>
                <Typography variant="p" noWrap className={styles.textIdBox}>
                  {boundary?.lower_bound}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="label"
                className={styles.labelColor}
                tooltipIconSize={16}
                tooltipVerticalAlign="middle"
              >
                Maximum
              </Typography>

              <Grid item className={styles.idbox} sx={{ width: "100px" }}>
                <Typography variant="p" noWrap className={styles.textIdBox}>
                  {boundary?.upper_bound}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={1} mt={2}>
            <Grid item xs={12}>
              <Typography variant="h3-bold">Labels</Typography>
            </Grid>

            <Grid container item spacing={2} direction="column" mb={1}>
              {orderedLabels.map((item, index) => {
                return (
                  <Grid item key={index}>
                    <ConfigurationSlider
                      label={item.label ?? "Slider has no label"}
                      min={boundary?.lower_bound as number}
                      max={boundary?.upper_bound as number}
                      range={[[item.lower_bound, item.upper_bound]]}
                      uncovered
                    />
                  </Grid>
                )
              })}
            </Grid>

            <Grid container item xs={12} mb={1}>
              {0 < uncovered.length && (
                <ConfigurationSlider
                  label="Uncovered"
                  min={boundary?.lower_bound as number}
                  max={boundary?.upper_bound as number}
                  range={uncovered}
                  uncovered
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
