import React, { Fragment, useMemo, useState } from "react"

import { useQuery } from "react-query"

import { Warning } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { isNumber } from "lodash"

import { InfoContainer } from "../../../../../../../components/InfoContainer"
import { KonanLogViewer } from "../../../../../../../components/KonanLogViewer"
import { ModelType } from "../../../../../../../components/ModelType"
import { ConfusionMatrix } from "../../../../../../../components/graphs/ConfusionMatrix"
import { getTheme } from "../../../../../../../hooks/UseTheme"
import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { ConfusionMatrixInput } from "../../../../../../../types/custom/projects"
import { AutoMLJobLogs } from "../../../../../../../types/generated/api/AutoMLJobLogs"
import { ClassificationSetting } from "../../../../../../../types/generated/api/ClassificationSetting"
import { RetrainingJobLogs } from "../../../../../../../types/generated/api/RetrainingJobLogs"

import styles from "../TrainingJobs.module.scss"

type MetricType = {
  metric_name: string
  metric_value: number
}

type Props = {
  open: boolean
  uuid: string
  projectUUID: string
  resultingModel: string
  evalMetrics: {
    test: MetricType[]
    train: MetricType[]
  }
  isAutoMl: boolean
  retrainingUUID?: string
  TrainingDataName: string
  dataSplit?: string
  points?: number
  onClose: () => void
  createdModelStatus?: string
  createdModelName?: string
}

function EvaluationDataCardLoader(): React.ReactElement {
  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start" className={"data-card"}>
      <Skeleton animation="wave" width={"100%"} />
      <Skeleton animation="wave" width={"100%"} />
    </Grid>
  )
}

type EvalProps = {
  title: string
  trainValue: number
  testValue: number
}
function EvaluationDataCard(props: EvalProps): React.ReactElement {
  const { title, trainValue, testValue } = props

  const metricValue = `${(trainValue as number)?.toFixed(2)} / ${(testValue as number)?.toFixed(2)}`
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      className={styles.dataCard}
    >
      {/* Title */}
      <Typography variant="p" noWrap className={styles.trainingText}>
        {title}
      </Typography>

      {/* Value */}
      <Typography variant="p" noWrap className={styles.trainingText}>
        {typeof trainValue === "number" && typeof testValue === "number" ? metricValue : trainValue / testValue}
      </Typography>
    </Grid>
  )
}

export function TrainingReportDialog(props: Props): React.ReactElement {
  const {
    open,
    onClose,
    createdModelName,
    createdModelStatus,
    projectUUID,
    uuid,
    dataSplit,
    points,
    TrainingDataName,
    resultingModel,
    evalMetrics,
    isAutoMl,
  } = props

  const theme = useTheme()
  const themeMode = getTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

  const [heatmapData, setHeatmapData] = useState<Array<ConfusionMatrixInput>>([])

  const { isLoading, data = { logs: [] } } = useQuery<RetrainingJobLogs, AxiosError>(
    ["retraining-logs", projectUUID, uuid],
    () => KonanAPI.fetchTrainingModelLogs(projectUUID, uuid),
    {
      enabled: !!projectUUID && open,
      refetchInterval: isAutoMl ? false : 10000,
    },
  )

  // fetch model configs
  const { data: modelConfigurations } = useQuery<AxiosResponse, AxiosError>(
    ["model-configurations", resultingModel],
    () => KonanAPI.fetchModelConfigurationsList(resultingModel),
    {
      enabled: !!resultingModel && isAutoMl,
    },
  )

  // fetch threshold metrics
  const { data: thresholdMetricsData, isLoading: isThresholdMetricsLoading } = useQuery<AxiosResponse>(
    ["threshold-metrics", resultingModel],
    () =>
      KonanAPI.fetchThresholdWithMetrics({
        projectUUID: projectUUID as string,
        modelUUID: resultingModel as string,
      }),
    {
      enabled: !!projectUUID && !!resultingModel && isAutoMl,
    },
  )

  const { isLoading: isTrainingAutomlLogsLoading, data: trainingAutomlLogs } = useQuery<AutoMLJobLogs, AxiosError>(
    ["training-automl-logs", projectUUID, uuid],
    () => KonanAPI.fetchAutoMLTrainingJobLogs(projectUUID, uuid),
    {
      enabled: !!projectUUID && open,
      refetchInterval: !isAutoMl ? false : 10000,
    },
  )

  const currentJobType = isAutoMl ? trainingAutomlLogs : data

  const extractedThresholdFromConfigurationsMemo = useMemo(() => {
    let threshold = 0
    if (modelConfigurations?.data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      modelConfigurations?.data?.results?.forEach((result: any) => {
        const activeConfig = result?.is_active
        if (activeConfig) {
          const setting = result?.settings?.find(
            (setting: ClassificationSetting) => setting?.upper_bound === 100 && setting?.type === "label",
          )
          threshold = setting?.lower_bound
        }
      })
    }
    return threshold
  }, [modelConfigurations])

  const extractedMetrcisBasedOnThresholdMemo = useMemo(() => {
    if (thresholdMetricsData?.data && extractedThresholdFromConfigurationsMemo) {
      const metricData = thresholdMetricsData?.data?.metrics?.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (metric: any) => metric?.threshold === extractedThresholdFromConfigurationsMemo,
      )

      // extracting AutoML model labels dynamically from the return
      // by checking the false values which should always be there
      // then extracting the values without the "false_" prefix
      const autoMlModelLabels = Object.keys(metricData)
        .filter((key) => key.startsWith("false_"))
        .map((key) => key.replace("false_", ""))

      // dynamically extracting metrics data using the labels stored in formik
      if (metricData) {
        const {
          [`false_${autoMlModelLabels[0]}`]: false_1,
          [`false_${autoMlModelLabels[1]}`]: false_0,
          [`true_${autoMlModelLabels[0]}`]: true_1,
          [`true_${autoMlModelLabels[1]}`]: true_0,
        } = metricData

        if (isNumber(false_1) && isNumber(false_0) && isNumber(true_1) && isNumber(true_0)) {
          const rows = [
            {
              id: "Positive",
              data: [
                { x: autoMlModelLabels[0], y: true_1 },
                { x: autoMlModelLabels[1], y: true_0 },
              ],
            },
            {
              id: "Negative",
              data: [
                { x: autoMlModelLabels[0], y: false_1 },
                { x: autoMlModelLabels[1], y: false_0 },
              ],
            },
          ]
          setHeatmapData(rows)
          return { false_1, false_0, true_1, true_0 }
        }
      }
    }
  }, [thresholdMetricsData?.data, extractedThresholdFromConfigurationsMemo])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" fullScreen={fullScreen}>
      <DialogContent className="dialog-content-base">
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item>
            <ModelType modelState={createdModelStatus} size="small" />
          </Grid>
          <Grid item>
            <IconButton
              style={{ color: themeMode.palette.gray.background[1] }}
              size="small"
              onClick={() => onClose()}
              className={"close-icon-button"}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="h1-bold">{createdModelName}</Typography>
        <Grid style={{ margin: "20px 0px" }}>
          <Typography variant="h2-bold">Training Report</Typography>
        </Grid>
        <Grid style={{ margin: "10px 0px" }}>
          <Typography variant="h3-bold">Overview</Typography>
        </Grid>
        <Grid container item direction="row" className={styles.trainingReport2BoxContainer}>
          <div className={styles.trainingReportLeftWidthLeft}>
            <Typography variant="label" className={styles.labelColor}>
              Training Data
            </Typography>
            <Grid container className={styles.idbox}>
              <Grid item xs={12}>
                <Typography style={{ wordWrap: "break-word" }} variant="p">
                  {TrainingDataName ? TrainingDataName : "data.csv"}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={styles.trainingReportLeftWidthMiddle}>
            <Typography variant="label" className={styles.labelColor}>
              Data Points
            </Typography>
            <Grid container className={styles.idbox}>
              <Grid item xs={12}>
                <Typography style={{ wordWrap: "break-word" }} variant="p">
                  {points ? points : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={styles.trainingReportLeftWidthRight}>
            <Typography variant="label" className={styles.labelColor}>
              Data Split
            </Typography>
            <Grid container className={styles.idbox}>
              <Typography variant="p" noWrap>
                {dataSplit ? dataSplit : "N/A"}
              </Typography>
            </Grid>
          </div>
        </Grid>
        {!isAutoMl && (
          <Fragment>
            <Grid container item direction="row">
              <Grid container item direction="row">
                <Grid item xs={12} sm={9}>
                  <Typography variant="label" className={styles.labelColor}>
                    Evaluation Metrics ( Train / Test )
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {!evalMetrics && !isLoading && (
              <Grid
                className={styles.addTrainingSection}
                container
                direction="row"
                justifyContent="space-between"
                item
                xs={12}
              >
                <Typography variant="span" align="center" className={styles.emptyPredictions}>
                  There are no evaluation tests in this model
                </Typography>
              </Grid>
            )}

            <Grid
              container
              direction="row"
              spacing={1}
              className={styles.horizontalScrollingTrainingDialog}
              marginBottom={2}
            >
              {isLoading &&
                [1, 2, 3].map((_, index: number) => (
                  <Grid item xs={4} key={index}>
                    <EvaluationDataCardLoader />
                  </Grid>
                ))}

              {!isLoading &&
                evalMetrics &&
                evalMetrics?.train?.length &&
                evalMetrics?.train?.map((ev: { metric_name: string; metric_value: number }, index) => (
                  <Grid
                    item
                    xs={evalMetrics?.train?.length === 1 ? 12 : evalMetrics?.train?.length === 2 ? 6 : 4}
                    className={styles.evalCard}
                    key={`${ev.metric_name}-${ev.metric_value}`}
                  >
                    <EvaluationDataCard
                      title={ev?.metric_name}
                      trainValue={ev?.metric_value}
                      testValue={evalMetrics?.test[index]?.metric_value}
                    />
                  </Grid>
                ))}
            </Grid>
          </Fragment>
        )}

        {isLoading || isTrainingAutomlLogsLoading ? (
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            marginBottom={0.5}
            marginTop={1}
            className={styles.logsGridHeight}
          >
            <InfoContainer icon={<CircularProgress />} title="Loading trainings Logs..." />
          </Grid>
        ) : currentJobType && currentJobType.logs !== null && currentJobType.logs?.length ? (
          <Grid container item direction="row" marginBottom={2}>
            <KonanLogViewer data={currentJobType.logs as string[]} isLoading={isLoading} projectUUID={projectUUID} />
          </Grid>
        ) : (
          <Grid container justifyContent="center" alignContent="center" className={styles.logsGridHeight}>
            <InfoContainer
              icon={<Warning fontSize="large" style={{ color: "var(--yellow-text-2" }} />}
              title="No trainings logs to display."
            />
          </Grid>
        )}
        <Grid item xs={12} lg={12} mt={3}>
          {isAutoMl && extractedMetrcisBasedOnThresholdMemo && heatmapData && (
            <ConfusionMatrix
              title="CONFUSION MATRIX"
              data={extractedMetrcisBasedOnThresholdMemo && heatmapData ? heatmapData : []}
              graphHeight={300}
              isLoading={isThresholdMetricsLoading}
            />
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
