import React, {
  ChangeEvent,
  Dispatch,
  Fragment,
  HTMLAttributes,
  LegacyRef,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { useDropzone } from "react-dropzone"
import { useMutation, useQueryClient } from "react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import { Box, Grid } from "@mui/material"
import { Button, InputText, NotificationUtils, Select, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FormikValues } from "formik"
import { v4 as uuidv4 } from "uuid"

import { FeaturesMenu } from "../../../../../../../components/FeaturesMenu"
import { RadioButtonsCondition } from "../../../../../../../components/RadioButtonsCondition"
import { UploadFileBlock } from "../../../../../../../components/UploadFileBlock"
import { useDebounce } from "../../../../../../../hooks/useDebounce"
import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { RootState } from "../../../../../../../store/ReduxStore"
import { Operators, UpdateWorkflowRequest } from "../../../../../../../types/custom/projects"
import { MapValueTypeToFeatureType, NewFeatureData, Rule, ScorecardRule } from "../../../../../../../types/custom/rules"
import { FeatureData, SchemaFeature } from "../../../../../../../types/custom/workflows"
import { ConditionListFile } from "../../../../../../../types/generated/api/ConditionListFile"
import { DataFile } from "../../../../../../../types/generated/api/DataFile"
import { WorkflowSchemaFeature } from "../../../../../../../types/generated/api/WorkflowSchemaFeature"
import {
  getFieldErrorIfTouched,
  getRuleWeightValue,
  handleFeatureChange,
  multiValuesOperators,
  operatorsBasedOnFeatureType,
} from "../../../../../../../utils/conditionHelpers"
import { dropZoneCSVExtensionValidator } from "../../../../../../../utils/genericHelpers"
import { isTypeIncluded } from "../../../../../../../utils/rulesetHelpers"
import { DataBlock } from "../../Ruleset/components/components/RuleCard"

import styles from "./Scorecard.module.scss"

interface AdornmentProps extends HTMLAttributes<HTMLSpanElement> {
  onClick: () => void
  anchorEl: HTMLElement | null
  fill?: string
}
export const ArrowAdornment = forwardRef(
  (props: AdornmentProps, ref: LegacyRef<HTMLSpanElement>): React.ReactElement => {
    const { onClick, anchorEl, fill } = props

    return (
      <span ref={ref} onClick={() => onClick()} style={{ pointerEvents: "none" }}>
        {!anchorEl ? (
          <svg
            fill={fill}
            className="adornment"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z"
              className="adornment"
            />
          </svg>
        ) : (
          <svg
            fill={fill}
            className="adornment"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 0.294922L0 6.29492L1.41 7.70492L6 3.12492L10.59 7.70492L12 6.29492L6 0.294922Z"
              className="adornment"
            />
          </svg>
        )}
      </span>
    )
  },
)

type Props = {
  isCreateMode: boolean
  isEditMode: boolean
  formik: FormikValues
  handleRemoveRule?: () => void
  id: string
  index: number
  scorecardIndex: number
  isScorecardLoading?: boolean
  conditionListFile: ConditionListFile
  ruleIndex: number
  conditionLevel: string
  removeCondition?: () => void
  setFileData: Dispatch<SetStateAction<{ name: string; uuid: string }>>
  fileData: { name: string; uuid: string }
  lastSwitchSetter?: Dispatch<SetStateAction<string>>
  filesCount: number
  setFilesCount: Dispatch<SetStateAction<number>>
  versionHistoryCondition?: Rule[]
  ruleType?: "simple" | "complex"
}

/**
 * single condition/rule component
 * @param {boolean} isCreateMode indicator if scorecard is in isCreateMode
 * @param {boolean} isEditMode indicator if scorecard is in isEditMode
 * @param {function} formik form control state handle
 * @param {function} handleRemoveRule handler function executes upon removing rule
 * @param {string} id id for condition so formik can track it
 * @param {number} index index for conditionArray
 * @param {number} scorecardIndex
 * @param {boolean} isScorecardLoading indicator for scorecard loading/currently updating or not
 * @returns {React.ReactElement}
 */
export function Condition({
  isCreateMode,
  isEditMode,
  formik,
  index,
  id,
  scorecardIndex,
  versionHistoryCondition,
  removeCondition,
  isScorecardLoading,
  conditionListFile,
  conditionLevel,
  fileData,
  setFileData,
  ruleIndex,
  filesCount,
  setFilesCount,
  ruleType,
}: Readonly<Props>): React.ReactElement {
  // variable contains all features (computed, schema)
  const schemaFeatures = useSelector((state: RootState) => state?.project?.schemaFeatures)
  const computedSchemaFeatures = useSelector((state: RootState) => state?.project?.computedFeatures)

  const workflowsList = useSelector((state: RootState) => state?.workflow?.workflows)

  // filtered features for LHS feature input
  const [filteredFeatures, setFilteredFeatures] = useState<Array<FeatureData>>(schemaFeatures ?? [])
  // filtered features for RHS feature input
  const [secondFilteredFeatures, setSecondFilteredFeatures] = useState<Array<FeatureData>>(schemaFeatures ?? [])
  // anchor for LHS feature
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // anchor for RHS feature
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null)
  const [selectedFeat, setSelectedFeat] = useState<string>("")

  // TODO: change later when we add helper function to detect initial operators based on exisiting feature type
  const [operators, setOperators] = useState<Operators[] | string[]>(operatorsBasedOnFeatureType.UNDEFINED)

  // ref for LHS feature
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>()
  // ref for RHS feature
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef2 = useRef<any>()

  const { id: projectID } = useParams<{ id: string }>()

  const queryClient = useQueryClient()

  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])

  const abortControllerRef = useRef<AbortController | null>(null)

  const condition = versionHistoryCondition
    ? versionHistoryCondition[index]
    : formik?.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex][conditionLevel][index]

  const { open, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted: (files) => {
      setAcceptedFiles([...acceptedFiles, ...files])
      if (
        (isEditMode || isCreateMode) &&
        multiValuesOperators.includes(
          Operators[
            formik?.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex][conditionLevel][index]
              .operator as keyof typeof Operators
          ],
        )
      ) {
        condition.value = "$file"
      }
    },
    accept: {
      "text/*": [".csv"],
    },
    validator: dropZoneCSVExtensionValidator,
  })

  // update a workflow
  const updateWorkflowMutation = useMutation<AxiosResponse, AxiosError, UpdateWorkflowRequest>(
    KonanAPI.updateWorkflow,
    {
      onSuccess: async (res) => {
        // success snackbar - feature added to schema
        NotificationUtils.toast("Feature added to schema successfully.", {
          snackBarVariant: "positive",
        })

        // invalidate queries
        await queryClient.invalidateQueries("workflows")
        await queryClient.invalidateQueries("workflow")
        await queryClient.invalidateQueries(["project", projectID as string])

        setFilteredFeatures(
          [...schemaFeatures, ...computedSchemaFeatures].filter((item: FeatureData) =>
            item?.name.includes(condition?.feature),
          ),
        )

        setSecondFilteredFeatures(
          [...schemaFeatures, ...computedSchemaFeatures].filter((item: FeatureData) =>
            item?.name.includes(condition?.secondFeature),
          ),
        )

        const currentFeat = res?.data?.schema?.features?.find(
          (feat: SchemaFeature) => feat?.name === condition?.feature,
        )

        if (currentFeat && currentFeat?.type === WorkflowSchemaFeature.type.BOOLEAN) {
          formik.setFieldValue(id + ".type", "true")
          setTypes(["true", "false"])
        }
      },

      onError: () => {
        NotificationUtils.toast("An error occurred while adding this feature to schema!", {
          snackBarVariant: "negative",
        })
      },
    },
  )

  const {
    isLoading: isCsvUploading,
    mutateAsync: uploadCSVAsyncMutation,
    reset,
  } = useMutation<DataFile, AxiosError, File>(
    (file: File) => {
      abortControllerRef.current = new AbortController()
      return KonanAPI.uploadDataFile({
        project_uuid: projectID as string,
        file: file,
        signal: abortControllerRef.current.signal,
        type: DataFile.type.CONDITION_LIST,
      })
    },
    {
      mutationKey: "uploadCSV",
      onSuccess: async (response) => {
        setFileData({ name: response.name, uuid: response.uuid })
        formik.setFieldValue(`scorecards[${scorecardIndex}].ruleCards[${ruleIndex}].condition_list_file`, {
          name: response?.name,
          uuid: response?.uuid,
        })

        setFilesCount(1)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (response: any) => {
        condition.value = ""

        if (response?.response?.data?.details) {
          NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
            snackBarVariant: "negative",
          })
        } else if (response?.response?.data?.data_file) {
          NotificationUtils.toast(response?.response?.data?.data_file[0], {
            snackBarVariant: "negative",
          })
        }
      },
    },
  )

  const deleteDataFileMutation = useMutation<AxiosResponse, AxiosError>(
    () => KonanAPI.deleteDataFile(projectID as string, fileData?.uuid),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Data File deleted successfully!", {
          snackBarVariant: "positive",
        })

        setFilesCount((prev) => (prev - 1 < 0 ? 0 : prev - 1))

        reset()
        setAcceptedFiles([])
        setFileData({ name: "", uuid: "" })
        formik.setFieldValue(`scorecards[${scorecardIndex}].ruleCards[${ruleIndex}].condition_list_file`, {
          name: "",
          uuid: "",
        })

        if (formik) {
          formik.setFieldValue(
            `scorecards[${scorecardIndex}].ruleCards[${ruleIndex}][${conditionLevel}][${index}].value`,
            "",
          )
        }
      },
      onError: () => {
        NotificationUtils.toast("An error occurred while deleting data file!", {
          snackBarVariant: "negative",
        })
      },
    },
  )

  // handle upload once files are accepted
  const handleUpload = async (): Promise<void> => {
    try {
      await uploadCSVAsyncMutation(acceptedFiles[0])

      // Invalidate training data to trigger a refresh
      await queryClient.invalidateQueries(["data-files", projectID])
    } catch (err) {
      // reset dropzone on error
      handleCloseAndAbort()
    }
  }

  // handle close dropzone
  const handleCloseAndAbort = useCallback(() => {
    abortControllerRef.current?.abort()
    reset()
    setAcceptedFiles([])
  }, [reset])

  // handle delete data file
  async function handleDeleteFile(): Promise<void> {
    if (isEditMode) {
      formik.setFieldValue(
        `scorecards[${scorecardIndex}].ruleCards[${ruleIndex}][${conditionLevel}][${index}].value`,
        "",
      )

      if (filesCount === 1 || formik?.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.type === "simple") {
        setFileData({ name: "", uuid: "" })

        formik.setFieldValue(`scorecards[${scorecardIndex}].ruleCards[${ruleIndex}].condition_list_file`, {
          name: "",
          uuid: "",
        })
      }

      setAcceptedFiles([])
      setFilesCount((prev) => (prev - 1 < 0 ? 0 : prev - 1))
    } else if (filesCount > 1) {
      setFilesCount((prev) => (prev - 1 < 0 ? 0 : prev - 1))
      formik.setFieldValue(
        `scorecards[${scorecardIndex}].ruleCards[${ruleIndex}][${conditionLevel}][${index}].value`,
        "",
      )
    } else {
      try {
        await deleteDataFileMutation.mutateAsync()
        await queryClient.invalidateQueries(["data-files", projectID])
      } catch (err) {
        // reset dropzone on error
        handleCloseAndAbort()
      }
    }
  }

  // special handler for changing type field and all side effects in specific cases
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    formik.setFieldValue(id + ".type", event.target.value)

    //When selecting true, false, or null from the menu -> set the value field to the selection
    if (isTypeIncluded(event?.target?.value)) {
      formik.setFieldValue(id + ".value", event.target.value)
      setOperators([Operators["!="], Operators["="]])
    }

    // whenever number type is selected, change the operators to include all numerical operators
    if (event.target.value === "number") {
      setOperators(Object.values(Operators).filter((v) => isNaN(Number(v))))
    }

    if (event.target.value === "string") {
      setOperators(operatorsBasedOnFeatureType.TEXT)
    }

    //When selecting number or string from the menu -> reset the value field
    else if (["string", "number"].includes(event.target.value) && !["string", "number"].includes(condition?.type)) {
      if (condition?.value === "$file") {
        handleDeleteFile()
      }

      formik.setFieldValue(id + ".value", "")
    }
  }

  // special handler for changing operator field and all side effects in specific cases
  const handleOperatorChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    formik.setFieldValue(id + ".operator", event.target.value)

    // reset the value field when selecting in/not in operators from the menu
    if (
      multiValuesOperators.includes(Operators[event.target.value as keyof typeof Operators]) &&
      !multiValuesOperators.includes(Operators[condition?.operator as keyof typeof Operators])
    ) {
      if (condition?.value === "$file" && fileData?.uuid) {
        handleDeleteFile()
      }
      formik.setFieldValue(id + ".value", "")
    }

    if (
      [Operators.between, ...multiValuesOperators].includes(Operators[event.target.value as keyof typeof Operators])
    ) {
      formik.setFieldValue(id + ".valueOrFeature", "Value")
    }

    if (
      condition?.value === "$file" &&
      fileData?.uuid &&
      !multiValuesOperators.includes(Operators[event.target.value as keyof typeof Operators]) &&
      multiValuesOperators.includes(Operators[condition?.operator as keyof typeof Operators])
    ) {
      handleDeleteFile()
    }

    // automatically switch value's type to number when these operators are active
    // -> "greater than", "greater than/equal", "lower than", "lower than/equal", "between"
    if (
      ["greater than", "greater than/equal", "lower than", "lower than/equal", "between"].includes(event.target.value)
    ) {
      formik?.setFieldValue(id + ".type", "number")
    }

    // automatically switch value's type to string when these operators are active
    // -> "in", "not in", "contains", "not contains"
    if (multiValuesOperators.includes(event.target.value)) {
      formik?.setFieldValue(id + ".type", "string")
    }
  }

  // starts uploading once the file is read
  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      handleUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  // handling if this file is first one so we upload or just reference the existing file
  const handleClickOnUploadIcon = (): void => {
    if ((!fileData.name && !fileData.uuid) || filesCount < 1) {
      open()
    } else {
      if (formik) {
        condition.value = "$file"
      }
      // TODO:: change this later to increment upon accepting the selected file not just on icon click
      setFilesCount((prev) => prev + 1)
    }
  }

  // this effect aims to set the fileData with the retrieved data file and update the filesCount
  // this effect should run when isEditMode (scorecard edit button) changes
  useEffect(() => {
    if (
      isEditMode &&
      condition?.value === "$file" &&
      formik.values.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.condition_list_file?.uuid
    ) {
      setFileData({
        name:
          formik.values.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.condition_list_file?.name || fileData?.name,
        uuid:
          formik.values.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.condition_list_file?.uuid ??
          conditionListFile?.uuid,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, formik.values.scorecards[scorecardIndex]?.ruleCards?.length])

  const operatorSizeCheck = (): boolean =>
    // check if the operator is one of ["equal", "not equal"]
    ["equal", "not equal"].includes(condition?.operator) &&
    condition?.valueOrFeature === "Value" &&
    (isEditMode || isCreateMode) &&
    (!currentFeature ||
      currentFeature?.type === WorkflowSchemaFeature.type.UNDEFINED ||
      currentFeature?.type === WorkflowSchemaFeature.type.BOOLEAN)

  const [types, setTypes] = useState<Array<string>>(["string", "number", "true", "false", "null"])

  // return value field type based on conditions
  const getValueFieldType = (): string => {
    return multiValuesOperators.includes(condition?.operator)
      ? "text"
      : condition?.type === "number"
        ? "number"
        : "text"
  }

  useEffect(() => {
    setFilteredFeatures([...(schemaFeatures ?? []), ...(computedSchemaFeatures ?? [])])
    setSecondFilteredFeatures([...(schemaFeatures ?? []), ...(computedSchemaFeatures ?? [])])
  }, [schemaFeatures, computedSchemaFeatures])

  const currentFeature = useMemo(() => {
    const feature = condition?.feature
    if ([...schemaFeatures, ...computedSchemaFeatures]?.length > 0 && feature) {
      return [...schemaFeatures, ...computedSchemaFeatures]?.find((feat) => feat.name === feature)
    }
  }, [computedSchemaFeatures, condition?.feature, schemaFeatures])

  const handleUpdateWorkflow = async (featureData: NewFeatureData, featureName: string): Promise<void> => {
    // Fetch single workflow
    const { data: workflowData } = await queryClient.fetchQuery(["workflow", projectID, featureData.workflow], () =>
      KonanAPI.fetchWorkflow(projectID as string, featureData.workflow),
    )

    const newFeat = {
      name: featureName?.replace(/\s/g, ""),
      type: featureData.type,
      is_required: featureData.isRequired,
      id: uuidv4(),
      source: "workflow",
      new: true,
    }

    if (workflowData?.active_version) {
      await updateWorkflowMutation.mutateAsync({
        name: workflowData?.name,
        projectUUID: projectID as string,
        workflowUUID: workflowData?.uuid,
        schema: [...(workflowData?.active_version?.schema?.features ?? []), newFeat],
      })
    }
  }

  const debouncedOnChangeLHS = useDebounce((): void => {
    const updatedConditionParts = handleFeatureChange(
      [...schemaFeatures, ...computedSchemaFeatures],
      condition?.feature,
      condition,
    )
    setFilteredFeatures(updatedConditionParts?.filteredFeatures as FeatureData[])
    setSecondFilteredFeatures(updatedConditionParts?.otherFilteredFeatures as FeatureData[])
    setOperators(updatedConditionParts?.operators)
    formik.setFieldValue(id + ".operator", updatedConditionParts?.operator)

    setTypes(updatedConditionParts?.types)

    formik.setFieldValue(id + ".type", updatedConditionParts?.type)

    formik.setFieldValue(id + ".value", updatedConditionParts?.value)

    setAnchorEl(inputRef?.current)
    setTimeout(() => {
      inputRef?.current?.focus()
    }, 100)
  }, 400)

  const debouncedOnChangeRHS = useDebounce((): void => {
    const updatedConditionParts = handleFeatureChange(
      [...schemaFeatures, ...computedSchemaFeatures],
      condition?.secondFeature,
      condition,
    )
    setFilteredFeatures(updatedConditionParts?.otherFilteredFeatures as FeatureData[])
    setSecondFilteredFeatures(updatedConditionParts?.filteredFeatures as FeatureData[])
    setOperators(updatedConditionParts?.operators)
    formik.setFieldValue(id + ".operator", updatedConditionParts?.operator)

    setTypes(updatedConditionParts?.types)

    formik.setFieldValue(id + ".type", updatedConditionParts?.type)

    formik.setFieldValue(id + ".value", updatedConditionParts?.value)

    setAnchorEl2(inputRef2?.current)
    setTimeout(() => {
      inputRef2?.current?.focus()
    }, 100)
  }, 400)

  const handleLHSFeatureChange = (event: ChangeEvent<Element> | React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
    formik?.handleChange(event)
    setAnchorEl(null)
    debouncedOnChangeLHS()
  }

  const handleFeatureSelectionFromMenu = (item: FeatureData, featureIndicator: "LHS" | "RHS"): void => {
    setSelectedFeat(item?.name)
    const updatedConditionParts = handleFeatureChange(
      [...schemaFeatures, ...computedSchemaFeatures],
      item?.name,
      condition,
    )

    setFilteredFeatures(updatedConditionParts?.filteredFeatures as FeatureData[])
    setSecondFilteredFeatures(updatedConditionParts?.otherFilteredFeatures as FeatureData[])
    setOperators(updatedConditionParts?.operators)
    formik.setFieldValue(id + ".operator", updatedConditionParts?.operator)

    setTypes(updatedConditionParts?.types)

    formik.setFieldValue(id + ".type", updatedConditionParts?.type)

    formik.setFieldValue(id + ".value", updatedConditionParts?.value)

    if (featureIndicator === "LHS") {
      setFilteredFeatures(updatedConditionParts?.filteredFeatures as FeatureData[])
      setSecondFilteredFeatures(updatedConditionParts?.otherFilteredFeatures as FeatureData[])
    } else {
      setFilteredFeatures(updatedConditionParts?.otherFilteredFeatures as FeatureData[])
      setSecondFilteredFeatures(updatedConditionParts?.filteredFeatures as FeatureData[])
    }
  }

  /**
   * indicator flag for displaying value/feature options (radio buttons options)
   */
  const shouldDisplayValueOrFeature =
    // 1. when scorecard in edit/create mode
    (isEditMode || isCreateMode) &&
    // 2. if operator is not in [in, not in, contains, not contains]
    !Operators.between !== condition?.operator &&
    // 3. if value type isn't in [true, false, null] OR equal to any of these values
    // but feature is empty or currentFeature not equal to boolean or undefined
    (!isTypeIncluded(condition?.type) ||
      (isTypeIncluded(condition?.type) &&
        currentFeature &&
        currentFeature?.type !== WorkflowSchemaFeature.type.BOOLEAN &&
        currentFeature?.type !== WorkflowSchemaFeature.type.UNDEFINED))

  /**
   * indicator flag for displaying type field
   */
  const shouldDisplayTypeField =
    // 1. when scorecard in edit/create mode
    (isEditMode || isCreateMode) &&
    // 2. when i'm not comparing between another feature
    condition?.valueOrFeature !== "Feature" &&
    // 3. when operator value is equal or not equal
    [Operators["!="], Operators["="]].includes(condition?.operator) &&
    // 4. if feature is empty or currentFeature undefined or the type of the currentFeature is undefined
    (condition?.feature === "" ||
      !currentFeature ||
      (currentFeature &&
        [WorkflowSchemaFeature.type.UNDEFINED, WorkflowSchemaFeature.type.BOOLEAN].includes(
          WorkflowSchemaFeature.type[currentFeature?.type as keyof typeof WorkflowSchemaFeature.type],
        )))

  /**
   * indicator flag for hiding value field
   */
  const shouldHideValueField =
    // 1. when scorecard in edit/create mode
    (isEditMode || isCreateMode) &&
    // 2. if value type is in [true, false, null] or equal to any of these values
    isTypeIncluded(condition?.type) &&
    // 3. if operator isn't equal to [contains, not contains, in, not in]
    !multiValuesOperators.includes(condition?.operator) &&
    // 4. if current feature type is either undefined or boolean and type is in [true, false, null]
    // or current feature not defined
    ((currentFeature &&
      [WorkflowSchemaFeature.type.UNDEFINED, WorkflowSchemaFeature.type.BOOLEAN].includes(
        WorkflowSchemaFeature.type[currentFeature?.type as keyof typeof WorkflowSchemaFeature.type],
      )) ||
      condition?.feature === "" ||
      !currentFeature)

  /**
   * inidicator for displaying the file block that contains the file name used in condition
   */
  const shouldDisplayFileBlock = Boolean(
    condition?.value === "$file" && multiValuesOperators.includes(condition?.operator) && (isEditMode || isCreateMode),
  )

  const getInitialNewFeatureType = (): WorkflowSchemaFeature.type => {
    if ([Operators.between, ...multiValuesOperators].includes(condition?.operator)) {
      return WorkflowSchemaFeature.type.LIST
    } else if (condition?.type === "true" || condition?.type === "false") {
      return WorkflowSchemaFeature.type.BOOLEAN
    } else if (condition?.type === "number") {
      return WorkflowSchemaFeature.type.NUMBER
    } else {
      return WorkflowSchemaFeature.type.TEXT
    }
  }

  const handleFeatureTypeChange = (value: string): void => {
    switch (value) {
      case WorkflowSchemaFeature.type.TEXT:
      case WorkflowSchemaFeature.type.UNDEFINED:
      case WorkflowSchemaFeature.type.DATE:
        if (
          (condition?.type === "true" && condition?.value === "true") ||
          (condition?.type === "false" && condition?.value === "false")
        ) {
          formik.setFieldValue(id + ".value", "")
        }

        formik.setFieldValue(id + ".type", "string")
        setOperators(operatorsBasedOnFeatureType.TEXT)
        formik.setFieldValue(id + ".operator", "equal")
        break

      case WorkflowSchemaFeature.type.BOOLEAN:
        formik.setFieldValue(id + ".type", "true")
        formik.setFieldValue(id + ".value", "true")
        setOperators([Operators["!="], Operators["="]])
        formik.setFieldValue(id + ".operator", "equal")
        break

      case WorkflowSchemaFeature.type.NUMBER:
        if (
          (condition?.type === "true" && condition?.value === "true") ||
          (condition?.type === "false" && condition?.value === "false")
        ) {
          formik.setFieldValue(id + ".value", "")
        }

        formik.setFieldValue(id + ".type", "number")
        setOperators(Object.values(Operators).filter((v) => isNaN(Number(v))))
        formik.setFieldValue(id + ".operator", "equal")
        break

      case WorkflowSchemaFeature.type.LIST:
        formik.setFieldValue(id + ".operator", "in")

        // reset the value field when selecting in/not in operators from the menu
        formik.setFieldValue(id + ".value", "")
        formik.setFieldValue(id + ".valueOrFeature", "Value")
        break
      default:
        break
    }
  }

  return (
    <Grid container item className={styles.conditionContainer} spacing={1}>
      <Grid item container alignItems="center" gap={1} xs={ruleType === "simple" ? 10 : 12}>
        {/* And/Or */}
        {versionHistoryCondition ? (
          index !== 0 ? (
            <Grid item lg={1}>
              <DataBlock value={versionHistoryCondition[index]?.andOr} />
            </Grid>
          ) : (
            index === 0 &&
            versionHistoryCondition?.length > 1 && (
              <Grid item lg={1} mt={1.5}>
                <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                  <Typography variant="p">If</Typography>
                </Box>
              </Grid>
            )
          )
        ) : index !== 0 ? (
          <Grid item lg={1.5}>
            {(isEditMode || isCreateMode) && index === 1 ? (
              <Select
                id={id + ".andOr"}
                type="text"
                value={formik?.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex][conditionLevel][0]?.andOr}
                handleBlur={formik.handleBlur}
                handleChange={(e) => {
                  formik.handleChange(e)
                  formik.setFieldValue(
                    `scorecards[${scorecardIndex}].ruleCards[${ruleIndex}][${conditionLevel}][0].andOr`,
                    e.target.value,
                  )
                }}
                hideDescription
                menuProps={{ menuMaxContent: true }}
                fullWidth
                disabled={formik?.isSubmitting}
                options={["and", "or"]}
              />
            ) : (
              <DataBlock
                value={formik?.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex][conditionLevel][0]?.andOr}
              />
            )}
          </Grid>
        ) : (
          index === 0 &&
          formik?.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.type === "complex" && (
            /* If */
            <Grid item lg={1.5} mt={1.5}>
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Typography variant="p">If</Typography>
              </Box>
            </Grid>
          )
        )}

        {/* Feature */}
        <Grid item xs={isEditMode || isCreateMode ? 5.5 : 5} md={isEditMode || isCreateMode ? 3.1 : 2.9}>
          {isEditMode || isCreateMode ? (
            <div>
              <InputText
                hideDescription
                anchoringRef={inputRef}
                id={id + ".feature"}
                placeholder="Feature"
                //key={`${selectedFeat}_${formik?.values?.scorecards?.length}_${formik?.values?.scorecards[scorecardIndex]?.uuid}`}
                endAdornment={
                  [...schemaFeatures, ...computedSchemaFeatures]?.length > 0 ? (
                    <ArrowAdornment
                      anchorEl={anchorEl}
                      onClick={() => setAnchorEl(!!anchorEl ? null : inputRef?.current)}
                    />
                  ) : undefined
                }
                handleChange={handleLHSFeatureChange}
                handleBlur={formik.handleBlur}
                value={condition?.feature}
                error={getFieldErrorIfTouched(
                  formik?.errors,
                  formik?.touched,
                  `scorecards.${scorecardIndex}.ruleCards.${ruleIndex}.${conditionLevel}.${index}.feature`,
                )}
                disabled={formik.isSubmitting || isScorecardLoading}
                fullWidth
              />
              {/* TODO:: check if we need to remove debouncer after revamp */}
              {/* as far as i remember it was made to accommodate MUI, so maybe we dont need it -> 9/2/2023 */}
              <FeaturesMenu
                filteredFeatures={filteredFeatures}
                key={`${filteredFeatures?.length}`}
                workflowsList={workflowsList}
                anchorEl={anchorEl}
                queryKey={condition?.feature}
                handleFeatureTypeChange={(value: string) => handleFeatureTypeChange(value)}
                onAddingNewFeature={(featureData) => {
                  setAnchorEl(null)
                  handleUpdateWorkflow(featureData, condition?.feature)
                }}
                onSelectingFeature={(item) => {
                  formik.setFieldValue(id + ".feature", item?.name)
                  handleFeatureSelectionFromMenu(item, "LHS")
                  setAnchorEl(null)
                }}
                onMenuClose={() => setAnchorEl(null)}
                initialFeatureType={getInitialNewFeatureType}
              />
            </div>
          ) : (
            <DataBlock value={condition?.feature} />
          )}
        </Grid>

        {/* Operator */}
        <Grid
          item
          lg={operatorSizeCheck() ? 1.9 : true}
          md={operatorSizeCheck() ? 2 : true}
          xs={operatorSizeCheck() ? 3 : true}
        >
          {isEditMode || isCreateMode ? (
            <Select
              id={id + ".operator"}
              disabled={isScorecardLoading}
              type="text"
              handleBlur={formik.handleBlur}
              value={condition?.operator}
              handleChange={handleOperatorChange}
              fullWidth
              menuProps={{ menuMaxContent: true }}
              options={operators}
              hideDescription
            />
          ) : (
            <DataBlock value={condition?.operator} />
          )}
        </Grid>

        {/* Type */}
        {shouldDisplayTypeField && (
          <Grid item xs={isEditMode || isCreateMode ? 3.5 : "auto"} md={isTypeIncluded(condition?.type) ? true : 1.9}>
            {isEditMode || isCreateMode ? (
              <Select
                id={id + ".type"}
                menuProps={{ menuMaxContent: true }}
                fullWidth
                disabled={isScorecardLoading}
                key={condition?.id}
                type="text"
                handleBlur={formik.handleBlur}
                value={condition?.type}
                handleChange={(e) => handleTypeChange(e)}
                options={types}
                hideDescription
              />
            ) : (
              <DataBlock value={condition?.type} />
            )}
          </Grid>
        )}

        {/* Feature/value radio buttons*/}
        {shouldDisplayValueOrFeature && (
          <RadioButtonsCondition
            handleOnChange={(e) => {
              formik.setFieldValue(id + ".valueOrFeature", e.target.value)

              setOperators(
                operatorsBasedOnFeatureType[
                  MapValueTypeToFeatureType[condition?.type as keyof typeof MapValueTypeToFeatureType]
                ],
              )
            }}
            checkedFeat={condition?.valueOrFeature === "Feature"}
            checkedValue={condition?.valueOrFeature === "Value"}
            isDisabled={formik?.isSubmitting}
          />
        )}

        {/* Double values*/}
        {condition?.operator === "between" ? (
          <Grid container item xs>
            <Grid gap={0.5} item display="flex" justifyContent="space-between" width="100%">
              <Grid item xs>
                {isCreateMode || isEditMode ? (
                  <InputText
                    hideDescription
                    id={id + ".value"}
                    key={condition?.id}
                    placeholder="Value"
                    value={condition?.value}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    error={getFieldErrorIfTouched(
                      formik?.errors,
                      formik?.touched,
                      `scorecards.${scorecardIndex}.ruleCards.${ruleIndex}.${conditionLevel}.${index}.value`,
                    )}
                    disabled={formik.isSubmitting || isScorecardLoading}
                    fullWidth
                    type="number"
                  />
                ) : (
                  <DataBlock value={condition?.value} />
                )}
              </Grid>

              {/* And */}
              <Grid item width={"fit-content"} mt={isCreateMode || isEditMode ? 0.5 : 0} className="and-block">
                <Typography variant="h3-bold" variantColor={1} color="neutral">
                  And
                </Typography>
              </Grid>

              <Grid item xs>
                {isCreateMode || isEditMode ? (
                  <InputText
                    hideDescription
                    id={id + ".secondValue"}
                    key={condition?.id}
                    placeholder="Value"
                    value={condition?.secondValue}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    error={getFieldErrorIfTouched(
                      formik?.errors,
                      formik?.touched,
                      `scorecards.${scorecardIndex}.ruleCards.${ruleIndex}.${conditionLevel}.${index}.secondValue`,
                    )}
                    disabled={formik.isSubmitting || isScorecardLoading}
                    fullWidth
                    type="number"
                  />
                ) : (
                  <DataBlock value={condition?.secondValue} />
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            xs
            //md={isEditMode ? (multiValuesOperators.includes(condition?.operator) ? 3 : 2) : 3.5}
            // Important: hide this field using display: "none" in order NOT to lose access to this element by id,
            // as the id of this field is used by the formik object
            key={condition?.id}
            style={shouldHideValueField ? { display: "none" } : undefined}
          >
            {shouldDisplayFileBlock ? (
              <UploadFileBlock
                onDelete={handleDeleteFile}
                fileName={
                  fileData?.name ||
                  formik?.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.condition_list_file?.name
                }
                isUploading={isCsvUploading}
                handleCancel={handleCloseAndAbort}
                isDeleting={deleteDataFileMutation.isLoading}
                editMode={isEditMode}
                createMode={isCreateMode}
                isDisabled={formik?.isSubmitting}
              />
            ) : (isEditMode || isCreateMode) && condition?.valueOrFeature === "Feature" ? (
              <div>
                <InputText
                  hideDescription
                  id={id + ".secondFeature"}
                  key={`${selectedFeat}_${formik?.values?.scorecards?.length}_${formik?.values?.scorecards[scorecardIndex]?.uuid}`}
                  placeholder="Feature"
                  anchoringRef={inputRef2}
                  value={condition?.secondFeature}
                  endAdornment={
                    [...schemaFeatures, ...computedSchemaFeatures]?.length > 0 ? (
                      <ArrowAdornment
                        anchorEl={anchorEl2}
                        onClick={() => setAnchorEl2(!!anchorEl2 ? null : inputRef2?.current)}
                      />
                    ) : undefined
                  }
                  handleChange={(e) => {
                    formik?.handleChange(e)
                    setAnchorEl2(null)
                    debouncedOnChangeRHS()
                  }}
                  handleBlur={formik.handleBlur}
                  error={getFieldErrorIfTouched(
                    formik?.errors,
                    formik?.touched,
                    `scorecards.${scorecardIndex}.ruleCards.${ruleIndex}.${conditionLevel}.${index}.secondFeature`,
                  )}
                  disabled={formik.isSubmitting || isScorecardLoading}
                  fullWidth
                />
                <FeaturesMenu
                  filteredFeatures={secondFilteredFeatures}
                  workflowsList={workflowsList}
                  anchorEl={anchorEl2}
                  queryKey={condition?.secondFeature}
                  onAddingNewFeature={(featureData) => {
                    setAnchorEl2(null)
                    handleUpdateWorkflow(featureData, condition?.secondFeature)
                  }}
                  onSelectingFeature={(item) => {
                    formik.setFieldValue(id + ".secondFeature", item?.name)
                    handleFeatureSelectionFromMenu(item, "RHS")
                    setAnchorEl2(null)
                  }}
                  onMenuClose={() => setAnchorEl2(null)}
                />
              </div>
            ) : isEditMode || isCreateMode ? (
              /* Value */
              <InputText
                hideDescription
                id={id + ".value"}
                //key={formik.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.id}
                placeholder="Value"
                value={condition?.value}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                endAdornment={
                  !isCsvUploading &&
                  !deleteDataFileMutation.isLoading &&
                  multiValuesOperators.includes(Operators[condition?.operator as keyof typeof Operators]) ? (
                    <Fragment>
                      {/* must add this input to make it work in safari*/}
                      <input {...getInputProps()} />
                      <FileUploadOutlinedIcon
                        onClick={handleClickOnUploadIcon}
                        style={{ color: "var(--grayscale-text-2)" }}
                        fontSize="small"
                      />
                    </Fragment>
                  ) : undefined
                }
                error={getFieldErrorIfTouched(
                  formik?.errors,
                  formik?.touched,
                  `scorecards.${scorecardIndex}.ruleCards.${ruleIndex}.${conditionLevel}.${index}.value`,
                )}
                disabled={formik.isSubmitting || isScorecardLoading}
                fullWidth
                type={getValueFieldType()}
              />
            ) : (
              <DataBlock
                value={
                  condition?.value === "$file"
                    ? formik?.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.condition_list_file?.name ??
                      conditionListFile?.name
                    : condition?.value
                }
                isLoading={false}
              />
            )}
          </Grid>
        )}

        {/* Remove button */}
        {(isEditMode || isCreateMode) &&
          !(index === 0 && conditionLevel === "levelOneConditions") &&
          formik.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.type === "complex" && (
            <Grid display="flex" item alignSelf="center">
              <Button
                variant="dangerous"
                size="small"
                onClick={() => {
                  if (
                    multiValuesOperators.includes(Operators[condition?.operator as keyof typeof Operators]) &&
                    condition?.value === "$file"
                  ) {
                    handleDeleteFile()
                  }
                  removeCondition?.()
                }}
                disabled={formik?.isSubmitting}
              >
                <RemoveCircleOutlineIcon fontSize="inherit" />
              </Button>
            </Grid>
          )}
      </Grid>

      {ruleType === "simple" && (
        <Grid item flexGrow={1} container display="flex" xs alignSelf="flex-end">
          {/* = */}
          <Grid alignSelf="flex-end" item xs={"auto"} marginRight={1} marginTop={isEditMode || isCreateMode ? 2.5 : 1}>
            <Typography variant="a">{"="}</Typography>
          </Grid>
          {/* Rule weight */}
          <Grid
            key={isCreateMode ? scorecardIndex : condition}
            item
            xs
            display={isEditMode || isCreateMode ? "flex" : "block"}
            flexGrow={1}
          >
            {isEditMode || isCreateMode ? (
              <InputText
                hideDescription
                id={`scorecards[${scorecardIndex}].ruleCards[${ruleIndex}].return_value`}
                key={formik.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.id}
                placeholder="Rule Weight"
                value={getRuleWeightValue(
                  formik?.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.return_value,
                )}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                error={getFieldErrorIfTouched(
                  formik?.errors,
                  formik?.touched,
                  `scorecards.${scorecardIndex}.ruleCards.${ruleIndex}.return_value`,
                )}
                disabled={formik.isSubmitting || isScorecardLoading}
                type="number"
                fullWidth
              />
            ) : (
              <DataBlock
                value={`${parseFloat(formik?.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.return_value)}`}
              />
            )}
          </Grid>
        </Grid>
      )}

      {/* Remove button */}
      {(isEditMode || isCreateMode) &&
        formik.values?.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.type === "simple" &&
        ((formik.values.scorecards[scorecardIndex]?.ruleCards[ruleIndex]?.levelOneConditions?.length === 1 &&
          formik.values.scorecards[scorecardIndex]?.ruleCards?.length > 1) ||
          index !== 0 ||
          conditionLevel === "levelTwoConditions") && (
          <Grid display="flex" item alignSelf="center">
            <Button
              variant="dangerous"
              size="small"
              onClick={() => {
                const newRules = formik?.values?.scorecards[scorecardIndex]?.ruleCards?.filter(
                  (_: ScorecardRule, idx: number) => idx !== ruleIndex,
                )

                formik?.setFieldValue(`scorecards[${scorecardIndex}].ruleCards`, newRules)
              }}
              disabled={formik?.isSubmitting}
            >
              <RemoveCircleOutlineIcon fontSize="inherit" />
            </Button>
          </Grid>
        )}
    </Grid>
  )
}
