import React, { Fragment, useEffect, useState } from "react"

import { useMutation } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"

import * as Yup from "yup"
import { Box, CircularProgress, Grid, Tab, Tabs, useMediaQuery } from "@mui/material"
import { CredentialResponse, GoogleLogin } from "@react-oauth/google"
import { Button, InputPassword, InputText, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"
import { jwtDecode } from "jwt-decode"

import { SidAPI } from "../../../services/SidAPI"
import { CustomTokenObtainPair } from "../../../types/generated/authentication/CustomTokenObtainPair"
import { TokenObtainPairResponse } from "../../../types/generated/authentication/TokenObtainPairResponse"
import { Auth } from "../../../utils/auth"
import { EntryDisclaimer } from "./components/EntryDisclaimer"
import { Verification } from "./components/VerificationScreen"

import styles from "../Entry.module.scss"

// props and LocationProps are used when an unauthorized user is trying to enter the application to a specific location
type Props = {
  redirectPath: string
}

type LocationProps = {
  state: {
    from: Location
  }
}

// Yup validation schema object
const validationSchema = Yup.object({
  email: Yup.string().email("Email must be valid").trim().required("Email is required"),
  password: Yup.string().required("Password is required"),
})

/**
 * Login form component
 * @param  {string} redirectPath path the user is trying to go to
 * @return  {<Login />}
 */
export function Login(props: Readonly<Props>): React.ReactElement {
  const { redirectPath } = props

  const navigate = useNavigate()
  const location = useLocation() as LocationProps

  const [entryTab, setEntryTab] = useState<"login" | "signup">("login")

  const [isGoogleLoginError, setisGoogleLoginError] = useState<boolean>(false)
  const [isGoogleAccVerified, setIsGoogleAccVerified] = useState<boolean>(true)
  const [isGoogleLoginFailure, setIsGoogleLoginFailure] = useState<boolean>(false)

  const breakpointMatch = useMediaQuery("(max-width:400px)")

  const redirectToPath = location?.state?.from?.pathname || redirectPath

  // Request mutations
  const loginMutation = useMutation<AxiosResponse<TokenObtainPairResponse>, AxiosError, CustomTokenObtainPair>(
    SidAPI.login,
  )

  const resendVerificationMutation = useMutation<AxiosResponse<void>, AxiosError, string>(SidAPI.resendVerificationLink)

  const googleloginMutation = useMutation<AxiosResponse<TokenObtainPairResponse>, AxiosError, string>(
    SidAPI.googleLogin,
  )

  const onGoogleLoginSuccess = async (credentialResponse: CredentialResponse): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let response: any

    // Checks if google account is verified before login/SignUp
    // if account isn't verified login isn't attempted
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((jwtDecode(credentialResponse.credential as string) as any).email_verified === false) {
      setIsGoogleAccVerified(false)
      return
    }

    // Tries to login, if it fails it signs the user up
    try {
      response = await googleloginMutation.mutateAsync(credentialResponse.credential as string)
    } catch (error) {
      try {
        response = await SidAPI.googleSignup(credentialResponse.credential as string)
      } catch (error) {
        setIsGoogleLoginFailure(true)
        return
      }
    }

    // Logs the user in after login/SignUp
    const data = response.data
    await Auth.login(data.access, data.refresh)
    navigate(redirectToPath, { replace: true })
  }

  // Login form control
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ email, password }, { resetForm }) => {
      const response = await loginMutation.mutateAsync({ email, password })

      await Auth.login(response.data.access, response.data.refresh)

      resetForm({})

      // navigate based on the url the user tried to access with
      if (location?.state?.from) {
        navigate(location?.state?.from, { replace: true })
      } else {
        navigate(redirectPath, { replace: true })
      }
    },
  })

  // logs user in automatically if user is authenticated
  useEffect(() => {
    if (Auth.isAuthenticated()) {
      navigate(redirectToPath, { replace: true })
    }
  }, [navigate, redirectToPath])

  return (
    <Grid>
      {/* success message. if user logs in && isn't verified and resend a verification email */}
      {loginMutation.isSuccess ||
        (resendVerificationMutation.isSuccess && (
          <Snackbar variant="positive" fullWidth description="Verification link has been sent to your email" />
        ))}

      {/* If user inst verified and logs is, gets navigated to Verification screen else login screen */}
      {loginMutation?.error?.response?.status === 403 || googleloginMutation.error?.response?.status === 403 ? (
        <Verification email={formik.values.email} />
      ) : (
        <Fragment>
          <Grid container justifyContent="center" className={styles.containerWidth}>
            {/* Network error; shown if client cant reach api for any reason,
            or failed login prompt */}
            {loginMutation.isError && loginMutation?.error?.response?.status !== 403 && (
              <Grid item xs={12}>
                <Snackbar
                  variant="negative"
                  fullWidth
                  description={
                    loginMutation.error?.message === "Network Error"
                      ? "Network Error. Check your internet connection."
                      : "Invalid email or password."
                  }
                />
                <Box mt={2} />
              </Grid>
            )}

            {/**
             * Google Login errors
             * 1. google login error
             * 2. google account isn't verified
             * 3. google signup failure for an unregistered account trying to login using google
             */}
            {isGoogleLoginError && (
              <Grid item xs={12}>
                <Snackbar
                  variant="negative"
                  fullWidth
                  description="Failed to sign in with google, please try again later."
                />
                <Box mt={2} />
              </Grid>
            )}

            {!isGoogleAccVerified && (
              <Grid item xs={12}>
                <Snackbar
                  variant="negative"
                  fullWidth
                  description="Google account isn't verified, please verify your account and try again"
                />
                <Box mt={2} />
              </Grid>
            )}

            {isGoogleLoginFailure && (
              <Grid item xs={12}>
                <Snackbar
                  variant="negative"
                  fullWidth
                  description="Login with google failed please try again later or fill up the login form"
                />
                <Box mt={2} />
              </Grid>
            )}
          </Grid>

          <Grid container className={styles.signupContainer} justifyContent="center">
            <Grid item xs={12}>
              <Tabs
                value={entryTab}
                onChange={(_e, value: "login" | "signup") => {
                  setEntryTab(value)
                }}
                indicatorColor="primary"
                textColor="primary"
                className={"entry-tabs"}
              >
                <Tab label="login" value="login" className={"entry-tab"} />
                <Tab
                  label="Create account"
                  value="signup"
                  className={"entry-tab"}
                  onClick={() => {
                    navigate("/register")
                  }}
                />
              </Tabs>
            </Grid>

            <Grid item xs={12} padding={3}>
              <Typography variant="h2-bold" gutterBottom={false}>
                Welcome back
              </Typography>
            </Grid>

            {/* Login form */}
            <Grid container item xs={12} paddingX={3} paddingTop={0}>
              <Grid item xs={12}>
                <InputText
                  fullWidth
                  id="email"
                  label="Email"
                  placeholder="name@example.com"
                  error={Boolean(formik.errors.email) && formik.touched.email && formik.errors.email}
                  value={formik.values.email}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  required
                  disabled={formik.isSubmitting}
                />
              </Grid>

              <Grid item xs={12} paddingTop={2}>
                <InputPassword
                  fullWidth
                  id="password"
                  error={Boolean(formik.errors.password) && formik.touched.password && formik.errors.password}
                  value={formik.values.password}
                  handleChange={formik.handleChange}
                  handleHelperClick={() => navigate("/reset-password")}
                  handleBlur={formik.handleBlur}
                  disabled={formik.isSubmitting}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className={styles.loginButton}>
              <Button
                onClick={() => formik.submitForm()}
                variant="primary"
                disabled={formik.isSubmitting || !(formik.isValid || formik.dirty)}
                type="submit"
                fullWidth
              >
                {formik.isSubmitting ? <CircularProgress color="inherit" size={20} /> : "Login"}
              </Button>

              <hr className={styles.divider} />

              <GoogleLogin
                width={breakpointMatch ? 252 : 312}
                theme="outline"
                size="medium"
                text="signin_with"
                onSuccess={onGoogleLoginSuccess}
                onError={() => {
                  setisGoogleLoginError(true)
                }}
                auto_select={false}
                locale="en"
              />
            </Grid>
          </Grid>

          <Box mt={1} />
          <EntryDisclaimer type="signin" />
        </Fragment>
      )}
    </Grid>
  )
}
