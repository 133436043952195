import React, { Fragment, useMemo, useState } from "react"

import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery } from "react-query"
import { useParams } from "react-router-dom"

import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"
import { Grid } from "@mui/material"
import { Button } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { KonanEmptyState, KonanEmptyStateFilter } from "../components/KonanEmptyState"
import { KonanPageHeader } from "../components/KonanPageHeader"
import { KonanSubHeader } from "../components/KonanSubHeader"
import { ScriptCard, ScriptLoadingCard } from "../features/Scripts"
import { BuiltinScriptDialog } from "../features/Scripts/BuiltinScriptDialog"
import { ScriptDialog } from "../features/Scripts/ScriptDialog"
import { KonanAPI } from "../services/KonanAPI"
import { PaginatedScriptGroupListList } from "../types/generated/api/PaginatedScriptGroupListList"
import { ScriptGroupList } from "../types/generated/api/ScriptGroupList"
import { extractPageFromBackEndPaginationLink } from "../utils/genericHelpers"
import { filterListByState, sortList } from "../utils/searchSortFilterHelpers"

/**
 * A component that manages the display of scripts using an infinite scroll implementation.
 * It allows the user to filter, sort, and search through scripts. The component provides
 * interactions to create new scripts or use existing templates through dialog components.
 *
 * The component handles various states for loading and fetching scripts, as well as dialog
 * states for creating and managing scripts. It fetches scripts dynamically as the user scrolls,
 * making use of the `useInfiniteQuery` from react-query for data fetching and pagination.
 *
 * @returns {React.ReactElement} The Scripts component that includes the search, filter, sorting UI,
 * and an infinite scrolling list of ScriptCard components. It dynamically opens dialogs for script
 * creation and template usage based on user interactions.
 */
export function Scripts(): React.ReactElement {
  const { id: projectId } = useParams<{ id: string }>()

  // SSF states
  const [searchValue, setSearchValue] = useState<string>("")
  const [filterValue, setFilterValue] = useState<string[]>([])
  const [sortValue, setSortValue] = useState<string>("Most Recent")

  // Open dialogs states
  const [isScriptDialogOpen, setIsScriptDialogOpen] = useState<boolean>(false)
  const [isBuiltinScriptDialogOpen, setIsBuiltinScriptDialogOpen] = useState<boolean>(false)

  const {
    data: scripts,
    isLoading: isScriptsLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<AxiosResponse<PaginatedScriptGroupListList>, AxiosError>(
    ["scripts", projectId, searchValue],
    ({ pageParam = 1 }) => KonanAPI.fetchScripts(projectId as string, pageParam, 21, searchValue),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.data?.next ? extractPageFromBackEndPaginationLink(lastPage.data?.next) : false
      },
      enabled: !!projectId,
      refetchOnMount: true,
    },
  )

  // extracting the results after infinite-Query
  const adjustedScripts = useMemo(() => {
    let concatenatedResults = scripts?.pages.reduce((accumulator, page) => {
      return accumulator.concat(page.data.results as ScriptGroupList[])
    }, [] as ScriptGroupList[])

    if (sortValue && concatenatedResults && concatenatedResults?.length > 0) {
      concatenatedResults = sortList(concatenatedResults, sortValue, "scripts")
    }

    if (filterValue && filterValue?.length > 0 && concatenatedResults && concatenatedResults?.length > 0) {
      concatenatedResults = filterListByState(concatenatedResults, filterValue, "scripts")
    }

    return concatenatedResults
  }, [filterValue, scripts?.pages, sortValue])

  return (
    <Fragment>
      {isScriptDialogOpen && (
        <ScriptDialog isOpen onClose={() => setIsScriptDialogOpen(false)} isNewScriptTriggered renderView="scripts" />
      )}

      {isBuiltinScriptDialogOpen && (
        <BuiltinScriptDialog
          isOpen
          renderView="scripts"
          isNewScriptTriggered
          onClose={() => setIsBuiltinScriptDialogOpen(false)}
        />
      )}

      <Grid container>
        <KonanPageHeader title="Scripts" />
        {isScriptsLoading ||
        (!isScriptsLoading &&
          ((adjustedScripts && adjustedScripts?.length > 0) || !!searchValue || !!filterValue.length)) ? (
          <Fragment>
            <Grid item container mt={1.5}>
              <KonanSubHeader
                searchValue={searchValue}
                isDebouncedInput
                setSearchValue={setSearchValue}
                sortValue={sortValue}
                setSortValue={setSortValue}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                filterOptions={["Custom", "Built-in"]}
                containerType="Script"
                sortOptions={["Most Recent", "Name"]}
                actions={[
                  <Button
                    onClick={() => setIsBuiltinScriptDialogOpen(true)}
                    startIcon={
                      <Inventory2OutlinedIcon style={{ fontSize: "16px", display: "flex", alignSelf: "center" }} />
                    }
                    variant="secondary"
                    size="regular"
                  >
                    Use Template
                  </Button>,
                  <Button size="regular" variant="primary" onClick={() => setIsScriptDialogOpen(true)}>
                    + New Script
                  </Button>,
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              {isScriptsLoading ? (
                <Grid container spacing={2}>
                  {[1, 2, 3].map((item: number) => (
                    <Grid item xs={12} md={6} lg={4} key={item}>
                      <ScriptLoadingCard />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <InfiniteScroll
                  dataLength={adjustedScripts?.length ?? 0}
                  next={() => !isScriptsLoading && !isFetchingNextPage && fetchNextPage()}
                  hasMore={hasNextPage ?? false}
                  /** TODO: remove this when
                   * we implmemnt intersectionObserver that tracks element height
                   * and according to the remaining space trigger the infinte query
                   * for now we adding this check to ensure, UI syncing with actual data fetching
                   */
                  loader={
                    (isScriptsLoading || isFetchingNextPage) && (
                      <Grid item xs={12} container spacing={2}>
                        {[1, 2, 3].map((item: number) => (
                          <Grid item xs={12} md={6} lg={4} key={item} mt={2}>
                            <ScriptLoadingCard />
                          </Grid>
                        ))}
                      </Grid>
                    )
                  }
                >
                  <Grid container spacing={2} pb={1}>
                    {adjustedScripts && adjustedScripts?.length > 0 ? (
                      adjustedScripts?.map((script) => (
                        <Grid item xs={12} md={6} lg={4} key={script?.uuid}>
                          <ScriptCard script={script} />
                        </Grid>
                      ))
                    ) : filterValue?.length > 0 ? (
                      <KonanEmptyStateFilter title={filterValue} />
                    ) : (
                      <KonanEmptyState
                        title={`No results for '${searchValue}'`}
                        subTitle="Adjust the search value and try again"
                      />
                    )}
                  </Grid>
                </InfiniteScroll>
              )}
            </Grid>
          </Fragment>
        ) : (
          <Grid minHeight={"50vh"} container height="100%">
            <KonanEmptyState
              title="No scripts, yet."
              subTitle={"Create your first script or choose from ready templates."}
              actionButtons={[
                <Button
                  onClick={() => setIsBuiltinScriptDialogOpen(true)}
                  startIcon={
                    <Inventory2OutlinedIcon style={{ fontSize: "16px", display: "flex", alignSelf: "center" }} />
                  }
                  variant="secondary"
                  size="regular"
                >
                  Use Template
                </Button>,
                <Button size="regular" variant="primary" onClick={() => setIsScriptDialogOpen(true)}>
                  + New Script
                </Button>,
              ]}
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}
