import React, { Dispatch, Fragment, SetStateAction, useContext, useEffect, useMemo, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Button as MuiButton,
  Skeleton,
  SvgIcon,
} from "@mui/material"
import {
  Button,
  InputText,
  Menu,
  MenuItem,
  NotificationUtils,
  Tooltip,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import { useFormik } from "formik"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

import { KonanAvatar } from "../../components/Avatar"
import { KonanEmptyState } from "../../components/KonanEmptyState"
import { VersionTag } from "../../components/VersionTag"
import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { VersioningDialog } from "../../components/dialogs/VersioningDialog"
import { getTheme } from "../../hooks/UseTheme"
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { RootState } from "../../store/ReduxStore"
import { CreateWorkflowRequest, Operators } from "../../types/custom/projects"
import {
  CreateTaglistRequest,
  CustomTag,
  CustomTagRequest,
  MapValueTypeToFeatureType,
  Rule,
  SingleTagCard,
  TagGroupConditions,
  UpdateTaglistRequest,
  VersionChangeRequest,
} from "../../types/custom/rules"
import { SchemaFeature } from "../../types/custom/workflows"
import { TagListGroupRetrieve } from "../../types/generated/api/TagListGroupRetrieve"
import { TagListVersionRetrieve } from "../../types/generated/api/TagListVersionRetrieve"
import { WorkflowCreateRequest } from "../../types/generated/api/WorkflowCreateRequest"
import { WorkflowSchemaFeatureRequest } from "../../types/generated/api/WorkflowSchemaFeatureRequest"
import { isPermitted } from "../../utils/PermissionsHelpers"
import { Auth } from "../../utils/auth"
import { getValidMaxMinorVersion } from "../../utils/deploymentDetailsHelpers"
import {
  compareTwoRulesList,
  convertComplexConditionsToNestedForm,
  convertComplexFormToCondition,
} from "../../utils/rulesetHelpers"
import { TagCard } from "./TagCard"

import styles from "../../screens/ProjectDetails/components/DecisonEngines/components/Ruleset/Ruleset.module.scss"

const levelSchema = Yup.object().shape({
  feature: Yup.string()
    .required("Feature is required")
    .matches(/^[a-zA-Z0-9._-]+$/, {
      message: "This field cannot contain white spaces or special character except: (.-_)",
    }),
  andOr: Yup.string().required(),
  operator: Yup.string().required("Operator is required"),
  type: Yup.string().required("Type is required"),
  value: Yup.mixed().when(["valueOrFeature", "type", "operator"], {
    is: (valueOrFeature: string, type: string, operator: string) => {
      return (
        valueOrFeature !== "Feature" &&
        ["true", "false", "null"].includes(type) &&
        ["greater than", "greater than/equal", "lower than", "lower than/equal", "between"].includes(operator)
      )
    },
    then: Yup.number().typeError("Value must be a number").required("Value is required"),
    otherwise: Yup.mixed(),
  }),
  secondValue: Yup.string().when(["operator"], (operator) => {
    return operator === "between"
      ? Yup.number().typeError("Value must be a number").required("Value is required")
      : Yup.string()
  }),
})

// Yup validation schema
const validationSchema = Yup.object({
  taglistName: Yup.string().required("Taglist name is required"),
  tagCards: Yup.array().of(
    Yup.object().shape({
      tagName: Yup.string().required("Tag name is required"),
      levelOneConditions: Yup.array().of(levelSchema),
      group1Switch: Yup.string().required(),
      group2Switch: Yup.string().required(),
      levelTwoConditions: Yup.array().of(levelSchema),
      levelThreeConditions: Yup.array().of(levelSchema),
    }),
  ),
})

type Props = {
  taglistUUID: string
  createdBy: string
  createdAt?: string
  closeCreateCard?: () => void
}

/**
 * Taglist card component
 * @param {string} taglistUUID
 * @param {string} createdBy email of creator
 * @param {string} createdAt  date of creation
 * @return {React.ReactElement}
 */
export function TaglistCard(props: Readonly<Props>): React.ReactElement {
  const { taglistUUID, createdBy, createdAt, closeCreateCard } = props
  const { id: projectId } = useParams<{ id: string }>()

  const permissions = Auth.getPermissions()

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  const workflowsList = useSelector((state: RootState) => state?.workflow?.workflows)
  const schemaFeatures = useSelector((state: RootState) => state?.project?.schemaFeatures)
  const computedSchemaFeatures = useSelector((state: RootState) => state?.project?.computedFeatures)

  const theme = getTheme()
  const queryClient = useQueryClient()

  const isCreateMode = taglistUUID === "new"

  const [isRuleConverted, setIsRuleConverted] = useState<boolean>(isCreateMode)

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchor)

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false)

  // saving a snap shot of the tags on edit click so if we cancel after some edit we get this snap shot back
  const [tagCardsSnapShot, setTagCardsSnapShot] = useState<Array<TagGroupConditions>>([])

  const [isVersionDialogOpen, setIsVersionDialogOpen] = useState<boolean>(false)
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined)

  // fetch single taglist
  const { isLoading: isTaglistLoading, data: taglist } = useQuery<AxiosResponse<TagListGroupRetrieve>, AxiosError>(
    ["taglist", currentProject, taglistUUID],
    () => KonanAPI.fetchTaglist(currentProject.uuid as string, taglistUUID),
    {
      enabled: !!currentProject && taglistUUID !== "new",
      refetchOnWindowFocus: !isEditMode && !isCreateMode,
      onSuccess: (res) => {
        formik.setFieldValue("taglistName", res.data.name)
      },
    },
  )

  // create workflow mutation
  const createWorkflowMutation = useMutation<AxiosResponse, AxiosError, CreateWorkflowRequest>(
    KonanAPI.createWorkflow,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["workflows", currentProject?.uuid])
        await queryClient.invalidateQueries(["project", currentProject?.uuid])
      },
    },
  )

  // create taglist
  const createTaglistMutation = useMutation<AxiosResponse, AxiosError, CreateTaglistRequest>(KonanAPI.createTaglist, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("taglists")
      await queryClient.invalidateQueries("taglist")
      NotificationUtils.toast("Taglist successfully created!", {
        snackBarVariant: "positive",
      })
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError(error: any) {
      if (error?.response?.data?.name) {
        NotificationUtils.toast(error?.response?.data?.name[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.condition) {
        NotificationUtils.toast(error?.response?.data?.condition[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.details) {
        NotificationUtils.toast(error?.response?.data?.details, {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while creating your Taglist.", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  // ruleset deletion Mutation
  const deleteTaglistMutation = useMutation<
    AxiosResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    { project_uuid: string; resource_uuid: string }
  >((props) => KonanAPI.deleteWorkflowResource({ ...props, resource_type: "taglists" }), {
    onSuccess: async () => {
      NotificationUtils.toast("Taglist deleted successfully", { snackBarVariant: "positive" })

      await queryClient.invalidateQueries("taglists")
      await queryClient.invalidateQueries(["taglist", currentProject, taglistUUID])
      await queryClient.invalidateQueries("taglist")
    },
    onError: ({ response }) => {
      NotificationUtils.toast(response?.data?.details ?? "Taglist deletion failed", { snackBarVariant: "negative" })
    },
  })

  // change taglist version
  const taglistVersionChangeMutation = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
    KonanAPI.changeResourceVersion,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("taglists")
        await queryClient.invalidateQueries(["taglist", currentProject, taglistUUID])
        await queryClient.invalidateQueries("taglist")
      },
    },
  )

  // update taglist
  const updateTaglistMutation = useMutation<AxiosResponse, AxiosError, UpdateTaglistRequest>(KonanAPI.updateTaglist, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("taglists")
      await queryClient.invalidateQueries("taglist")
      setIsEditMode(false)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError(error: any) {
      if (error?.response?.data?.name) {
        NotificationUtils.toast(error?.response?.data?.name[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.condition) {
        NotificationUtils.toast(error?.response?.data?.condition[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.details) {
        NotificationUtils.toast(error?.response?.data?.details, {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while updating your taglist.", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  // Memoize active version to render it in the card
  const activeVersion = useMemo(() => {
    if (taglist) {
      for (const version of taglist.data.versions) {
        if (version.is_active_version) {
          return version
        }
      }
    }
  }, [taglist])

  const emptyCondition: Rule = {
    id: uuidv4(),
    feature: "",
    andOr: "and",
    operator: Operators["="],
    type: "string",
    value: "",
    secondValue: "",
    valueOrFeature: "Value",
    schemaFeatureType: WorkflowSchemaFeatureRequest.type.TEXT,
  }

  const formik = useFormik({
    initialValues: {
      taglistName: "",
      tagCards: [
        {
          id: `new-${uuidv4()}`,
          tagName: "",
          group1Switch: "and",
          group2Switch: "and",
          levelOneConditions: [emptyCondition] as Rule[],
          levelTwoConditions: [] as Rule[],
          levelThreeConditions: [] as Rule[],
          condition_list_file: { name: "", uuid: "" },
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async ({ taglistName, tagCards }) => {
      const finalTaglistFormat: CustomTagRequest[] = []
      const featuresArray: Array<SchemaFeature> = []
      const trimmedTaglistName = taglistName?.trim()

      for (const tagCard of tagCards) {
        const trimmedTagCardName = tagCard?.tagName?.trim()

        try {
          const multiValuesOperators = [
            Operators["in"],
            Operators["not in"],
            Operators["contains"],
            Operators["not contains"],
          ]

          if (tagCard.levelOneConditions && tagCard.levelOneConditions.length > 0) {
            tagCard.levelOneConditions.forEach((item: Rule) => {
              featuresArray.push({
                name: item?.feature,
                is_required: true,
                source: "workflow",
                type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
              })

              item.andOr = tagCard.group1Switch
              if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
                item.type = item.operator
              }
            })
          }

          if (tagCard.levelTwoConditions && tagCard.levelTwoConditions.length > 0) {
            tagCard.levelTwoConditions.forEach((item: Rule) => {
              featuresArray.push({
                name: item?.feature,
                is_required: true,
                source: "workflow",
                type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
              })
              item.andOr = tagCard.group2Switch
              if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
                item.type = item.operator
              }
            })
          }

          if (tagCard.levelThreeConditions && tagCard.levelThreeConditions.length > 1) {
            const ruleLevelThree = tagCard.levelThreeConditions[1]
            const switchValue = ruleLevelThree.andOr
            tagCard.levelThreeConditions.forEach((item: Rule) => {
              featuresArray.push({
                name: item?.feature,
                is_required: true,
                source: "workflow",
                type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
              })
              item.andOr = switchValue
              if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
                item.type = item.operator
              }
            })
          }

          const newRule = tagCard?.condition_list_file?.uuid
            ? {
                name: trimmedTagCardName,
                condition: convertComplexFormToCondition(
                  tagCard.levelOneConditions,
                  tagCard.levelTwoConditions,
                  tagCard.levelThreeConditions,
                ),
                condition_list_file: tagCard?.condition_list_file?.uuid,
              }
            : {
                name: trimmedTagCardName,
                condition: convertComplexFormToCondition(
                  tagCard.levelOneConditions,
                  tagCard.levelTwoConditions,
                  tagCard.levelThreeConditions,
                ),
              }

          finalTaglistFormat.push(newRule)
        } catch (e) {
          console.warn(e)
        }
      }
      if (workflowsList?.length === 0 && featuresArray?.length > 0) {
        await createWorkflowMutation.mutateAsync({
          name: "Untitled-1",
          state: WorkflowCreateRequest.state.DISABLED,
          projectUUID: currentProject?.uuid as string,
          schema: [...featuresArray],
        })
      }
      if (isCreateMode) {
        // Create a new taglist
        await createTaglistMutation.mutateAsync({
          projectUUID: currentProject?.uuid as string,
          taglistName: trimmedTaglistName,
          rules: [...finalTaglistFormat],
        })
        closeCreateCard?.()
      } else {
        if (activeVersion?.rules && compareTwoRulesList(finalTaglistFormat, activeVersion?.rules)) {
          await updateTaglistMutation.mutateAsync({
            projectUUID: currentProject.uuid,
            taglistUUID: taglist?.data.uuid as string,
            taglistName: trimmedTaglistName === taglist?.data?.name ? undefined : trimmedTaglistName,
          })
        } else {
          await updateTaglistMutation.mutateAsync({
            projectUUID: currentProject.uuid,
            taglistUUID: taglist?.data.uuid as string,
            taglistName: trimmedTaglistName === taglist?.data?.name ? undefined : trimmedTaglistName,
            rules: [...(finalTaglistFormat ?? [])],
          })
        }

        setIsEditMode(false)
        NotificationUtils.toast("Taglist successfully updated!", {
          snackBarVariant: "positive",
        })

        await queryClient.invalidateQueries("taglists")
        await queryClient.invalidateQueries("taglist")
      }
    },
  })

  useEffect(() => {
    if (taglist && !isCreateMode && activeVersion?.rules) {
      const formikTagCards: SingleTagCard[] = []
      activeVersion?.rules?.forEach((rule) => {
        const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)
        const newRule = {
          id: uuidv4(),
          tagName: rule?.name,
          group1Switch: "and",
          group2Switch: "and",
          levelOneConditions: convertedRule && convertedRule.length > 0 ? convertedRule[0] : [emptyCondition],
          levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
          levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
          condition_list_file: { name: rule?.condition_list_file?.name, uuid: rule?.condition_list_file?.uuid },
        }
        formikTagCards?.push(newRule)
      })
      formik.setFieldValue("tagCards", [...formikTagCards])
      setIsRuleConverted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taglist, activeVersion, isCreateMode])

  const deleteRule = async (name: string, closeDialogState: Dispatch<SetStateAction<boolean>>): Promise<void> => {
    const clonedArray = activeVersion?.rules?.map((obj: CustomTag) => {
      const clonedObj = { ...obj }
      if (!clonedObj.condition_list_file) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { condition_list_file, ...rest } = clonedObj
        return rest
      }
      return clonedObj
    })

    try {
      const finalForamttedRules = clonedArray?.map((rule: CustomTag) => {
        return { ...rule }
      })
      await updateTaglistMutation.mutateAsync({
        projectUUID: currentProject.uuid,
        taglistUUID: taglist?.data?.uuid ?? "",
        taglistName: undefined,
        rules: [...(finalForamttedRules?.filter((rule: CustomTag) => rule.name !== name) ?? [])],
      })

      // Invalidate react-query queries
      await queryClient.invalidateQueries("taglist")
      await queryClient.invalidateQueries(["taglists", currentProject])
      await queryClient.invalidateQueries(["taglist", currentProject, taglistUUID])
      closeDialogState(false)

      NotificationUtils.toast("Tag successfully deleted!", {
        snackBarVariant: "positive",
      })
    } catch (e) {
      console.warn(e)
    }
  }

  const handleCancelTaglist = (): void => {
    formik.setFieldValue("tagCards", tagCardsSnapShot)
    setIsEditMode(false)
    closeCreateCard?.()
    formik.setFieldValue("taglistName", taglist?.data.name)
    formik.setErrors({})
  }

  const handleRemoveRule = (ruleIndex: number): boolean => {
    if (isCreateMode || isEditMode) {
      formik.setFieldValue(
        "tagCards",
        formik.values.tagCards.filter((_, index) => index !== ruleIndex),
      )
      formik.setFieldError(`tagCards[${ruleIndex}]`, "")
      return false
    } else {
      return true
    }
  }

  // helper function for the versioning dialog
  // returns data of the version selected
  const GetTaglistContainer = (): React.ReactElement => {
    const [cachedResults, setCachedResults] = useState<Record<string, string>>({})

    const Version = useMemo((): TagListVersionRetrieve | undefined => {
      if (taglist && selectedVersion) {
        if (!cachedResults[selectedVersion?.split(".")?.[0]]) {
          const result = getValidMaxMinorVersion(taglist.data.versions as TagListVersionRetrieve[], selectedVersion)
          setCachedResults((prevResults) => ({
            ...prevResults,
            ...result,
          }))
        }

        for (const version of taglist.data.versions) {
          if (version.version === selectedVersion) {
            return version
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVersion, taglist])

    // extracting the current major version
    const majorSelectedVersion = (selectedVersion ?? activeVersion?.version)?.split(".")?.[0]

    // show restore button when the current major version from the selected version/active version existed in our valid versions hashMap
    // and it's not the current active version
    const shouldShowRestoreButton =
      cachedResults[majorSelectedVersion as string] === (selectedVersion ?? activeVersion?.version) &&
      cachedResults[majorSelectedVersion as string] !== activeVersion?.version

    return (
      <Grid container>
        {/* Header */}
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          className={"versioning-dialog-header"}
        >
          <Grid item gap={1} xs={9} display="flex">
            <Typography variant="h2-bold" style={{ textTransform: "inherit", marginRight: "5px" }}>
              {taglist?.data.name}
            </Typography>

            <VersionTag
              version={Version?.version ?? selectedVersion}
              variant={Version ? (activeVersion?.version === selectedVersion ? "positive" : "default") : "positive"}
            />
          </Grid>

          {shouldShowRestoreButton && (
            <Button
              variant="secondary"
              size="small"
              onClick={() => changeTaglistVersion(selectedVersion as string)}
              disabled={taglistVersionChangeMutation.isLoading}
            >
              {taglistVersionChangeMutation.isLoading ? <CircularProgress size={12} /> : "Restore"}
            </Button>
          )}
        </Grid>

        {/* Body */}
        <Grid item xs={12} p={1.5} pt={0}>
          {Version?.rules && 0 < Version?.rules?.length ? (
            Version?.rules?.map((rule, index: number) => (
              <Grid
                container
                item
                key={rule?.name}
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                pt={1.5}
              >
                <Grid item xs={12}>
                  <TagCard
                    name={rule.name}
                    dialogView={true}
                    condition={convertComplexConditionsToNestedForm(rule.condition)}
                    conditionListFile={{ name: rule.condition_list_file?.name, uuid: rule?.condition_list_file?.uuid }}
                    taglistFormik={formik}
                    index={index}
                    andOr={["and", "or"]}
                    key={rule.name}
                    isLoading={updateTaglistMutation.isLoading || createTaglistMutation.isLoading}
                  />
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid
              container
              height={"100%"}
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              pt={1.5}
            >
              <KonanEmptyState title="No rules in this version" subTitle="Checkout another version" />
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  const changeTaglistVersion = async (version: string): Promise<void> => {
    try {
      await taglistVersionChangeMutation.mutateAsync({
        projectUUID: currentProject.uuid,
        resourceUUID: taglistUUID,
        resource: "taglists",
        version,
      })

      NotificationUtils.toast(`Version (${version}) restored.`, {
        snackBarVariant: "positive",
      })
      // Invalidate react-query queries
      await queryClient.invalidateQueries("ruleset")
    } catch (error) {
      NotificationUtils.toast(`Couldn't restore version (${version}). Please try again later`, {
        snackBarVariant: "negative",
      })
    }
  }

  return (
    <Fragment>
      {isVersionDialogOpen && (
        <VersioningDialog
          isOpen={isVersionDialogOpen}
          onClose={() => setIsVersionDialogOpen(false)}
          versions={taglist?.data.versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          activeVersion={activeVersion as TagListVersionRetrieve}
        >
          <GetTaglistContainer />
        </VersioningDialog>
      )}

      {isDeletionDialogOpen && (
        <BaseSimpleDialog
          open={isDeletionDialogOpen}
          name={formik.values.taglistName ?? taglist?.data.name}
          onClose={() => setIsDeletionDialogOpen(false)}
          onAccept={() => {
            deleteTaglistMutation.mutateAsync({
              project_uuid: projectId as string,
              resource_uuid: taglistUUID,
            })
          }}
          mode={"taglist-deletion"}
          isLoading={deleteTaglistMutation.isLoading}
        />
      )}

      <Card className="card-box-shadow">
        <CardHeader
          className={styles.padding}
          title={
            <Grid container direction="column" item xs={12} spacing={1}>
              <Grid item container xs={12} justifyContent="space-between">
                <Grid item container xs={6} md={3}>
                  {isTaglistLoading ? (
                    <Skeleton width={200} />
                  ) : isEditMode || isCreateMode ? (
                    <InputText
                      hideDescription
                      id="taglistName"
                      placeholder="Name"
                      value={formik.values.taglistName}
                      handleChange={formik.handleChange}
                      error={
                        formik.touched.taglistName && Boolean(formik.errors.taglistName) && formik.errors.taglistName
                      }
                      handleBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  ) : (
                    <Typography variant="h3-bold" style={{ textTransform: "inherit" }}>
                      {formik.values.taglistName ?? taglist?.data.name ?? ""}
                    </Typography>
                  )}
                  {!isCreateMode && !isEditMode && (activeVersion?.version || selectedVersion) && (
                    <Grid item mt={isCreateMode || isEditMode ? 0 : -1} ml={1}>
                      <VersionTag
                        version={selectedVersion ?? activeVersion?.version}
                        variant={
                          selectedVersion
                            ? selectedVersion === activeVersion?.version
                              ? "positive"
                              : "default"
                            : "positive"
                        }
                      />
                    </Grid>
                  )}
                </Grid>

                {!isCreateMode && !isEditMode && (
                  <Grid container item xs justifyContent={"flex-end"}>
                    {isPermitted("Delete taglist", permissions.konan, flattenedKonanPermissions) && (
                      <IconButton
                        aria-label="settings"
                        size="small"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget)}
                      >
                        <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
                      </IconButton>
                    )}

                    <Menu
                      key="basic-menu"
                      anchorEl={anchor}
                      open={isMenuOpen}
                      onClose={() => setAnchor(null)}
                      menuMaxContent
                    >
                      <MenuItem
                        onClick={() => {
                          setIsDeletionDialogOpen(true)

                          setAnchor(null)
                        }}
                      >
                        <Typography variant="a" color="negative" variantColor={2}>
                          Remove Taglist
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Grid>
                )}
              </Grid>

              {/* Avatar section */}
              <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                sx={{ flexDirection: { xs: "column", md: "row" } }}
              >
                <Grid container item spacing={1} xs={6} alignItems="flex-start" className={styles.avatarContainer}>
                  <Grid item sx={{ marginTop: "2px" }}>
                    {/* SvgIcon is used to fix square avatars on safari */}
                    <SvgIcon className={styles.modelAvatar}>
                      <KonanAvatar size={24} name={createdBy} />
                    </SvgIcon>
                  </Grid>
                  <Grid container item xs={10}>
                    <Grid item>
                      <Typography
                        variant="label"
                        noWrap
                        style={{ width: "fit-content", marginTop: isCreateMode ? "5px" : "0px" }}
                      >
                        {createdBy}
                      </Typography>

                      {createdAt && (
                        <Typography variant="label" noWrap style={{ width: "fit-content" }}>
                          <Tooltip title={format(new Date(createdAt), "dd/MM/yyyy, p")} placement="right">
                            <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                              {moment(new Date(createdAt)).fromNow()}
                            </Typography>
                          </Tooltip>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  alignSelf={"flex-end"}
                  sx={{ justifyContent: { xs: "flex-start", md: "flex-end" } }}
                >
                  {taglistUUID !== "new" && (
                    <Grid item pr={1}>
                      <Button
                        size="small"
                        variant="secondary"
                        disabled={isTaglistLoading || !isRuleConverted}
                        onClick={() => {
                          setSelectedVersion(activeVersion?.version)
                          setIsVersionDialogOpen(true)
                        }}
                      >
                        Version history
                      </Button>
                    </Grid>
                  )}

                  {!isTaglistLoading && !isEditMode && taglistUUID !== "new"
                    ? isPermitted("Update taglist", permissions.konan, flattenedKonanPermissions) && (
                        <Grid item alignSelf={"flex-end"}>
                          <Box display="flex">
                            <Button
                              variant="secondary"
                              size="small"
                              disabled={!isRuleConverted}
                              onClick={() => {
                                setIsEditMode(!isEditMode)
                                setTagCardsSnapShot(JSON.parse(JSON.stringify(formik.values.tagCards)))
                              }}
                            >
                              Edit
                            </Button>
                          </Box>
                        </Grid>
                      )
                    : !isTaglistLoading &&
                      (isEditMode || isCreateMode) && (
                        <Grid item alignSelf={"flex-end"}>
                          <Grid container spacing={1}>
                            <Grid item>
                              <Box display="flex">
                                <Button
                                  disabled={isTaglistLoading || !isRuleConverted}
                                  size="small"
                                  variant={"secondary"}
                                  onClick={handleCancelTaglist}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            </Grid>

                            <Grid item>
                              <Box display="flex" justifyContent="flex-end">
                                <Button
                                  variant="primary"
                                  size="small"
                                  disabled={
                                    updateTaglistMutation.isLoading ||
                                    createTaglistMutation.isLoading ||
                                    isTaglistLoading ||
                                    !isRuleConverted
                                  }
                                  onClick={formik.submitForm}
                                >
                                  {updateTaglistMutation.isLoading || createTaglistMutation.isLoading ? (
                                    <CircularProgress color="inherit" size={16} />
                                  ) : (
                                    "Save"
                                  )}
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                </Grid>
              </Grid>

              <Grid item container>
                <Typography variant="p" display="block" style={{ textTransform: "inherit" }}>
                  Define conditions with tags. To be effective, you integrate it into your workflow.
                </Typography>
              </Grid>
            </Grid>
          }
        />

        <CardContent className={styles.cardContent}>
          {isTaglistLoading || !isRuleConverted ? (
            <Grid item xs={12}>
              <Card className={styles.cardHeader}>
                <CardHeader className={styles.padding} title={<Skeleton width={200} />} />
                <CardContent className={styles.cardContent}>
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <Fragment>
              <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
                {formik.values.tagCards &&
                  formik.values.tagCards.length > 0 &&
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  formik.values.tagCards.map((rule: any, index) => (
                    <Grid key={rule?.id} container item justifyContent="flex-start" alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        <TagCard
                          createMode={isCreateMode || rule?.id?.includes("new")}
                          editMode={isEditMode}
                          name={rule.tagName}
                          andOr={["and", "or"]}
                          conditionListFile={{
                            name: rule.condition_list_file?.name,
                            uuid: rule?.condition_list_file?.uuid,
                          }}
                          // IMPORTANT: key constructed with these 3 dependecies to re-render whenever change occurs
                          key={`${rule.id}_${schemaFeatures.length}_${computedSchemaFeatures.length}`}
                          index={index}
                          handleRemoveRule={(index: number) => handleRemoveRule(index)}
                          taglistFormik={formik}
                          handleDelete={(name: string, stateFn: Dispatch<SetStateAction<boolean>>) =>
                            deleteRule(name, stateFn)
                          }
                          isLoading={updateTaglistMutation.isLoading || createTaglistMutation.isLoading}
                        />
                      </Grid>
                    </Grid>
                  ))}
              </Grid>

              <Grid container justifyContent="flex-start" alignItems="center" mt={2}>
                {(isEditMode || isCreateMode) && (
                  <Grid item>
                    <MuiButton
                      size="small"
                      className={styles.MuiButton}
                      disabled={createTaglistMutation.isLoading || updateTaglistMutation.isLoading}
                      onClick={() => {
                        formik.setFieldValue("tagCards", [
                          ...formik.values.tagCards,
                          {
                            id: `new-${uuidv4()}`,
                            tagName: "",
                            group1Switch: "and",
                            group2Switch: "and",
                            levelOneConditions: [emptyCondition],
                            levelTwoConditions: [],
                            levelThreeConditions: [],
                            condition_list_file: { name: "", uuid: "" },
                          },
                        ])
                      }}
                    >
                      + Add Tag
                    </MuiButton>
                  </Grid>
                )}
              </Grid>
            </Fragment>
          )}
        </CardContent>
      </Card>
    </Fragment>
  )
}
