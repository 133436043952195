import React, { Fragment, useContext, useEffect, useState } from "react"

import { useIsMutating, useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import CloseIcon from "@mui/icons-material/Close"
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material"
import { useTheme as useMuiTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Button, NotificationUtils, Select, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"
import { OpenAPIV3 } from "openapi-types"

import { SelectWithSearch } from "../../../../../../../components/UI/SelectWithSearch"
import { AuotMLConfigurationForm } from "../../../../../../../features/projects/components/AutoMLConfigurationForm"
import { getTheme } from "../../../../../../../hooks/UseTheme"
import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../../../store/CurrentProjectAndModelContext"
import {
  ClassificationConfiguration,
  ConfigureAutoMlModelRequest,
  TestPrediction,
  baseErrorType,
} from "../../../../../../../types/custom/projects"
import { Model } from "../../../../../../../types/generated/api/Model"
import { TestPredictionResponse } from "../../../../../../../types/generated/api/TestPredictionResponse"
import { extractPredictionKeysFromOpenapi } from "../../../../../../../utils/modelDetailsHelpers"
import { ConfigurationForm, handleSubmit as getConfiguration } from "./ModelConfiguration/components/ConfigurationForm"
import { DryRunForm } from "./ModelConfiguration/components/DryRunForm"

import styles from "../ModelConfigurations.module.scss"

// Configuration form validation schema object
const configurationValidationSchema = Yup.object({
  target: Yup.string().required("Required"),
  lowerLimit: Yup.number().required("Required"),
  upperLimit: Yup.number().required("Required"),
  step: Yup.number(),
})

// validation schema
const autoMlConfigValidationSchema = Yup.object({
  positiveLabel: Yup.string().required("Required"),
  negativeLabel: Yup.string().required("Required"),
  threshold: Yup.number().required("Required"),
  model: Yup.string().required("Request body is required!"),
})

type Props = {
  open: boolean
  onClose: () => void
}

/**
 * Model Configuration creation flow dialog
 * @param {boolean} open
 * @param {function} onClose
 * @return {React.ReactElement}
 */
export function ConfigurationFlow(props: Readonly<Props>): React.ReactElement {
  const { open, onClose } = props

  const { id: projectId } = useParams<{ id: string }>()

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const queryClient = useQueryClient()
  const MuiTheme = useMuiTheme()
  const theme = getTheme()

  const [activeStep, setActiveStep] = useState<number>(0)
  const [requestJsonError, setRequestJsonError] = useState<boolean>(false)

  const predictionsTest = useMutation<AxiosResponse<TestPredictionResponse>, AxiosError<baseErrorType>, TestPrediction>(
    KonanAPI.testPrediction,
  )

  // fetch all models
  const { data: models } = useQuery<AxiosResponse<Array<Model>>, AxiosError>(
    ["models", projectId],
    () => KonanAPI.fetchModels(projectId as string),
    { enabled: !!projectId },
  )

  const classificationConfigurationMutation = useMutation<
    AxiosResponse,
    AxiosError<baseErrorType>,
    ClassificationConfiguration
  >(KonanAPI.createModelConfiguration, {
    onSuccess: () => NotificationUtils.toast("Configuration successful!", { snackBarVariant: "positive" }),
    onError: () => NotificationUtils.toast("Configuration failed!", { snackBarVariant: "negative" }),
  })

  // Fetch project docs using project ID in route
  const { isLoading: isLoadingOpenApiData, data: response } = useQuery<
    AxiosResponse<OpenAPIV3.Document>,
    AxiosError<baseErrorType>
    // added open as query key to force useQuery to refetch on mount since we reset everything when closing
    // N.B.: probably not the best approach
    // TODO:: look for a better approach
  >(["project-docs", projectId, open], () => KonanAPI.fetchProjectDocs(projectId as string), {
    retry: false,
  })

  const configureAutoMlModeMutation = useMutation<AxiosResponse, AxiosError, ConfigureAutoMlModelRequest>(
    KonanAPI.configureAutoMlModel,
    {
      mutationKey: "configure-automl",
      onSuccess: async () => {
        NotificationUtils.toast("Model configured successfully", { snackBarVariant: "positive" })
        onClose()
        configurationAutoMLFormik?.resetForm()
        queryClient.invalidateQueries("model-configurations")
      },
      onError: async () => {
        NotificationUtils.toast("An error occurred while configuring model", { snackBarVariant: "negative" })
      },
    },
  )

  const isConfigureAutoMlMutating = useIsMutating({
    mutationKey: "configure-automl",
    exact: true,
  })

  const configurationAutoMLFormik = useFormik({
    validationSchema: autoMlConfigValidationSchema,
    initialValues: {
      labels: [],
      positiveLabel: "",
      negativeLabel: "",
      threshold: 0,
      model: "",
    },
    onSubmit: async ({ threshold, positiveLabel, negativeLabel, model }) => {
      configureAutoMlModeMutation.mutateAsync({
        projectUUID: projectId as string,
        modelUUID: model,
        threshold: threshold,
        positive_label: positiveLabel,
        negative_label: negativeLabel,
        state: models?.data.some((modelData) => modelData.state === "live")
          ? models?.data.find((modelData) => modelData.uuid === model)?.state
          : "live",
      })
    },
  })

  const DryRunFormik = useFormik({
    initialValues: {
      request: "{}",
      model: "Choose model",
    },
    validationSchema: Yup.object({
      request: Yup.string().required("Request body is required!"),
      model: Yup.string().required("Request body is required!"),
    }),
    onSubmit: async (values) => {
      try {
        const requestJson = JSON.parse(values.request)
        setRequestJsonError(false)
        try {
          values.model !== "default" &&
            (await predictionsTest.mutateAsync({
              model_uuid: values.model,
              requestJson: {
                ...requestJson,
              },
              skip_config: true,
            }))
        } catch (e) {
          console.warn(e)
        }
      } catch (e) {
        setRequestJsonError(true)
      }
    },
  })

  // fetch single model
  const { data: modelData, isLoading: isModelDataLoading } = useQuery<AxiosResponse<Model>, AxiosError>(
    ["model", DryRunFormik?.values?.model],
    () => KonanAPI.fetchModel(DryRunFormik?.values?.model),
    {
      enabled:
        !!DryRunFormik?.values?.model &&
        DryRunFormik?.values?.model !== "Choose model" &&
        DryRunFormik.values.model !== "default" &&
        currentProject?.type === "credit_scoring",
    },
  )

  // Model configuration form control
  const configurationFormik = useFormik({
    initialValues: {
      dataType: "",
      reDataType: "",
      target: "",
      lowerLimit: 0,
      upperLimit: 100,
      step: 1,
    },
    validationSchema: configurationValidationSchema,
    onSubmit: async () => {
      const requestObject = getConfiguration()

      // TODO:: Should be refactored to make the dialog use formik and validate using yup
      // cant do that now since i will have to refactor the whole form to make it work
      requestObject?.settings?.forEach((setting) => {
        if (setting.label === "") {
          NotificationUtils.toast("Please add labels to your range configurations", { snackBarVariant: "negative" })
          throw new Error("Please add labels to your range configurations")
        }
      })

      await classificationConfigurationMutation
        .mutateAsync({
          ...requestObject,
        })
        .then(async () => {
          onClose()

          queryClient.invalidateQueries("activeConfig")
          await queryClient.invalidateQueries("model-configurations")

          // waiting to avoid flickering
          setTimeout(() => {
            DryRunFormik.resetForm()
            configurationFormik.resetForm()

            predictionsTest.reset()
            classificationConfigurationMutation.reset()
            setActiveStep(0)
          }, 200)
        })
    },
  })

  const TestResponse = predictionsTest.isError
    ? predictionsTest.error?.response?.data.ml_service_output
    : predictionsTest.data?.data.output

  const responseData = !TestResponse ? "// Execute to see response" : JSON.stringify(TestResponse, null, "  ")

  // configuration steps with action buttons, description, etc...
  const steps = [
    {
      name: "Choose Model",
      content: "Enter project name and description then choose between a use-case, or deploy your own model",
      btnText: "Configure Target",
      component: (
        <Fragment>
          <Grid item xs={12}>
            <Typography variant="h2-bold">Choose Model</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="label">
              {models?.data.some((modelData) => modelData.state === "live")
                ? "This action will not change the Model's state"
                : "This action will change the Model to LIVE"}
            </Typography>
          </Grid>

          <Grid container item xs={12} mt={1.5} justifyContent="space-between">
            <Grid item xs={currentProject?.type === "credit_scoring" ? 3.8 : 12} mb={3}>
              <SelectWithSearch
                options={[
                  { label: "Choose Model", value: "", isDisabled: true },
                  ...(models?.data?.map((model) => {
                    return { label: model.name, value: model.uuid }
                  }) ?? []),
                ]}
                placeHolder="Choose Model"
                label="Compare With"
                searchInputPlaceHolder="Search models"
                fullWidth
                onSelectMenuItem={(item: { label: string; value: string }) => {
                  if (item?.value === "none") {
                    DryRunFormik.setFieldValue("model", "")
                    configurationAutoMLFormik.setFieldValue("model", "")
                  } else {
                    DryRunFormik.setFieldValue("model", item?.value)
                    configurationAutoMLFormik.setFieldValue("model", item?.value)
                  }
                }}
              />
            </Grid>

            {currentProject?.type === "credit_scoring" && !!configurationAutoMLFormik.values.model && (
              <Fragment>
                <Grid item xs={8} container justifyContent="space-between">
                  <Grid item xs={5.9}>
                    <Select
                      hideDescription
                      id="positiveLabel"
                      label="Positive Label"
                      placeholder={"Positive Label"}
                      value={configurationAutoMLFormik?.values?.positiveLabel}
                      handleChange={configurationAutoMLFormik?.handleChange}
                      handleBlur={configurationAutoMLFormik?.handleBlur}
                      error={
                        configurationAutoMLFormik?.touched &&
                        configurationAutoMLFormik?.errors &&
                        configurationAutoMLFormik?.touched?.positiveLabel &&
                        Boolean(configurationAutoMLFormik?.errors?.positiveLabel) &&
                        configurationAutoMLFormik?.errors?.positiveLabel
                      }
                      required
                      fullWidth
                      options={configurationAutoMLFormik.values.labels}
                      disabled={configurationAutoMLFormik.values.labels.length === 0}
                    />
                  </Grid>
                  <Grid item xs={5.9}>
                    <Select
                      hideDescription
                      id="negativeLabel"
                      label="Negative Label"
                      placeholder={"Negative Label"}
                      value={configurationAutoMLFormik?.values?.negativeLabel}
                      handleChange={configurationAutoMLFormik.handleChange}
                      handleBlur={configurationAutoMLFormik.handleBlur}
                      error={
                        configurationAutoMLFormik?.touched?.negativeLabel &&
                        Boolean(configurationAutoMLFormik?.errors?.negativeLabel) &&
                        configurationAutoMLFormik?.errors?.negativeLabel
                      }
                      required
                      fullWidth
                      options={configurationAutoMLFormik.values.labels}
                      disabled={configurationAutoMLFormik.values.labels.length === 0}
                    />
                  </Grid>
                </Grid>

                <AuotMLConfigurationForm
                  isModelDataLoading={isModelDataLoading}
                  modelUUID={modelData?.data?.uuid ?? ""}
                  formik={configurationAutoMLFormik}
                />
              </Fragment>
            )}
          </Grid>

          {((modelData?.data?.type && modelData?.data?.type !== "automl") ||
            currentProject?.type !== "credit_scoring") && (
            <DryRunForm
              response={responseData}
              requestErrorMessage={
                requestJsonError
                  ? "Request isn't JSON object, please re-enter the the request and try again"
                  : (predictionsTest.error?.response?.data.details as string)
              }
              isRequestError={requestJsonError}
              isMutationError={predictionsTest.isError}
              responseStatusCode={!requestJsonError ? predictionsTest.error?.response?.status : undefined}
              formik={DryRunFormik}
              isLoadingRequest={isLoadingOpenApiData}
              modelSelect
            />
          )}
        </Fragment>
      ),
    },
    {
      name: "Configure Target",
      content: "The uploaded data will be used to train the model",
      btnText: classificationConfigurationMutation.isLoading ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        "Configure"
      ),
      component: (
        <ConfigurationForm
          formik={configurationFormik}
          responseJSON={predictionsTest.data?.data.output as Record<string, string>}
          modelUUID={DryRunFormik.values.model}
          errorMessage={classificationConfigurationMutation.error?.response?.data.details}
        />
      ),
    },
  ]

  /**
   * generic click actions function to be called when needed
   * to make it easier to than using switch cases and if conditions
   * @return  {void}
   */
  const clickActions = (): void => {
    switch (activeStep) {
      case 0:
        setActiveStep(activeStep + 1)
        break
      case 1:
        configurationFormik.submitForm()
        break
      default:
        break
    }
  }

  /**
   * stepper helper function
   * to help disable the next btn when needed in the generic project flow
   * @return  {boolean}
   */
  const shouldDisable = (): boolean => {
    switch (activeStep) {
      case 0:
        return !predictionsTest.isSuccess
      case 1:
        return !(configurationFormik.isValid && configurationFormik.dirty)
      default:
        return false
    }
  }

  // Getting Predictions object from the helper function then convert it to JSON
  useEffect(() => {
    if (response?.data) {
      try {
        const extractedObjectJSON = JSON.stringify(extractPredictionKeysFromOpenapi(response?.data), null, 2)
        DryRunFormik.setFieldValue("request", extractedObjectJSON)
      } catch (_) {
        DryRunFormik.setFieldValue("request", "{}")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response?.data])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setTimeout(() => {
          setActiveStep(0)
        }, 300)
      }}
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("md"))}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className="dialog-header-base" style={{ backgroundColor: "var(--grayscale-background-2)" }}>
        <Grid container>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h2-bold">Configuration Setup</Typography>
            <IconButton
              onClick={() => {
                onClose()
              }}
              size="small"
              className={"close-icon-button"}
            >
              <CloseIcon style={{ color: theme.palette.grayscale.text[2] }} />
            </IconButton>
          </Grid>

          {((currentProject?.type === "credit_scoring" && modelData?.data && modelData?.data?.type !== "automl") ||
            currentProject?.type !== "credit_scoring") && (
            <Grid item xs={12} mt={1.5}>
              <Stepper activeStep={activeStep} connector={null}>
                {steps.map((step, index) => (
                  <Step key={step.name} style={{ paddingLeft: index === 0 ? "0px" : "8px" }}>
                    <StepLabel>
                      <Typography variant="h3-bold">{step.name}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          )}
        </Grid>
      </DialogTitle>

      <DialogContent className={`dialog-content-base ${styles.flowContainer}`}>
        <Grid container>
          <Grid item xs={12}>
            {steps[activeStep].component}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className="dialog-actions-base">
        {activeStep === 0 && modelData?.data?.type !== "automl" && currentProject?.type !== "credit_scoring" ? (
          <Button
            variant="secondary"
            disabled={
              !(DryRunFormik.isValid && DryRunFormik.dirty) ||
              predictionsTest.isLoading ||
              DryRunFormik.values.model === "default"
            }
            onClick={() => {
              DryRunFormik.submitForm()
            }}
            id="execute"
          >
            {predictionsTest.isLoading ? <CircularProgress color="inherit" size={20} /> : "Execute"}
          </Button>
        ) : (
          currentProject?.type === "credit_scoring" && (
            <Button
              variant="primary"
              disabled={!(configurationAutoMLFormik.isValid && configurationAutoMLFormik.dirty)}
              onClick={() => {
                configurationAutoMLFormik.submitForm()
              }}
              id="configure_automl"
            >
              {isConfigureAutoMlMutating ? <CircularProgress color="inherit" size={20} /> : "Configure"}
            </Button>
          )
        )}
        {activeStep === 1 && (
          <Button
            variant="secondary"
            onClick={() => {
              setActiveStep(activeStep - 1)
            }}
            id="retest"
          >
            Back
          </Button>
        )}
        {modelData?.data?.type !== "automl" && currentProject?.type !== "credit_scoring" && (
          <Button onClick={clickActions} disabled={shouldDisable()} variant="primary">
            {steps[activeStep].btnText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
