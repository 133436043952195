import React from "react"

import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"

import * as Yup from "yup"
import { Box, CircularProgress, Grid } from "@mui/material"
import { Button, InputText, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { useFormik } from "formik"

import { SidAPI } from "../../../services/SidAPI"
import { SendEmailReset } from "../../../types/generated/authentication/SendEmailReset"

import styles from "../Entry.module.scss"

// Yup validation schema object
const validationSchema = Yup.object({
  email: Yup.string().email().required("Email is required"),
})

/**
 * forget password form component
 * @return  {<ForgetPassword />}
 */
export function ForgetPassword(): React.ReactElement {
  const navigate = useNavigate()

  const resetPasswordMutation = useMutation<SendEmailReset, AxiosError, SendEmailReset>(SidAPI.resetPassword)

  // form control
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ email }) => {
      try {
        // this api call actually returns on content when successful, only status 204
        await resetPasswordMutation.mutateAsync({ email })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.response?.data) {
          formik.setErrors(e.response.data)
        }
      }
    },
  })

  return (
    <Grid>
      <Grid container direction="column" className={styles.containerWidth} justifyContent="center">
        {/**
         * Forgot password snackbars
         * 1. email sent to account successfully
         * 2. Network error
         */}
        {resetPasswordMutation.isSuccess && (
          <Grid item xs={12}>
            <Snackbar
              variant="positive"
              fullWidth
              description="If your email address exists in Konan, you will receive a password reset link at your email address in a
              few minutes"
            />
            <Box mt={2} />
          </Grid>
        )}

        {resetPasswordMutation.isError && (
          <Grid item xs={12}>
            <Snackbar variant="negative" fullWidth description="Network Error Could not connect to server." />
            <Box mt={2} />
          </Grid>
        )}
      </Grid>

      <Grid container direction="column" className={styles.signupContainer} justifyContent="center">
        <Grid item className={styles.headerPadding}>
          <Typography variant="h2-bold" gutterBottom={false}>
            Reset password
          </Typography>
        </Grid>

        <Grid container item className={styles.forgotPasswordForm}>
          <Grid item md sm xs>
            <InputText
              fullWidth
              id="email"
              label="Email"
              placeholder="name@example.com"
              error={Boolean(formik.errors.email) && formik.touched.email && formik.errors.email}
              description={"We'll send you a password reset link"}
              value={formik.values.email}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              required
              disabled={formik.isSubmitting}
            />
          </Grid>
        </Grid>
        <Grid item className={styles.buttonGroup}>
          <Button
            onClick={() => formik.submitForm()}
            variant="primary"
            disabled={formik.isSubmitting || !formik.isValid}
            type="submit"
            fullWidth
          >
            {formik.isSubmitting ? <CircularProgress color="inherit" size={20} /> : "Send Email"}
          </Button>

          <hr style={{ margin: "8px 0px", borderColor: "rgba(128, 128, 128, 0.8)" }} />

          <Button
            onClick={() => navigate("/login")}
            variant="secondary"
            disabled={formik.isSubmitting}
            type="submit"
            fullWidth
          >
            Back to login
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
