import React, { Fragment } from "react"

import Skeleton from "@mui/material/Skeleton"
import { Tag } from "@synapse-analytics/synapse-ui"

type Props = {
  type: string | undefined
  size?: "small" | "regular"
  skeletonWidth?: number
  margin?: number | string
}

export function ProjectType(props: Props): React.ReactElement {
  const { type, size = "regular", margin, skeletonWidth = 50 } = props

  const cleanType = type ? type.replace(/_+/g, " ") : ""
  const animation: "wave" | "pulse" = "pulse"

  return (
    <Fragment>
      {type ? (
        <Tag
          size={size}
          // TODO:: refactor to use enums
          variant={type === "classification" ? "pink" : type === "generic" ? "purple" : "default"}
          style={{
            margin: margin,
          }}
        >
          {cleanType}
        </Tag>
      ) : (
        <Skeleton width={skeletonWidth} animation={animation} style={{ display: "inline-block", margin: margin }} />
      )}
    </Fragment>
  )
}
