import React, { Fragment, ReactNode, useState } from "react"

import { CircularProgress, Dialog, DialogContent, Grid, useMediaQuery, useTheme } from "@mui/material"
import { Button, InputText, RadioButton, Typography } from "@synapse-analytics/synapse-ui"

type DialogTextTypeProps = {
  dialogText: string | React.ReactElement
  subText?: string | React.ReactElement
  primaryBtnMode: "dangerous" | "secondary" | "primary"
  primaryBtnText?: string
  secondaryBtnMode: "dangerous" | "secondary" | "primary"
  secondaryBtnText?: string
}
/**
 * helper function to get dialog data based on the dialog mode
 * @param  {string} mode dialog mode
 * @param  {string} newState (state switch dialog's) new state
 * @param  {string} name resource name to appear on dialog
 * @return  {dialogTextType}
 */
const GetContent = (props: BaseSimpleDialogProps): DialogTextTypeProps => {
  const { mode, newState, name, list } = props

  switch (mode) {
    case "project-deletion":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to delete{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            ?
          </Fragment>
        ),
        subText: "Deleted projects cannot be restored.",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Delete",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "model-deletion":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to delete{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            ?
          </Fragment>
        ),
        subText: "Deleted models cannot be restored.",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Delete",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "training-data-deletion":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to delete{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            ?
          </Fragment>
        ),
        subText: "Deleted training datasets cannot be restored.",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Delete",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }

    case "rule-deletion":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to delete{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            ?
          </Fragment>
        ),
        subText: "Deleted rules cannot be restored.",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Delete",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "ruleset-deletion":
    case "scorecardset-deletion":
    case "program-deletion":
    case "script-deletion":
    case "taglist-deletion":
      return {
        dialogText: (
          <Fragment>
            Deleting{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>{" "}
            will deprecate associated workflows. Are you sure?
          </Fragment>
        ),
        subText: "This action cannot be undone.",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Delete & Deprecate Workflows",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "member-leaving":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to leave{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            ?
          </Fragment>
        ),
        subText: "This action cannot be undone",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Leave",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "member-removal":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to remove{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            ?
          </Fragment>
        ),
        subText: "This action cannot be undone",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Remove",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "organization-deletion":
      return {
        dialogText: (
          <Fragment>
            You're the last member. Leaving will delete{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            ?
          </Fragment>
        ),
        subText: "This action cannot be undone",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Delete",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "invitation-deletion":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to remove{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            's invitation?
          </Fragment>
        ),
        subText: "This action cannot be undone",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Remove",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "role-deletion":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to delete role{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            ?
          </Fragment>
        ),
        subText: "Members with this role will be changed to USER role. Members will not be removed.",
        primaryBtnMode: "dangerous",
        primaryBtnText: `Delete ${name}`,
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "group-deletion":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to delete group{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            ?
          </Fragment>
        ),
        subText: "Members in this group will be changed to All projects. Members will not be removed.",
        primaryBtnMode: "dangerous",
        primaryBtnText: `Delete ${name}`,
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "workflow-switch":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to switch
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>{" "}
            to{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {newState === "DISABLED" ? "IDLE" : "ACTIVE"}
            </Typography>
            ?
          </Fragment>
        ),
        primaryBtnMode: "primary",
        primaryBtnText: "Switch",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "model-switch":
      return {
        dialogText: name,
        primaryBtnMode: "primary",
        primaryBtnText: "Switch",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "pending-model-switch":
      return {
        dialogText: "This Model has new features. Do you want to add them to the project schema?",
        primaryBtnMode: "primary",
        primaryBtnText: "Add features",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }

    case "close-while-edit":
      return {
        dialogText: `Are you sure you want to discard the changes?`,
        subText: "Any unsaved changes will be lost",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Discard Changes",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "remove-filter-node":
      return {
        dialogText: "Removing this filter will remove everything below it. Are you sure?",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Remove filter",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "deprecate-workflows":
      return {
        dialogText: `Be careful! These feature(s) are colliding with project schema`,
        subText: (
          <Fragment>
            {list && list?.length > 0 && (
              <ul>
                {list?.map((item) => (
                  <li key={`${item}`}>
                    <Typography variant="p">{item}</Typography>
                  </li>
                ))}
              </ul>
            )}
            <Typography variant="p" gutterBottom>
              Do you want to override feature types and deprecate all other workflows using these feature(s)?
            </Typography>
          </Fragment>
        ),
        primaryBtnMode: "dangerous",
        primaryBtnText: "Override Features & Deprecate Workflows",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "export-table":
      return {
        dialogText: `Export Data`,
        primaryBtnMode: "primary",
        primaryBtnText: "Export Data",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "data-file-deletion":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to delete{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            ?
          </Fragment>
        ),
        subText: "Deleted Data Files cannot be restored.",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Delete",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
    case "data-file-cancelation":
      return {
        dialogText: (
          <Fragment>
            Are you sure you want to cancel{" "}
            <Typography variant={"h2-bold"} variantColor={2} style={{ display: "inline-flex" }}>
              {name}
            </Typography>
            ?
          </Fragment>
        ),
        subText: "Cancelled data file cannot be restored.",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Proceed",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }

    default:
      // if this message appears that means something went wrong in the switch case
      return {
        dialogText: `Are you sure you want to delete the selected Resource?`,
        subText: "This action cannot be undone",
        primaryBtnMode: "dangerous",
        primaryBtnText: "Delete",
        secondaryBtnMode: "secondary",
        secondaryBtnText: "Cancel",
      }
  }
}

type BaseSimpleDialogProps = {
  children?: ReactNode
  disableMainBtn?: boolean
  open: boolean
  isLoading: boolean
  name: string
  newState?: string
  list?: Array<string>
  onAccept: () => void
  onClose: () => void
  mode:
    | "project-deletion"
    | "model-deletion"
    | "rule-deletion"
    | "ruleset-deletion"
    | "scorecardset-deletion"
    | "program-deletion"
    | "taglist-deletion"
    | "script-deletion"
    | "model-switch"
    | "workflow-switch"
    | "close-while-edit"
    | "organization-deletion"
    | "member-leaving"
    | "member-removal"
    | "invitation-deletion"
    | "role-deletion"
    | "group-deletion"
    | "training-data-deletion"
    | "deprecate-workflows"
    | "pending-model-switch"
    | "fix-model-schema"
    | "remove-filter-node"
    | "export-table"
    | "data-file-deletion"
    | "data-file-cancelation"
}

/**
 * simple dialog base to use in simple repeatable cases
 * @param  {boolean} open is dialog open?
 * @param  {boolean} isLoading if true triggers is loading mode
 * @param  {string} name project/model name
 * @param  {string} newState model switch state to be
 * @param  {function} onAccept function to fire up on click of the main btn of the dialog
 * @param  {function} onClose function to fire up on dialog close
 * @return  {React.ReactElement}
 */
export function BaseSimpleDialog(props: Readonly<BaseSimpleDialogProps>): React.ReactElement {
  const { children, open, isLoading, onClose, onAccept, disableMainBtn = false } = props

  const theme = useTheme()

  const content = GetContent(props)

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if ((reason === "backdropClick" || reason === "escapeKeyDown") && isLoading) {
          return undefined
        } else {
          onClose()
        }
      }}
      fullWidth
      fullScreen={useMediaQuery(theme.breakpoints.down("sm"))}
      maxWidth="xs"
      PaperProps={{
        style: {
          // To enable the date-picker to overflow
          overflow: "unset",
        },
      }}
    >
      <DialogContent className={`dialog-base`} style={{ borderRadius: "8px" }}>
        <Grid container gap={3}>
          <Grid item xs={12}>
            <Typography variant="h2-bold" display="inline">
              {content.dialogText}
            </Typography>
          </Grid>

          {content.subText && (
            <Grid item xs={12}>
              <Typography variant="p" gutterBottom>
                {content.subText}
              </Typography>
            </Grid>
          )}

          {children && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}

          <Grid container item xs={12} gap={1.5}>
            {content.primaryBtnText && (
              <Grid item xs={12}>
                <Button
                  variant={content.primaryBtnMode}
                  onClick={onAccept}
                  id="main_btn"
                  fullWidth
                  disabled={disableMainBtn}
                  size="large"
                >
                  {isLoading ? <CircularProgress size={23} color="inherit" /> : content.primaryBtnText}
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                variant={content.secondaryBtnMode}
                onClick={onClose}
                disabled={isLoading}
                autoFocus
                fullWidth
                size="large"
              >
                {content.secondaryBtnText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export function ConfirmationSimpleDialog(props: Readonly<BaseSimpleDialogProps>): React.ReactElement {
  const { name } = props

  const [value, setValue] = useState<string>("")

  return (
    <BaseSimpleDialog {...props} disableMainBtn={name !== value}>
      <InputText
        label={`Type ${name} to confirm deletion`}
        id="confirmation"
        value={value}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        handleChange={(e: React.ChangeEvent<Element>) => setValue(e.target.value)}
        placeholder={`${name}`}
        fullWidth
      />
    </BaseSimpleDialog>
  )
}

type SwitchModelDialogProps = Omit<BaseSimpleDialogProps, "OnAccept"> & {
  modelState: "live" | "disabled" | "challenger" | "pending-action"
  onAccept: (newState: string) => Promise<void>
}

/**
 * Base Simple Dialog, customized for model switch flow (used in model card and model header)
 * @param {string} modelState current model state
 * @param {function} onAccept handler for switching action
 * @returns {React.ReactElement}
 */
export function ModelSwitchDialog(props: SwitchModelDialogProps): React.ReactElement {
  const { modelState, onAccept, mode } = props

  const modelStates = [
    { name: "Idle", description: "Doesn't run predictions", state: "disabled" },
    { name: "Challenger", description: "Challenges the live model", state: "challenger" },
    { name: "Live", description: "For production", state: "live" },
  ]

  const adjustedModelStates = modelStates.filter((model) => model.state !== modelState)

  const [newModelState, setNewModelState] = useState<string>(adjustedModelStates[0]?.state)

  return (
    <BaseSimpleDialog {...props} onAccept={() => onAccept(newModelState as string)}>
      <Grid item xs={12} display="flex" flexDirection="column">
        {adjustedModelStates.map((model) => (
          <Grid item xs={12} mb={2}>
            <RadioButton
              value={newModelState}
              label={model.name}
              id={model.name}
              name={model.state}
              checked={newModelState === model.state}
              onChange={(e) => setNewModelState(e.target.name)}
              description={model.description}
            />
          </Grid>
        ))}
      </Grid>

      {/* hide this sub text if any other mode is rendered */}
      {mode === "model-switch" && (
        <Grid item xs={12} mt={2}>
          <Typography variant="p" gutterBottom variantColor={2}>
            You can switch this back later
          </Typography>
        </Grid>
      )}
    </BaseSimpleDialog>
  )
}
