import React, { useContext } from "react"

import BatchPredictionOutlinedIcon from "@mui/icons-material/BatchPredictionOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import { Grid } from "@mui/material"
import { Tab, Tabs } from "@synapse-analytics/synapse-ui"

import { useHandlePageQueryParam } from "../../../../hooks/useHandlePageQueryParam"
import { CurrentProjectAndModelContext } from "../../../../store/CurrentProjectAndModelContext"
import { ModelConfigurations } from "./components/ModelConfigurations/ModelConfigurations"
import { Models } from "./components/Models/Models"

const getDecisionEngine = (engine: string): React.ReactElement => {
  switch (engine) {
    case "Models":
      return <Models />
    case "Configuration":
      return <ModelConfigurations />
    default:
      return <Models />
  }
}

/**
 * Decision engines component
 * home to Konan Decision engines
 * Models, Rulesets, Workflows, Scorecards, Model Configuration
 * @return {React.ReactElement}
 */
export function DecisionEngines(): React.ReactElement {
  const [value, setTabValue] = useHandlePageQueryParam(["Models", "Configuration"], "Models")
  const { currentProject } = useContext(CurrentProjectAndModelContext)

  return (
    <Grid container>
      {currentProject && currentProject.type !== "generic" && (
        <Grid item xs={12}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <Tabs value={value} className="base-tabs">
            <Tab
              icon={() => <BatchPredictionOutlinedIcon fontSize="small" />}
              label="Models"
              value={"Models"}
              selected={value === "Models"}
              onClick={() => setTabValue("Models")}
            />
            <Tab
              icon={() => <SettingsOutlinedIcon fontSize="small" />}
              label="Configuration"
              value="Configuration"
              selected={value === "Configuration"}
              onClick={() => setTabValue("Configuration")}
            />
          </Tabs>
        </Grid>
      )}

      <Grid item xs={12}>
        {getDecisionEngine(value)}
      </Grid>
    </Grid>
  )
}
