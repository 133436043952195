import React, { Fragment, useMemo, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import CloseIcon from "@mui/icons-material/Close"
import CodeIcon from "@mui/icons-material/Code"
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import { Button, InputText, NotificationUtils, Skeleton, Tab, Tabs, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FormikProps, useFormik } from "formik"

import { BuiltinScriptCreationStep } from "."
import { InfoContainer } from "../../components/InfoContainer"
import { ErrorStateWithRefetch } from "../../components/UI/ErrorStateWithRefetch"
import { InfoBlock } from "../../components/containers/InfoBlock"
import { KeyValueBlock } from "../../components/dialogs/components/KeyValueBlock"
import { TemplateFeaturesTable } from "../../components/tables/TemplateFeaturesTable"
import { getTheme } from "../../hooks/UseTheme"
import { KonanAPI } from "../../services/KonanAPI"
import {
  CreateBuiltinScriptRequest,
  FeatureMapping,
  SchemaFeature,
  UpdateScriptRequest,
} from "../../types/custom/workflows"
import { PaginatedScriptGroupListList } from "../../types/generated/api/PaginatedScriptGroupListList"
import { ScriptGroupRetrieve } from "../../types/generated/api/ScriptGroupRetrieve"
import { ScriptSchema } from "../../types/generated/api/ScriptSchema"
import { ScriptTemplateRetrieve } from "../../types/generated/api/ScriptTemplateRetrieve"
import { mapFeaturesListToKeyValuePair } from "../../utils/deploymentDetailsHelpers"
import { CustomScriptLogs } from "./components/CustomScriptComponents"

import styles from "./Scripts.module.scss"

// Yup validation schema
const validationSchema = Yup.object({
  scriptName: Yup.string().required("Script name is required"),
})

interface ScriptDialogProps {
  isOpen: boolean
  onClose: (value?: string) => void
  scriptInfo?: { name?: string; uuid?: string; template?: string }
  workflowMode?: "edit" | "create" | "read"
  isNewScriptTriggered?: boolean
  renderView?: "workflow" | "scripts"
  openInEditMode?: boolean
  workflowSchema?: Array<SchemaFeature>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workflowFormik?: FormikProps<any>
  tab?: "Logs"
  setFormikScriptData?: (scriptSchema: ScriptSchema) => void
}

// Type definition for the initial form state of the script.
export type BaseFormState = {
  /** Name of the script */
  scriptName: string
  /** Step in the creation process, numerically represented */
  creationStep: number
  /** UUID of the template being used */
  templateUUID: string
}

export interface InitialFormState extends BaseFormState, DataRecordForm {
  /**  Optional array of feature mappings */
  feature_mappings?: Array<FeatureMapping>
  /**  A snapshot of the form state that is of the same type as BaseFormState */
  lastSnapshot: BaseFormState & Pick<DataRecordForm, "creds">
}

export interface Cred {
  feat: string
  value: string
  type: string
  isRequired: boolean
}

export interface DataRecordForm {
  creds: Array<Cred>
  feature_mappings?: Array<FeatureMapping>
}

/**
 * Represents a dialog component for managing built-in scripts. This includes creating new scripts,
 * editing existing ones, and viewing details. It handles various states and modes such as edit, create,
 * and read. The dialog integrates with various backend services to fetch and update scripts or create new ones.
 *
 * @param {boolean} isOpen Controls the visibility of the dialog.
 * @param {Function} onClose Callback function to execute when the dialog is closed.
 * @param {object} [scriptInfo] Contains optional details of the script like name and UUID.
 * @param {"edit" | "create" | "read"} [workflowMode] Specifies the mode of the dialog related to workflow operations.
 * @param {boolean} [isNewScriptTriggered] Indicates if a new script creation was initiated.
 * @param {number} [scriptsCount] Number of scripts, if needed for rendering or calculations.
 * @param {"workflow" | "scripts"} [renderView] Specifies the context in which the dialog is used.
 * @param {boolean} [openInEditMode] Determines if the script should open in edit mode directly.
 * @param {Array<SchemaFeature>} [workflowSchema] Schema definition for workflows, if applicable.
 * @param {FormikProps<any>} [workflowFormik] Formik props for managing form state in workflow contexts.
 *
 * @returns {React.ReactElement} A React element representing the built-in script dialog.
 */
export function BuiltinScriptDialog(props: Readonly<ScriptDialogProps>): React.ReactElement {
  const {
    isOpen,
    onClose,
    renderView,
    openInEditMode = false,
    isNewScriptTriggered = false,
    scriptInfo,
    workflowSchema,
    workflowFormik,
    workflowMode,
    tab,
    setFormikScriptData,
  } = props
  const { id: projectId } = useParams<{ id: string }>()

  const theme = getTheme()
  const MuiTheme = useMuiTheme()

  const queryClient = useQueryClient()

  const [selectedState, setSelectedState] = useState<"Script Setup" | "Logs">(tab ?? "Script Setup")
  const [toggleOpenInEditMode, setToggleOpenInEditMode] = useState<boolean>(openInEditMode)
  // state to handle the current mode
  const [mode, setMode] = useState<{ create: boolean; edit: boolean }>({
    create: isNewScriptTriggered,
    edit: Boolean(openInEditMode),
  })

  const isScriptInViewMode = !mode.create && !mode.edit

  // fetch scripts
  const { data: scriptsData, isLoading: isScriptsDataLoading } = useQuery<
    AxiosResponse<PaginatedScriptGroupListList>,
    AxiosError
  >(["scripts", projectId], () => KonanAPI.fetchScripts(projectId as string), {
    enabled: !!projectId,
  })

  const updateScriptMutation = useMutation<
    AxiosResponse<ScriptGroupRetrieve>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    UpdateScriptRequest
  >(KonanAPI.updateScript, {
    onSuccess: async (res) => {
      NotificationUtils.toast(`Script (${res?.data?.name}) updated successfully!`, {
        snackBarVariant: "positive",
      })
      onClose(res?.data?.uuid)
      setTimeout(() => {
        setMode({ edit: false, create: false })
      }, 300)

      queryClient.invalidateQueries(["scripts", projectId])
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: async (response: any) => {
      if (response?.response?.data?.details) {
        NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
          snackBarVariant: "negative",
        })
      } else if (response?.response?.data?.name) {
        NotificationUtils.toast(response?.response?.data?.name[0], {
          snackBarVariant: "negative",
        })
      } else if (response?.response?.data?.length > 0) {
        NotificationUtils.toast(response?.response?.data[0], {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while updating script, please try again!", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  // create builtin script
  const createBuiltinScriptMutation = useMutation<AxiosResponse, AxiosError, CreateBuiltinScriptRequest>(
    KonanAPI.createBuiltinScript,
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries(["ready-templates", projectId])
        await queryClient.invalidateQueries(["scripts", projectId])
        NotificationUtils.toast("Script successfully created!", {
          snackBarVariant: "positive",
        })
        onClose(res?.data?.uuid)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError(error: any) {
        if (error?.response?.data?.details) {
          NotificationUtils.toast(error?.response?.data?.details, {
            snackBarVariant: "negative",
          })
        } else if (error?.response?.data?.length > 0) {
          NotificationUtils.toast(error?.response?.data[0], {
            snackBarVariant: "negative",
          })
        } else if (error?.response?.data?.file?.length > 0) {
          NotificationUtils.toast(error?.response?.data?.file[0], {
            snackBarVariant: "negative",
          })
        } else {
          NotificationUtils.toast("An error occurred while creating your Script.", {
            snackBarVariant: "negative",
          })
        }
      },
    },
  )

  // fetch current script
  const { data: scriptData, isLoading: isScriptDataLoading } = useQuery<AxiosResponse<ScriptGroupRetrieve>, AxiosError>(
    ["script", projectId, scriptInfo?.uuid],
    () =>
      KonanAPI.fetchScript({
        projectUUID: projectId as string,
        scriptUUID: scriptInfo?.uuid as string,
      }),
    { enabled: !!projectId && !!scriptInfo?.uuid },
  )

  // current selected script from the dropdown
  const selectedScript = useMemo(() => {
    // first check if script in the script listing
    if (scriptData) {
      return scriptData?.data
    }
    // check if script exists in scriptInfo in case for example, the retrieved script is actually deleted
    // so in this case we need the script info but the script itself won't exist in the scripts lisiting
    // so we extract its data from scriptInfo (retrieved script node data)
    else if (isScriptInViewMode && scriptInfo?.uuid && scriptInfo?.template) {
      return scriptInfo
    }
  }, [scriptData, isScriptInViewMode, scriptInfo])

  const initialFormState: InitialFormState = {
    scriptName: scriptInfo?.name ?? selectedScript?.name ?? "",
    creationStep: 1,
    templateUUID: "",
    creds: [],
    lastSnapshot: {
      scriptName: scriptInfo?.name ?? selectedScript?.name ?? "",
      creationStep: 1,
      templateUUID: "",
      creds: [],
    },
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialFormState,
    onSubmit: async ({ scriptName, templateUUID, creds }) => {
      const filteredFeatures = mapFeaturesListToKeyValuePair(creds)

      if (isNewScriptTriggered) {
        const baseMutationParams = {
          project_uuid: projectId as string,
          template_uuid: templateUUID,
          name: scriptName,
        }
        const mutationParams =
          creds?.length > 0 && Object.keys(filteredFeatures)?.length > 0
            ? { ...baseMutationParams, creds: filteredFeatures }
            : baseMutationParams

        await createBuiltinScriptMutation.mutateAsync(mutationParams)
      } else {
        const baseMutationParams = {
          name: formik.values.scriptName,
          project_uuid: projectId as string,
          script_uuid: selectedScript?.uuid as string,
        }

        const mutationParams =
          creds?.length > 0 && Object.keys(filteredFeatures)?.length > 0
            ? { ...baseMutationParams, creds: filteredFeatures }
            : baseMutationParams

        await updateScriptMutation.mutateAsync(mutationParams)
      }
    },
  })

  // fetch origin template
  const {
    data: templateData,
    isLoading: isTemplateDataLoading,
    isFetching: isTemplateDataFetching,
    isError: isFetchingTemplateDataError,
    refetch: refetchTemplate,
  } = useQuery<AxiosResponse<ScriptTemplateRetrieve>, AxiosError>(
    ["ready-template", projectId, selectedScript, formik.values.templateUUID, scriptInfo],
    () =>
      KonanAPI.fetchReadyTemplate({
        projectUUID: projectId as string,
        templateUUID: isNewScriptTriggered
          ? formik.values.templateUUID
          : (selectedScript?.template as string) ?? scriptInfo?.template,
      }),
    {
      onSuccess: (response) => {
        const formatResponseToCredsKeyValue = response?.data?.schema?.secrets?.map((cred) => ({
          feat: cred?.name,
          value: "",
          type: cred?.type,
          isRequired: cred?.is_required,
        }))

        formik.setFieldValue("creds", formatResponseToCredsKeyValue)

        setFormikScriptData?.(response.data.schema)
      },
      enabled:
        !!projectId &&
        (!!formik.values.templateUUID || !!selectedScript?.template || !!scriptInfo?.template) &&
        !(isNewScriptTriggered && formik.values.creationStep === 1),
    },
  )

  const handleSelectingTemplate = (templateId: string): void => {
    formik.setFieldValue("templateUUID", templateId)
  }

  const handleCancelClicked = (): void => {
    setMode({ edit: false, create: false })

    if (toggleOpenInEditMode) {
      formik.setFieldValue("scriptName", scriptInfo?.name)
      setToggleOpenInEditMode(false)
    } else {
      setTimeout(() => {
        formik.setFieldValue("creds", formik.values.lastSnapshot.creds)
        formik.setFieldValue("templateUUID", formik.values.lastSnapshot.templateUUID)
        formik.setFieldValue("creationStep", formik.values.lastSnapshot.creationStep)

        formik.setFieldValue("scriptName", selectedScript?.name ?? formik.values.lastSnapshot.scriptName)
      }, 10)
    }
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={isNewScriptTriggered && formik.values.creationStep === 1 ? "sm" : "md"}
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("md"))}
      onClose={() => {
        onClose()
      }}
    >
      <DialogTitle className="dialog-header-base" style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2-bold">BUILT-IN SCRIPT</Typography>
        <IconButton
          onClick={() => {
            onClose()
          }}
          size="small"
          className={"close-icon-button"}
        >
          <CloseIcon style={{ color: theme.palette.grayscale.text[2] }} />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.scriptDialogContent}>
        <Grid container item xs={12}>
          <Grid
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "space-between", md: "center" },
              justifyContent: { xs: "flex-start", md: "space-between" },
            }}
            item
            container
            p={2}
            pb={0}
          >
            <Grid item xs height="fit-content" container justifyContent="flex-start">
              <Grid item className={styles.scriptSideBar}>
                <CodeIcon sx={{ color: theme.palette.grayscale.text[1] }} />
              </Grid>

              <Grid item xs container direction="column" gap={0.9}>
                <Grid item>
                  <Typography variant="label" variantColor={2}>
                    BUILT-IN SCRIPT
                  </Typography>
                </Grid>

                <Grid justifySelf="flex-end" item xs mt={mode.create || mode.edit ? "-7px" : "5px"} container>
                  {isScriptInViewMode && !(selectedScript?.name || scriptInfo?.name) ? (
                    <Grid item mt={1}>
                      <Skeleton width={"60%"} height={18} />
                    </Grid>
                  ) : isScriptInViewMode && (selectedScript?.name || scriptInfo?.name) ? (
                    <Typography variant="h3-bold">{selectedScript?.name ?? scriptInfo?.name}</Typography>
                  ) : (
                    <InputText
                      disabled={formik.isSubmitting}
                      type="text"
                      handleChange={formik.handleChange}
                      value={formik.values.scriptName}
                      id="scriptName"
                      hideDescription
                      widthCalculator
                      placeholder="Script Name"
                      error={formik.touched.scriptName && Boolean(formik.errors.scriptName) && formik.errors.scriptName}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* Control buttons (edit/save/cancel) */}
            {!(isNewScriptTriggered && formik.values.creationStep === 1) && renderView !== "workflow" && (
              <Grid
                item
                mt={1}
                xs
                md={6}
                gap={1}
                justifyContent="flex-end"
                container
                height="fit-content"
                sx={{
                  justifyContent: { xs: "flex-start", md: "flex-end" },
                }}
              >
                {isScriptInViewMode ? (
                  <Button
                    onClick={() => {
                      setMode({ create: false, edit: true })
                      setTimeout(() => {
                        formik.setFieldValue("lastSnapshot", {
                          scriptName: formik.values.scriptName,
                          creds: formik.values.creds,
                          creationStep: formik.values.creationStep,
                          templateUUID: formik.values.templateUUID,
                        })
                      }, 10)
                    }}
                    disabled={formik.isSubmitting || isScriptsDataLoading}
                    variant="secondary"
                  >
                    Edit
                  </Button>
                ) : (
                  <Fragment>
                    {mode.edit && !isNewScriptTriggered && (
                      <Button
                        disabled={createBuiltinScriptMutation.isLoading || updateScriptMutation.isLoading}
                        onClick={handleCancelClicked}
                        variant="secondary"
                      >
                        cancel
                      </Button>
                    )}
                  </Fragment>
                )}

                {isNewScriptTriggered && formik.values.creationStep === 2 && (
                  <Button
                    disabled={createBuiltinScriptMutation.isLoading || updateScriptMutation.isLoading}
                    onClick={() => formik.setFieldValue("creationStep", 1)}
                    variant="secondary"
                  >
                    Back
                  </Button>
                )}

                {(mode.create || mode.edit) && (
                  <Button
                    disabled={
                      createBuiltinScriptMutation.isLoading ||
                      updateScriptMutation.isLoading ||
                      !formik.values.scriptName
                    }
                    onClick={formik.submitForm}
                    variant="primary"
                  >
                    {createBuiltinScriptMutation.isLoading || updateScriptMutation.isLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                )}
              </Grid>
            )}

            <Grid container item xs={12} mt={1}>
              {!isNewScriptTriggered && (
                <Tabs value={selectedState}>
                  {["Script Setup", ...(isNewScriptTriggered ? [] : ["Logs"])].map((label) => (
                    <Tab
                      key={label}
                      label={label === "Logs" ? "Historical Logs" : label}
                      value={label}
                      selected={label === selectedState}
                      onClick={() => setSelectedState(label as "Script Setup" | "Logs")}
                    />
                  ))}
                </Tabs>
              )}
            </Grid>
          </Grid>

          {/* Template info section (input features, output features, description, etc..) */}
          <Grid
            container
            mt={selectedState === "Logs" ? 1 : 0}
            p={selectedState === "Logs" ? 0 : 2}
            item
            flexDirection="column"
          >
            {selectedState === "Logs" ? (
              <CustomScriptLogs uuid={scriptData?.data.uuid as string} />
            ) : (
              <Fragment>
                {isTemplateDataLoading || isScriptDataLoading ? (
                  <Grid item xs={12} container minHeight={"80%"}>
                    <InfoContainer icon={<CircularProgress />} title="Loading Template info..." />
                  </Grid>
                ) : isNewScriptTriggered && formik.values.creationStep === 1 ? (
                  <BuiltinScriptCreationStep
                    onClose={onClose}
                    onUseTemplateClick={() => formik.setFieldValue("creationStep", 2)}
                    onTemplateSelect={(templateId: string) => handleSelectingTemplate(templateId)}
                    selectedTemplate={formik.values.templateUUID}
                    scripts={scriptsData?.data?.results}
                  />
                ) : (
                  <Fragment>
                    {isFetchingTemplateDataError ? (
                      <Grid container item height={400}>
                        <ErrorStateWithRefetch
                          onRefetch={() => refetchTemplate()}
                          isFetching={isTemplateDataFetching || isTemplateDataLoading}
                          name="script template"
                        />
                      </Grid>
                    ) : (
                      renderView !== "workflow" && (
                        <Fragment>
                          {/* Description */}
                          {templateData?.data?.description && (
                            <Fragment>
                              <Typography variant="h3-bold" gutterBottom>
                                Description
                              </Typography>

                              <Typography variant="p" variantColor={2}>
                                {templateData?.data?.description}
                              </Typography>
                            </Fragment>
                          )}

                          {/* Creds */}
                          {templateData?.data?.schema &&
                            templateData?.data?.schema?.secrets?.length > 0 &&
                            (formik.values.creds?.length > 0 ? (
                              <Grid item mt={3}>
                                <Typography variant="h3-bold" gutterBottom>
                                  Credentials
                                </Typography>

                                <KeyValueBlock
                                  isViewMode={isScriptInViewMode}
                                  formik={formik as FormikProps<InitialFormState | DataRecordForm>}
                                  isCreds={true}
                                  usedCreds={scriptData?.data?.active_version.creds_keys}
                                  valueListOptions={workflowSchema?.filter((feature) => feature?.name?.length > 0)}
                                  type="Script"
                                />
                              </Grid>
                            ) : (
                              <InfoContainer icon={<CircularProgress />} title="Loading Creds..." />
                            ))}

                          {/* Output features */}
                          {templateData?.data?.schema && templateData?.data?.schema?.output_features?.length > 0 && (
                            <Grid item mt={3}>
                              <Typography variant="h3-bold" gutterBottom>
                                Expected Outputs
                              </Typography>
                              <TemplateFeaturesTable featuresList={templateData?.data?.schema?.output_features} />
                            </Grid>
                          )}
                        </Fragment>
                      )
                    )}

                    {/* Input Features */}
                    {templateData?.data?.schema && (
                      <Grid item mt={renderView === "workflow" ? 1 : 3}>
                        <Typography variant="h3-bold" gutterBottom>
                          {renderView === "workflow" && workflowMode !== "read"
                            ? `Map Script Input Features (Optional)`
                            : "Script Input Features"}
                        </Typography>
                        <Typography variant="p" variantColor={2} gutterBottom>
                          By default, a Script feature is expected to be available, with the same name, in the Workflow
                          Schema or as a calculated feature. Alternatively, a Script feature can be mapped to a
                          differently-named feature that already exists in the Workflow Schema or as a calculated
                          feature.
                        </Typography>

                        {renderView === "workflow" &&
                        (isTemplateDataFetching || isTemplateDataLoading || isScriptDataLoading) ? (
                          <InfoContainer icon={<CircularProgress />} title="Loading feature mappings.." />
                        ) : renderView === "workflow" ? (
                          workflowFormik?.values?.feature_mappings?.length === 0 ? (
                            <Grid item xs={12} display={"flex"} mt={1}>
                              <InfoBlock text={"No features found for this script"} bordered alignText="center" />
                            </Grid>
                          ) : (
                            <Grid mt={1}>
                              <KeyValueBlock
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                formik={workflowFormik as FormikProps<any>}
                                isCreds={false}
                                isViewMode={workflowMode === "read"}
                                valueListOptions={workflowSchema?.filter((feature) => feature?.name?.length > 0)}
                                inputFeatures={templateData?.data?.schema?.input_features}
                                type="Script"
                                enableSelfMappingUnmappedValues
                              />
                            </Grid>
                          )
                        ) : (
                          <TemplateFeaturesTable featuresList={templateData?.data?.schema?.input_features} />
                        )}

                        {/* Output features */}
                        {renderView === "workflow" &&
                          templateData?.data?.schema &&
                          templateData?.data?.schema?.output_features?.length > 0 && (
                            <Grid item mt={3}>
                              <Typography variant="h3-bold" gutterBottom>
                                Expected Outputs
                              </Typography>
                              <TemplateFeaturesTable featuresList={templateData?.data?.schema?.output_features} />
                            </Grid>
                          )}
                      </Grid>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
