import { v4 as uuidv4 } from "uuid"

import { Operators } from "../../../../../../types/custom/projects"
import { ScorecardCondition } from "../../../../../../types/custom/rules"

/**  Scorecard related constants values */
export const EMPTY_SCORECARD_CONDITION: ScorecardCondition = {
  feature: "",
  valueOrFeature: "Value",
  value: "",
  type: "string",
  secondValue: "",
  secondFeature: "",
  return_value: "",
  rule: undefined,
  id: uuidv4(),
  andOr: "and",
  operator: Operators["="],
  ruleName: "",
}
