/* generated using openapi-typescript-codegen -- do not edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { ExportJobModel } from "./ExportJobModel"

export type ExportJobRetrieve = {
  readonly uuid: string
  /**
   * This name will be taken as the exported csv file name. It allows all characters except [
   * ,     ,  , .]
   */
  name: string
  /**
   * * `DEPLOYMENT_PREDICTIONS` - Deployment Predictions
   * * `MODEL_PREDICTIONS` - Model Predictions
   * * `SIMULATION_PREDICTIONS` - Simulation Predictions
   */
  type: ExportJobRetrieve.type
  readonly created_at: string
  model: ExportJobModel | null
  simulation_job: string | null
  /**
   * Represents the time the export script was started
   */
  started_at?: string | null
  /**
   * Represents the time the export job finished
   */
  ended_at?: string | null
  created_by: string
  /**
   * * `PENDING` - Pending
   * * `RUNNING` - Running
   * * `SUCCEEDED` - Succeeded
   * * `FAILED` - Failed
   * * `CANCELLED` - Cancelled
   */
  status?: ExportJobRetrieve.status
  /**
   * Prediction file output by the job
   */
  predictions_file?: string | null
  predictions_start_time?: string | null
  predictions_end_time?: string | null
  predictions_count?: number | null
  requested_predictions_count?: number | null
}
export namespace ExportJobRetrieve {
  /**
   * * `DEPLOYMENT_PREDICTIONS` - Deployment Predictions
   * * `MODEL_PREDICTIONS` - Model Predictions
   * * `SIMULATION_PREDICTIONS` - Simulation Predictions
   */
  export enum type {
    DEPLOYMENT_PREDICTIONS = "DEPLOYMENT_PREDICTIONS",
    MODEL_PREDICTIONS = "MODEL_PREDICTIONS",
    SIMULATION_PREDICTIONS = "SIMULATION_PREDICTIONS",
  }
  /**
   * * `PENDING` - Pending
   * * `RUNNING` - Running
   * * `SUCCEEDED` - Succeeded
   * * `FAILED` - Failed
   * * `CANCELLED` - Cancelled
   */
  export enum status {
    PENDING = "PENDING",
    RUNNING = "RUNNING",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
    CANCELLED = "CANCELLED",
  }
}
