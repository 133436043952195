import React, { Fragment, useCallback, useEffect, useState } from "react"

import ReactGA from "react-ga4"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

import * as Yup from "yup"
import styled from "@emotion/styled"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import CloseIcon from "@mui/icons-material/Close"
import {
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material"
import { Button, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"
import { OpenAPIV3 } from "openapi-types"

import { getTheme } from "../../hooks/UseTheme"
import {
  ConfigurationForm,
  handleSubmit as getConfiguration,
} from "../../screens/ProjectDetails/components/DecisonEngines/components/ModelConfigurations/Components/ModelConfiguration/components/ConfigurationForm"
import { DryRunForm } from "../../screens/ProjectDetails/components/DecisonEngines/components/ModelConfigurations/Components/ModelConfiguration/components/DryRunForm"
import { KonanAPI } from "../../services/KonanAPI"
import {
  ClassificationConfiguration,
  CreateAutoMlRequest,
  CreateModelRequest,
  CreateModelResponse,
  CreateProjectRequest,
  CustomFeatureRequest,
  TestPrediction,
  TrainModelUsingPrebuiltModelRequest,
  baseErrorType,
} from "../../types/custom/projects"
import { Deployment } from "../../types/generated/api/Deployment"
import { ExcludedFeatureRequest } from "../../types/generated/api/ExcludedFeatureRequest"
import { TestPredictionResponse } from "../../types/generated/api/TestPredictionResponse"
import { TrainingDataSchemaResponse } from "../../types/generated/api/TrainingDataSchemaResponse"
import { mapResponseTypesToTabValues } from "../../utils/deploymentDetailsHelpers"
import { getHostnames } from "../../utils/genericHelpers"
import { extractPredictionKeysFromOpenapi } from "../../utils/modelDetailsHelpers"
import { DataMappingFlow } from "./components/DataMappingFlow"
import { ModelCreationForm } from "./components/ModelCreationForm"
import { PostCreationDialog } from "./components/PostCreationDialog"
import { ProjectCreationOverview } from "./components/ProjectCreationOverview"
import { UploadTrainingDataFlow } from "./components/UploadTrainingDataFlow"

import Styles from "./Projects.module.scss"

// A new component based on SUI Button, but with some override styles
const MarginalizedButton = styled(Button)`
  margin-right: 8px;

  @media (max-width: 1385px) {
    margin-bottom: 8px;
  }
`
// Project form validation schema object
const projectValidationSchema = Yup.object({
  name: Yup.string().max(30, "Must be 30 characters or less").required("Required"),
  description: Yup.string().max(200, "Must be 200 characters or less"),
  type: Yup.string().required("Required"),
})

// Model form validation schema object
const modelValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
  registry: Yup.string().required("Required"),
  image_url: Yup.string().required("Required"),
  exposed_port: Yup.number().notOneOf([8008, 9090], "Restricted port number").required("Required"),
  docker_username: Yup.string().max(50, "Must be 50 characters or less"),
  docker_password: Yup.string().max(50, "Must be 50 characters or less"),
})

// Configuration form validation schema object
const configurationValidationSchema = Yup.object({
  target: Yup.string().required("Required"),
  lowerLimit: Yup.number().required("Required"),
  upperLimit: Yup.number().required("Required"),
  step: Yup.number(),
})

//AutoML formik validation
const autoMlValidation = Yup.object({
  name: Yup.string().required("Required"),
  target_column: Yup.string().required("Required"),
  targetColumnType: Yup.string(),
})

/**
 * Project creation flow dialog container
 * @return  {<React.ReactElement />}
 */
export function ProjectCreationFlow(): React.ReactElement {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const theme = getTheme()

  const [createdProjectUUID, setCreatedProjectUUID] = useState<string>("")
  const [activeStep, setActiveStep] = useState<number>(0)
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
  const [requestJsonError, setRequestJsonError] = useState<boolean>(false)
  const [trainingDataUUID, setTrainingDataUUID] = useState<string>("")

  const [autoMlData, setAutoMlData] = useState<{
    mapping: Array<CustomFeatureRequest>
    excludedColumns: Array<ExcludedFeatureRequest>
  }>({
    mapping: [],
    excludedColumns: [],
  })

  // Request mutations
  const createProjectMutation = useMutation<AxiosResponse<Deployment>, AxiosError<baseErrorType>, CreateProjectRequest>(
    KonanAPI.createProject,
  )

  const createModelMutation = useMutation<
    AxiosResponse<CreateModelResponse>,
    AxiosError<baseErrorType>,
    CreateModelRequest
  >(KonanAPI.createModel)

  const predictionsTest = useMutation<AxiosResponse<TestPredictionResponse>, AxiosError<baseErrorType>, TestPrediction>(
    KonanAPI.testPrediction,
  )

  const classificationConfigurationMutation = useMutation<
    AxiosResponse,
    AxiosError<baseErrorType>,
    ClassificationConfiguration
  >(KonanAPI.createModelConfiguration)

  const trainModelUsingPrebuiltModel = useMutation<
    AxiosResponse,
    AxiosError<baseErrorType>,
    TrainModelUsingPrebuiltModelRequest
  >(KonanAPI.trainModelUsingPrebuiltModel)

  // Fetch project docs using project ID in route
  const { isLoading: isLoadingOpenApiData, data: dryRunRequest } = useQuery<
    AxiosResponse<OpenAPIV3.Document>,
    AxiosError<baseErrorType>
  >(
    ["project-docs", createProjectMutation.data?.data.uuid],
    () => KonanAPI.fetchProjectDocs(createProjectMutation.data?.data.uuid as string),
    {
      retry: true,

      enabled: createModelMutation.isSuccess,
    },
  )

  const {
    mutateAsync: trainModelUsingAutoMlModel,
    isError: isAutoMlError,
    error,
    data: trainingModelAutomlData,
    isLoading,
    isSuccess: isAutomlSuccess,
  } = useMutation<AxiosResponse, AxiosError<baseErrorType>, CreateAutoMlRequest>(KonanAPI.trainModelUsingAutoMlModel)

  const { data: trainingSchema, isLoading: isTrainingSchemaLoading } = useQuery<TrainingDataSchemaResponse, AxiosError>(
    ["training-data-schema", trainingDataUUID],
    () => KonanAPI.fetchTrainingDataSchema(createProjectMutation.data?.data.uuid as string, trainingDataUUID),
    {
      enabled: !!trainingDataUUID,
    },
  )

  // Project creation form control
  const projectFormikValues = useFormik({
    initialValues: {
      name: "",
      description: "",
      type: "",
      category: "model",
    },
    validationSchema: projectValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsBtnLoading(true)

        ReactGA.event({
          category: "Deployments",
          action: "Creating new deployment",
        })

        const response = await createProjectMutation.mutateAsync({
          name: values.name,
          type:
            values.type === "credit_scoring_auto-ml" || values.type === "credit-scoring_pre-built"
              ? "credit_scoring"
              : values.type,
          category: values.category,
          description: values.description || undefined,
        })
        setCreatedProjectUUID(response.data.uuid)
        setActiveStep(activeStep + 1)
        setIsBtnLoading(false)

        queryClient.invalidateQueries("projects")
      } catch (e) {
        setIsBtnLoading(false)

        ReactGA.event({
          category: "Deployments",
          action: "Deployment contains errors",
        })
      }
    },
  })

  // Model creation form control
  const modelFormikValues = useFormik({
    initialValues: {
      name: "",
      image_url: "",
      registry: "KCR",
      exposed_port: 8000,
      docker_username: "",
      docker_password: "",
    },
    validationSchema: modelValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsBtnLoading(true)
        ReactGA.event({
          category: "Deployments",
          action: "Creating new model",
        })
        await createModelMutation.mutateAsync({
          project_uuid: createdProjectUUID,
          name: values.name,
          image_url: values.image_url,
          exposed_port: values.exposed_port,
          docker_password: values.docker_password || undefined,
          docker_username: values.docker_username || undefined,
          state: "live",
        })
        setIsBtnLoading(false)

        queryClient.invalidateQueries(["overviewModels", createdProjectUUID])
        queryClient.invalidateQueries("models")
      } catch (e) {
        setIsBtnLoading(false)
        ReactGA.event({
          category: "Models",
          action: "model contains errors",
        })
      }
    },
  })

  // initial AutoML form control
  const AutoMlFormikValues = useFormik({
    initialValues: {
      name: "",
      target_column: "",
      targetColumnType: "default",
    },
    validationSchema: autoMlValidation,
    onSubmit: async ({ name, target_column }) => {
      setIsBtnLoading(true)
      try {
        await trainModelUsingAutoMlModel({
          projectUUID: createdProjectUUID ?? "",
          name: name !== "" ? name : "",
          mapping: autoMlData?.mapping,
          excluded_columns: autoMlData?.excludedColumns,
          target_column: target_column,

          training_data: trainingDataUUID !== "" && trainingDataUUID !== "sample" ? trainingDataUUID : "",
        })
      } catch (error) {
        console.error(error)
      }
      setIsBtnLoading(false)
    },
  })

  const modelCreationError =
    (createModelMutation.error?.response?.status === 500 && "An error occurred please try again later") ||
    createModelMutation.error?.response?.data.name ||
    createModelMutation.error?.response?.data.details

  const isFailedLogs = !!createModelMutation.error?.response?.data?.container_logs

  // Dry run form control
  const DryRunFormikValues = useFormik({
    initialValues: {
      request: "{}",
      // adding as a place holder
      model: "default",
    },
    validationSchema: Yup.object({
      request: Yup.string().required("Request body is required!"),
    }),
    onSubmit: async (values) => {
      try {
        const requestJson = JSON.parse(values.request)
        setRequestJsonError(false)

        try {
          await predictionsTest.mutateAsync({
            model_uuid: createModelMutation.data?.data.model.uuid || "",
            requestJson: {
              ...requestJson,
            },
          })
        } catch (e) {
          console.warn(e)
        }
      } catch (e) {
        setRequestJsonError(true)
      }
    },
  })

  // Model configuration form control
  const configurationFormikValues = useFormik({
    initialValues: {
      dataType: "",
      reDataType: "",
      target: "",
      lowerLimit: 0,
      upperLimit: 100,
      step: 1,
    },
    validationSchema: configurationValidationSchema,
    onSubmit: async () => {
      const requestObject = getConfiguration()

      // TODO:: Should be refactored to make the dialog use formik and validate using yup
      // cant do that now since i will have to refactor the whole form to make it work
      requestObject?.settings?.forEach((setting) => {
        if (setting.label === "") {
          NotificationUtils.toast("Please add labels to your range configurations", { snackBarVariant: "negative" })
          throw new Error("Please add labels to your range configurations")
        }
      })

      setIsBtnLoading(true)

      await classificationConfigurationMutation
        .mutateAsync({
          ...requestObject,
        })
        .then(() => {
          queryClient.invalidateQueries("activeConfig")
        })

      NotificationUtils.toast("Model configured successfully", { snackBarVariant: "positive" })

      setIsBtnLoading(false)
    },
  })

  // Dry run response
  const TestResponse = predictionsTest.isError
    ? predictionsTest.error?.response?.data.ml_service_output
    : predictionsTest.data?.data.output

  const responseData = !TestResponse ? "// Execute to see response" : JSON.stringify(TestResponse, null, "  ")

  /**
   * stepper helper function
   * to decide which disable helper function to use based on the project type
   * @return  {boolean}
   */
  const shouldDisableNext = (): boolean => {
    switch (projectFormikValues.values.type) {
      case "generic":
        return shouldDisableGeneric()
      case "classification":
        return shouldDisableClassification()
      case "credit_scoring_pre-built":
        return shouldDisableCreditScoring()
      case "credit_scoring_auto-ml":
        return shouldDisableCreditScoringAutoml()

      default:
        return shouldDisableGeneric()
    }
  }

  /**
   * credit scoring helper function
   * trains prebuilt model for credit scoring project
   * @return  {Promise<void>}
   */
  const handleTrainClick = async (type: "auto-ml" | "pre-built"): Promise<void> => {
    setIsBtnLoading(true)
    if (type === "pre-built") {
      await trainModelUsingPrebuiltModel.mutateAsync({
        projectUUID: createdProjectUUID,
        modelName: AutoMlFormikValues.values.name !== "" ? AutoMlFormikValues.values.name : undefined,
        trainingDataUUID: trainingDataUUID !== "" && trainingDataUUID !== "sample" ? trainingDataUUID : undefined,
      })
    }
    setIsBtnLoading(false)
  }

  const onAutoMlDataChange = useCallback(
    (mapping: Array<CustomFeatureRequest>, excludedColumns: Array<ExcludedFeatureRequest>) => {
      setAutoMlData({ mapping, excludedColumns })
    },
    [],
  )

  // Credit scoring steps with action buttons, description, etc...
  const creditScoringSteps = [
    {
      name: "Create Project",
      content:
        "Provide the project name and description, and then select between utilizing AutoML for automated model building or deploying your own model manually.",
      btnText: "Create project",
      component: (
        <ProjectCreationOverview
          formik={projectFormikValues}
          errorMessage={createProjectMutation.error?.response?.data?.non_field_errors?.[0]}
        />
      ),
    },
    {
      name: "Upload Training Data",
      content: "The uploaded data will be used to train the model",
      btnText: "Train Model",
      component: (
        <UploadTrainingDataFlow
          currentProjectUUID={createdProjectUUID}
          onDataChange={(datasetUUID: string) => {
            setTrainingDataUUID(datasetUUID)
          }}
        />
      ),
    },
  ]

  // Credit scoring steps with action buttons, description, etc...
  const creditScoringAutoMLSteps = [
    {
      name: "Create Project",
      content:
        "Provide the project name and description, and then select between utilizing AutoML for automated model building or deploying your own model manually.",
      btnText: "Create project",
      component: (
        <ProjectCreationOverview
          formik={projectFormikValues}
          errorMessage={createProjectMutation.error?.response?.data?.non_field_errors?.[0]}
        />
      ),
    },
    {
      name: "Upload Training Data",
      content: "The uploaded data will be used to train the model",
      btnText: "Next",
      component: (
        <UploadTrainingDataFlow
          currentProjectUUID={createdProjectUUID}
          onDataChange={(datasetUUID: string) => {
            setTrainingDataUUID(datasetUUID)
          }}
          creditScoringType="auto-ml"
          formik={AutoMlFormikValues}
        />
      ),
    },
    {
      name: "Create Schema",
      content:
        "Assign a pre-defined type to the target column and exclude any specific columns you wish from the training process if needed.",
      btnText: "Train Model",
      component: (
        <DataMappingFlow
          onDataChange={onAutoMlDataChange}
          createdProjectUUID={createdProjectUUID}
          fileHeaders={trainingSchema?.columns}
          isSchemaLoading={isTrainingSchemaLoading}
          errors={error}
          formik={AutoMlFormikValues}
          trainingDataUUID={trainingDataUUID}
        />
      ),
    },
  ]

  // credit scoring (pre-built flow) click actions function to be called when needed
  // to make it easier to than using switch cases and if conditions
  const creditScoringPreBuiltClickActions = (): void => {
    switch (activeStep) {
      case 0:
        projectFormikValues.submitForm()
        break
      case 1:
        handleTrainClick("pre-built")
        break
      default:
        break
    }
  }

  // credit scoring (auto-ml flow) click actions function to be called when needed
  // to make it easier to than using switch cases and if conditions
  const creditScoringAutoMLClickActions = (): void => {
    switch (activeStep) {
      case 0:
        projectFormikValues.submitForm()
        break
      case 1:
        setActiveStep(activeStep + 1)
        break
      case 2:
        AutoMlFormikValues.submitForm()
        break
      default:
        break
    }
  }

  /**
   * stepper helper function
   * to help disable the next btn when needed in the credit scoring project flow
   * @return  {boolean}
   */
  const shouldDisableCreditScoring = (): boolean => {
    switch (activeStep) {
      case 0:
        return !(projectFormikValues.dirty && projectFormikValues.isValid)
      case 1:
        return false
      default:
        return true
    }
  }

  // generic steps array to be mapped
  // to make it easier to than using switch cases and if conditions
  const genericSteps = [
    {
      name: "Create Project",
      content:
        "Provide the project name and description, and then select between utilizing AutoML for automated model building or deploying your own model manually.",
      btnText: "Create project",
      component: (
        <ProjectCreationOverview
          formik={projectFormikValues}
          errorMessage={createProjectMutation.error?.response?.data?.non_field_errors?.[0]}
        />
      ),
    },
    {
      name: "Add Model",
      content: (
        <Typography variant="p">
          Deploy your own model here. If you need help in this step please refer to our{" "}
          <Link
            href={`https://${getHostnames().docs}/`}
            target="_blank"
            style={{ color: theme.palette.grayscale.text[1], textDecoration: "underline" }}
          >
            documentation
          </Link>
        </Typography>
      ),
      btnText: createModelMutation.isSuccess ? "Integrate" : "Add model",
      component: (
        <Fragment>
          {createModelMutation.isLoading || isFailedLogs || createModelMutation.isSuccess ? (
            <PostCreationDialog
              creationErrors={createModelMutation.error?.response?.data?.errors}
              creationLogs={
                createModelMutation.data?.data.container_logs ||
                createModelMutation.error?.response?.data?.container_logs
              }
              isLoading={modelFormikValues.isSubmitting}
            />
          ) : (
            <ModelCreationForm formik={modelFormikValues} errorMessage={modelCreationError} />
          )}
        </Fragment>
      ),
    },
  ]

  // generic click actions function to be called when needed
  // to make it easier to than using switch cases and if conditions
  const genericClickActions = (): void => {
    switch (activeStep) {
      case 0:
        projectFormikValues.submitForm()
        break
      case 1:
        createModelMutation.isSuccess
          ? navigate(`/projects/${createProjectMutation.data?.data.uuid}/integration/python`)
          : modelFormikValues.submitForm()
        break
      default:
        break
    }
  }

  const shouldDisableCreditScoringAutoml = (): boolean => {
    switch (activeStep) {
      case 0:
        return !(projectFormikValues.dirty && projectFormikValues.isValid)
      case 1:
        return (
          !(AutoMlFormikValues.dirty && modelFormikValues.isValid) ||
          !(trainingDataUUID !== "" && trainingDataUUID !== "sample")
        )
      case 2:
        return AutoMlFormikValues.values.target_column === ""
      default:
        return true
    }
  }

  /**
   * stepper helper function
   * to help disable the next btn when needed in the generic project flow
   * @return  {boolean}
   */
  const shouldDisableGeneric = (): boolean => {
    switch (activeStep) {
      case 0:
        return !(projectFormikValues.dirty && projectFormikValues.isValid)
      case 1:
        return !(modelFormikValues.dirty && modelFormikValues.isValid && !isFailedLogs)
      default:
        return true
    }
  }

  // classification steps array to be mapped
  // to make it easier to than using switch cases and if conditions
  const classificationSteps = [
    {
      name: "Create Project",
      content:
        "Provide the project name and description, and then select between utilizing AutoML for automated model building or deploying your own model manually.",
      btnText: "Create project",
      component: (
        <ProjectCreationOverview
          formik={projectFormikValues}
          errorMessage={createProjectMutation.error?.response?.data?.non_field_errors?.[0]}
        />
      ),
    },
    {
      name: "Add Model",
      content: (
        <Typography variant="p">
          Deploy your own model here. If you need help in this step please refer to our{" "}
          <Link
            href={`https://${getHostnames().docs}/`}
            target="_blank"
            style={{ color: theme.palette.grayscale.text[1], textDecoration: "underline" }}
          >
            documentation
          </Link>
        </Typography>
      ),
      btnText: createModelMutation.isSuccess ? "Test your model" : "Add model",
      component: (
        <Fragment>
          {createModelMutation.isLoading || isFailedLogs || createModelMutation.isSuccess ? (
            <PostCreationDialog
              creationErrors={createModelMutation.error?.response?.data?.errors}
              creationLogs={
                createModelMutation.data?.data.container_logs ||
                createModelMutation.error?.response?.data?.container_logs
              }
              isLoading={createModelMutation.isLoading}
            />
          ) : (
            <ModelCreationForm formik={modelFormikValues} errorMessage={modelCreationError} />
          )}
        </Fragment>
      ),
    },
    {
      name: "Test Request",
      content: "Test your prediction request. This step will help you to configure the model's target.",
      btnText: "Choose Target",
      component: (
        <DryRunForm
          response={responseData}
          requestErrorMessage={
            requestJsonError
              ? "Request isn't JSON object, please re-enter the the request and try again"
              : (predictionsTest.error?.response?.data.details as string)
          }
          isRequestError={requestJsonError}
          isMutationError={predictionsTest.isError}
          responseStatusCode={!requestJsonError ? predictionsTest.error?.response?.status : undefined}
          formik={DryRunFormikValues}
          isLoadingRequest={isLoadingOpenApiData}
        />
      ),
    },
    {
      name: "Configure Target",
      content:
        "If your target is a range, pin each range under a label. Note: any uncovered range will be caught as uncovered.\n if your target is labelled, konan will automatically detect it from the output",
      btnText: classificationConfigurationMutation.isSuccess ? "Integrate" : "Configure Target",
      component: (
        <ConfigurationForm
          formik={configurationFormikValues}
          responseJSON={predictionsTest.data?.data.output as Record<string, string>}
          modelUUID={createModelMutation.data?.data.model.uuid as string}
          errorMessage={classificationConfigurationMutation.error?.response?.data.details}
        />
      ),
    },
  ]

  // classification click actions function to be called when needed
  // to make it easier to than using switch cases and if conditions
  const classificationClickActions = (): void => {
    switch (activeStep) {
      case 0:
        projectFormikValues.submitForm()
        break
      case 1:
        createModelMutation.isSuccess ? setActiveStep(activeStep + 1) : modelFormikValues.submitForm()
        break
      case 2:
        predictionsTest.isSuccess && setActiveStep(activeStep + 1)
        break
      case 3:
        classificationConfigurationMutation.isSuccess
          ? navigate(`/projects/${createProjectMutation.data?.data.uuid}/integration/python`)
          : configurationFormikValues.submitForm()
        break
      default:
        break
    }
  }

  /**
   * stepper helper function
   * to help disable the next btn when needed in the classification project flow
   * @return  {boolean}
   */
  const shouldDisableClassification = (): boolean => {
    switch (activeStep) {
      case 0:
        return !(projectFormikValues.dirty && projectFormikValues.isValid)
      case 1:
        return !(modelFormikValues.dirty && modelFormikValues.isValid)
      case 2:
        return !predictionsTest.isSuccess
      case 3:
        return !(configurationFormikValues.isValid && configurationFormikValues.dirty)
      default:
        return true
    }
  }

  type stepType = {
    name: string
    content: string | React.ReactNode
    btnText: string
    component: React.ReactNode
  }

  type stepProps = {
    type?: string
  }

  /**
   * stepper helper function to get the steps of the flow given the project type
   * @param  {string} type type of the project
   * @return  {Array<stepType>}
   */
  function getSteps(props: stepProps): Array<stepType> {
    const { type } = props

    switch (type) {
      case "generic":
        return genericSteps
      case "classification":
        return classificationSteps
      case "credit_scoring_pre-built":
        return creditScoringSteps
      case "credit_scoring_auto-ml":
        return creditScoringAutoMLSteps
      default:
        return creditScoringAutoMLSteps
    }
  }

  const steps = getSteps({ type: projectFormikValues.values.type })

  // reroutes user to training jobs page after model training is initiated
  useEffect(() => {
    if (
      (trainModelUsingPrebuiltModel.isSuccess && !trainModelUsingPrebuiltModel.isError) ||
      (isAutomlSuccess && !isAutoMlError)
    ) {
      setTimeout(() => {
        navigate({
          pathname: `/projects/${createProjectMutation.data?.data.uuid}/model-training`,
          search: `tab=${mapResponseTypesToTabValues(
            trainModelUsingPrebuiltModel?.data?.data?.status ?? trainingModelAutomlData?.data?.status,
          )}`,
        })
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProjectMutation, isLoading, isAutoMlError, isAutomlSuccess, trainModelUsingPrebuiltModel])

  /**
   * Getting Predictions object from the helper function then convert it to JSON
   */
  useEffect(() => {
    if (dryRunRequest?.data) {
      try {
        const extractedObjectJSON = JSON.stringify(extractPredictionKeysFromOpenapi(dryRunRequest?.data), null, 2)
        DryRunFormikValues.setFieldValue("request", extractedObjectJSON)
      } catch (_) {
        DryRunFormikValues.setFieldValue("request", "{}")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dryRunRequest?.data])

  return (
    <div className="layoutPadding">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        className={Styles.creationFlowRoot}
      >
        <Grid item xs={12} md={9}>
          <Card className="card-box-shadow">
            <CardHeader
              title={<Typography variant="h2-bold">Model Project Setup</Typography>}
              className={Styles.flowHeader}
              action={
                <IconButton
                  aria-label="close"
                  onClick={() =>
                    isFailedLogs ? createModelMutation.reset() : navigate("/projects?page=model", { replace: true })
                  }
                >
                  {isFailedLogs ? (
                    <ArrowBackIcon style={{ color: theme.palette.grayscale.text[2] }} />
                  ) : (
                    <CloseIcon style={{ color: theme.palette.grayscale.text[2] }} />
                  )}
                </IconButton>
              }
            />
            <Grid className={Styles.card}>{steps[activeStep].component}</Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.name}>
                <StepLabel>
                  <Typography variant="a">{step.name}</Typography>
                </StepLabel>
                <StepContent>
                  <Typography variant="p">{step.content}</Typography>
                  <div className={Styles.actionsContainer}>
                    {activeStep === 2 && projectFormikValues.values.type !== "credit_scoring_auto-ml" && (
                      <MarginalizedButton
                        variant="secondary"
                        disabled={
                          !(DryRunFormikValues.isValid && DryRunFormikValues.dirty) || predictionsTest.isLoading
                        }
                        onClick={() => {
                          DryRunFormikValues.submitForm()
                        }}
                        id="execute"
                      >
                        {predictionsTest.isLoading ? <CircularProgress color="inherit" size={20} /> : "Execute"}
                      </MarginalizedButton>
                    )}
                    {activeStep === 3 && projectFormikValues.values.type !== "credit_scoring_auto-ml" && (
                      <MarginalizedButton
                        variant="secondary"
                        onClick={() => {
                          setActiveStep(activeStep - 1)
                        }}
                        style={Styles.btnMargins as React.CSSProperties}
                        id="retest"
                      >
                        Retest
                      </MarginalizedButton>
                    )}
                    <MarginalizedButton
                      id="next_button"
                      variant="primary"
                      disabled={shouldDisableNext() || isBtnLoading}
                      onClick={() => {
                        switch (projectFormikValues.values.type) {
                          case "generic":
                            return genericClickActions()
                          case "classification":
                            return classificationClickActions()
                          case "credit_scoring_pre-built":
                            return creditScoringPreBuiltClickActions()
                          case "credit_scoring_auto-ml":
                            return creditScoringAutoMLClickActions()
                          default:
                            return creditScoringPreBuiltClickActions()
                        }
                      }}
                    >
                      {isBtnLoading ? <CircularProgress color="inherit" size={20} /> : step.btnText}
                    </MarginalizedButton>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </div>
  )
}
