import React, { useCallback, useContext, useEffect, useMemo } from "react"

import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"

import Grid from "@mui/material/Grid"
import { AxiosError, AxiosResponse } from "axios"
import moment from "moment"

import { EvaluationMetricsCard } from "../../../../../components/EvaluationMetricsCard"
import { RequestsLineGraph } from "../../../../../components/graphs/RequestsLineGraph"
import { RequestLogTable } from "../../../../../components/tables/RequestLogTable"
import { KonanAPI } from "../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../store/CurrentProjectAndModelContext"
import { ProjectTimeRange, baseErrorType } from "../../../../../types/custom/projects"
import { EvaluateResult } from "../../../../../types/generated/api/EvaluateResult"
import { Model } from "../../../../../types/generated/api/Model"
import { PaginatedListModelStateSwitchList } from "../../../../../types/generated/api/PaginatedListModelStateSwitchList"
import { RequestSummaryDailyOutput } from "../../../../../types/generated/api/RequestSummaryDailyOutput"
import {
  convertActivityDataToMarkers,
  convertDatatoTotalRequestsLineGraph,
  fillMissingRequestSummaryDates,
} from "../../../../../utils/deploymentDetailsHelpers"
import { getModelByUUID } from "../../../../../utils/modelDetailsHelpers"

/**
 * Component that monitors requests/predictions on live model as well as evaluation metrics
 * contains requests log table with the (date, request body, response) to each request
 * @returns {React.ReactElement}
 */
export function ApiMonitor(): React.ReactElement {
  const [searchParams] = useSearchParams()

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const startDate = searchParams.get("startDate")
  const endDate = searchParams.get("endDate")

  const {
    mutate,
    data: evaluationData,
    isLoading,
    isError,
    error,
  } = useMutation<EvaluateResult, AxiosError<baseErrorType>, ProjectTimeRange>(KonanAPI.evaluateProject)

  // fetch project "/predictions/summary/daily/" endpoint
  const { isLoading: isLoadingDailyRequestSummary, data: dailyRequestSummaryResponse } = useQuery<
    RequestSummaryDailyOutput,
    AxiosError<baseErrorType>
  >(
    ["project-daily-request-summary", currentProject?.uuid, startDate, endDate],
    () =>
      KonanAPI.fetchDailyProjectRequestSummary({
        start_date: startDate as string,
        end_date: endDate as string,
        project_uuid: currentProject?.uuid as string,
      }),
    { enabled: startDate != null && endDate != null },
  )

  // fetch "/models" endpoint
  const { data: models } = useQuery<AxiosResponse<Array<Model>>, AxiosError<baseErrorType>>(
    ["models", currentProject?.uuid],
    () => KonanAPI.fetchModels(currentProject?.uuid as string),
  )

  // fetch project "/switch/history/" endpoint
  const { isLoading: isActivityLoading, data: activityResponse } = useQuery<
    PaginatedListModelStateSwitchList,
    AxiosError<baseErrorType>
  >(["modelHistory", currentProject?.uuid], () => KonanAPI.getProjectHistory(currentProject?.uuid as string), {
    enabled: currentProject?.uuid !== "",
  })

  //fetch dates that have data in them
  // TODO:: uncomment when ready from the backend
  // const { data: dates } = useQuery<string[], AxiosError<baseErrorType>>(["request-dates", currentProject?.uuid], () =>
  //   KonanAPI.getRequestDates(currentProject?.uuid as string)
  // )

  const memoizedGetModelByUUID = useCallback(
    (currentModel: string) => getModelByUUID(currentModel, models?.data),
    [models],
  )

  //add model name to activity response
  const activityData = useMemo(() => {
    if (activityResponse?.results && activityResponse?.results.length > 0) {
      return convertActivityDataToMarkers(memoizedGetModelByUUID, activityResponse.results)
    }
    return []
  }, [activityResponse?.results, memoizedGetModelByUUID])

  // memoizing the returned data
  const adjustedData = useMemo(() => {
    if (startDate && endDate) {
      // If there's new data, fill in the missing dates and convert it to Serie[] type
      return convertDatatoTotalRequestsLineGraph(
        fillMissingRequestSummaryDates(
          moment(startDate),
          moment(endDate),
          dailyRequestSummaryResponse?.data ?? [],
          activityData,
        ),
      )
    }
    return []
  }, [startDate, endDate, dailyRequestSummaryResponse, activityData])

  // Use evaluation endpoint whenever datetime range changes
  useEffect(() => {
    if (startDate && endDate) {
      mutate({
        start_date: startDate as string,
        end_date: endDate as string,
        project_uuid: currentProject?.uuid as string,
      })
    }
  }, [startDate, endDate, currentProject?.uuid, mutate])

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {/* Requests Line Graph */}
        <Grid item xs={12} md={8} lg={9}>
          <RequestsLineGraph
            title="Number of Requests per Day"
            data={adjustedData}
            graphHeight={400}
            isLoading={isLoadingDailyRequestSummary || isActivityLoading}
          />
        </Grid>

        {/* Evaluation Metrics card */}
        <Grid item xs={12} md={4} lg={3}>
          <EvaluationMetricsCard
            metrics={evaluationData?.metrics}
            cardHeight={400}
            isLoading={isLoading}
            isError={isError || !!evaluationData?.error}
            error={
              error?.response?.data.detail
                ? error?.response?.data.detail
                : error?.response?.data.error === "Not Found"
                  ? "Evaluation endpoint not found. Please make sure you implemented the evaluation endpoint in your model."
                  : evaluationData?.error || "An unknown error occurred"
            }
          />
        </Grid>

        {/* Requests log table */}
        <Grid item xs={12}>
          <RequestLogTable
            startDate={startDate as string}
            endDate={endDate as string}
            projectUUID={currentProject?.uuid as string}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
