import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined"
import { Grid } from "@mui/material"
import { Button, InputChangeEvent, Select } from "@synapse-analytics/synapse-ui"
import { MRT_ColumnDef } from "material-react-table"

import { CustomFeatureRequest } from "../../types/custom/projects"
import { ExcludedFeatureRequest } from "../../types/generated/api/ExcludedFeatureRequest"
import { PredefinedTypeList } from "../../types/generated/api/PredefinedTypeList"
import { BaseTable } from "./BaseTable"

import styles from "./DataMappingTable.module.scss"

type Props = {
  data: Array<PredefinedTypeList>
  onDataChange: (mapping: Array<CustomFeatureRequest>, excludedColumns: Array<ExcludedFeatureRequest>) => void
  fileHeaders: Array<string> | undefined
  isSchemaLoading: boolean
  targetColumn: string
  mapping: Array<CustomFeatureRequest>
  setMapping: Dispatch<SetStateAction<CustomFeatureRequest[]>>
  targetColumnType: string
}

/**
 * Table component responsible for handling mapping the type of the target column of the data
 * @param {Array<PredefinedTypeList>} data predefined list of types
 * @param {Function} onDataChange handler for changing mapping or excluded columns in table
 * @param {Array<string> | undefined} fileHeaders Array of extracted file columns/headers
 * @param {boolean} isSchemaLoading indicator for loading state of the schema (file headers)
 * @param {string} targetColumn target column to be mapped to a type
 * @param {Array<CustomFeatureRequest>} mapping Mapping item sto types array
 * @param {Dispatch<SetStateAction<CustomFeatureRequest[]>>} setMapping  Mapping state setter
 * @param {string} targetColumnType
 * @returns {React.ReactElement}
 */
export const DataMappingTable = React.memo((props: Props): React.ReactElement => {
  const { data, fileHeaders, isSchemaLoading, onDataChange, mapping, setMapping, targetColumn, targetColumnType } =
    props

  /**
   * initial 2 states for each button on every row to be set to remove option
   * and the select dropdown to be set to default (choose type) option
   */
  const [excludedColumnsButton, setExcludedColumnsButton] = useState<Array<string>>(
    fileHeaders ? fileHeaders?.map(() => "remove") : [],
  )
  const [selectedPredefinedType, setSelectedPredefinedType] = useState<Array<string>>(
    fileHeaders ? fileHeaders?.map(() => "default") : [],
  )
  /**
   * initial state, to control the excluded columns
   */
  const [excludedRows, setExcludedRows] = useState<Array<ExcludedFeatureRequest>>([])

  // update exclude button and selected type value states
  useEffect(() => {
    if (fileHeaders && fileHeaders?.length > 0) {
      setExcludedColumnsButton(fileHeaders?.map(() => "remove"))
      setSelectedPredefinedType(fileHeaders?.map(() => "default"))
    }
  }, [fileHeaders])

  /**
   * toggle the option from (remove) column to (add) column and vice versa whenever button is clicked
   * NOTE: we have to store each button id to be able to handle each button on every row separately
   * check if this column already exists in excluded columns state then dropped, if not then add it
   * and update the states
   * @param rowData
   */
  const handleButtonClick = (name: string, tableId: number): void => {
    setExcludedColumnsButton((prevButtonTexts: Array<string>) =>
      prevButtonTexts.map((text: string, i: number) => (i === tableId ? (text === "remove" ? "add" : "remove") : text)),
    )
    setExcludedRows((prevExcludedRows) => {
      if (prevExcludedRows?.some((row) => row?.name === name)) {
        return prevExcludedRows?.filter((row) => row?.name !== name)
      } else {
        return [...prevExcludedRows, { name: name }]
      }
    })
  }

  /**
   * when a pre-defined type is selected from the select dropdown, we check if the option === none
   * then we set this select component on this row, to be equal to the default type (remove the mapped type)
   * then we check if this columns was in our mapping state we dropped, if not we add it.
   * @param e
   * @param rowData
   */
  const handleSelectChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rowData: { name: string; tableId: number },
  ): void => {
    if (e.target.value === "none") {
      setSelectedPredefinedType((prevSelectedValues: Array<string>) =>
        prevSelectedValues.map((value: string, i: number) => (i === rowData.tableId ? "default" : value)),
      )
    } else {
      setSelectedPredefinedType((prevSelectedValues: Array<string>) =>
        prevSelectedValues.map((value: string, i: number) => (i === rowData.tableId ? e.target.value : value)),
      )
    }
    setMapping((prevMapping: Array<CustomFeatureRequest>) => {
      if (e.target.value === "none") {
        return prevMapping.filter((row: CustomFeatureRequest) => row.name !== rowData.name)
      } else if (prevMapping.some((row: CustomFeatureRequest) => row.name === rowData.name)) {
        return prevMapping.map((row: CustomFeatureRequest) =>
          row.name === rowData.name ? { ...row, type: e.target.value } : row,
        )
      } else {
        return [...prevMapping, { name: rowData.name, type: e.target.value }]
      }
    })
  }

  // whenever the mapping or the exculdedRows states change we need to update the upper state
  useEffect(() => {
    onDataChange(mapping, excludedRows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapping, excludedRows])

  // whenever there is a change in the target column, we need to update the pre-defined type in the row
  // that has the same name as the target column
  useEffect(() => {
    if (fileHeaders) {
      for (let i = 0; i < fileHeaders?.length; i++) {
        if (fileHeaders[i] === targetColumn) {
          setTimeout(() => {
            setSelectedPredefinedType((prevSelectedValues: Array<string>) =>
              prevSelectedValues.map((value: string, idx: number) =>
                idx === i ? targetColumnType ?? "default" : value,
              ),
            )
          }, 5)
        }
      }
    }
  }, [fileHeaders, targetColumn, targetColumnType])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: MRT_ColumnDef<any>[] = [
    {
      header: "Name",
      accessorKey: "name",
      enableColumnFilter: false,
    },
    {
      header: "Type",
      accessorKey: "type",
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return (
          <Grid container item xs={12} flexWrap="nowrap">
            <Grid item container>
              <Select
                hideDescription
                fullWidth
                id="selectModelToCompare"
                value={selectedPredefinedType[row.index]}
                handleChange={(e: InputChangeEvent) =>
                  handleSelectChange(e, { name: row.original.name, tableId: row.index })
                }
                disabled={targetColumn === row.original?.name}
                optionsWithValues={[
                  { label: "Choose Type", value: "default", disabled: true },
                  { label: "Choose none", value: "none" },
                  ...data?.map((item) => {
                    return { label: item.name, value: item.name }
                  }),
                ]}
              />
            </Grid>
            <Grid container item xs={true} display={"flex"} alignContent={"center"} marginLeft={1}>
              <Button
                variant={excludedColumnsButton[row.index] === "remove" ? "dangerous" : "secondary"}
                size="large"
                onClick={() => handleButtonClick(row.original.name, row.index)}
                className={styles.iconButton}
              >
                {excludedColumnsButton[row.index] === "remove" ? (
                  <RemoveCircleOutlineOutlinedIcon
                    fontSize="small"
                    style={{ fill: "var(--important-text-enabled) !important" }}
                  />
                ) : (
                  <AddCircleOutlineOutlinedIcon
                    fontSize="small"
                    style={{ fill: "var(--important-text-enabled) !important" }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        )
      },
    },
  ]

  return (
    <BaseTable
      title="Choose Types"
      isLoading={(fileHeaders && fileHeaders?.length > 0 && excludedColumnsButton?.length === 0) || isSchemaLoading}
      columns={columns}
      enableGlobalFilter={false}
      enableOrdering={false}
      enableHiding={false}
      enableFilters={false}
      data={
        isSchemaLoading || (fileHeaders && fileHeaders?.length === 0)
          ? []
          : fileHeaders
            ? fileHeaders?.map((str: string) => ({ name: str }))
            : []
      }
    />
  )
})
