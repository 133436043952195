import { useState } from "react"

import { useQuery } from "react-query"
import { useParams, useSearchParams } from "react-router-dom"

import { Grid } from "@mui/material"
import { AxiosError, AxiosResponse } from "axios"
import { MRT_PaginationState } from "material-react-table"

import { ProjectDecisionsTable } from "../../../../components/tables/ProjectDecisions"
import { KonanAPI } from "../../../../services/KonanAPI"
import { PaginatedListPredictionsList } from "../../../../types/generated/api/PaginatedListPredictionsList"

type ParamsType = {
  id: string
}

export function Decisions(): React.ReactElement {
  const { id: projectId } = useParams<ParamsType>()
  const [searchParams] = useSearchParams()

  const startDate = searchParams.get("startDate")
  const endDate = searchParams.get("endDate")

  const [filterObject, setFilterObject] = useState<object>({})

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  //   fetch model features (model predictions table data)
  const {
    isLoading: isProjectPredictionsLoading,
    isFetching: isProjectPredictionsFetching,
    data: projectPredictions,
  } = useQuery<AxiosResponse<PaginatedListPredictionsList>, AxiosError>(
    ["project-predictions", startDate, endDate, pagination, filterObject],
    () =>
      KonanAPI.fetchProjectPredictions(
        startDate as string,
        endDate as string,
        projectId as string,
        pagination.pageIndex + 1,
        pagination.pageSize,
        filterObject,
      ),
    {
      keepPreviousData: true,
      enabled: !["", null, undefined].includes(projectId) && startDate != null && endDate != null,
    },
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProjectDecisionsTable
          data={projectPredictions?.data ?? undefined}
          isFetching={isProjectPredictionsFetching}
          isLoading={isProjectPredictionsLoading}
          pagination={pagination}
          setPagination={setPagination}
          filterObject={filterObject}
          setFilterObject={setFilterObject}
        />
      </Grid>
    </Grid>
  )
}
