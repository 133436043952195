/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ExportJobCreateRequest = {
  /**
   * This name will be taken as the exported csv file name. It allows all characters except [
   * ,     ,  , .]
   */
  name: string
  /**
   * * `DEPLOYMENT_PREDICTIONS` - Deployment Predictions
   * * `MODEL_PREDICTIONS` - Model Predictions
   * * `SIMULATION_PREDICTIONS` - Simulation Predictions
   */
  type: ExportJobCreateRequest.type
  model?: string
  simulation_job?: string
  predictions_start_time?: string | null
  predictions_end_time?: string | null
}
export namespace ExportJobCreateRequest {
  /**
   * * `DEPLOYMENT_PREDICTIONS` - Deployment Predictions
   * * `MODEL_PREDICTIONS` - Model Predictions
   * * `SIMULATION_PREDICTIONS` - Simulation Predictions
   */
  export enum type {
    DEPLOYMENT_PREDICTIONS = "DEPLOYMENT_PREDICTIONS",
    MODEL_PREDICTIONS = "MODEL_PREDICTIONS",
    SIMULATION_PREDICTIONS = "SIMULATION_PREDICTIONS",
  }
}
