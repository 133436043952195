import React from "react"

import WarningIcon from "@mui/icons-material/Warning"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { Chip, Typography } from "@synapse-analytics/synapse-ui"

import { ParentsBreadcrumbs } from "./components/ParentsBreadcrumbs"

import styles from "../DataMonitor.module.scss"

type Props = {
  name: string
  parents: string
}

export function UnknownFeatureCard(props: Props): React.ReactElement {
  const { name, parents } = props

  return (
    <Paper className={styles.valueContainer}>
      <Grid container>
        {/* Name and Type */}
        <Grid item xs={2}>
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" wrap="wrap">
            <Grid container item spacing={1} xs={12}>
              {/* Parents */}
              {parents && <ParentsBreadcrumbs parents={parents} />}
            </Grid>
            <Grid item container xs={11} spacing={1}>
              <Grid item xs={11} className={styles.featName}>
                <Typography variant="h2-bold" noWrap>
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={4} className={styles.chipAlignment}>
                <Chip>Unknown</Chip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={8} className={styles.dataSection} justifyContent="center">
          <Typography variant="label">
            <WarningIcon className={styles.warningChip} /> Unable to detect data type
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
