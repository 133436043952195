import React, { memo, useEffect, useMemo, useRef, useState } from "react"

import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { Handle, Position, useStoreApi } from "reactflow"

import BlockIcon from "@mui/icons-material/Block"
import CloseIcon from "@mui/icons-material/Close"
import { Card, CircularProgress, Grid, IconButton } from "@mui/material"
import { InputChangeEvent, InputText, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { queryClient } from "../../../../../../.."
import { ValueBlock } from "../../../../../../../components/ValueBlock"
import { getTheme } from "../../../../../../../hooks/UseTheme"
import { useDebounce } from "../../../../../../../hooks/useDebounce"
import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { Label } from "../../../../../../../types/generated/api/Label"
import { PaginatedLabelList } from "../../../../../../../types/generated/api/PaginatedLabelList"

import styles from "./CustomCards.module.scss"

interface LabeledNodeProps {
  menuView?: boolean
  data: {
    nodeType: "EndResultNode"
    readMode?: boolean
    liveModelState?: { name: string | null; disable: null | string }
    return_label?: Label
    hasChildren?: boolean
  }
  id?: string
}

/**
 * Grouping component for (live model node + return label node)
 * @param {object} data and object contains all passed data to this node
 * @param {boolean} menuView an indicator if the current view is menu or not
 * @param {string} id node id
 * @return {React.ReactElement}
 */
export const LabeledNode = memo(function LabeledNode(props: Readonly<LabeledNodeProps>): React.ReactElement {
  const { menuView, data, id } = props
  const { id: projectId } = useParams<{ id: string }>()

  const pageSize = 20
  const [page, setPage] = useState<number>(0)
  const [searchKey, setSearchKey] = useState<string | null>(null)

  const [labels, setLabels] = useState<Label[]>([])

  const CreateLabelMutation = useMutation<
    AxiosResponse<Label>,
    AxiosError,
    {
      project_uuid: string
      name: string
    }
  >(KonanAPI.CreateLabel, {
    onSuccess: async () => {
      NotificationUtils.toast("Label created successfully", { snackBarVariant: "positive" })

      await queryClient.invalidateQueries("label-list")
    },
    onError: async () => {
      NotificationUtils.toast("Label creation failed", { snackBarVariant: "negative" })
    },
  })

  // fetch uploaded training data headers (columns)
  const { data: labelList, isLoading: isLabelListLoading } = useQuery<AxiosResponse<PaginatedLabelList>, AxiosError>(
    ["label-list", projectId, page, pageSize, searchKey],
    () =>
      KonanAPI.RetrieveLabels({
        project_uuid: projectId as string,
        page: page + 1,
        pageSize: pageSize,
        search: searchKey,
      }),
    {
      onSuccess: (response) => {
        // filtering out already existing labels in the list
        const tempLabels =
          response.data.results?.filter((item: Label) => !labels.find((label: Label) => label.uuid === item.uuid)) ?? []

        setLabels([...labels, ...tempLabels])

        response.data.results?.length === 1 &&
          !["", null, undefined].includes(searchKey) &&
          // setting name (automatically) ONLY if the user writes the full name correctly
          response.data.results[0].name === searchKey &&
          setFlowNodes({
            name: response.data.results[0].name,
            uuid: response.data.results[0].uuid,
          })
      },
      enabled: !data?.readMode && !!projectId && data.nodeType === "EndResultNode",
      refetchOnMount: true,
    },
  )

  const optionsWithValues = useMemo(() => {
    return labels.map((label) => {
      return { label: label.name, value: label.uuid }
    })
  }, [labels])

  const nodeId = id ?? null
  const readMode = data?.readMode ?? null
  const return_label = data?.return_label ?? null

  const store = useStoreApi()
  const { setNodes, getNodes } = store.getState()

  const theme = getTheme()

  const onChange = (e: InputChangeEvent<Element>): void => {
    e.preventDefault()

    setFlowNodes({
      name: e?.target?.value as string,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      uuid: ![null, undefined, ""].includes(e?.target?.value)
        ? labelList?.data?.results?.find((label) => label.name === e.target.value)?.uuid
        : "",
    })

    debouncedOnChange()
  }

  const setFlowNodes = (props: { name: string; uuid: string }): void => {
    const { name, uuid } = props

    setNodes(
      getNodes().map((node) => {
        const newNode = { ...node }
        if (node.id === nodeId) {
          newNode.data = {
            ...newNode.data,
            return_label: {
              name: name,
              uuid: uuid,
            },
          }
        }
        return newNode
      }),
    )
  }

  const debouncedOnChange = useDebounce((): void => {
    const label = return_label?.name ?? ""

    setSearchKey(label === "" ? null : label)
    setPage(0)
  }, 400)

  const handleRemoveNode = (): void => {
    setNodes(
      getNodes()?.map((node) => {
        const newNode = { ...node }

        if (node.id === nodeId) {
          newNode.type = "AddBlockNode"

          // Destructure the data object from newNode
          const { data } = newNode

          // Extract the desired properties from data
          const { updateGraph, parentId, formik, schema } = data

          // Assign the filtered data back to newNode
          newNode.data = { updateGraph, parentId, formik, schema }
        }

        return newNode
      }),
    )

    NotificationUtils.toast("Label node removed!", {
      snackBarVariant: "positive",
    })
  }

  // memo to track input changes in editMode
  const inputValue = useMemo(() => {
    if (!readMode && data.nodeType === "EndResultNode") {
      getNodes().forEach((node) => {
        if (node.id === nodeId) {
          return node?.data?.return_label.name
        }
      })
    } else {
      return ""
    }
  }, [data.nodeType, getNodes, nodeId, readMode])

  // creating a replica of formik touched
  // till we figure out if we should do this in KONAN or SUI
  const ref = useRef({ now: return_label?.name, prev: return_label?.name })
  useEffect(() => {
    ref.current.now = return_label?.name
  }, [return_label?.name])

  const nodesData = [
    {
      nodeTitle: "END",
      nodeLogo: <BlockIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
      logoClassName: "endCardType",
      nodeBody: (
        <Grid item container xs={12} display="flex" flexWrap="nowrap">
          <Grid item xs={2.5} mr={1} marginTop={!readMode ? "6px" : "4px"}>
            <Typography variant="h3-bold" variantColor={menuView ? 2 : 1}>
              Result
            </Typography>
          </Grid>
          <Grid item xs={readMode ? 9.5 : true} marginTop={readMode ? "2px" : "-2px"}>
            {readMode && return_label ? (
              <ValueBlock value={return_label.name} size={12} />
            ) : (
              <InputText
                fullWidth
                disabled={menuView}
                handleChange={onChange}
                value={inputValue ?? return_label?.name ?? ""}
                noEntriesProps={{
                  //isHidden: ref.current.now !== ref.current.prev,
                  shouldAcceptNewValues: true,
                  handleAddNewValue: async () => {
                    await CreateLabelMutation.mutateAsync({
                      project_uuid: projectId as string,
                      name: return_label?.name as string,
                    }).then((res) => {
                      setFlowNodes({
                        name: res.data.name,
                        uuid: res.data.uuid,
                      })
                    })
                  },
                }}
                hideDescription
                optionsWithValues={optionsWithValues}
                loaderComponent={
                  <Grid container item xs={12} px={2} py={0.5}>
                    <CircularProgress size={14} />
                  </Grid>
                }
                loading={CreateLabelMutation.isLoading}
                isLoadingOptions={isLabelListLoading}
                placeholder="Label"
                type="text"
                menuProps={{
                  menuMaxContent: true,
                  scrollThreshold: 10,
                  handleScrollToEnd: () => {
                    labelList?.data.next && setPage(page + 1)
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
      ),
    },
  ]

  return (
    <Card className={`${styles.card} disableKeyboardA11y nodrag`}>
      {!menuView && <Handle type="target" position={Position.Top} id="b" isConnectable={true} />}
      <Grid container flexWrap="nowrap">
        <Grid item xs={1.9}>
          <Grid item className={styles[`${nodesData[0]?.logoClassName}`]}>
            {nodesData[0]?.nodeLogo}
          </Grid>
        </Grid>
        <Grid
          width="100%"
          xs={10.1}
          item
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item>
              <Typography variant="label" variantColor={2}>
                {nodesData[0]?.nodeTitle}
              </Typography>
            </Grid>
            {!menuView && !readMode && (
              <Grid item marginRight={"-5px"} justifySelf={"end"}>
                <IconButton style={{ marginTop: "-15px" }} size="small" onClick={() => handleRemoveNode()}>
                  <CloseIcon fontSize="small" className={styles.remove} />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <Grid
            flexWrap="nowrap"
            item
            display="flex"
            xs={12}
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            width="100%"
            marginTop={!readMode && !menuView ? "0px" : menuView ? "6px" : "5px"}
          >
            {nodesData[0]?.nodeBody}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
})
