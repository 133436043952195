import React from "react"

import { Card, CardHeader, CircularProgress, Grid } from "@mui/material"
import { Button, NotificationUtils, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { FormikProps } from "formik"

import { MonacoTextEditor } from "../../../../../../../../../components/MonacoTextEditor"

import styles from "../../../ModelConfigurations.module.scss"

interface Props {
  formik: FormikProps<{
    request: string
    model: string
  }>
  response: string
  requestErrorMessage: string
  isRequestError: boolean
  isMutationError: boolean
  responseStatusCode: number | undefined
  isLoadingRequest?: boolean
  modelSelect?: boolean
}

/**
 * Dry run form
 * @return {React.ReactElement}
 */
export function DryRunForm(props: Props): React.ReactElement {
  const {
    formik,
    response,
    requestErrorMessage,
    isRequestError,
    isMutationError,
    responseStatusCode,
    isLoadingRequest,
  } = props

  return (
    <React.Fragment>
      <Grid container item xs={12} mt={0}>
        <Grid item xs={12}>
          <Typography variant="h3-bold">Test Request</Typography>
        </Grid>

        <Grid item xs={12} mt={0.5}>
          <Grid container spacing={2} direction="column">
            {isRequestError || isMutationError ? (
              <Grid item>
                <Snackbar
                  variant="negative"
                  fullWidth
                  description={
                    isRequestError && isMutationError && responseStatusCode !== 406
                      ? requestErrorMessage
                      : requestErrorMessage
                        ? requestErrorMessage + ". Adjust request according to response message and try again."
                        : "Something went wrong, Please try again later."
                  }
                />
              </Grid>
            ) : (
              response !== "// Execute to see response" && (
                <Grid item>
                  <Snackbar variant="positive" fullWidth description="Test run Successful" />
                </Grid>
              )
            )}

            <Grid container item spacing={2} direction="row">
              <Grid item xs={6}>
                <Card>
                  <CardHeader
                    title={<Typography variant="p">Request</Typography>}
                    className={styles.responseTextArea}
                  />
                  {isLoadingRequest ? (
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems={"center"}
                      justifyContent={"center"}
                      style={{ height: "60vh" }}
                    >
                      <CircularProgress style={{ color: "rgb(25 118 210)" }} size={36} />
                    </Grid>
                  ) : (
                    <MonacoTextEditor
                      value={isLoadingRequest ? undefined : formik.values.request}
                      language={"json"}
                      height={"60vh"}
                      onChange={(text) => formik.setFieldValue("request", text)}
                      loading={<CircularProgress style={{ color: "rgb(25 118 210)" }} size={36} />}
                    />
                  )}
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card>
                  <CardHeader
                    title={<Typography variant="p">Response</Typography>}
                    action={
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(response)
                          NotificationUtils.toast("Copied to Clipboard!", { snackBarVariant: "positive" })
                        }}
                      >
                        Copy
                      </Button>
                    }
                    className={styles.responseTextArea}
                  />
                  <MonacoTextEditor
                    value={response}
                    language={"json"}
                    height={"60vh"}
                    readOnly
                    loading={<CircularProgress style={{ color: "rgb(25 118 210)" }} size={36} />}
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
