import React, { Fragment, useContext, useState } from "react"

import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { Card, Grid, SvgIcon } from "@mui/material"
import { Button, Skeleton, Tag, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { differenceInSeconds, format } from "date-fns"
import moment from "moment"

import { KonanAvatar } from "../../../../../../../components/Avatar"
import { ModelType } from "../../../../../../../components/ModelType"
import { KonanLogo } from "../../../../../../../components/icons/KonanLogo"
import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../../../store/CurrentProjectAndModelContext"
import { Model } from "../../../../../../../types/generated/api/Model"
import { RetrainingJob } from "../../../../../../../types/generated/api/RetrainingJob"
import { TrainingDataUpload } from "../../../../../../../types/generated/api/TrainingDataUpload"
import { TrainingJob } from "../../../../../../../types/generated/api/TrainingJob"
import { uniqueJobName } from "../../../../../../../utils/deploymentDetailsHelpers"
import { KonanTimeHelper } from "../../../../../../../utils/genericHelpers"
import { AbortTrainingJobDialog } from "./AbortTrainingJobDialog"
import { RetrainingLogsView } from "./RetrainingLogs"

import styles from "../TrainingJobs.module.scss"

type ParamsType = {
  id: string
}
type Props = {
  predictions: string
  parentModel: string
  startedAt: string
  firstPredictionDate: string
  lastPredictionDate: string
  createdModelName: string
  endedAt: string
  trainingDataUUID: string
  liveModel: Model | undefined
  cardType: string
  uuid: string
  trainingOrRetraining: string
  isLoading?: boolean

  status: RetrainingJob.status

  createdAt: string
  createdModelStatus?: string

  projectType?: string
  /** Used to guarantee that avatars are unique per user. */
  userName?: string

  duration?: string
  dataPoints?: string
  evaluationTests?: { label: string; data: string }[]

  onCancel?: () => void
  viewLogs?: (uuid: string) => void
  viewStats?: () => void
}

export function PendingFailedTrainingJobCard(props: Props): React.ReactElement {
  const {
    isLoading,
    status,
    startedAt,
    createdAt,
    parentModel,
    userName,
    firstPredictionDate,
    lastPredictionDate,
    dataPoints,
    trainingDataUUID,
    endedAt,
    projectType,
    uuid,
    duration,
    trainingOrRetraining,
    cardType,
    liveModel,
  } = props

  const isJobPendingAndAutoMLTraining = trainingOrRetraining === "training-automl" && cardType === "pending"
  const isStatusPendingOrRunning = status === TrainingJob.status.PENDING || TrainingJob.status.RUNNING

  const navigate = useNavigate()
  const { id: projectId } = useParams<ParamsType>()
  const konanTime = new KonanTimeHelper()
  const [openRetrainingLogs, setOpenRetrainingLogs] = useState(false)
  const [AbortDialogOpen, setAbortDialogOpen] = useState(false)

  // fetch model data
  const { isLoading: isParentModelDataLoading, data: parentModelData } = useQuery<AxiosResponse<Model>, AxiosError>(
    ["model", parentModel],
    () => KonanAPI.fetchModel(parentModel),
    { enabled: !!parentModel },
  )

  const { data: trainingData, isLoading: isTrainingDataLoading } = useQuery<TrainingDataUpload, AxiosError>(
    ["training-data", projectId, trainingDataUUID],
    () => KonanAPI.fetchSingleTrainingData(projectId as string, trainingDataUUID),
    { enabled: projectId != null && trainingDataUUID != null },
  )
  const { setCurrentModel } = useContext(CurrentProjectAndModelContext)

  const handleLogsClose = (): void => {
    setOpenRetrainingLogs(false)
  }

  return (
    <Fragment>
      <RetrainingLogsView
        open={openRetrainingLogs}
        onClose={handleLogsClose}
        projectUUID={projectId as string}
        retrainingUUID={uuid}
        jobStatus={status}
        modelName={parentModelData?.data?.name}
        trainingJobType={trainingOrRetraining}
        trainingName={uniqueJobName(uuid, createdAt, trainingOrRetraining)}
        isAutoMl={Boolean(trainingOrRetraining === "training-automl")}
      />
      <AbortTrainingJobDialog
        projectUUID={projectId as string}
        trainingUUID={uuid}
        open={AbortDialogOpen}
        onClose={() => setAbortDialogOpen(false)}
        trainingOrRetraining={trainingOrRetraining}
        liveModel={liveModel}
      />
      <Card className="card-box-shadow">
        <Grid container direction="column" className={styles.headerContainer}>
          <Grid item xs={12} container justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} container direction="row">
              <Grid item>
                <Tag
                  size="small"
                  variant={status === "FAILED" ? "negative" : status === "CANCELLED" ? "warning" : "default"}
                  onResize={undefined}
                  onResizeCapture={undefined}
                >
                  {status?.toLowerCase()}
                </Tag>
              </Grid>
              <Grid item direction="column" justifyContent="center" alignItems="flex-end">
                <Typography variant="span" className={styles.durationText} noWrap>
                  {cardType === "failed" && !isLoading && duration ? `Lasted ${konanTime.timeParser(duration)}` : ""}
                  {cardType === "pending" && !isLoading && status === "PENDING" && "Waiting in Queue"}
                  {cardType === "failed" &&
                    !isLoading &&
                    status === "RUNNING" &&
                    `Running ${konanTime.formatTimeMinsAndSec(differenceInSeconds(new Date(), new Date(startedAt)))}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3-bold" noWrap className={styles.headerName}>
              {uniqueJobName(uuid, createdAt, trainingOrRetraining)}
            </Typography>
          </Grid>

          <Grid container justifyContent="space-between" className={styles.avatarSpacing}>
            <Grid
              container
              justifyContent="space-between"
              item
              xs={isJobPendingAndAutoMLTraining ? 12 : isStatusPendingOrRunning ? 5 : 7}
              sm={isJobPendingAndAutoMLTraining ? 12 : isStatusPendingOrRunning ? 4 : 6}
              lg={isJobPendingAndAutoMLTraining ? 12 : isStatusPendingOrRunning ? 5 : 7}
              xl={isJobPendingAndAutoMLTraining ? 12 : isStatusPendingOrRunning ? 6 : 8}
              spacing={1}
              alignItems="flex-start"
              className={styles.avatarContainer}
            >
              <Grid item sx={{ marginTop: "2px" }}>
                <SvgIcon className={styles.avatar}>
                  <KonanAvatar size={24} name={userName} />
                </SvgIcon>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="label" noWrap>
                  {userName}
                </Typography>

                <Typography variant="label" noWrap>
                  <Tooltip
                    display="auto"
                    title={<Typography variant="span">{format(new Date(createdAt), "dd/MM/yyyy, p")} </Typography>}
                    placement="right"
                  >
                    <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                      {moment(new Date(createdAt)).fromNow()}
                    </Typography>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              direction="row"
              xs={5}
              sm={isStatusPendingOrRunning ? 7 : 5}
              lg={isStatusPendingOrRunning ? 7 : 5}
              xl={isStatusPendingOrRunning ? 5 : 4}
              container
              className={styles.buttonsContainer}
            >
              <Grid item>
                {cardType === "failed" ? (
                  <Button onClick={() => setOpenRetrainingLogs(true)} size="small" variant="secondary">
                    View Logs
                  </Button>
                ) : ["PENDING", "RUNNING"].includes(status) && trainingOrRetraining !== "training-automl" ? (
                  <Button
                    variant="dangerous"
                    style={{ marginRight: "3px" }}
                    onClick={() => setAbortDialogOpen(true)}
                    size="small"
                  >
                    Abort
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          className={cardType === "failed" ? styles.pendingBodyContainer : styles.pendingBodyContainerPending}
        >
          <Grid container item direction="row" alignItems="flex-end">
            <Grid container item direction="row">
              <Grid item xs={12} sm={9}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
                  <Typography
                    variant="label"
                    tooltip={
                      trainingOrRetraining === "training-automl" || trainingOrRetraining === "training-prebuilt"
                        ? "Trained Model is developed by Konan"
                        : "Original version of the retrained model"
                    }
                    tooltipIconSize={16}
                    tooltipVerticalAlign="middle"
                    className={styles.labelColor}
                  >
                    Source Model
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container className={styles.sourceModelSection}>
              {trainingOrRetraining === "training-automl" || trainingOrRetraining === "training-prebuilt" ? (
                <Fragment>
                  <Grid item marginTop={2} marginBottom={1}>
                    <Typography variant="span">{projectType}</Typography>
                  </Grid>
                  <Grid item>
                    <KonanLogo logoWidth={115} />
                  </Grid>
                </Fragment>
              ) : (
                <Grid item container direction="column" className={styles.pendingSourceModel}>
                  {!isParentModelDataLoading && parentModelData?.data && (
                    <Fragment>
                      <Grid item>
                        <ModelType modelState={parentModelData?.data?.state} size="small" />
                      </Grid>
                      <Grid
                        spacing={1}
                        item
                        onClick={() => {
                          navigate(`/projects/${projectId}/models/requests`)
                          setCurrentModel(parentModelData?.data?.uuid)
                        }}
                        sx={{ width: "100%" }}
                      >
                        <Typography
                          onClick={() => {
                            navigate(`/projects/${projectId}/models/requests`)
                            setCurrentModel(parentModelData?.data?.uuid)
                          }}
                          variant="p"
                          noWrap
                          className={styles.linkText}
                          color="important"
                          variantColor={2}
                        >
                          {parentModelData?.data?.name}
                        </Typography>
                      </Grid>

                      <Grid className={`${styles.dateContainer} ${styles.pendingDataWrapper}`} item>
                        <Typography variant="label" noWrap>
                          <Tooltip
                            display="auto"
                            title={
                              <Typography variant="span">
                                {format(
                                  new Date(parentModelData?.data?.created_at ? parentModelData?.data?.created_at : ""),
                                  "dd/MM/yyyy, p",
                                )}
                              </Typography>
                            }
                            placement="right"
                          >
                            <Typography variant="span">
                              {moment(
                                new Date(parentModelData?.data?.created_at ? parentModelData?.data?.created_at : ""),
                              ).fromNow()}
                            </Typography>
                          </Tooltip>
                        </Typography>
                      </Grid>
                    </Fragment>
                  )}
                  {isParentModelDataLoading && (
                    <Grid
                      container
                      item
                      xs={12}
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexWrap="nowrap"
                    >
                      <Grid container direction="column" className={"generatedModelCard"}>
                        <Grid item>
                          <Skeleton variant="rectangular" width={"40%"} height={10} />
                        </Grid>
                        <Grid item>
                          <Skeleton variant="rectangular" width={"70%"} height={10} />
                        </Grid>
                        <Grid item style={{ marginBottom: "5px" }}>
                          <Skeleton variant="rectangular" width={"40%"} height={10} />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          {cardType === "failed" && (
            <Grid container item direction="row" className={styles.twoBoxesContainer}>
              <div className={styles.testWidthLeft}>
                <Typography
                  variant="label"
                  className={styles.labelColor}
                  tooltipIconSize={16}
                  tooltipVerticalAlign="middle"
                  tooltip={`Start datetime of the ${
                    trainingOrRetraining === "training-automl" || trainingOrRetraining === "training-prebuilt"
                      ? "training"
                      : "retraining"
                  } job`}
                >
                  Started
                </Typography>
                <Grid item className={styles.idbox} sx={{ width: "100px" }}>
                  <Typography variant="p" noWrap className={styles.textIdBox}>
                    {startedAt ? format(new Date(startedAt), "dd/MM/yyyy h:mm:ss") : "N/A"}
                  </Typography>
                </Grid>
              </div>
              <div className={styles.testWidthRight}>
                <Typography
                  variant="label"
                  className={styles.labelColor}
                  tooltip={`End datetime of the ${
                    trainingOrRetraining === "training-automl" || trainingOrRetraining === "training-prebuilt"
                      ? "training"
                      : "retraining"
                  } job`}
                  tooltipIconSize={16}
                  tooltipVerticalAlign="middle"
                >
                  Ended
                </Typography>
                <Grid item className={styles.idbox} sx={{ width: "100px" }}>
                  <Typography variant="p" noWrap className={styles.textIdBox}>
                    {endedAt ? format(new Date(endedAt), "dd/MM/yyyy h:mm:ss") : "N/A"}
                  </Typography>
                </Grid>
              </div>
            </Grid>
          )}

          <Grid container item direction="row" className={styles.twoBoxesContainer}>
            <div className={styles.testWidthLeft}>
              {trainingOrRetraining === "retraining" ? (
                <Fragment>
                  <Typography
                    variant="label"
                    className={styles.labelColor}
                    tooltip="Data date range that the job used to retrain the model"
                    tooltipIconSize={16}
                    tooltipVerticalAlign="middle"
                  >
                    Serving Data
                  </Typography>
                  <Grid container className={styles.idbox}>
                    {firstPredictionDate && lastPredictionDate ? (
                      <Typography variant="p" noWrap className={styles.textIdBox}>
                        {format(new Date(firstPredictionDate), "dd/MM/yy")} -{" "}
                        {format(new Date(lastPredictionDate), "dd/MM/yy")}
                      </Typography>
                    ) : (
                      <Typography variant="p">N/A</Typography>
                    )}
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography
                    variant="label"
                    className={styles.labelColor}
                    tooltip="Training data that the job used to train the model"
                    tooltipIconSize={16}
                    tooltipVerticalAlign="middle"
                  >
                    Training Data
                  </Typography>
                  <Grid container className={styles.idbox}>
                    <Grid item xs={12}>
                      {isTrainingDataLoading && (
                        <Typography variant="p">
                          <Skeleton variant="rectangular" width={"100%"} height={10} />
                        </Typography>
                      )}
                    </Grid>
                    {!isTrainingDataLoading && trainingData?.name && (
                      <Typography variant="p" noWrap className={styles.textIdBox}>
                        {trainingData?.name}
                      </Typography>
                    )}
                    {!isTrainingDataLoading && trainingData?.uuid && !trainingData?.name && (
                      <Typography variant="p" noWrap className={styles.textIdBox}>
                        data.csv
                      </Typography>
                    )}
                    {!isTrainingDataLoading && !trainingData?.uuid && !trainingData?.name && (
                      <Typography variant="p" noWrap className={styles.textIdBox}>
                        N/A
                      </Typography>
                    )}
                  </Grid>
                </Fragment>
              )}
            </div>
            <div className={styles.testWidthRight}>
              <Typography
                variant="label"
                className={styles.labelColor}
                tooltip="Training and testing data split percentage"
                tooltipIconSize={16}
                tooltipVerticalAlign="middle"
              >
                Data Split
              </Typography>
              <Grid container className={styles.idbox}>
                {status === RetrainingJob.status.SUCCEEDED && dataPoints && !isLoading ? (
                  <Typography variant="p" noWrap className={styles.textIdBox}>
                    {dataPoints}
                  </Typography>
                ) : isLoading ? (
                  <div>
                    <Skeleton variant="rectangular" width="100%" height={20} />
                  </div>
                ) : (
                  <Typography variant="p" noWrap className={styles.textIdBox}>
                    N/A
                  </Typography>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
