import React, { Fragment, useEffect, useMemo, useState } from "react"

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import CloseIcon from "@mui/icons-material/Close"
import CodeIcon from "@mui/icons-material/Code"
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import { Button, InputText, NotificationUtils, Skeleton, Tab, Tabs, Typography } from "@synapse-analytics/synapse-ui"
import axios, { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"
import { v4 as uuidv4 } from "uuid"

import { VersionHistory } from "../../components/UI/VersionHistory"
import { StaticAnalysisScript } from "../../screens/ProjectDetails/components/DecisonEngines/components/Workflows/workflow-fixtures"
import { KonanAPI } from "../../services/KonanAPI"
import { VersionChangeRequest } from "../../types/custom/rules"
import { CreateScriptRequest, UpdateScriptRequest } from "../../types/custom/workflows"
import { DataFile } from "../../types/generated/api/DataFile"
import { PaginatedScriptVersionList } from "../../types/generated/api/PaginatedScriptVersionList"
import { ScriptGroupRetrieve } from "../../types/generated/api/ScriptGroupRetrieve"
import { ScriptVersion } from "../../types/generated/api/ScriptVersion"
import { ScriptVersionRetrieve } from "../../types/generated/api/ScriptVersionRetrieve"
import { mapFeaturesListToKeyValuePair } from "../../utils/deploymentDetailsHelpers"
import { createFileFromString, extractPageFromBackEndPaginationLink } from "../../utils/genericHelpers"
import { isStaticAnalysisValid } from "../../utils/workflowHelpers"
import { ScriptDialogProps, SingleScriptProps } from "./Interfaces"
import { CustomScriptEditor, CustomScriptLogs, CustomScriptTestFeatures } from "./components/CustomScriptComponents"

import styles from "./Scripts.module.scss"

/**
 * Dialog for creating, reading and updating custom script
 *
 * @param {function} onClose handler for closing dialog
 * @param {boolean} isWorkFlowInReadMode
 * @param {object} scriptId
 * @param {string} nodeId
 * @param {"workflow" | "scripts"} [renderView] Specifies the context in which the dialog is used.
 * @param {boolean} isNewScriptTriggered indicator for this dialog should be new with clean slate or reference to existing script
 * @returns {React.ReactElement}
 */
function SingleScript(props: Readonly<SingleScriptProps>): React.ReactElement {
  const {
    isWorkFlowInReadMode,
    onClose,
    isNewScriptTriggered = false,
    isScriptLoading,
    scriptData,
    renderView = "workflow",
    openInEditMode = false,
    tab,
    isVersionDialog = false,
    setIsVersionDialog,
  } = props

  const { id: projectId } = useParams<{ id: string }>()
  const queryClient = useQueryClient()

  // states
  const [currentScriptUUID, setCurrentScriptUUID] = useState<string | undefined>(scriptData?.uuid)
  const [retrievedFileText, setRetrievedFileText] = useState<string>("")
  const [selectedState, setSelectedState] = useState<"Test Features" | "Code Editor" | "Logs">(tab ?? "Code Editor")

  const [isConsoleExpanded, setIsConsoleExpanded] = useState<boolean>(false)
  const [isLogsExpanded, setIsLogsExpanded] = useState<boolean>(false)

  const [toggleOpenInEditMode, setToggleOpenInEditMode] = useState<{ isOpen: boolean; snippet?: string }>({
    isOpen: openInEditMode,
    snippet: "",
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [testResponse, setTestResponse] = useState<any>("")

  // state to handle the current mode
  const [mode, setMode] = useState<{ create: boolean; edit: boolean }>({
    create: isNewScriptTriggered,
    edit: openInEditMode,
  })

  const createScriptMutation = useMutation<
    AxiosResponse<ScriptGroupRetrieve>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    CreateScriptRequest
  >(KonanAPI.createScript, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: async (res: any) => {
      setCurrentScriptUUID(res?.data?.uuid)

      await queryClient.invalidateQueries(["scripts", projectId])
      await queryClient.invalidateQueries(["script", projectId, scriptData?.uuid])
      await queryClient.invalidateQueries("getFileContent")
      if (formik.values.isTestSubmission) {
        setMode({ create: true, edit: false })
      } else {
        NotificationUtils.toast(`Script (${res?.data?.name}) created successfully!`, {
          snackBarVariant: "positive",
        })
        onClose()
        setMode({ create: false, edit: false })
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: async (response: any) => {
      if (formik.values.isTestSubmission) {
        formik.setFieldValue("isTestSubmission", false)
      }
      if (response?.response?.data?.details) {
        NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
          snackBarVariant: "negative",
        })
      } else if (response?.response?.data?.name) {
        NotificationUtils.toast(response?.response?.data?.name[0], {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while creating script, please try again!", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  // change script version
  const scriptVersionChangeMutation = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
    KonanAPI.changeResourceVersion,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("selectedScriptVersion")
        await queryClient.invalidateQueries(["scriptVersionsList", projectId])
        await queryClient.invalidateQueries(["script", projectId, scriptData?.uuid])
        await queryClient.invalidateQueries(["scripts", projectId])
      },
      onError: async () => {
        NotificationUtils.toast("An error occurred while restoring script version", {
          snackBarVariant: "negative",
        })
      },
    },
  )

  const updateScriptMutation = useMutation<
    AxiosResponse<ScriptGroupRetrieve>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    UpdateScriptRequest
  >(KonanAPI.updateScript, {
    onSuccess: async (res) => {
      setCurrentScriptUUID(res?.data?.uuid)

      await queryClient.invalidateQueries(["scripts", projectId])
      await queryClient.invalidateQueries(["script", projectId, scriptData?.uuid])

      if (formik.values.isTestSubmission) {
        setMode({ edit: true, create: false })
      } else {
        NotificationUtils.toast(`Script (${res?.data?.name}) updated successfully!`, {
          snackBarVariant: "positive",
        })
        onClose()
        setTimeout(() => {
          setMode({ edit: false, create: false })
        }, 300)
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: async (response: any) => {
      if (formik.values.isTestSubmission) {
        formik.setFieldValue("isTestSubmission", false)
      }
      if (response?.response?.data?.details) {
        NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
          snackBarVariant: "negative",
        })
      } else if (response?.response?.data?.name) {
        NotificationUtils.toast(response?.response?.data?.name[0], {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while updating script, please try again!", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  const testScriptMutation = useMutation<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosResponse<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >(KonanAPI.testScript, {
    mutationKey: ["Test-script-mutation", scriptData?.uuid],
    onSuccess: async (res) => {
      NotificationUtils.toast(
        res?.data?.succeeded
          ? `Script tested successfully!`
          : `It seems there's an error in the script, please check logs for info!`,
        {
          snackBarVariant: res?.data?.succeeded ? "positive" : "negative",
        },
      )

      setTestResponse(res?.data)
      formik.setFieldValue("isTestSubmission", false)
      // this is the last valid date user can revert to, since testing saves and updates the script
      if (mode.edit) {
        setLastSaved(formik.values)
        setToggleOpenInEditMode({ isOpen: false })
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: async (response: any) => {
      if (formik.values.isTestSubmission) {
        formik.setFieldValue("isTestSubmission", false)
      }
      if (response?.response?.data?.details) {
        NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while testing script, please try again!", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  const { mutateAsync: uploadScriptAsyncMutation } = useMutation<DataFile, AxiosError, File>(
    (file: File) => {
      return KonanAPI.uploadDataFile({
        project_uuid: projectId as string,
        file: file,
        type: DataFile.type.SCRIPT,
      })
    },
    {
      mutationKey: "uploadScriptFile",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (response: any) => {
        if (response?.response?.data?.details) {
          NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
            snackBarVariant: "negative",
          })
        }
      },
    },
  )

  const formik = useFormik({
    validationSchema: Yup.object({ scriptName: Yup.string().required("Script name is required") }),
    initialValues: {
      snippet: StaticAnalysisScript,
      scriptName: scriptData?.name ?? "",
      // This is used as an indicator that the "run test" button has been clicked but the mutation hasn't started yet
      // TODO:: refactor later
      isTestSubmission: false,
      isDraft: !isWorkFlowInReadMode,
      showDraftBtn: !isWorkFlowInReadMode,
      testFeatures: [{ feat: "", value: "", uuid: uuidv4() }],
      id: uuidv4(),
    },
    onSubmit: async ({ snippet, scriptName, isDraft, isTestSubmission }) => {
      const staticAnalysis = isStaticAnalysisValid(snippet)
      if (!staticAnalysis.valid && staticAnalysis.reason) {
        formik.setFieldValue("isTestSubmission", false)

        NotificationUtils.toast(`${staticAnalysis.reason}!`, {
          snackBarVariant: "negative",
        })
      } else if (isTestSubmission) {
        formik.isValid ? handleRunTestClicked() : formik.setFieldValue("isTestSubmission", false)
      } else {
        const fileName = `main-${uuidv4()}.py`
        const fileContentType = "text/x-python"
        // converting string to file object
        const finalFile = createFileFromString(snippet, fileName, fileContentType)

        // check if script exists or not first
        if (currentScriptUUID || scriptData?.uuid) {
          // upload script file first
          const dataFileResponse = await uploadScriptAsyncMutation(finalFile)
          // update script first, then testing it
          await updateScriptMutation.mutateAsync({
            file: dataFileResponse?.uuid,
            name: scriptName,
            project_uuid: projectId as string,
            is_draft: isDraft,
            script_uuid: (scriptData?.uuid ?? currentScriptUUID) as string,
          })
        } else {
          // upload script file first
          const dataFileResponse = await uploadScriptAsyncMutation(finalFile)
          // create script and then testing it
          await createScriptMutation.mutateAsync({
            file: dataFileResponse?.uuid,
            name: formik.values.scriptName,
            project_uuid: projectId as string,
            is_draft: isDraft,
          })
        }
      }
    },
  })

  const shouldEnableScriptContentFetch = Boolean(
    !mode.create &&
      !isNewScriptTriggered &&
      !isScriptLoading &&
      !formik.values.isTestSubmission &&
      isStaticAnalysisValid(formik.values?.snippet).valid &&
      !testScriptMutation.isLoading,
  )

  const shouldUpdateScript = Boolean(
    !isWorkFlowInReadMode &&
      !isNewScriptTriggered &&
      !isScriptLoading &&
      !testScriptMutation.isLoading &&
      !formik.values.isTestSubmission,
  )

  const {
    isLoading: isFileLoading,
    isRefetching: isFileRefetching,
    isError: isFetchingFileError,
  } = useQuery(
    ["getFileContent", scriptData?.uuid, scriptData?.active_version?.version],
    async () => (await axios(scriptData?.active_version.file as string)).data,
    {
      refetchOnMount: true,
      enabled: shouldEnableScriptContentFetch,
      onSuccess(data: string) {
        if (shouldUpdateScript) {
          formik.setFieldValue("scriptName", scriptData?.name)
          formik.setFieldValue("showDraftBtn", scriptData?.active_version.is_draft)
          formik.setFieldValue("isDraft", scriptData?.active_version.is_draft)
        }

        if (scriptData) {
          formik.setFieldValue("scriptName", scriptData?.name)
        }

        if (toggleOpenInEditMode.isOpen) {
          setToggleOpenInEditMode({ isOpen: true, snippet: data })
        }

        formik.setFieldValue("snippet", data)
        setRetrievedFileText(data)
      },
      onError() {
        console.warn("An error occurred while fetching script!")
      },
    },
  )

  // saving snapshot of formik before editing
  const [lastSaved, setLastSaved] = React.useState(formik.values)

  // handler for Run test button
  const handleRunTestClicked = async (): Promise<void> => {
    const fileName = `main-${uuidv4()}.py`
    const fileContentType = "text/x-python"

    // converting string to file object
    const finalFile = createFileFromString(formik.values.snippet, fileName, fileContentType)

    const filteredFeatures = mapFeaturesListToKeyValuePair(formik.values.testFeatures)

    // check if there's a script or not, if not then we need to create one first before testing
    if (scriptData?.uuid || currentScriptUUID) {
      // another check we need to do, check wether the script and script name
      // have changed or not, to decide if we will just test script or update script then test it
      const isSameScript = Boolean(
        !isFileLoading &&
          retrievedFileText === formik.values.snippet &&
          (scriptData ? scriptData?.name === formik.values.scriptName : true),
      )

      if (isSameScript) {
        testScriptMutation.mutateAsync({
          project_uuid: projectId as string,
          script_uuid: scriptData?.uuid,
          features: filteredFeatures,
          version: isVersionDialog ? scriptData?.active_version.version : undefined,
        })
        formik.setFieldValue("isTestSubmission", false)
      } else {
        // upload script file first
        const dataFileResponse = await uploadScriptAsyncMutation(finalFile)
        // update script first, then testing it
        const response = await updateScriptMutation.mutateAsync({
          file: dataFileResponse?.uuid,
          name: formik.values.scriptName,
          project_uuid: projectId as string,
          is_draft: formik.values.isDraft,
          script_uuid: scriptData?.uuid as string,
        })

        if (response?.status === 200 && response?.data) {
          setRetrievedFileText(formik.values.snippet)
          // needed to avoid race condition
          setTimeout(async (): Promise<void> => {
            await testScriptMutation.mutateAsync({
              project_uuid: projectId as string,
              script_uuid: response?.data?.uuid,
              features: filteredFeatures,
              version: isVersionDialog ? scriptData?.active_version.version : undefined,
            })
          }, 500)
        }
      }
    } else {
      // upload script file first
      const dataFileResponse = await uploadScriptAsyncMutation(finalFile)
      // create script and then testing it
      if (mode.create) {
        const response = await createScriptMutation.mutateAsync({
          file: dataFileResponse?.uuid,
          name: formik.values.scriptName,
          project_uuid: projectId as string,
          is_draft: true,
        })

        if (response?.status === 201 && response?.data) {
          setRetrievedFileText(formik.values.snippet)
          setTimeout(async () => {
            await testScriptMutation.mutateAsync({
              project_uuid: projectId as string,
              script_uuid: response?.data?.uuid,
              features: filteredFeatures,
              version: isVersionDialog ? scriptData?.active_version.version : undefined,
            })
          }, 500)
        }
        setMode({ create: true, edit: false })
      }
    }
  }

  const isScriptInViewMode = !mode.create && !mode.edit

  const shouldDisableButtons =
    createScriptMutation.isLoading ||
    updateScriptMutation.isLoading ||
    (formik.values.isTestSubmission && formik.isValid) ||
    formik.isSubmitting ||
    testScriptMutation.isLoading

  const handleCancelClicked = (): void => {
    setMode({ edit: false, create: false })

    if (toggleOpenInEditMode.isOpen) {
      formik.setFieldValue("scriptName", scriptData?.name)
      formik.setFieldValue("snippet", toggleOpenInEditMode?.snippet)
      setToggleOpenInEditMode({ isOpen: false })
    } else {
      setTimeout(() => {
        formik.setValues(lastSaved)
        formik.setFieldValue("scriptName", scriptData?.name ?? lastSaved.scriptName)
      }, 10)
    }
  }

  const extractedConsoleData = useMemo((): string[] => {
    if (testScriptMutation.isError) return ["/* Failed to execute script */"]
    else if (testResponse && !testResponse?.succeeded)
      return ["/* It seems there's an error in the script, please check logs for info */"]
    else if (testResponse?.succeeded && testResponse?.output) return [JSON.stringify(testResponse?.output)]
    else return ["// run test to see results"]
  }, [testResponse, testScriptMutation.isError])

  // used to update formik script name once script request finishes
  useEffect(() => {
    scriptData?.name &&
      scriptData?.name !== formik.values.scriptName &&
      formik.setFieldValue("scriptName", scriptData?.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptData?.name])

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} p={2} pb={0.5} style={{ backgroundColor: "var(--grayscale-background-1)" }}>
        <Grid container item xs={12} flexDirection="row" alignItems="center" justifyContent="space-between">
          <Grid item className={styles.scriptSideBar}>
            <CodeIcon sx={{ color: "var(--grayscale-text-1)" }} />
          </Grid>

          <Grid item xs container direction="column" gap={1}>
            {/* Dialog title */}
            <Grid item>
              <Typography variant="label" variantColor={2}>
                SCRIPT
              </Typography>
            </Grid>

            <Grid container item xs mt={mode.create || mode.edit ? "-7px" : "4px"}>
              {isScriptInViewMode && !scriptData?.name && isScriptLoading ? (
                <Grid item mt={1}>
                  <Skeleton width={"60%"} height={18} />
                </Grid>
              ) : isScriptInViewMode && (scriptData?.name || formik.values.scriptName) ? (
                <Typography variant="h3-bold">{scriptData?.name ?? formik.values.scriptName}</Typography>
              ) : (
                <InputText
                  hideDescription
                  key={formik.values.id}
                  disabled={
                    formik.isSubmitting ||
                    (formik.values.isTestSubmission && formik.isValid) ||
                    testScriptMutation.isLoading
                  }
                  type="text"
                  handleChange={formik.handleChange}
                  value={formik.values.scriptName}
                  id="scriptName"
                  widthCalculator
                  placeholder="Script Name"
                  error={formik.touched.scriptName && Boolean(formik.errors.scriptName) && formik.errors.scriptName}
                />
              )}
            </Grid>
          </Grid>

          {renderView !== "workflow" && (
            <Grid container item xs mt={1} height="fit-content" display={"flex"} justifyContent="flex-end">
              {isScriptInViewMode ? (
                <Fragment>
                  {!isVersionDialog ? (
                    <Fragment>
                      <Button
                        style={{ marginRight: "8px" }}
                        onClick={setIsVersionDialog}
                        disabled={
                          formik.isSubmitting || testScriptMutation.isLoading || isScriptLoading || isFileLoading
                        }
                        variant="secondary"
                      >
                        Version History
                      </Button>

                      <Button
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          setMode({ create: false, edit: true })
                          setTimeout(() => {
                            setLastSaved(formik.values)
                          }, 10)
                        }}
                        disabled={formik.isSubmitting || testScriptMutation.isLoading || isScriptLoading}
                        variant="secondary"
                      >
                        Edit
                      </Button>
                    </Fragment>
                  ) : (
                    !scriptData?.active_version?.is_active_version && (
                      <Button
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          scriptVersionChangeMutation.mutateAsync({
                            projectUUID: projectId as string,
                            resource: "scripts",
                            version: scriptData?.active_version.version as string,
                            resourceUUID: scriptData?.uuid as string,
                          })
                        }}
                        disabled={
                          testScriptMutation.isLoading || isScriptLoading || scriptVersionChangeMutation.isLoading
                        }
                        variant="secondary"
                      >
                        Restore
                      </Button>
                    )
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {mode.edit && !isNewScriptTriggered && (
                    <Button
                      style={{ marginRight: "8px" }}
                      disabled={shouldDisableButtons}
                      onClick={handleCancelClicked}
                      variant="secondary"
                    >
                      cancel
                    </Button>
                  )}

                  {formik.values.showDraftBtn && (
                    <Button
                      style={{ marginRight: "8px" }}
                      onClick={() => {
                        formik.setFieldValue("isDraft", true)
                        formik.submitForm()
                      }}
                      disabled={shouldDisableButtons}
                      variant="secondary"
                    >
                      {(createScriptMutation.isLoading || updateScriptMutation.isLoading || formik.isSubmitting) &&
                      formik.values.isDraft &&
                      !formik.values.isTestSubmission &&
                      formik.isValid &&
                      !testScriptMutation.isLoading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Save as draft"
                      )}
                    </Button>
                  )}
                </Fragment>
              )}

              {(mode.create || mode.edit) && (
                <Button
                  disabled={shouldDisableButtons}
                  onClick={() => {
                    formik.setFieldValue("isDraft", false)
                    formik.submitForm()
                  }}
                  variant="primary"
                >
                  {((createScriptMutation.isLoading || updateScriptMutation.isLoading) &&
                    !formik.values.isDraft &&
                    !formik.values.isTestSubmission) ||
                  (formik.isSubmitting && !formik.values.isDraft && !formik.values.isTestSubmission) ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Save"
                  )}
                </Button>
              )}
            </Grid>
          )}
        </Grid>

        <Grid container item xs={12} mt={1}>
          <Tabs value={selectedState}>
            {["Code Editor", "Test Features", ...(isNewScriptTriggered ? [] : ["Logs"])].map((label) => (
              <Tab
                key={label}
                label={label === "Logs" ? "Historical Logs" : label}
                value={label}
                selected={label === selectedState}
                onClick={() => setSelectedState(label as "Code Editor" | "Test Features" | "Logs")}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>

      <Grid container item xs={12} flexDirection={"column"}>
        {selectedState === "Logs" ? (
          <CustomScriptLogs uuid={scriptData?.uuid as string} />
        ) : selectedState === "Code Editor" ? (
          <CustomScriptEditor
            isLoading={isFileLoading || isScriptLoading}
            isRefetchingFile={isFileRefetching}
            script={scriptData}
            isReadMode={shouldDisableButtons || isScriptInViewMode}
            formik={formik}
            consoleLogs={extractedConsoleData}
            isFetchingFileError={isFetchingFileError}
            logs={testResponse?.logs}
            isConsoleExpanded={isConsoleExpanded}
            setIsConsoleExpanded={() => setIsConsoleExpanded(!isConsoleExpanded)}
            setIsLogsExpanded={() => setIsLogsExpanded(!isLogsExpanded)}
            isLogsExpanded={isLogsExpanded}
          />
        ) : (
          <CustomScriptTestFeatures formik={formik} />
        )}
      </Grid>
    </Grid>
  )
}

export function ScriptDialog(props: Readonly<ScriptDialogProps>): React.ReactElement {
  const { isOpen, onClose, scriptId } = props
  const { id: projectId } = useParams<{ id: string }>()

  const MuiTheme = useMuiTheme()

  const [isVersionDialog, setIsVersionDialog] = useState<boolean>(false)
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>()

  // fetch Script
  const { data: scriptData, isLoading: isScriptLoading } = useQuery<AxiosResponse<ScriptGroupRetrieve>, AxiosError>(
    ["script", projectId, scriptId],
    () => KonanAPI.fetchScript({ projectUUID: projectId as string, scriptUUID: scriptId as string }),
    {
      onSuccess: ({ data }) => {
        setSelectedVersion(data.active_version.version)
      },
      refetchOnMount: false,
      enabled: !!projectId && !!scriptId && !isVersionDialog,
    },
  )

  // list Versions
  const {
    isLoading: isVersionsListLoading,
    isFetchingNextPage,
    data: versionsList,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<AxiosResponse<PaginatedScriptVersionList>, AxiosError>(
    ["scriptVersionsList", projectId, scriptId, isVersionDialog],
    ({ pageParam = 1 }) =>
      KonanAPI.listScriptVersions({
        projectUUID: projectId as string,
        scriptUUID: scriptId as string,
        page_size: 10,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.data?.next ? extractPageFromBackEndPaginationLink(lastPage.data?.next) : false
      },
      enabled: !!projectId && !!scriptId && isVersionDialog,
      refetchOnMount: true,
    },
  )

  // extracting the results after infinite-Query
  const adjustedScriptVersions = useMemo(() => {
    return (
      versionsList?.pages.reduce(
        (accumulator, page) => accumulator.concat(page.data.results as ScriptVersion[]),
        [] as ScriptVersion[],
      ) ?? []
    )
  }, [versionsList?.pages])

  // fetch Version
  const { data: scriptVersionData, isLoading: isScriptVersionDataLoading } = useQuery<
    AxiosResponse<ScriptVersionRetrieve>,
    AxiosError
  >(
    ["selectedScriptVersion", projectId, scriptId, selectedVersion, isVersionDialog],
    () =>
      KonanAPI.retrieveScriptVersion({
        projectUUID: projectId as string,
        scriptUUID: scriptId as string,
        version: selectedVersion as string,
      }),
    {
      enabled: !!projectId && !!scriptId && isVersionDialog && !!selectedVersion,
      refetchOnMount: false,
    },
  )

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={isVersionDialog ? "lg" : "md"}
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("md"))}
      onClose={onClose}
    >
      <DialogTitle className="dialog-header-base" style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2-bold">SCRIPT</Typography>

        <IconButton onClick={onClose} size="small" className={"close-icon-button"}>
          <CloseIcon style={{ color: "var(--grayscale-text-2)" }} />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.scriptDialogContent}>
        {!isVersionDialog ? (
          <SingleScript
            {...props}
            isVersionDialog={isVersionDialog}
            setIsVersionDialog={() => setIsVersionDialog(true)}
            scriptData={scriptData?.data as ScriptGroupRetrieve}
            isScriptLoading={isScriptLoading}
          />
        ) : (
          <Grid container>
            <Grid item width={"calc(100% - 300px)"} id="single-script">
              <SingleScript
                {...props}
                isVersionDialog={isVersionDialog}
                setIsVersionDialog={() => setIsVersionDialog(true)}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                scriptData={{ ...scriptData?.data, active_version: scriptVersionData?.data as ScriptVersionRetrieve }}
                isScriptLoading={isScriptVersionDataLoading}
              />
            </Grid>

            <Grid item xs={3} minWidth={"300px"}>
              <VersionHistory
                onClose={() => setIsVersionDialog(false)}
                versions={adjustedScriptVersions ?? []}
                selectedVersion={selectedVersion ?? ""}
                setSelectedVersion={setSelectedVersion}
                isLoading={isVersionsListLoading}
                isScriptView
                activeVersion={scriptData?.data.active_version as ScriptVersionRetrieve}
                infiniteScrollProps={{
                  hasNextPage,
                  fetchNextPage,
                  isFetchingNextPage,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}
