import { AxiosResponse } from "axios"
import { OpenAPIV3 } from "openapi-types"

import { AxiosApi } from "../lib/axios"
import { CreateIssueRequest } from "../types/custom/core"
import {
  ClassificationConfiguration,
  ConfigureAutoMlModelRequest,
  CreateAutoMlRequest,
  CreateModelRequest,
  CreateModelResponse,
  CreateProgramRequest,
  CreateProjectRequest,
  CreateRetrainingModelRequest,
  CreateScoreCardRequest,
  CreateScorecardSetRequest,
  CreateWorkflowRequest,
  DeleteModelRequest,
  DriftResponse,
  ModelTimeRange,
  ProjectLimits,
  ProjectTimeRange,
  RequiredSchema,
  ScriptLogsRange,
  StatisticsResponse,
  TaskId,
  TestPrediction,
  TrainModelUsingPrebuiltModelRequest,
  UpdateProgramRequest,
  UpdateScorecardRequest,
  UpdateScorecardsetRequest,
  UpdateWorkflowRequest,
  WorkflowSimulationJobCreateRequest,
  WorkflowSimulationJobFetchRequest,
  WorkflowSimulationOutputFetchRequest,
  uploadDataRequest,
} from "../types/custom/projects"
import {
  CreateRulesetRequest,
  CreateTaglistRequest,
  RetrieveLabelsRequest,
  UpdateRulesetRequest,
  UpdateTaglistRequest,
  VersionChangeRequest,
} from "../types/custom/rules"
import {
  CreateBuiltinScriptRequest,
  CreateScriptRequest,
  TestScriptRequest,
  UpdateScriptRequest,
} from "../types/custom/workflows"
import { AutoMLJobLogs } from "../types/generated/api/AutoMLJobLogs"
import { ClassificationEvaluation } from "../types/generated/api/ClassificationEvaluation"
import { DataFile } from "../types/generated/api/DataFile"
import { Deployment } from "../types/generated/api/Deployment"
import { DeploymentLatestFeedbackDateTime } from "../types/generated/api/DeploymentLatestFeedbackDateTime"
import { DeploymentLogs } from "../types/generated/api/DeploymentLogs"
import { DeploymentModelsAvgResponseTime } from "../types/generated/api/DeploymentModelsAvgResponseTime"
import { DeploymentPredictionsSummary } from "../types/generated/api/DeploymentPredictionsSummary"
import { DeploymentUsage } from "../types/generated/api/DeploymentUsage"
import { EvaluateResult } from "../types/generated/api/EvaluateResult"
import { ExportJobCreateRequest } from "../types/generated/api/ExportJobCreateRequest"
import { ExportJobRetrieve } from "../types/generated/api/ExportJobRetrieve"
import { KonanRegistryImagesSeriliazer } from "../types/generated/api/KonanRegistryImagesSeriliazer"
import { KonanRegistryToken } from "../types/generated/api/KonanRegistryToken"
import { Label } from "../types/generated/api/Label"
import { ListClassificationModelOutputs } from "../types/generated/api/ListClassificationModelOutputs"
import { Model } from "../types/generated/api/Model"
import { PaginatedAutoMLTrainingJobList } from "../types/generated/api/PaginatedAutoMLTrainingJobList"
import { PaginatedDownloadExportJobList } from "../types/generated/api/PaginatedDownloadExportJobList"
import { PaginatedExportJobListList } from "../types/generated/api/PaginatedExportJobListList"
import { PaginatedLabelList } from "../types/generated/api/PaginatedLabelList"
import { PaginatedListClassificationModelOutputsList } from "../types/generated/api/PaginatedListClassificationModelOutputsList"
import { PaginatedListModelOutputsList } from "../types/generated/api/PaginatedListModelOutputsList"
import { PaginatedListModelStateSwitchList } from "../types/generated/api/PaginatedListModelStateSwitchList"
import { PaginatedListPredictionsList } from "../types/generated/api/PaginatedListPredictionsList"
import { PaginatedListRequestLogList } from "../types/generated/api/PaginatedListRequestLogList"
import { PaginatedModelSettingsSetList } from "../types/generated/api/PaginatedModelSettingsSetList"
import { PaginatedPredefinedTypeListList } from "../types/generated/api/PaginatedPredefinedTypeListList"
import { PaginatedPredictionReviewList } from "../types/generated/api/PaginatedPredictionReviewList"
import { PaginatedProgramList } from "../types/generated/api/PaginatedProgramList"
import { PaginatedRetrainingJobList } from "../types/generated/api/PaginatedRetrainingJobList"
import { PaginatedRuleSetListList } from "../types/generated/api/PaginatedRuleSetListList"
import { PaginatedScoreCardSetListList } from "../types/generated/api/PaginatedScoreCardSetListList"
import { PaginatedScriptGroupListList } from "../types/generated/api/PaginatedScriptGroupListList"
import { PaginatedScriptTemplateList } from "../types/generated/api/PaginatedScriptTemplateList"
import { PaginatedScriptVersionList } from "../types/generated/api/PaginatedScriptVersionList"
import { PaginatedTagListListList } from "../types/generated/api/PaginatedTagListListList"
import { PaginatedTrainingJobList } from "../types/generated/api/PaginatedTrainingJobList"
import { PaginatedWorkflowListList } from "../types/generated/api/PaginatedWorkflowListList"
import { PaginatedWorkflowSimulationJobList } from "../types/generated/api/PaginatedWorkflowSimulationJobList"
import { PaginatedWorkflowSimulationOutputList } from "../types/generated/api/PaginatedWorkflowSimulationOutputList"
import { PaginatedWorkflowVersionListList } from "../types/generated/api/PaginatedWorkflowVersionListList"
import { PatchedConfigureAutoMLRequest } from "../types/generated/api/PatchedConfigureAutoMLRequest"
import { PatchedScoreCardSetCreateUpdateRequest } from "../types/generated/api/PatchedScoreCardSetCreateUpdateRequest"
import { PredictionReview } from "../types/generated/api/PredictionReview"
import { PredictionReviewRequest } from "../types/generated/api/PredictionReviewRequest"
import { Program } from "../types/generated/api/Program"
import { ProgramRetrieve } from "../types/generated/api/ProgramRetrieve"
import { RequestSummaryDailyOutput } from "../types/generated/api/RequestSummaryDailyOutput"
import { RequestSummaryOutput } from "../types/generated/api/RequestSummaryOutput"
import { RetrainingJobLogs } from "../types/generated/api/RetrainingJobLogs"
import { RuleSetGroupRetrieve } from "../types/generated/api/RuleSetGroupRetrieve"
import { ScoreCardGroupRetrieve } from "../types/generated/api/ScoreCardGroupRetrieve"
import { ScoreCardList } from "../types/generated/api/ScoreCardList"
import { ScoreCardSetGroupRetrieve } from "../types/generated/api/ScoreCardSetGroupRetrieve"
import { ScriptGroupRetrieve } from "../types/generated/api/ScriptGroupRetrieve"
import { ScriptTemplateRetrieve } from "../types/generated/api/ScriptTemplateRetrieve"
import { ScriptVersionRetrieve } from "../types/generated/api/ScriptVersionRetrieve"
import { TableColumnConfigurationRequest } from "../types/generated/api/TableColumnConfigurationRequest"
import { TableConfigurationRequest } from "../types/generated/api/TableConfigurationRequest"
import { TagListGroupRetrieve } from "../types/generated/api/TagListGroupRetrieve"
import { TestPredictionResponse } from "../types/generated/api/TestPredictionResponse"
import { TrainingDataResponse } from "../types/generated/api/TrainingDataResponse"
import { TrainingDataSchemaResponse } from "../types/generated/api/TrainingDataSchemaResponse"
import { TrainingDataUpload } from "../types/generated/api/TrainingDataUpload"
import { Version } from "../types/generated/api/Version"
import { WorkflowGroupRetrieve } from "../types/generated/api/WorkflowGroupRetrieve"
import { WorkflowResponseFilter } from "../types/generated/api/WorkflowResponseFilter"
import { WorkflowSimulationJobCreate } from "../types/generated/api/WorkflowSimulationJobCreate"
import { WorkflowSimulationJobReport } from "../types/generated/api/WorkflowSimulationJobReport"
import { WorkflowVersionRetrieve } from "../types/generated/api/WorkflowVersionRetrieve"
import { getHostnames } from "../utils/genericHelpers"
import { DeploymentPredictionNextRunAt } from "./../types/generated/api/DeploymentPredictionNextRunAt"

class KonanAPI extends AxiosApi {
  constructor() {
    const { backend } = getHostnames()

    super(backend)
  }

  fetchKonanPermissions = async (): Promise<AxiosResponse> => {
    const { data } = await this.fetchResource(`/permissions/`)
    return data
  }

  fetchRegistryImages = async (): Promise<Array<KonanRegistryImagesSeriliazer>> => {
    const { data } = await this.fetchResource(`/registry/images/`)
    return data
  }

  fetchRegistryToken = async (): Promise<KonanRegistryToken> => {
    const { data } = await this.fetchResource(`/registry/token/`)
    return data
  }

  fetchProjects = async (): Promise<AxiosResponse<Array<Deployment>>> => {
    return this.fetchResource(`/projects/`)
  }

  fetchProject = async (uuid: string): Promise<AxiosResponse<Deployment>> => {
    return this.fetchResource(`/projects/${uuid}/`)
  }

  fetchProjectDocs = async (uuid: string): Promise<AxiosResponse<OpenAPIV3.Document>> => {
    return this.fetchResource(`/ml-docs/${uuid}/`)
  }

  fetchBackendVersion = async (): Promise<AxiosResponse<Version>> => {
    return this.fetchResource(`/version/`)
  }

  createProject = async (props: CreateProjectRequest): Promise<AxiosResponse<Deployment>> => {
    return this.postResource(`/projects/`, {
      name: props.name,
      type: props.type,
      category: props.category,
      description: props.description ? props.description : undefined,
    })
  }

  deleteProject = async (projectUUID: string): Promise<AxiosResponse> => {
    return this.deleteResource(`/projects/${projectUUID}/`)
  }

  fetchRequestLogData = async (
    startDate: string,
    endDate: string,
    projectUUID: string,
    page: number,
    pageSize: number,
  ): Promise<AxiosResponse<PaginatedListRequestLogList>> => {
    return this.fetchResource(`/projects/${projectUUID}/requests/`, {
      start_time: startDate,
      end_time: endDate,
      page: page,
      page_size: pageSize,
    })
  }

  fetchRequestSummary = async ({
    start_date,
    end_date,
    project_uuid,
  }: ProjectTimeRange): Promise<AxiosResponse<RequestSummaryOutput>> => {
    return this.fetchResource(`/projects/${project_uuid}/requests/summary/`, {
      start_time: start_date,
      end_time: end_date,
    })
  }

  fetchDailyProjectRequestSummary = async ({
    start_date,
    end_date,
    project_uuid,
  }: ProjectTimeRange): Promise<RequestSummaryDailyOutput> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/predictions/summary/daily/`, {
      start_time: start_date,
      end_time: end_date,
    })
    return data
  }

  fetchDailyModelRequestSummary = async ({
    start_date,
    end_date,
    model_uuid,
  }: ModelTimeRange): Promise<RequestSummaryDailyOutput> => {
    const { data } = await this.fetchResource(`/projects/models/${model_uuid}/predictions/summary/daily/`, {
      start_time: start_date,
      end_time: end_date,
    })
    return data
  }

  fetchProjectAverageResponseTime = async ({
    start_date,
    end_date,
    project_uuid,
  }: ProjectTimeRange): Promise<AxiosResponse<DeploymentModelsAvgResponseTime>> => {
    return this.fetchResource(`/projects/${project_uuid}/metrics/response-time/avg/`, {
      start_time: start_date,
      end_time: end_date,
    })
  }

  fetchModelLogs = async ({ start_date, end_date, model_uuid }: ModelTimeRange): Promise<DeploymentLogs> => {
    const { data } = await this.fetchResource(`/projects/models/${model_uuid}/logs/`, {
      model_uuid: model_uuid,
      start_time: start_date,
      end_time: end_date,
    })
    return data
  }

  switchModelState = async ({
    projectUUID,
    modelUUID,
    newState,
  }: {
    projectUUID: string | undefined
    modelUUID: string
    newState: string
  }): Promise<AxiosResponse> => {
    return this.postResource(`/projects/${projectUUID}/switch/`, {
      new_state: newState,
      switching_model: modelUUID,
    })
  }

  createStatisticsRun = async ({ start_date, end_date, project_uuid }: ProjectTimeRange): Promise<TaskId> => {
    const { data } = await this.postResource(`/projects/${project_uuid}/statistics/`, {
      project_uuid: project_uuid,
      start_time: start_date,
      end_time: end_date,
    })
    return data
  }

  fetchStatistics = async (taskId: string): Promise<AxiosResponse<StatisticsResponse>> => {
    return this.fetchResource(`/projects/tasks/${taskId}/`)
  }

  uploadTrainingData = async (
    project_uuid: string,
    file: File,
    setProgress: (progress: number) => void,
    signal: AbortSignal,
  ): Promise<TrainingDataResponse> => {
    const formData = new FormData()
    formData.append("training_data_file", file)
    const { data } = await this.postResource(`/projects/${project_uuid}/training-data/upload/`, formData, {
      onUploadProgress: function (progressEvent) {
        // TODO:: check this later
        progressEvent.total && setProgress((progressEvent.loaded / progressEvent.total) * 100)
      },
      signal,
    })
    return data
  }

  fetchTrainingData = async (project_uuid: string): Promise<TrainingDataUpload[]> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/training-data/`)
    return data
  }

  deleteTrainingDataFromModel = async (model_uuid: string): Promise<AxiosResponse> => {
    return this.deleteResource(`/projects/models/${model_uuid}/training_data/`)
  }

  deleteTrainingDataFromProject = async (project_uuid: string, training_uuid: string): Promise<AxiosResponse> => {
    return this.deleteResource(`/projects/${project_uuid}/training-data/${training_uuid}/`)
  }

  AssignTrainingDataToModel = async (model_uuid: string, training_data: string): Promise<AxiosResponse> => {
    return this.postResource(`/projects/models/${model_uuid}/training_data/`, {
      training_data: training_data,
    })
  }

  fetchSingleTrainingData = async (
    project_uuid: string,
    training_data_uuid: string | undefined,
  ): Promise<TrainingDataUpload> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/training-data/${training_data_uuid}/`)
    return data
  }

  fetchDataDriftJobs = async (
    modelUuid: string,
    page?: number,
    pageSize?: number,
  ): Promise<AxiosResponse<DriftResponse>> => {
    return this.fetchResource(`/projects/models/${modelUuid}/data-drift-jobs/`, {
      page: page,
      page_size: pageSize,
    })
  }

  fetchProjectPredictions = async (
    startDate: string,
    endDate: string,
    projectUUID: string,
    page: number,
    pageSize: number,
    filterObject?: object,
  ): Promise<AxiosResponse<PaginatedListPredictionsList>> => {
    return this.fetchResource(`/projects/${projectUUID}/predictions/`, {
      start_time: startDate,
      end_time: endDate,
      page: page,
      page_size: pageSize,
      ...filterObject,
    })
  }

  evaluateProject = async ({ start_date, end_date, project_uuid }: ProjectTimeRange): Promise<EvaluateResult> => {
    const { data } = await this.postResource(`/projects/${project_uuid}/evaluate/`, {
      start_time: start_date,
      end_time: end_date,
    })
    return data
  }

  evaluateModel = async ({ start_date, end_date, model_uuid }: ModelTimeRange): Promise<EvaluateResult> => {
    const { data } = await this.postResource(`/projects/models/${model_uuid}/evaluate/`, {
      start_time: start_date,
      end_time: end_date,
    })
    return data
  }

  getRequestDates = async (project_uuid: string): Promise<string[]> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/requests/dates/`)
    return data
  }

  fetchModels = async (project_uuid: string): Promise<AxiosResponse<Array<Model>>> => {
    return this.fetchResource(`/projects/${project_uuid}/models/`)
  }

  fetchModel = async (uuid: string): Promise<AxiosResponse<Model>> => {
    return this.fetchResource(`/projects/models/${uuid}/`)
  }

  createModel = async (requestBody: CreateModelRequest): Promise<AxiosResponse<CreateModelResponse>> => {
    return this.postResource(`/projects/${requestBody.project_uuid}/models/`, requestBody)
  }

  deleteModel = async (props: DeleteModelRequest): Promise<AxiosResponse> => {
    return this.deleteResource(`/projects/models/${props.model_uuid}/`)
  }

  fetchModelCpuAndRamUsage = async (
    modelUuid: string,
    start_date: string,
    end_date: string,
  ): Promise<AxiosResponse<DeploymentUsage>> => {
    return this.fetchResource(`/projects/models/${modelUuid}/usage/`, {
      start_time: start_date,
      end_time: end_date,
      step_size_seconds: 260,
      window_size_seconds: 60,
    })
  }

  fetchProjectCpuAndRamUsage = async (
    project_uuid: string,
    start_date: string,
    end_date: string,
  ): Promise<AxiosResponse<DeploymentUsage>> => {
    return this.fetchResource(`/projects/${project_uuid}/usage/`, {
      start_time: start_date,
      end_time: end_date,
      step_size_seconds: 500,
      window_size_seconds: 2500,
    })
  }

  switchModelToLive = async ({
    project_uuid,
    model_uuid,
    switch_to,
  }: {
    project_uuid: string
    model_uuid: string
    switch_to: "disabled" | "challenger"
  }): Promise<AxiosResponse> => {
    return this.postResource(`/projects/${project_uuid}/switch/`, {
      switch_to: switch_to,
      new_live_model: model_uuid,
    })
  }

  getProjectHistory = async (project_uuid: string): Promise<PaginatedListModelStateSwitchList> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/switch/history/`)
    return data
  }

  getModelHistory = async (model_uuid: string): Promise<PaginatedListModelStateSwitchList> => {
    const { data } = await this.fetchResource(`/projects/models/${model_uuid}/switch/history/`)
    return data
  }

  fetchApiRequestsSummary = async ({
    start_date,
    end_date,
    project_uuid,
  }: ProjectTimeRange): Promise<AxiosResponse<DeploymentPredictionsSummary>> => {
    return this.fetchResource(`/projects/${project_uuid}/predictions/summary/`, {
      start_time: start_date,
      end_time: end_date,
    })
  }

  fetchLastFeedback = async (project_uuid: string): Promise<AxiosResponse<DeploymentLatestFeedbackDateTime>> => {
    return this.fetchResource(`/projects/${project_uuid}/predictions/feedback/latest/`)
  }

  fetchProjectRequestLogData = async (
    startDate: string,
    endDate: string,
    projectUUID: string,
    page: number,
    pageSize: number,
  ): Promise<AxiosResponse<PaginatedListPredictionsList>> => {
    return this.fetchResource(`/projects/${projectUUID}/predictions/`, {
      start_time: startDate,
      end_time: endDate,
      page: page,
      page_size: pageSize,
    })
  }

  fetchModelRequestLogData = async (
    startDate: string,
    endDate: string,
    modelUuid: string,
    page?: number,
    pageSize?: number,
  ): Promise<AxiosResponse<PaginatedListModelOutputsList>> => {
    return this.fetchResource(`/projects/models/${modelUuid}/predictions/`, {
      start_time: startDate,
      end_time: endDate,
      page: page,
      page_size: pageSize,
    })
  }

  fetchProjectsPredictionsNextRun = async (project_uuid: string): Promise<DeploymentPredictionNextRunAt> => {
    const { data } = await this.fetchResource(`/projects/models/${project_uuid}/predictions/next-run-at/`)
    return data
  }

  fetchModelClassificationEvaluationMetrics = async (
    startDate: string,
    endDate: string,
    modelUuid: string,
  ): Promise<AxiosResponse<ClassificationEvaluation>> => {
    return this.fetchResource(`/projects/models/${modelUuid}/evaluate/classification/`, {
      start_time: startDate,
      end_time: endDate,
    })
  }

  fetchModelOutputPredictionsData = async (
    startDate: string,
    endDate: string,
    modelUuid: string,
    page?: number,
    pageSize?: number,
    filterObject?: object,
  ): Promise<AxiosResponse<PaginatedListClassificationModelOutputsList>> => {
    return this.fetchResource(`/projects/models/${modelUuid}/predictions/classification/`, {
      start_time: startDate,
      end_time: endDate,
      page: page,
      page_size: pageSize,
      ...filterObject,
    })
  }

  fetchProjectPredictionDates = async (projectUUID: string): Promise<AxiosResponse> => {
    return this.fetchResource(`/projects/${projectUUID}/predictions/dates/`)
  }

  createRetrainingModel = async (requestBody: CreateRetrainingModelRequest): Promise<AxiosResponse> => {
    return this.postResource(`/projects/${requestBody.project_uuid}/retraining/`, {
      predictions_start_time: requestBody.predictions_start_time,
      predictions_end_time: requestBody.predictions_end_time,
      append_training_data: requestBody.append_training_data,
      resulting_model_name: requestBody.resulting_model_name,
      resulting_model_state: requestBody.resulting_model_state,
    })
  }

  fetchRetrainingModels = async (
    project_uuid: string,
    pageSize?: number,
    page?: number,
  ): Promise<PaginatedRetrainingJobList> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/retraining/`, {
      page_size: pageSize,
      page: page,
    })
    return data
  }

  fetchTrainingModels = async (
    project_uuid: string,
    pageSize?: number,
    page?: number,
  ): Promise<PaginatedTrainingJobList> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/training/`, {
      page_size: pageSize,
      page: page,
    })
    return data
  }

  fetchRetrainingModelLogs = async (project_uuid: string, uuid: string): Promise<RetrainingJobLogs> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/retraining/${uuid}/logs/`)
    return data
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchTrainingModelLogs = async (project_uuid: string, uuid: string): Promise<any> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/training/${uuid}/logs/`)
    return data
  }

  fetchAutoMLTrainingJobLogs = async (project_uuid: string, uuid: string): Promise<AutoMLJobLogs> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/automl/trainings/${uuid}/logs/`)
    return data
  }

  deleteRetrainingModel = async (project_uuid: string): Promise<AxiosResponse> => {
    return this.deleteResource(`/projects/${project_uuid}/retraining/`)
  }
  deleteTrainingModel = async (project_uuid: string, training_uuid: string): Promise<AxiosResponse> => {
    return this.deleteResource(`/projects/${project_uuid}/training/${training_uuid}`)
  }

  createIssue = async ({ summary, issueType, description }: CreateIssueRequest): Promise<AxiosResponse> => {
    return this.postResource(`/support/`, {
      summary: summary,
      issue_type: issueType,
      description: description,
    })
  }

  testPrediction = async ({
    model_uuid,
    requestJson,
    skip_config = false,
  }: TestPrediction): Promise<AxiosResponse<TestPredictionResponse>> => {
    return await this.postResource(`/projects/${model_uuid}/predictions/test/`, {
      ...requestJson,
      skip_config,
    })
  }

  fetchModelConfigurationsList = async (
    model_uuid: string,
    page?: number,
    pageSize?: number,
  ): Promise<AxiosResponse<PaginatedModelSettingsSetList>> => {
    return this.fetchResource(`/projects/models/${model_uuid}/configurations/`, {
      page: page,
      page_size: pageSize,
    })
  }

  fetchActiveModelConfiguration = async (
    model_uuid: string,
  ): Promise<AxiosResponse<ListClassificationModelOutputs>> => {
    return this.fetchResource(`/projects/models/${model_uuid}/configurations/active/`)
  }

  createModelConfiguration = async ({
    model,
    type,
    target_key_path,
    settings,
  }: ClassificationConfiguration): Promise<AxiosResponse> => {
    return this.postResource(`/projects/models/${model}/configurations/`, {
      model,
      type,
      target_key_path,
      settings,
    })
  }

  fetchRequiredSchema = async (projectUUID: string): Promise<RequiredSchema[]> => {
    const { data } = await this.fetchResource(`/projects/${projectUUID}/prebuilt-model-schema/`)
    return data
  }

  fetchProjectLimits = async (): Promise<AxiosResponse<ProjectLimits>> => {
    return this.fetchResource(`/projects/limits/`)
  }

  trainModelUsingPrebuiltModel = async (props: TrainModelUsingPrebuiltModelRequest): Promise<AxiosResponse> => {
    return this.postResource(`/projects/${props.projectUUID}/models/prebuilt/`, {
      name: props.modelName,
      training_data: props.trainingDataUUID,
    })
  }

  fetchRulesets = async (
    projectUUID: string,
    page?: number,
    page_size?: number,
  ): Promise<AxiosResponse<PaginatedRuleSetListList>> => {
    return this.fetchResource(`/projects/${projectUUID}/rulesets/`, {
      page,
      page_size,
    })
  }

  createRuleset = async (props: CreateRulesetRequest): Promise<AxiosResponse> => {
    return this.postResource(`/projects/${props.projectUUID}/rulesets/`, {
      rules: props?.rules ? props?.rules : props.rulesUUID,
      name: props.rulesetName,
      type: props.type,
    })
  }

  updateRuleset = async (props: UpdateRulesetRequest): Promise<AxiosResponse> => {
    return this.patchResource(`/projects/${props.projectUUID}/rulesets/${props.rulesetUUID}/`, {
      rules: props.rules,
      name: props.rulesetName,
    })
  }

  fetchRuleset = async (projectUUID: string, rulesetUUID: string): Promise<AxiosResponse<RuleSetGroupRetrieve>> => {
    return this.fetchResource(`/projects/${projectUUID}/rulesets/${rulesetUUID}/`)
  }

  deleteWorkflowResource = async (props: {
    project_uuid: string
    resource_uuid: string
    resource_type: "rulesets" | "taglists" | "programs" | "scorecardsets"
  }): Promise<AxiosResponse> => {
    const { project_uuid, resource_uuid, resource_type } = props

    return this.deleteResource(`/projects/${project_uuid}/${resource_type}/${resource_uuid}/`)
  }

  fetchWorkflows = async ({
    projectUUID,
    page_size,
    page,
  }: {
    projectUUID: string
    page_size: number
    page?: number
  }): Promise<AxiosResponse<PaginatedWorkflowListList>> => {
    return this.fetchResource(`/projects/${projectUUID}/workflows/`, {
      page_size: page_size,
      page,
    })
  }

  fetchWorkflowVersions = async ({
    projectUUID,
    workflowUUID,
    pageSize,
    page,
  }: {
    projectUUID: string
    workflowUUID: string
    pageSize?: number
    page?: number
  }): Promise<AxiosResponse<PaginatedWorkflowVersionListList>> => {
    return this.fetchResource(`/projects/${projectUUID}/workflows/${workflowUUID}/versions/`, {
      page_size: pageSize,
      page,
    })
  }

  fetchWorkflowVersion = async (
    projectUUID: string,
    version: string,
    workflowUUID: string,
  ): Promise<AxiosResponse<WorkflowVersionRetrieve>> => {
    // adding the extra ".0" since the backend requires the version to be X.Y at a minimum
    // where X is the major version and Y is the minor version
    const computedVersion = version.split(".").length === 2 ? version : version + ".0"

    return this.fetchResource(`/projects/${projectUUID}/workflows/${workflowUUID}/versions/${computedVersion}/`)
  }

  fetchWorkflow = async (projectUUID: string, workflowUUID: string): Promise<AxiosResponse<WorkflowGroupRetrieve>> => {
    return this.fetchResource(`/projects/${projectUUID}/workflows/${workflowUUID}/`)
  }

  createWorkflow = async (data: CreateWorkflowRequest): Promise<AxiosResponse> => {
    return this.postResource(`/projects/${data.projectUUID}/workflows/`, {
      name: data.name,
      state: data.state,
      start_node: data.start_node,
      nodes: data.nodes,
      schema: data.schema,
    })
  }

  // update a workflow
  updateWorkflow = async (props: UpdateWorkflowRequest): Promise<AxiosResponse> => {
    const { projectUUID, workflowUUID, deprecate_workflows = false, name, newState, nodes, schema, start_node } = props
    return this.patchResource(
      `/projects/${projectUUID}/workflows/${workflowUUID}/?deprecate_workflows=${deprecate_workflows}`,
      {
        state: newState,
        name: name,
        nodes: nodes,
        start_node: start_node,
        schema: schema,
      },
    )
  }

  // update scorecardset
  updateScorecardset = async (
    props: UpdateScorecardsetRequest,
  ): Promise<AxiosResponse<PatchedScoreCardSetCreateUpdateRequest>> => {
    return this.patchResource(`/projects/${props.projectUUID}/scorecardsets/${props.scorecardsetUUID}/`, {
      name: props.name,
      calculation_method: props.calculation_method,
      scorecards: props?.scorecards,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateScorecard = async (props: UpdateScorecardRequest): Promise<AxiosResponse<any>> => {
    return this.patchResource(`/projects/${props.projectUUID}/scorecards/${props.scorecardUUID}/`, {
      name: props.name,
      rules: props.rules,
    })
  }

  // fetch scorecardsets
  fetchScorecardsets = async (
    projectUUID: string,
    page?: number,
    pageSize?: number,
  ): Promise<AxiosResponse<PaginatedScoreCardSetListList>> => {
    return this.fetchResource(`/projects/${projectUUID}/scorecardsets/`, {
      page_size: pageSize,
      page,
    })
  }

  createScorecard = async (props: CreateScoreCardRequest): Promise<AxiosResponse> => {
    return this.postResource(`/projects/${props.projectUUID}/scorecards/`, {
      name: props.name,
      rules: props?.rules,
    })
  }

  createScorecardSet = async (props: CreateScorecardSetRequest): Promise<AxiosResponse> => {
    return this.postResource(`/projects/${props.projectUUID}/scorecardsets/`, {
      name: props.name,
      calculation_method: props.calculation_method,
      scorecards: props.scorecards,
    })
  }

  fetchScorecardset = async (
    projectUUID: string,
    scorecardsetUUID: string,
  ): Promise<AxiosResponse<ScoreCardSetGroupRetrieve>> => {
    return this.fetchResource(`/projects/${projectUUID}/scorecardsets/${scorecardsetUUID}/`)
  }

  fetchScorecards = async (projectUUID: string): Promise<AxiosResponse<ScoreCardList>> => {
    return this.fetchResource(`/projects/${projectUUID}/scorecards/`)
  }

  fetchScorecard = async (
    projectUUID: string,
    scorecardUUID: string,
  ): Promise<AxiosResponse<ScoreCardGroupRetrieve>> => {
    return this.fetchResource(`/projects/${projectUUID}/scorecards/${scorecardUUID}`)
  }

  // Programs
  fetchProgram = async (projectUUID: string, programUUID: string): Promise<AxiosResponse<ProgramRetrieve>> => {
    return this.fetchResource(`/projects/${projectUUID}/programs/${programUUID}`)
  }

  fetchPrograms = async (
    projectUUID: string,
    page?: number,
    page_size?: number,
  ): Promise<AxiosResponse<PaginatedProgramList>> => {
    return this.fetchResource(`/projects/${projectUUID}/programs/`, {
      page: page,
      page_size: page_size,
    })
  }

  createProgram = async (props: CreateProgramRequest): Promise<AxiosResponse<Program>> => {
    return this.postResource(`/projects/${props.projectUUID}/programs/`, {
      name: props.name,
      items: props?.items,
    })
  }

  updateProgram = async (props: UpdateProgramRequest): Promise<AxiosResponse> => {
    return this.patchResource(`/projects/${props.projectUUID}/programs/${props.programUUID}/`, {
      name: props.name,
      items: props?.items,
    })
  }

  trainModelUsingAutoMlModel = async (props: CreateAutoMlRequest): Promise<AxiosResponse> => {
    const { excluded_columns, name, training_data, target_column, mapping } = props
    return this.postResource(`/projects/${props.projectUUID}/automl/trainings/`, {
      name,
      mapping,
      excluded_columns,
      target_column,
      training_data,
    })
  }

  fetchTrainingDataSchema = async (
    projectUUID: string,
    trainingDataUUID: string,
  ): Promise<TrainingDataSchemaResponse> => {
    const { data } = await this.fetchResource(`/projects/${projectUUID}/training-data/${trainingDataUUID}/schema/`)
    return data
  }

  // get all pre-defined types for automl mapping
  fetchAutomlPredefinedTypes = async (props: {
    projectUUID: string
    type: string
  }): Promise<AxiosResponse<PaginatedPredefinedTypeListList>> => {
    const { projectUUID, type } = props
    return this.fetchResource(`/projects/${projectUUID}/automl/predefined-types/`, {
      type,
    })
  }

  fetchAutoMlTrainingJobs = async (
    project_uuid: string,
    pageSize?: number,
    page?: number,
  ): Promise<PaginatedAutoMLTrainingJobList> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/automl/trainings/`, {
      page_size: pageSize,
      page: page,
    })
    return data
  }

  configureAutoMlModel = (
    props: ConfigureAutoMlModelRequest,
  ): Promise<AxiosResponse<PatchedConfigureAutoMLRequest>> => {
    const { threshold, state, negative_label, positive_label } = props
    return this.patchResource(`/projects/${props.projectUUID}/automl/${props.modelUUID}/configure/`, {
      threshold,
      state,
      negative_label,
      positive_label,
    })
  }

  // list all thresholds with their metrics
  fetchThresholdWithMetrics = (props: { projectUUID: string; modelUUID: string }): Promise<AxiosResponse> => {
    const { modelUUID, projectUUID } = props
    return this.fetchResource(`/projects/${projectUUID}/automl/${modelUUID}/threshold-metrics/`)
  }

  // DataFiles
  uploadDataFile = async (props: uploadDataRequest): Promise<DataFile> => {
    const { project_uuid, file, setProgress, signal, type } = props

    const formData = new FormData()
    formData.append("data_file", file)
    formData.append("type", type)
    const { data } = await this.postResource(`/projects/${project_uuid}/data-files/upload/`, formData, {
      onUploadProgress: function (progressEvent) {
        // TODO:: check this later
        progressEvent.total && setProgress?.((progressEvent.loaded / progressEvent.total) * 100)
      },
      signal,
    })
    return data
  }

  fetchDataFiles = (project_uuid: string, type: string): Promise<AxiosResponse> => {
    return this.fetchResource(`/projects/${project_uuid}/data-files/`, {
      type: type,
    })
  }

  fetchDataFile = (project_uuid: string, file_uuid: string): Promise<AxiosResponse<DataFile>> => {
    return this.fetchResource(`/projects/${project_uuid}/data-files/${file_uuid}`)
  }

  deleteDataFile = async (project_uuid: string, data_file_uuid: string): Promise<AxiosResponse> => {
    return this.deleteResource(`/projects/${project_uuid}/data-files/${data_file_uuid}`)
  }

  // Simulation
  createSimulation = async (
    props: WorkflowSimulationJobCreateRequest,
    workflowId: string,
  ): Promise<AxiosResponse<WorkflowSimulationJobCreate>> => {
    const { projectUUID, predictions_datafile, predictions_start_time, predictions_end_time, target_column } = props

    return this.postResource(`/projects/${projectUUID}/workflows/simulations/`, {
      predictions_datafile: predictions_datafile,
      predictions_start_time: predictions_start_time,
      predictions_end_time: predictions_end_time,
      target_column: target_column,
      workflow: workflowId,
    })
  }

  fetchSimulations = async (
    props: WorkflowSimulationJobFetchRequest,
  ): Promise<AxiosResponse<PaginatedWorkflowSimulationJobList>> => {
    const { projectUUID, page, pageSize, workflow_name } = props

    return this.fetchResource(`/projects/${projectUUID}/workflows/simulations/`, {
      page: page,
      page_size: pageSize,
      workflow_name: workflow_name,
    })
  }

  fetchSimulationOutputs = async (
    props: WorkflowSimulationOutputFetchRequest,
  ): Promise<AxiosResponse<PaginatedWorkflowSimulationOutputList>> => {
    const { projectUUID, simulation_uuid, page, pageSize, filterObject } = props

    return this.fetchResource(`/projects/${projectUUID}/workflows/simulations/${simulation_uuid}/outputs`, {
      page: page,
      page_size: pageSize,
      ...filterObject,
    })
  }

  // Reports
  fetchSimulationReportCharts = async (props: {
    project_uuid: string
    simulation_uuid: string
  }): Promise<AxiosResponse<WorkflowSimulationJobReport>> => {
    const { project_uuid, simulation_uuid } = props

    return this.fetchResource(`/projects/${project_uuid}/reports/simulations/${simulation_uuid}/`)
  }

  // Labels
  RetrieveLabels = async (props: RetrieveLabelsRequest): Promise<AxiosResponse<PaginatedLabelList>> => {
    const { project_uuid, page, pageSize, search } = props

    return await this.fetchResource(`/projects/${project_uuid}/labels/`, {
      page: page,
      search: search,
      page_size: pageSize,
    })
  }

  FetchLabel = async (props: { project_uuid: string; label_uuid: string }): Promise<AxiosResponse<Label>> => {
    const { project_uuid, label_uuid } = props

    return await this.fetchResource(`/projects/${project_uuid}/labels/${label_uuid}/`)
  }

  CreateLabel = async (props: { project_uuid: string; name: string }): Promise<AxiosResponse<Label>> => {
    const { project_uuid, name } = props

    return await this.postResource(`/projects/${project_uuid}/labels/`, { name: name })
  }

  DeleteLabel = async (props: { project_uuid: string; label_uuid: string }): Promise<AxiosResponse> => {
    const { project_uuid, label_uuid } = props

    return await this.deleteResource(`/projects/${project_uuid}/labels/${label_uuid}/`)
  }

  UpdateLabel = async (props: {
    project_uuid: string
    label_uuid: string
    name: string
  }): Promise<AxiosResponse<Label>> => {
    const { project_uuid, label_uuid, name } = props

    return await this.patchResource(`/projects/${project_uuid}/labels/${label_uuid}/`, { name: name })
  }

  // Output filter
  createUpdateOutputFilters = async (props: {
    project_uuid: string
    workflow_uuid: string
    fields: string[]
  }): Promise<AxiosResponse<WorkflowResponseFilter>> => {
    const { project_uuid, workflow_uuid, fields } = props

    return this.putResource(`/projects/${project_uuid}/workflows/${workflow_uuid}/response-filter/`, {
      fields: fields,
    })
  }

  // scripts
  fetchScripts = async (
    projectUUID: string,
    page?: number,
    page_size?: number,
    search?: string,
  ): Promise<AxiosResponse<PaginatedScriptGroupListList>> => {
    return this.fetchResource(`/projects/${projectUUID}/scripts/`, {
      page: page,
      page_size: page_size ?? 200,
      search,
    })
  }

  fetchScript = async (props: {
    projectUUID: string
    scriptUUID: string
  }): Promise<AxiosResponse<ScriptGroupRetrieve>> => {
    const { projectUUID, scriptUUID } = props

    return this.fetchResource(`/projects/${projectUUID}/scripts/${scriptUUID}/`)
  }

  listScriptVersions = async (props: {
    projectUUID: string
    scriptUUID: string
    page?: number
    page_size?: number
  }): Promise<AxiosResponse<PaginatedScriptVersionList>> => {
    const { projectUUID, scriptUUID, page, page_size } = props

    return this.fetchResource(`/projects/${projectUUID}/scripts/${scriptUUID}/versions`, {
      page: page,
      page_size: page_size,
    })
  }

  retrieveScriptVersion = async (props: {
    projectUUID: string
    scriptUUID: string
    version: string
  }): Promise<AxiosResponse<ScriptVersionRetrieve>> => {
    const { projectUUID, scriptUUID, version } = props

    return this.fetchResource(`/projects/${projectUUID}/scripts/${scriptUUID}/versions/${version}`)
  }

  // create script
  createScript = async (props: CreateScriptRequest): Promise<AxiosResponse<ScriptGroupRetrieve>> => {
    const { file, name, project_uuid, is_draft, language } = props

    return this.postResource(`/projects/${project_uuid}/scripts/`, {
      file,
      name,
      is_draft,
      language,
    })
  }

  // update
  updateScript = async (props: UpdateScriptRequest): Promise<AxiosResponse<ScriptGroupRetrieve>> => {
    const { file, language_version, name, project_uuid, is_draft, language, script_uuid, creds } = props

    return this.patchResource(`/projects/${project_uuid}/scripts/${script_uuid}/`, {
      file,
      language_version,
      name,
      is_draft,
      language,
      creds,
    })
  }

  // script-logs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchScriptLogs = async ({ start_date, end_date, project_uuid, script_uuid }: ScriptLogsRange): Promise<any> => {
    const { data } = await this.fetchResource(`/projects/${project_uuid}/scripts/${script_uuid}/logs/`, {
      start_time: start_date,
      end_time: end_date,
    })
    return data
  }

  DeleteScript = async (props: { project_uuid: string; script_uuid: string }): Promise<AxiosResponse> => {
    const { project_uuid, script_uuid } = props

    return await this.deleteResource(`/projects/${project_uuid}/scripts/${script_uuid}/`)
  }

  // fetch templates (ready scripts)
  fetchReadyTemplates = async (props: {
    projectUUID: string
    page?: number
    page_size?: number
    search?: string
    language?: string
  }): Promise<AxiosResponse<PaginatedScriptTemplateList>> => {
    const { projectUUID, language, page, page_size, search } = props
    return this.fetchResource(`/projects/${projectUUID}/scripts/templates/`, {
      page,
      page_size,
      search,
      language,
    })
  }

  // fetch builtin template (ready script)
  fetchReadyTemplate = async (props: {
    projectUUID: string
    templateUUID: string
  }): Promise<AxiosResponse<ScriptTemplateRetrieve>> => {
    const { projectUUID, templateUUID } = props
    return this.fetchResource(`/projects/${projectUUID}/scripts/templates/${templateUUID}/`)
  }

  // create built-in script
  createBuiltinScript = async (props: CreateBuiltinScriptRequest): Promise<AxiosResponse> => {
    const { project_uuid, template_uuid, creds, name } = props

    return this.postResource(`/projects/${project_uuid}/scripts/from-template/${template_uuid}/`, {
      creds,
      name,
    })
  }

  // test
  testScript = async (props: TestScriptRequest): Promise<AxiosResponse> => {
    const { project_uuid, script_uuid, features, version } = props

    return this.postResource(`/projects/${project_uuid}/scripts/${script_uuid}/test/`, {
      ...features,
      version,
    })
  }

  // TagLists
  fetchTagLists = async (
    projectUUID: string,
    page?: number,
    page_size?: number,
  ): Promise<AxiosResponse<PaginatedTagListListList>> => {
    return this.fetchResource(`/projects/${projectUUID}/taglists/`, {
      page,
      page_size,
    })
  }

  fetchTaglist = async (projectUUID: string, taglistUUID: string): Promise<AxiosResponse<TagListGroupRetrieve>> => {
    return this.fetchResource(`/projects/${projectUUID}/taglists/${taglistUUID}/`)
  }

  createTaglist = async (props: CreateTaglistRequest): Promise<AxiosResponse> => {
    return this.postResource(`/projects/${props.projectUUID}/taglists/`, {
      rules: props?.rules,
      name: props.taglistName,
    })
  }

  updateTaglist = async (props: UpdateTaglistRequest): Promise<AxiosResponse> => {
    return this.patchResource(`/projects/${props.projectUUID}/taglists/${props.taglistUUID}/`, {
      rules: props.rules,
      name: props.taglistName,
    })
  }

  changeResourceVersion = async (props: VersionChangeRequest): Promise<AxiosResponse> => {
    // adding the extra ".0" since the backend requires the version to be X.Y at a minimum
    // where X is the major version and Y is the minor version
    const version = props.version.split(".").length === 2 ? props.version : props.version + ".0"

    return this.patchResource(
      `/projects/${props.projectUUID}/${props.resource}/${props.resourceUUID}/change-version/`,
      {
        version: version,
      },
    )
  }

  configureTableColumns = async (
    props: TableConfigurationRequest & { project_uuid: string },
  ): Promise<AxiosResponse<TableColumnConfigurationRequest>> => {
    const { project_uuid, column_configs, table } = props

    return this.putResource(`/projects/${project_uuid}/table-configurations/`, {
      column_configs,
      table,
    })
  }

  fetchTableColumnsConfigs = async (
    projectUUID: string,
    table: string,
  ): Promise<AxiosResponse<TableConfigurationRequest>> => {
    return this.fetchResource(`/projects/${projectUUID}/table-configurations/`, {
      table,
    })
  }

  // Export
  listExportJobs = async (props: {
    project_uuid: string
    search: string
    status: string
    page: number
    page_size: number
    ordering: string
  }): Promise<AxiosResponse<PaginatedExportJobListList>> => {
    const { project_uuid, search, status, page, page_size, ordering } = props

    return this.fetchResource(`/projects/${project_uuid}/export-jobs/`, {
      name: search,
      status: status,
      page: page,
      page_size: page_size,
      ordering: ordering,
    })
  }

  retrieveExportJob = async (props: {
    project_uuid: string
    export_job_uuid: string
  }): Promise<AxiosResponse<ExportJobRetrieve>> => {
    const { project_uuid, export_job_uuid } = props

    return this.fetchResource(`/projects/${project_uuid}/export-jobs/${export_job_uuid}/`)
  }

  createExportJob = async (
    props: ExportJobCreateRequest & {
      project_uuid: string
      predictions_start_time?: string
      predictions_end_time?: string
    },
  ): Promise<AxiosResponse<ExportJobRetrieve>> => {
    const { project_uuid } = props

    return this.postResource(`/projects/${project_uuid}/export-jobs/`, {
      ...props,
    })
  }

  deleteExportJob = async (props: {
    project_uuid: string
    export_job_uuid: string
  }): Promise<AxiosResponse<ExportJobRetrieve>> => {
    const { project_uuid, export_job_uuid } = props

    return this.deleteResource(`/projects/${project_uuid}/export-jobs/${export_job_uuid}/`)
  }

  cancelExportJob = async (props: {
    project_uuid: string
    export_job_uuid: string
  }): Promise<AxiosResponse<ExportJobRetrieve>> => {
    const { project_uuid, export_job_uuid } = props

    return this.deleteResource(`/projects/${project_uuid}/export-jobs/${export_job_uuid}/cancel/`)
  }

  retrieveExportJobsDownloadLogs = async (props: {
    project_uuid: string
    search: string
  }): Promise<AxiosResponse<PaginatedDownloadExportJobList>> => {
    const { project_uuid, search } = props

    return this.fetchResource(`/projects/${project_uuid}/downloaded-export-jobs/`, {
      export_job_name: search,
    })
  }

  // Prediction Reviews
  createPredictionReview = async (
    props: PredictionReviewRequest & { prediction_uuid: string },
  ): Promise<AxiosResponse<PredictionReview>> => {
    const { prediction_uuid, status } = props

    return this.postResource(`/projects/predictions/${prediction_uuid}/reviews/`, {
      status: status,
    })
  }

  fetchPredictionReviewHistory = async (props: {
    prediction_uuid: string
    page: number
    pageSize: number
  }): Promise<AxiosResponse<PaginatedPredictionReviewList>> => {
    const { prediction_uuid, page, pageSize } = props

    return this.fetchResource(`/projects/predictions/${prediction_uuid}/reviews/`, {
      page: page,
      page_size: pageSize,
    })
  }
}

const api = new KonanAPI()
export { api as KonanAPI }
