import { Dispatch, FC, Fragment, SetStateAction, useContext, useMemo, useRef, useState } from "react"

import { useQuery } from "react-query"
import { EdgeLabelRenderer, EdgeProps, getBezierPath, useStoreApi } from "reactflow"

import AddIcon from "@mui/icons-material/Add"
import { Box, Menu, MenuItem, PopoverVirtualElement } from "@mui/material"
import { Button, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { getTheme } from "../../../../../../../hooks/UseTheme"
import { KonanAPI } from "../../../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../../../store/CurrentProjectAndModelContext"
import { UpdateGraphParams } from "../../../../../../../types/custom/workflows"
import { Deployment } from "../../../../../../../types/generated/api/Deployment"
import { PaginatedProgramList } from "../../../../../../../types/generated/api/PaginatedProgramList"
import { PaginatedRuleSetListList } from "../../../../../../../types/generated/api/PaginatedRuleSetListList"
import { PaginatedScoreCardSetListList } from "../../../../../../../types/generated/api/PaginatedScoreCardSetListList"
import { PaginatedScriptGroupListList } from "../../../../../../../types/generated/api/PaginatedScriptGroupListList"
import { PaginatedTagListListList } from "../../../../../../../types/generated/api/PaginatedTagListListList"
import { RuleSetCreateRequest } from "../../../../../../../types/generated/api/RuleSetCreateRequest"
import { nodeBuilder } from "../../../../../../../utils/workflowHelpers"
import { NODES_WITHOUT_LABEL } from "../workflow-fixtures"
import { MenuItemWithTooltip } from "./AddBlockNode"
import { CalculatorNode } from "./CalculatorNode"
import { SelectionNode } from "./SelectionNode"

import styles from "./CustomCards.module.scss"

type NodeOptionsProps = {
  anchorEl: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | undefined | null>>
  source: string
  updateGraph: (data: UpdateGraphParams) => void
  target: string
  edgeLabel?: string
}

function NodeOptionsMenu({
  anchorEl,
  setAnchorEl,
  source,
  updateGraph,
  target,
  edgeLabel,
}: Readonly<NodeOptionsProps>): React.ReactElement {
  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const theme = getTheme()

  //fetch rulesets
  const { data: rulesets, isLoading: isRulesetsLoading } = useQuery<
    AxiosResponse<PaginatedRuleSetListList>,
    AxiosError
  >(["rulesets", currentProject], () => KonanAPI.fetchRulesets(currentProject.uuid as string), {
    enabled: currentProject !== "",
  })

  // fetch programs
  const { isLoading: isProgramsLoading, data: programs } = useQuery<AxiosResponse<PaginatedProgramList>, AxiosError>(
    ["programs", currentProject],
    () => KonanAPI.fetchPrograms(currentProject.uuid as string),
    {
      enabled: currentProject != null,
    },
  )

  // Fetching projects,
  const { isLoading: isProjectsLoading, data: projects } = useQuery<AxiosResponse<Array<Deployment>>, AxiosError>(
    ["projects"],
    () => KonanAPI.fetchProjects(),
  )

  // fetch scorecardsets
  const { isLoading: isScorecardsetsLoading, data: scorecardsets } = useQuery<
    AxiosResponse<PaginatedScoreCardSetListList>,
    AxiosError
  >(["scorecardsets", currentProject], () => KonanAPI.fetchScorecardsets(currentProject?.uuid as string), {
    enabled: currentProject !== "",
  })

  // fetch taglists
  const { isLoading: isTaglistsLoading, data: taglists } = useQuery<
    AxiosResponse<PaginatedTagListListList>,
    AxiosError
  >(["taglists", currentProject], () => KonanAPI.fetchTagLists(currentProject?.uuid as string), {
    enabled: !!currentProject,
  })

  // fetch scripts
  const { data: scripts, isLoading: isScriptsLoading } = useQuery<
    AxiosResponse<PaginatedScriptGroupListList>,
    AxiosError
  >(["scripts", currentProject], () => KonanAPI.fetchScripts(currentProject?.uuid as string), {
    enabled: !!currentProject,
  })

  const open = Boolean(anchorEl)

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  // handler for selecting an item (node) to add
  const handleItemClick = (type: string): void => {
    const newNode = nodeBuilder(source, type, updateGraph)
    if (updateGraph) {
      updateGraph({
        arrayOfNewNodes: [newNode],
        isNodeBetween: true,
        targetNode: target,
        sourceNode: source,
        edgeLabel,
      })
    }
  }

  // extracting and memoizing the generic typed rulesets
  const genericRulesetsMemo = useMemo(() => {
    const generics = rulesets?.data?.results?.filter((ruleset) => ruleset?.type === RuleSetCreateRequest.type.GENERIC)
    return generics
  }, [rulesets?.data?.results])

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      classes={{
        paper: styles.menu,
      }}
      slotProps={{
        paper: {
          style: {
            backgroundColor: theme.palette.grayscale.background[1],
            border: theme.palette.neutral.background.enabled,
          },
        },
      }}
      variant="selectedMenu"
    >
      {/* Ruleset node */}
      <MenuItemWithTooltip
        Item={<SelectionNode menuView={true} setAllNodes={updateGraph} data={{ nodeType: "RulesetNode" }} />}
        onSelectingBlock={() => handleItemClick("RulesetNode")}
        tooltipCondition={!isRulesetsLoading && genericRulesetsMemo?.length === 0}
        tooltipText={"This project doesn't have any rulesets, therefore you can't add a ruleset node in a workflow"}
        isNodeDisabled={isRulesetsLoading}
      />

      <Box mt={1} />
      <MenuItem onClick={() => handleItemClick("DecisionNode")}>
        <SelectionNode menuView={true} data={{ nodeType: "DecisionNode" }} />
      </MenuItem>

      <Box mt={1} />
      {/* TaglistNode node */}
      <MenuItemWithTooltip
        Item={<SelectionNode menuView={true} data={{ nodeType: "TaglistNode" }} setAllNodes={updateGraph} />}
        onSelectingBlock={() => handleItemClick("TaglistNode")}
        tooltipCondition={!isTaglistsLoading && taglists?.data?.results?.length === 0}
        tooltipText={
          "This project doesn't have any scorecardsets, therefore you can't add a scorecardset node in a workflow"
        }
        isNodeDisabled={isTaglistsLoading}
      />

      <Box mt={1} />
      {/* Scorecardset node */}
      <MenuItemWithTooltip
        Item={<SelectionNode menuView={true} data={{ nodeType: "ScorecardsetNode" }} setAllNodes={updateGraph} />}
        onSelectingBlock={() => handleItemClick("ScorecardsetNode")}
        tooltipCondition={!isScorecardsetsLoading && scorecardsets?.data?.results?.length === 0}
        tooltipText={
          "This project doesn't have any scorecardsets, therefore you can't add a scorecardset node in a workflow"
        }
        isNodeDisabled={isScorecardsetsLoading}
      />

      <Box mt={1} />
      {/* Program node */}
      <MenuItemWithTooltip
        Item={<SelectionNode menuView={true} data={{ nodeType: "ProgramNode" }} setAllNodes={updateGraph} />}
        onSelectingBlock={() => handleItemClick("ProgramNode")}
        tooltipCondition={!isProgramsLoading && programs?.data?.results?.length === 0}
        tooltipText={"This project doesn't have any programs, therefore you can't add a program node in a workflow"}
        isNodeDisabled={isProgramsLoading}
      />

      <Box mt={1} />
      <MenuItem onClick={() => handleItemClick("CalculatorNode")}>
        <CalculatorNode menuView={true} />
      </MenuItem>

      <Box mt={1} />
      {/* Script node */}
      <MenuItemWithTooltip
        Item={<SelectionNode menuView={true} setAllNodes={updateGraph} data={{ nodeType: "ScriptNode" }} />}
        onSelectingBlock={() => handleItemClick("ScriptNode")}
        tooltipCondition={!isScriptsLoading && scripts?.data?.results?.length === 0}
        tooltipText={"This project doesn't have any scripts, therefore you can't add a script node in a workflow"}
        isNodeDisabled={isScriptsLoading}
      />

      <Box mt={1} />
      {/* Project node */}
      <MenuItemWithTooltip
        Item={<SelectionNode menuView={true} data={{ nodeType: "ProjectNode" }} setAllNodes={updateGraph} />}
        onSelectingBlock={() => handleItemClick("ProjectNode")}
        tooltipCondition={!isProjectsLoading && projects?.data?.length === 0}
        tooltipText={
          "This organization doesn't have any other projects, therefore you can't add a project node in a workflow"
        }
        isNodeDisabled={isProjectsLoading}
      />
    </Menu>
  )
}

export const CustomEdgeLabel: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
  source,
  target,
  style,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const theme = getTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | undefined>(null)
  const updateGraph = data?.updateGraph ?? null

  const store = useStoreApi()

  const { getNodes } = store.getState()

  const buttonRef = useRef<HTMLButtonElement>()

  const sourceNodeType = useMemo(() => {
    const sourceNode = getNodes()?.find((n) => n.id === source)
    if (sourceNode) {
      return sourceNode?.data?.nodeType
    }
  }, [getNodes, source])

  const targetNodeType = useMemo(() => {
    const targetNode = getNodes()?.find((n) => n.id === target)
    if (targetNode) {
      return targetNode?.type
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNodes(), target])

  // boolean (showAddNodeButton) if these conditions met
  const showAddNodeButton = Boolean(
    !data?.readMode && sourceNodeType !== "EndResultNode" && targetNodeType !== "AddBlockNode",
  )

  // boolean (center label) if these conditions met
  const centerLabel = Boolean(
    data?.readMode || NODES_WITHOUT_LABEL.includes(sourceNodeType) || targetNodeType === "AddBlockNode",
  )

  const adjustedFilterXCoordinates = Boolean(sourceNodeType === "FilterNode" && !data?.readMode && showAddNodeButton)

  const getAddNodeButtonXAxis = (): string => {
    return sourceNodeType === "FilterNode" && data?.text === "false"
      ? "40%"
      : sourceNodeType === "FilterNode" && data?.text === "true"
        ? "-90%"
        : "-50%"
  }

  const getAddNodeButtonYAxis = (): string => {
    return sourceNodeType === "FilterNode" ? "-20%" : NODES_WITHOUT_LABEL.includes(sourceNodeType) ? "-50%" : "10%"
  }

  return (
    <Fragment>
      <NodeOptionsMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        source={source}
        updateGraph={updateGraph}
        target={target}
        edgeLabel={data?.text}
      />

      <path id={id} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        {!NODES_WITHOUT_LABEL.includes(sourceNodeType) && (
          <div
            style={{
              position: "absolute",
              transform: `translate(${
                adjustedFilterXCoordinates && data?.text === "false"
                  ? "-130%"
                  : adjustedFilterXCoordinates && data?.text === "true"
                    ? "50%"
                    : "-50%"
              }, ${
                centerLabel ? "-50%" : sourceNodeType === "FilterNode" ? "-85%" : "-120%"
              }) translate(${labelX}px,${labelY}px)`,

              background: theme.palette.grayscale.background[1],
              padding: 5,
              borderRadius: 2,
              fontSize: 12,
              fontWeight: 700,
              color: theme.palette.grayscale.text[1],
            }}
            className="nodrag nopan"
          >
            <Typography variant="label">{data.text}</Typography>
          </div>
        )}

        {showAddNodeButton && (
          <div
            style={{
              position: "absolute",
              transform: `translate(${getAddNodeButtonXAxis()}, ${getAddNodeButtonYAxis()}) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: "all",
            }}
            className="nodrag nopan"
          >
            <Button size="large" variant="secondary" onClick={() => setAnchorEl(buttonRef.current)} ref={buttonRef}>
              <AddIcon fontSize="small" />
            </Button>
          </div>
        )}
      </EdgeLabelRenderer>
    </Fragment>
  )
}
