import React, { useMemo, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useLocation, useParams } from "react-router-dom"

import { Grid, Skeleton } from "@mui/material"
import {
  DateRangePicker,
  InputChangeEvent,
  InputText,
  NotificationUtils,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import moment from "moment"
import queryString from "query-string"

import { KonanAPI } from "../../services/KonanAPI"
import { ExportJobCreateRequest } from "../../types/generated/api/ExportJobCreateRequest"
import { ExportJobRetrieve } from "../../types/generated/api/ExportJobRetrieve"
import { PaginatedListModelOutputsList } from "../../types/generated/api/PaginatedListModelOutputsList"
import { PaginatedListPredictionsList } from "../../types/generated/api/PaginatedListPredictionsList"
import { KonanTimeHelper } from "../../utils/genericHelpers"
import { BaseSimpleDialog } from "./BaseSimpleDialog"

interface Props {
  onClose: () => void
  jobType: ExportJobCreateRequest.type
  model?: string
  simulation_job?: string
}

/** ExportDataDialog container that wraps the date picker for choosing the predictions time range
 *  as well as the name of the Exported data file name
 *
 * @param {function} onClose Callback function called when the dialog is closed.
 * @param {ExportJobCreateRequest.type} jobType The type of export job.
 * @param {string} [model] The model associated with the export job.
 * @param {string} [simulation_job] The simulation job associated with the export job.
 * @returns {React.ReactElement}
 */
export function ExportDataDialog(props: Readonly<Props>): React.ReactElement {
  const { onClose, jobType, model, simulation_job } = props
  const { id: projectId } = useParams<{ id: string }>()

  const queryClient = useQueryClient()
  const konanTime = new KonanTimeHelper()
  const location = useLocation()

  // fetches query search params from URL
  const queryParams = useMemo(() => queryString.parse(location.search), [location.search])

  const [startDate, setStartDate] = useState<moment.Moment | null>(
    queryParams.startDate && typeof queryParams.startDate === "string"
      ? moment(queryParams.startDate)
      : konanTime.adjustDate(moment().subtract(30, "days"), "start"),
  )
  const [endDate, setEndDate] = useState<moment.Moment | null>(
    queryParams.endDate && typeof queryParams.endDate === "string"
      ? moment(queryParams.endDate)
      : konanTime.adjustDate(moment(), "end"),
  )

  const [jobName, setJobName] = useState<string>("")

  // Fetching metadata selectively
  const { isLoading: isRequestLogsLoading, data: requestLogData } = useQuery<
    AxiosResponse<PaginatedListModelOutputsList | PaginatedListPredictionsList>,
    AxiosError
  >(
    ["export-request-log", startDate, endDate, model],
    () =>
      model
        ? KonanAPI.fetchModelRequestLogData(
            startDate?.toISOString() as string,
            endDate?.toISOString() as string,
            model,
            1,
            1,
          )
        : KonanAPI.fetchProjectRequestLogData(
            startDate?.toISOString() as string,
            endDate?.toISOString() as string,
            projectId as string,
            1,
            1,
          ),
    { keepPreviousData: true, enabled: simulation_job === undefined },
  )

  const createExportJobMutation = useMutation<
    AxiosResponse<ExportJobRetrieve>,
    AxiosError,
    ExportJobCreateRequest & {
      project_uuid: string
      predictions_start_time: string | undefined
      predictions_end_time: string | undefined
    }
  >(KonanAPI.createExportJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(["data-files", projectId])

      NotificationUtils.toast("Export Started", {
        snackBarVariant: "positive",
      })
    },
    onError: () =>
      NotificationUtils.toast("Export Failed", {
        snackBarVariant: "negative",
      }),
  })

  const onAcceptClicked = async (): Promise<void> => {
    await createExportJobMutation.mutateAsync({
      predictions_end_time: simulation_job ? undefined : (endDate?.toISOString() as string),
      predictions_start_time: simulation_job ? undefined : (startDate?.toISOString() as string),
      type: jobType,
      name: jobName,
      project_uuid: projectId as string,
      model: model,
      simulation_job: simulation_job,
    })

    onClose()
  }

  return (
    <BaseSimpleDialog
      open
      // TODO:: make name nullable
      name={""}
      onAccept={async () => onAcceptClicked()}
      onClose={onClose}
      mode={"export-table"}
      isLoading={createExportJobMutation.isLoading}
      disableMainBtn={jobName === "" || (!isRequestLogsLoading && requestLogData?.data.count === 0)}
    >
      <InputText
        label={`Name`}
        id="Name"
        value={jobName}
        placeholder={`Name`}
        fullWidth
        handleChange={(e: InputChangeEvent) => setJobName(e.target.value as string)}
      />

      {!simulation_job && (
        <Grid item xs={12} className="data-popper-modifier" mt={1.5}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={(startDate: moment.Moment | null) =>
              setStartDate(konanTime.adjustDate(startDate, "start"))
            }
            onEndDateChange={(endDate: moment.Moment | null) => setEndDate(konanTime.adjustDate(endDate, "end"))}
            disableFuture
          />
          {isRequestLogsLoading ? (
            <Skeleton animation="wave" width="30%" height="60%" />
          ) : (
            <Typography variant="span">{requestLogData?.data.count} requests</Typography>
          )}
        </Grid>
      )}
    </BaseSimpleDialog>
  )
}
