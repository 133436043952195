import React, { Fragment, ReactElement, useEffect, useState } from "react"

import { useIsMutating, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { CircularProgress, Grid } from "@mui/material"
import { Button, InputChangeEvent, InputText, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { FormikProps } from "formik"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

import { queryClient } from "../../.."
import { KonanLogViewer } from "../../../components/KonanLogViewer"
import { MonacoTextEditor } from "../../../components/MonacoTextEditor"
import { AccordionHeader } from "../../../components/UI/AccordionHeader"
import { ErrorStateWithRefetch } from "../../../components/UI/ErrorStateWithRefetch"
import { KonanPagination } from "../../../components/tables/KonanPagination"
import { getTheme } from "../../../hooks/UseTheme"
import { KonanAPI } from "../../../services/KonanAPI"
import { KonanTimeHelper } from "../../../utils/genericHelpers"
import { ScriptEditorProps } from "../Interfaces"

import styles from "../Scripts.module.scss"

/**
 * Script editor responsible for showing/editing editor and logs from running tests
 *
 * @param {boolean} isLoading
 * @param {ScriptGroupRetrieve} script
 * @param {boolean} isReadMode
 * @param {FormikProps} formik
 * @param {string[]} consoleLogs
 * @param {boolean} isFetchingFileError
 * @param {string[]} logs
 * @returns {React.ReactElement}
 */
export function CustomScriptEditor(props: Readonly<ScriptEditorProps>): React.ReactElement {
  const {
    isLoading,
    script,
    isReadMode,
    formik,
    consoleLogs,
    isFetchingFileError,
    logs,
    isRefetchingFile,
    isConsoleExpanded,
    isLogsExpanded,
    setIsConsoleExpanded,
    setIsLogsExpanded,
  } = props

  const theme = getTheme()

  const CURRENT_PY_VERSION = "3.10"

  const isTestScriptMutationLoading = useIsMutating({ mutationKey: ["Test-script-mutation", script?.uuid] })

  return (
    <Grid container item xs={12} flexDirection={"column"}>
      <Grid item>
        {isLoading ? (
          <Grid
            container
            item
            xs={12}
            minHeight={"538px"}
            alignItems={"center"}
            justifyContent={"center"}
            style={{ background: "black", borderRadius: "8px 8px 0px 0px" }}
          >
            <CircularProgress style={{ color: theme.palette.blue.text[2] }} size={36} />
          </Grid>
        ) : (
          <Fragment>
            <Grid style={{ background: "black" }}>
              <AccordionHeader
                view="code"
                shouldShowActionButton
                actionButtonOnClick={() => {
                  if (formik.isValid) {
                    formik.setFieldValue("isTestSubmission", true)
                  }
                  formik.submitForm()
                }}
                /* TODO: periodically revisit current PY version used in scripts w/DevOps team */
                headerTitle={`Code - (Python) v${script?.language_version ?? CURRENT_PY_VERSION}`}
                isActionButtonDisabled={
                  (formik.values.isTestSubmission && formik.isValid) || !!isTestScriptMutationLoading
                }
              />

              {(isLoading || isRefetchingFile) && (
                <Grid container item xs={12} className={styles.scriptDialogLoader}>
                  <CircularProgress style={{ color: "rgb(25 118 210)" }} size={36} />
                </Grid>
              )}

              <Grid
                style={{
                  opacity: 1,
                  transition: "opacity 0.5s ease-in",
                }}
              >
                {isFetchingFileError && !isRefetchingFile ? (
                  <Grid container item height={400}>
                    <ErrorStateWithRefetch
                      onRefetch={() =>
                        queryClient.fetchQuery(["getFileContent", script?.uuid, script?.active_version.version])
                      }
                      isFetching={isLoading}
                      name="script"
                    />
                  </Grid>
                ) : (
                  !isLoading &&
                  !isRefetchingFile && (
                    <MonacoTextEditor
                      key={formik.values.id}
                      value={formik.values.snippet}
                      height="400px"
                      language="python"
                      onChange={(text) => formik.setFieldValue("snippet", text)}
                      enableCopy
                      renderLineHighLight="none"
                      wordWrap="on"
                      width="100%"
                      loading={<CircularProgress style={{ color: "rgb(25 118 210)" }} size={36} />}
                      readOnly={isReadMode}
                    />
                  )
                )}
              </Grid>
            </Grid>

            <Grid item>
              <AccordionHeader
                headerTitle="Console"
                isAccordionExpanding={isConsoleExpanded}
                actionButtonOnClick={() => {
                  setIsConsoleExpanded()
                }}
              />

              {isConsoleExpanded && (
                <KonanLogViewer
                  enableSearch={false}
                  customBorderRadius="0px"
                  data={consoleLogs}
                  isLoading={!!isTestScriptMutationLoading}
                  minHeight="150px"
                />
              )}
            </Grid>

            <Grid item>
              <AccordionHeader
                headerTitle="Logs"
                isAccordionExpanding={isLogsExpanded}
                actionButtonOnClick={() => {
                  setIsLogsExpanded()
                }}
              />

              {isLogsExpanded && (
                <KonanLogViewer
                  customBorderRadius="0px 0px 4px 4px"
                  data={logs?.length > 0 ? logs : []}
                  isLoading={!!isTestScriptMutationLoading}
                  minHeight="150px"
                  scriptNodeView
                />
              )}
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Grid>
  )
}

/**
 * Historical logs dialog responsible for showing logs of current script up to the last 7 days
 *
 * @param {string} uuid
 * @returns {React.ReactElement}
 */
export function CustomScriptLogs(props: Readonly<{ uuid: string }>): React.ReactElement {
  const { uuid } = props
  const { id: projectId } = useParams<{ id: string }>()

  const konanTime = new KonanTimeHelper()

  const [startDate, setStartDate] = useState(konanTime.adjustDate(moment().subtract(6, "days"), "start"))
  const [endDate, setEndDate] = useState(konanTime.adjustDate(moment(), "end"))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isLoading, data } = useQuery<any, AxiosError>(
    ["script-logs", projectId, startDate, endDate, uuid],
    () =>
      KonanAPI.fetchScriptLogs({
        start_date: startDate?.toISOString() as string,
        end_date: endDate?.toISOString() as string,
        project_uuid: projectId as string,
        script_uuid: uuid,
      }),
    { enabled: !!startDate && !!endDate, refetchInterval: 3000 },
  )

  return (
    <Grid item>
      <AccordionHeader headerTitle="Logs" view={"code"} />

      <KonanLogViewer
        customBorderRadius="0px 0px 4px 4px"
        data={data?.logs ?? []}
        isLoading={isLoading}
        minHeight="421px"
        scriptNodeView
        enableDatePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        isOutSideRange={konanTime.lastWeekOnly}
      />
    </Grid>
  )
}

const MemoizedCustomPagination = React.memo(KonanPagination)

const TestFeatureItem = (props: {
  idx: number
  handleRemove: () => void
  handleInputChange: (e: InputChangeEvent<Element>) => void
  feature: { feat: string; value: string }
  enableDeletion: boolean
}): ReactElement => {
  const { idx, handleRemove, handleInputChange, feature, enableDeletion } = props

  return (
    <Grid container item xs={12} style={{ borderBottom: "1px solid var(--grayscale-border)" }}>
      <Grid item xs={6} className={styles.testFeature}>
        <InputText
          id={`testFeatures[${idx}].feat`}
          value={feature.feat}
          handleChange={(e) => handleInputChange(e)}
          placeholder={"Feature"}
          hideDescription
          fullWidth
        />
      </Grid>

      <Grid item xs={6} className={styles.testValue}>
        <InputText
          id={`testFeatures[${idx}].value`}
          value={feature.value}
          handleChange={(e) => handleInputChange(e)}
          placeholder={"Value"}
          hideDescription
          fullWidth
        />

        <Button disabled={!enableDeletion} onClick={async () => handleRemove()}>
          <DeleteOutlineIcon
            fontSize="medium"
            style={{ fill: enableDeletion ? "var(--gray-background-1)" : "var(--grayscale-border)" }}
          />
        </Button>
      </Grid>
    </Grid>
  )
}

/**
 * Table where you can add/remove/edit test features used by script while testing
 *
 * @param {FormikProps} formik
 * @returns {React.ReactElement}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomScriptTestFeatures(props: Readonly<{ formik: FormikProps<any> }>): React.ReactElement {
  const { formik } = props

  const [page, setPage] = useState<number>(0)

  // go back one page automatically if the last page has no more items to show
  useEffect(() => {
    formik.values.testFeatures.length <= page * 8 && setPage(page - 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.testFeatures.length])

  return (
    <Grid container p={3} py={2}>
      {/* Table Header */}
      <Grid container item xs={12} px={1} pb={1} style={{ borderBottom: "1px solid var(--grayscale-border)" }}>
        <Grid item xs={6}>
          <Typography variant={"a"}>Feature</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"a"}>Value</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} px={1} py={0.5} style={{ borderBottom: "1px solid var(--grayscale-border)" }}>
        <Button
          size="large"
          variant="ghost"
          onClick={() =>
            formik.setFieldValue("testFeatures", [
              { feat: "", value: "", uuid: uuidv4() },
              ...formik.values.testFeatures,
            ])
          }
          className={styles.MuiButton}
        >
          + Add Test Feature
        </Button>
      </Grid>

      <Grid item xs={12} minHeight={"382px"}>
        {formik.values.testFeatures
          .slice(page * 8, page * 8 + 8)
          ?.map((item: { feat: string; value: string; uuid: string }, index: number) => {
            return (
              <TestFeatureItem
                key={item.uuid}
                idx={page * 8 + index}
                handleRemove={() => {
                  formik.setFieldValue("testFeatures", [
                    ...formik.values.testFeatures.filter(
                      (testFeature: { feat: string; value: string; uuid: string }) => testFeature.uuid !== item.uuid,
                    ),
                  ])
                }}
                enableDeletion={formik.values.testFeatures.length > 1}
                handleInputChange={(e) => formik.handleChange(e)}
                feature={item}
              />
            )
          })}
      </Grid>

      <Grid item xs={12}>
        <MemoizedCustomPagination
          stripped={true}
          count={formik.values.testFeatures.length ?? 0}
          page={page}
          onPageChange={(index: number) => {
            setPage(index)
          }}
          rowsPerPage={8}
          hideRowCount
        />
      </Grid>
    </Grid>
  )
}
