import React, { useContext, useEffect, useMemo, useState } from "react"

import InfiniteScroll from "react-infinite-scroller"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import * as Yup from "yup"
import { Box, CircularProgress } from "@mui/material"
import Grid from "@mui/material/Grid"
import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"

import {
  KonanEmptyState,
  KonanEmptyStateFilter,
  KonanEmptyStateSearch,
} from "../../../../../../components/KonanEmptyState"
import { KonanSubHeader } from "../../../../../../components/KonanSubHeader"
import { TrainingDataUploadDialog } from "../../../../../../components/TrainingDataUploadDialog"
import { BaseSimpleDialog } from "../../../../../../components/dialogs/BaseSimpleDialog"
import { KonanAPI } from "../../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../../store/CurrentProjectAndModelContext"
import { RootState } from "../../../../../../store/ReduxStore"
import {
  CreateAutoMlRequest,
  DeleteModelRequest,
  TrainModelUsingPrebuiltModelRequest,
  baseErrorType,
} from "../../../../../../types/custom/projects"
import { ExcludedFeatureRequest } from "../../../../../../types/generated/api/ExcludedFeatureRequest"
import { FeatureRequest } from "../../../../../../types/generated/api/FeatureRequest"
import { Model } from "../../../../../../types/generated/api/Model"
import { isPermitted } from "../../../../../../utils/PermissionsHelpers"
import { Auth } from "../../../../../../utils/auth"
import { hasLiveModel, mapResponseTypesToTabValues } from "../../../../../../utils/deploymentDetailsHelpers"
import {
  filterListByState,
  orderModelsByStatus,
  searchListByName,
  sortList,
} from "../../../../../../utils/searchSortFilterHelpers"
import { KonanModelCard } from "./components/KonanModelCard"
import { ModelLoadingComponent } from "./components/ModelLoadingComponent"
import { NewModelDialog } from "./components/NewModelDialog"

import styles from "./Models.module.scss"

type ParamsType = {
  id: string
}

// Automl form validation
const automlFormValidation = Yup.object({
  name: Yup.string().required("Required"),
  target_column: Yup.string().required("Required"),
})

/**
 * Model overview screen component
 * @return {React.ReactElement}
 */
export function Models(): React.ReactElement {
  const { id: projectId } = useParams<ParamsType>()
  const permissions = Auth.getPermissions()

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)

  // SSF states
  const [searchValue, setSearchValue] = useState<string>("")
  const [sortValue, setSortValue] = useState<string>("Status")
  const [filterValue, setFilterValue] = useState<string[]>([])

  // open dialog states
  const [openNewModelDialog, setOpenNewModelDialog] = React.useState<boolean>(false)
  const [openModelDeletionDialog, setOpenModelDeletionDialog] = useState<boolean>(false)

  const [modelToDelete, setModelToDelete] = useState<{ name: string; id: string; isLive: boolean }>({
    name: "",
    id: "",
    isLive: false,
  })

  const [openTrainingDataDialog, setOpenTrainingDataDialog] = useState<boolean>(false)

  const [trainingDataUUID, setTrainingDataUUID] = useState<string>("")

  const [autoMlData, setAutoMlData] = useState<{
    mapping: Array<FeatureRequest>
    excludedColumns: Array<ExcludedFeatureRequest>
  }>({
    mapping: [],
    excludedColumns: [],
  })

  const [currentPage, setCurrentPage] = useState<number>(1)
  const pageSize = 6

  const trainModelUsingPrebuiltModel = useMutation<
    AxiosResponse,
    AxiosError<baseErrorType>,
    TrainModelUsingPrebuiltModelRequest
  >(KonanAPI.trainModelUsingPrebuiltModel)

  const {
    mutateAsync: trainModelUsingAutoMlModel,
    reset,
    data: trainingModelAutomlData,
    isError: isAutoMlError,
    error: automlCreationErrors,
    isSuccess: isAutomlSuccess,
  } = useMutation<AxiosResponse, AxiosError<baseErrorType>, CreateAutoMlRequest>(KonanAPI.trainModelUsingAutoMlModel)

  const deleteModelMutation = useMutation<AxiosResponse, AxiosError, DeleteModelRequest>(KonanAPI.deleteModel)

  const { isLoading: isModelsLoading, data: models } = useQuery<AxiosResponse<Array<Model>>, AxiosError<baseErrorType>>(
    ["overviewModels", projectId],
    () => KonanAPI.fetchModels(projectId as string),
  )

  // Automl form control
  const automlFormik = useFormik({
    initialValues: {
      name: "",
      target_column: "",
    },
    validationSchema: automlFormValidation,
    onSubmit: async () => {
      try {
        setIsBtnLoading(true)

        await trainModelUsingAutoMlModel({
          projectUUID: currentProject?.uuid as string,
          name: automlFormik.values.name !== "" ? automlFormik.values.name : "",
          training_data: trainingDataUUID !== "" && trainingDataUUID !== "sample" ? trainingDataUUID : "",
          target_column: automlFormik.values.target_column,
          excluded_columns: autoMlData.excludedColumns,
          mapping: autoMlData.mapping,
        })
        setIsBtnLoading(false)
      } catch (error) {
        setIsBtnLoading(false)
        console.error(error)
      }
    },
  })

  //function that returns adjusted models based on search, sort, and filter values
  const adjustedModels = useMemo((): Model[] => {
    let adjustedModels = models?.data ? orderModelsByStatus(models?.data as Model[], "asc") : []
    if (searchValue && searchValue.length > 0) {
      adjustedModels = adjustedModels ? searchListByName(adjustedModels, searchValue) : []
      setCurrentPage(1)
    }

    if (sortValue && sortValue.length > 0) {
      adjustedModels = adjustedModels ? sortList(adjustedModels, sortValue, "models") : []
      setCurrentPage(1)
    }

    if (filterValue && filterValue.length > 0) {
      adjustedModels = adjustedModels
        ? filterListByState(
            adjustedModels,
            filterValue?.includes("idle")
              ? filterValue.map((item) => (item === "idle" ? "disabled" : item))
              : filterValue,
            "models",
          )
        : []
      setCurrentPage(1)
    }

    return adjustedModels
  }, [models, searchValue, sortValue, filterValue])

  const canSwitchToChallenger = useMemo(() => {
    return hasLiveModel(adjustedModels)
  }, [adjustedModels])

  const handleModelDeletionClicked = (name: string, id: string, isLive: boolean): void => {
    setModelToDelete({ name, id, isLive })
    setOpenModelDeletionDialog(true)
  }

  const loadMoreModels = (): void => {
    setCurrentPage(currentPage + 1)
  }

  async function deleteModel(): Promise<void> {
    try {
      await deleteModelMutation.mutateAsync({
        model_uuid: modelToDelete?.id,
      })

      NotificationUtils.toast("Model successfully deleted.", { snackBarVariant: "positive" })

      // Invalidate react-query queries
      queryClient.invalidateQueries("models")
      queryClient.invalidateQueries(["model", modelToDelete.id])

      queryClient.invalidateQueries(["overviewModels", projectId])
    } catch (e) {
      NotificationUtils.toast("Model deletion failed.", { snackBarVariant: "negative" })
    }

    setOpenModelDeletionDialog(false)
    //  To prevent flickering of alerts when closing the dialog

    setTimeout(() => deleteModelMutation.reset(), 300)
  }

  // handle what happens after clicking on train btn in CS mode
  const handleTrainClick = async (type: "auto-ml" | "pre-built"): Promise<void> => {
    if (type === "pre-built") {
      await trainModelUsingPrebuiltModel.mutateAsync({
        projectUUID: currentProject?.uuid,
        modelName: automlFormik.values.name !== "" ? automlFormik.values.name : undefined,
        trainingDataUUID: trainingDataUUID !== "" && trainingDataUUID !== "sample" ? trainingDataUUID : undefined,
      })
    } else if (type === "auto-ml") {
      automlFormik.submitForm()
    }
  }

  // Navigates to training jobs page on successful cs training
  useEffect(() => {
    if (
      (trainModelUsingPrebuiltModel.isSuccess && !trainModelUsingPrebuiltModel.isError) ||
      (isAutomlSuccess && !isAutoMlError)
    ) {
      setTimeout(() => {
        navigate({
          pathname: `/projects/${projectId}/model-training`,
          search: `tab=${mapResponseTypesToTabValues(
            trainModelUsingPrebuiltModel?.data?.data?.status ?? trainingModelAutomlData?.data?.status,
          )}`,
        })
      }, 500)
    } else if (trainModelUsingPrebuiltModel.isError) {
      NotificationUtils.toast("Training Failed", { snackBarVariant: "negative" })
    }
  }, [
    navigate,
    trainModelUsingPrebuiltModel,
    projectId,
    isAutomlSuccess,
    isAutoMlError,
    trainingModelAutomlData?.data?.status,
  ])

  //reset CPU, RAM and Data Drift states whenever the user deletes a model
  useEffect(() => {
    queryClient.invalidateQueries("apiRequests")
    queryClient.invalidateQueries("averageResponseTime")
    queryClient.invalidateQueries("overviewDriftJobs")
    queryClient.invalidateQueries("cpuAndRamUsage")
  }, [models, queryClient])

  return (
    <React.Fragment>
      {openNewModelDialog && (
        <NewModelDialog
          open={openNewModelDialog}
          onClose={() => setOpenNewModelDialog(false)}
          projectId={projectId as string}
          models={models?.data}
        />
      )}
      {/* Project deletion dialog */}
      {openModelDeletionDialog && (
        <BaseSimpleDialog
          open={openModelDeletionDialog}
          name={modelToDelete.name}
          mode={"model-deletion"}
          onAccept={deleteModel}
          isLoading={deleteModelMutation.isLoading}
          onClose={() => {
            setOpenModelDeletionDialog(false)
            //  To prevent flickering of alerts when closing the dialog

            setTimeout(() => deleteModelMutation.reset(), 300)
          }}
        />
      )}

      {openTrainingDataDialog && (
        <TrainingDataUploadDialog
          isBtnLoading={isBtnLoading}
          open={openTrainingDataDialog}
          resetErrors={reset}
          projectId={projectId as string}
          trainingDataUUID={trainingDataUUID}
          handleTrainClick={(e: "auto-ml" | "pre-built") => handleTrainClick(e)}
          formik={automlFormik}
          setAutoMlData={setAutoMlData}
          automlErrors={automlCreationErrors}
          setDialog={setOpenTrainingDataDialog}
          setTrainingDataUUID={setTrainingDataUUID}
          errorMessage={
            trainModelUsingPrebuiltModel.error
              ? trainModelUsingPrebuiltModel.error?.response?.data.detail ??
                trainModelUsingPrebuiltModel.error?.response?.data.name
              : automlCreationErrors
                ? automlCreationErrors.response?.data.detail ?? automlCreationErrors.response?.data.name
                : ""
          }
        />
      )}

      <Grid item xs={12} pt={2}>
        <Grid container spacing={1}>
          <Grid container item className={styles.overviewHeader}>
            <KonanSubHeader
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              sortValue={sortValue}
              setSortValue={setSortValue}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              filterOptions={["live", "challenger", "idle"]}
              sortOptions={["Status", "Most Recent", "Name"]}
              containerType="Model"
              setAction={currentProject?.type === "credit_scoring" ? setOpenTrainingDataDialog : setOpenNewModelDialog}
              title={currentProject?.type === "credit_scoring" ? "Train New Model" : "Deploy New Model"}
              showButton={isPermitted("Add model", permissions.konan, flattenedKonanPermissions)}
            />
          </Grid>

          {isModelsLoading ? (
            <Grid container spacing={2} paddingLeft={1}>
              {[1, 2].map((item: number) => (
                <Grid item xs={12} md={6} lg={4} key={item}>
                  <ModelLoadingComponent />
                </Grid>
              ))}
            </Grid>
          ) : (
            <InfiniteScroll
              pageStart={currentPage}
              loadMore={loadMoreModels}
              hasMore={adjustedModels?.length >= currentPage * pageSize}
              className={styles.infiniteScroller}
            >
              <Grid container spacing={2}>
                {adjustedModels?.slice(0, currentPage * pageSize).map((item: Model) => (
                  <Grid item xs={12} md={6} lg={4} key={item?.uuid} className={styles.modelCardContainer}>
                    <KonanModelCard
                      modelState={item?.state}
                      modelName={item?.name}
                      imageURL={item?.image_url}
                      createdAt={item?.created_at}
                      createdBy={item?.created_by}
                      port={item?.exposed_port}
                      uuid={item?.uuid}
                      onModelDeletionClicked={handleModelDeletionClicked}
                      trainingDataUUID={item?.training_data}
                      canSwitchToChallenger={canSwitchToChallenger}
                    />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          )}

          {adjustedModels && adjustedModels.length >= currentPage * pageSize && (
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
              <Box mt={3} />
              <CircularProgress size={36} />
              <Box mt={1} />
            </Grid>
          )}

          {adjustedModels?.length === 0 && (
            <Grid container xs={12} className={"empty-container"}>
              {!isModelsLoading && models?.data.length === 0 ? (
                <KonanEmptyState
                  setAction={
                    currentProject?.type === "credit_scoring" ? setOpenTrainingDataDialog : setOpenNewModelDialog
                  }
                  title="No models, yet."
                  buttonText={
                    isPermitted("Add model", permissions.konan, flattenedKonanPermissions)
                      ? currentProject?.type === "credit_scoring"
                        ? "Train New Model"
                        : "Deploy New model"
                      : undefined
                  }
                  subTitle={
                    currentProject?.type === "credit_scoring" ? "Train your first model" : "Deploy your first model"
                  }
                />
              ) : !isModelsLoading && (searchValue || (searchValue && filterValue)) && adjustedModels?.length === 0 ? (
                <KonanEmptyStateSearch title={searchValue} />
              ) : (
                filterValue &&
                !searchValue &&
                adjustedModels?.length === 0 &&
                !isModelsLoading && <KonanEmptyStateFilter title={filterValue} />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
