import { Dispatch, SetStateAction } from "react"

import * as Yup from "yup"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined"
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined"
import ScheduleIcon from "@mui/icons-material/Schedule"
import SubjectIcon from "@mui/icons-material/Subject"
import TagOutlinedIcon from "@mui/icons-material/TagOutlined"
import { FormikErrors, FormikProps, FormikTouched } from "formik"

import { ScorecardFormikValues } from "../screens/ProjectDetails/components/DecisonEngines/components/Scorecardset/components/Scorecard"
import { Operators } from "../types/custom/projects"
import {
  BaseCondition,
  ConditionFeatureChange,
  FilterCondition,
  MapValueTypeToFeatureType,
  Rule,
  ScorecardRule,
  getNewRuleInfoResult,
} from "../types/custom/rules"
import { FeatureData, SchemaFeature } from "../types/custom/workflows"
import { WorkflowSchemaFeature } from "../types/generated/api/WorkflowSchemaFeature"
import { WorkflowSchemaFeatureRequest } from "../types/generated/api/WorkflowSchemaFeatureRequest"
import { detectValueType, extractFeatureOperatorValue } from "./deploymentDetailsHelpers"
import { convertComplexConditionsToNestedForm, isTypeIncluded } from "./rulesetHelpers"

export const handleSelectFeature = (
  currentType: string,
  id: string,
  setOperators: Dispatch<SetStateAction<Array<string>>>,
  setTypes: Dispatch<SetStateAction<Array<string>>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>,
): void => {
  switch (currentType) {
    case WorkflowSchemaFeatureRequest.type.NUMBER:
      formik.setFieldValue(id + ".type", "number")

      setOperators(Object.values(Operators).filter((v) => isNaN(Number(v))))
      break
    case WorkflowSchemaFeatureRequest.type.TEXT:
    case WorkflowSchemaFeatureRequest.type.UNDEFINED:
      formik.setFieldValue(id + ".type", "string")

      setOperators(
        Object.values(Operators).filter((v) => [Operators["!="], Operators["="], ...multiValuesOperators].includes(v)),
      )
      break
    case WorkflowSchemaFeatureRequest.type.BOOLEAN:
      setTypes(["true", "false"])
      formik.setFieldValue(id + ".type", "true")

      setOperators(
        Object.values(Operators).filter((v) => [Operators["!="], Operators["="], ...multiValuesOperators].includes(v)),
      )
      break
    default:
      break
  }
}

export const multiValuesOperators: Array<Operators | string> = [
  Operators["in"],
  Operators["not in"],
  Operators["contains"],
  Operators["not contains"],
]

export const schemaFeatureTypes = [
  WorkflowSchemaFeature.type.TEXT,
  WorkflowSchemaFeature.type.DATE,
  WorkflowSchemaFeature.type.NUMBER,
  WorkflowSchemaFeature.type.BOOLEAN,
  WorkflowSchemaFeature.type.UNDEFINED,
  WorkflowSchemaFeature.type.LIST,
]

export const containsAndNotContains = [Operators["contains"], Operators["not contains"]]

export const isMultiValuesOperatorsIncluded = (item: string): boolean => {
  return multiValuesOperators.includes(Operators[item as keyof typeof Operators])
}

/** extract operator key by passing its value */
export function getOpeartorKeyByValue(value: string): keyof typeof Operators | undefined {
  for (const key in Operators) {
    if (Operators[key as keyof typeof Operators] === value) {
      return key as keyof typeof Operators
    }
  }
}

/** get the correct value parsed syntax based on the condition strurcture (operator, type, etc..) */
export const getCorrectValueSyntax = (condition: BaseCondition): string => {
  const conditionType = condition?.type
  // check if value is string, trim it.
  const trimmedValue = detectValueType(condition?.value) === "string" ? condition?.value?.trim() : condition?.value
  if (condition?.valueOrFeature === "Feature") {
    return `"${condition?.secondFeature?.trim()}"`
  } else if (multiValuesOperators?.includes(Operators[condition?.operator as keyof typeof Operators])) {
    if (trimmedValue === "$file") {
      return "$file"
    } else {
      return `[${trimmedValue}]`
    }
  } else if (condition?.operator === "between" && condition?.secondValue) {
    return `[${trimmedValue}, ${condition?.secondValue}]`
  } else if (conditionType === "string") {
    return `'${trimmedValue}'`
  } else if (conditionType === "number") {
    return trimmedValue
  } else {
    return conditionType as string
  }
}

/** ------- PARSING CONDITION (SEND + RETRIEVE) --------
Sending condition - parse form condition to string before sending to backend **/
export const parseConditionIntoString = (condition: BaseCondition): string => {
  //trim feature
  const trimmedFeature = condition?.feature?.trim()

  // read the correct mapped operator name from Opeartors enum
  const mappedOperator = getOpeartorKeyByValue(condition?.operator?.toLowerCase())

  // get the correct parsed value syntax
  const parsedValue = getCorrectValueSyntax(condition)

  return `(${trimmedFeature} ${mappedOperator} ${parsedValue})`
}

/** parsing retrieved condition to its form (formik) format */
export class ConditionParser {
  private condition: string
  private parsedCondition: Array<string> | string
  private type: string
  private feature: string
  private operator: string
  private value: string
  private secondValue: string
  /**Group common steps to parse any given condition */
  constructor(condition: string) {
    this.condition = condition
    this.parsedCondition = extractFeatureOperatorValue(this?.condition)
    this.type = detectValueType(this?.parsedCondition[2])
    this.feature = this?.parsedCondition[0]
    this.operator = Operators[this?.parsedCondition[1] as keyof typeof Operators]
    this.value = this?.parsedCondition[2]
    this.secondValue = this?.parsedCondition[3]
  }

  /** LHS feature */
  getFeature(): string {
    return this.feature
  }

  /** Condition operator */
  getOpeartor(): string {
    return this.operator
  }

  /** Condition type */
  getType(): string {
    return this?.type
  }

  /** RHS Indicator (Value - Feature) */
  getRHSIndicator(): "Feature" | "Value" {
    return String(this.value)?.startsWith('"') && String(this.value)?.endsWith('"') ? "Feature" : "Value"
  }

  /** Condition RHS Value */
  getValue(): string {
    return this?.type === "number" || this?.value === "$file" || isTypeIncluded(this.type)
      ? this?.value
      : this?.value?.slice(1, -1)
  }

  /** second value, needed when condition operator is 'between' */
  getSecondValue(): string {
    return this.operator === "between" ? this.secondValue : ""
  }

  /** second feature, needed when comparing between 2 Features */
  getSecondFeature(): string {
    return String(this.value)?.startsWith('"') && String(this.value)?.endsWith('"') ? this.value?.slice(1, -1) : ""
  }
}

type Scorecard = {
  name: string
  weight: number | undefined
  ruleCards: ScorecardRule[]
  uuid: string
}
/**
 * when adding new feature to a scorecard, we check on exisiting non-empty features,
 * if they are all equal we assign the newly condition feature same value, else we add empty feature
 */
export const getNewRuleInfo = (
  scorecards: Scorecard[],
  checkLevel: "scorecard" | "global",
  specificScorecardIndex?: number,
): getNewRuleInfoResult => {
  const nonEmptyFeatures: Rule[] = []
  const featureMap: { [key: string]: Rule } = {}

  const addFeatures = (ruleCards: ScorecardRule[]): void => {
    ruleCards.forEach((rule) => {
      rule.levelOneConditions.concat(rule.levelTwoConditions).forEach((condition) => {
        if (condition.feature && nonEmptyFeatures) {
          featureMap[condition.feature] = condition
        }
      })
    })
  }

  if (checkLevel === "global") {
    scorecards.forEach((scorecard) => addFeatures(scorecard.ruleCards))
  } else if (checkLevel === "scorecard" && specificScorecardIndex !== undefined) {
    addFeatures(scorecards[specificScorecardIndex]?.ruleCards)
  }

  Object.values(featureMap).forEach((condition) => {
    nonEmptyFeatures.push(condition)
  })

  const uniqueFeatures = Array.from(new Set(nonEmptyFeatures.map((object) => JSON.stringify(object)))).map((object) =>
    JSON.parse(object),
  )

  const commonFeature =
    uniqueFeatures.length === 1
      ? { name: uniqueFeatures[0]?.feature, type: uniqueFeatures[0]?.type }
      : { name: "", type: "string" }

  return { commonFeature, nonEmptyFeatures }
}

/**
 * object to register each feature type to its suitable operators
 *
 * TEXT -> all operators accepted except numrical ones
 *
 * NUMBER -> all operators accepted except (contains, not contains)
 *
 * UNDEFINED -> all operators accepted
 *
 * BOOLEAN -> only equal and not equal operators accepted
 *
 * DATE -> all operators accepted except (contains, not contains)
 *
 * LIST -> all operators accepted except numrical ones
 */
export const operatorsBasedOnFeatureType: Record<WorkflowSchemaFeature.type, Operators[] | string[]> = {
  TEXT: [Operators["="], Operators["!="], ...multiValuesOperators],

  NUMBER: [...Object.values(Operators).filter((v) => ![Operators.contains, Operators["not contains"]].includes(v))],

  UNDEFINED: [...Object.values(Operators)],

  BOOLEAN: [...Object.values(Operators).filter((v) => [Operators["="], Operators["!="]].includes(v))],

  DATE: [...Object.values(Operators).filter((v) => ![Operators.contains, Operators["not contains"]].includes(v))],

  LIST: [Operators["="], Operators["!="], ...multiValuesOperators],
}

/**
 * this helper function handles the changes in all the other condition parts (type, operator, etc..)
 * based on the changed input feature and its registered type, and returns all the condition parts with the updated values
 */
export const handleFeatureChange = (
  featuresList: Array<FeatureData | SchemaFeature>,
  feature: string,
  condition: BaseCondition,
): ConditionFeatureChange => {
  let filteredFeatures: Array<FeatureData | SchemaFeature> = []
  let otherFilteredFeatures: Array<FeatureData | SchemaFeature> = []
  let operators: Array<Operators | string> = []
  let types: Array<string> = ["string", "number", "true", "false", "null"]
  let value = condition?.value
  let type = condition?.type as string

  if (featuresList.length > 0) {
    filteredFeatures = featuresList.filter((item: FeatureData | SchemaFeature) =>
      item?.name?.toLowerCase()?.includes(feature?.toLowerCase()),
    )
    const featureInSchema = featuresList.find((item) => item?.name?.toLowerCase() === feature?.toLowerCase())

    if (featureInSchema && featureInSchema?.type) {
      if (featureInSchema.type === WorkflowSchemaFeature.type.UNDEFINED) {
        operators =
          operatorsBasedOnFeatureType[
            MapValueTypeToFeatureType[condition.type as keyof typeof MapValueTypeToFeatureType]
          ]
      } else {
        operators = operatorsBasedOnFeatureType[featureInSchema?.type as keyof typeof operatorsBasedOnFeatureType]
      }

      if (featureInSchema.type === WorkflowSchemaFeature.type.BOOLEAN) {
        types = ["true", "false"]
        type = "true"
        value = "true"
      }

      if (featureInSchema.type === WorkflowSchemaFeature.type.TEXT) {
        type = "string"
      }

      if (featureInSchema.type === WorkflowSchemaFeature.type.NUMBER) {
        type = "number"
      }

      otherFilteredFeatures =
        featureInSchema.type === WorkflowSchemaFeature.type.UNDEFINED
          ? [...featuresList]
          : featuresList.filter((item) => item?.type === featureInSchema.type)
    } else {
      otherFilteredFeatures = [...featuresList]
      operators =
        operatorsBasedOnFeatureType[MapValueTypeToFeatureType[condition.type as keyof typeof MapValueTypeToFeatureType]]
    }
  } else {
    operators =
      operatorsBasedOnFeatureType[MapValueTypeToFeatureType[condition.type as keyof typeof MapValueTypeToFeatureType]]
  }

  return {
    operators,
    operator: !operators.includes(condition?.operator) ? operators[0] : condition?.operator,
    filteredFeatures,
    otherFilteredFeatures,
    types,
    type,
    value,
  }
}

export const getIconForFeatureType = (featureType: WorkflowSchemaFeature.type): React.ReactElement => {
  switch (featureType) {
    case WorkflowSchemaFeature.type.DATE:
      return <ScheduleIcon sx={{ color: "var(--slate-11)" }} fontSize="small" />

    case WorkflowSchemaFeature.type.BOOLEAN:
      return <RuleOutlinedIcon sx={{ color: "var(--slate-11)" }} fontSize="small" />

    case WorkflowSchemaFeature.type.LIST:
      return <FormatListNumberedIcon sx={{ color: "var(--slate-11)" }} fontSize="small" />

    case WorkflowSchemaFeature.type.NUMBER:
      return <TagOutlinedIcon sx={{ color: "var(--slate-11)" }} fontSize="small" />

    case WorkflowSchemaFeature.type.UNDEFINED:
      return <QuestionMarkOutlinedIcon sx={{ color: "var(--slate-11)" }} fontSize="small" />

    case WorkflowSchemaFeature.type.TEXT:
      return <SubjectIcon sx={{ color: "var(--slate-11)" }} fontSize="small" />
  }
}

// Yup schema for condition Level structure
export const levelSchema = Yup.object().shape({
  feature: Yup.string()
    .required("Feature is required")
    .matches(/^[a-zA-Z0-9._-]+$/, {
      message: "This field cannot contain white spaces or special character except: (.-_)",
    }),
  andOr: Yup.string(),
  operator: Yup.string().required("Operator is required"),
  type: Yup.string().required("Type is required"),
  value: Yup.mixed().when(["type", "operator"], {
    is: (type: string, operator: string) => {
      return (
        ["true", "false", "null"].includes(type) &&
        ["greater than", "greater than/equal", "lower than", "lower than/equal", "between"].includes(operator)
      )
    },
    then: Yup.number().typeError("Value must be a number").required("Value is required"),
    otherwise: Yup.mixed().when("valueOrFeature", {
      is: (valueOrFeature: string) => valueOrFeature === "Feature",
      then: Yup.mixed().notRequired(),
      otherwise: Yup.mixed().required("Value is required"),
    }),
  }),
  secondFeature: Yup.string().when("valueOrFeature", {
    is: (valueOrFeature: string) => valueOrFeature === "Feature",
    then: Yup.string().required("Second feature is required"),
    otherwise: Yup.string().notRequired(),
  }),
  secondValue: Yup.mixed().when("operator", {
    is: (operator: string) => operator === "between",
    then: Yup.number().typeError("Second value must be a number").required("Second value is required"),
    otherwise: Yup.string().notRequired(),
  }),
})

export const getRuleWeightValue = (ruleWeight: string): number | undefined => {
  return ruleWeight ? (`${ruleWeight}`?.endsWith(".00") ? parseInt(ruleWeight) : parseFloat(ruleWeight)) : undefined
}

export const detectRuleType = (condition: string): "complex" | "simple" => {
  const convertedRule = convertComplexConditionsToNestedForm(condition)
  return (convertedRule?.length === 1 && convertedRule[0]?.length === 1) || convertedRule?.length === 0
    ? "simple"
    : "complex"
}

/**
 * Checks if a field is touched and has an error.
 * @param errors - The Formik errors object.
 * @param touched - The Formik touched object.
 * @param key - The key path to the field, separated by dots (e.g., "scorecards.0.ruleCards.0.return_value").
 * @returns The error string if the field is touched and has an error, otherwise false.
 */
export function getFieldErrorIfTouched(
  errors: FormikErrors<ScorecardFormikValues>,
  touched: FormikTouched<ScorecardFormikValues>,
  key: string,
): string | false {
  /**
   * Splits the key path into parts.
   * @param key - The key path to split.
   * @returns An array of key parts.
   */
  function getKeyParts(key: string): string[] {
    return key.split(".")
  }

  /**
   * Recursively checks nested objects.
   * @param errors - The Formik errors object.
   * @param touched - The Formik touched object.
   * @param keyParts - The array of key parts to check.
   * @returns The error string if the field is touched and has an error, otherwise false.
   */
  function checkNested(
    errors: FormikErrors<ScorecardFormikValues>,
    touched: FormikTouched<ScorecardFormikValues>,
    keyParts: string[],
  ): string | false {
    if (!keyParts.length) return false // Base case: No more key parts to check

    const currentKey = keyParts[0]
    const remainingKeys = keyParts.slice(1)

    // Check if current key exists in both errors and touched
    if (errors[currentKey as keyof typeof errors] && touched[currentKey as keyof typeof touched]) {
      // If this is the last key part, return the error string if both are truthy
      if (remainingKeys.length === 0) {
        return touched[currentKey as keyof typeof touched] && errors[currentKey as keyof typeof errors]
          ? String(errors[currentKey as keyof typeof errors])
          : false
      }

      // Continue checking nested keys
      return checkNested(
        errors[currentKey as keyof typeof errors] as FormikErrors<ScorecardFormikValues>,
        touched[currentKey as keyof typeof touched] as FormikTouched<ScorecardFormikValues>,
        remainingKeys,
      )
    }

    // If key doesn't exist in either, return false
    return false
  }

  const keyParts = getKeyParts(key)
  return checkNested(errors, touched, keyParts)
}

/**
 * Converts a rule array into a condition string.
 *
 * This function takes an array of rules and converts each rule into a string
 * representation using the `parseConditionIntoString` function. It combines
 * these string representations with logical operators (e.g., AND, OR) and
 * returns the final condition string enclosed in parentheses.
 *
 * @template T - The type of elements in the rule array, which must extend `BaseCondition`.
 * @param {Array<T>} rule - The array of rules to convert into a condition string.
 * @returns {string} The final condition string representing the combined rules.
 */
export function convertSingleRuleToCondition<T extends FilterCondition>(rule: Array<T>): string {
  let result = ""
  let finalResult = ""

  rule.forEach((item: T, index: number) => {
    result += parseConditionIntoString(item)
    if (rule.length - 1 > index) {
      result += " " + rule[0].andOr + " "
    }
  })

  if (result !== "") {
    finalResult = "(" + result + finalResult + ")"
  }

  return finalResult
}
