import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { FileRejection } from "react-dropzone"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams, useSearchParams } from "react-router-dom"

import CloseIcon from "@mui/icons-material/Close"
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined"
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button as MuiButton,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Button, InputText, NotificationUtils, Select, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FormikProps } from "formik"
import { MRT_ColumnDef } from "material-react-table"
import { v4 as uuidv4 } from "uuid"

import { Dropzone } from "../../features/projects/components/Dropzone"
import { getTheme } from "../../hooks/UseTheme"
import { DataBlock } from "../../screens/ProjectDetails/components/DecisonEngines/components/Ruleset/components/components/RuleCard"
import { KonanAPI } from "../../services/KonanAPI"
import { WorkflowInputSchema } from "../../types/custom/projects"
import { SchemaFeature, WorkflowSchema } from "../../types/custom/workflows"
import { DataFile } from "../../types/generated/api/DataFile"
import { Schema } from "../../types/generated/api/Schema"
import { WorkflowSchemaFeatureRequest } from "../../types/generated/api/WorkflowSchemaFeatureRequest"
import { schemaFeatureTypes } from "../../utils/conditionHelpers"
import { findDuplicateElementByProperty } from "../../utils/genericHelpers"
import { BaseTableContainer } from "../tables/BaseTableContainer"

import styles from "./WorkflowSchemaDialog.module.scss"

type SchemaDialogProps = {
  open: boolean
  onClose: () => void
  formik: FormikProps<WorkflowSchema>
  schema: Schema
  acceptedFile: File[]
  setAcceptedFile: Dispatch<SetStateAction<Array<File>>>
  activeWorkflowId: string
  workflowName: string
  isWorkflowsLoading: boolean
  isWorkflowInReadMode: boolean
  isWorkflowDeprecated: boolean
}

type ParamsType = {
  id: string
}

export function WorkflowSchemaDialog(props: SchemaDialogProps): React.ReactElement {
  const {
    open,
    onClose,
    formik,
    schema,
    acceptedFile,
    isWorkflowsLoading,
    setAcceptedFile,
    activeWorkflowId,
    workflowName,
    isWorkflowInReadMode,
    isWorkflowDeprecated,
  } = props
  const theme = useTheme()
  const themeMode = getTheme()

  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [fileUUID, setFileUUID] = useState<string>("")
  const [_, setInvalidFiles] = useState<FileRejection[]>([])
  const [formikSnapShot, setFormikSnapShot] = useState<Array<SchemaFeature>>(
    JSON.parse(JSON.stringify(formik?.values?.features)),
  )

  // using this to get the current workflow state from the URL
  const [searchParams] = useSearchParams()

  const [currentMode, setCurrentMode] = useState<"create" | "read" | "edit">(
    searchParams.get("workflowId") === "new" ? "create" : "read",
  )

  const abortControllerRef = useRef<AbortController | null>(null)

  const queryClient = useQueryClient()

  const { id: projectID } = useParams<ParamsType>()

  const {
    isLoading: uploadingCSV,
    mutateAsync: uploadCSVAsyncMutation,
    reset,
  } = useMutation<DataFile, AxiosError, File>(
    (file: File) => {
      abortControllerRef.current = new AbortController()
      return KonanAPI.uploadDataFile({
        project_uuid: projectID as string,
        file: file,
        setProgress: setUploadProgress,
        signal: abortControllerRef.current.signal,
        type: DataFile.type.PREDICTIONS,
      })
    },
    {
      mutationKey: "uploadCSV",
      onSuccess: async (response) => {
        setFileUUID(response.uuid)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (response: any) => {
        if (response?.response?.data?.details) {
          NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
            snackBarVariant: "negative",
          })
        }
      },
    },
  )

  // fetch dataFile
  const { data: dataFileColumns } = useQuery<AxiosResponse<DataFile>>(
    ["data-file", projectID, fileUUID],
    () => KonanAPI.fetchDataFile(projectID as string, fileUUID),
    {
      enabled: currentMode === "create" && !!fileUUID && !!projectID && open,
    },
  )

  // handle close dropzone
  const handleCloseAndAbort = useCallback(() => {
    abortControllerRef.current?.abort()
    reset()
    setUploadProgress(0)
    setAcceptedFile([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  // handle upload once files are accepted
  const handleUpload = async (): Promise<void> => {
    try {
      await uploadCSVAsyncMutation(acceptedFile[0])

      // Invalidate training data to trigger a refresh
      await queryClient.invalidateQueries(["data-files", projectID])
    } catch (err) {
      // reset dropzone on error
      handleCloseAndAbort()
    }
  }

  // starts uploading once the file is read
  useEffect(() => {
    if (acceptedFile && acceptedFile?.length > 0) {
      handleUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFile])

  useEffect(() => {
    if (searchParams.get("workflowId") === "new") {
      setCurrentMode("create")
    } else if (searchParams.get("workflowId") && searchParams.get("workflowId") !== "new") {
      setCurrentMode("read")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const duplicateFeature = useMemo(() => {
    if (formik.values.features?.length > 0) {
      const object = findDuplicateElementByProperty(formik.values.features, "name")
      if (object && object?.name !== "") {
        return object?.name
      }
    }
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.features, fileUUID, dataFileColumns?.data?.columns])

  useEffect(() => {
    if (dataFileColumns?.data?.columns?.length) {
      const newSchemaArr: Array<SchemaFeature> = []
      dataFileColumns?.data?.columns?.forEach((column) => {
        newSchemaArr.push({
          name: column,
          type: WorkflowSchemaFeatureRequest.type.UNDEFINED,
          is_required: true,
          file: true,
          source: "workflow",
          id: uuidv4(),
        })
      })
      formik.setFieldValue("features", [...formik.values.features.filter((feat) => feat?.name !== ""), ...newSchemaArr])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFileColumns?.data?.columns])

  const columns: MRT_ColumnDef<WorkflowInputSchema>[] = [
    {
      header: "Feature Name",
      accessorKey: "name",
      Cell: ({ row }) => {
        return (
          <Grid item xs={12}>
            {currentMode === "read" ||
            (currentMode !== "create" &&
              !formik.values.features[row.index]?.new &&
              schema?.features?.find((feat) => feat?.name === formik.values.features[row.index]?.name)?.source !==
                "workflow") ||
            (currentMode === "edit" && !formik.values.features[row.index]?.new) ? (
              <Typography variant="p">
                {formik.values.features?.length > 0 ? formik.values.features[row.index]?.name : ""}
              </Typography>
            ) : (
              <InputText
                fullWidth
                id={`features[${row.index}].name`}
                placeholder="Feature Name"
                key={formik.values.features[row.index]?.id}
                value={formik.values.features[row.index]?.name}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                hideDescription
              />
            )}
          </Grid>
        )
      },
    },
    {
      header: "Type",
      accessorKey: "type",
      Cell: ({ row }) => {
        return (
          <Grid container item xs={12} flexWrap="nowrap">
            <Grid item container>
              {currentMode === "read" ? (
                <Typography style={{ textTransform: "capitalize" }} variant="label">
                  {formik.values.features[row?.index]?.type?.toLowerCase()}
                </Typography>
              ) : (
                <Select
                  hideDescription
                  id={`features[${row?.index}].type`}
                  value={formik.values.features[row?.index]?.type}
                  handleChange={(e) => formik.setFieldValue(`features[${row?.index}].type`, e.target.value)}
                  disabled={
                    (currentMode !== "create" &&
                      !formik.values.features[row.index]?.new &&
                      schema?.features?.find((feat) => feat?.name === formik.values.features[row.index]?.name)
                        ?.source !== "workflow") ||
                    (currentMode === "edit" &&
                      !formik.values.features[row.index]?.new &&
                      schema?.features?.find((feat) => feat?.name === formik.values.features[row.index]?.name)?.type !==
                        WorkflowSchemaFeatureRequest.type.UNDEFINED)
                  }
                  fullWidth
                  type="text"
                  options={schemaFeatureTypes}
                  placeholder={"Choose Type"}
                />
              )}
            </Grid>
          </Grid>
        )
      },
    },
    {
      header: "Required",
      accessorKey: "required",
      Cell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="flex-start" margin="0px 4px 0px 0px">
            <Switch
              sx={{
                "& .MuiSwitch-thumb": {
                  width: "15px",
                  height: "15px",
                },
              }}
              size="small"
              onChange={() =>
                formik.setFieldValue(
                  `features[${row.index}].is_required`,
                  !formik.values.features[row.index]?.is_required,
                )
              }
              checked={
                formik.values.features?.length > 0
                  ? formik.values.features[row.index]?.is_required
                  : schema?.features[row.index]?.is_required
              }
              disabled={
                currentMode === "read" ||
                (currentMode !== "create" &&
                  !formik.values.features[row.index]?.new &&
                  schema?.features?.find((feat) => feat?.name === formik.values.features[row.index]?.name)?.source !==
                    "workflow")
              }
            />
          </Box>
        )
      },
    },

    {
      header: "Source",
      accessorKey: "source",
      Cell: ({ row }) => {
        // Replace underscores with spaces
        const stringWithSpaces = formik.values.features[row?.index]?.source?.replace(/_/g, " ")

        // Replace "and" (with or without underscores) with plus sign
        const stringWithPlus = stringWithSpaces?.replace(/\band\b|_and_|_and\b|\band_/g, "+")

        return (
          <Box display="flex" justifyContent="flex-start" margin="0px 4px 0px 0px">
            <Typography variant="label">{stringWithPlus ?? "workflow"}</Typography>
          </Box>
        )
      },
    },
    {
      header: "Remove",
      accessorKey: "remove",
      Cell: ({ row }) => {
        const isButtonDisabled = Boolean(
          currentMode === "read" ||
            (currentMode !== "create" &&
              !formik.values.features[row.index]?.new &&
              schema?.features?.find((feat) => feat?.name === formik.values.features[row.index]?.name)?.source !==
                "workflow"),
        )

        return (
          <Grid item xs={12} container flexWrap={"nowrap"}>
            <Button
              tooltip={
                isButtonDisabled && currentMode === "edit"
                  ? formik.values.features[row.index]?.source !== "workflow"
                    ? "Can't remove feature used in live model"
                    : "Can't remove a required feature"
                  : ""
              }
              tooltipPlacement="bottom"
              variant={"dangerous"}
              size="small"
              onClick={() => {
                formik.setFieldValue(
                  `features`,
                  formik.values.features.filter((feat) => feat?.id !== formik.values.features[row.index]?.id),
                )
              }}
              className={styles.iconButton}
              disabled={isButtonDisabled}
            >
              <RemoveCircleOutlineOutlinedIcon
                fontSize="small"
                style={{ fill: "var(--important-text-enabled) !important" }}
              />
            </Button>
          </Grid>
        )
      },
    },
  ]

  const adjustedColumns =
    currentMode === "create" ? columns.filter((column) => column.accessorKey !== "source") : columns

  // read table data from formik
  const tableData = formik.values.features ?? []

  const handleAcceptDialogClose = (mode: "create" | "edit" | "read"): void => {
    if (mode === "create") {
      onClose()
    } else if (mode === "edit") {
      onClose()

      setFormikSnapShot(JSON.parse(JSON.stringify(formik?.values?.features)))

      setTimeout(() => {
        formik.resetForm({
          values: {
            workflowName: workflowName,
            features: formikSnapShot ?? schema?.features,
          },
        })
      }, 10)
      setCurrentMode("read")
    }
    onClose()
  }

  const handleSchemaSubmit = (): void => {
    if (formik.values.features.some((feat) => feat.name === "")) {
      NotificationUtils.toast(`Can't submit empty feature(s), please remove any unused features`, {
        snackBarVariant: "negative",
      })
    } else {
      formik.setFieldValue("isSchemaSubmit", true)
      formik.submitForm()
      setTimeout(() => {
        onClose()
        setCurrentMode("read")
      }, 200)
    }
  }

  return (
    <Dialog
      onClose={(_, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return undefined
        } else {
          setTimeout(() => {
            handleAcceptDialogClose(currentMode)
          }, 10)
        }
      }}
      open={open}
      maxWidth="md"
      fullWidth
      fullScreen={useMediaQuery(theme.breakpoints.down("lg"))}
    >
      <DialogTitle className="dialog-header-base">
        <Grid item container justifyContent="space-between" xs={12}>
          <Grid item alignSelf={"center"}>
            <Typography variant="h2-bold">{currentMode === "create" ? "Add Schema" : "Schema"}</Typography>
          </Grid>

          <Grid item alignSelf={"center"}>
            <IconButton
              aria-label="close"
              onClick={() => {
                handleAcceptDialogClose(currentMode)
              }}
              className="close-icon-button"
            >
              <CloseIcon style={{ color: themeMode.palette.gray.background[1] }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog-content-base">
        {/* Showing duplicate feature name*/}
        {duplicateFeature && (
          <Grid item mb={1}>
            <Snackbar variant="negative" fullWidth description={`Duplicate feature: "${duplicateFeature}"`} />
          </Grid>
        )}

        <Grid item container xs={12}>
          {currentMode !== "read" && (
            <Grid container xs={12} item mb={1} justifyContent="space-between">
              {currentMode === "create" ? (
                <Grid item className={styles.inputContainer}>
                  <InputText
                    hideDescription
                    id="workflowName"
                    placeholder="Workflow name"
                    value={formik.values.workflowName}
                    key={activeWorkflowId}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.workflowName && Boolean(formik.errors.workflowName) && formik.errors.workflowName
                    }
                    handleBlur={formik.handleBlur}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
              ) : (
                <Grid item justifyContent={"flex-start"}>
                  <DataBlock value={formik.values.workflowName} />
                </Grid>
              )}

              <Grid item justifyContent={"flex-end"}>
                <MuiButton
                  size="small"
                  className={"MuiButton"}
                  onClick={() => {
                    formik.setFieldValue("features", [
                      { name: "", type: "TEXT", is_required: true, remove: false, id: uuidv4(), new: true },
                      ...formik.values.features,
                    ])
                  }}
                >
                  + Add feature
                </MuiButton>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <BaseTableContainer
              source="workflow-schema"
              disableToolbar
              columns={(adjustedColumns as MRT_ColumnDef[]) ?? []}
              data={tableData ?? []}
              isLoading={currentMode === "read" && isWorkflowsLoading}
              title="Add Schema"
              pageSize={5}
              stripped
            />
          </Grid>
        </Grid>

        {currentMode === "create" && (
          <Grid item container xs={12} mt={6}>
            <Grid item container justifyContent="space-between" xs={12}>
              <Grid item alignSelf={"center"} mb={0.5}>
                <Typography variant="label">Upload schema</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Dropzone
                uploadingCSV={uploadingCSV}
                uploadProgress={uploadProgress}
                currentSelectedFiles={acceptedFile}
                setSelectedFiles={(file: File[]) => setAcceptedFile(file)}
                setInvalidFiles={(file: FileRejection[]) => setInvalidFiles(file)}
                handleCancelUpload={handleCloseAndAbort}
                bottomText="Max 2 GB"
                height={72}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className="dialog-actions-base">
        {currentMode !== "read" && !isWorkflowDeprecated && (
          <Grid container justifyContent="flex-end" item xs={12}>
            {currentMode !== "create" && (
              <Button
                disabled={!formik.values.workflowName}
                variant="secondary"
                onClick={() => handleAcceptDialogClose(currentMode)}
              >
                cancel
              </Button>
            )}

            <Button
              style={{ marginLeft: "6px" }}
              disabled={duplicateFeature || !formik.values.workflowName}
              variant={"primary"}
              onClick={() => {
                if (isWorkflowInReadMode) {
                  handleSchemaSubmit()
                } else {
                  setFileUUID("")
                  reset()
                  setUploadProgress(0)
                  setAcceptedFile([])
                  onClose()
                }
              }}
            >
              {formik.isSubmitting ? (
                <CircularProgress color="inherit" size={16} />
              ) : isWorkflowInReadMode ? (
                "Save"
              ) : (
                "Edit"
              )}
            </Button>
          </Grid>
        )}
        {currentMode === "read" && !isWorkflowDeprecated && (
          <Grid container xs={12} item mb={1} justifyContent="flex-end">
            <Button
              disabled={!formik.values.workflowName}
              variant="secondary"
              onClick={() => {
                setCurrentMode("edit")
              }}
            >
              Edit
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  )
}
