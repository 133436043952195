import React, { Fragment, ReactElement, useReducer, useRef } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { AxiosError, AxiosResponse } from "axios"
import { MRT_ColumnDef } from "material-react-table"

import { tableConfigReducer } from "../../reducers/TableConfigsReducer"
import { KonanAPI } from "../../services/KonanAPI"
import { ColumnState, TableRefState, VisibilityMap } from "../../types/custom/tables"
import { TableColumnConfiguration } from "../../types/generated/api/TableColumnConfiguration"
import { TableColumnConfigurationRequest } from "../../types/generated/api/TableColumnConfigurationRequest"
import { TableConfigurationRequest } from "../../types/generated/api/TableConfigurationRequest"
import { BaseTable } from "./BaseTable"
import { ORDERED_TABLES } from "./CONSTANTS"
import { TableLoadingState } from "./ProjectDecisions"

type Props = {
  source: TableConfigurationRequest.table
  columns: MRT_ColumnDef[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  hiddenColumns?: string[]
  isLoading: boolean
  title: string | ReactElement
}

/**
 * A container component that handles logic for column visibility and order, and fetches table configurations.
 * It manages states for columns' visibility, order, and provides UI elements for interaction with column settings.
 * This component encapsulates the BaseTable presentational component, passing computed states and props.
 *
 * @prop {TableConfigurationRequest.table} source - The source table configuration request, used to determine table-specific settings.
 * @prop {MRT_ColumnDef[]} columns - Array of column definitions used for rendering the table columns.
 * @prop {any[]} data - The actual data that will be displayed in the table. 'any[]' type is used to allow flexibility in data structure.
 * @prop {string[]} [hiddenColumns] - Optional array of column keys that should be initially hidden in the table.
 * @prop {boolean} isLoading - Flag indicating whether the data is currently being loaded.
 * @prop {string | ReactElement} title - Title of the table, can be a string or a React element for custom titles.
 * @returns {React.ReactElement} A React Element representing the table and its configuration controls.
 */
export function BaseTableContainer(props: Readonly<Props>): React.ReactElement {
  const { source, columns, data, hiddenColumns, title, isLoading, ...rest } = props
  const { id: projectId } = useParams<{ id: string }>()

  const queryClient = useQueryClient()

  const tableRef = useRef<TableRefState>(null) // Create a ref to store the table instance

  // Determine if the current table has order support or not
  const supportedOrderingTables = source ? ORDERED_TABLES.includes(source) : false

  const initialState: ColumnState = {
    columnsOrder: supportedOrderingTables ? [] : columns?.map((column) => column?.accessorKey),
    columnsVisibility: {},
  }

  const [columnState, dispatch] = useReducer(tableConfigReducer, initialState)

  // Fetch column configurations if backend is supported
  const {
    isLoading: isTableConfigsLoading,
    isRefetching: isTableConfigsRefetching,
    isFetching,
  } = useQuery<AxiosResponse<TableConfigurationRequest>, AxiosError>(
    ["column-configs", source, projectId, data],
    () => KonanAPI.fetchTableColumnsConfigs(projectId as string, source as string),
    {
      keepPreviousData: true,
      onSuccess: async (response) => {
        // check if this table has been ordered before
        if (response?.data?.column_configs?.length > 0) {
          const columnConfigurations = response?.data?.column_configs

          // map columns data to array of columns names (Ids)
          const mapResponseToColumnNames = columnConfigurations?.map(
            (column: TableColumnConfigurationRequest) => column?.column_name,
          )

          // Create a visibility map based on the backend response
          const visibilityMap = columnConfigurations.reduce((acc: VisibilityMap, column) => {
            acc[column.column_name] = column.is_visible !== undefined ? column.is_visible : true
            return acc
          }, {} as VisibilityMap)

          // extract the rest (un-ordered) columns
          const restUnOrderedColumns = columns
            .filter((column) => !mapResponseToColumnNames?.some((col: string) => col === column?.accessorKey))
            ?.map((singleColumn) => singleColumn?.accessorKey)

          // update the columns order with both the retrieved mapped columns and add the rest un-ordered columns
          dispatch({
            type: "UPDATE_COLUMN_ORDER",
            payload: { newOrder: [...mapResponseToColumnNames, ...restUnOrderedColumns] },
          })

          // Update visibility state
          // For columns not included in the backend response, default to true (visible)
          dispatch({
            type: "UPDATE_VISIBILITY",
            payload: {
              newVisibility: columns.reduce((acc: VisibilityMap, column) => {
                if (column?.accessorKey) {
                  // Check if accessorKey is defined
                  acc[column.accessorKey] = visibilityMap.hasOwnProperty(column.accessorKey)
                    ? visibilityMap[column.accessorKey]
                    : true // Default to true if not found in visibilityMap
                }
                return acc
              }, {} as VisibilityMap),
            },
          })
        } else if (columns && columns?.length > 0) {
          // If no configurations retrieved, set default order and visibility
          const defaultOrder = columns.map((column) => column?.accessorKey)

          const defaultVisibility = columns.reduce((acc: VisibilityMap, column) => {
            if (column.accessorKey) {
              acc[column.accessorKey] = !hiddenColumns?.includes(column.accessorKey)
            }
            return acc
          }, {} as VisibilityMap)

          dispatch({
            type: "UPDATE_VISIBILITY",
            payload: {
              newVisibility: defaultVisibility,
            },
          })

          dispatch({
            type: "UPDATE_COLUMN_ORDER",
            payload: { newOrder: [...(defaultOrder ?? [])] },
          })
        }
      },
      enabled: !!source && !!projectId && supportedOrderingTables,
    },
  )

  const orderColumnsMutation = useMutation<
    AxiosResponse<TableColumnConfiguration>,
    AxiosError,
    TableConfigurationRequest & { project_uuid: string }
  >(KonanAPI.configureTableColumns, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["column-configs", source, projectId, columns])
    },
  })

  // handler for updating the columns configuration
  // configuration includes (column order, column visibility)
  const updateColumnConfig = (currentOrder: string[], columnsVisibility: Record<string, boolean>): void => {
    const columnConfigs: TableColumnConfiguration[] = currentOrder
      .filter((order) => order !== "mrt-row-expand")
      .map((columnId, index) => ({
        column_name: columnId,
        order: index,
        is_visible: columnsVisibility[columnId] ?? true,
      }))

    // update table config only if columns length below the specified threshold
    if (columnConfigs?.length < Number(window.__RUNTIME_CONFIG__.KONAN_TABLE_MAX_COLUMNS)) {
      orderColumnsMutation.mutate({
        project_uuid: projectId as string,
        column_configs: columnConfigs,
        table: source,
      })
    }
  }

  const onColumnOrderChange = (newOrder: string[]): void => {
    dispatch({
      type: "UPDATE_COLUMN_ORDER",
      payload: { newOrder },
    })

    updateColumnConfig(newOrder, columnState?.columnsVisibility)
  }

  const onColumnVisibilityChange = (
    updaterFn: (visibility: Record<string, boolean>) => Record<string, boolean>,
  ): void => {
    if (tableRef.current) {
      const newState = updaterFn(tableRef.current.getState().columnVisibility)

      const areAllColumnsHidden = columns.every((column) => newState[column?.accessorKey] === false)

      if (areAllColumnsHidden) {
        dispatch({
          type: "UPDATE_VISIBILITY",
          payload: { newVisibility: { ...newState, "mrt-row-expand": false } },
        })
      } else {
        dispatch({
          type: "UPDATE_VISIBILITY",
          payload: { newVisibility: { ...newState, "mrt-row-expand": true } },
        })
      }

      // Use the current column order and new visibility state for the backend update
      const currentOrder = tableRef.current?.getState()?.columnOrder

      updateColumnConfig(currentOrder, newState)
    }
  }

  return (
    <Fragment>
      {isTableConfigsLoading ? (
        <TableLoadingState />
      ) : (
        <BaseTable
          columns={columns}
          data={data}
          tableRef={tableRef}
          title={title}
          isLoading={isLoading || isTableConfigsRefetching || isFetching}
          columnsOrder={columnState?.columnsOrder ?? []}
          onColumnVisibilityChange={onColumnVisibilityChange}
          onColumnOrderChange={onColumnOrderChange}
          columnsVisibility={columnState?.columnsVisibility}
          source={source}
          {...rest}
        />
      )}
    </Fragment>
  )
}
