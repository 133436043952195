import React from "react"

import { Breadcrumbs } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

type Props = {
  parents: string
}

export function ParentsBreadcrumbs(props: Props): React.ReactElement {
  const { parents } = props

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {parents.split(".").map((parent) => (
        <Typography variant="span">{parent}</Typography>
      ))}
    </Breadcrumbs>
  )
}
